<template>
  <div
    class="PmButtonPure"
    :class="[$attrs.class, classes]"
    v-bind="separateAttrs($attrs).listeners"
  >
    <component :is="tagRoot" class="PmButtonPure-inner">
      <input
        v-if="isInput && inputType"
        v-bind="propsInput"
        ref="elInput"
        :type="inputType"
        class="PmButtonPure-input"
        :disabled="isDisabled"
        :name="group?.toString()"
        @input="onInput"
        @invalid="formValidation.onInvalid"
      />

      <component
        :is="tagContent"
        class="PmButtonPure-content"
        :title="titleNormalized"
        :disabled="isDisabled"
        v-bind="{
          ...separateAttrs($attrs).attributes,
          ...attributesContent,
        }"
        @click="emit('click', $event)"
      >
        <div v-if="notification" class="PmButtonPure-notification"></div>

        <div v-if="loading" class="PmButtonPure-iconContainer">
          <PmLoadingPure
            v-if="loading"
            class="PmButtonPure-icon PmButtonPure-icon--loading"
          />
        </div>

        <div
          v-if="(icon || iconHover) && !loading"
          class="PmButtonPure-iconContainer PmButtonPure-iconContainer--before"
        >
          <div
            class="PmButtonPure-icon PmButtonPure-icon--before PmButtonPure-icon--default"
          >
            <PmIconPure v-if="icon" :key="icon" :name="icon" :size="size" />

            <div v-if="$slots.icon" class="PmButtonPure-generic">
              <slot name="icon" />
            </div>
          </div>

          <div
            v-if="iconHover"
            class="PmButtonPure-icon PmButtonPure-icon--before PmButtonPure-icon--hover"
          >
            <PmIconPure :key="iconHover" :name="iconHover" :size="size" />
          </div>
        </div>

        <div v-if="label || $slots.default" class="PmButtonPure-label">
          {{ label }}
          <slot />
        </div>

        <div
          v-if="iconAfter"
          class="PmButtonPure-iconContainer PmButtonPure-iconContainer--after"
        >
          <div class="PmButtonPure-icon PmButtonPure-icon--after">
            <PmIconPure
              v-if="iconAfter"
              :key="iconAfter"
              :name="iconAfter"
              :size="size"
            />
          </div>
        </div>
      </component>
    </component>

    <div v-if="note" class="PmButtonPure-note">{{ note }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useFormValidation } from '@thomasaull-shared/composables'
import { type Props as PropsUseFormValidation } from '@thomasaull-shared/composables/src/useFormValidation.ts'

import { separateAttrs } from '@/utilities/misc'
import { useLink, type Props as PropsUseLink } from '@/composition/useLink'

import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import type { PropsFileInput } from '@/components/basics/PmInputFile/PmInputFilePure.vue'

import type { Icon } from '@/constants/icons'

const COMPONENT_NAME = 'PmButtonPure'

type PickedPropsUseLink = Pick<
  PropsUseLink,
  'target' | 'href' | 'to' | 'linkOpenInNewWindow'
>

export interface Props
  extends PropsUseFormValidation,
    PickedPropsUseLink,
    PropsFileInput {
  id?: string
  icon?: Icon
  iconHover?: Icon
  iconAfter?: Icon
  label?: string
  title?: string
  disabled?: boolean
  loading?: boolean
  notification?: boolean
  variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger'
  active?: boolean
  inGroup?: boolean
  isFirstInGroup?: boolean
  isLastInGroup?: boolean
  isInMiddleOfGroup?: boolean
  checked?: boolean
  type?: 'button' | 'submit' | 'checkbox' | 'radio' | 'file'
  group?: number | string
  expanded?: boolean
  centered?: boolean
  size?: 'default' | 'small'
  alternative?: 'default' | 'ghost'
  note?: string
  /** Use the button to submit a form from outside of the <form> tags */
  form?: string
  /** When input is used, should it be required? */
  required?: boolean
  /** In case file input is used use this to set a file value on the input */
  files?: Map<string, File>
}

const props = withDefaults(defineProps<Props>(), {
  checked: true,
  type: 'button',
  variant: 'secondary',
})

const emit = defineEmits<{
  input: [event: Event]
  inputId: [string]
  click: [event: MouseEvent]
  updateInvalidMessage: [string | undefined]
}>()

const link = useLink(props)

/**
 * Input validation
 */
const elInput = ref<HTMLInputElement>()

const inputType = computed(() => {
  if (props.type === 'checkbox') return 'checkbox'
  if (props.type === 'radio') return 'radio'
  if (props.type === 'file') return 'file'

  return false
})

const formValidation = useFormValidation({
  elInput: elInput,
  type: inputType.value ? inputType.value : undefined,
  validate: () => {
    if (!props.invalidMessage) return
    if (
      Array.isArray(props.invalidMessage) &&
      props.invalidMessage.length === 0
    ) {
      return
    }

    return 'Eingabe ungültig'
  },
})

watch(formValidation.invalidMessage, () => {
  emit('updateInvalidMessage', formValidation.invalidMessage.value)
})
/**
 * For validation purposes we set the value to any file provided via the prop
 * The technique is described here:
 * @see https://pqina.nl/blog/set-value-to-file-input/
 */
// function addFilesToFileInput(files?: File[]) {
function addFilesToFileInput(files?: Map<string, File>) {
  if (!elInput.value) return

  const dataTransfer = new DataTransfer()
  files?.forEach((file) => dataTransfer.items.add(file))
  elInput.value.files = dataTransfer.files
}

watch(
  () => props.files,
  () => {
    addFilesToFileInput(props.files)
    formValidation.checkValidity()
  },
  { deep: true }
)

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--inGroup`]: props.inGroup,
    [`${COMPONENT_NAME}--noLabel`]: !props.label,
    [`${COMPONENT_NAME}--withIconHover`]: props.iconHover,
    [`${COMPONENT_NAME}--withIconBefore`]: props.icon,
    [`${COMPONENT_NAME}--withIconAfter`]: props.iconAfter,
    [`${COMPONENT_NAME}--primary`]: props.variant === 'primary',
    [`${COMPONENT_NAME}--secondary`]: props.variant === 'secondary',
    [`${COMPONENT_NAME}--success`]: props.variant === 'success',
    [`${COMPONENT_NAME}--warning`]: props.variant === 'warning',
    [`${COMPONENT_NAME}--danger`]: props.variant === 'danger',
    [`${COMPONENT_NAME}--ghost`]: props.alternative === 'ghost',
    [`${COMPONENT_NAME}--expanded`]: props.expanded === true,
    [`${COMPONENT_NAME}--centered`]: props.centered === true,
    [`${COMPONENT_NAME}--sizeSmall`]: props.size === 'small',
    'is-active': props.active,
    'is-disabled': isDisabled.value,
    'is-firstInGroup': props.isFirstInGroup,
    'is-inMiddleOfGroup': props.isInMiddleOfGroup,
    'is-lastInGroup': props.isLastInGroup,
    'is-loading': props.loading,
  }
})

const tagRoot = computed(() => {
  return isInput.value ? 'label' : 'div'
  // return 'div'
})

const tagContent = computed(() => {
  if (isInput.value) return 'div'
  return link.tag.value
})

const isInput = computed(() => {
  return (
    props.type === 'checkbox' || props.type === 'radio' || props.type === 'file'
  )
})

const attributesContent = computed(() => {
  let attributes: {
    type?: Props['type']
    form?: Props['form']
  } = {}

  if (props.type === 'button' || props.type === 'submit') {
    attributes.type = props.type
    attributes.form = props.form
  }

  attributes = {
    ...attributes,
    ...link.props.value,
  }

  return attributes
})

const isDisabled = computed(() => {
  if (props.loading) return true
  if (props.disabled) return true

  return false
})

const titleNormalized = computed(() => {
  if (props.title) return props.title
  if (props.label) return props.label

  return undefined
})

function onInput(event: Event) {
  emit('input', event)

  if (props.id) {
    emit('inputId', props.id)
  }
}

const propsInput = computed(() => {
  const base = {
    required: props.required,
  }

  if (props.type === 'checkbox') {
    return {
      ...base,
      checked: props.checked,
    }
  }

  if (props.type === 'radio') {
    return {
      ...base,
      checked: props.checked,
    }
  }

  if (props.type === 'file') {
    return {
      ...base,
      multiple: props.maxNumberOfFiles !== 1,
      accept: props.allowedFileTypes?.join(', '),
    }
  }

  return undefined
})
</script>

<style lang="scss">
@use 'sass:color' as sassColor;

.PmButtonPure {
  $block: &;
  $name: function.getBlockName($block);

  @include cssVar.define(
    $block,
    'colorText--hover',
    cssVar.use($block, 'colorText')
  );
  @include cssVar.define(
    $block,
    'colorText--active',
    cssVar.use($block, 'colorText')
  );
  @include cssVar.define(
    $block,
    'colorText--disabled',
    cssVar.use($block, 'colorText')
  );

  &--primary {
    @include cssVar.define($block, 'colorText', color.$white);
    @include cssVar.define($block, 'colorBackground', color.$primary-500);
    @include cssVar.define(
      $block,
      'colorBackground--hover',
      color.$primary-600
    );
    @include cssVar.define(
      $block,
      'colorBackground--active',
      color.$primary-700
    );
    @include cssVar.define(
      $block,
      'colorBackground--disabled',
      sassColor.adjust(color.$primary-600, $saturation: -30%, $lightness: 25%)
    );
    @include cssVar.define(
      $block,
      'colorBorder',
      cssVar.use($block, 'colorBackground')
    );
    @include cssVar.define(
      $block,
      'colorBorder--hover',
      cssVar.use($block, 'colorBackground--hover')
    );
    @include cssVar.define(
      $block,
      'colorBorder--active',
      cssVar.use($block, 'colorBackground--active')
    );
    @include cssVar.define(
      $block,
      'colorBorder--disabled',
      cssVar.use($block, 'colorBackground--disabled')
    );
    @include cssVar.define(
      $block,
      'colorShadow--focus',
      rgba(color.$primary-500, 0.4)
    );

    &#{$block}--ghost {
      @include cssVar.define($block, 'colorText', color.$primary-600);
      @include cssVar.define(
        $block,
        'colorBackground--hover',
        color.$primary-600
      );
    }
  }

  &--secondary {
    @include cssVar.define($block, 'colorText', color.$text-default);
    @include cssVar.define(
      $block,
      'colorText--hover',
      cssVar.use($block, 'colorText')
    );
    @include cssVar.define(
      $block,
      'colorText--active',
      cssVar.use($block, 'colorText')
    );
    @include cssVar.define(
      $block,
      'colorText--disabled',
      cssVar.use($block, 'colorText')
    );
    @include cssVar.define($block, 'colorBackground', color.$white);
    @include cssVar.define($block, 'colorBackground--hover', color.$gray-100);
    @include cssVar.define($block, 'colorBackground--active', color.$gray-200);
    @include cssVar.define(
      $block,
      'colorBackground--disabled',
      color.$gray-200
    );
    @include cssVar.define($block, 'colorBorder', color.$gray-300);
    @include cssVar.define(
      $block,
      'colorBorder--hover',
      cssVar.use($block, 'colorBorder')
    );
    @include cssVar.define(
      $block,
      'colorBorder--active',
      cssVar.use($block, 'colorBorder')
    );
    @include cssVar.define(
      $block,
      'colorBorder--disabled',
      cssVar.use($block, 'colorBackground--disabled')
    );
    @include cssVar.define(
      $block,
      'colorShadow--focus',
      rgba(color.$gray-500, 0.4)
    );

    &#{$block}--ghost {
      @include cssVar.define(
        $block,
        'colorText--hover',
        cssVar.use($block, 'colorText')
      );
      @include cssVar.define(
        $block,
        'colorText--active',
        cssVar.use($block, 'colorText')
      );
      @include cssVar.define($block, 'colorBackground--hover', color.$gray-200);
      @include cssVar.define(
        $block,
        'colorBackground--active',
        color.$gray-300
      );
      @include cssVar.define($block, 'colorBorder', transparent);
    }
  }

  &--success {
    @include cssVar.define($block, 'colorText', color.$white);
    @include cssVar.define($block, 'colorBackground', color.$success-500);
    @include cssVar.define(
      $block,
      'colorBackground--hover',
      color.$success-600
    );
    @include cssVar.define(
      $block,
      'colorBackground--active',
      color.$success-700
    );
    @include cssVar.define(
      $block,
      'colorBackground--disabled',
      sassColor.adjust(color.$success-600, $saturation: -20%, $lightness: 30%)
    );
    @include cssVar.define(
      $block,
      'colorBorder',
      cssVar.use($block, 'colorBackground')
    );
    @include cssVar.define(
      $block,
      'colorBorder--hover',
      cssVar.use($block, 'colorBackground--hover')
    );
    @include cssVar.define(
      $block,
      'colorBorder--active',
      cssVar.use($block, 'colorBackground--active')
    );
    @include cssVar.define(
      $block,
      'colorBorder--disabled',
      cssVar.use($block, 'colorBackground--disabled')
    );
    @include cssVar.define(
      $block,
      'colorShadow--focus',
      rgba(color.$success-500, 0.4)
    );

    &#{$block}--ghost {
      @include cssVar.define($block, 'colorText', color.$success-600);
      @include cssVar.define(
        $block,
        'colorBackground--hover',
        color.$success-600
      );
    }
  }

  &--warning {
    @include cssVar.define($block, 'colorText', color.$white);
    @include cssVar.define($block, 'colorBackground', color.$warning-500);
    @include cssVar.define(
      $block,
      'colorBackground--hover',
      color.$warning-600
    );
    @include cssVar.define(
      $block,
      'colorBackground--active',
      color.$warning-700
    );
    @include cssVar.define(
      $block,
      'colorBackground--disabled',
      sassColor.adjust(color.$warning-600, $saturation: -10%, $lightness: 25%)
    );
    @include cssVar.define(
      $block,
      'colorBorder',
      cssVar.use($block, 'colorBackground')
    );
    @include cssVar.define(
      $block,
      'colorBorder--hover',
      cssVar.use($block, 'colorBackground--hover')
    );
    @include cssVar.define(
      $block,
      'colorBorder--active',
      cssVar.use($block, 'colorBackground--active')
    );
    @include cssVar.define(
      $block,
      'colorShadow--focus',
      rgba(color.$warning-500, 0.4)
    );

    &#{$block}--ghost {
      @include cssVar.define($block, 'colorText', color.$warning-600);
      @include cssVar.define(
        $block,
        'colorBackground--hover',
        color.$warning-600
      );
    }
  }

  &--danger {
    @include cssVar.define($block, 'colorText', color.$white);
    @include cssVar.define($block, 'colorBackground', color.$danger-500);
    @include cssVar.define($block, 'colorBackground--hover', color.$danger-600);
    @include cssVar.define(
      $block,
      'colorBackground--active',
      color.$danger-700
    );
    @include cssVar.define(
      $block,
      'colorBackground--disabled',
      sassColor.adjust(color.$danger-600, $saturation: -10%, $lightness: 20%)
    );
    @include cssVar.define(
      $block,
      'colorBorder',
      cssVar.use($block, 'colorBackground')
    );
    @include cssVar.define(
      $block,
      'colorBorder--hover',
      cssVar.use($block, 'colorBackground--hover')
    );
    @include cssVar.define(
      $block,
      'colorBorder--active',
      cssVar.use($block, 'colorBackground--active')
    );
    @include cssVar.define(
      $block,
      'colorBorder--disabled',
      cssVar.use($block, 'colorBackground--disabled')
    );
    @include cssVar.define(
      $block,
      'colorShadow--focus',
      rgba(color.$danger-500, 0.4)
    );

    &#{$block}--ghost {
      @include cssVar.define($block, 'colorText', color.$danger-600);
      @include cssVar.define(
        $block,
        'colorBackground--hover',
        color.$danger-600
      );
    }
  }

  &--ghost {
    @include cssVar.define($block, 'colorText--hover', color.$white);
    @include cssVar.define($block, 'colorText--active', color.$white);
    @include cssVar.define($block, 'colorBackground', transparent);
    @include cssVar.define($block, 'colorBackground--disabled', transparent);
    @include cssVar.define($block, 'colorBorder', transparent);
    @include cssVar.define($block, 'colorBorder--hover', transparent);
    @include cssVar.define($block, 'colorBorder--active', transparent);
    @include cssVar.define($block, 'colorBorder--disabled', transparent);
  }

  @include cssVar.define($block, 'height', 32px);
  @include cssVar.define($block, 'spaceOutside', 8px);
  @include cssVar.define($block, 'spaceOutside--small', 4px);

  &--sizeSmall {
    @include cssVar.define($block, 'height', 22px);
    @include cssVar.define($block, 'spaceOutside', 4px);
    @include cssVar.define($block, 'spaceOutside--small', 2px);
  }

  // overflow: hidden; Apparently not needed?

  &.is-disabled {
    pointer-events: none;
  }

  // stylelint-disable-next-line plugin/selector-bem-pattern, no-descending-specificity
  .PmButtonListPure--fullWidthOnMobile & {
    @include mixin.media('<=buttons-full-width') {
      width: 100%;
    }
  }

  &-inner {
    // Empty
  }

  &-input {
    @include mixin.visuallyHidden;
  }

  &-content {
    height: cssVar.use($block, 'height');
    min-width: cssVar.use($block, 'height');
    max-width: 100%;
    background-color: cssVar.use($block, 'colorBackground');
    border: 1px solid cssVar.use($block, 'colorBorder');
    border-radius: constant.$borderRadius-default;
    font-weight: 550;
    // display: inline-flex; // TODO: Check if flex instead of inline-flex does make problems
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    color: cssVar.use($block, 'colorText');

    @include mixin.transition-hover((background-color, border-color, color));

    &:hover,
    #{$block}.is-hover & {
      background-color: cssVar.use($block, 'colorBackground--hover');
      border-color: cssVar.use($block, 'colorBorder--hover');
      color: cssVar.use($block, 'colorText--hover');
    }

    &:active,
    #{$block}.is-active &,
    #{$block}-input:checked ~ & {
      background-color: cssVar.use($block, 'colorBackground--active');
      border-color: cssVar.use($block, 'colorBorder--active');
      color: cssVar.use($block, 'colorText--active');
    }

    &:focus,
    #{$block}.is-focus,
    #{$block}-input:focus ~ & {
      outline: none;

      /* stylelint-disable-next-line plugin/selector-bem-pattern */
      .is-keyboardNavigation & {
        box-shadow: 0 0 0 3px cssVar.use($block, 'colorShadow--focus');
      }
    }

    #{$block}.is-disabled & {
      background-color: cssVar.use($block, 'colorBackground--disabled');
      border-color: cssVar.use($block, 'colorBorder--disabled');
      color: cssVar.use($block, 'colorText--disabled');
    }

    // Set default padding
    padding-left: cssVar.use($block, 'spaceOutside');
    padding-right: cssVar.use($block, 'spaceOutside');

    // Remove default padding left for some cases
    #{$block}--withIconBefore &,
    #{$block}:not(#{$block}--noLabel).is-loading & {
      // padding-left: cssVar.use($block, 'spaceOutside--small');
      padding-left: 0;
    }

    #{$block}--noLabel & {
      padding-left: 0;
    }

    // Remove default padding right for some cases
    #{$block}--withIconAfter &,
    #{$block}--noLabel & {
      padding-right: 0;
    }

    #{$block}--inGroup & {
      border-radius: 0;
    }

    #{$block}--inGroup:not(:first-child) &,
    #{$block}--inGroup.is-lastInGroup &,
    #{$block}--inGroup.is-inMiddleOfGroup & {
      border-left: 0;
    }

    #{$block}--inGroup:first-child &,
    #{$block}--inGroup.is-firstInGroup & {
      border-radius: constant.$borderRadius-default 0 0
        constant.$borderRadius-default;
    }

    #{$block}--inGroup:last-child &,
    #{$block}--inGroup.is-lastInGroup & {
      border-radius: 0 constant.$borderRadius-default
        constant.$borderRadius-default 0;
    }

    #{$block}--expanded & {
      width: 100%;
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern, no-descending-specificity
    .PmButtonListPure--fullWidthOnMobile & {
      @include mixin.media('<=buttons-full-width') {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &-notification {
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background-color: color.$danger-500;
    position: absolute;
    top: -3px;
    right: -3px;
    box-shadow: 0 0 0 2px rgba(color.$white, 0.5);
    z-index: 1;
  }

  &-iconContainer {
    @include cssVar.define($block, 'iconSize', 30px);
    @include cssVar.define($block, 'iconPadding', 3px);

    height: cssVar.use($block, 'iconSize');
    width: cssVar.use($block, 'iconSize');
    overflow: hidden;
    flex: none;

    #{$block}--noLabel & {
      @include cssVar.define($block, 'iconPadding', 3px);
    }

    #{$block}--sizeSmall & {
      @include cssVar.define($block, 'iconSize', 20px);
      @include cssVar.define($block, 'iconPadding', 1px);
    }

    #{$block}.is-disabled & {
      opacity: 0.8;
    }

    #{$block}--secondary.is-disabled & {
      opacity: 0.3;
    }
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    position: relative;
    height: cssVar.use($block, 'iconSize');
    width: cssVar.use($block, 'iconSize');
    padding: cssVar.use($block, 'iconPadding');

    #{$block}--withIconHover & {
      transition: all 0.2s;
      transition-property: transform, opacity;
      transition-timing-function: ease-out;
      will-change: opacity, transform;
    }

    #{$block}--withIconHover &--default {
      opacity: 1;
    }

    #{$block}--withIconHover:hover &--default {
      opacity: 0;
    }

    #{$block}--withIconHover &--hover {
      opacity: 0;
    }

    #{$block}--withIconHover:hover &--hover {
      opacity: 1;
    }

    #{$block}--withIconHover:hover & {
      transform: translateY(-100%);
    }
  }

  &-label {
    @include mixin.truncateText;

    margin: 0;
    border: none;
    height: calc(cssVar.use($block, 'height') - 2px);
    line-height: calc(cssVar.use($block, 'height') - 2px);
    background: none;
    text-align: left;
    width: 100%; // Is this needed? Apprently yes, this centers the buttons in the tab navigation on PmActionbarPure

    &:focus {
      outline: none;
    }

    #{$block}--centered & {
      text-align: center;
    }

    #{$block}.is-disabled & {
      opacity: 0.8;
    }

    #{$block}--secondary.is-disabled & {
      opacity: 0.5;
    }
  }

  &-note {
    font-size: constant.$fontSize-default;
    font-weight: 500;
    color: color.$gray-700;
    margin-top: 4px;
    text-align: center;
  }
}
</style>
