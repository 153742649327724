import { cloneDeep, get, isEqual, isNil } from 'lodash-es'

import { router } from '@/router'
import { formatWithLocale } from '@/utilities/date'
import { FORMAT_DATE_SERVER } from '@/constants/persoplan'
import { urlParamsToPersist } from '@/store/plugins/settingsConfig'

export default (store) => {
  let previousState = cloneDeep(store.state)

  store.subscribe((mutation, state) => {
    // Ignore the mutation which applies the actual settings
    if (mutation.type === 'applyPersistedSettings') {
      return
    }

    const changedSettings = Object.keys(urlParamsToPersist).filter(
      (statePath) => {
        const options = urlParamsToPersist[statePath]
        const valuePrevious = get(previousState, statePath)
        const valueCurrent = get(state, statePath)

        if (options.type === Date) {
          return hasDateChanged(valuePrevious, valueCurrent)
        }

        if (options.type === Object) {
          return hasObjectchanged(valuePrevious, valueCurrent)
        }

        // If code runs until here, it's a primitive value
        return valuePrevious !== valueCurrent
      }
    )

    // Nothing has changed
    if (!changedSettings.length) return

    // TODO: Only persist changed settings in url instead all of them

    const stateSnapshot = cloneDeep(state)
    persistSettings(stateSnapshot)

    previousState = cloneDeep(state)
  })
}

function hasDateChanged(previous, current) {
  return previous.getTime() !== current.getTime()
}

function hasObjectchanged(previous, current) {
  return JSON.stringify(previous) !== JSON.stringify(current)
}

async function persistSettings(state) {
  const route = router.currentRoute.value
  if (route.name !== 'persoplan') return

  const newQuery = cloneDeep(route.query)

  Object.keys(urlParamsToPersist).forEach((statePath) => {
    const value = get(state, statePath)
    const options = urlParamsToPersist[statePath]

    if (isNil(value)) {
      delete newQuery[options.id]
      return
    }

    let valueNormalized

    if (options.type === Date) {
      valueNormalized = formatWithLocale(value, FORMAT_DATE_SERVER)
    }

    if (options.type === Number) {
      valueNormalized = value.toString()
    }

    if (!valueNormalized) {
      console.warn('there is no normalized value for', value, options)
    }

    newQuery[options.id] = valueNormalized
  })

  // Check if new and old route will be same
  const isSame = isEqual(route.query, newQuery)
  if (isSame) return

  router.replace({
    query: newQuery,
  })
}
