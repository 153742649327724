<template>
  <div class="PmLoginPure">
    <div class="PmLoginPure-container">
      <div class="PmLoginPure-content">
        <PmStatusPure
          v-if="xstate.state.value.matches('checkIfLoggedIn')"
          :icon="ICONS.LOCK_OPEN"
        >
          Prüfe Anmeldung
        </PmStatusPure>

        <PmStatusPure
          v-if="xstate.state.value.matches('autoLogin')"
          :icon="ICONS.LOCK_OPEN"
        >
          Versuche automatische Anmeldung
        </PmStatusPure>

        <PmStatusPure
          v-if="xstate.state.value.matches('login.success')"
          :icon="ICONS.CHECK"
        >
          Anmeldung erfolgreich
        </PmStatusPure>

        <PmLoginFormPure
          v-if="loginFormVisible"
          class="PmLoginPure-loginForm"
          :state="loginFormState"
          :error-message="errorMessage"
          :error-details="errorDetails"
          @login="$emit('loginWithCredentials', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRef } from 'vue'
import type { PropType } from 'vue'

import { ICONS } from '@/constants/icons'

import { PmLoginPureState, states } from '@/views/PmLogin/PmLoginPureState'

import type { TState } from '@/views/PmLogin/PmLoginPureState'

import { useXState } from '@/composition/useXState'

import PmLoginFormPure from '@/components/PmLogin/PmLoginFormPure.vue'
import PmStatusPure from '@/components/basics/PmStatusPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmLoginPure',
  components: {
    PmLoginFormPure,
    PmStatusPure,
  },

  props: {
    state: {
      type: String as PropType<TState>,
      default: undefined,
    },
    errorMessage: { type: String, default: undefined },
    errorDetails: { type: Array, default: undefined },
  },

  emits: ['loginWithCredentials'],

  setup(props) {
    const xstate = useXState(PmLoginPureState, {
      syncStateWith: toRef(props, 'state'),
    })

    return {
      ICONS,

      xstate,
    }
  },

  computed: {
    loginFormState() {
      if (this.xstate.state.value.matches('login.default')) return 'default'
      if (this.xstate.state.value.matches('login.loggingIn')) return 'loggingIn'
      if (this.xstate.state.value.matches('login.success')) return 'success'
      if (this.xstate.state.value.matches('login.failed')) return 'failed'

      return undefined
    },

    loginFormVisible() {
      if (this.xstate.state.value.matches('login.success')) return false
      if (this.xstate.state.value.matches('login')) return true

      return false
    },
  },
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmLoginPure {
  $block: &;

  background-color: color.$gray-300;
  height: 100%;

  &-container {
    // Shift container bit to the top for better vertical alignment
    transform: translateY(-3%);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    // min-height: -webkit-fill-available; // Does not seem to work correctly in Chrome
    padding-left: var(--space-default);
    padding-right: var(--space-default);
  }

  &-content {
    @include shadow.default('medium');

    padding: var(--space-small);
    background-color: color.$white;
    border-radius: constant.$borderRadius-large;
    width: 100%;
    max-width: 600px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-loginForm {
    width: 100%;
  }
}
</style>
