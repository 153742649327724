import semver from 'semver'
import { cloneDeep, isNumber } from 'lodash-es'

import migrations from '@/migrations/migrations'

/**
 * Get predefined migrations and run migrations
 */
export function migrateType({ schema, type, to }) {
  // Get migrations for type
  const migrationsAreAvailable = Object.prototype.hasOwnProperty.call(
    migrations,
    type
  )
  if (!migrationsAreAvailable) {
    throw new Error(`Can't find any migrations for type ${type}`)
  }

  const migrationsOfType = migrations[type]

  /**
   * Special case, version on type `view` was saved as number 2,
   * so in this case we convert it
   */
  if (type === 'view' && isNumber(schema.version) && schema.version === 2) {
    schema = cloneDeep(schema)
    schema.version = semver.valid(semver.coerce(schema.version))
  }

  // console.log(`Run migrations for type ${type} from ${schema.version} → ${to}`)
  return migrate({ schema, migrations: migrationsOfType, to })
}

/**
 *  Run migrations until the final version is reaced
 */
export function migrate({ schema, migrations, to }) {
  if (!schema.version) {
    throw new Error('schema.version is undefined')
  }
  const from = schema.version

  // Clone schema, so we're ready to mess around with it
  schema = cloneDeep(schema)

  if (!semver.valid(from)) throw new Error('from is no valid semver')
  if (!semver.valid(to)) throw new Error('to is no valid semver')

  // Check if schema is already on final version and exit migration
  const schemaIsOnFinalVersion = semver.eq(schema.version, to)
  if (schemaIsOnFinalVersion) {
    // console.log('nothing to migrate')
    return schema
  }

  // Check if to is greater than from
  const isDirectionUp = semver.gt(to, from)
  if (!isDirectionUp) {
    throw new Error('down migrations are currently not supported')
  }

  const migratedSchema = runMigrations({
    schema,
    migrations,
    finalVersion: to,
  })

  return migratedSchema
}

/**
 * Get the next migration and run it
 */
export function runMigrations({ schema, migrations, finalVersion }) {
  const schemaIsOnFinalVersion = semver.eq(schema.version, finalVersion)
  if (schemaIsOnFinalVersion) {
    return schema
  }

  const currentMigration = findNextMigrationToRun({
    migrations,
    from: schema.version,
    finalVersion,
  })

  // No more migrations found
  if (!currentMigration) {
    throw new Error(`no migration for ${schema.version} until ${finalVersion}`)
  }

  // console.log(
  //   `Run migration ${currentMigration.from} (schema: ${schema.version}) → ${currentMigration.to}`
  // )

  const migratedSchema = currentMigration.up(schema)

  return runMigrations({ schema: migratedSchema, migrations, finalVersion })
}

/**
 * Get next migration
 */
function findNextMigrationToRun({ migrations, from, finalVersion }) {
  // console.table({ label: 'findNextMigrationToRun', from, finalVersion })

  const fittingMigrations = migrations.filter((migration) => {
    const toIslowerOrEqualThanFinalVersion = semver.lte(
      migration.to,
      finalVersion
    )

    return migration.from === from && toIslowerOrEqualThanFinalVersion
  })

  if (fittingMigrations.length > 1) {
    throw new Error(
      `There are multiple migrations defined for ${from} → ${finalVersion}`
    )
  }

  if (fittingMigrations.length === 0) {
    throw new Error(`No migration found for ${from} → ${finalVersion}`)
  }

  const migration = fittingMigrations[0]

  // Check if migration has an up method
  const hasUpProperty = Object.prototype.hasOwnProperty.call(migration, 'up')
  if (!hasUpProperty) {
    throw new Error('migration has no up property')
  }

  const isFunction = typeof migration['up'] === 'function'
  if (!isFunction) {
    throw new Error('up is not a function')
  }

  return migration
}
