<template>
  <PmInputFilePure
    v-bind="props"
    :files-info="filesInfo"
    :invalid-message="invalidMessage"
    @add-files="addFiles"
    @remove-all-files="removeAllFiles"
    @remove-file="removeFile"
  />
</template>

<script setup lang="ts">
import { computed, triggerRef } from 'vue'
import type { Get } from 'type-fest'
import { useI18n } from 'vue-i18n'

import PmInputFilePure, {
  type Props as PropsInputFilePure,
} from '@/components/basics/PmInputFile/PmInputFilePure.vue'
import {
  convertSizeToMegaBytes,
  formatFileSizeToHumanReadable,
  checkIfAllowedFileType,
  convertSizeToBytes,
} from '@/utilities/file'

export interface Props extends Omit<PropsInputFilePure, 'files'> {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const files = defineModel<Map<string, File>>('files', { default: new Map() })

type FileInfo = NonNullable<Get<PropsInputFilePure, 'filesInfo[0]'>>
type InvalidReason = keyof FileInfo['invalidReasons']

const filesInfo = computed(() => {
  const result: FileInfo[] = []

  let index = -1 // Starting at -1 because we increase it first thing in the loop

  files.value.forEach((file) => {
    index += 1

    if (file.size === null) {
      throw new Error('file.size is null')
    }

    const invalidReasons: Record<InvalidReason, boolean> = {
      fileToLarge: false,
      wrongFileType: false,
      tooManyFiles: false,
    }

    const isAllowedFileType = checkIfAllowedFileType({
      mimeType: file.type,
      allowedFileTypes: props.allowedFileTypes,
    })

    const isAllowedFileSize = props.maxFileSize
      ? convertSizeToMegaBytes(file.size) < props.maxFileSize
      : true

    const isLessThanMaxFiles = props.maxNumberOfFiles
      ? index <= props.maxNumberOfFiles - 1
      : true

    if (!isAllowedFileType) invalidReasons.wrongFileType = true
    if (!isAllowedFileSize) invalidReasons.fileToLarge = true
    if (!isLessThanMaxFiles) invalidReasons.tooManyFiles = true

    const valid = isAllowedFileType && isAllowedFileSize && isLessThanMaxFiles

    result.push({
      name: file.name,
      size: convertSizeToMegaBytes(file.size),
      sizeHumanReadable: formatFileSizeToHumanReadable(file.size),
      valid: valid,
      invalidReasons: invalidReasons,
    })
  })

  return result
})

const invalidReasons = computed(() => {
  const reasons: Record<InvalidReason, number> = {
    fileToLarge: 0,
    wrongFileType: 0,
    tooManyFiles: 0,
  }

  filesInfo.value.forEach((fileInfo) => {
    Object.entries(fileInfo.invalidReasons).forEach(([id, invalid]) => {
      if (!invalid) return

      reasons[id] += 1
    })
  })

  return reasons
})

const { t } = useI18n({
  messages: {
    de: {
      invalidMessage: {
        fileToLarge:
          'Eine Datei ist größer als die erlaubte Dateigröße von {fileSize} | Mehrere Dateien sind größer als die erlaubte ,Dateigröße von {fileSize}',
        wrongFileType:
          'Eine Datei hat einen unerlaubten Dateityp | Mehrere Dateien haben einen unerlaubten Dateityp',
        tooManyFiles: 'Es sind maximal {maxNumberOfFiles} Dateien erlaubt',
      },
    },
  },
})

const invalidMessage = computed(() => {
  const result: string[] = []

  if (props.maxFileSize) {
    const maxFileSizeHumanReadable = formatFileSizeToHumanReadable(
      convertSizeToBytes(props.maxFileSize)
    )

    if (invalidReasons.value.fileToLarge > 0) {
      result.push(
        t('invalidMessage.fileToLarge', {
          fileSize: maxFileSizeHumanReadable,
        })
      )
    }
  }

  if (invalidReasons.value.wrongFileType > 0) {
    result.push(
      t('invalidMessage.wrongFileType', invalidReasons.value.wrongFileType)
    )
  }

  if (props.maxNumberOfFiles) {
    if (invalidReasons.value.tooManyFiles > 0) {
      result.push(
        t('invalidMessage.tooManyFiles', {
          maxNumberOfFiles: props.maxNumberOfFiles,
        })
      )
    }
  }

  return result
})

const isMultipleFilesAllowed = computed(() => {
  return props.maxNumberOfFiles !== 1
})

function addFiles(newFiles: File[]) {
  if (isMultipleFilesAllowed.value !== true) {
    removeAllFiles()
  }

  newFiles.forEach((file) => {
    files.value.set(file.name, file)
  })
  triggerRef(files)
}

function removeAllFiles() {
  files.value.clear()
  triggerRef(files)
}

function removeFile(name: string) {
  files.value.delete(name)
  triggerRef(files)
}
</script>
