//@ts-check
import { pickBy } from 'lodash-es'

/**
 *
 * @typedef fFilterPropsOptions
 * @property {string[]=} remove - Items you want to remove
 * @property {string[]=} onlyUse - Items you want to use
 *
 * @param {object} props - The props from the vue component
 * @param {fFilterPropsOptions} options
 */
export function filterProps(
  props,
  { remove = undefined, onlyUse = undefined }
) {
  if (remove) {
    return pickBy(props, (value, key) => !remove.includes(key))
  }

  if (onlyUse) {
    return pickBy(props, (value, key) => onlyUse.includes(key))
  }
}
