import { get } from 'lodash-es'
import { ApolloError } from '@apollo/client'

import { knownErrors as KNOWN_ERRORS } from '@/constants/errors'

export function getFriendlyErrors(
  error: unknown,
  { operation }: { operation?: string } = {}
) {
  if (!error) return

  if (error instanceof Error && !(error instanceof ApolloError)) {
    return getError(error, { operation })
  }

  if (error instanceof ApolloError) {
    return getGraphQLErrors(error, { operation })
  }

  throw new Error('Unknown error type')
}

function getError(error: Error, { operation }: { operation?: string } = {}) {
  const knownError = findKnownError(error.message, { operation })

  if (knownError) {
    return {
      message: knownError.message,
      details: knownError.details,
    }
  } else {
    return {
      message: 'Es ist ein unbekannter Fehler aufgetreten.',
      details: normalizeDetails([error.message]),
    }
  }
}

type ErrorNormalized = { message: string; details?: string[] }

function getGraphQLErrors(
  error: ApolloError,
  { operation }: { operation?: string } = {}
) {
  if (error.graphQLErrors.length === 0) {
    return getError(error)
  }

  const errors: ErrorNormalized[] = []

  const isMultiple = error.graphQLErrors.length > 1
  let hasUnknownErrors = false

  // Fill errors
  error.graphQLErrors.forEach((graphQLError) => {
    const errorMessage = graphQLError.message
    const knownError = findKnownError(errorMessage, {
      operation,
    })

    if (knownError) {
      errors.push(knownError)
    } else {
      hasUnknownErrors = true
      errors.push({ message: errorMessage })
    }
  })

  if (isMultiple) {
    return {
      message: 'Es sind mehrere Fehler aufgetreten.',
      details: normalizeDetails(errors.map((error) => error.message)),
    }
  }

  // Is single error
  if (hasUnknownErrors) {
    const details: ErrorNormalized['details'] = []

    if (error.message) {
      details.push(error.message)
    }

    const normalizedDetails = normalizeDetails(errors[0].details)
    if (normalizedDetails) {
      details.concat(normalizedDetails)
    }

    return {
      message: 'Es ist ein unbekannter Fehler aufgetreten.',
      details: details,
    }
  }

  // Is single and known error
  return {
    message: errors[0].message,
    details: normalizeDetails(errors[0].details),
  }
}

function findKnownError(
  errorMessage: string,
  { operation }: { operation?: string } = {}
) {
  // @ts-ignore Can't have type safety here
  const knownError = KNOWN_ERRORS[errorMessage]
  if (!knownError) return

  const knownErrorForOperation = get(knownError, `operations.${operation}`)

  return {
    message: knownErrorForOperation || knownError.message,
    details: normalizeDetails(knownError.details),
  }
}

function normalizeDetails(details: string[] | string | undefined) {
  if (!details) return

  if (!Array.isArray(details)) {
    details = [details]
  }

  return details.filter((detail) => {
    // Check if string is empty
    if (!detail) return false

    return true
  })
}

export function throwFriendlyError(error: unknown) {
  if (!(error instanceof Error)) {
    console.log(error)
    throw new Error('error ist not instance of Error')
  }

  console.error(error)

  const friendlyError = getFriendlyErrors(error)
  if (!friendlyError) return

  throw new FriendlyError(friendlyError, {
    cause: error,
  })
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#es6_custom_error_class
 */
export class FriendlyError extends Error {
  details: string[]

  constructor(
    { message, details }: { message: string; details?: string[] },
    // @ts-ignore TODO: Remove when typescript updated
    errorOptions?: ErrorOptions
  ) {
    // @ts-ignore TODO: Remove when typescript updated
    super(message, errorOptions)

    // https://ashsmith.io/handling-custom-error-classes-in-typescript
    Object.setPrototypeOf(this, FriendlyError.prototype)

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#es6_custom_error_class
    // @ts-ignore No idea how this would work
    if (Error.captureStackTrace) {
      // @ts-ignore No idea how this would work
      Error.captureStackTrace(this, Error)
    }

    this.name = 'FriendlyError'
    // Custom debugging information
    this.details = details ? details : []
  }
}

export function assertFriendlyError(
  error: unknown
): asserts error is FriendlyError {}
