import { createMachine, send } from 'xstate'

import type { Typegen0 } from './PmGroupManagerState.typegen'

export type TState = Typegen0['matchesStates']

export const PmGroupManagerState = createMachine({
  tsTypes: {} as import('./PmGroupManagerState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'SAVE' } | { type: 'RESET' } | { type: 'CANCEL' },

    services: {} as {
      updateProjectGroup: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmGroupManagerState',
  initial: 'default',
  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        SAVE: 'save',
      },
    },

    save: {
      initial: 'saving',
      states: {
        saving: {
          invoke: {
            id: 'update-group',
            src: 'updateProjectGroup',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
            onDone: 'success',
          },
        },
        success: {
          on: {
            RESET: '#PmGroupManagerState.default',
          },
          entry: send('RESET', { delay: 500 }),
        },
        failed: {
          on: {
            CANCEL: '#PmGroupManagerState.default',
          },
        },
      },
    },
  },
})
