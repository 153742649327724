<template>
  <PmDropzonePure v-slot="props" :type="dragAndDropType" @drop="onDrop">
    <div
      class="PmResourcePure"
      :class="{ ...classes.root, ...props.classes }"
      no-data-resource-id="resource.id"
      no-data-resource-type-id="resource.resourceFunction.resourceType.id"
    >
      <div v-if="selectVisible" class="PmResourcePure-select">
        <PmCheckboxPure
          size="small"
          :disabled="!canBeSelected"
          :value="isSelected"
          @input="onSelectInput"
        />
      </div>

      <PmResourceShortPure
        class="PmResourcePure-short"
        :icon="icon"
        :label="shortText"
        :quantity-needed="quantityNeeded"
        :quantity-achieved="quantityAchieved"
        :quantity-unclear="quantityUnclear"
        @click="$emit('showDetails')"
      />

      <div v-if="hasActions" class="PmResourcePure-actions">
        <PmResourceActionPure
          v-show="hasSearchButton"
          class="PmResourcePure-action"
          :icon="ICONS_SMALL.SEARCH"
          @click="$emit('search')"
        />
      </div>

      <div v-if="hasInfo" class="PmResourcePure-info">
        <div
          v-if="isLocked"
          class="PmResourcePure-infoItem"
          title="Zeiten sind als fertig markiert"
        >
          <PmIconPure
            class="PmResourcePure-infoIcon"
            :name="ICONS_SMALL.LOCK"
          />
        </div>
      </div>

      <div class="PmResourcePure-allocations">
        <slot v-bind="{ classes: 'PmResourcePure-allocation' }" />
      </div>

      <div
        v-if="isTimeVisible"
        class="PmResourcePure-time"
        :title="timeDetails"
      >
        {{ time }}
      </div>
    </div>
  </PmDropzonePure>
</template>

<script>
import {
  RESOURCE_TYPE,
  RESOURCE_TYPES,
  DRAG_AND_DROP_TYPE,
} from '@/constants/persoplan'
import { ICONS, ICONS_SMALL } from '@/constants/icons'

import { startEndDate } from '@/utilities/date'
import propValidator from '@/functional/propValidator'

import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'
import PmResourceActionPure from '@/components/persoplan/PmResourceActionPure.vue'
import PmDropzonePure from '@/components/utilities/PmDropzonePure.vue'
import PmResourceShortPure from '@/components/persoplan/PmResource/PmResourceShortPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export const propTypes = {
  type: { allowed: RESOURCE_TYPES },
}

const allowedDragAndDropTypes = [
  DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_ADDRESS,
  DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_VEHICLE,
]

export default {
  name: 'PmResourcePure',
  components: {
    PmCheckboxPure,
    PmResourceActionPure,
    PmDropzonePure,
    PmResourceShortPure,
    PmIconPure,
  },

  props: {
    shortText: { type: String, default: undefined },
    selectVisible: { type: Boolean, default: false },
    canBeSelected: { type: Boolean, default: true },
    isSelected: { type: Boolean, default: false },
    hasSearchButton: { type: Boolean, default: false },
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    icon: { type: String, default: undefined },
    type: {
      type: String,
      required: true,
      validator: (value) => propValidator.oneOf(value, propTypes.type.allowed),
    },
    quantityNeeded: { type: Number, default: 1 },
    quantityAchieved: { type: Number, default: undefined },
    quantityUnclear: { type: Number, default: undefined },
    isLocked: { type: Boolean, default: false },
    isTimeVisible: { type: Boolean, default: true },
  },

  emits: [
    'showDetails',
    'search',
    'createResourceAllocation',
    'select',
    'unselect',
  ],

  setup() {
    return {
      ICONS,
      ICONS_SMALL,
    }
  },

  computed: {
    classes() {
      return {
        root: {
          'is-selected': this.isSelected,
          'is-danger': this.isDanger,
          // '-disabled': this.disabled,
          // '-isDraggedOver': this.isDraggedOver,
          // '-hasMissingResources': this.numberOfMissingResources > 0,
          // '-selectVisible': this.staffRequestMode,
          // 'resource--compact': this.dayWidth * this.numberOfDays < 150
        },
        short: {
          // '-hasQuantity': this.resource.quantity > 1
        },
      }
    },

    time() {
      return startEndDate(this.startDate, this.endDate)
    },

    timeDetails() {
      return startEndDate(this.startDate, this.endDate, {
        showDateAnyway: true,
        showFull: true,
      })
    },

    dragAndDropType() {
      if (this.type === RESOURCE_TYPE.ADDRESS)
        return DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_ADDRESS

      if (this.type === RESOURCE_TYPE.VEHICLE)
        return DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_VEHICLE

      return undefined
    },

    isDanger() {
      if (!this.quantityNeeded) return false
      if (this.quantityAchieved < this.quantityNeeded) return true

      return false
    },

    hasActions() {
      if (this.hasSearchButton) return true
      return false
    },

    hasInfo() {
      if (this.isLocked) return true
      return false
    },
  },

  methods: {
    onDrop(event) {
      if (!allowedDragAndDropTypes.includes(event.type)) {
        throw new Error('Not an allowed drag and drop type')
      }

      this.$emit('createResourceAllocation', {
        id: event.data.id,
        resourceType: event.data.resourceType,
      })
    },

    onSelectInput(selected) {
      selected ? this.$emit('select') : this.$emit('unselect')
    },
  },
}
</script>

<style lang="scss">
.PmResourcePure {
  $block: &;

  display: flex;
  align-items: flex-start;
  margin-bottom: 2px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: constant.$borderRadius-default;
  position: relative;
  font-size: constant.$fontSize-default;
  min-height: 17px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    // Highglihted/Dragged over/Danger styles
    $spread: 3px;

    content: '';
    position: absolute;
    top: -$spread;
    left: -$spread;
    bottom: -$spread;
    right: -$spread;
    background-color: rgba(color.$primary-500, 0.25);
    border: 2px solid color.$primary-500;
    border-radius: constant.$borderRadius-default + $spread;
    opacity: 0;
    transition: opacity constant.$duration-fast;
    pointer-events: none;

    #{$block}.is-draggedOver & {
      outline: 2px solid fuchsia;
    }
  }

  &.is-draggedOver {
    &::before {
      opacity: 1;
    }
  }

  &.is-danger:not(#{$block}.is-draggedOver) {
    &::before {
      opacity: 1;
      background-color: color.$danger-200;
      border-color: color.$danger-500;
    }
  }

  &-select {
    margin-right: 2px;
    position: relative;
  }

  &-short {
    flex-basis: auto;
    flex-shrink: 0;
    justify-self: start;
    align-self: flex-start;
    min-height: 17px;

    #{$block}.is-draggedOver & {
      @include cssVar.define(
        '.PmResourceShortPure',
        'colorBackground',
        color.$primary-500
      );
    }

    /* stylelint-disable */
    .PmAppointmentPure:hover & {
      width: max-content;
    }
    /* stylelint-enable */
  }

  &-actions {
    display: flex;
    position: relative;
    gap: 2px;
    margin-left: 2px;
  }

  &-info {
    display: flex;
    flex: none;
    gap: 2px;
    margin-left: 1px;
  }

  &-infoItem {
    position: relative;
    flex: none;
    width: 14px;
    height: 17px;
  }

  &-allocations {
    position: relative;
    width: 100%;
    padding-left: 4px;
    padding-right: 2px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &-allocation {
    // Empty
  }

  &-time {
    @include mixin.truncateText;

    flex-basis: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-left: 2px;
    margin-right: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    flex-shrink: 0;

    // TODO: Use Container Queries
    // #{$block}--compact & {
    //   flex-shrink: 1;
    // }
  }
}
</style>
