import { type RouteRecordRaw } from 'vue-router'

import { FEATURE_FLAG } from '@/constants/featureFlags'
import { IS_DEVELOPMENT } from './constants/general'
import { LAYOUT } from '@/constants/layout'
import { router } from '@/router'
import store from '@/store'
import { ability } from '@/services/ability'

import PmLogin from '@/views/PmLogin/PmLogin.vue'
import PmStart from '@/views/PmStart/PmStart.vue'
import PmPersoplan from '@/views/PmPersoplan/PmPersoplan.vue'
import PmResourceRequests from '@/views/PmResourceRequests/PmResourceRequests.vue'
import PmAccount from '@/views/PmAccount/PmAccount.vue'
import PmLogout from '@/views/PmLogout/PmLogout.vue'
// import PerformanceTestVue from '@/components/tests/PerformanceTestVue/PerformanceTestVue.vue'
import PmNotFound from '@/views/PmNotFound/PmNotFoundPure.vue'
const PmApolloSandbox = () => import('@/components/PmApolloSandbox.vue')
import PmViewRequests from '@/views/PmViewRequests/PmViewRequests.vue'

const optionalRoutes: RouteRecordRaw[] = []

if (IS_DEVELOPMENT === true) {
  optionalRoutes.push({
    path: '/graphql-docs',
    component: PmApolloSandbox,
  })
}

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: PmLogin,
    meta: {
      layout: LAYOUT.NAKED,
      title: 'Login',
      auth: false,
    },
  },
  {
    path: '/',
    name: 'root',
    component: PmStart,
    meta: {
      layout: LAYOUT.DEFAULT,
    },
  },
  {
    path: '/persoplan',
    name: 'persoplan',
    component: PmPersoplan,
    meta: {
      layout: LAYOUT.DEFAULT,
      title: 'Persoplan',
      role: 'ROLE_PERSOPLAN',
    },
  },
  {
    path: '/anfragen',
    name: 'resource-requests',
    component: PmResourceRequests,
    meta: {
      layout: LAYOUT.DEFAULT,
      title: 'Anfragen',
      role: 'ROLE_PERSOPLAN',
    },
    beforeEnter: () => {
      return ability.can('use', 'resourceRequestOverview')
    },
  },

  {
    path: '/antraege/',
    name: 'requests',
    redirect: {
      name: 'requestsCurrent',
    },
    meta: {
      layout: LAYOUT.DEFAULT,
      title: 'Anträge',
    },
    beforeEnter: () => {
      return ability.can('approve', 'requests')
    },
    children: [
      {
        path: 'aktuell/:type(urlaub|fremddienstleistung)?/:id(\\d+)?',
        name: 'requestsCurrent',
        component: PmViewRequests,
        props: (route) => {
          if (Array.isArray(route.params.id)) return

          return {
            openRequest: {
              type: route.params.type,
              id: parseInt(route.params.id),
            },
          }
        },
      },
      {
        path: 'archiv',
        name: 'requestsArchive',
        component: PmViewRequests,
      },
    ],
  },
  {
    path: '/account',
    name: 'account',
    component: PmAccount,
    meta: {
      layout: LAYOUT.DEFAULT,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: PmLogout,
    meta: {
      layout: LAYOUT.NAKED,
    },
  },
  // {
  //   path: '/performance/:numberOfJobs',
  //   name: 'performance',
  //   component: PerformanceTestVue,
  //   props: true,
  //   meta: {
  //     layout: LAYOUT.NAKED,
  //   },
  // },

  ...optionalRoutes,

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: PmNotFound,
  },
]

// Add all the routes
routes.forEach((route) => router.addRoute(route))

/**
 * Add logic for redirect to request url
 * @see https://stackoverflow.com/a/51034158/7236347
 */
router.beforeEach((to, from, next) => {
  if (to.meta.auth !== false && !store.getters['auth/isLoggedIn']) {
    // console.log('redirect to login')
    // console.log({
    //   needsAuth: to.meta.auth,
    //   isLoggedIn: store.getters['auth/isLoggedIn']
    // })

    let loginPath: string | undefined = to.path || window.location.pathname

    // Dont redirect for certain routes:
    if (loginPath === '/logout') {
      loginPath = undefined
    }

    const query = {
      ...to.query,
      from: loginPath,
    }

    return next({ path: '/login', query: query })
  }

  next()
})
