import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmPollingIndicatorPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmPollingIndicatorPureState = createMachine({
  tsTypes: {} as import('./PmPollingIndicatorPureState.typegen').Typegen0,
  schema: {},

  id: 'PmPollingIndicatorPureState',
  initial: 'initialLoading',

  states: {
    initialLoading: {},

    inactive: {},

    waiting: {
      meta: {
        title: 'Klicke um jetzt die Ansicht zu aktualisieren',
      },
    },

    updating: {},

    updatingFromExternal: {},

    failed: {
      meta: {
        title:
          'Es gab einen Fehler bei der Aktualisierung. Klicke um es erneut zu versuchen',
      },
    },

    offline: {
      meta: {
        title: 'Es gibt keine Verbindung zum Internet',
      },
    },
  },
})

export const states = extractAllStates(PmPollingIndicatorPureState)
