import store from '@/store'

export default (store) => {
  syncAllCssVars(store.state.cssVar.variables)

  store.subscribe((mutation, state) => {
    if (mutation.type === 'cssVar/set') {
      const variable = state.cssVar.variables[mutation.payload.name]

      if (!variable) {
        throw new Error('syncCssVarsToDocument: Variable could not be found')
      }

      applyCssVar(mutation.payload.name, variable)
    }

    if (mutation.type === 'applyPersistedSettings') {
      syncAllCssVars(state.cssVar.variables)
    }
  })

  store.watch(
    (state, getters) => {
      return getters['cssVar/derivedVariables']
    },
    (derivedVariables, oldValue) => {
      /**
       * Instead of comparing the difference between derivedVariables and the old value
       * we just re-apply every variable. Might be good to fix later, if number of derived
       * variables increases
       * @todo: Maybe make comparison beforehand
       */
      oldValue // Reference for eslint
      syncAllDerivedVariables(derivedVariables)
    },
    {
      immediate: true,
    }
  )
}

export function syncAllCssVars(variables) {
  if (!variables) {
    variables = store.state.cssVar.variables
  }

  Object.entries(variables).forEach(([name, variable]) => {
    applyCssVar(name, variable)
  })
}

export function syncAllDerivedVariables(derivedVariables) {
  if (!derivedVariables) {
    derivedVariables = store.getters['cssVar/derivedVariables']
  }

  Object.entries(derivedVariables).forEach(([name, variable]) => {
    applyCssVar(name, variable)
  })
}

function applyCssVar(name, variable) {
  if (variable.value === null || variable.value === undefined) {
    return removeOrApplyFallback(name, variable)
  }

  let value
  if (variable.unit) value = `${variable.value}${variable.unit}`
  else value = variable.value

  document.documentElement.style.setProperty(`--${name}`, value)
}

function removeOrApplyFallback(name, variable) {
  const hasFallback = variable.fallback ? true : false

  if (hasFallback) {
    document.documentElement.style.setProperty(`--${name}`, variable.fallback)
  } else {
    document.documentElement.style.removeProperty(`--${name}`)
  }
}

// function getDerivedVariablesFor(name, derivedVariables) {
//   const result = Object.entries(derivedVariables).reduce(
//     (result, [key, value]) => {
//       if (value.derivedFrom !== name) {
//         return result
//       }

//       result[key] = value
//       return result
//     },
//     {}
//   )

//   return result
// }
