import { setup, fromPromise, assertEvent, assign } from 'xstate5'
import { assertFriendlyError } from '@/functional/friendlyErrors'
import type {
  ApproveRequestVariables,
  RejectRequestVariables,
} from '@/components/PmRequestOverview/PmRequestOverviewDetails.vue'
import type { Emits as EmitsDialogRequestApprovePure } from '@/components/PmRequestOverview/PmDialogRequestApprove/PmDialogRequestApprovePure.vue'
import type { Emits as EmitsDialogRequestRejectPure } from '@/components/PmRequestOverview/PmDialogRequestReject/PmDialogRequestRejectPure.vue'
import type { LeaveRequestApprovalType } from 'generated/graphql'

export const PmRequestOverviewDetailsState = setup({
  types: {
    events: {} as
      | {
          type: 'approve'
          variables: {
            id: number
            type: 'leaveRequest' | 'externalServiceRequest'
            approvalType?: `${LeaveRequestApprovalType}` | null
          }
        }
      | {
          type: 'reject'
          variables: {
            id: number
            type: 'leaveRequest' | 'externalServiceRequest'
          }
        }
      | { type: 'cancel' }
      | { type: 'close' }
      | {
          type: 'confirmApprove'
          variables: EmitsDialogRequestApprovePure['submit']
        }
      | {
          type: 'confirmReject'
          variables: EmitsDialogRequestRejectPure['submit']
        },

    meta: {} as {
      error?: boolean
      message?: string
    },

    context: {} as {
      requestId?: number
      requestType?: 'leaveRequest' | 'externalServiceRequest'
      approvalType?: `${LeaveRequestApprovalType}` | null
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  actions: {
    resetContext: assign({
      requestId: undefined,
      requestType: undefined,
      error: undefined,
      errorDetails: undefined,
    }),

    showApproveSuccessNotification: () => {
      throw new Error('showSuccessNotification not implemented')
    },

    showRejectSuccessNotification: () => {
      throw new Error('showSuccessNotification not implemented')
    },
  },

  actors: {
    approveRequest: fromPromise<void, { variables: ApproveRequestVariables }>(
      async () => {
        throw new Error('approveRequest not implemented')
      }
    ),

    rejectRequest: fromPromise<void, { variables: RejectRequestVariables }>(
      async () => {
        throw new Error('rejectRequest not implemented')
      }
    ),
  },
}).createMachine({
  id: 'PmRequestOverviewDetailsState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      entry: 'resetContext',

      on: {
        approve: 'approve',
        reject: 'reject',
      },
    },

    approve: {
      initial: 'waitForConfirm',
      entry: assign(({ event }) => {
        assertEvent(event, 'approve')
        return {
          requestId: event.variables.id,
          requestType: event.variables.type,
          approvalType: event.variables.approvalType,
        }
      }),

      states: {
        waitForConfirm: {
          on: {
            cancel: '#PmRequestOverviewDetailsState.default',
            confirmApprove: 'saving',
          },
        },

        saving: {
          invoke: {
            id: 'approveRequest',
            src: 'approveRequest',
            input: ({ context, event }) => {
              assertEvent(event, 'confirmApprove')
              if (!context.requestId || !context.requestType) {
                throw new Error('context is not filled')
              }

              return {
                variables: {
                  id: context.requestId,
                  type: context.requestType,
                  formData: event.variables,
                },
              }
            },
            onDone: {
              target: 'success',
            },
            onError: {
              target: 'error',
              actions: ({ context, event }) => {
                assertFriendlyError(event.error)
                context.error = event.error.message
                context.errorDetails = event.error.details
              },
            },
          },
        },

        success: {
          entry: 'showApproveSuccessNotification',
          always: '#PmRequestOverviewDetailsState.default',
        },

        error: {
          on: {
            cancel: '#PmRequestOverviewDetailsState.default',
          },
        },
      },
    },

    reject: {
      initial: 'waitForConfirm',
      entry: assign(({ event }) => {
        assertEvent(event, 'reject')
        return {
          requestId: event.variables.id,
          requestType: event.variables.type,
        }
      }),

      states: {
        waitForConfirm: {
          on: {
            cancel: '#PmRequestOverviewDetailsState.default',
            confirmReject: 'saving',
          },
        },

        saving: {
          invoke: {
            id: 'rejectRequest',
            src: 'rejectRequest',
            input: ({ context, event }) => {
              assertEvent(event, 'confirmReject')
              if (!context.requestId || !context.requestType) {
                throw new Error('context is not filled')
              }

              return {
                variables: {
                  id: context.requestId,
                  type: context.requestType,
                  formData: event.variables,
                },
              }
            },
            onDone: {
              target: 'success',
            },
            onError: {
              target: 'error',
              actions: ({ context, event }) => {
                assertFriendlyError(event.error)
                context.error = event.error.message
                context.errorDetails = event.error.details
              },
            },
          },
        },

        success: {
          entry: 'showRejectSuccessNotification',
          always: '#PmRequestOverviewDetailsState.default',
        },

        error: {
          on: {
            cancel: '#PmRequestOverviewDetailsState.default',
          },
        },
      },
    },
  },
})
