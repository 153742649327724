/**
 * Composable for using native html form validation
 * @see https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation#implementing_a_customized_error_message
 */
import { computed, type Ref, ref, isRef } from 'vue'
import type { Entries } from 'type-fest'

export type ValidationStateKey = keyof ValidityState
export type ValidationMessages = Partial<Record<ValidationStateKey, string>>

const validationMessagesBase: ValidationMessages = {
  // patternMismatch: 'Bitte halten Sie sich an das vorgegebene Format.',
  // tooShort: 'Bitte geben Sie mindestens {minlength} Zeichen ein.',
  // valueMissing: 'Bitte füllen Sie dieses Feld aus.',
}

const validationMessagesNumber: ValidationMessages = {
  // badInput: 'Bitte geben Sie eine Nummer ein.',
}

const validationMessagesEmail: ValidationMessages = {
  // typeMismatch: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
}

const validationMessagesPhone: ValidationMessages = {
  // typeMismatch: 'Bitte geben Sie eine gültige Telefonnummer ein.',
  // patternMismatch:
  //   'Bitte geben Sie eine gültige Telefonnummer ein. Sie können Ziffern sowie die Zeichen +()/ verwenden.',
}

const validationMessagesPassword: ValidationMessages = {
  // tooShort:
  //   'Ihr Passwort ist nicht sicher. Bitte wählen Sie ein Passwort mit mindestens {minlength} Zeichen',
}

const validationMessagesSelect: ValidationMessages = {
  // valueMissing: 'Bitte wählen Sie einen Eintrag in der Liste.',
}

const validationMessagesFile: ValidationMessages = {
  // valueMissing: 'Bitte wählen Sie eine Datei aus.',
}

const validationMessagesRadio: ValidationMessages = {}

type Type =
  | 'text'
  | 'textarea'
  | 'number'
  | 'tel'
  | 'email'
  | 'password'
  | 'select'
  | 'file'
  | 'radio'

type Options = {
  elInput: Ref<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | undefined
  >
  type?: Ref<Type | undefined> | Type
  validationMessages?: Ref<ValidationMessages | undefined>
  minlength?: Ref<number | undefined>
  pattern?: Ref<string> | string
}

// https://regex101.com/r/6tWHQ1/3
const regexPhone = '[0-9/+()\\s]+'

export const useFormValidation = (options: Options) => {
  const hasFirstValidationHappendAlready = ref(false)
  const isInvalid = ref(false)
  const invalidMessage = ref<string>()

  const type = computed(() => {
    if (isRef(options.type)) return options.type.value
    else return options.type
  })

  const pattern = computed(() => {
    if (type.value === 'tel') {
      return regexPhone
    }

    return undefined
  })

  const validationMessagesNormalized = computed(() => {
    // Get default validtion messages for differen types
    let result: ValidationMessages = { ...validationMessagesBase }

    if (type.value === 'email') {
      result = { ...result, ...validationMessagesEmail }
    }

    if (type.value === 'number') {
      result = { ...result, ...validationMessagesNumber }
    }

    if (type.value === 'tel') {
      result = { ...result, ...validationMessagesPhone }
    }

    if (type.value === 'password') {
      result = { ...result, ...validationMessagesPassword }
    }

    if (type.value === 'select') {
      result = { ...result, ...validationMessagesSelect }
    }

    if (type.value === 'file') {
      result = { ...result, ...validationMessagesFile }
    }

    if (type.value === 'radio') {
      result = { ...result, ...validationMessagesRadio }
    }

    // Merge with prop validation message
    result = {
      ...result,
      ...options.validationMessages?.value,
    }

    const typedResultEntries = Object.entries(result) as Entries<typeof result>
    typedResultEntries.forEach(([key, value]) => {
      result[key] = replaceStringVariable(value)
    })

    return result
  })

  function replaceStringVariable(value: string | undefined) {
    if (!value) return value

    let result = value

    if (options.minlength?.value) {
      result = result.replaceAll(
        '{minlength}',
        options.minlength.value.toString()
      )
    }

    return result
  }

  function showInvalidMessage() {
    if (!options.elInput?.value) throw new Error('elInput is undefined')

    isInvalid.value = true
    hasFirstValidationHappendAlready.value = true

    /**
     * Check for custom validation messages to display
     */
    const invalidTypes: Array<keyof ValidityState> = []

    let key: keyof typeof options.elInput.value.validity
    for (key in options.elInput.value.validity) {
      const isInvalid = options.elInput.value.validity[key]

      if (isInvalid) {
        invalidTypes.push(key)
      }
    }

    const useCustomMessageForKey = invalidTypes.find((key) => {
      if (!validationMessagesNormalized.value) return

      const hasCustomMessage = Object.prototype.hasOwnProperty.call(
        validationMessagesNormalized.value,
        key
      )

      return hasCustomMessage
    })

    let validationMessage = options.elInput.value.validationMessage

    if (useCustomMessageForKey) {
      const customValidationMessage =
        validationMessagesNormalized.value?.[useCustomMessageForKey]

      if (customValidationMessage) {
        // setCustomValidity needs to be removed manually
        // this.$refs.input.setCustomValidity(validationMessage)
        validationMessage = customValidationMessage
      }
    }

    invalidMessage.value = validationMessage
  }

  function checkValidity() {
    if (!options.elInput?.value) throw new Error('elInput is undefined')

    if (options.elInput.value.validity.valid) {
      isInvalid.value = false
      invalidMessage.value = undefined
    } else {
      if (!hasFirstValidationHappendAlready.value) return
      showInvalidMessage()
    }
  }

  function onInvalid(event: Event) {
    event.preventDefault()
    showInvalidMessage()
  }

  return {
    isInvalid,
    invalidMessage,
    pattern,
    checkValidity,
    showInvalidMessage,
    onInvalid,
  }
}
