<template>
  <li
    ref="elRoot"
    class="PmSearchResultPure"
    :class="classes.root"
    tabindex="1"
    @click="$emit('click')"
  >
    <div class="PmSearchResultPure-content">
      <header class="PmSearchResultPure-header">
        <div class="PmSearchResultPure-title">{{ title }}</div>

        <div
          v-if="isCollectedWithAllJobsOfProject"
          :title="`Job wird zusammen mit anderen Jobs des Projekts ${projectNumber} zusammengefasst angezeigt`"
          class="PmSearchResultPure-icon"
        >
          <PmIconPure :name="ICONS_SMALL.COLLECTION" />
        </div>
      </header>

      <div class="PmSearchResultPure-details">
        <template v-if="project">
          Projekt: {{ project }}
          <br />
        </template>

        <template v-if="projectLeader">
          Leiter: {{ projectLeader }}
          <br />
        </template>
        Zeitraum:
        {{ formatWithLocale(startDate, FORMAT_DATE_DEFAULT) }} —
        {{ formatWithLocale(endDate, FORMAT_DATE_DEFAULT) }}
      </div>
    </div>

    <div class="PmSearchResultPure-loader" :class="{ 'is-visible': loading }">
      <PmLoadingPure />
    </div>

    <PmAppNotificationPure
      v-if="warning"
      variant="warning"
      class="PmSearchResultPure-warning"
    >
      {{ warning }}
    </PmAppNotificationPure>
  </li>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount } from 'vue'

const COMPONENT_NAME = 'ExampleComponentBarebones'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { differenceInCalendarDays } from 'date-fns'

import { ICONS_SMALL } from '@/constants/icons'
import { FORMAT_DATE_DEFAULT } from '@/constants/persoplan'
import { formatWithLocale } from '@/utilities/date'

import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

import type { Nilable } from '@/types/misc'

// import hasStatus from '@/mixins/hasStatus'

export interface Props {
  title: string
  startDate: Date
  endDate: Date
  project?: string
  projectNumber?: string
  projectLeader?: Nilable<string>
  loading?: boolean
  warning?: string
  isActive?: boolean
  now?: Date
  isCollectedWithAllJobsOfProject?: Nilable<boolean>
}

const props = withDefaults(defineProps<Props>(), {
  now: () => new Date(),
})

const emit = defineEmits<{
  (event: 'click'): void
}>()

const elRoot = ref<HTMLLIElement>()

const daysToNow = computed(() => {
  return Math.abs(differenceInCalendarDays(props.startDate, props.now))
})

const classes = computed(() => {
  return {
    root: {
      // ...hasStatus_classes,
      'is-focus': props.isActive,
      'is-quiet': daysToNow.value > 365, // TODO: This should be a prop and be set where it's used maybe?
    },
  }
})

watch(() => props.isActive, maybeSetFocus)
onMounted(maybeSetFocus)

// this.$eventHub.$on('search.resetResultsStatus', this.reset) // TODO
onBeforeUnmount(() => {
  // this.$eventHub.$off('search.resetResultsStatus', this.reset) // TODO
})

function maybeSetFocus() {
  if (!props.isActive) return
  if (!elRoot.value) return

  elRoot.value.scrollIntoView({
    // behavior: 'smooth',
    block: 'nearest',
    // inline: 'nearest'
  })
}
</script>

<style lang="scss">
.PmSearchResultPure {
  $block: &;
  $name: function.getBlockName($block);

  @include cssVar.define($block, 'color', color.$gray-500);
  @include mixin.status(cssVar.name($block, 'color'));

  list-style-type: none;
  margin: 0;
  padding: 8px;
  border: 2px solid transparent;
  border-radius: constant.$borderRadius-default;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'content loader'
    'warning warning';

  &:hover,
  &.is-hover {
    opacity: 1;
    background-color: rgba(color.$key, 0.2);
  }

  &:focus,
  &.is-focus {
    outline: none;
    opacity: 1;
    border-color: color.$key;
    background-color: rgba(color.$key, 0.2);
  }

  &.is-quiet {
    opacity: 0.5;
  }

  &-content {
    grid-area: content;
  }

  &-header {
    display: flex;
    align-items: center;
    gap: 4px;
    min-height: 20px;
    margin-bottom: 0.5em;
  }

  &-icon {
    width: 20px;
    height: 20px;
  }

  &-title {
    font-size: constant.$fontSize-large;
    font-weight: 600;
    display: flex;
    align-items: baseline;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: var(--#{$name}-color);
      margin-right: 5px;
    }
  }

  &-warning {
    grid-area: warning;
    margin-top: var(--space-gutters);
  }

  &-loader {
    grid-area: loader;
    margin-right: 10px;
    margin-left: 10px;
    visibility: hidden;
    align-self: center;
    width: 20px;
    height: 20px;

    &.is-visible {
      visibility: visible;
    }
  }
}
</style>
