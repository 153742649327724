<template>
  <PmNotificationResourceRequestFeedbackPure
    v-bind="{ ...$attrs, ...props }"
    @open-details="openDetails"
    @jump="jumpToJobOrProjectinCalendar"
  />
</template>

<script setup lang="ts">
import { useStore } from 'vuex'

import { DATA_MODAL_TYPE } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'
import EventHub from '@/eventHub'
import PmNotificationResourceRequestFeedbackPure, {
  type Props as PropsNotificationResourceRequestFeedbackPure,
} from '@/components/PmNotification/PmNotificationResourceRequestFeedback/PmNotificationResourceRequestFeedbackPure.vue'
import { useJumpTargets } from '@/pinia/jumpTargets'
import type { CalendarItemType } from '@/constants/persoplan'

export interface Props extends PropsNotificationResourceRequestFeedbackPure {
  resourceAllocationIds: number[]
  calendarItem?: {
    id: number
    type: Extract<CalendarItemType, 'job' | 'project'>
    startDate: Date
    endDate: Date
  }
}

const props = withDefaults(defineProps<Props>(), {})

const store = useStore()
const jumpTargets = useJumpTargets()

function openDetails() {
  const resourceAllocationId = props.resourceAllocationIds[0]
  if (!resourceAllocationId)
    throw new Error('resourceAllocationId is undefined')

  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.RESOURCE_REQUEST,
    id: resourceAllocationId,
  })
}

function jumpToJobOrProjectinCalendar() {
  if (!props.calendarItem) {
    throw new Error('calendarItem is undefined, jump is not possilbe')
  }

  // Set highlight
  store.commit('persoplan/clearHighlightedItems')
  store.commit('persoplan/addToHighlightedItems', {
    id: props.calendarItem.id,
    type: props.calendarItem.type,
  })

  jumpTargets.jumpTo(
    {
      id: props.calendarItem.id,
      type: 'AppointmentsContainer',
      startDate: props.calendarItem.startDate,
      endDate: props.calendarItem.endDate,
    },
    {
      changeDate: true,
    }
  )
}
</script>
