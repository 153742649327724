import { computed, reactive, watch, ref } from 'vue'

interface IParams<Type extends string> {
  loaders: readonly Type[]
  autoStart?: boolean
  onLoadingFinished?: () => void
}

/**
 * This example works with TypeScript 5, probably need to update and update this code
 * @todo Update code after upgrade to TypeScript 5
 *
 */
// function blupp<Type extends string>(type: Type) {
//   const bla = {} as Record<Type, boolean>

//   if ('jkl' in bla) {
//     bla['jkl'] = false
//   }

//   return bla
// }

// const test = blupp('asd')

// if (test.asd === true) {
//   console.log('jo')
// }

export function useLoading<Type extends string>(options: IParams<Type>) {
  const { autoStart = false } = options

  const loadingStates = ref({} as Record<Type, boolean>)

  // Set initial values for all loaders
  Object.values(options.loaders).forEach((loader) => {
    // @ts-expect-error This error appeared with the update to vue 3.3, don't know how to fix
    loadingStates.value[loader] = autoStart ? true : undefined
  })

  const checkKey = (id: Type) => {
    const hasKey = Object.prototype.hasOwnProperty.call(loadingStates.value, id)
    if (!hasKey) {
      throw new Error(`loader ${id} not found`)
    }
  }

  const startLoading = (id: Type) => {
    checkKey(id)
    // @ts-expect-error This error appeared with the update to vue 3.3, don't know how to fix
    loadingStates.value[id] = true
  }

  const endLoading = (id: Type) => {
    checkKey(id)
    // @ts-expect-error This error appeared with the update to vue 3.3, don't know how to fix
    loadingStates.value[id] = false
  }

  const setLoading = (id: Type, value: boolean) => {
    checkKey(id)
    // @ts-expect-error This error appeared with the update to vue 3.3, don't know how to fix
    loadingStates.value[id] = value
  }

  const hasLoaders = computed(() => {
    // @ts-expect-error This error appeared with the update to vue 3.3, don't know how to fix
    const numberOfLoaders = Object.keys(loadingStates.value).length

    if (!Number.isInteger(numberOfLoaders)) {
      throw new Error('numberOfLoaders need to be an Int')
    }

    return numberOfLoaders > 0
  })

  const numberOfLoading = computed(() => {
    // @ts-expect-error This error appeared with the update to vue 3.3, don't know how to fix
    return Object.values(loadingStates.value).filter((state) => {
      return state === true
    }).length
  })

  const isLoadingAny = computed(() => {
    if (!hasLoaders.value) return false
    return numberOfLoading.value > 0
  })

  watch(
    () => isLoadingAny.value,
    () => {
      if (isLoadingAny.value === true) return
      if (!options.onLoadingFinished) return

      options.onLoadingFinished()
    },
    {
      immediate: true,
    }
  )

  return {
    isLoadingAny,
    startLoading,
    endLoading,
    setLoading,
    isLoading: loadingStates,
    numberOfLoading,
  }
}
