import { ref, watch } from 'vue'
import { defineStore } from 'pinia'

import type { ResourceType } from '@/constants/persoplan'

interface TimelineBase {
  type: ResourceType
  id: number
}

interface Timeline extends TimelineBase {
  scrollIntoView: () => void
}

export const useResourceTimelines = defineStore('resourceTimelines', () => {
  const timelines = new Map<string, Timeline>()
  const highlightedTimeline = ref<TimelineBase>()
  let unhighlightTimeout: number | undefined

  /**
   * Unhighlight highlighted timeline after a couple of seconds
   */
  watch(highlightedTimeline, async () => {
    if (!highlightedTimeline.value?.id) return

    if (unhighlightTimeout) {
      clearInterval(unhighlightTimeout)
      unhighlightTimeout = undefined
    }

    unhighlightTimeout = window.setTimeout(clearHighlightedTimeline, 5_000)
  })

  function clearHighlightedTimeline() {
    highlightedTimeline.value = undefined
    unhighlightTimeout = undefined
  }

  function getMapId(timeline: TimelineBase) {
    // Map freelancer to address:
    const type = timeline.type === 'freelancer' ? 'address' : timeline.type

    return `${type}.${timeline.id}`
  }

  function register(timeline: Timeline) {
    timelines.set(getMapId(timeline), timeline)
    // console.log('add', timeline)
  }

  function unregister(timeline: TimelineBase) {
    timelines.delete(getMapId(timeline))
    // console.log('remove', timeline.id, 'size:', timelines.size)
  }

  function isTimelineVisible(timeline: TimelineBase) {
    return timelines.has(getMapId(timeline))
  }

  function scrollIntoView(timeline: TimelineBase) {
    const timelineToJumpTo = timelines.get(getMapId(timeline))

    if (!timelineToJumpTo) {
      // TODO: Maybe throw an error/notification
      return
    }

    highlightedTimeline.value = {
      id: timeline.id,
      type: timeline.type,
    }

    timelineToJumpTo.scrollIntoView()
  }

  return {
    register,
    unregister,
    isTimelineVisible,
    scrollIntoView,
    highlightedTimeline,
  }
})
