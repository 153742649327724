import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmResourceAllocationEditPureState.typegen'

export type State = Typegen0['matchesStates']

export const PmResourceAllocationEditPureState = createMachine({
  tsTypes: {} as import('./PmResourceAllocationEditPureState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'DELETE' } | { type: 'CONFIRM' } | { type: 'CANCEL' },
  },

  id: 'PmResourceAllocationEditPureState',
  initial: 'update',
  states: {
    default: {},

    create: {
      initial: 'default',

      states: {
        default: {},

        save: {
          initial: 'saving',

          states: {
            saving: {},

            failed: {
              meta: {
                error: true,
                errorMessage:
                  'Es gab einen unbekannten Fehler beim Erstellen der Zuordnung',
              },
            },
          },
        },

        vehicleAllocation: {
          initial: 'pickResource',
          states: {
            pickResource: {},
          },
        },

        driverAllocation: {
          initial: 'pickResource',
          states: {
            pickResource: {},
          },
        },
      },
    },

    update: {
      initial: 'default',

      states: {
        default: {
          on: {
            DELETE: 'delete',
          },
        },

        delete: {
          initial: 'askForConfirmation',

          states: {
            askForConfirmation: {
              on: {
                CONFIRM: 'deleting',
                CANCEL: '#PmResourceAllocationEditPureState.update.default',
              },

              entry: 'userIntentsToDelete',
              exit: 'userDoesNotIntentToDelete',
            },

            deleting: {
              entry: 'emitDelete',
            },

            failed: {
              on: {
                CONFIRM: 'deleting',
                CANCEL: '#PmResourceAllocationEditPureState.update.default',
              },

              meta: {
                error: true,
                errorMessage:
                  'Es gab einen unbekannten Fehler beim Löschen der Zuordnung',
              },
            },
          },
        },

        vehicleAllocation: {
          initial: 'pickResource',
          states: {
            pickResource: {},
          },
        },

        driverAllocation: {
          initial: 'pickResource',
          states: {
            pickResource: {},
          },
        },

        save: {
          initial: 'saving',

          states: {
            saving: {},

            failed: {
              meta: {
                error: true,
                errorMessage:
                  'Es gab einen unbekannten Fehler beim Speichern der Zuordnung',
              },
            },
          },
        },
      },
    },
  },
})

export const states = extractAllStates(PmResourceAllocationEditPureState)
