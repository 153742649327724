<template>
  <div>
    <GlobalEvents
      @keydown.alt.v.prevent.stop="shortcutShowPreviousWeek"
      @keydown.alt.n.prevent.stop="shortcutShowNextWeek"
      @keydown.alt.p.prevent.stop="shortcutToggleEditMode"
    />

    <PmSettingsPure
      :start-date="store.state.persoplan.visibleStartDate"
      :end-date="store.state.persoplan.visibleEndDate"
      :staff-request-mode-active="persoplanState.state.value.matches('edit')"
      :views="normalizedViews"
      :selected-view-id="store.state.view.id"
      :staff-request-disabled="!can('edit', 'persoplan')"
      v-bind="separateAttrs($attrs).attributes"
      :is-default-view="store.getters['view/isDefault']"
      :is-saved-view="store.getters['view/isSavedView']"
      :is-view-changed="store.getters['view/isChanged']"
      :user-can-create-public-view="can('create', 'PublicViews')"
      :is-public-view="isPublicView"
      @reset-view="resetView"
      @revert-view="revertView"
      @select-view="selectView"
      @create-view="isViewCreateVisible = true"
      @save-view="isViewUpdateVisible = true"
      @duplicate-view="isViewDuplicateVisible = true"
      @delete-view="isViewDeleteVisible = true"
      @toggle-edit-mode="onToggleEditMode"
      @show-previous-week="showPreviousWeek"
      @show-next-week="showNextWeek"
      @open-view-editor="store.commit('persoplan/showViewEditor')"
      @open-search="emit('openSearch')"
      @open-date-controller="emit('openDateController')"
      @create-resource-state-freelancer="emit('createResourceStateFreelancer')"
    />

    <portal to="modal">
      <PmViewCreate
        v-if="isViewCreateVisible"
        @close="isViewCreateVisible = false"
      />

      <PmViewUpdate
        v-if="isViewUpdateVisible"
        @close="isViewUpdateVisible = false"
      />

      <PmViewDuplicate
        v-if="isViewDuplicateVisible"
        @close="isViewDuplicateVisible = false"
      />

      <PmViewDelete
        v-if="isViewDeleteVisible"
        @close="isViewDeleteVisible = false"
      />
    </portal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSettings'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { addDays, subDays } from 'date-fns'
import { GlobalEvents } from 'vue-global-events'
import { useStore } from 'vuex'
import { useQuery } from '@vue/apollo-composable'

import type { Icon } from '@/constants/icons'

import { separateAttrs } from '@/utilities/misc'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import { useAppAbility } from '@/composition/useAppAbility'

import PmSettingsPure from '@/components/persoplan/PmSettings/PmSettingsPure.vue'
import PmViewCreate from '@/components/persoplan/PmViewCreate/PmViewCreate.vue'
import PmViewUpdate from '@/components/persoplan/PmViewUpdate/PmViewUpdate.vue'
import PmViewDuplicate from '@/components/persoplan/PmViewDuplicate/PmViewDuplicate.vue'
import PmViewDelete from '@/components/persoplan/PmViewDelete/PmViewDelete.vue'

import { ViewsDocument, CurrentUserDocument } from '@/../generated/graphql'

const emit = defineEmits<{
  (event: 'openSearch'): void
  (event: 'openDateController'): void
  (event: 'createResourceStateFreelancer'): void
}>()

const persoplanState = injectStrict(persoplanStateKey)
const store = useStore()
const { can } = useAppAbility()

const viewsQuery = useQuery(ViewsDocument)
const views = computed(() => viewsQuery.result.value?.views)

const userQuery = useQuery(CurrentUserDocument)
const user = computed(() => userQuery.result.value?.user)

const isViewCreateVisible = ref(false)
const isViewUpdateVisible = ref(false)
const isViewDuplicateVisible = ref(false)
const isViewDeleteVisible = ref(false)

const normalizedViews = computed(() => {
  if (!views.value) return

  interface Item {
    id: number
    label: string
    icon: Icon | null
    iconTitle: string | null
  }

  const result: Item[] = []

  views.value.forEach((view) => {
    if (!view) return

    result.push({
      id: view.id,
      label: view.title,
      icon: view.public ? 'public' : null,
      iconTitle: view.public ? 'Öffentlich' : null,
    })
  })

  return result
})

const isPublicView = computed(() => {
  if (!views.value?.length) return

  const selectedView = views.value.find((view) => {
    if (!view) return false
    return view.id === store.state.view.id
  })

  if (!selectedView) return
  return selectedView.public === true
})

function resetView() {
  store.commit('view/reset')
}

function revertView() {
  store.commit('view/revert')
}

function selectView(viewId: number) {
  if (!views.value) return
  if (!viewId) {
    store.commit('view/reset')
    return
  }

  const view = views.value.find((view) => view?.id === viewId)
  if (!view) return

  store.commit('view/setView', {
    id: view.id,
    title: view.title,
    query: view.query,
  })
}

/**
 * Check if a saved view in persisted settings is deleted
 * and reset the view to defaults if that's the case
 */

watch(views, () => checkForOrphanedView)

function checkForOrphanedView() {
  // There is no saved view selected, so we do nothing
  if (!store.state.view.id) {
    return
  }

  const selectedViewIdExists = views.value?.find((view) => {
    return view?.id === store.state.view.id
  })
    ? true
    : false

  if (selectedViewIdExists) return

  // Selected view does not exist, reset to default view
  store.commit('view/reset')
}

function onToggleEditMode() {
  if (persoplanState.state.value.matches('view')) {
    persoplanState.service.value.send('START_EDIT')
    return
  }

  if (persoplanState.state.value.matches('edit')) {
    persoplanState.service.value.send('FINISH_EDIT')
    return
  }
}

function showPreviousWeek() {
  const dates = {
    startDate: subDays(store.state.persoplan.visibleStartDate, 7),
    endDate: subDays(store.state.persoplan.visibleEndDate, 7),
  }

  store.commit('persoplan/setVisibleDates', dates)
}

function showNextWeek() {
  const dates = {
    startDate: addDays(store.state.persoplan.visibleStartDate, 7),
    endDate: addDays(store.state.persoplan.visibleEndDate, 7),
  }

  store.commit('persoplan/setVisibleDates', dates)
}

function shortcutToggleEditMode(event: KeyboardEvent) {
  if (event.repeat) return
  onToggleEditMode()
}

function shortcutShowPreviousWeek(event: KeyboardEvent) {
  if (event.repeat) return
  showPreviousWeek()
}

function shortcutShowNextWeek(event: KeyboardEvent) {
  if (event.repeat) return
  showNextWeek()
}
</script>
