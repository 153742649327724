<template>
  <PmGeneralPageContainerPure>
    <PmGeneralPagePure :title="`Hallo ${currentUser?.firstName}`">
      <PmMyRequests v-if="can('create', 'requests')" />
    </PmGeneralPagePure>
  </PmGeneralPageContainerPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'

import PmGeneralPageContainerPure from '@/components/PmGeneralPage/PmGeneralPageContainerPure.vue'
import PmGeneralPagePure from '@/components/PmGeneralPage/PmGeneralPagePure.vue'
import PmMyRequests from '@/components/PmMyRequests/PmMyRequests.vue'
import { useAppAbility } from '@/composition/useAppAbility'
import { CurrentUserDocument } from '@/../generated/graphql'

const { can } = useAppAbility()

const currentUserQuery = useQuery(CurrentUserDocument)
const currentUser = computed(() => currentUserQuery.result.value?.user)
</script>
