<template>
  <PmResourceRequestOverviewDetailsPure
    :number-of-selected="resourceRequests.size"
    @confirm="action = 'confirm'"
    @reserve="action = 'reserve'"
    @decline="action = 'decline'"
  >
    <PmDataModalResourceRequest
      v-if="activeResourceRequest && isSingleItemSelected"
      :id="activeResourceRequest"
    />

    <template v-if="isMultipleItemsSelected">
      Mehrere Einträge ausgewählt
    </template>
  </PmResourceRequestOverviewDetailsPure>

  <PmResourceRequestUpdate
    v-if="action"
    :action="action"
    :resource-allocation-ids="Array.from(resourceRequests.values())"
    @close="action = null"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import PmResourceRequestOverviewDetailsPure from '@/components/PmResourceRequestOverviewDetails/PmResourceRequestOverviewDetailsPure.vue'
import PmDataModalResourceRequest from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmDataModalResourceRequest.vue'
import PmResourceRequestUpdate from '@/components/PmResourceRequestUpdate/PmResourceRequestUpdate.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  resourceRequests: Set<number>
  activeResourceRequest?: Nilable<number>
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{}>()

const isSingleItemSelected = computed(() => {
  if (!props.activeResourceRequest) return false
  if (props.resourceRequests.size > 1) return false

  return true
})

const isMultipleItemsSelected = computed(() => {
  return props.resourceRequests.size > 1
})

const action = ref<'confirm' | 'reserve' | 'decline' | null>()
</script>

<style lang="scss">
.PmResourceRequestOverviewDetails {
  $block: &;
}
</style>
