import { type Ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

import { useJumpTargets, type JumpTargetType } from '@/pinia/jumpTargets'
import { waitUntilInViewport } from '@/utilities/viewport'
// import cssVar from '@/utilities/cssVar'

type Options = {
  id: number | string
  type: JumpTargetType
  el: Ref<HTMLElement | undefined>
  align?: {
    horizontal?: 'left' | 'center'
    vertical?: 'top' | 'center'
  }
  dontScrollIfVisible?: {
    horizontal?: boolean
  }
  /**
   * If you don't want to register the jump target when mounted,
   * this is useful for example if you need to wait for packing to be finsiehd
   * */
  registerOnMounted?: boolean
  hooks?: {
    whenInViewport?: () => void
  }
}

export function useJumpTarget(options: Options) {
  const store = useStore()
  const jumpTargets = useJumpTargets()

  function register() {
    jumpTargets.register({
      type: options.type,
      id: options.id,
      scrollIntoView: jump,
    })
  }

  function unregister() {
    jumpTargets.unregister({ type: options.type, id: options.id })
  }

  onMounted(() => {
    if (options.registerOnMounted === false) return

    register()
  })

  onBeforeUnmount(unregister)

  function getScrollX(rect: DOMRect, options: Options) {
    if (!document.scrollingElement) return

    const alignHorizontal = options?.align?.horizontal ?? 'center'
    const dontScrollIfVisible = options.dontScrollIfVisible?.horizontal ?? false
    const padding = 24

    const sidebarLeftWidthWithVisibility = store.getters['cssVar/get'](
      'sidebarLeftWidthWithVisibility'
    ).value

    const calendarWidth =
      document.scrollingElement.clientWidth - sidebarLeftWidthWithVisibility

    const viewportWidth = document.scrollingElement.clientWidth

    let scrollX: number | undefined = document.scrollingElement.scrollLeft

    // Check if element is in viewport and retrun if keepHorizontalIfVisibleis true
    if (dontScrollIfVisible) {
      const boundingViewport = {
        x: sidebarLeftWidthWithVisibility,
        width: viewportWidth,
      }

      const isLeftOfSidebar = rect.x > boundingViewport.x
      const minOverlap = 60
      const isInViewport = rect.x + minOverlap < boundingViewport.width

      if (isLeftOfSidebar && isInViewport) {
        return scrollX
      }
    }

    const scrollXAlignLeft =
      rect.x +
      document.scrollingElement.scrollLeft -
      sidebarLeftWidthWithVisibility

    if (alignHorizontal === 'left') {
      scrollX = scrollXAlignLeft
    }

    if (alignHorizontal === 'center') {
      scrollX = scrollXAlignLeft

      // Check if width is larger than viewport and jump to start of element in this case
      const targetIsWiderThanViewport = rect.width > calendarWidth

      if (targetIsWiderThanViewport) {
        scrollX -= padding
      } else {
        scrollX -= calendarWidth / 2 - rect.width / 2
      }
    }

    return scrollX
  }

  function getScrollY(rect: DOMRect, options: Options) {
    if (!document.scrollingElement) return

    const alignVertical = options?.align?.vertical ?? 'center'
    const padding = 24

    const navigationHeight =
      store.getters['cssVar/get']('navigationHeight').value

    const headerHeight = store.getters['cssVar/get'](
      'PmCalendarPure-headerHeight'
    ).value

    const viewportHeight =
      document.scrollingElement.clientHeight - navigationHeight - headerHeight

    // Aligment is top
    let scrollY =
      rect.y +
      document.scrollingElement.scrollTop -
      navigationHeight -
      headerHeight

    if (alignVertical === 'center') {
      // Check if width is taller than viewport and jump to start of element in this case
      const targetIsTallerThanViewport = rect.height > viewportHeight

      if (targetIsTallerThanViewport) {
        scrollY -= padding
      } else {
        scrollY -= viewportHeight / 2 - rect.height / 2
      }
    }

    return scrollY
  }

  async function jump() {
    if (!options?.el?.value) return
    if (!document.scrollingElement) return

    const rect = options.el.value.getBoundingClientRect()

    // FIXME: This does not work, because calc is used:
    // const padding = cssVar.read('space-default', 'number')

    const scrollX = getScrollX(rect, options)
    const scrollY = getScrollY(rect, options)

    document.scrollingElement.scrollTo({
      left: scrollX,
      top: scrollY,
      behavior: 'smooth',
    })

    if (options.hooks?.whenInViewport) {
      await waitUntilInViewport(options.el.value)
      options.hooks.whenInViewport()
    }
  }

  return {
    jump,
    register,
  }
}
