export async function waitUntilInViewport(el: HTMLElement) {
  return new Promise<void>((resolve) => {
    if (!el) throw new Error('el is undefined')

    const onIntersection = (entries: IntersectionObserverEntry[]) => {
      const isIntersecting = entries[0].isIntersecting
      if (!isIntersecting) return

      resolve()

      if (intersectionObserver === null) {
        throw new Error('intersectionObserver is null')
      }

      if (!el) throw new Error('el is undefined')

      intersectionObserver.unobserve(el)
      intersectionObserver.disconnect()
      intersectionObserver = null
    }

    let intersectionObserver: IntersectionObserver | null =
      new IntersectionObserver(onIntersection)

    intersectionObserver.observe(el)
  })
}
