import { createMachine } from 'xstate'

import { extractAllStates } from '@/composition/useXState'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import type { Typegen0 } from './NotificationsState.typegen'

export type State = Typegen0['matchesStates']

export const NotificationsState = createMachine(
  {
    tsTypes: {} as import('./NotificationsState.typegen').Typegen0,
    schema: {
      events: {} as { type: 'GET_NOTIFICATIONS' },

      services: {} as {
        getNotifications: any
      },
    },

    id: 'UseNotificationsState',
    initial: 'idle',

    states: {
      idle: {
        on: {
          GET_NOTIFICATIONS: 'getNotifications',
        },
        after: {
          20_000: [
            { target: 'getNotifications', cond: 'shouldBePolling' },
            { target: 'idle' },
          ],
        },
      },

      getNotifications: {
        invoke: {
          id: 'getNotifications',
          src: 'getNotifications',
          onDone: 'idle',
          onError: 'idle',
        },
      },
    },
  },
  {
    guards: {
      shouldBePolling: () => {
        return FEATURE_FLAG.AUTO_REFRESH_DATA !== false
      },
    },
  }
)

export const states = extractAllStates(NotificationsState)
