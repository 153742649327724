import { isWithinInterval, endOfDay, subDays } from 'date-fns'
import { orderBy } from 'lodash-es'

import {
  intervalIsAfterMidnightAndBeforeAlternativeEndOfDay,
  isAfterMidnightAndBeforeAlternativeEndOfDay,
} from '@/utilities/date'

export function contentStartDate({
  items,
  containerStartDate,
  containerEndDate,
}) {
  /**
   * Get first item which is the element for the contentStartDate
   */
  const orderedItems = orderBy(items, ['startDate'], ['asc'])
  const firstItem = orderedItems[0]
  let contentStartDate = firstItem?.startDate
  const contentEndDate = firstItem?.endDate

  if (!contentStartDate || !contentEndDate) return

  /**
   * Edge case, if startDate and endDate of content item are between midnight and 5:30
   * it get's constrained to the previous day, therefore we need to update the contentBounds aswell
   */
  const contentBoundsAreAfterMidnightAndBeforeEndOfDay =
    intervalIsAfterMidnightAndBeforeAlternativeEndOfDay({
      startDate: contentStartDate,
      endDate: contentEndDate,
    })

  if (contentBoundsAreAfterMidnightAndBeforeEndOfDay) {
    contentStartDate = endOfDay(subDays(contentStartDate, 1))
  }

  /**
   *  Check if contentStartDate is inside the frame of the job
   */
  const isWithinJobInterval = isWithinInterval(contentStartDate, {
    start: containerStartDate,
    end: containerEndDate,
  })
  if (!isWithinJobInterval) return

  return contentStartDate
}

export function contentEndDate({
  items,
  containerStartDate,
  containerEndDate,
}) {
  /**
   * Get first item which is the element for the contentEndDate
   */
  const orderedItems = orderBy(items, ['endDate'], ['desc'])
  const lastItem = orderedItems[0]
  let contentEndDate = lastItem?.endDate
  if (!contentEndDate) return

  /**
   * Check if the contentEndDate starts after midnight and before the alternative end of day
   * In this case we need to manually contract the contentEndDate for this day
   *
   * Example: contentEndDate is on day 2 at 00:30, meaning between midnight and 05:30
   * Because of this we set the contentEndDate to 23:59 of day 1 because in this case
   * the job is shortened to day 1
   */
  if (isAfterMidnightAndBeforeAlternativeEndOfDay(contentEndDate)) {
    contentEndDate = endOfDay(subDays(contentEndDate, 1))
  }

  /**
   * Check if contentEndDate is inside the frame of the job
   */
  const isWithinJobInterval = isWithinInterval(contentEndDate, {
    start: containerStartDate,
    end: containerEndDate,
  })
  if (!isWithinJobInterval) return

  return contentEndDate
}
