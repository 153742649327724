<template>
  <div class="PmVersionCheck">
    <portal to="notifications">
      <PmAppNotificationPure
        v-if="newVersionAvailable"
        :key="`${uid}.newVersionAvailable`"
        :can-be-closed="true"
        :with-shadow="true"
        title="Neue Version verfügbar"
        @close="newVersionAvailable = false"
      >
        Es ist eine neue Version
        <strong>{{ currentVersion }}</strong> verfügbar (du verwendest gerade
        {{ versionAtBuildTime }}). Klicke auf den Button um zu aktualisieren,
        die Seite wird dabei neu geladen.

        <template #actions>
          <PmButtonPure
            label="Auf neue Version aktualisieren"
            variant="secondary"
            @click="forceReload"
          />
        </template>
      </PmAppNotificationPure>

      <PmAppNotificationPure
        v-if="updateSuccessfulNotificationVisible"
        :key="`${uid}.updateSuccessful`"
        variant="success"
        :with-shadow="true"
        :can-be-closed="true"
        :timeout="5"
        @timeout-complete="updateSuccessfulNotificationVisible = false"
        @close="updateSuccessfulNotificationVisible = false"
      >
        Du hast die Anwendung erfolgreich auf Version
        {{ versionAtBuildTime }} aktualisiert
      </PmAppNotificationPure>

      <PmAppNotificationPure
        v-if="updateFailedNotificationVisible"
        :key="`${uid}.updateFailed`"
        variant="danger"
        :with-shadow="true"
        title="Update fehlgeschlagen"
        :can-be-closed="true"
        @close="updateFailedNotificationVisible = false"
      >
        Das Update hat leider nicht geklappt. Die Version ist immer noch
        <strong>{{ versionAtBuildTime }}.</strong>
        <br />
        <br />
        Versuche einen Hard-Reload mit STRG+F5 oder lösche deinen Browsercache
      </PmAppNotificationPure>
    </portal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { cloneDeep } from 'lodash-es'
import cuid from '@paralleldrive/cuid2'

import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'

import { version as versionAtBuildTime } from '@/../package.json'

export default defineComponent({
  name: 'PmVersionCheck',
  components: {
    PmAppNotificationPure,
    PmButtonPure,
  },

  setup() {
    return {
      uid: cuid.createId(),
    }
  },

  data() {
    return {
      versionAtBuildTime,
      interval: undefined,
      newVersionAvailable: false,
      currentVersion: undefined,
      updateSuccessfulNotificationVisible: false,
      updateFailedNotificationVisible: false,
    }
  },

  mounted() {
    const minutesBetweenChecks = 1000 * 60 * 1
    this.interval = setInterval(this.checkVersion, minutesBetweenChecks)
    this.checkIfJustUpdated()

    this.checkVersion()
  },

  methods: {
    async checkIfJustUpdated() {
      const updateFrom = this.$route.query['update-from']
      const updateTo = this.$route.query['update-to']

      // Remove query from url
      const query = cloneDeep(this.$route.query)
      if (query['update-from'] || query['update-to']) {
        delete query['update-from']
        delete query['update-to']

        this.$router.replace({ query: query })
      }

      if (!(updateFrom && updateTo)) return

      /**
       * Check if update was successful
       */
      if (updateFrom === versionAtBuildTime) {
        // Version did not get updated at all
        this.updateFailedNotificationVisible = true
        return
      }

      const currentVersion = await this.getCurrentVersion()
      if (versionAtBuildTime !== currentVersion) {
        // Did not update to the current version
        this.updateFailedNotificationVisible = true
        return
      }

      this.updateSuccessfulNotificationVisible = true
    },

    async getCurrentVersion() {
      const timestamp = new Date().getTime()

      try {
        const response = await axios({
          url: `/VERSION?${timestamp}`,
          method: 'get',
          baseURL: '/',
          responseType: 'text',
        })

        // Vite adds sourcemap info to the file, thus we just use the first line
        const currentVersion = response.data.split('\n')[0].trim()
        return currentVersion
      } catch (error) {
        throw new Error('Could not read current version')
      }
    },

    async checkVersion() {
      try {
        this.currentVersion = await this.getCurrentVersion()
        this.newVersionAvailable = this.currentVersion !== versionAtBuildTime
      } catch (error) {
        // silent error
      }
    },

    /**
     * window.location.reload(forceReload) - forceReload is deprecated
     * According to the Stackoverflow issue you can use a form with a POST request
     * to refresh all resources and bypass the browser cache
     * @see: https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
     * For Alternatives:
     * @see: https://www.codeproject.com/Questions/5273780/How-to-hard-reload-a-page-in-javascript
     * @see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Clear-Site-Data
     * @see: https://developer.mozilla.org/en-US/docs/Web/API/Cache/delete
     */
    forceReload() {
      const url = new URL(location.href)
      url.searchParams.set('update-from', versionAtBuildTime)
      url.searchParams.set('update-to', this.currentVersion)

      // This apparently is sufficient since the HTML is not cached, but needs more testing
      window.location.replace(url.href)

      // const form = document.createElement('form')
      // form.method = 'POST'
      // form.action = url.href
      // this.$el.appendChild(form)
      // form.submit()
    },
  },
})
</script>

<style lang="scss">
.PmVersionCheck {
  $block: &;

  /* stylelint-disable */
  strong {
    font-weight: bold;
  }
  /* stylelint-enable */
}
</style>
