import { createMachine } from 'xstate'

import type { Typegen0 } from './PmViewDeleteState.typegen'

export type TState = Typegen0['matchesStates']

export const PmViewDeleteState = createMachine({
  tsTypes: {} as import('./PmViewDeleteState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'DELETE' },

    services: {} as {
      deleteView: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmViewDeleteState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        DELETE: 'delete',
      },
    },

    delete: {
      initial: 'deleting',
      states: {
        deleting: {
          invoke: {
            id: 'delete-view',
            src: 'deleteView',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },

            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {
          on: {
            DELETE: 'deleting',
          },
        },
      },
    },
  },
})
