<template>
  <PmViewEditorDropdown
    label="Projekt"
    attribute="projects"
    :options="normalizedProjects"
    :is-loading="isLoadingAny"
    v-bind="separateAttrs($attrs).attributes"
    :value="value"
    @close="onClose"
    @input-searchterm="(value) => (searchterm = value)"
  />
</template>

<script>
import { uniqBy } from 'lodash-es'

import { separateAttrs } from '@/utilities/misc'
import { useLoading } from '@/composition/useLoading'

import PmViewEditorDropdown from '@/components/persoplan/ViewEditor/PmViewEditorDropdown.vue'

import ViewEditorProjectsQuery from '@/components/persoplan/ViewEditor/ViewEditorProjectsQuery.graphql'
import ViewEditorProjectsSearchQuery from '@/components/persoplan/ViewEditor/ViewEditorProjectsSearchQuery.graphql'

export default {
  name: 'PmViewEditorProjects',
  components: {
    PmViewEditorDropdown,
  },

  props: {
    value: { type: Array, default: () => [] },
  },

  setup() {
    const { setLoading, isLoadingAny } = useLoading({
      loaders: ['projects', 'projectSearch'],
    })
    return {
      separateAttrs,
      setLoading,
      isLoadingAny,
    }
  },

  data() {
    return {
      searchterm: undefined,
      projects: [],
      projectsSearch: [],
    }
  },

  computed: {
    normalizedProjects() {
      const projects = this.projects.map((project) => ({
        id: project.id,
        label: project.caption,
        number: project.number,
      }))

      const projectsSearch = this.projectsSearch.map((project) => ({
        id: project.id,
        label: project.caption,
        number: project.number,
      }))

      // Remove any double values
      return uniqBy([...projects, ...projectsSearch], 'id')
    },
  },

  methods: {
    onClose() {
      this.projectsSearch = []
      this.searchterm = undefined
    },
  },

  apollo: {
    projects: {
      query: ViewEditorProjectsQuery,

      variables() {
        // If there are no ids selected, use -1 to avoid getting results from query
        let ids = this.value
        if (!ids.length) ids = [-1]

        return {
          ids: ids,
        }
      },

      watchLoading(isLoading) {
        this.setLoading('projects', isLoading)
      },
    },

    projectsSearch: {
      query: ViewEditorProjectsSearchQuery,

      debounce: 250,

      variables() {
        return {
          searchterm: this.searchterm,
        }
      },

      skip() {
        if (!this.searchterm) return true
        return false
      },

      watchLoading(isLoading) {
        this.setLoading('projectSearch', isLoading)
      },
    },
  },
}
</script>
