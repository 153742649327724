<template>
  <PmResourceRequestOverviewFilterPure
    v-model:resource-function-id="resourceFunctionId"
    v-model:job-or-project-leader-id="jobOrProjectLeaderId"
    v-model:createdById="createdById"
    v-model:project-id="projectId"
    v-model:job-id="jobId"
    v-model:resource-id="resourceId"
    v-model:status-id="statusId"
    v-model:status-feedback-id="statusFeedbackId"
    v-model:updated-within-id="updatedWithinId"
    v-model:use-timeframe-of-project="useTimeframeOfProject"
    v-model:use-timeframe-of-job="useTimeframeOfJob"
    :is-loading-static-data="resourceRequestOverviewFilterStatic.loading.value"
    :job-or-project-leaders="jobOrProjectLeadersNormalized"
    :created-by-options="createdByOptions"
    :resource-functions="resourceFunctionsNormalized"
    :start-date="startEndDateNormalized.startDate"
    :end-date="startEndDateNormalized.endDate"
    :is-projects-loading="projectSearchQuery.loading.value"
    :projects="projectsNormalised"
    :is-jobs-loading="jobSearchQuery.loading.value"
    :jobs="jobsNormalized"
    :resources="resourcesNormalized"
    :is-resource-loading="resourceSearchQuery.loading.value"
    :is-date-controller-disabled="isDateControllerDisabled"
    :is-use-timeframe-of-project-disabled="isTimeframeOfProjectDisabled"
    :is-use-timeframe-of-job-disabled="isTimeframeOfJobDisabled"
    :note-timeframe="noteTimeframe"
    @update:search-term-project="(value) => (searchTermProject = value)"
    @update:search-term-job="(value) => (searchTermJob = value)"
    @open-date-controller="isDateControllerVisible = true"
    @update:search-term-resource="(value) => (searchTermResource = value)"
  />

  <PmDateControllerPure
    v-if="isDateControllerVisible"
    :start-date="startDateUser"
    :end-date="endDateUser"
    :shortcuts="SHORTCUTS_VISIBLE_TIMEFRAME"
    @close="isDateControllerVisible = false"
    @set-dates="setDates"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceRequestOverviewFilter'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { uniqBy } from 'lodash-es'

import { SHORTCUTS_VISIBLE_TIMEFRAME } from '@/constants/persoplan'

import {
  getDefaultStartDate,
  getDefaultEndDate,
  parseServerDateString,
} from '@/utilities/date'

import PmResourceRequestOverviewFilterPure, {
  type Props as PropsResourceRequestOverviewFilterPure,
  type UpdatedWithinId,
} from '@/components/PmResourceRequestOverviewFilter/PmResourceRequestOverviewFilterPure.vue'
import PmDateControllerPure, {
  type Event as EventDateControllerPure,
} from '@/components/persoplan/PmDateControllerPure.vue'

import type { Option as DropdownOption } from '@/components/basics/PmDropdownPure.vue'
import type { Nilable } from '@/types/misc'
import {
  ResourceRequestOverviewFilterStaticDocument,
  ResourceRequestOverviewFilterProjectDocument,
  ResourceRequestOverviewFilterProjectSearchDocument,
  ResourceRequestOverviewFilterJobSearchDocument,
  ResourceRequestOverviewFilterJobDocument,
  ViewEditorResourceAllocationsSearchDocument,
  ViewEditorResourceAllocationsDocument,
} from '@/../generated/graphql'

export interface Props {
  myProp?: string
}

export interface Emit {
  filter: {
    jobOrProjectLeaderId: number | undefined
    projectId: number | undefined
    createdById: number | undefined
    jobId: number | undefined
    resourceId: number | undefined
    resourceFunctionId: number | undefined
    statusId: PropsResourceRequestOverviewFilterPure['statusId']
    statusFeedbackId: PropsResourceRequestOverviewFilterPure['statusFeedbackId']
    startDate: Date
    endDate: Date
    updatedWithinId: UpdatedWithinId | undefined
  }
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'update:filter', value: Emit['filter']): void
  (event: 'initialLoadingFinished'): void
}>()

const resourceRequestOverviewFilterStatic = useQuery(
  ResourceRequestOverviewFilterStaticDocument
)

const unwatchLoadingFinished = watch(
  resourceRequestOverviewFilterStatic.loading,
  (to, from) => {
    if (from === true && to === false) {
      unwatchLoadingFinished()
      emit('initialLoadingFinished')
    }
  }
)

/**
 * Job- or Project leader
 */
const jobOrProjectLeaderId = ref<number>()

const jobOrProjectLeadersNormalized = computed(() => {
  return resourceRequestOverviewFilterStatic.result.value?.easyJobUsers?.reduce(
    (result: DropdownOption[], easyJobUser) => {
      if (!easyJobUser) return result

      result.push({
        id: easyJobUser.id,
        label: easyJobUser.displayName ?? 'n/a',
      })

      return result
    },
    []
  )
})

/**
 * Created by
 */
const createdById = ref<number>()

const createdByOptions = computed(() => {
  return resourceRequestOverviewFilterStatic.result.value?.easyJobUsers?.reduce(
    (result: DropdownOption[], easyJobUser) => {
      if (!easyJobUser?.address?.id) return result

      result.push({
        id: easyJobUser.address?.id,
        label: easyJobUser.displayName ?? 'n/a',
      })

      return result
    },
    []
  )
})

/**
 * Resource Function
 */
const resourceFunctionId = ref<number>()

const resourceFunctionsNormalized = computed(() => {
  return resourceRequestOverviewFilterStatic.result.value?.resourceFunctions?.reduce(
    (result: DropdownOption[], resourceFunction) => {
      if (!resourceFunction) return result

      result.push({
        id: resourceFunction.id,
        label: resourceFunction.caption ?? 'n/a',
        number: resourceFunction.abbreviation ?? undefined,
      })

      return result
    },
    []
  )
})

/**
 * Start/End Date
 */
const startDateUser = ref<Date>(getDefaultStartDate())
const endDateUser = ref<Date>(getDefaultEndDate())
const isDateControllerVisible = ref(false)
const useTimeframeOfProject = ref(false)
const useTimeframeOfJob = ref(false)

function setDates(dates: EventDateControllerPure['setDates']) {
  startDateUser.value = dates.start
  endDateUser.value = dates.end
}

const isDateControllerDisabled = computed(() => {
  return startEndDateNormalized.value.type !== 'user'
})

const startEndDateNormalized = computed<{
  type: 'selectedProject' | 'selectedJob' | 'user'
  startDate: Date
  endDate: Date
}>(() => {
  if (
    selectedProjectNormalized.value &&
    selectedProjectNormalized.value.startDate &&
    selectedProjectNormalized.value.endDate &&
    useTimeframeOfProject.value === true
  ) {
    return {
      type: 'selectedProject',
      startDate: selectedProjectNormalized.value.startDate,
      endDate: selectedProjectNormalized.value.endDate,
    }
  }

  if (
    selectedJobNormalized.value &&
    selectedJobNormalized.value.startDate &&
    selectedJobNormalized.value.endDate &&
    useTimeframeOfJob.value === true
  ) {
    return {
      type: 'selectedJob',
      startDate: selectedJobNormalized.value.startDate,
      endDate: selectedJobNormalized.value.endDate,
    }
  }

  return {
    type: 'user',
    startDate: startDateUser.value,
    endDate: endDateUser.value,
  }
})

const isTimeframeOfProjectDisabled = computed(() => {
  if (useTimeframeOfJob.value === true) return true
  return false
})

const isTimeframeOfJobDisabled = computed(() => {
  if (useTimeframeOfProject.value === true) return true
  return false
})

const noteTimeframe = computed(() => {
  if (startEndDateNormalized.value.type === 'selectedProject') {
    return 'Der Zeitraum des ausgewählten Projekts wird verwendet'
  }

  if (startEndDateNormalized.value.type === 'selectedJob') {
    return 'Der Zeitraum des ausgewählten Jobs wird verwendet'
  }

  return undefined
})

/**
 * Project Search and selected job
 */
const searchTermProject = ref<Nilable<string>>()
const projectId = ref<number>()

const isSearchProjectEnabled = computed(() => {
  if (!searchTermProject.value) return false
  return searchTermProject.value?.length >= 3
})

const projectSearchQuery = useQuery(
  ResourceRequestOverviewFilterProjectSearchDocument,
  () => ({
    searchTerm: searchTermProject.value,
  }),
  () => ({
    enabled: isSearchProjectEnabled.value,
    debounce: 250,
  })
)

const projectQuery = useQuery(
  ResourceRequestOverviewFilterProjectDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: projectId.value,
  }),
  () => ({
    enabled: Boolean(projectId.value),
  })
)

const selectedProjectNormalized = computed(() => {
  if (!projectQuery.result.value?.project) return
  if (!projectId.value) return

  return {
    id: projectQuery.result.value.project.id,
    label: projectQuery.result.value.project.caption,
    number: projectQuery.result.value.project.number,
    startDate: parseServerDateString(
      projectQuery.result.value.project.startDate
    ),
    endDate: parseServerDateString(projectQuery.result.value.project.endDate),
  }
})

const projectsNormalised = computed(() => {
  const searchResults =
    projectSearchQuery.result.value?.projects?.reduce(
      (result: DropdownOption[], project) => {
        if (!project) return result

        result.push({
          id: project.id,
          label: project.caption,
          number: project.number,
        })

        return result
      },
      []
    ) ?? []

  const projects: DropdownOption[] = []

  if (selectedProjectNormalized.value) {
    projects.push({
      id: selectedProjectNormalized.value.id,
      label: selectedProjectNormalized.value.label,
      number: selectedProjectNormalized.value.number,
    })
  }

  return uniqBy([...searchResults, ...projects], 'id')
})

/**
 * Automatically use timeframe of project, when the user
 * selects a project and this options is not set for the job
 */
watch(projectId, () => {
  if (!projectId.value) return
  if (useTimeframeOfJob.value === true) return

  useTimeframeOfProject.value = true
})

/**
 * Job Search and selected job
 */
const searchTermJob = ref<Nilable<string>>()
const jobId = ref<number>()

const isSearchJobEnabled = computed(() => {
  if (!searchTermJob.value) return false
  return searchTermJob.value?.length >= 3
})

const jobSearchQuery = useQuery(
  ResourceRequestOverviewFilterJobSearchDocument,
  () => ({
    searchTerm: searchTermJob.value,
  }),
  () => ({
    enabled: isSearchJobEnabled.value,
    debounce: 250,
  })
)

const jobQuery = useQuery(
  ResourceRequestOverviewFilterJobDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: jobId.value,
  }),
  () => ({
    enabled: Boolean(jobId.value),
  })
)

const selectedJobNormalized = computed(() => {
  if (!jobQuery.result.value?.job) return
  if (!jobId.value) return

  return {
    id: jobQuery.result.value.job.id,
    label: jobQuery.result.value.job.caption,
    number: jobQuery.result.value.job.number,
    startDate: parseServerDateString(jobQuery.result.value.job.startDate),
    endDate: parseServerDateString(jobQuery.result.value.job.endDate),
  }
})

const jobsNormalized = computed(() => {
  const searchResults =
    jobSearchQuery.result.value?.jobs?.reduce(
      (result: DropdownOption[], job) => {
        if (!job) return result

        result.push({
          id: job.id,
          label: job.caption,
          number: job.number,
        })

        return result
      },
      []
    ) ?? []

  const jobs: DropdownOption[] = []

  if (selectedJobNormalized.value) {
    jobs.push({
      id: selectedJobNormalized.value.id,
      label: selectedJobNormalized.value.label,
      number: selectedJobNormalized.value.number,
    })
  }

  return uniqBy([...searchResults, ...jobs], 'id')
})

/**
 * Automatically use timeframe of job, when the user
 * selects a job and this options is not set for the project
 */
watch(jobId, () => {
  if (!jobId.value) return
  if (useTimeframeOfProject.value === true) return

  useTimeframeOfJob.value = true
})

/**
 * Resource search and selected resource
 */
const searchTermResource = ref<Nilable<string>>()
const resourceId = ref<number>()

const isSearchResourceEnabled = computed(() => {
  if (!searchTermResource.value) return false
  return searchTermResource.value?.length >= 3
})

const resourceSearchQuery = useQuery(
  ViewEditorResourceAllocationsSearchDocument,
  () => ({
    searchterm: searchTermResource.value,
  }),
  () => ({
    enabled: isSearchResourceEnabled.value,
    debounce: 250,
  })
)

const resourceQuery = useQuery(
  ViewEditorResourceAllocationsDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    ids: [resourceId.value],
  }),
  () => ({
    enabled: Boolean(resourceId.value),
  })
)

const resourcesNormalized = computed(() => {
  const searchResults =
    resourceSearchQuery.result.value?.resourceAllocationsSearch?.reduce(
      (result: DropdownOption[], resource) => {
        if (!resource) return result

        result.push({
          id: resource.id,
          label: resource.displayName,
        })

        return result
      },
      []
    ) ?? []

  const resources: DropdownOption[] = []

  if (resourceQuery.result.value?.resourceAllocations) {
    resourceQuery.result.value?.resourceAllocations.forEach(
      (resourceAllocation) => {
        if (!resourceAllocation) return

        resources.push({
          id: resourceAllocation.id,
          label: resourceAllocation.displayName,
        })
      }
    )
  }

  return uniqBy([...searchResults, ...resources], 'id')
})

const statusId = ref<PropsResourceRequestOverviewFilterPure['statusId']>()
const statusFeedbackId =
  ref<PropsResourceRequestOverviewFilterPure['statusFeedbackId']>()

/**
 * Updated within
 */
const updatedWithinId = ref<UpdatedWithinId>()

/**
 * Emit event when filter values have been updated
 */

/** Collected filter values */
const filter = computed(() => {
  return {
    jobOrProjectLeaderId: jobOrProjectLeaderId.value,
    createdById: createdById.value,
    projectId: projectId.value,
    jobId: jobId.value,
    resourceId: resourceId.value,
    resourceFunctionId: resourceFunctionId.value,
    startDate: startEndDateNormalized.value.startDate,
    endDate: startEndDateNormalized.value.endDate,
    statusId: statusId.value,
    statusFeedbackId: statusFeedbackId.value,
    updatedWithinId: updatedWithinId.value,
  }
})

watch(filter, () => emit('update:filter', filter.value), {
  deep: true,
})

// Update the filter for the first time
emit('update:filter', filter.value)
</script>
