<template>
  <div class="PmSidebarProjects">
    <PmSidebarSectionPure
      title="Vorgänge"
      action-title="Resourcen-Status für externe Dienstleister erstellen"
      :can-be-collapsed="true"
      :collapsed="collapsableArea.isCollapsed.value"
      :mark-when-collapsed="true"
      @expand="collapsableArea.expand"
      @collapse="collapsableArea.collapse"
    >
      <template
        v-for="stockTypeService in sidebarOperations?.stockTypeServices"
      >
        <PmSidebarStockTypeService
          v-if="stockTypeService"
          :id="stockTypeService.id"
          :key="stockTypeService.id"
        />
      </template>

      <template v-for="purchaseOrder in sidebarOperations?.purchaseOrders">
        <PmSidebarPurchaseOrder
          v-if="purchaseOrder"
          :id="purchaseOrder.id"
          :key="purchaseOrder.id"
        />
      </template>
    </PmSidebarSectionPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarOperations'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuery } from '@vue/apollo-composable'

import { useCollapsableArea } from '@/composition/useCollapsableArea'
import { formatToServerDateString } from '@/utilities/date'

import PmSidebarSectionPure from '@/components/persoplan/PmSidebarSection/PmSidebarSectionPure.vue'
import PmSidebarStockTypeService from '@/components/persoplan/PmSidebarStockTypeService/PmSidebarStockTypeService.vue'
import PmSidebarPurchaseOrder from '@/components/persoplan/PmSidebarPurchaseOrder/PmSidebarPurchaseOrder.vue'

import { SidebarOperationsDocument } from '@/../generated/graphql'

export interface Props {
  startDate: Date
  endDate: Date
  showOnlyWithResources: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()

const collapsableArea = useCollapsableArea({ id: 'stockTypeServices' })

const sidebarOperationsQuery = useQuery(SidebarOperationsDocument, () => ({
  startDate: formatToServerDateString(props.startDate),
  endDate: formatToServerDateString(props.endDate),
  showOnlyWithResources: props.showOnlyWithResources,
}))

const sidebarOperations = computed(() => sidebarOperationsQuery.result.value)
</script>
