<template>
  <svg
    class="PmIconPure"
    :viewBox="`0 0 ${viewboxDimensions.width} ${viewboxDimensions.height}`"
    xmlns="http://www.w3.org/2000/svg"
    @click="emit('click')"
  >
    <use :href="`#${iconId}`" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmIconPure'

export const propTypes = {
  name: {
    allowed: [...Object.values(ICONS), ...Object.values(ICONS_SMALL)],
  },
  size: {
    allowed: ['default', 'small'],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { ICONS, ICONS_SMALL } from '@/constants/icons'
import type { Icon } from '@/constants/icons'

import { lookup } from '@/utilities/misc'

export interface Props {
  name: Icon
  /** The size variant of the icon, will fall back to `default` if the icon is not available  */
  size?: (typeof propTypes.size.allowed)[number]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'click'): void
}>()

type ViewboxDimensions = {
  width: number
  height: number
}

/**
 * If an icon hast a viewbox which is not 24×24 or 20×20 for small icons
 * it needs to be defined here:
 */
const customViewbox: Partial<Record<Icon, ViewboxDimensions>> = {
  overflowLeft: { width: 30, height: 16 },
  overflowRight: { width: 30, height: 16 },
} as const

const viewboxDimensions = computed<ViewboxDimensions>(() => {
  const customViewboxForIcon = lookup(iconNameNormalized.value, customViewbox)

  if (customViewboxForIcon) {
    return customViewboxForIcon
  }

  return {
    width: iconSize.value,
    height: iconSize.value,
  }
})

const iconNameNormalized = computed(() => {
  /**
   * Automatically get small icon if size is small and small icon is available
   */
  if (props.size !== 'small') return props.name
  if (props.name.includes('.small')) return props.name

  const smallIconName = Object.values(ICONS_SMALL).find(
    (name) => name === `${props.name}.small`
  )

  if (smallIconName) return smallIconName
  return props.name
})

const isSmallIcon = computed(() => {
  if (iconNameNormalized.value.includes('.small')) return true
  return false
})

const iconSize = computed(() => {
  return isSmallIcon.value ? 20 : 24
})

const iconId = computed(() => {
  return `svgsprite-${iconNameNormalized.value}`
})
</script>

<style lang="scss">
.PmIconPure {
  @include mixin.svgIcon;

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  --strokeWidth: unset;
}
</style>
