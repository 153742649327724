<template>
  <Portal to="modal">
    <PmModalPure
      :can-be-closed="false"
      title="Spesenabrechnung"
      :is-loading="isLoading"
    >
      <div :class="componentClass.root">
        <template v-if="!xstate.snapshot.matches({ create: 'success' })">
          <PmFormPure
            :id="formId"
            :disabled="isDisabled"
            @submit.prevent="onSubmit"
          >
            <PmDropdownPure
              v-model:value="expenseTypeId"
              :options="optionsExpenseType"
              label="Spesenart"
              required
            />

            <PmInputPure
              v-model:value="dateOfSubmission"
              type="date"
              label="Abrechnung für"
              required
            />

            <slot name="dropdownJob">
              <PmDropdownPure
                v-model:value="jobId"
                placeholder="Placeholder"
                :options="[
                  {
                    id: 42,
                    label: 'Example Job',
                    number: '42',
                  },
                ]"
                label="Job"
                required
              />
            </slot>

            <PmTextareaPure v-model:value="notes" label="Hinweise" :rows="3" />

            <PmRadioListPure
              v-model:checked-id="typeOfPaymentId"
              :items="
                Object.entries(optionsTypeOfPayment).map(([id, item]) => ({
                  id: id,
                  label: item.label,
                }))
              "
              required
              label="Bezahlt mit"
              name="typeOfPayment"
              layout="horizontal"
            />

            <PmInputPure
              v-if="typeOfPaymentId === 'private'"
              v-model:value="iban"
              type="text"
              label="IBAN"
              :required="false"
            />

            <template v-if="isTravelExpense">
              <!-- Travel Expenses -->
              <PmInputPure
                v-model:value="route"
                type="text"
                label="Route"
                required
                note="Zwischen welchen Orten bist du gefahren?"
              />

              <PmRadioListPure
                v-model:checked-id="typeOfTransportId"
                :items="
                  Object.entries(optionsTypeOfTransport).map(([id, item]) => ({
                    id: id,
                    label: item.label,
                  }))
                "
                required
                label="Transportart"
                name="typeOfTransport"
                layout="horizontal"
              />

              <PmInputPure
                v-if="typeOfTransportId === 'private'"
                v-model:value="numberOfKilometers"
                type="number"
                label="Anzahl Kilometer"
                required
              />
            </template>

            <template v-if="isCatering">
              <!-- Catering -->

              <PmInputPure
                v-model:value="dateOfCatering"
                type="date"
                label="Datum der Bewirtung"
                required
              />

              <PmTextareaPure
                v-model:value="placeOfHospitalityAddress"
                label="Ort der Bewirtung"
                :rows="3"
                required
                note="Bitte den Namen und die komplette Adresse angeben"
              />

              <PmTextareaPure
                v-model:value="personsServed"
                label="Bewirtete Personen"
                :rows="3"
                required
              />

              <PmTextareaPure
                v-model:value="reasonForCatering"
                label="Anlass der Bewirtung"
                :rows="3"
                required
              />

              <PmInputPure
                v-model:value="amountOfExpenses"
                type="number"
                label="Höhe der Aufwendungen"
                note="gemäß beigefügter Rechnung (inkl. MwSt.)"
                required
              />

              <PmInputPure
                v-model:value="tip"
                type="number"
                label="Trinkgeld"
              />

              <PmInputPure
                label="Gesamtbetrag"
                type="number"
                readonly
                :value="amountOfExpensesTotal"
              />
            </template>

            <PmInputFile
              v-model:files="receipts"
              label="Belege"
              :allowed-file-types="['.pdf', '.jpg', '.jpeg', '.png']"
              :max-file-size="2"
              :max-number-of-files="10"
            />

            <template v-if="xstate.meta.value?.error" #error>
              <PmErrorNotificationPure
                :message="errorMessage || xstate.meta.value?.message"
                :details="errorDetails"
              />
            </template>
          </PmFormPure>
        </template>
      </div>

      <template #footer>
        <PmButtonListPure
          v-if="!xstate.snapshot.matches({ create: 'success' })"
          layout="distribute"
        >
          <PmButtonPure
            label="Abbrechen"
            icon="close"
            :disabled="isDisabled"
            @click="emit('close')"
          />

          <PmButtonPure
            variant="primary"
            label="Einreichen"
            type="submit"
            :form="formId"
            icon="send"
            :loading="xstate.snapshot.matches({ create: 'saving' })"
            :disabled="isDisabled"
          />
        </PmButtonListPure>
      </template>
    </PmModalPure>
  </Portal>
</template>

<script setup lang="ts">
import { ref, toRef, computed, watch } from 'vue'
import { Portal } from 'portal-vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import cuid from '@paralleldrive/cuid2'
import * as v from 'valibot'
import { isFinite, isNil } from 'lodash-es'

import { useXState, type StatePathOrValue } from '@/composition/useXState5'
import { PmCreateLeaveRequestPureState } from '@/components/PmCreateLeaveRequest/PmCreateLeaveRequestPureState'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmDropdownPure, {
  type Props as PropsDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmRadioListPure from '@/components/basics/PmRadioList/PmRadioListPure.vue'
import type { RequireKeys } from '@/types/misc'
import PmInputFile from '@/components/basics/PmInputFile/PmInputFile.vue'

export interface Props {
  state?: StatePathOrValue<typeof PmCreateLeaveRequestPureState>
  errorMessage?: string
  errorDetails?: string[]
  expenseTypeIdCatering?: number
  expenseTypeIdTravelExpense?: number
  optionsExpenseType?: PropsDropdownPure['options']
  defaultValues?: {
    dateOfSubmission?: Date
    dateOfCatering?: Date
  }
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const formDataSchema = v.object({
  expenseTypeId: v.number(),
  dateOfSubmission: v.date(),
  jobId: v.number(),
  notes: v.optional(v.string()),
  typeOfPaymentId: v.union([v.literal('companyCard'), v.literal('private')]),
  iban: v.optional(v.string()),
  receipts: v.optional(v.map(v.string(), v.file())),
})

const formDataTravel = v.object({
  route: v.string(),
  typeOfTransportId: v.union([
    v.literal('private'),
    v.literal('cabOrPublicTransport'),
  ]),
  numberOfKilometers: v.optional(v.number()),
})

const formDataCatering = v.object({
  dateOfCatering: v.date(),
  placeOfHospitalityAddress: v.string(),
  personsServed: v.string(),
  reasonForCatering: v.string(),
  amountOfExpenses: v.number(),
  amountTip: v.nullish(v.number()),
  amountTotal: v.nullish(v.number()),
})

type FormDataBase = v.InferOutput<typeof formDataSchema>
type FormDataTravelExpenses = v.InferOutput<typeof formDataTravel>
type FormDataCatering = v.InferOutput<typeof formDataCatering>

export type Emits = {
  submit: {
    base: FormDataBase
    travelExpense?: FormDataTravelExpenses
    catering?: FormDataCatering
  }
}

const emit = defineEmits<{
  close: []
  submit: [Emits['submit']]
}>()

const componentClass = useComponentClass()
const xstate = useXState(PmCreateLeaveRequestPureState, {
  syncStateWith: toRef(props, 'state'),
})

const optionsTypeOfPayment = {
  companyCard: {
    label: 'Firmenkarte',
  },
  private: {
    label: 'Privat',
  },
} as const satisfies Record<FormDataBase['typeOfPaymentId'], { label: string }>

const optionsTypeOfTransport = {
  private: {
    label: 'Privat',
  },
  cabOrPublicTransport: {
    label: 'Taxi oder öffentlicher Nahverkehr',
  },
} as const satisfies Record<
  FormDataTravelExpenses['typeOfTransportId'],
  { label: string }
>

const formId = `${componentClass.root}.${cuid.createId()}`
const expenseTypeId = ref<number>()
const dateOfSubmission = ref<Date>()
const jobId = defineModel<number>('jobId')
const notes = ref<string>()
const typeOfPaymentId = ref<FormDataBase['typeOfPaymentId']>()
const iban = ref<string>()
const receipts = ref(new Map<string, File>())

// Travel
const route = ref<string>()
const typeOfTransportId = ref<FormDataTravelExpenses['typeOfTransportId']>()
const numberOfKilometers = ref<number>()

// Catering
const placeOfHospitalityAddress = ref<string>()
const dateOfCatering = ref<Date>()
const personsServed = ref<string>()
const reasonForCatering = ref<string>()
const amountOfExpenses = ref<number>()
const tip = ref<number>()

function isFiniteAndDefined(value: number | undefined): value is number {
  if (value === undefined) return false
  if (!isFinite(value)) return false
  return true
}

const amountOfExpensesTotal = computed<number>(() => {
  const amountOfExpensesNormalized = isFiniteAndDefined(amountOfExpenses.value)
    ? amountOfExpenses.value
    : 0
  const tipNormalized = isFiniteAndDefined(tip.value) ? tip.value : 0

  return amountOfExpensesNormalized + tipNormalized
})

const isTravelExpense = computed(() => {
  if (isNil(expenseTypeId.value)) return false
  return expenseTypeId.value === props.expenseTypeIdTravelExpense
})

const isCatering = computed(() => {
  if (isNil(expenseTypeId.value)) return false
  return expenseTypeId.value === props.expenseTypeIdCatering
})

function onSubmit() {
  const dataBase = v.parse(formDataSchema, {
    expenseTypeId: expenseTypeId.value,
    dateOfSubmission: dateOfSubmission.value,
    jobId: jobId.value,
    notes: notes.value,
    typeOfPaymentId: typeOfPaymentId.value,
    iban: iban.value,
    receipts: receipts.value,
  } satisfies RequireKeys<FormDataBase>)

  const dataTravelExpense = isTravelExpense.value
    ? v.parse(formDataTravel, {
        route: route.value,
        typeOfTransportId: typeOfTransportId.value,
        numberOfKilometers: numberOfKilometers.value,
      } satisfies RequireKeys<FormDataTravelExpenses>)
    : undefined

  const dataCatering = isCatering.value
    ? v.parse(formDataCatering, {
        placeOfHospitalityAddress: placeOfHospitalityAddress.value,
        dateOfCatering: dateOfCatering.value,
        personsServed: personsServed.value,
        reasonForCatering: reasonForCatering.value,
        amountOfExpenses: amountOfExpenses.value,
        amountTip: tip.value,
        amountTotal: amountOfExpensesTotal.value,
      } satisfies RequireKeys<FormDataCatering>)
    : undefined

  emit('submit', {
    base: dataBase,
    travelExpense: dataTravelExpense,
    catering: dataCatering,
  })
}

const isDisabled = computed(() => {
  if (xstate.value.snapshot.matches({ create: 'saving' })) return true
  return false
})

/**
 * Set default values
 */
watch(
  () => props.defaultValues,
  () => {
    if (!props.defaultValues) return

    dateOfSubmission.value = props.defaultValues.dateOfSubmission
    dateOfCatering.value = props.defaultValues.dateOfCatering
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.PmCreateExpenseReportPure {
  $block: &;
}
</style>
