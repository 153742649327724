<template>
  <div class="PmDriverVehicleControllerPure">
    <PmFloatingBarPure>
      <div class="PmDriverVehicleControllerPure-content">
        <PmIconPure
          v-if="icon"
          :key="icon"
          :name="icon"
          class="PmDriverVehicleControllerPure-icon"
        />

        {{ label }}

        <PmButtonPure
          class="PmDriverVehicleControllerPure-button"
          label="Abbrechen"
          :icon="ICONS.CLOSE"
          @click="$emit('cancel')"
        />
      </div>
    </PmFloatingBarPure>
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'
import { RESOURCE_TYPES, RESOURCE_TYPE } from '@/constants/persoplan'
import propValidator from '@/functional/propValidator'

import PmFloatingBarPure from '@/components/basics/PmFloatingBarPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export const propTypes = {
  type: { allowed: RESOURCE_TYPES },
}

export default {
  name: 'PmDriverVehicleControllerPure',
  components: {
    PmFloatingBarPure,
    PmButtonPure,
    PmIconPure,
  },

  props: {
    type: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.type.allowed),
    },
  },

  emits: ['cancel'],

  setup() {
    return {
      ICONS,
    }
  },

  computed: {
    label() {
      if (this.type === RESOURCE_TYPE.ADDRESS) {
        return 'Klicke auf die Resource die du als Fahrzeug verwenden möchtest'
      }

      if (this.type === RESOURCE_TYPE.VEHICLE) {
        return 'Klicke auf die Resource die du als Fahrer verwenden möchtest'
      }

      return undefined
    },

    icon() {
      if (this.type === RESOURCE_TYPE.ADDRESS) return ICONS.VEHICLE_SELECT
      if (this.type === RESOURCE_TYPE.VEHICLE) return ICONS.DRIVER_SELECT

      return null
    },
  },
}
</script>

<style lang="scss">
.PmDriverVehicleControllerPure {
  $block: &;

  &-content {
    display: flex;
    align-items: center;
    gap: var(--space-gutters);
  }

  &-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }

  &-button {
    flex-shrink: 0;
  }
}
</style>
