<template>
  <PmModalPure title="Ansicht duplizieren" @close="$emit('close')">
    <div class="PmViewDuplicatePure">
      <PmErrorNotificationPure
        v-if="error && errorMessage"
        class="PmViewDuplicatePure-notification"
        :message="errorMessage"
        :details="errorDetails"
      />

      <PmAppNotificationPure
        icon="info"
        class="PmViewDuplicatePure-notification"
      >
        Die ausgewählte Ansicht ist eine öffentliche Ansicht von
        {{ userNameOfViewOwner }}, deshalb kannst du diese Ansicht nicht
        speichern. Du kannst deine Änderungen aber unter einem neuen Namen
        speichern.
      </PmAppNotificationPure>

      <form
        :id="`form${uid}`"
        class="PmViewDuplicatePure-form"
        @submit.prevent="duplicate"
      >
        <PmInputPure
          v-model:value="name"
          label="Name"
          placeholder="Namen eingeben"
          class="PmViewDuplicatePure-input"
          required
          :disabled="loading"
          :not-allowed="existingViewNames"
          :trigger-validity-check="triggerValidityCheck"
          :validation-messages="{
            notAllowed:
              'Dieser Name existiert bereits für eine andere Ansicht. Bitte wähle einen eindeutigen Namen.',
          }"
        />

        <PmCheckboxPure
          v-if="isPublicViewCheckboxVisible"
          v-model:value="formIsPublicView"
          :disabled="loading"
          class="PmViewDuplicatePure-public"
          note="Öffentliche Ansichten können von anderen Benutzern verwendet werden. Admins können die Ansicht bearbeiten/löschen."
        >
          Öffentlich
        </PmCheckboxPure>
      </form>
    </div>

    <template #footer>
      <PmButtonListPure align="right">
        <PmButtonPure
          label="Abbrechen"
          :disabled="loading"
          @click="emit('close')"
        />

        <PmButtonPure
          label="Ansicht duplizieren"
          variant="primary"
          type="submit"
          :form="`form${uid}`"
          :loading="loading"
        />
      </PmButtonListPure>
    </template>
  </PmModalPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmViewDuplicatePure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref } from 'vue'
import cuid from '@paralleldrive/cuid2'

import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'

export interface Props {
  loading?: boolean
  error?: boolean
  errorMessage?: string
  errorDetails?: string[]
  isPublicViewCheckboxVisible?: boolean
  userNameOfViewOwner: string
  existingViewNames: string[]
}

const props = withDefaults(defineProps<Props>(), {
  existingViewNames: () => [],
})

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'duplicate', payload: { name: string; isPublic: boolean }): void
}>()

const uid = cuid.createId()
const name = ref()
const formIsPublicView = ref(false)
const triggerValidityCheck = ref(0)

function duplicate() {
  triggerValidityCheck.value += 1

  emit('duplicate', {
    name: name.value,
    isPublic: formIsPublicView.value,
  })
}
</script>

<style lang="scss">
.PmViewDuplicatePure {
  $block: &;

  &-title {
    @include typography.h4;
  }

  &-notification {
    margin-bottom: var(--space-gutters);
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
