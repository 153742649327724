<template>
  <div class="PmRichTextPure">
    <slot />
  </div>
</template>

<script>
/**
 * Component for displaying rich text (lists, links, etc)
 * It's far from finished and will be extended as needed
 */

export default {
  name: 'PmRichTextPure',
  components: {},
}
</script>

<style lang="scss">
.PmRichTextPure {
  $block: &;

  hyphens: auto;

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  ul {
    margin-bottom: 1em;

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    & ul {
      margin-left: 5px;
    }

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    &:last-child {
      margin-bottom: 0;
    }

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    & li {
      position: relative;
      padding-left: 10px;

      /* stylelint-disable-next-line plugin/selector-bem-pattern */
      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: currentColor;
        position: absolute;
        user-select: none;
        top: 0.5em;
        left: 0;
      }
    }
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  a {
    text-decoration: underline;
  }
}
</style>
