<template>
  <PmDropzonePure
    v-slot="props"
    :type="DRAG_AND_DROP_TYPE.UPDATE_PROJECT_GROUP"
    @drop="onDrop"
  >
    <div
      class="PmGroupManagerItemPure"
      :class="{ ...props.classes, ...classes.root }"
    >
      <div class="PmGroupManagerItemPure-label">Gruppe {{ number }}</div>

      <PmLoadingPure
        v-if="xstate.state.value.matches('save.saving')"
        size="small"
        class="PmGroupManagerItemPure-loading"
      />

      <div
        v-if="statusVisible"
        class="PmGroupManagerItemPure-status"
        :class="classes.status"
      >
        <PmIconPure :key="icon" :name="icon" />
      </div>
    </div>
  </PmDropzonePure>
</template>

<script>
import { defineComponent, toRef } from 'vue'
import { ICONS } from '@/constants/icons'
import { DRAG_AND_DROP_TYPE } from '@/constants/persoplan'

import { useXState } from '@/composition/useXState'
import propValidator from '@/functional/propValidator'
import {
  PmGroupManagerItemPureState,
  states,
} from '@/components/persoplan/PmGroupManager/PmGroupManagerItemPureState'

import PmDropzonePure from '@/components/utilities/PmDropzonePure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmGroupManagerItemPure',
  components: {
    PmDropzonePure,
    PmLoadingPure,
    PmIconPure,
  },

  props: {
    number: { type: Number, required: true },
    isLoading: { type: Boolean, default: undefined },
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    disabled: { type: Boolean, default: false },
  },

  emits: ['drop'],

  setup(props) {
    const xstate = useXState(PmGroupManagerItemPureState, {
      syncStateWith: toRef(props, 'state'),
    })

    return {
      DRAG_AND_DROP_TYPE,

      xstate,
    }
  },

  computed: {
    icon() {
      if (this.xstate.state.value.matches('save.success')) return ICONS.CHECK
      if (this.xstate.state.value.matches('save.failed')) return ICONS.ALERT

      return undefined
    },

    statusVisible() {
      if (this.xstate.state.value.matches('save.success')) return true
      if (this.xstate.state.value.matches('save.failed')) return true

      return false
    },

    classes() {
      return {
        root: {
          'is-disabled': this.disabled,
        },
        status: {
          'is-success': this.xstate.state.value.matches('save.success'),
          'is-danger': this.xstate.state.value.matches('save.failed'),
        },
      }
    },
  },

  methods: {
    onDrop(event) {
      this.$emit('drop', {
        projectId: event.data.projectId,
        number: this.number,
      })
    },
  },
})
</script>

<style lang="scss">
.PmGroupManagerItemPure {
  $block: &;

  @include mixin.transition-hover((background-color, border-color));

  background-color: color.$gray-100;
  border: 1px solid color.$gray-400;
  border-radius: constant.$borderRadius-default;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;

  &.is-draggedOver {
    background-color: color.$gray-300;
    border-color: color.$gray-500;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &-label {
    padding: 12px;
    padding-right: 40px;
    margin-right: auto;
  }

  &-loading {
    position: absolute;
    right: 12px;
  }

  &-status {
    position: absolute;
    right: 8px;
    width: 28px;
    height: 28px;

    &.is-success {
      color: color.$success-500;
    }

    &.is-danger {
      color: color.$danger-600;
    }
  }
}
</style>
