<template>
  <PmAppNotificationPure variant="danger" :title="message">
    <template v-if="details && details.length > 1">
      <ul>
        <li v-for="detail in details" :key="detail">
          {{ detail }}
        </li>
      </ul>
    </template>

    <template v-if="details && details.length === 1">
      {{ details[0] }}
    </template>

    <slot />
  </PmAppNotificationPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmInlineErrorNotificationPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'

export interface Props {
  message?: string
  details?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  message: 'Unbekannter Fehler',
})
</script>
