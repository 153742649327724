<template>
  <component
    :is="link.tag.value"
    v-bind="link.props.value"
    class="PmContextNavigationItemPure"
    :class="classes"
    @click="emit('click')"
  >
    <div class="PmContextNavigationItemPure-content">
      <div v-if="icon" class="PmContextNavigationItemPure-iconContainer">
        <PmIconPure
          :key="icon"
          class="PmContextNavigationItemPure-icon"
          :name="icon"
        />
      </div>

      <div class="PmContextNavigationItemPure-inner">
        <div class="PmContextNavigationItemPure-label">
          {{ label }}
        </div>

        <div v-if="note" class="PmContextNavigationItemPure-note">
          {{ note }}
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmContextNavigationItemPure'

export const propTypes = {
  variant: {
    allowed: ['primary', 'secondary', 'danger'],
    default: 'secondary',
  },
  icon: Object.values(ICONS),
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { type Icon, ICONS } from '@/constants/icons'

import { useLink, type Props as PropsUseLink } from '@/composition/useLink'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  label?: string
  icon?: Icon
  disabled?: boolean
  isVisible?: boolean
  variant?: (typeof propTypes.variant.allowed)[number]
  note?: string
  hasNotification?: boolean
  // Link props
  target?: PropsUseLink['target']
  href?: PropsUseLink['href']
  to?: PropsUseLink['to']
  linkOpenInNewWindow?: PropsUseLink['linkOpenInNewWindow']
}

const props = withDefaults(defineProps<Props>(), {
  variant: propTypes.variant.default,
  isVisible: true,
})

const emit = defineEmits<{
  (event: 'click'): void
}>()

const link = useLink(props)

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--variantPrimary`]: props.variant === 'primary',
    [`${COMPONENT_NAME}--variantSecondary`]: props.variant === 'secondary',
    [`${COMPONENT_NAME}--variantDanger`]: props.variant === 'danger',
    'is-disabled': props.disabled,
    'has-notification': props.hasNotification,
  }
})
</script>

<style lang="scss">
@use 'sass:math';

.PmContextNavigationItemPure {
  $block: &;

  @include mixin.transition-hover((color, background-color));
  @include cssVar.define($block, 'colorText', color.$text-default);
  @include cssVar.define(
    $block,
    'colorText-hover',
    cssVar.use($block, 'colorText')
  );
  @include cssVar.define($block, 'colorBackground', transparent);
  @include cssVar.define($block, 'colorBackground-hover', color.$gray-100);
  @include cssVar.define($block, 'colorIcon', color.$gray-400);
  @include cssVar.define($block, 'colorIcon-hover', color.$gray-600);

  &--variantPrimary {
    @include cssVar.define($block, 'colorBackground-hover', color.$primary-50);
    @include cssVar.define($block, 'colorIcon', color.$primary-500);
    @include cssVar.define($block, 'colorIcon-hover', color.$primary-500);
  }

  &--variantDanger {
    @include cssVar.define($block, 'colorText', color.$danger-600);
    @include cssVar.define($block, 'colorBackground-hover', color.$danger-50);
    @include cssVar.define($block, 'colorIcon', color.$danger-400);
    @include cssVar.define($block, 'colorIcon-hover', color.$danger-600);
  }

  color: cssVar.use($block, 'colorText');
  background-color: cssVar.use($block, 'colorBackground');

  &:hover,
  &.is-hover {
    background-color: cssVar.use($block, 'colorBackground-hover');
    color: cssVar.use($block, 'colorText-hover');
  }

  &.is-disabled {
    pointer-events: none;
  }

  &-content {
    display: flex;
    align-items: flex-start;

    #{$block}.is-disabled & {
      opacity: 0.5;
    }
  }

  &-iconContainer {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 8px;

    &::before {
      $size: 8px;

      content: '';
      background-color: color.$danger-500;
      width: $size;
      height: $size;
      border-radius: $size;
      position: absolute;
      top: math.div($size, 4) * -1;
      right: math.div($size, 4) * -1;
      z-index: 1;

      #{$block}:not(.has-notification) & {
        display: none;
      }
    }
  }

  &-icon {
    @include mixin.transition-hover(color, $block);

    color: cssVar.use($block, 'colorIcon');
    position: relative;

    #{$block}:hover &,
    #{$block}.is-hover & {
      color: cssVar.use($block, 'colorIcon-hover');
    }
  }

  &-label {
    font-weight: 600;
    text-align: left;
    line-height: 20px;
  }

  &-note {
    font-size: constant.$fontSize-default;
    font-weight: 500;
    color: color.$gray-700;
    max-width: 60ch;
  }
}
</style>
