<template>
  <div class="PmResourceAllocationEditPure">
    <div
      v-if="!formVisible && !waitForAllocationVisible"
      class="PmResourceAllocationEditPure-empty"
    >
      Das hier sollte nicht sichtbar sein
    </div>

    <div
      v-if="waitForAllocationVisible"
      class="PmResourceAllocationEditPure-waitForAllocation"
    >
      <PmIconPure
        v-if="waitForAllocationIcon"
        :key="waitForAllocationIcon"
        :name="waitForAllocationIcon"
        class="PmResourceAllocationEditPure-waitForAllocationIcon"
      />
    </div>

    <form
      v-show="formVisible"
      class="PmResourceAllocationEditPure-form"
      @submit.prevent="onFormSubmit"
    >
      <div v-if="label" class="PmResourceAllocationEditPure-formTitle">
        {{ label }}
      </div>

      <div class="PmResourceAllocationEditPure-inputs">
        <PmDropdownPure
          :options="statusOptions"
          :value="value.statusId"
          label="Status"
          class="PmResourceAllocationEditPure-input"
          required
          :note="noteStatus"
          @input="(value) => onInput('status', value)"
        />

        <PmInputPure
          label="Bemerkung"
          class="PmResourceAllocationEditPure-input"
          :value="value.note"
          @input="(value) => onInput('note', value)"
        />

        <PmInputWithStatusPure
          v-if="
            type === RESOURCE_TYPE.ADDRESS || type === RESOURCE_TYPE.FREELANCER
          "
          label="Travel"
          class="PmResourceAllocationEditPure-input"
          :value="value.travel"
          @input="(value) => onInput('travel', value)"
        />

        <PmInputWithStatusPure
          v-if="
            type === RESOURCE_TYPE.ADDRESS || type === RESOURCE_TYPE.FREELANCER
          "
          label="Hotel"
          class="PmResourceAllocationEditPure-input"
          :value="value.hotel"
          @input="(value) => onInput('hotel', value)"
        />

        <template
          v-if="
            type === RESOURCE_TYPE.ADDRESS || type === RESOURCE_TYPE.FREELANCER
          "
        >
          <div class="PmResourceAllocationEditPure-vehicle">
            <PmInputPure
              class="PmResourceAllocationEditPure-vehicleInput"
              label="Fahrzeug"
              :value="vehicle"
              :disabled="true"
              :loading="vehicleLoading"
            />

            <PmButtonPure
              v-if="vehicle"
              variant="danger"
              title="Verknüpfung mit Fahrzeug aufheben"
              :icon="ICONS.UNLINK"
              @click="emit('unlinkVehicleAllocation')"
            />

            <PmButtonPure
              :icon="ICONS.VEHICLE"
              :label="vehicle ? 'Fahrzeug ändern' : 'Fahrzeug zuweisen'"
              @click="emit('startVehicleAllocation')"
            />
          </div>
        </template>

        <template v-if="type === RESOURCE_TYPE.VEHICLE">
          <div class="PmResourceAllocationEditPure-driver">
            <PmInputPure
              class="PmResourceAllocationEditPure-driverInput"
              label="Fahrer"
              :value="driver"
              :disabled="true"
              :loading="driverLoading"
            />

            <PmButtonPure
              v-if="driver"
              variant="danger"
              title="Verknüpfung mit Fahrer aufheben"
              :icon="ICONS.UNLINK"
              @click="emit('unlinkDriverAllocation')"
            />

            <PmButtonPure
              :icon="ICONS.DRIVER"
              :label="driver ? 'Fahrer ändern' : 'Fahrer zuweisen'"
              @click="emit('startDriverAllocation')"
            />
          </div>
        </template>
      </div>

      <div
        v-show="hasSlotContent.conflicts"
        class="PmResourceAllocationEditPure-conflicts"
      >
        <slot name="conflicts" />
      </div>

      <PmSectionPure
        v-if="canHaveResourceRequest && resourceRequest"
        title="Anfrage"
        variant="default"
        class="PmResourceAllocationEditPure-resourceRequest"
      >
        <div class="PmResourceAllocationEditPure-resourceRequestStatus">
          <div class="PmResourceAllocationEditPure-resourceRequestStatusTitle">
            Feedback von {{ resourceRequest.nameOfRequestedPerson }}
          </div>

          <div
            class="PmResourceAllocationEditPure-resourceRequestStatusContent"
          >
            <PmStatusPillPure
              type="resourceRequestFeedback"
              :resource-request-feedback-status="resourceRequest.statusFeedback"
            />

            <template
              v-if="
                resourceRequest.availableStartDate &&
                resourceRequest.availableEndDate
              "
            >
              für
              {{
                startEndDateForText(
                  resourceRequest.availableStartDate,
                  resourceRequest.availableEndDate
                )
              }}
            </template>
          </div>
        </div>

        <div class="PmResourceAllocationEditPure-resourceRequestStatus">
          <div class="PmResourceAllocationEditPure-resourceRequestStatusTitle">
            Status
          </div>

          <PmFromToPure>
            <template #from>
              <PmStatusPillPure
                type="resourceRequest"
                :resource-request-status="resourceRequest.status"
              />
            </template>

            <template v-if="newResourceRequestStatus !== undefined" #to>
              <PmStatusPillPure
                type="resourceRequest"
                :resource-request-status="newResourceRequestStatus"
              />
            </template>
          </PmFromToPure>
        </div>

        <!-- <PmDropdownPure
          label="Status"
          :options="statusResourceRequestOptions"
          :value="value.statusResourceRequestId ?? undefined"
          @input="(value) => onInput('statusResourceRequest', value)"
        /> -->
      </PmSectionPure>

      <div
        v-show="hasSlotContent.resourceDay"
        class="PmResourceAllocationEditPure-resourceDay"
      >
        <slot
          name="resourceDay"
          :user-intents-to-delete-resource-allocation="
            xstate.state.value.matches('update.delete.askForConfirmation')
          "
        />
      </div>

      <div class="PmResourceAllocationEditPure-control">
        <PmErrorNotificationPure
          v-if="xstate.meta.value.error"
          class="PmResourceAllocationEditPure-notification"
          :message="errorMessage || xstate.meta.value.errorMessage"
          :details="errorDetails"
        />

        <PmAskForConfirmationPure
          v-if="xstate.state.value.matches('update.delete')"
          title="Zuordnung löschen"
          :is-loading="xstate.state.value.matches('update.delete.deleting')"
          @delete="xstate.service.value.send('CONFIRM')"
          @cancel="xstate.service.value.send('CANCEL')"
        >
          Die Zuordnung <b>{{ label }}</b> kann nicht wiederhergestellt werden.
        </PmAskForConfirmationPure>

        <PmButtonListPure v-if="!xstate.state.value.matches('update.delete')">
          <template v-if="xstate.state.value.matches('create')">
            <PmButtonPure
              label="Nicht erstellen"
              variant="secondary"
              :disabled="xstate.state.value.matches('create.save.saving')"
              :icon="ICONS.CLOSE"
              @click="emit('cancel')"
            />

            <PmButtonListDividerPure />

            <PmButtonPure
              label="Erstellen + Schließen"
              variant="primary"
              type="submit"
              :icon="ICONS.PLUS"
              :loading="xstate.state.value.matches('create.save.saving')"
            />
          </template>

          <template v-if="xstate.state.value.matches('update')">
            <PmButtonPure
              label="Abbrechen"
              variant="secondary"
              :disabled="xstate.state.value.matches('update.save.saving')"
              :icon="ICONS.CLOSE"
              @click="$emit('cancel')"
            />

            <PmButtonListDividerPure />

            <PmButtonPure
              label="Zuordnung Löschen"
              variant="danger"
              :disabled="xstate.state.value.matches('update.save.saving')"
              :icon="ICONS.DELETE"
              @click="xstate.service.value.send('DELETE')"
            />

            <PmButtonPure
              label="Speichern + Schließen"
              variant="primary"
              type="submit"
              :icon="ICONS.SAVE"
              :loading="xstate.state.value.matches('update.save.saving')"
            />
          </template>
        </PmButtonListPure>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceAllocationEditPure'

export const propTypes = {
  state: {
    allowed: states,
  },
  type: {
    allowed: [
      RESOURCE_TYPE.ADDRESS,
      RESOURCE_TYPE.FREELANCER,
      RESOURCE_TYPE.VEHICLE,
    ] as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef, computed } from 'vue'
import { cloneDeep, isNil } from 'lodash-es'

import { ICONS } from '@/constants/icons'
import {
  RESOURCE_TYPE,
  type StatusResourceRequest,
  type StatusResourceRequestFeedback,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  type ResourceAllocationStatus,
} from '@/constants/persoplan'

import {
  PmResourceAllocationEditPureState,
  states,
  type State,
} from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationEditPureState'
import { useXState } from '@/composition/useXState'
import { useHasSlotContent } from '@/composition/useHasSlotContent'
import { startEndDateForText } from '@/utilities/date'
import { lookup } from '@/utilities/misc'

import PmDropdownPure, {
  type Emit as EmitDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmAskForConfirmationPure from '@/components/PmAskForConfirmation/PmAskForConfirmationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmInputWithStatusPure from '@/components/basics/PmInputWithStatus/PmInputWithStatusPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'
import PmSectionPure from '@/components/basics/PmSectionPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmFromToPure from '@/components/persoplan/PmFromTo/PmFromToPure.vue'

import type { Nilable } from '@/types/misc'

export interface StatusOption {
  id: number | string
  label: string
}

export interface FormData {
  statusId?: number | string
  note?: Nilable<string>
  travel?: Nilable<string>
  hotel?: Nilable<string>
}

export type ResourceRequest = {
  availableStartDate?: Nilable<Date>
  availableEndDate?: Nilable<Date>
  status?: Nilable<StatusResourceRequest>
  statusFeedback?: Nilable<StatusResourceRequestFeedback>
  nameOfRequestedPerson?: string
}

export interface Props {
  state?: State
  value: FormData
  type: (typeof propTypes.type.allowed)[number]
  label: Nilable<string>
  status?: string
  vehicle?: string
  driver?: string
  errorMessage?: string
  errorDetails?: string[]
  statusOptions: StatusOption[]
  vehicleLoading?: boolean
  driverLoading?: boolean
  resourceRequest?: ResourceRequest
}

const props = withDefaults(defineProps<Props>(), {
  statusOptions: () => [],
})

const emit = defineEmits<{
  (event: 'save'): void
  (event: 'cancel'): void
  (event: 'unlinkVehicleAllocation'): void
  (event: 'startVehicleAllocation'): void
  (event: 'unlinkDriverAllocation'): void
  (event: 'startDriverAllocation'): void
  (event: 'input', newValue: FormData): void
  (event: 'update:value', newValue: FormData): void
  (event: 'delete'): void
  (event: 'create'): void
  (event: 'update:userIntentsToDelete', value: boolean): void
}>()

const xstate = useXState(PmResourceAllocationEditPureState, {
  actions: {
    emitDelete: () => emit('delete'),
    userIntentsToDelete: () => emit('update:userIntentsToDelete', true),
    userDoesNotIntentToDelete: () => emit('update:userIntentsToDelete', false),
  },
  syncStateWith: toRef(props, 'state'),
})

const hasSlotContent = useHasSlotContent(['conflicts', 'resourceDay'])

const waitForAllocationIcon = computed(() => {
  if (
    xstate.state.value.matches('create.vehicleAllocation') ||
    xstate.state.value.matches('update.vehicleAllocation')
  )
    return ICONS.VEHICLE_SELECT

  if (
    xstate.state.value.matches('create.driverAllocation') ||
    xstate.state.value.matches('update.driverAllocation')
  )
    return ICONS.DRIVER_SELECT

  return null
})

const waitForAllocationVisible = computed(() => {
  if (xstate.state.value.matches('default')) return false

  if (
    xstate.state.value.matches('create.vehicleAllocation') ||
    xstate.state.value.matches('update.vehicleAllocation')
  ) {
    return true
  }

  if (
    xstate.state.value.matches('create.driverAllocation') ||
    xstate.state.value.matches('update.driverAllocation')
  ) {
    return true
  }

  return false
})

const formVisible = computed(() => {
  if (xstate.state.value.matches('default')) return false
  if (waitForAllocationVisible.value) return false

  return true
})

const onInput = (
  type: string | StatusResourceRequest,
  value: EmitDropdownPure['input'] | Nilable<string>
) => {
  const isString = typeof value === 'string'
  const isNumber = typeof value === 'number'

  const newValue = cloneDeep(props.value)

  if (type === 'status' && value) {
    if (!isNumber && !isNil(value)) throw new Error('value is not a number')
    newValue.statusId = value
  }

  if (type === 'note') {
    if (!isString && !isNil(value)) throw new Error('value is not a string')
    newValue.note = value
  }

  if (type === 'travel') {
    if (!isString && !isNil(value)) throw new Error('value is not a string')
    newValue.travel = value
  }

  if (type === 'hotel') {
    if (!isString && !isNil(value)) throw new Error('value is not a string')
    newValue.hotel = value
  }

  emit('input', newValue)
  emit('update:value', newValue)
}

const onFormSubmit = () => {
  if (xstate.state.value.matches('create')) {
    emit('create')
  }

  if (xstate.state.value.matches('update')) {
    emit('save')
  }
}

const canHaveResourceRequest = computed(() => {
  if (props.type === 'address') return true
  if (props.type === 'freelancer') return true

  return false
})

const newResourceRequestStatusLookup: Record<
  ResourceAllocationStatus,
  StatusResourceRequest | null
> = {
  cancelled: 'decline',
  confirmed: 'accept',
  considered: null,
  requested: null,
  reserved: 'reserve',
}

const newResourceRequestStatus = computed(() => {
  if (!props.resourceRequest) return
  if (!props.value.statusId) return

  const newResourceAllocationStatus = lookup(
    props.value.statusId,
    STATUS_RESOURCE_ALLOCATION_LOOKUP
  )

  if (!newResourceAllocationStatus) return

  const result = newResourceRequestStatusLookup[newResourceAllocationStatus]

  // Old and new status are the same, no change
  const isSame = result === props.resourceRequest.status
  const isBothNil = isNil(result) && isNil(props.resourceRequest.status)
  if (isSame || isBothNil) return

  return result
})

const noteStatus = computed(() => {
  if (newResourceRequestStatus.value === undefined) return

  return `Der Status der Anfrage wird auf automatisch angepasst`
})

const newResourceRequestStatusFeedbackLookup: Partial<
  Record<ResourceAllocationStatus, StatusResourceRequestFeedback | null>
> = {
  confirmed: 'accept',
}

const newResourceRequestStatusFeedback = computed(() => {
  if (!props.resourceRequest) return
  if (!props.value.statusId) return

  // The status will only be set if the request hasn't been answered by the requested resource yet
  if (
    props.resourceRequest.statusFeedback &&
    props.resourceRequest.statusFeedback !== 'unknown'
  )
    return

  const newResourceAllocationStatus = lookup(
    props.value.statusId,
    STATUS_RESOURCE_ALLOCATION_LOOKUP
  )

  if (!newResourceAllocationStatus) return

  const result =
    newResourceRequestStatusFeedbackLookup[newResourceAllocationStatus]

  // Old and new status are the same, no change
  const isSame = result === props.resourceRequest.statusFeedback
  const isBothNil = isNil(result) && isNil(props.resourceRequest.statusFeedback)
  if (isSame || isBothNil) return

  return result
})
</script>

<style lang="scss">
.PmResourceAllocationEditPure {
  $block: &;

  max-width: 600px;

  &-waitForAllocation {
    display: flex;
    align-items: center;
    gap: var(--space-gutters);
  }

  &-waitForAllocationIcon {
    width: 32px;
    height: 32px;
  }

  &-formTitle {
    @include typography.h4($includeMargin: false);

    margin-bottom: 16px;
  }

  &-input {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &-driver,
  &-vehicle {
    display: flex;
    align-items: flex-end;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &-driverInput,
  &-vehicleInput {
    flex-grow: 1;
  }

  &-resourceRequest {
    margin-top: 20px;
  }

  &-resourceRequestStatus {
    margin-bottom: 12px;
  }

  &-resourceRequestStatusTitle {
    @include mixin.textLabel;

    margin-bottom: 4px;
  }

  &-conflicts {
    margin-top: 12px;
  }

  &-resourceDay {
    margin-top: 12px;
  }

  &-control {
    margin-top: 20px;
  }

  &-notification {
    margin-bottom: 8px;
  }
}
</style>
