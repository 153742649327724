<template>
  <div :class="componentClass.root">
    <PmMyRequestsListPure
      :is-loading="isLoading"
      :requests="requestsNormalized"
      @open-details="emit('openDetails', $event)"
      @abort="emit('abort', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import PmMyRequestsListPure, {
  type Props as PropsMyRequestsListPure,
  type Emits as EmitsMyRequestsListPure,
} from '@/components/PmMyRequests/PmMyRequestsListPure.vue'
import { useQuery } from '@vue/apollo-composable'
import { MyRequestsListDocument } from '@/../generated/graphql'
import { parseServerDateString } from '@/utilities/date'
import { LEAVE_REQUEST_STATUS_LOOKUP } from '@/constants/leaveRequest'
import { POLLING_INTERVAL } from '@/constants/persoplan'
import { EXTERNAL_SERVICE_REQUEST_STATUS_LOOKUP } from '@/constants/externalServiceRequest'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  openDetails: EmitsMyRequestsListPure['openDetails']
  abort: EmitsMyRequestsListPure['abort']
}

const emit = defineEmits<{
  openDetails: [Emits['openDetails']]
  abort: [Emits['abort']]
}>()

const componentClass = useComponentClass()

const requests = useQuery(MyRequestsListDocument, null, () => ({
  pollInterval: POLLING_INTERVAL * 1_000,
  notifyOnNetworkStatusChange: true,
}))

const isLoading = computed(() => requests.loading.value)

const requestsNormalized = computed(() => {
  const result: PropsMyRequestsListPure['requests'] = []
  if (!requests.result.value) return result

  // Leave Requests
  requests.result.value.leaveRequests?.forEach((leaveRequest) => {
    if (!leaveRequest) return

    const startDate = parseServerDateString(leaveRequest.startDate)
    const endDate = parseServerDateString(leaveRequest.endDate)
    const createdDate = parseServerDateString(leaveRequest.created)

    if (!startDate || !endDate || !createdDate)
      throw new Error('startDate, endDate or createdDate is undefined')

    const type = leaveRequest.type
    if (!type) throw new Error('type is undefined')

    const item: (typeof result)[0] = {
      id: leaveRequest.id,
      startDate,
      endDate,
      createdDate,
      status: LEAVE_REQUEST_STATUS_LOOKUP[leaveRequest.state],
      type,
      requestType: 'leaveRequest',
    }

    result.push(item)
  })

  // External Service Requests
  requests.result.value.externalServiceRequests?.forEach(
    (externalServiceRequest) => {
      if (!externalServiceRequest) return

      const startDate = parseServerDateString(externalServiceRequest.startDate)
      const endDate = parseServerDateString(externalServiceRequest.endDate)
      const createdDate = parseServerDateString(externalServiceRequest.created)

      if (!startDate || !endDate || !createdDate)
        throw new Error('startDate, endDate or createdDate is undefined')

      const item: (typeof result)[0] = {
        id: externalServiceRequest.id,
        startDate,
        endDate,
        createdDate,
        status:
          EXTERNAL_SERVICE_REQUEST_STATUS_LOOKUP[externalServiceRequest.state],
        requestType: 'externalServiceRequest',
      }

      result.push(item)
    }
  )

  return result
})
</script>

<style lang="scss">
.PmMyRequestsList {
  $block: &;
}
</style>
