<template>
  <div class="PmRequestOverviewListPure">
    <PmTablePure
      :columns="columns"
      :sortable="true"
      :is-loading="isLoading"
      @update-sorting="(event) => emit('updateSorting', event)"
    >
      <slot name="table" />
    </PmTablePure>
  </div>
</template>

<script lang="ts">
export const columns: TableColumns = [
  {
    id: 'createdDate',
    label: 'Erstellt',
  },
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Typ' },
  {
    id: 'date',
    label: 'Datum',
    sort: {
      property: 'start',
    },
  },
  { id: 'status', label: 'Status' },
]
</script>

<script setup lang="ts">
import PmTablePure, {
  type TableColumns,
  type Emit as EmitTablePure,
} from '@/components/basics/PmTable/PmTablePure.vue'

export interface Props {
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'updateSorting', payload: EmitTablePure['updateSorting']): void
}>()
</script>

<style lang="scss">
.PmRequestOverviewListPure {
  $block: &;

  user-select: none;
}
</style>
