import { setup, fromPromise, assertEvent, assign } from 'xstate5'
import { assertFriendlyError } from '@/functional/friendlyErrors'
import type {
  ApproveRequestVariables,
  RejectRequestVariables,
} from '@/components/PmRequestOverview/PmRequestOverviewDetails.vue'

export const PmMyRequestsState = setup({
  types: {
    events: {} as
      | {
          type: 'abort'
          variables: {
            id: number
            type: 'leaveRequest' | 'externalServiceRequest'
          }
        }
      | { type: 'cancel' }
      | { type: 'close' }
      | { type: 'confirmAbort' },

    meta: {} as {
      error?: boolean
      message?: string
    },

    context: {} as {
      requestId?: number
      requestType?: 'leaveRequest' | 'externalServiceRequest'
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  actions: {
    resetContext: assign({
      requestId: undefined,
      requestType: undefined,
      error: undefined,
      errorDetails: undefined,
    }),

    showAbortSuccessNotification: () => {
      throw new Error('showAbortSuccessNotification not implemented')
    },
  },

  actors: {
    abortRequest: fromPromise<
      void,
      {
        variables: {
          id: number
          type: 'leaveRequest' | 'externalServiceRequest'
        }
      }
    >(async () => {
      throw new Error('abortRequest not implemented')
    }),
  },
}).createMachine({
  id: 'PmRequestOverviewDetailsState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      entry: 'resetContext',

      on: {
        abort: 'abort',
      },
    },

    abort: {
      initial: 'waitForConfirm',
      entry: assign(({ event }) => {
        assertEvent(event, 'abort')
        return {
          requestId: event.variables.id,
          requestType: event.variables.type,
        }
      }),

      states: {
        waitForConfirm: {
          on: {
            cancel: '#PmRequestOverviewDetailsState.default',
            confirmAbort: 'saving',
          },
        },

        saving: {
          invoke: {
            id: 'abortRequest',
            src: 'abortRequest',
            input: ({ context, event }) => {
              assertEvent(event, 'confirmAbort')
              if (!context.requestId || !context.requestType) {
                throw new Error('context is not filled')
              }

              return {
                variables: {
                  id: context.requestId,
                  type: context.requestType,
                },
              }
            },
            onDone: {
              target: 'success',
            },
            onError: {
              target: 'error',
              actions: ({ context, event }) => {
                assertFriendlyError(event.error)
                context.error = event.error.message
                context.errorDetails = event.error.details
              },
            },
          },
        },

        success: {
          entry: 'showAbortSuccessNotification',
          always: '#PmRequestOverviewDetailsState.default',
        },

        error: {
          on: {
            cancel: '#PmRequestOverviewDetailsState.default',
          },
        },
      },
    },
  },
})
