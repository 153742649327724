<template>
  <div class="PmResourceDayItemPure" :class="classes">
    <div class="PmResourceDayItemPure-timeIndicators">
      <PmTimeIndicatorPure
        class="PmResourceDayItemPure-timeIndicator"
        :start-date="startDate"
        :end-date="endDate"
        :container-start-date="startOfDay(containerStartDate)"
        :container-end-date="endOfDay(containerEndDate)"
        :is-time-always-visible="true"
      />

      <PmTimeIndicatorPure
        v-for="item in conflictingItemsNormalized"
        :key="item.key"
        class="PmResourceDayItemPure-timeIndicator PmResourceDayItemPure-timeIndicator--conflict"
        :start-date="item.startDate"
        :end-date="item.endDate"
        :container-start-date="startOfDay(containerStartDate)"
        :container-end-date="endOfDay(containerEndDate)"
        :is-conflict="true"
        :show-overflow-marker="false"
      />
    </div>

    <div class="PmResourceDayItemPure-content">
      <div v-if="hasMeta" class="PmResourceDayItemPure-meta">
        <PmPillPure
          v-if="jobNumber"
          class="PmResourceDayItemPure-pill"
          :label="jobNumber"
          :color="jobStatusColor"
          :title="
            jobStatusLabel ? `${jobNumber} (${jobStatusLabel})` : undefined
          "
        />

        <PmResourceActionPure
          v-if="dutyOrder"
          class="PmResourceDayItemPure-dutyOrder"
          :label="dutyOrder"
          :is-button="false"
          :variant="alertOnDutyOrder ? 'danger' : undefined"
        />
      </div>

      <PmButtonListPure v-if="hasActions" class="PmResourceDayItemPure-actions">
        <PmButtonPure
          v-if="jumpVisible"
          :icon="ICONS_SMALL.JUMP"
          alternative="ghost"
          size="small"
          title="Im Kalender anzeigen"
          @click.stop="emit('jump')"
        />

        <PmButtonPure
          v-if="editVisible"
          title="Bearbeiten"
          :icon="ICONS.EDIT"
          size="small"
          alternative="ghost"
          @click.stop="emit('edit')"
        />
      </PmButtonListPure>

      <div class="PmResourceDayItemPure-header">
        <div v-if="title" class="PmResourceDayItemPure-title">
          {{ title }}
        </div>

        <div v-if="subtitle" class="PmResourceDayItemPure-subtitle">
          {{ subtitle }}
        </div>

        <div class="PmResourceDayItemPure-resourceAllocationStatus">
          <PmStatusDotPure
            type="resourceAllocation"
            :resource-allocation-status="resourceAllocationStatus"
          />
          <span class="PmResourceDayItemPure-resourceAllocationStatusLabel">{{
            resourceAllocationStatusLabel
          }}</span>
        </div>
      </div>

      <PmNotePure
        v-if="note"
        :note="note"
        class="PmResourceDayItemPure-note"
        :compact="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceDayItemPure'

export const propTypes = {
  type: {
    allowed: Object.values(RESOURCE_DAY_ITEM_TYPE),
  },

  resourceAllocationStatus: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  },

  jobStatus: {
    allowed: Object.values(STATUS),
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, toRef } from 'vue'

import {
  isAfter,
  isBefore,
  startOfDay,
  endOfDay,
  areIntervalsOverlapping,
} from 'date-fns'

import { cloneDeep } from 'lodash-es'

import {
  RESOURCE_DAY_ITEM_TYPE,
  type JobStatus,
  STATUS,
  type ResourceAllocationStatus,
  STATUS_RESOURCE_ALLOCATION,
} from '@/constants/persoplan'

import type { ResourceDayItemType } from '@/constants/persoplan'
import { formatToServerDateString } from '@/utilities/date'
import { ICONS, ICONS_SMALL } from '@/constants/icons'
import { useJobStatus } from '@/composition/useJobStatus'

import PmTimeIndicatorPure from '@/components/basics/PmTimeIndicator/PmTimeIndicatorPure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmNotePure from '@/components/PmNote/PmNotePure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmResourceActionPure from '@/components/persoplan/PmResourceActionPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'

import type { Nilable } from '@/types/misc'

export interface ConflictingItem {
  startDate: Date
  endDate: Date
}

/**
 * Status Job via Pill
 * Job
 * Appointment
 * Status of Resource Allocation
 */

export interface Props {
  id: number // TODO: Maybe this does not need to be here
  isLoading?: boolean
  title?: Nilable<string>
  subtitle?: Nilable<string>
  note?: Nilable<string>
  containerStartDate: Date
  containerEndDate: Date
  startDate: Date
  endDate: Date
  type: ResourceDayItemType
  userCanEdit?: boolean
  dutyOrder?: Nilable<number>
  alertOnDutyOrder?: boolean
  conflictingItems?: ConflictingItem[]
  resourceAllocationStatus?: ResourceAllocationStatus
  resourceAllocationStatusLabel?: string
  jobNumber?: string
  jobStatus?: JobStatus
  jobStatusLabel?: string
  /**
   * Indicates that the item should not be editable, for example because
   * it was created by a leave request automatically
   */
  isLocked?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'jump'): void
  (event: 'edit'): void
}>()

const { color: jobStatusColor } = useJobStatus({
  status: toRef(props, 'jobStatus'),
})

const classes = computed(() => {
  return {
    'is-loading': props.isLoading,
    [`${COMPONENT_NAME}--noMeta`]: !hasMeta.value,
  }
})

const conflictingItemsNormalized = computed(() => {
  if (!props.conflictingItems) return

  // Create a copy so we can manipulate stuff
  const conflictingItemsCopy = cloneDeep(props.conflictingItems)

  /**
   * Filter out all conflicting items which are completely outside of the start/end date timeframe
   */
  const conflictingItemsInsideOfTimeframe = conflictingItemsCopy.filter(
    (item) => {
      return areIntervalsOverlapping(
        {
          start: item.startDate,
          end: item.endDate,
        },
        {
          start: props.startDate,
          end: props.endDate,
        }
      )
    }
  )

  return conflictingItemsInsideOfTimeframe.map((item, index) => {
    const key = `${formatToServerDateString(
      item.startDate
    )}.${formatToServerDateString(item.endDate)}.${index}`

    // Normalize to startDate/endDate
    if (isBefore(item.startDate, props.startDate)) {
      item.startDate = props.startDate
    }

    if (isAfter(item.endDate, props.endDate)) {
      item.endDate = props.endDate
    }

    return {
      ...item,
      key,
    }
  })
})

const editVisible = computed(() => {
  if (!props.userCanEdit) return false
  if (props.isLocked) return false
  return props.type === RESOURCE_DAY_ITEM_TYPE.RESOURCE_STATE
})

const jumpVisible = computed(() => {
  return props.type === RESOURCE_DAY_ITEM_TYPE.RESOURCE_ALLOCATION
})

const hasMeta = computed(() => {
  if (props.jobNumber) return true
  return false
})

const hasActions = computed(() => {
  if (jumpVisible.value) return true
  if (editVisible.value) return true

  return false
})
</script>

<style lang="scss">
.PmResourceDayItemPure {
  $block: &;

  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 2px solid color.$gray-300--alpha;
  position: relative;

  &-timeIndicators {
    // Negate padding of .PmPopoverPure-content:
    margin-right: -12px;
    margin-left: -12px;
    position: relative;
  }

  &-timeIndicator {
    &--conflict {
      position: absolute;
      top: 0;
    }
  }

  &-content {
    margin-right: auto;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'meta actions'
      'header actions'
      'note note';

    #{$block}--noMeta & {
      grid-template-rows: auto auto;
      grid-template-areas:
        'header actions'
        'note note';
    }
  }

  &-meta {
    grid-area: meta;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    align-items: center;
  }

  &-dutyOrder {
    user-select: none;
  }

  &-actions {
    opacity: 0.1;
    align-self: start;
    transition: opacity constant.$duration-hoverOut;

    #{$block}:hover &,
    #{$block}.is-hover & {
      opacity: 1;
      pointer-events: all;
      transition: opacity constant.$duration-hoverIn;
    }
  }

  &-header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    font-size: constant.$fontSize-default;
    gap: 0.2em;
  }

  &-title {
    font-weight: 500;
    font-size: constant.$fontSize-large;

    #{$block}--small & {
      font-size: constant.$fontSize-default;
    }
  }

  &-subtitle {
    font-size: constant.$fontSize-default;
  }

  &-resourceAllocationStatus {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &-resourceAlloctionStatusLabel {
    font-size: constant.$fontSize-default;
  }

  &-note {
    grid-area: note;
    margin-top: 8px;
  }
}
</style>
