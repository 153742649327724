<template>
  <PmGroupManagerPure
    v-if="groupManagerVisible"
    :pass-state-to-group="saveToGroup"
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :disabled-group="groupNumberOfDraggedItem"
    @drop="onDrop"
    @close="xstate.service.value.send('CANCEL')"
  />
</template>

<script>
import { defineComponent, getCurrentInstance, computed } from 'vue'
import { cloneDeep } from 'lodash-es'

import { DRAG_AND_DROP_TYPE } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import { useXState } from '@/composition/useXState'
import { PmGroupManagerState } from '@/components/persoplan/PmGroupManager/PmGroupManagerState'
import { throwFriendlyError } from '@/functional/error'
import EventHub from '@/eventHub'
import { useDragAndDrop } from '@/pinia/dragAndDrop'

import PmGroupManagerPure from '@/components/persoplan/PmGroupManager/PmGroupManagerPure.vue'

import UpdateProjectGroupMutation from '@/graphql/UpdateProjectGroupMutation.graphql'
// import SidebarProjectQuery from '@/components/persoplan/PmSidebarProject/SidebarProjectQuery.graphql'
import ProjectFragment from '@/graphql/fragments/ProjectFragment.graphql'

export default defineComponent({
  name: 'PmGroupManager',
  components: {
    PmGroupManagerPure,
  },

  setup() {
    const instance = getCurrentInstance()
    const dragAndDrop = useDragAndDrop()

    const xstate = useXState(PmGroupManagerState, {
      services: {
        updateProjectGroup: (context, { projectId, groupNumber }) =>
          instance.ctx.updateProjectGroup({ projectId, groupNumber }),
      },
    })

    const groupManagerVisible = computed(() => {
      const dragActionInProgress =
        dragAndDrop.type === DRAG_AND_DROP_TYPE.UPDATE_PROJECT_GROUP

      if (dragActionInProgress) return true
      if (xstate.state.value.matches('save')) return true

      return false
    })

    const groupNumberOfDraggedItem = computed(() => {
      return dragAndDrop.data?.groupNumber
    })

    return {
      xstate,
      groupManagerVisible,
      groupNumberOfDraggedItem,
    }
  },

  data() {
    return {
      saveToGroup: undefined,
    }
  },

  methods: {
    onDrop(event) {
      this.saveToGroup = event.number

      this.xstate.service.value.send('SAVE', {
        projectId: event.projectId,
        groupNumber: event.number,
      })
    },

    async updateProjectGroup({ projectId, groupNumber }) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateProjectGroupMutation,

          variables: {
            projectId: projectId,
            groupNumber: groupNumber,
          },

          update: (store) => {
            // Prepare
            const queryVariables =
              this.$store.getters['queryVariables/calendar']

            const shared = {
              id: `Project:${projectId}`,
              fragment: ProjectFragment,
              variables: {
                startDate: queryVariables.startDate,
                endDate: queryVariables.endDate,
              },
            }

            // Read
            const readFragmentResult = store.readFragment({
              ...shared,
            })

            const data = cloneDeep(readFragmentResult)

            // Modify
            data.group = {
              ...data.group,
              isSortedManually: true,
              number: groupNumber,
            }

            // Write
            store.writeFragment({
              ...shared,
              data,
            })
          },
        })

        EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
      } catch (error) {
        throwFriendlyError(error)
      }
    },
  },
})
</script>

<style lang="scss">
.PmGroupManager {
  $block: &;
}
</style>
