<template>
  <div class="PmSidebarVehicles">
    <PmSidebarSectionPure
      title="Fahrzeuge"
      :collapsed="collapsableArea.isCollapsed.value"
      @expand="collapsableArea.expand"
      @collapse="collapsableArea.collapse"
    >
      <PmSidebarResourceGroupPure
        :resources="vehiclesNormalized"
        :type="RESOURCE_TYPE.VEHICLE"
        @highlight="
          (id) => store.commit('persoplan/setTimelineHighlightedId', id)
        "
        @un-highlight="store.commit('persoplan/clearTimelineHighlightedId')"
      />
    </PmSidebarSectionPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarVehicles'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { RESOURCE_TYPE } from '@/constants/persoplan'

import { normalizeVehicles } from '@/utilities/persoplan'

import { useCollapsableArea } from '@/composition/useCollapsableArea'
import { useResourceTimelines } from '@/pinia/resourceTimelines'

import PmSidebarSectionPure from '@/components/persoplan/PmSidebarSection/PmSidebarSectionPure.vue'
import PmSidebarResourceGroupPure from '@/components/persoplan/PmSidebarResourceGroupPure.vue'

import { ProMusikVehiclesDocument } from '@/../generated/graphql'

const store = useStore()
const collapsableArea = useCollapsableArea({ id: 'vehicles' })
const resourceTimelines = useResourceTimelines()

const vehiclesQuery = useQuery(ProMusikVehiclesDocument)
const vehicles = computed(() => vehiclesQuery.result.value?.vehicles)

const vehiclesNormalized = computed(() => {
  if (!vehicles.value) return

  const result = normalizeVehicles(vehicles.value)
  if (!result) return

  result.forEach((item: any) => {
    let isHighlighted = false

    if (store.state.persoplan.timelineHighlightedId === item.id) {
      isHighlighted = true
    }

    if (
      resourceTimelines.highlightedTimeline?.id === item.id &&
      resourceTimelines.highlightedTimeline?.type === 'vehicle'
    ) {
      isHighlighted = true
    }

    item.isHighlighted = isHighlighted
  })

  return result
})
</script>

<style lang="scss">
.PmSidebarVehicles {
  $block: &;
}
</style>
