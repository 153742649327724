<template>
  <div class="PmPillWithLabelPure">
    <PmPillPure
      v-if="pill.label"
      v-bind="pill"
      :title="label ?? undefined"
      class="PmPillWithLabelPure-pill"
    />
    <div class="PmPillWithLabelPure-label">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmPillWithLabelPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import PmPillPure, {
  type Props as PropsPillPure,
} from '@/components/basics/PmPillPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  pill: PropsPillPure
  label?: Nilable<string>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()
</script>

<style lang="scss">
.PmPillWithLabelPure {
  $block: &;

  display: flex;
  position: relative;
  gap: 4px;

  &-pill {
    flex-shrink: 0;
  }

  &-label {
    @include mixin.truncateText;

    padding-top: 0.1em;
  }
}
</style>
