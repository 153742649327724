import { cloneDeep } from 'lodash-es'
import cuid from '@paralleldrive/cuid2'

const state = {
  notifications: [],
}

const mutations = {
  add(state, error) {
    const normalizedNotification = cloneDeep(error)
    normalizedNotification.id = cuid.createId()

    state.notifications = [...state.notifications, normalizedNotification]
  },

  remove(state, id) {
    const index = state.notifications.findIndex(
      (notification) => notification.id === id
    )
    if (index === -1) throw new Error('index not found')

    state.notifications.splice(index, 1)
  },
}

const getters = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
