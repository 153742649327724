<template>
  <transition appear>
    <div v-if="isVisible" class="PmPauseOverlayPure" @click="emit('click')">
      <div class="PmPauseOverlayPure-content">
        <div class="PmPauseOverlayPure-header">
          <PmIconPure name="pause" class="PmPauseOverlayPure-icon" />

          <div class="PmPauseOverlayPure-title">
            Automatische Aktualisierung pausiert
          </div>
        </div>

        <div class="PmPauseOverlayPure-text">
          <p>
            Es sieht so aus als würdest du den Persoplan nicht mehr verwenden,
            deshalb wurde die automatische Aktualisierung angehalten.
          </p>

          <p>
            Wenn es weitergehen soll, bewege einfach deine Maus oder klicke
            irgendwo hier ins Fenster.
          </p>
        </div>

        <p v-if="inactiveSince" class="PmPauseOverlayPure-inactiveSince">
          Inaktiv seit {{ inactiveSince }}
        </p>

        <slot />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  isVisible?: boolean
  inactiveSince?: string
}

const props = withDefaults(defineProps<Props>(), {
  isVisible: true,
})

const emit = defineEmits<{
  (event: 'click'): void
}>()
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmPauseOverlayPure {
  $block: &;

  width: 100vw;
  min-height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-small);
  cursor: pointer;
  user-select: none;

  @supports not (min-height: 100dvh) {
    min-height: calc(var(--appHeight));
  }

  &.v-enter-active,
  &.v-leave-active {
    transition-duration: constant.$duration-default;
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }

  &-content {
    @include shadow.default('high', $outline: false);

    border-radius: constant.$borderRadius-large;
    color: color.$white;
    padding: 40px;
    background-color: rgba(color.$black, 0.75);
    backdrop-filter: blur(15px);
    max-width: 550px;

    @supports not (backdrop-filter: blur(42px)) {
      background-color: rgba(color.$gray-700, 0.95);
    }
  }

  &-header {
    @include typography.h3;

    display: flex;

    @include mixin.media('<=mobile') {
      flex-direction: column;
      align-items: center;
      gap: 1em;
    }
  }

  &-icon {
    margin-top: -0.3em;
    margin-right: 0.5em;
    width: 40px;
    height: 40px;

    @include mixin.media('<=mobile') {
      /* stylelint-disable-next-line plugin/selector-bem-pattern */
      --strokeWidth: 3px;

      margin-right: 0;
      width: 60px;
      height: 60px;
    }
  }

  &-title {
    @include mixin.media('<=mobile') {
      text-align: center;
    }
  }

  &-text {
    @include typography.copy-default;

    opacity: 0.75;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    & > p:not(:last-child) {
      margin-bottom: 1em;
    }

    @include mixin.media('<=mobile') {
      text-align: center;
    }
  }

  &-inactiveSince {
    margin-top: 1em;
    font-weight: 600;
  }
}
</style>
