<template>
  <div class="PmCalendarFramePure" tabindex="1">
    <div class="PmCalendarFramePure-header">
      <div class="PmCalendarFramePure-headerMonthContainer">
        <PmCalendarMonthPure
          v-for="month in visibleMonths"
          :key="month.name"
          :date="month.date"
          :number-of-days="month.numberOfDays"
          class="PmCalendarFramePure-headerMonthItem"
        />
      </div>

      <div
        v-for="(week, weekIndex) in visibleWeeks"
        :key="weekIndex"
        class="PmCalendarFramePure-headerWeek"
      >
        <PmCalendarWeekPure
          :date="week.date"
          class="PmCalendarFramePure-headerWeekHeader"
        />

        <div class="PmCalendarFramePure-headerDays">
          <div
            v-for="(day, dayIndex) in week.days"
            :key="dayIndex"
            class="PmCalendarFramePure-headerDay"
          >
            <PmDayHeadPure
              :date="day.date"
              :is-public-holiday-in-germany="day.isPublicHolidayInGermany"
              :is-public-holiday-in-luxemburg="day.isPublicHolidayInLuxemburg"
              class="PmCalendarFramePure-dayHead"
            />

            <PmDayWidthController
              :index="getDayIndex(day.date)"
              class="PmCalendarFramePure-dayWidthController"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="PmCalendarFramePure-content">
      <div class="PmCalendarFramePure-testContent">
        <slot />
      </div>

      <PmDayPure
        v-for="day in visibleDaysNormalized"
        :key="day.date.getTime()"
        :date="day.date"
        :is-public-holiday-in-germany="day.isPublicHolidayInGermany"
        :is-public-holiday-in-luxemburg="day.isPublicHolidayInLuxemburg"
        class="PmCalendarFramePure-contentDay"
        :opening-hours="openingHours"
        :core-hours="coreHours"
      />
    </div>
  </div>
</template>

<script>
import {
  lastDayOfWeek,
  isBefore,
  isAfter,
  isWithinInterval,
  // set,
  differenceInCalendarDays,
  eachWeekOfInterval,
  eachDayOfInterval,
  startOfDay,
  endOfDay,
  getYear,
  getMonth,
} from 'date-fns'

import { groupBy } from 'lodash-es'

// import Sidebar from './Sidebar.vue'
import PmDayPure from '@/components/persoplan/Day/PmDayPure.vue'
import PmDayHeadPure from '@/components/persoplan/Day/PmDayHeadPure.vue'
import PmCalendarWeekPure from '@/components/persoplan/PmCalendarFrame/PmCalendarWeekPure.vue'
import PmDayWidthController from '@/components/persoplan/PmDayWidthController/PmDayWidthController.vue'
import PmCalendarMonthPure from '@/components/persoplan/PmCalendarFrame/PmCalendarMonthPure.vue'

import { filterProps } from '@/utilities/props'

export default {
  name: 'PmCalendarFramePure',
  components: {
    PmDayHeadPure,
    PmDayPure,
    PmCalendarWeekPure,
    PmDayWidthController,
    PmCalendarMonthPure,
  },

  props: {
    ...filterProps(PmDayPure.props, {
      onlyUse: ['openingHours', 'coreHours'],
    }),
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    publicHolidaysInGermany: { type: Array, default: () => [] },
    publicHolidaysInLuxemburg: { type: Array, default: () => [] },
  },

  data() {
    return {
      offset: { x: 0, y: 0 },
      offsetWithScroll: { x: 0, y: 0 },
      mounted: false,
    }
  },

  computed: {
    startDateStartOfDay() {
      return startOfDay(this.startDate)
    },

    endDateEndOfDay() {
      return endOfDay(this.endDate)
    },

    visibleDaysNormalized() {
      const dates = eachDayOfInterval({
        start: this.startDateStartOfDay,
        end: this.endDateEndOfDay,
      })

      const days = dates.map((date) => {
        return {
          date,
          isPublicHolidayInGermany: this.isDatePublicHolidayInGermany(date),
          isPublicHolidayInLuxemburg: this.isDatePublicHolidayInLuxemburg(date),
        }
      })

      return days
    },

    visibleWeeks() {
      const weeks = eachWeekOfInterval(
        {
          start: this.startDateStartOfDay,
          end: this.endDateEndOfDay,
        },
        {
          weekStartsOn: 1,
        }
      )

      const visibleWeeks = weeks.map((week) => {
        let dateOfFirstDayOfWeek = week
        let dateOfLastDayOfWeek = lastDayOfWeek(week, { weekStartsOn: 1 })

        // Special Cases, start and end of date Range in the middle of week:
        if (isBefore(dateOfFirstDayOfWeek, this.startDateStartOfDay)) {
          dateOfFirstDayOfWeek = this.startDateStartOfDay
        }

        if (isAfter(dateOfLastDayOfWeek, this.endDateEndOfDay)) {
          dateOfLastDayOfWeek = this.endDateEndOfDay
        }

        const allDaysOfCurrentWeek = eachDayOfInterval({
          start: dateOfFirstDayOfWeek,
          end: dateOfLastDayOfWeek,
        })

        const days = allDaysOfCurrentWeek.map((day) => {
          return {
            date: day,
            isPublicHolidayInGermany: this.isDatePublicHolidayInGermany(day),
            isPublicHolidayInLuxemburg:
              this.isDatePublicHolidayInLuxemburg(day),
          }
        })

        return {
          date: dateOfFirstDayOfWeek,
          days: days,
        }
      })

      return visibleWeeks
    },

    visibleMonths() {
      const days = eachDayOfInterval({
        start: this.startDateStartOfDay,
        end: this.endDateEndOfDay,
      })

      const daysGroupedByMonth = groupBy(days, (day) => {
        return `${getYear(day)}-${getMonth(day)}`
      })

      const months = Object.values(daysGroupedByMonth).map((daysInMonth) => {
        const date = daysInMonth[0]
        const numberOfDays = daysInMonth.length

        return {
          date,
          numberOfDays,
        }
      })

      return months
    },
  },

  methods: {
    isDatePublicHolidayInGermany(date) {
      return this.isPublicHoliday(date, this.publicHolidaysInGermany)
    },

    isDatePublicHolidayInLuxemburg(date) {
      return this.isPublicHoliday(date, this.publicHolidaysInLuxemburg)
    },

    isPublicHoliday(date, publicHolidays) {
      const firstPublicHoliday = publicHolidays.find((publicHoliday) => {
        return isWithinInterval(date, {
          start: publicHoliday.startDate,
          end: publicHoliday.endDate,
        })
      })

      return firstPublicHoliday ? true : false
    },

    getDayIndex(date) {
      return differenceInCalendarDays(date, this.startDateStartOfDay)
    },
  },
}
</script>

<style lang="scss">
.PmCalendarFramePure {
  $block: &;
  $name: function.getBlockName($block);

  // TODO: Stylelint update
  /* stylelint-disable */
  --#{$name}-dayWidth: var(--dayWidth, 150px);
  /* stylelint-enable */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: var(--PmPersoplan-minHeight);

  &-header {
    display: inline-flex;
    flex: none;
    position: sticky;
    top: var(--navigationHeight);
    z-index: 1;
    // height: var(--headerHeight);
    // height: 88px + 1px;
    height: var(--PmCalendarPure-headerHeight);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: color.$gray-100;
      border-bottom: 1px solid color.$gray-300--alpha;
    }
  }

  &-headerMonthContainer {
    height: 20px;
    z-index: 100;
    position: absolute;
    left: 0;
    top: 10px;
    display: flex;
  }

  &-headerMonthItem {
    flex: none;
  }

  &-headerWeek {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    flex: none;

    // Lineo
    &::after {
      content: '';
      width: 3px;
      background-color: color.$gray-300;
      position: absolute;
      left: -3px;
      top: 28px;
      bottom: 15px;
      z-index: 1;
    }
  }

  &-headerWeekHeader {
    position: sticky;
    top: 0;
    width: 100%;
    margin-top: 26px;
  }

  &-headerDays {
    display: inline-flex;
    height: 100%;
  }

  &-headerDay {
    width: var(--#{$name}-dayWidth);
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: var(--space-gutters);
  }

  &-dayHead {
    position: sticky;
    left: var(--sidebarLeftWidth);
  }

  &-dayWidthController {
    position: absolute;
    right: 1px;
    bottom: 8px;
    transform: translate(50%, 50%);
    opacity: 0;
    transition: opacity constant.$duration-hoverOut;
    z-index: 10;
    pointer-events: all;

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    #{$block}:hover &,
    .App.is-dragAndDropTypeChangeDayWidth & {
      opacity: 1;
    }

    #{$block}-headerDay:last-child & {
      display: none;
    }
  }

  &-content {
    display: flex;
    min-height: calc(
      var(--PmPersoplan-minHeight) - var(--PmCalendarPure-headerHeight)
    );
    height: 100%;
    position: relative;

    // @at-root .is-storybook & {
    //   min-height: 200vh;
    // }
  }

  &-testContent {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &-contentDay {
    width: var(--#{$name}-dayWidth);
    position: relative;
    z-index: 0;
  }
}
</style>
