<template>
  <PmTableRowPure
    :class="componentClass.root"
    :selected="selected"
    :active="active"
    @click="onClick"
  >
    <template #cell(selected)>
      <PmCheckboxPure
        ref="elCheckbox"
        :value="checkbox"
        @select="emit('select')"
        @unselect="emit('unselect')"
      />
    </template>

    <template #cell(id)> {{ id }}</template>

    <template #cell(statusFeedback)>
      <PmStatusPillPure
        type="resourceRequestFeedback"
        :resource-request-feedback-status="statusFeedback"
      />
    </template>

    <template #cell(status)>
      <PmStatusPillPure
        type="resourceRequest"
        :resource-request-status="status"
      />
    </template>

    <template #cell(resourceFunctions)>
      <template v-if="resourceFunctions.length > 1">
        <PmRichTextPure>
          <ul>
            <li
              v-for="resourceFunction in resourceFunctions"
              :key="`${resourceFunction.abbreviation}.${resourceFunction.label}`"
            >
              <PmPillWithLabelPure
                :pill="{
                  label: resourceFunction.abbreviation,
                }"
                :label="resourceFunction.label"
              />
            </li>
          </ul>
        </PmRichTextPure>
      </template>

      <template v-else>
        <PmPillWithLabelPure
          :pill="{
            label: resourceFunctions[0].abbreviation,
          }"
          :label="resourceFunctions[0].label"
        />
      </template>
    </template>

    <template #cell(appointments)>
      <template v-if="appointments.length > 1">
        <PmRichTextPure>
          <ul>
            <li
              v-for="appointment in appointments"
              :key="`${appointment.name}`"
            >
              {{ appointment.name }}
            </li>
          </ul>
        </PmRichTextPure>
      </template>

      <template v-else>
        {{ appointments[0].name }}
      </template>
    </template>

    <template #cell(name)>
      <div :class="componentClass.element('name')" :title="name">
        {{ name }}
      </div>
    </template>

    <template #cell(createdBy)>
      {{ createdBy }}
    </template>

    <template #cell(updated)>
      <div :class="componentClass.element('date')">
        <PmDatePure :date="updated" />
      </div>
    </template>
  </PmTableRowPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceRequestOverviewItemPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed } from 'vue'

import { useComponentClass } from '@/composition/useComponentClass'

import PmTableRowPure from '@/components/basics/PmTable/PmTableRowPure.vue'
import PmCheckboxPure, {
  type Props as PropsCheckboxPure,
} from '@/components/basics/PmCheckboxPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmPillWithLabelPure from '@/components/basics/PmPillWithLabel/PmPillWithLabelPure.vue'
import PmDatePure from '@/components/basics/PmDate/PmDatePure.vue'
import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'

import type { StatusResourceRequestFeedback } from '@/composition/useResourceRequestFeedbackStatus'
import type { StatusResourceRequest } from '@/composition/useResourceRequestStatus'
import type { Nilable } from '@/types/misc'

export interface Props {
  id: number
  selected?: boolean
  active?: boolean
  statusFeedback?: Nilable<StatusResourceRequestFeedback>
  status?: Nilable<StatusResourceRequest>
  resourceFunctions: {
    id: number
    abbreviation?: Nilable<string>
    label: Nilable<string>
  }[]
  appointments: {
    id: number
    name?: Nilable<string>
  }[]
  name?: string
  updated?: Date
  createdBy?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'click'): void
  (event: 'select'): void
  (event: 'unselect'): void
}>()

const componentClass = useComponentClass()

const elCheckbox = ref<InstanceType<typeof PmCheckboxPure>>()

function shouldClickBeIgnored(event: Event) {
  const elCheckboxRoot = elCheckbox.value?.elRoot
  if (!elCheckboxRoot) return false

  if (event.target === elCheckboxRoot) return true

  if (!(event.target instanceof Node)) return false
  if (elCheckboxRoot.contains(event.target)) return true

  return false
}

function onClick(event: Event) {
  if (shouldClickBeIgnored(event)) return

  if (event instanceof MouseEvent || event instanceof PointerEvent) {
    if (event.shiftKey && event.ctrlKey) {
      props.selected ? emit('unselect') : emit('select')
      return
    }
  }

  emit('click')
}

const checkbox = computed<PropsCheckboxPure['value']>(() => {
  if (props.active === true && props.selected === false) return 'indeterminate'
  return props.selected
})
</script>

<style lang="scss">
.PmResourceRequestOverviewItemPure {
  $block: &;

  &-name {
    @include mixin.truncateText;
  }

  &-date {
    @include mixin.truncateText;
  }
}
</style>
