<template>
  <PmLoginPure
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    @login-with-credentials="
      xstate.service.value.send('LOGIN', {
        username: $event.username,
        password: $event.password,
      })
    "
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, inject, ref } from 'vue'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es'
import { useRoute, useRouter } from 'vue-router'

import { PmLoginState } from '@/views/PmLogin/PmLoginState'
import { useXState } from '@/composition/useXState'
import { appStateKey, injectStrict } from '@/utilities/inject'

import PmLoginPure from '@/views/PmLogin/PmLoginPure.vue'

export default defineComponent({
  name: 'PmLogin',
  components: {
    PmLoginPure,
  },

  setup() {
    const appState = injectStrict(appStateKey)
    const isStorybook = inject('isStorybook', ref(false))
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const xstate = useXState(PmLoginState, {
      services: {
        checkIfLoggedIn: () => store.dispatch('auth/loginWithLocalStorage'),
        autoLogin: () => store.dispatch('auth/loginWithSso'),
        loginWithCredentials: (
          context,
          { username, password }: { username: string; password: string }
        ) => {
          return store.dispatch('auth/loginWithCredentials', {
            username,
            password,
          })
        },
      },
      actions: {
        loginSuccessful: () => onLoginSuccessful(),
      },
    })

    const onLoginSuccessful = () => {
      appState.service.value.send('LOGIN_SUCCESSFUL')

      if (isStorybook.value === true) return

      const query = cloneDeep(route.query)

      if (route?.query?.from) {
        const redirectTo = route.query.from
        delete query.from

        if (typeof redirectTo !== 'string') {
          throw new Error('redirectTo is not a string')
        }

        router.replace({ path: redirectTo, query: query })
      } else {
        router.push({ path: '/', query: query })
      }
    }

    appState.service.value.send('LOGIN')

    onMounted(() => {
      xstate.service.value.send('CHECK_IF_LOGGED_IN')
    })

    return {
      appState,
      isStorybook,
      xstate,
    }
  },
})
</script>
