/**
 * Read global cssVariable
 * @todo Maybe evaluate css calc
 */
type Options = {
  el: HTMLElement
}

function read(name: string, type: 'number', options?: Options): number
function read(name: string, type: 'string', options?: Options): string

function read(name: string, type: unknown, options?: Options): unknown {
  if (!type) throw new Error(`You need to provide a type parameter`)

  const elToReadFrom = options?.el ?? document.documentElement
  const value = getComputedStyle(elToReadFrom).getPropertyValue(`--${name}`)

  if (type === 'number') {
    let normalizedValue: number | undefined = undefined

    normalizedValue = parseInt(value)

    if (typeof normalizedValue !== 'number') {
      throw Error('type of normalizedValue is not number')
    }

    return normalizedValue
  }

  if (type === 'string') {
    return value
  }
}

export default {
  read,
}
