<template>
  <div id="calendar" class="PmCalendarPure" :style="styles">
    <div class="PmCalendarPure-content" :style="stylesContent">
      <div v-show="contentVisible" class="PmCalendarPure-contentInner">
        <div
          id="generalEvents"
          ref="generalEvents"
          class="PmCalendarPure-generalEvents"
          :class="{ 'is-withContent': isGeneralEventsVisible }"
        >
          <slot name="generalEvents" />
        </div>

        <div
          id="calendarEvents"
          ref="calendarEvents"
          class="PmCalendarPure-calendarEvents"
          :class="{ 'is-withContent': isCalendarEventsVisible }"
        >
          <slot name="calendarEvents" />
        </div>

        <div
          id="birthdays"
          ref="birthdays"
          class="PmCalendarPure-birthdays"
          :class="{ 'is-withContent': isBirthdaysVisible }"
        >
          <slot name="birthdays" />
        </div>

        <div
          id="freelancers"
          ref="freelancers"
          class="PmCalendarPure-freelancers"
          :class="{ 'is-withContent': isFreelancersVisible }"
        >
          <slot name="freelancers" />
        </div>

        <div
          id="employees"
          ref="employees"
          class="PmCalendarPure-employees"
          :class="{ 'is-withContent': isEmployeesVisible }"
        >
          <slot name="employees" />
        </div>

        <div
          id="vehicles"
          ref="vehicles"
          class="PmCalendarPure-vehicles"
          :class="{ 'is-withContent': isVehiclesVisible }"
        >
          <slot name="vehicles" />
        </div>

        <div id="jobs" ref="jobs" class="PmCalendarPure-jobs">
          <slot name="jobs" />
        </div>

        <div id="afterJobs" ref="afterJobs" class="PmCalendarPure-afterJobs">
          <slot name="afterJobs" />
        </div>
      </div>
    </div>

    <div v-if="$slots.settings" class="PmCalendarPure-settings">
      <slot name="settings" />
    </div>

    <PmCalendarFramePure
      class="PmCalendarPure-frame"
      :start-date="startDate"
      :end-date="endDate"
      :opening-hours="openingHours"
      :core-hours="coreHours"
      :public-holidays-in-germany="publicHolidaysInGermany"
      :public-holidays-in-luxemburg="publicHolidaysInLuxemburg"
    />

    <div class="PmCalendarPure-control">
      <slot name="control" />
    </div>
  </div>
</template>

<script>
import { differenceInCalendarDays } from 'date-fns'

import { EVENT } from '@/constants/events'

import PmCalendarFramePure from '@/components/persoplan/PmCalendarFrame/PmCalendarFramePure.vue'

import { filterProps } from '@/utilities/props'

export default {
  name: 'PmCalendarPure',
  components: {
    PmCalendarFramePure,
  },

  props: {
    ...filterProps(PmCalendarFramePure.props, {
      onlyUse: ['openingHours', 'coreHours'],
    }),
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    publicHolidaysInGermany: { type: Array, default: () => [] },
    publicHolidaysInLuxemburg: { type: Array, default: () => [] },
    contentVisible: { type: Boolean, default: true },
    isGeneralEventsVisible: { type: Boolean, default: true },
    isCalendarEventsVisible: { type: Boolean, default: true },
    isBirthdaysVisible: { type: Boolean, default: true },
    isFreelancersVisible: { type: Boolean, default: true },
    isEmployeesVisible: { type: Boolean, default: true },
    isVehiclesVisible: { type: Boolean, default: true },
  },

  emits: ['updateContainerHeight'],

  data() {
    return {
      resizeObserver: undefined,
      containerHeight: undefined,
    }
  },

  computed: {
    styles() {
      return {
        height: this.containerHeight ? `${this.containerHeight}px` : undefined,
      }
    },

    stylesContent() {
      return {
        width: `calc(${this.numberOfDays} * var(--dayWidth))`,
      }
    },

    numberOfDays() {
      return differenceInCalendarDays(this.endDate, this.startDate) + 1
    },
  },

  watch: {
    contentVisible(value, oldValue) {
      /**
       * Save and restore container height, otherwise the user will be scrolled to the top
       */
      if (value === false && oldValue === true) {
        this.containerHeight = this.$el.getBoundingClientRect().height
      }

      if (value === true && oldValue === false) {
        this.containerHeight = null
      }
    },
  },

  mounted() {
    const refIds = [
      'generalEvents',
      'calendarEvents',
      'birthdays',
      'freelancers',
      'employees',
      'vehicles',
      'jobs',
      'afterJobs',
    ]

    this.resizeObserver = new ResizeObserver(this.onResize)
    this.resizeObserver.observe(this.$el)

    refIds.forEach((id) => {
      const ref = this.$refs[id]
      this.resizeObserver.observe(ref)
    })
  },

  beforeUnmount() {
    this.resizeObserver.disconnect()
  },

  methods: {
    onResize(entries) {
      if (!this.contentVisible) return

      /**
       * Avoid ResizeObserver loop limit exceeded error
       * @see https://stackoverflow.com/a/58701523
       */
      window.requestAnimationFrame(() => {
        entries.forEach((entry) => this.updateContainerHeight(entry.target))
      })
    },

    updateContainerHeight(el) {
      if (el === this.$el) {
        this.$eventHub.$emit(EVENT.UPDATE_SCROLLBAR_DIMENSIONS)
      }

      const id = el.getAttribute('id')
      if (!id) return

      const height = el.getBoundingClientRect().height

      this.$emit('updateContainerHeight', {
        id,
        height,
      })
    },
  },
}
</script>

<style lang="scss">
.PmCalendarPure {
  $block: &;

  margin-left: var(--sidebarLeftWidthWithVisibility, 0);
  margin-right: var(--sidebarRightWidthWithVisibility, 0);
  position: relative;

  &-content {
    position: relative;
    z-index: 1;
    // padding-top: calc(var(--headerHeight) + var(--navbarHeight));
    padding-top: var(--PmCalendarPure-headerHeight);
    width: 100%;
    min-height: var(--sidebarHeight);

    // TODO:
    // height: 500vh;

    // overflow: hidden; // This prevents sticky elements inside to work
  }

  &-generalEvents {
    &.is-withContent {
      min-height: 41px;
    }
  }

  &-calendarEvents {
    &.is-withContent {
      min-height: 41px;
    }
  }

  &-birthdays {
    &.is-withContent {
      min-height: 41px;
    }
  }

  &-freelancers {
    &.is-withContent {
      padding-top: 41px;
    }
  }

  &-employees {
    &.is-withContent {
      padding-top: 41px;
    }
  }

  &-vehicles {
    &.is-withContent {
      padding-top: 41px;
    }
  }

  &-jobs {
    padding-top: 41px;
    min-height: var(--sidebarJobsHeight);
  }

  &-frame {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &-settings {
    position: fixed;
    z-index: 2;
    top: var(--navigationHeight);
    left: 0;
    width: calc(var(--sidebarLeftWidth) + 1px);
    background-color: color.$gray-100;
    border-right: 1px solid color.$gray-100--alpha;
    // min-height: 40px;

    // Gradient
    &::after {
      content: '';
      width: 50px;
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 10;
      background: linear-gradient(
          90deg,
          rgba(#000, 0.06),
          ease-out,
          rgba(#000, 0)
        ),
        linear-gradient(
          90deg,
          rgba(#000, 0.1),
          cubic-bezier(0, 0, 0, 1),
          rgba(#000, 0)
        );
    }
  }

  &-control {
    position: fixed;
    top: calc(var(--navigationHeight) + 4px);
    right: calc(var(--sidebarRightWidthWithVisibility) + 4px);
    z-index: 2;
  }
}
</style>
