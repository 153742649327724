<template>
  <div class="PmCalendarWeekPure">
    <div class="PmCalendarWeekPure-content">
      <div class="PmCalendarWeekPure-kw">{{ kwFormatted }}</div>
    </div>
  </div>
</template>

<script>
import { formatWithLocale } from '@/utilities/date'

export default {
  name: 'CalendarWeek',
  props: {
    date: { type: Date, required: true },
  },

  computed: {
    kwFormatted() {
      return 'KW ' + formatWithLocale(this.date, 'ww')
    },
  },
}
</script>

<style lang="scss">
.PmCalendarWeekPure {
  $block: &;

  @include cssVar.define($block, 'gradientWidth', 50px);

  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  pointer-events: none;
  user-select: none;
  z-index: 3;
  // margin-bottom: -40px;
  width: 100%;
  display: flex;

  &-content {
    position: sticky;
    top: 0;
    left: var(--sidebarLeftWidth, 0);
    padding-left: var(--space-gutters);
    padding-right: var(--space-gutters);
    height: 100%;
    flex: none;
  }

  &-kw {
    @include mixin.textLabel;

    position: relative;
    z-index: 1;
  }
}
</style>
