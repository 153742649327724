import { cloneDeep } from 'lodash-es'

import { VIEW_SETTING_MODE } from '@/constants/persoplan'
import { MIGRATION_TYPE } from '@/migrations/constants'

import { migrateType } from '@/migrations/runMigration'

export const INACTIVE_JOB_STATE_IDS = [
  3, // Abgesagt Vermietung
  6, // Freies Angebot Vermietung
  11, // Fertigungsplanung
  12, // Abgesagt Zumietung
  13, // Inventur
  19, // Abgesagt Verkauf
]

export const INACTIVE_PROJECT_STATE_IDS = [
  3, // Abgesagt
]

/**
 * Default view gets merged with saved user views
 * Changing or removing values needs a migration. Adding new root-level properties is fine
 */
export const defaultView = {
  version: '2.0.1',
  showGeneralEvents: true,
  showBirthdays: true,
  showFreelancers: true,
  showEmployees: true,
  showVehicles: true,
  showCalendarEvents: true,
  showOperations: true,

  showOpeningHours: true,
  showCoreHours: true,
  showMissingResourcesWarning: true,

  showOnlyWithResources: true,
  showOnlyActive: true,

  // showMyProjectsOnly: false,
  employeeGroups: {
    ids: [
      117, // Geschäftsführung
      131, // Personalleitung
      132, // Büro Azubi
      138, // Vertrieb
      130, // Lagerleitung
      124, // Temporäre Mitarbeiter
    ],
    mode: VIEW_SETTING_MODE.EXCLUDE,
  },
  clients: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  projectLeaders: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  projects: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  projectStates: { ids: [], mode: VIEW_SETTING_MODE.EXCLUDE },
  projectTypes: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  jobLeaders: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  jobStates: { ids: [], mode: VIEW_SETTING_MODE.EXCLUDE },
  jobServices: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  resourceTypes: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  resourceFunctions: { ids: [148], mode: VIEW_SETTING_MODE.EXCLUDE },
  resourceAllocations: { ids: [], mode: VIEW_SETTING_MODE.EXPLICIT },
  resourceAllocationStates: { ids: [3], mode: VIEW_SETTING_MODE.EXCLUDE },
}

const state = {
  id: undefined,
  title: undefined,
  currentView: cloneDeep(defaultView),
  savedView: cloneDeep(defaultView), // Last saved view (or default view)
  defaultSavedViewIdMobile: undefined,
  defaultSavedViewIdDesktop: undefined,
}

const mutations = {
  setView(state, { query, title, id }) {
    state.id = id
    state.title = title

    let parsedQuery

    try {
      parsedQuery = JSON.parse(query)
    } catch (error) {
      console.error('setView: Could not parse query')
      return
    }

    // Set default values for values which were not saved
    const queryWithDefaults = {
      ...cloneDeep(defaultView),
      ...parsedQuery,
    }

    // Run migrations
    const migratedView = migrateType({
      schema: queryWithDefaults,
      type: MIGRATION_TYPE.VIEW,
      to: defaultView.version,
    })

    state.currentView = migratedView
    // Save new savedView baseline
    state.savedView = cloneDeep(state.currentView)
  },

  setCurrentViewSettings(state, { settings }) {
    const clone = cloneDeep(settings)
    state.currentView = clone
  },

  reset(state) {
    state.id = undefined
    state.title = undefined
    state.currentView = cloneDeep(defaultView)
    state.savedView = cloneDeep(defaultView)
  },

  revert(state) {
    state.currentView = cloneDeep(state.savedView)
  },
}

const getters = {
  isSavedView(state) {
    return state.id ? true : false
  },

  isChanged(state, getters) {
    // If it's not a saved view, compare it against the default view
    if (!getters.isSavedView) {
      return !getters.isDefault
    }

    const currentView = JSON.stringify(state.currentView)
    const savedView = JSON.stringify(state.savedView)

    return currentView !== savedView
  },

  isDefault(state) {
    const currentViewJson = JSON.stringify(state.currentView)
    const defaultViewJson = JSON.stringify(cloneDeep(defaultView))

    return currentViewJson === defaultViewJson
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
