import { ExternalServiceRequestState } from '@/../generated/graphql'

export type ExternalServiceRequestStatus =
  | 'notProcessed'
  | 'inProgress'
  | 'aborted'
  | 'approved'
  | 'rejected'

export const EXTERNAL_SERVICE_REQUEST_STATUS_LABEL_LOOKUP = {
  notProcessed: 'Nicht bearbeitet',
  inProgress: 'In Bearbeitung',
  aborted: 'Zurückgezogen',
  approved: 'Genehmigt',
  rejected: 'Abgelehnt',
} as const satisfies Record<ExternalServiceRequestStatus, string>

/**
 * Lookup frontend status from backend status
 */
export const EXTERNAL_SERVICE_REQUEST_STATUS_LOOKUP = {
  created: 'notProcessed',
  updated: 'inProgress',
  aborted: 'aborted',
  accepted: 'approved',
  declined: 'rejected',
} as const satisfies Record<
  `${ExternalServiceRequestState}`,
  ExternalServiceRequestStatus
>
