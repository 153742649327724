import { onBeforeUnmount, onMounted, ref, type Ref } from 'vue'
import cuid from '@paralleldrive/cuid2'

import { EVENT } from '@/constants/events'

import EventHub from '@/eventHub'

function intersection(entries: IntersectionObserverEntry[]) {
  entries.forEach((entry) => {
    if (!(entry.target instanceof HTMLElement)) {
      throw new Error('entry.target is not an HTMLElement')
    }

    const id = entry.target.dataset.lazyLoadingId
    if (!id) throw new Error('datset.lazyloadingId is undefined')

    if (entry.isIntersecting) {
      EventHub.$emit(EVENT.LAZYLOAD_APPOINTMENT_ENTER, { id })
    } else {
      EventHub.$emit(EVENT.LAZYLOAD_APPOINTMENT_LEAVE, { id })
    }
  })
}

const intersectionObserver = new IntersectionObserver(intersection)

export function useLazyloading({
  element,
  onFirstEnter,
}: {
  element: Ref<HTMLElement | undefined>
  onFirstEnter: () => void
}) {
  const id = cuid.createId()
  const isInViewport = ref(false)
  let hasBeenVisibleOnce = false

  const maybeDoCallbackEnter = (event: { id: string }) => {
    if (event.id !== id) return
    isInViewport.value = true

    if (hasBeenVisibleOnce === false && onFirstEnter) {
      onFirstEnter()
      hasBeenVisibleOnce = true
    }
  }

  const maybeDoCallbackLeave = (event: { id: string }) => {
    if (event.id !== id) return
    isInViewport.value = false
  }

  onMounted(() => {
    if (!element.value) throw new Error('element is undefined')

    EventHub.$on(EVENT.LAZYLOAD_APPOINTMENT_ENTER, maybeDoCallbackEnter)
    EventHub.$on(EVENT.LAZYLOAD_APPOINTMENT_LEAVE, maybeDoCallbackLeave)

    intersectionObserver.observe(element.value)
  })

  onBeforeUnmount(() => {
    if (!element.value) throw new Error('element is undefined')

    EventHub.$off(EVENT.LAZYLOAD_APPOINTMENT_ENTER, maybeDoCallbackEnter)
    EventHub.$off(EVENT.LAZYLOAD_APPOINTMENT_LEAVE, maybeDoCallbackLeave)

    intersectionObserver.unobserve(element.value)
  })

  return {
    id,
    isInViewport,
  }
}
