<template>
  <div class="PmActionbarListPure">
    <PmActionbarListItemPure
      v-for="item in itemsSorted"
      :id="item.id"
      :key="item.id"
      ref="item"
      class="PmActionbarListPure-item"
      :title="item.title"
      :type="item.type"
      :ratings="item.ratings"
      :loading-capacity="item.loadingCapacity"
      :loading-volume="item.loadingVolume"
      :is-pro-musik="item.isProMusik"
      :has-no-resource-type="item.hasNoResourceType"
    />
  </div>
</template>

<script>
import { orderBy } from 'lodash-es'

import { RESOURCE_TYPE } from '@/constants/persoplan'

import { defaultSortVehicles } from '@/utilities/persoplan'

import PmActionbarListItemPure from '@/components/persoplan/Actionbar/PmActionbarListItemPure.vue'

export default {
  name: 'PmActionbarListPure',
  components: { PmActionbarListItemPure },

  props: {
    items: { type: Array, default: () => [] },
    type: { type: String, required: true },
  },

  computed: {
    itemsSorted() {
      if (this.type === RESOURCE_TYPE.ADDRESS) {
        return this.itemsAddressSorted
      }

      if (this.type === RESOURCE_TYPE.VEHICLE) {
        return this.itemsVehicleSorted
      }

      throw new Error(
        `type must be either RESOURCE_TYPE.ADDRESS or RESOURCE_TYPE.VEHICLE`
      )
    },

    itemsAddressSorted() {
      // Sort items by accumulated ratings, title
      return orderBy(
        this.items,
        [this.getTotalRating, 'title'],

        ['desc', 'asc']
      )
    },

    itemsVehicleSorted() {
      return defaultSortVehicles(this.items)
    },
  },

  methods: {
    getTotalRating(item) {
      if (!item.ratings || !item.ratings.length) {
        return 0
      }

      const totalRating = item.ratings.reduce((totalRating, ratingItem) => {
        return totalRating + ratingItem.rating
      }, 0)

      return totalRating
    },
  },
}
</script>

<style lang="scss">
.PmActionbarListPure {
  $block: &;

  &-item {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
