<template>
  <PmResourceStateEditPure
    :state-options="stateOptionsNormalized"
    v-bind="value"
    :state="xstate.path.value"
    mode="edit"
    :is-loading="isLoading"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    @input-start-date-time="(date) => (value.startDateTime = date)"
    @input-end-date-time="(date) => (value.endDateTime = date)"
    @select-state="(id) => (value.selectedStateId = id)"
    @input-note="(note) => (value.note = note)"
    @cancel="$emit('cancel')"
    @save="xstate.service.value.send('SAVE')"
    @delete="xstate.service.value.send('DELETE')"
    @update-searchterm-address="$emit('updateSearchtermAddress')"
  />
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import { RESOURCE_TYPE_LOOKUP, RESOURCE_TYPE } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import {
  parseServerDateString,
  formatToServerDateString,
} from '@/utilities/date'
import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { checkIfResourceStateTypeIsVisible } from '@/functional/resourceStateType'
import EventHub from '@/eventHub'

import { PmResourceStateEditState } from '@/components/persoplan/PmResourceStateEdit/PmResourceStateEditState'

import ResourceStateTypesQuery from '@/graphql/ResourceStateTypesQuery.graphql'
import ResourceStateQuery from '@/components/persoplan/PmResourceStateEdit/ResourceStateQuery.graphql'
import UpdateResourceStateMutation from '@/components/persoplan/PmResourceStateEdit/UpdateResourceStateMutation.graphql'
import DeleteResourceStateMutation from '@/components/persoplan/PmResourceStateEdit/DeleteResourceStateMutation.graphql'

import PmResourceStateEditPure from '@/components/persoplan/PmResourceStateEdit/PmResourceStateEditPure.vue'

export const propTypes = {
  type: {
    allowed: [RESOURCE_TYPE.ADDRESS, RESOURCE_TYPE.VEHICLE],
  },
}

export default defineComponent({
  name: 'PmResourceStateEdit',
  components: {
    PmResourceStateEditPure,
  },

  props: {
    id: { type: Number, required: true },
    type: { type: String, required: true },
  },

  emits: ['close', 'cancel', 'updateSearchtermAddress'],

  setup(props, context) {
    const instance = getCurrentInstance()

    const xstate = useXState(PmResourceStateEditState, {
      services: {
        updateResourceState: () => instance.ctx.updateResourceState(),
        deleteResourceState: () => instance.ctx.deleteResourceState(),
      },
      actions: {
        emitClose: () => context.emit('close'),
      },
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      resourceStateTypes: [],
      resourceState: undefined,
      value: undefined,
      isLoadingRresourceStateTypes: undefined,
      isLoadingResourceState: undefined,
    }
  },

  computed: {
    stateOptionsNormalized() {
      return this.resourceStateTypes.map((resourceStateType) => {
        return {
          id: resourceStateType.id,
          label: resourceStateType.caption,

          hidden: !checkIfResourceStateTypeIsVisible({
            applyForType: this.type,
            resourceStateTypeId: resourceStateType.id,
            resourceType:
              RESOURCE_TYPE_LOOKUP[resourceStateType.resourceType.id],
          }),
        }
      })
    },

    isLoading() {
      return this.isLoadingRresourceStateTypes || this.isLoadingResourceState
    },
  },

  watch: {
    resourceState: 'normalizeValue',
  },

  methods: {
    normalizeValue() {
      this.value = {
        startDateTime: parseServerDateString(this.resourceState.startDate),
        endDateTime: parseServerDateString(this.resourceState.endDate),
        selectedStateId: this.resourceState.resourceStateType?.id,
        note: this.resourceState.comment,
      }
    },

    async updateResourceState() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateResourceStateMutation,

          variables: {
            resourceStateId: this.id,
            resourceStateTypeId: this.value.selectedStateId,
            startDate: formatToServerDateString(this.value.startDateTime),
            endDate: formatToServerDateString(this.value.endDateTime),
            note: this.value.note,
          },
        })
      } catch (error) {
        throwFriendlyError(error, { operation: 'update' })
      }
    },

    async deleteResourceState() {
      try {
        await this.$apollo.mutate({
          mutation: DeleteResourceStateMutation,
          refetchQueries: ['ResourceDayAddress', 'ResourceDayVehicle'],

          variables: {
            resourceStateId: this.id,
          },

          // TODO: Update cache manually when using apollo v3
        })

        EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
      } catch (error) {
        throwFriendlyError(error, { operation: 'delete' })
      }
    },
  },

  apollo: {
    resourceStateTypes: {
      query: ResourceStateTypesQuery,

      watchLoading(isLoading) {
        this.isLoadingRresourceStateTypes = isLoading
      },
    },

    resourceState: {
      query: ResourceStateQuery,

      variables() {
        return {
          id: this.id,
        }
      },

      skip() {
        if (this.xstate && this.xstate.state.value.matches('deleted')) {
          return true
        }

        return false
      },

      // TODO: Use `useLoading`
      watchLoading(isLoading) {
        this.isLoadingResourceState = isLoading
      },
    },
  },
})
</script>

<style lang="scss">
.PmResourceStateEdit {
  $block: &;
}
</style>
