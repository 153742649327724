<template>
  <portal to="modal">
    <PmModalPure :can-be-closed="modalCanBeClosed" @close="$emit('close')">
      <div class="PmGroupManagerPure">
        <div class="PmGroupManagerPure-list">
          <PmGroupManagerItemPure
            v-for="index in totalGroups"
            :key="index"
            class="PmGroupManagerPure-item"
            :number="index"
            :state="index === passStateToGroup ? state : null"
            :disabled="index === disabledGroup"
            @drop="(event) => $emit('drop', event)"
          >
          </PmGroupManagerItemPure>
        </div>

        <PmInfoPure class="PmGroupManagerPure-info">
          Diese Einstellung gilt für alle Benutzer
        </PmInfoPure>

        <PmErrorNotificationPure
          v-if="xstate.state.value.matches('save.failed')"
          class="PmGroupManagerPure-error"
          :message="errorMessage || xstate.meta.value.errorMessage"
          :details="errorDetails"
        />
      </div>
    </PmModalPure>
  </portal>
</template>

<script>
import { defineComponent, toRef } from 'vue'

import { useXState } from '@/composition/useXState'
import propValidator from '@/functional/propValidator'

import {
  PmGroupManagerPureState,
  states,
} from '@/components/persoplan/PmGroupManager/PmGroupManagerPureState'

import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmGroupManagerItemPure from '@/components/persoplan/PmGroupManager/PmGroupManagerItemPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmInfoPure from '@/components/basics/PmInfo/PmInfoPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmGroupManagerPure',
  components: {
    PmModalPure,
    PmGroupManagerItemPure,
    PmErrorNotificationPure,
    PmInfoPure,
  },

  props: {
    passStateToGroup: { type: Number, default: undefined },
    disabledGroup: { type: Number, default: undefined },
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    errorMessage: { type: String, default: undefined },
    errorDetails: { type: Array, default: undefined },
  },

  emits: ['drop', 'close'],

  setup(props) {
    const xstate = useXState(PmGroupManagerPureState, {
      syncStateWith: toRef(props, 'state'),
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      totalGroups: 20,
    }
  },

  computed: {
    modalCanBeClosed() {
      if (this.xstate.state.value.matches('save.failed')) return true

      return false
    },
  },
})
</script>

<style lang="scss">
.PmGroupManagerPure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: 16px;

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }
}
</style>
