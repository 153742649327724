<template>
  <div class="PmResourceRequestOverviewDetailsPure">
    <div class="PmResourceRequestOverviewDetailsPure-controls">
      <div
        v-if="numberOfSelected"
        class="PmResourceRequestOverviewDetailsPure-numberOfSelected"
      >
        {{ numberOfSelected }} ausgewählt
      </div>

      <PmButtonListPure class="PmResourceRequestOverviewDetailsPure-actions">
        <PmButtonPure
          v-for="(button, key) in actions"
          :key="key"
          v-bind="button"
          :disabled="areActionsDisabled"
          @click="onAction(key)"
        />
      </PmButtonListPure>
    </div>

    <div class="PmResourceRequestOverviewDetailsPure-content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'

export interface Props {
  numberOfSelected?: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'confirm'): void
  (event: 'reserve'): void
  (event: 'decline'): void
}>()

const actions: Record<'decline' | 'reserve' | 'confirm', PropsButtonPure> = {
  decline: {
    label: 'Absagen',
    icon: 'close',
  },
  reserve: {
    label: 'Reservieren',
    icon: 'questionMark',
  },
  confirm: {
    label: 'Bestätigen',
    icon: 'check',
  },
}

const areActionsDisabled = computed(() => {
  return props.numberOfSelected === 0
})

function onAction(action: keyof typeof actions) {
  if (action === 'confirm') return emit('confirm')
  if (action === 'reserve') return emit('reserve')
  if (action === 'decline') return emit('decline')

  throw new Error('action is not defined')
}
</script>

<style lang="scss">
.PmResourceRequestOverviewDetailsPure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: 12px;

  &-controls {
    display: flex;
  }

  &-numberOfSelected {
    @include mixin.textLabel;

    margin-right: auto;
    align-self: center;
  }

  &-actions {
    margin-left: auto;
  }
}
</style>
