import { getCurrentInstance } from 'vue'

type Options = {
  /** For overriding the component name extracted from the vue instance */
  componentName?: string
}

/**
 * @deprecated Use same composable from @thomasaull-shared/composables instead
 */
export function useComponentClass(options?: Options) {
  const instance = getCurrentInstance()
  if (!instance) throw new Error('instance is undefined')

  const componentName =
    options?.componentName ?? instance.type.name ?? instance.type.__name

  if (!componentName) throw new Error('componentName is undefined')

  function element(name: string) {
    return `${componentName}-${name}`
  }

  function modifier(modifier: string) {
    return `${componentName}--${modifier}`
  }

  /**
   * Universal method to create a block-element--modifier class
   */
  function className(options: { element?: string; modifier?: string }) {
    if (options.element && options.modifier) {
      return `${componentName}-${element}--${modifier}`
    }

    if (!options.element && options.modifier) {
      return `${componentName}--${modifier}`
    }

    if (options.element) {
      return `${componentName}-${element}`
    }
  }

  return { element, modifier, root: componentName, className }
}
