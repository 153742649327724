import { breakpoint } from '@/assets/scss/scssVariables'

export const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
} as const

type TScrollDirectionKeys = keyof typeof SCROLL_DIRECTION
export type TScrollDirection = (typeof SCROLL_DIRECTION)[TScrollDirectionKeys]

export const BREAKPOINT = {
  MOBILE: breakpoint['mobile'],
} as const

export const USE_SENTRY =
  String(import.meta.env.VITE_USE_SENTRY).toLowerCase() === 'true'

export const IS_DEVELOPMENT = import.meta.env.DEV === true

/**
 * In development use the local frontend against the production api?
 */
export const DANGEROUSLY_USE_PRODUCTION_API =
  String(import.meta.env.VITE_DANGEROUSLY_USE_PRODUCTION_API).toLowerCase() ===
  'true'
