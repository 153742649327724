<template>
  <div :class="componentClass.root">
    <PmLoadingOverlayPure v-if="isLoading" />

    <div :class="componentClass.element('container')">
      <table :class="componentClass.element('table')">
        <thead
          v-if="isTableHeadVisible"
          ref="elHeader"
          :class="componentClass.element('header')"
        >
          <tr :class="componentClass.element('headerRow')">
            <th
              v-for="column in columns"
              :key="column.id"
              :class="componentClass.element('headerCell')"
              :style="{
                width: column.width ? `${column.width}px` : undefined,
              }"
            >
              <template v-if="column.label">
                <PmTableHeadItemPure
                  :label="column.label"
                  :sortable="sortable && column.sort !== false"
                  :sort-direction="getSortDirectionForItem(column)"
                  @sort-descending="() => sortDescending(column)"
                  @sort-ascending="() => sortAscending(column)"
                  @reset-sorting="resetSorting"
                />
              </template>
            </th>
          </tr>
        </thead>

        <div v-if="isEmpty" :class="componentClass.element('empty')">
          <div :class="componentClass.element('emptyInner')">
            <div
              :class="[
                componentClass.element('emptyContent'),
                { 'is-hidden': isLoading },
              ]"
            >
              Keine Daten vorhanden
            </div>
          </div>
        </div>

        <template v-if="data">
          <tbody v-if="!isEmpty" :class="componentClass.element('body')">
            <PmTableRowPure
              v-for="dataRow in dataNormalized"
              :key="JSON.stringify(dataRow)"
            >
              <template
                v-for="dataPoint in dataRow"
                :key="dataPoint.id"
                #[dataPoint.slotId]
              >
                <template v-if="dataPoint.type == 'primitive'">
                  {{ dataPoint.value }}
                </template>

                <template v-if="dataPoint.type === 'complex'">
                  <slot
                    :name="`cell(${dataPoint.id})`"
                    :value="dataPoint.value"
                  />
                </template>
              </template>
            </PmTableRowPure>
          </tbody>
        </template>

        <template v-else>
          <template v-if="isGrouped">
            <slot />
          </template>

          <template v-else>
            <tbody :class="componentClass.element('body')">
              <slot />
            </tbody>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
/**
 * @see https://dev.to/ajscommunications/the-awesome-dynamic-slot-name-in-vue-3-574k
 */

import { defineComponent } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import PmLoadingOverlayPure from '@/components/basics/PmLoadingOverlay/PmLoadingOverlayPure.vue'

const COMPONENT_NAME = 'PmTablePure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, provide, ref, toRef, watch, type Ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import { cloneDeep, round, orderBy } from 'lodash-es'

import { tableKey } from '@/utilities/inject'
import { useHasSlotContent } from '@/composition/useHasSlotContent'
import { useSortedTableData } from '@/components/basics/PmTable/useSortedTableData'

import PmTableRowPure, {
  type Props as PropsTableRowPure,
} from '@/components/basics/PmTable/PmTableRowPure.vue'

import PmTableHeadItemPure from '@/components/basics/PmTable/PmTableHeadItemPure.vue'

export type TableColumn<Id> = {
  label?: string
  id: Id
  slot?: boolean
  width?: number | 'label' | 'auto'
  sort?: SortOptions['options'] | false
  padding?: boolean
}

export type TableColumns<Id = any> = TableColumn<Id>[]

export type DataPoint =
  | string
  | number
  | Record<string, unknown>
  | undefined
  | null

export type TableData = Record<string, DataPoint>[]

export interface Props {
  columns: TableColumns
  data?: TableData
  isGrouped?: boolean
  sortable?: boolean
  isLoading?: boolean
}

export interface Provide {
  columns: Ref<Props['columns']>
  isTableHeadVisible: Ref<boolean>
}

export type SortOptions = {
  id: string | null
  direction: 'ascending' | 'descending' | null
  options?:
    | {
        /**
         *  Property used for sorting:
         * - when the data is an object or an array of objects
         */
        property?: string
      }
    | null
    | false
}

export type Emit = {
  updateSorting: SortOptions
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'updateSorting', payload: Emit['updateSorting']): void
}>()

const componentClass = useComponentClass({ props })

const isTableHeadVisible = computed(() => {
  const visibleLabels = props.columns.filter((column) => column.label).length
  return visibleLabels > 0
})

provide(tableKey, {
  columns: toRef(props, 'columns'),
  isTableHeadVisible: isTableHeadVisible,
})

const hasSlotContent = useHasSlotContent(['default'])

const columnIds = computed(() => {
  const ids = props.columns.map((column) => column.id)
  return ids
})

const isEmpty = computed(() => {
  const hasData = props.data && props.data.length > 0
  const hasSlot = hasSlotContent.value.default === true

  return !hasData && !hasSlot
})

const sortedTableData = useSortedTableData({
  data: toRef(props, 'data'),
})

interface DataPointNormalized {
  id: string
  slotId: string
  type: 'primitive' | 'complex'
  value: DataPoint
  width?: number
}

type DataRowNormalized = DataPointNormalized[]

const dataNormalized = computed(() => {
  if (!sortedTableData.data.value) return

  const dataNormalized: DataRowNormalized[] = []

  sortedTableData.data.value.forEach((dataRow) => {
    const dataRowNormalized: DataRowNormalized = []

    columnIds.value.forEach((id) => {
      const dataPointNormalized = normalizeDataPoint(id, dataRow[id])
      dataRowNormalized.push(dataPointNormalized)
    })

    dataNormalized.push(dataRowNormalized)
  })

  return dataNormalized
})

function normalizeDataPoint(
  id: string,
  value?: DataPoint
): DataPointNormalized {
  if (!value) {
    throw new Error('value is undefined')
  }

  const column = props.columns.find((column) => column.id === id)
  if (!column) throw new Error('column not found')

  const slotId = `cell(${column.id})`

  if (typeof value === 'object' || column.slot === true) {
    return {
      id: id,
      slotId,
      type: 'complex',
      value: value,
    }
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return {
      id: id,
      slotId,
      type: 'primitive',
      value: value,
    }
  }

  throw new Error('Wrong data schema')
}

const elHeader = ref()
const elHeaderSize = useElementSize(elHeader)
const elHeaderHeight = computed(() => {
  return round(elHeaderSize.height.value, 2)
})

const cssVars = computed(() => {
  const columnsString = props.columns.reduce((value, column) => {
    if (column.width) {
      if (typeof column.width === 'number') {
        return `${value} ${column.width}px `
      }

      if (column.width === 'auto') {
        return `${value} minmax(30px, min-content)`
      }

      if (column.width === 'label') {
        return `${value} minmax(30px, 1fr) `
      }
    }

    return `${value} minmax(30px, 1fr) `
  }, '')

  return {
    columns: columnsString,
    headerHeight: `${elHeaderHeight.value}px`,
  }
})

const sortOptions = ref<SortOptions>({
  id: null,
  direction: null,
  options: null,
})

watch(
  sortOptions,
  () => {
    sortedTableData.updateSortOptions(sortOptions.value)
    emit('updateSorting', sortOptions.value)
  },
  {
    immediate: true,
  }
)

function sortDescending(column: TableColumn<string>) {
  sortOptions.value = {
    id: column.id,
    direction: 'descending',
    options: cloneDeep(column.sort),
  }
}

function sortAscending(column: TableColumn<string>) {
  sortOptions.value = {
    id: column.id,
    direction: 'ascending',
    options: cloneDeep(column.sort),
  }
}

function resetSorting() {
  sortOptions.value = {
    id: null,
    direction: null,
    options: null,
  }
}

function getSortDirectionForItem(column: TableColumn<string>) {
  if (sortOptions.value.id !== column.id) {
    return undefined
  }

  if (sortOptions.value.direction === null) return undefined

  return sortOptions.value.direction
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmTablePure {
  $block: &;

  @include cssVar.define($block, 'cellPadding', 12px);
  @include cssVar.define($block, 'cellPadding-header', 8px);
  @include cssVar.define($block, 'colorBorder', color.$gray-400);
  @include cssVar.define($block, 'colorBorderInline', color.$gray-300);
  @include cssVar.define($block, 'borderRadius', constant.$borderRadius-large);
  @include cssVar.define(
    $block,
    'headerHeight',
    v-bind('cssVars.headerHeight')
  );

  width: 100%;
  position: relative;

  &-container {
    // overflow: auto; // Prevents position: sticky from working properly
    width: 100%;
    // height: 400px;
    // scroll-snap-type: both mandatory;
    border: 1px solid cssVar.use($block, 'colorBorder');
    border-radius: cssVar.use($block, 'borderRadius');
  }

  &-table {
    min-width: 100%;
    display: grid;
    grid-template-columns: v-bind('cssVars.columns');
    z-index: 0;
    position: relative;
  }

  &-header {
    display: grid;
    grid-column: -1 / 1;
    grid-template-columns: subgrid;
    position: sticky;
    top: calc(var(--navigationHeight) + 1px);
    z-index: 1;

    // Background
    &::before {
      content: '';
      position: absolute;
      inset: -1px;
      background-color: color.$gray-200;
      border-top-left-radius: cssVar.use($block, 'borderRadius');
      border-top-right-radius: cssVar.use($block, 'borderRadius');
      border: 1px solid cssVar.use($block, 'colorBorder');
      z-index: 1;
    }

    // Hide underlying table on scroll
    &::after {
      content: '';
      inset: -1px;
      bottom: 0;
      background: color.$white;
      z-index: 0;
      position: absolute;
    }
  }

  &-headerRow {
    display: grid;
    grid-column: -1 / 1;
    grid-template-columns: subgrid;
    position: relative;
    z-index: 1;

    // Shadow
    &::after {
      @include shadow.default('low');

      content: '';
      position: absolute;
      inset: 0;
      display: none;
    }
  }

  &-headerCell {
    padding-left: cssVar.use($block, 'cellPadding');
    padding-right: cssVar.use($block, 'cellPadding');
    text-align: left;
  }

  &-body {
    display: grid;
    grid-column: -1 / 1;
    grid-template-columns: subgrid;
    z-index: 0;
    position: relative;
  }

  &-empty {
    grid-column: -1/1;
  }

  &-emptyInner {
    padding: cssVar.use($block, 'cellPadding');

    #{$block}-emptyRow:not(:last-child) & {
      border-bottom: 1px solid cssVar.use($block, 'colorBorder');
    }
  }

  &-emptyContent {
    display: flex;
    justify-content: center;
    color: color.$gray-500;

    &.is-hidden {
      visibility: hidden;
    }
  }
}
</style>
