import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultipleResourceAllocationsRequestState.typegen'
import type { FormData } from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequestPure.vue'

export type State = Typegen0['matchesStates']

export const PmMultipleResourceAllocationsRequestState = createMachine(
  {
    tsTypes:
      {} as import('./PmMultipleResourceAllocationsRequestState.typegen').Typegen0,

    schema: {
      events: {} as
        | {
            type: 'REQUEST_RESOURCE_ALLOCATIONS'
            closeAfterSuccess: boolean
            formData: FormData
          }
        | { type: 'LOADING_FINISHED' }
        | { type: 'NO_RESULTS' },

      context: {} as {
        error: string | undefined
        errorDetails: string[] | undefined
        closeAfterSuccess: undefined | boolean
      },
    },

    id: 'PmMultipleResourceAllocationsRequestState',
    initial: 'loading',

    context: {
      error: undefined,
      errorDetails: undefined,
      closeAfterSuccess: undefined,
    },

    states: {
      loading: {
        on: {
          LOADING_FINISHED: 'default',
          NO_RESULTS: 'empty',
        },
      },

      empty: {
        type: 'final',
      },

      default: {
        on: {
          REQUEST_RESOURCE_ALLOCATIONS: 'request',
        },
      },

      request: {
        initial: 'requesting',

        states: {
          requesting: {
            entry: (context, event) => {
              if (event.type === 'REQUEST_RESOURCE_ALLOCATIONS') {
                context.closeAfterSuccess = event.closeAfterSuccess === true
              }
            },

            invoke: {
              id: 'requestResourceAllocations',
              src: 'requestResourceAllocations',

              onError: {
                target: 'failed',
                actions: (context, error) => {
                  context.error = error.data.message
                  context.errorDetails = error.data.details
                },
              },

              onDone: [
                {
                  target: 'successAndClose',
                  cond: 'shouldCloseAfterSuccess',
                },
                { target: 'success' },
              ],
            },
          },

          failed: {},

          success: {
            type: 'final',
          },

          successAndClose: {
            type: 'final',
            entry: ['showSuccessNotification', 'emitClose'],
          },
        },
      },
    },
  },
  {
    guards: {
      shouldCloseAfterSuccess: (context) => {
        return context.closeAfterSuccess === true
      },
    },
  }
)

export const states = extractAllStates(
  PmMultipleResourceAllocationsRequestState
)
