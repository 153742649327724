<template>
  <div class="PmResourceRequestOverviewList">
    <PmResourceRequestOverviewListPure
      @update-sorting="sortedTableData.updateSortOptions"
    >
      <template #table>
        <PmResourceRequestOverviewListGroupPure
          v-for="group in sortedTableData.data.value"
          :key="group.id"
          :project="group.project"
          :job="group.job"
        >
          <PmResourceRequestOverviewItemPure
            v-for="resourceRequest in group.resourceRequests"
            v-bind="resourceRequest"
            :key="resourceRequest.id"
            :selected="
              selectedResourceRequests.has(resourceRequest.resourceAllocationId)
            "
            :active="
              activeResourceRequest === resourceRequest.resourceAllocationId
            "
            @click="() => onClick(resourceRequest)"
            @select="() => onSelectResourceRequest(resourceRequest)"
            @unselect="() => onUnselectResourceRequest(resourceRequest)"
          />
        </PmResourceRequestOverviewListGroupPure>
      </template>
    </PmResourceRequestOverviewListPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceRequestOverviewList'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { uniqBy } from 'lodash-es'
import { differenceInCalendarDays } from 'date-fns'

import {
  formatToServerDateString,
  parseServerDateString,
} from '@/utilities/date'
import { getDisplayNameOfAddress } from '@/utilities/string'

import { useSortedTableData } from '@/components/basics/PmTable/useSortedTableData'

import PmResourceRequestOverviewItemPure, {
  type Props as PropsResourceRequestOverviewItemPure,
} from '@/components/PmResourceRequestOverviewItem/PmResourceRequestOverviewItemPure.vue'

import PmResourceRequestOverviewListPure from '@/components/PmResourceRequestOverviewList/PmResourceRequestOverviewListPure.vue'
import PmResourceRequestOverviewListGroupPure from '@/components/PmResourceRequestOverviewList/PmResourceRequestOverviewListGroupPure.vue'

import { type Emit as EmitResourceRequestOverviewFilter } from '@/components/PmResourceRequestOverviewFilter/PmResourceRequestOverviewFilter.vue'

import type { Nilable } from '@/types/misc'
import type { Get } from 'type-fest'

import {
  ResourceRequestOverviewListDocument,
  type ResourceRequestOverviewListQuery,
} from '@/../generated/graphql'

export interface Props {
  selectedResourceRequests: Set<number>
  activeResourceRequest?: Nilable<number>
  filter: Partial<EmitResourceRequestOverviewFilter['filter']>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  clickOnResourceRequest: [resourceAllocationId: number]
  selectResourceRequest: [resourceAllocationId: number]
  unselectResourceRequest: [resourceAllocationId: number]
  updateVisibleResourceRequests: [resourceAllocationIds: Set<number>]
  initialLoadingFinished: []
}>()

function onClick(resourceRequest: ResourceRequestNormalized) {
  emit('clickOnResourceRequest', resourceRequest.resourceAllocationId)
}

function onSelectResourceRequest(resourceRequest: ResourceRequestNormalized) {
  emit('selectResourceRequest', resourceRequest.resourceAllocationId)
}

function onUnselectResourceRequest(resourceRequest: ResourceRequestNormalized) {
  emit('unselectResourceRequest', resourceRequest.resourceAllocationId)
}

const projects = useQuery(
  ResourceRequestOverviewListDocument,
  () => {
    return {
      startDate:
        !!props.filter.startDate &&
        formatToServerDateString(props.filter.startDate),
      endDate:
        !!props.filter.endDate &&
        formatToServerDateString(props.filter.endDate),
    }
  },
  () => {
    const isEnabled = !!props.filter.startDate && !!props.filter.endDate
    return {
      enabled: isEnabled,
    }
  }
)

const unwatchLoadingFinished = watch(projects.loading, (to, from) => {
  if (from === true && to === false) {
    unwatchLoadingFinished()
    emit('initialLoadingFinished')
  }
})

type TableDataNormalized = GroupNormalized[]

type GroupNormalized = {
  id: string
  project: string
  job: string
  resourceRequests?: ResourceRequestNormalized[]
}

/**
 * Stores all resource requests by id so requests with mutliple
 * entires can be updated easily
 */
const resourceRequestsById = new Map<number, ResourceRequestNormalized>()

const tableDataNormalized = computed(() => {
  if (!projects.result.value?.projects) return

  const result: TableDataNormalized = []
  // Reset cached data
  resourceRequestsById.clear()

  projects.result.value.projects.forEach((project) => {
    const groups = normalizeProject(project)
    if (!groups) return
    result.push(...groups)
  })

  return result
})

const sortedTableData = useSortedTableData({
  data: tableDataNormalized,
  dataInNestedProperty: 'resourceRequests',
})

type ResourceRequestNormalized = PropsResourceRequestOverviewItemPure & {
  resourceAllocationId: number
}

function getResourceRequestForId(id: number) {
  const resourceRequest = resourceRequestsById.get(id)
  if (!resourceRequest) {
    throw new Error('resourceRequest not found')
  }

  return resourceRequest
}

function isFromJobOrProjectLeader(project: Project) {
  const jobOrProjectLeaderId = props.filter.jobOrProjectLeaderId
  if (!jobOrProjectLeaderId) return

  // Check jobs first
  const hasOneJobFromLeaderId = Boolean(
    project?.jobs?.find((job) => {
      const jobLeaderId = job?.arrangedBy?.id
      if (!jobLeaderId) return false
      return jobLeaderId === jobOrProjectLeaderId
    })
  )

  // If at leat one job of the project is true return early
  if (hasOneJobFromLeaderId) return true

  // Check project
  const projectLeaderId = project?.arrangedBy?.id
  if (!projectLeaderId) return
  return projectLeaderId === jobOrProjectLeaderId
}

function isProject(project: Project) {
  if (!props.filter.projectId) return
  return project?.id === props.filter.projectId
}

function isJob(job: Job) {
  if (!props.filter.jobId) return
  return job?.id === props.filter.jobId
}

function isResource(resourceAllocation: ResourceAllocation) {
  if (!props.filter.resourceId) return
  return resourceAllocation?.address?.id === props.filter.resourceId
}

function isResourceFunction(resource: Resource) {
  if (!props.filter.resourceFunctionId) return
  return resource?.resourceFunction?.id === props.filter.resourceFunctionId
}

function isStatus(resourceRequest: ResourceRequest) {
  if (!props.filter.statusId) return

  // Special handling for notAnswered which is representet as `null` in API
  if (props.filter.statusId === 'notAnswered') {
    return resourceRequest?.state === null
  }

  return resourceRequest?.state === props.filter.statusId
}

function isStatusFeedback(resourceRequest: ResourceRequest) {
  if (!props.filter.statusFeedbackId) return

  // Special handling for notAnswered which is representet as `null` or 'unknown' in API
  if (props.filter.statusFeedbackId === 'unknown') {
    return resourceRequest?.feedback?.userState === null
  }

  return resourceRequest?.feedback?.userState === props.filter.statusFeedbackId
}

function isCreatedBy(resourceRequest: ResourceRequest) {
  if (!props.filter.createdById) return

  return resourceRequest?.createdBy?.id === props.filter.createdById
}

function isUpdatedWithin(resourceRequest: ResourceRequest) {
  if (!props.filter.updatedWithinId) return
  if (!resourceRequest?.updated) return

  const updatedAt = parseServerDateString(resourceRequest.updated)
  if (!updatedAt) return
  const differenceInDays = differenceInCalendarDays(new Date(), updatedAt)

  if (props.filter.updatedWithinId === 'today') {
    return differenceInDays === 0
  }

  if (props.filter.updatedWithinId === 'last7days') {
    return differenceInDays <= 7
  }

  if (props.filter.updatedWithinId === 'last30days') {
    return differenceInDays <= 30
  }
}

function normalizeProject(project: Nilable<Project>) {
  if (!project) return
  if (!project.jobs) return

  // Filter
  if (isFromJobOrProjectLeader(project) === false) return
  if (isProject(project) === false) return

  const groups: TableDataNormalized = []

  let projectHasResourceRequests = false

  project.jobs.forEach((job) => {
    if (!job || !job.jobAppointments) return

    let jobHasResourceRequests = false

    // Filter
    if (isJob(job) === false) return

    const groupNormalized: Required<TableDataNormalized>[number] = {
      id: `${project.id}.${job.id}`,
      project: project.caption,
      job: job.caption,
      resourceRequests: [],
    }

    job.jobAppointments.forEach((jobAppointment) => {
      jobAppointment?.resourceFunctionAllocations?.forEach((resource) => {
        // Filter
        if (isResourceFunction(resource) === false) return

        resource?.resourceAllocations?.forEach((resourceAllocation) => {
          if (!resourceAllocation?.resourceRequest) return

          const resourceRequest = resourceAllocation?.resourceRequest

          // Filter
          if (isResource(resourceAllocation) === false) return
          if (isStatus(resourceRequest) === false) return
          if (isStatusFeedback(resourceRequest) === false) return
          if (isCreatedBy(resourceRequest) === false) return
          if (isUpdatedWithin(resourceRequest) === false) return

          projectHasResourceRequests = true
          jobHasResourceRequests = true

          const resourceRequestNormalized = normalizeResourceRequest({
            jobAppointment,
            resourceAllocation,
            resource,
            resourceRequest,
          })

          if (!resourceRequestNormalized) return

          /**
           * Check if resource request with same id is already present
           * and update it instead of pushing it to the list
           */
          const existingResourceRequest = resourceRequestsById.get(
            resourceRequestNormalized.id
          )

          // Update existing resource request
          if (existingResourceRequest) {
            existingResourceRequest.appointments = uniqBy(
              [
                ...existingResourceRequest.appointments,
                ...resourceRequestNormalized.appointments,
              ],
              'id'
            )

            existingResourceRequest.resourceFunctions = uniqBy(
              [
                ...existingResourceRequest.resourceFunctions,
                ...resourceRequestNormalized.resourceFunctions,
              ],
              'id'
            )
          } else {
            resourceRequestsById.set(
              resourceRequestNormalized.id,
              resourceRequestNormalized
            )

            groupNormalized.resourceRequests?.push(resourceRequestNormalized)
          }
        })
      })
    })

    if (jobHasResourceRequests) {
      groups.push(groupNormalized)
    }
  })

  if (!projectHasResourceRequests) return

  return groups
}

type Project = Get<ResourceRequestOverviewListQuery, 'projects[0]'>
type Job = Get<Project, 'jobs[0]'>
type JobAppointment = Get<Job, 'jobAppointments[0]'>
type Resource = Get<JobAppointment, 'resourceFunctionAllocations[0]'>
type ResourceAllocation = Get<Resource, 'resourceAllocations[0]'>
type ResourceRequest = Get<ResourceAllocation, 'resourceRequest'>

function normalizeResourceRequest({
  jobAppointment,
  resource,
  resourceAllocation,
  resourceRequest,
}: {
  jobAppointment: JobAppointment
  resource: Resource
  resourceAllocation: ResourceAllocation
  resourceRequest: ResourceRequest
}) {
  if (!jobAppointment || !resource || !resourceAllocation || !resourceRequest) {
    return
  }
  const resourceRequestNormalized: ResourceRequestNormalized = {
    id: resourceRequest.id,
    resourceAllocationId: resourceAllocation.id,
    status: resourceRequest.state,
    statusFeedback: resourceRequest.feedback?.userState,
    resourceFunctions: [],
    appointments: [
      {
        id: jobAppointment.id,
        name: jobAppointment.caption,
      },
    ],
    name: getDisplayNameOfAddress(resourceAllocation.address),
    createdBy: getDisplayNameOfAddress(resourceRequest.createdBy),
    updated: parseServerDateString(resourceRequest.updated),
  }

  if (resource.resourceFunction) {
    resourceRequestNormalized.resourceFunctions = [
      {
        id: resource.resourceFunction.id,
        abbreviation: resource.resourceFunction.abbreviation,
        label: resource.resourceFunction.caption,
      },
    ]
  }

  return resourceRequestNormalized
}

/**
 * Keep track of displayed resource requests for doing actions only for
 * the currently visible items
 */
const visibleResourceRequestIds = computed(() => {
  const resourceAllocationIds = new Set<number>()

  tableDataNormalized.value?.forEach((project) => {
    project.resourceRequests?.forEach((resourceRequest) => {
      resourceAllocationIds.add(resourceRequest.resourceAllocationId)
    })
  })

  return resourceAllocationIds
})

watch(
  visibleResourceRequestIds,
  () => {
    emit('updateVisibleResourceRequests', visibleResourceRequestIds.value)
  },
  { immediate: true }
)
</script>

<style lang="scss">
.PmResourceRequestOverviewList {
  $block: &;
}
</style>
