<template>
  <form
    :id="id"
    ref="elForm"
    :class="componentClass.root"
    @submit="(event) => emit('submit', event)"
  >
    <slot />

    <div v-if="slots.error" :class="componentClass.element('error')">
      <slot name="error" />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, toRef, watch, type Ref, provide, useSlots } from 'vue'
import { formKey } from '@/utilities/inject'
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props {
  id?: string
  triggerCheckValidity?: number
  disabled?: boolean
}

export interface Provide {
  disabled: Ref<Props['disabled']>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  submit: [Event]
}>()

const slots = useSlots()
const componentClass = useComponentClass()

/**
 * Check form validity from parent components
 */
const elForm = ref<HTMLFormElement>()
watch(() => props.triggerCheckValidity, checkValidity)

function checkValidity() {
  if (!elForm.value) {
    throw new Error('elForm is undefined')
  }

  const isValid = elForm.value.reportValidity()
  return isValid
}

provide(formKey, {
  disabled: toRef(props, 'disabled'),
})

defineExpose({
  elForm,
})
</script>

<style lang="scss">
.PmFormPure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: var(--space-smallest);
}
</style>
