<template>
  <div class="App" :class="globalClasses.globalClasses">
    <PmLayoutNaked v-if="appState.state.value.matches('startup')">
      <PmStartup
        class="App-startup"
        @done="appState.service.value.send('STARTUP_DONE')"
      />
    </PmLayoutNaked>

    <PmLoginInterceptor
      v-if="isLoginInterceptorVisibleNormalized"
      @success="onInterceptionSuccessful"
    />

    <PmVersionCheck v-if="!isStorybook" />
    <PmDataModal />

    <component
      :is="layoutComponent"
      v-if="!appState.state.value.matches('startup')"
    >
      <slot v-if="isStorybook" />

      <RouterView v-if="!isStorybook" v-slot="{ Component }">
        <KeepAlive :include="['PmPersoplan', 'PmResourceRequests']">
          <component :is="Component" />
        </KeepAlive>
      </RouterView>

      <template #notifications>
        <PmAppNotificationListPure
          :use-portal="true"
          portal-name="notifications"
        >
          <!-- General errors handled via vuex -->
          <PmAppNotificationPure
            v-for="error in store.state.error.errors"
            :key="error.id"
            variant="danger"
            :title="error.message"
            :can-be-closed="true"
            :with-shadow="true"
            @close="store.commit('error/remove', error.id)"
          >
            <ul v-if="error.details">
              <li v-for="item in error.details" :key="item">
                {{ item }}
              </li>
            </ul>
          </PmAppNotificationPure>

          <!-- General notifications handled via vuex -->
          <PmAppNotificationPure
            v-for="notification in store.state.notification.notifications"
            :key="notification.id"
            :title="notification.title"
            :can-be-closed="false"
            :timeout="3"
            :icon="notification.icon"
            :variant="notification.variant"
            :with-shadow="true"
            @timeout-complete="
              store.commit('notification/remove', notification.id)
            "
            @close="store.commit('notification/remove', notification.id)"
          >
            <p v-if="notification.text" v-html="notification.text"></p>

            <ul v-if="notification.details">
              <li v-for="item in notification.details" :key="item">
                {{ item }}
              </li>
            </ul>
          </PmAppNotificationPure>
        </PmAppNotificationListPure>
      </template>
    </component>

    <div class="App-portals">
      <div class="App-portal App-portal--tooltip">
        <portal-target name="tooltip" multiple />
      </div>

      <div class="App-portal App-portal--resourceDayPopover">
        <portal-target name="resourceDayPopover" multiple />
      </div>

      <div class="App-portal App-portal--popover">
        <portal-target name="popover" multiple />
      </div>

      <div class="App-portal App-portal--contextNavigation">
        <portal-target name="contextNavigation" multiple />
      </div>

      <div class="App-portal App-portal--modal">
        <portal-target name="modal" multiple />
      </div>

      <div class="App-portal App-portal--dialog">
        <portal-target name="dialog" multiple />
      </div>

      <div class="App-portal App-portal--dragMirror">
        <portal-target name="dragMirror" multiple />
      </div>

      <div v-if="hasPortalPageLoader" class="App-portal App-portal--pageLoader">
        <portal-target name="pageLoader" />
      </div>
    </div>

    <PmSvgSpritePure />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, provide, ref, onBeforeUnmount } from 'vue'
import whatInput from 'what-input'
import type { InputMethod } from 'what-input'
import { useStore } from 'vuex'
import { Wormhole } from 'portal-vue'
import { useRoute } from 'vue-router'

import { useViewportObserver } from '@/composition/useViewportObserver'
import '@/functional/detectKeyboardNavigation'
import { appState, persoplanState } from '@/state'

import {
  appStateKey,
  persoplanStateKey,
  whatInputStateKey,
} from '@/utilities/inject'

import { LAYOUT, type Layout } from '@/constants/layout'
import { EVENT } from '@/constants/events'
import { PERFORMANCE } from '@/constants/performance'

import EventHub from '@/eventHub'
import { addMark } from '@/utilities/performance'

import { useGlobalClasses } from '@/pinia/globalClasses'

import PmLayoutDefault from '@/layouts/PmLayoutDefault.vue'
import PmLayoutNaked from '@/layouts/PmLayoutNaked.vue'
import PmLoginInterceptor from '@/components/PmLoginInterceptor/PmLoginInterceptor.vue'
import PmAppNotificationListPure from '@/components/basics/PmAppNotificationList/PmAppNotificationListPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmVersionCheck from '@/components/PmVersionCheck.vue'
import PmStartup from '@/components/PmStartup/PmStartup.vue'
import PmSvgSpritePure from '@/components/PmSvgSprite/PmSvgSpritePure.vue'
import PmDataModal from '@/components/persoplan/DataModal/PmDataModal.vue'

export interface WhatInputState {
  input: InputMethod | undefined
  intent: InputMethod | undefined
  isTouch: boolean | undefined
}

export interface Props {
  isStorybook?: boolean
  storybookAppLayout?: string
  storybookWithStartup?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  storybookWithStartup: true,
})

provide('isStorybook', props.isStorybook)

const loginInterceptorVisible = ref(false)

const isLoginInterceptorVisibleNormalized = computed(() => {
  if (props.isStorybook === true && props.storybookWithStartup === false) {
    return false
  }

  return loginInterceptorVisible.value
})

const store = useStore()
const route = useRoute()

provide(appStateKey, appState)

persoplanState.service.value.subscribe(({ event }) => {
  if (event.type === 'START_EDIT') {
    store.commit('persoplan/setEditState', true)
  }

  if (event.type === 'FINISH_EDIT') {
    store.commit('persoplan/setEditState', false)
  }
})
provide(persoplanStateKey, persoplanState)

useViewportObserver()

const globalClasses = useGlobalClasses()

/**
 * WhatInput
 */
const whatInputState = reactive<WhatInputState>({
  input: undefined,
  intent: undefined,
  isTouch: undefined,
})

provide(whatInputStateKey, whatInputState)

function setWhatInput() {
  whatInputState.input = whatInput.ask('input')
  whatInputState.intent = whatInput.ask('intent')

  /**
   * Set isTouch according to mouse/touch intents (ignoring keyboard intents)
   */
  if (whatInputState.intent === 'touch') {
    whatInputState.isTouch = true
  }

  if (whatInputState.intent === 'mouse') {
    whatInputState.isTouch = false
  }
}

const hasPortalPageLoader = computed(() => {
  const hasWormholeContent = Wormhole.transports.get('pageLoader')
    ? true
    : false

  return hasWormholeContent
})

type LayoutComponent = typeof PmLayoutDefault | typeof PmLayoutNaked

const layoutLookup: Record<Layout, LayoutComponent> = {
  Default: PmLayoutDefault,
  Naked: PmLayoutNaked,
}

function isValidLayout(layout: string): layout is Layout {
  const isValid = Object.values(LAYOUT).includes(layout as Layout)
  return isValid
}

const layoutComponent = computed((): LayoutComponent => {
  if (props.isStorybook && props.storybookAppLayout) {
    return layoutLookup[props.storybookAppLayout]
  }

  const routeMetaLayout = route?.meta?.layout
  if (typeof routeMetaLayout === 'string' && isValidLayout(routeMetaLayout)) {
    return layoutLookup[routeMetaLayout]
  }

  return PmLayoutNaked
})

function showLoginInterceptor() {
  loginInterceptorVisible.value = true
}

function onInterceptionSuccessful() {
  loginInterceptorVisible.value = false
  EventHub.$emit(EVENT.LOGIN_INTERCEPTION_SUCCESS)
}

onBeforeUnmount(() => {
  EventHub.$off(EVENT.LOGIN_INTERCEPTION_START, showLoginInterceptor)

  whatInput.unRegisterOnChange(setWhatInput)
  whatInput.unRegisterOnChange(setWhatInput)
})

addMark(PERFORMANCE.MARK.INIT)
EventHub.$on(EVENT.LOGIN_INTERCEPTION_START, showLoginInterceptor)
setWhatInput()

whatInput.registerOnChange(setWhatInput, 'input')
whatInput.registerOnChange(setWhatInput, 'intent')

// In storybook, read login info from local
// storage and go straight to startup state
if (props.isStorybook) {
  if (props.storybookWithStartup) {
    store.dispatch('auth/loginWithLocalStorage')
    appState.restartWith('startup')
  } else {
    appState.restartWith('ready')
  }
}
</script>

<style lang="scss">
@import url('@/assets/scss/spacing.scss');

.App {
  $block: &;

  color: color.$text-default;

  &.is-dragAndDropInProgress *:not(.PmDropzonePure) {
    pointer-events: none;
  }

  &.is-dragAndDropInProgress {
    cursor: grabbing;
  }

  &-portals {
    position: absolute;
    inset: 0;
    z-index: constant.$zIndex-portals;
    pointer-events: none;
  }

  &-portal {
    &--tooltip {
      // Empty
    }

    &--resourceDayPopover {
      pointer-events: auto;
    }

    &--popover {
      min-width: 100vw;
      pointer-events: auto;
    }

    &--contextNavigation {
      pointer-events: auto;
    }

    &--modal {
      pointer-events: auto;
    }

    &--dialog {
      pointer-events: auto;
    }

    &--dragMirror {
      pointer-events: none;
      position: relative;
      z-index: constant.$zIndex-dragMirror;
    }

    &--pageLoader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100dvh;

      @supports not (min-height: 100dvh) {
        height: 100vh;
      }
    }
  }
}
</style>
