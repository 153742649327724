<template>
  <div class="PmLayoutNaked">
    <div class="PmLayoutNaked-notifications">
      <slot name="notifications" />
    </div>

    <div class="PmLayoutNaked-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PmLayoutNaked',
  components: {},
}
</script>

<style lang="scss">
.PmLayoutNaked {
  $block: &;

  @supports not (min-height: 100dvh) {
    min-height: var(--appHeight);
  }

  min-height: 100dvh;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas: 'content';

  &-notifications {
    position: fixed;
    z-index: constant.$zIndex-notifications;
    top: var(--navigationHeight);
    right: 0;
    width: 100%;
    max-width: 400px;
    pointer-events: none;
  }

  &-content {
    grid-area: content;
  }
}
</style>
