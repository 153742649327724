import { onUpdated, onMounted, useSlots, ref } from 'vue'
import { hasSlotContent as checkIfSlotHasContent } from '@/utilities/misc'

/**
 * @note This does not seem to work if the slot content is visible
 * depending on a computed property, the slot is not rendered, so the computed
 * prop is not evaluated, which would only be the case if the slot was rendered 😅
 * @warning Don't use this with `v-if`
 */

/**
 * @todo Update code after upgrade to TypeScript 5
 * @see "useLoading.ts"
 *
 */

interface SlotConfigOptions {
  allowEmptyText?: boolean
}

export function useHasSlotContent<
  SlotConfigOrId extends { id: string; options?: SlotConfigOptions } | string,
>(slotConfigs: readonly SlotConfigOrId[]) {
  // Normalize slotConfig
  const slotConfigsNormalized: { id: string; options?: SlotConfigOptions }[] =
    []

  slotConfigs.forEach((slotConfig) => {
    if (typeof slotConfig === 'string') {
      slotConfigsNormalized.push({ id: slotConfig })
      return
    }

    slotConfigsNormalized.push(slotConfig)
  })

  type SlotId = SlotConfigOrId extends { id: string }
    ? SlotConfigOrId['id']
    : SlotConfigOrId

  const hasSlotContent = ref({} as Record<SlotId, boolean>)

  // Fill with initial values
  slotConfigsNormalized.forEach((slotConfig) => {
    hasSlotContent.value[slotConfig['id']] = false
  })

  const slots = useSlots()

  function checkSlots() {
    slotConfigsNormalized.forEach((slotConfig) => {
      hasSlotContent.value[slotConfig['id']] = checkIfSlotHasContent(
        slots[slotConfig['id']],
        undefined,
        { allowEmptyText: slotConfig.options?.allowEmptyText }
      )
    })
  }

  onUpdated(checkSlots)
  onMounted(checkSlots)

  return hasSlotContent
}
