<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import type { PropType } from 'vue'
import { cloneDeep } from 'lodash-es'

import { useDragAndDrop } from '@/pinia/dragAndDrop'
import { useCurrentElement, useEventListener } from '@vueuse/core'

import type { DragAndDropType } from '@/constants/persoplan'

export const propTypes = {}

export interface Props {
  type: DragAndDropType
}

const COMPONENT_NAME = 'PmDropzonePure'
export default defineComponent({
  name: COMPONENT_NAME,
  components: {},

  props: {
    type: { type: String as PropType<Props['type']>, required: true },
  },

  emits: ['drop'],

  setup(props, context) {
    const isDraggedOver = ref(false)

    const el = useCurrentElement()
    const dragAndDrop = useDragAndDrop()

    // @ts-expect-error TODO
    useEventListener(el, 'pointerenter', () => onDragEnter())
    // @ts-expect-error TODO
    useEventListener(el, 'pointerleave', () => onDragLeave())
    // @ts-expect-error TODO
    useEventListener(el, 'pointerup', () => onDrop())

    const onDragEnter = () => {
      if (!dragAndDrop.canBeDropped) return
      if (!isDropAllowed.value) return

      isDraggedOver.value = true
    }

    const onDragLeave = () => {
      if (!dragAndDrop.canBeDropped) return
      if (!isDropAllowed.value) return

      isDraggedOver.value = false
    }

    const onDrop = () => {
      if (!dragAndDrop.canBeDropped) return
      if (!isDropAllowed.value) return

      context.emit('drop', {
        type: dragAndDrop.type,
        data: cloneDeep(dragAndDrop.data),
      })
    }

    const isDropAllowed = computed(() => {
      return dragAndDrop.type === props.type
    })

    const classes = computed(() => {
      let dragClasses = {}

      if (dragAndDrop.canBeDropped) {
        dragClasses = {
          'is-draggedOver': isDraggedOver.value,
          'is-dropNotAllowed': !isDropAllowed.value,
        }
      }

      return {
        [COMPONENT_NAME]: true,
        ...dragClasses,
      }
    })

    return () => {
      if (!context.slots.default) return
      return context.slots.default({
        classes: classes.value,
      })[0]
    }
  },
})
</script>

<style lang="scss">
.PmDropzonePure {
  $block: &;

  pointer-events: auto;

  &.is-dropNotAllowed {
    opacity: 0.25;
    pointer-events: none;
  }
}
</style>
