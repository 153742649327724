<script>
export default {
  name: 'PmOnClickOutsidePure',

  props: {
    do: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      willBeClickOutside: undefined,
    }
  },

  async mounted() {
    /**
     * Wait for the next macro tick to prevent a common glitch, where the same click
     * which opens a component with <PmOnClickOutside/> would be immediately closed,
     * since the click which openend it, was already outside
     */
    await new Promise((resolve) => setTimeout(resolve, 0))
    document.addEventListener('click', this.onDocumentClick)
    document.addEventListener('pointerdown', this.onPointerDown)
  },

  beforeUnmount() {
    document.removeEventListener('click', this.onDocumentClick)
    document.removeEventListener('pointerdown', this.onPointerDown)
  },

  methods: {
    onPointerDown(event) {
      this.willBeClickOutside = this.checkIfClickOutside(event)
    },

    onDocumentClick() {
      this.maybeDo()
      this.willBeClickOutside = undefined
    },

    checkIfClickOutside(event) {
      if (this.disabled) return false

      // Element is the slot element
      if (event.target === this.$el) return false

      // Element is inside of slot element
      if (this.$el.contains(event.target)) return false

      return true
    },

    maybeDo() {
      if (this.disabled) return
      if (!this.willBeClickOutside) return

      this.do()
    },
  },

  render() {
    if (!this.$slots.default()) return
    return this.$slots.default()[0]
  },
}
</script>
