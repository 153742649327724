<template>
  <PmNotePure
    :is-loading="projectQuery.loading.value"
    :note="note"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :state="pureState"
    :edit-button-visible="can('edit', 'persoplan')"
    @save="(value) => xstate.service.value.send('UPDATE', { value })"
    @cancel="xstate.service.value.send('CANCEL')"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import PmNotePure from '@/components/PmNote/PmNotePure.vue'
import { useMutation, useQuery } from '@vue/apollo-composable'

import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { useAppAbility } from '@/composition/useAppAbility'

import { PmNoteState } from '@/components/PmNote/PmNoteState'

import {
  NoteProjectDocument,
  UpdateNoteProjectDocument,
} from '@/../generated/graphql'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const { can } = useAppAbility()

const xstate = useXState(PmNoteState, {
  services: {
    updateNote: (context, { value }) => {
      return updateNote(value)
    },
  },
})

const note = computed(() => {
  return project.value?.comment
})

const pureState = computed(() => {
  if (xstate.state.value.matches('update.updating')) {
    return 'editing.saving'
  }

  if (xstate.state.value.matches('update.failed')) {
    return 'editing.failed'
  }

  if (xstate.state.value.matches('default')) {
    return 'default'
  }

  return undefined
})

const projectQuery = useQuery(NoteProjectDocument, () => {
  return {
    id: props.id,
  }
})

const project = computed(() => projectQuery.result.value?.project)

const updateNoteMutation = useMutation(UpdateNoteProjectDocument)

async function updateNote(value: string) {
  try {
    await updateNoteMutation.mutate({
      id: props.id,
      note: value,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>
