import { ref, computed, nextTick, onBeforeUnmount, type ComputedRef } from 'vue'
import type { Ref } from 'vue'
import { subject } from '@casl/ability'
import { isSameDay } from 'date-fns'

import { RESOURCE_ALLOCATION_HIGHLIGHT_REASON } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import EventHub from '@/eventHub'

import { useAppAbility } from '@/composition/useAppAbility'
import {
  useResourceDayShared,
  getConflictingTimeEntries,
  type ConflictItem,
  getResourceDayStatus,
} from '@/components/persoplan/PmResourceDay/useResourceDayShared'
import { useJumpTargets, type JumpTargetBase } from '@/pinia/jumpTargets'

import type { ResourceDayVehicleQuery } from '@/../generated/graphql'

export default function useResourceDayVehicle({
  vehicleId,
  agenda,
  additionalConflictItems,
  startDate,
  endDate,
}: {
  vehicleId: Ref<number | undefined>
  agenda: Ref<ResourceDayVehicleQuery['agenda']>
  additionalConflictItems?: Ref<ConflictItem[] | undefined>
  startDate?: ComputedRef<Date | undefined>
  endDate?: ComputedRef<Date | undefined>
}) {
  const { can } = useAppAbility()

  const isNotificationVisible = ref(false)

  const userCanCreateOrEditResourceState = computed(() => {
    return can('create', 'ResourceStateVehicle')
  })

  const { normalizedResourceAllocations, normalizedResourceStates } =
    useResourceDayShared({ agenda, userCanCreateOrEditResourceState })

  const normalizedItems = computed(() => {
    const items = [
      ...normalizedResourceAllocations.value,
      ...normalizedResourceStates.value,
    ]

    // Augment overlapping time entries
    items.forEach((item) => {
      item.conflictingItems = getConflictingTimeEntries(
        item,
        items,
        additionalConflictItems?.value
      )
    })

    return items
  })

  const normalizedTitle = computed(() => {
    return agenda.value?.vehicle?.caption
  })

  const resourceDayStatus = computed(() => {
    if (!startDate?.value || !endDate?.value) return
    if (!isSameDay(startDate.value, endDate.value)) {
      // console.log('no resourceDayStatus for multiple days')
      return
    }

    // From here on, we know startDate and endDate are on the same day
    const day = startDate.value

    return getResourceDayStatus({
      items: normalizedItems.value,
      day: day,
    })
  })

  const jumpTargets = useJumpTargets()

  const jumpToResourceAllocationInCalendar = (id: number) => {
    const jumpTarget: JumpTargetBase = {
      id: id,
      type: 'ResourceAllocation',
    }

    // Check if element is present and visible
    if (!jumpTargets.isAvailable(jumpTarget)) {
      return canNotJumpToResourceAllocation()
    }

    jumpTargets.jumpTo(jumpTarget)

    // Clear all previous highlights
    EventHub.$emit(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, {
      reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW,
    })

    EventHub.$emit(EVENT.RESOURCE_ALLOCATION_HIGHLIGHT, {
      id,
      reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW,
    })
  }

  const canNotJumpToResourceAllocation = async () => {
    if (isNotificationVisible.value === true) {
      isNotificationVisible.value = false
      await nextTick()
    }

    isNotificationVisible.value = true
  }

  onBeforeUnmount(() => {
    EventHub.$emit(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, {
      reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW,
    })
  })

  return {
    agenda,
    userCanCreateOrEditResourceState,
    normalizedItems,
    normalizedResourceAllocations,
    normalizedTitle,
    jumpToResourceAllocationInCalendar,
    isNotificationVisible,
    resourceDayStatus,
  }
}
