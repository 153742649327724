import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmChangePasswordFormPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmChangePasswordFormPureState = createMachine({
  tsTypes: {} as import('./PmChangePasswordFormPureState.typegen').Typegen0,
  schema: {},

  id: 'PmChangePasswordFormPureState',
  initial: 'default',
  states: {
    default: {},
    saving: {},
    success: {
      entry: ['resetForm'],
    },
    failed: {
      meta: {
        error: true,
        errorMessage:
          'Es gab einen unbekannten Fehler beim Ändern des Passworts',
      },
    },
  },
})

export const states = extractAllStates(PmChangePasswordFormPureState)
