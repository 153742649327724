<template>
  <PmFloatingBarPure>
    <div class="PmMultiSelectionControllerPure"></div>

    <PmMultiSelectionDefaultPure
      v-if="xstate.state.value.matches('default')"
      :type="type"
      :number-of-selected="numberOfSelected"
      :resource-functions="resourceFunctions"
      @drop-address="
        (addressId) => $emit('initMultiAllocationAddress', addressId)
      "
      @drop-vehicle="
        (vehicleId) => $emit('initMultiAllocationVehicle', vehicleId)
      "
      @cancel="$emit('clearSelection')"
      @search="$emit('searchResource')"
    />

    <div v-if="xstate.state.value.matches('allocate')">
      <PmMultiSelectionAllocatePure
        :resource-title="resourceTitle"
        :resources-to-allocate="resourcesToAllocate"
        :state="allocationState"
        :error-message="errorMessage"
        :status-options="statusOptions"
        :selected-status-id="selectedStatusId"
        @select-status="(statusId) => $emit('selectStatus', statusId)"
        @confirm="$emit('confirmMultiAllocation')"
        @cancel="$emit('cancelMultiAllocation')"
      />
    </div>
  </PmFloatingBarPure>
</template>

<script>
import { defineComponent, toRef } from 'vue'
import propValidator from '@/functional/propValidator'

import {
  PmMultiSelectionControllerPureState,
  states,
} from '@/components/persoplan/PmMultiSelectionController/PmMultiSelectionControllerPureState'
import { useXState } from '@/composition/useXState'

import PmFloatingBarPure from '@/components/basics/PmFloatingBarPure.vue'
import PmMultiSelectionDefaultPure from '@/components/persoplan/PmMultiSelectionController/PmMultiSelectionDefaultPure.vue'
import PmMultiSelectionAllocatePure from '@/components/persoplan/PmMultiSelectionController/PmMultiSelectionAllocatePure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmMultiSelectionControllerPure',
  components: {
    PmFloatingBarPure,
    PmMultiSelectionDefaultPure,
    PmMultiSelectionAllocatePure,
  },

  props: {
    type: { type: String, required: true },
    numberOfSelected: { type: Number, required: true },
    resourceFunctions: { type: Array, default: () => [] },
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    resourceTitle: { type: String, default: undefined },
    resourcesToAllocate: { type: Array, default: () => [] },
    errorMessage: { type: String, default: undefined },
    statusOptions: { type: Array, default: () => [] },
    selectedStatusId: { type: [Number, String], required: true },
  },

  emits: [
    'initMultiAllocationVehicle',
    'initMultiAllocationAddress',
    'clearSelection',
    'searchResource',
    'confirmMultiAllocation',
    'selectStatus',
    'cancelMultiAllocation',
  ],

  setup(props) {
    const xstate = useXState(PmMultiSelectionControllerPureState, {
      syncStateWith: toRef(props, 'state'),
    })

    return {
      xstate,
    }
  },

  computed: {
    allocationState() {
      if (this.xstate.state.value.matches('allocate.saving')) return 'saving'
      if (this.xstate.state.value.matches('allocate.success')) return 'success'
      if (this.xstate.state.value.matches('allocate.failed')) return 'failed'

      return undefined
    },
  },
})
</script>

<style lang="scss">
.PmMultiSelectionControllerPure {
  $block: &;
}
</style>
