import { setup } from 'xstate5'

export const PmCreateLeaveRequestPureState = setup({
  types: {
    events: { type: 'create' },

    meta: {} as {
      error?: boolean
      message?: string
    },
  },
}).createMachine({
  id: 'PmCreateLeaveRequestPureState',
  initial: 'default',

  states: {
    default: {
      on: {
        create: 'create',
      },
    },

    create: {
      initial: 'saving',

      states: {
        saving: {},
        error: {
          meta: {
            error: true,
            message:
              'Es gab einen unbekannten Fehler beim Erstellen des Urlaubsantrags',
          },
        },
        success: {
          type: 'final',
        },
      },
    },
  },
})
