<template>
  <div class="PmDayHeadPure" :class="classes">
    <div class="PmDayHeadPure-content">
      <div class="PmDayHeadPure-dayOfTheWeek">
        {{ dayOfTheWeek }}
      </div>
      <div class="PmDayHeadPure-dayOfTheMonth">
        {{ dayOfTheMonth }}

        <PmPillPure
          v-if="pillLabel"
          :label="pillLabel"
          variant="primary"
          class="PmDayHeadPure-pill"
          :title="pillTitle"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmDayHeadPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { isToday, isSunday } from 'date-fns'

import { formatWithLocale } from '@/utilities/date'
import PmPillPure from '@/components/basics/PmPillPure.vue'

export interface Props {
  date: Date
  isPublicHolidayInGermany?: boolean
  isPublicHolidayInLuxemburg?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const classes = computed(() => {
  return {
    'is-today': props.date ? isToday(props.date) : false,
    'is-holiday': isHoliday.value,
  }
})

const dayOfTheWeek = computed(() => {
  return formatWithLocale(props.date, 'EEEEEE')
})

const dayOfTheMonth = computed(() => {
  return formatWithLocale(props.date, 'dd')
})

const isHoliday = computed(() => {
  if (props.isPublicHolidayInGermany) return true
  if (props.isPublicHolidayInLuxemburg) return true
  if (props.date && isSunday(props.date)) return true

  return false
})

const pillLabel = computed(() => {
  if (props.isPublicHolidayInGermany && props.isPublicHolidayInLuxemburg)
    return 'De/Lux'

  if (props.isPublicHolidayInGermany) return 'De'
  if (props.isPublicHolidayInLuxemburg) return 'Lux'

  return undefined
})

const pillTitle = computed(() => {
  if (props.isPublicHolidayInGermany && props.isPublicHolidayInLuxemburg)
    return 'Feiertag in Deutschland und Luxemburg'

  if (props.isPublicHolidayInGermany) return 'Feiertag in Deutschland'
  if (props.isPublicHolidayInLuxemburg) return 'Feiertag in Luxemburg'

  return undefined
})
</script>

<style lang="scss">
.PmDayHeadPure {
  $block: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  user-select: none;

  // Today Indicator
  &::before {
    content: '';
    width: 100%;
    height: 3px;
    background: color.$key;
    position: absolute;
    bottom: -3px;
    left: 0;
    display: none;

    #{$block}.is-today & {
      display: block;
    }
  }

  &-content {
    padding-left: var(--space-gutters);
    padding-right: var(--space-gutters);
  }

  &-dayOfTheWeek {
    @include mixin.textLabel;

    text-align: center;
    line-height: 1;
    margin-bottom: 0.1em;

    #{$block}.is-today & {
      color: color.$key;
    }

    #{$block}.is-holiday & {
      color: color.$danger-600;
    }
  }

  &-dayOfTheMonth {
    font-size: constant.$fontSize-larger;
    line-height: 1;
    position: relative;

    #{$block}.is-today & {
      color: color.$key;
    }

    #{$block}.is-holiday & {
      color: color.$danger-600;
    }
  }

  &-pill {
    position: absolute;
    right: calc(100% + 4px);
    top: 1px;
  }
}
</style>
