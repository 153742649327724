import { createMachine } from 'xstate'

import type { Typegen0 } from './PmDataModalResourceSettingsState.typegen'

export const PmDataModalResourceSettingsState = createMachine({
  tsTypes: {} as import('./PmDataModalResourceSettingsState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'SAVE' },
    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
    services: {} as {
      save: any
    },
  },

  id: 'PmDataModalResourceSettingsState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        SAVE: 'saving',
      },
    },

    saving: {
      invoke: {
        id: 'saving',
        src: 'save',
        onError: {
          target: 'failed',
          actions: (context, error) => {
            console.error(error.data)
            context.error = error.data.message
            context.errorDetails = error.data.details
          },
        },
        onDone: 'default',
      },
    },
    failed: {
      on: {
        SAVE: 'saving',
      },
    },
  },
})

export type TState = Typegen0['matchesStates']
