<template>
  <button class="PmResourceActionPure" :class="classes" @click="emit('click')">
    <PmIconPure
      v-if="icon"
      :key="icon"
      :name="icon"
      class="PmResourceActionPure-icon"
    />

    <div v-if="label" class="PmResourceActionPure-label">{{ label }}</div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceActionPure'

export const propTypes = {
  variant: {
    allowed: ['default', 'danger'],
  },
  size: {
    allowed: ['small', 'default'],
  },
  icon: {
    allowed: Object.values(ICONS),
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { ICONS, type Icon } from '@/constants/icons'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  icon?: Icon
  label?: string | number
  isDisabled?: boolean
  variant?: (typeof propTypes.variant.allowed)[number]
  isActive?: boolean
  size?: (typeof propTypes.size.allowed)[number]
  hasNotification?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
})

const emit = defineEmits<{
  (event: 'click'): void
}>()

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--danger`]: props.variant === 'danger',
    [`${COMPONENT_NAME}--sizeSmall`]: props.size === 'small',
    [`${COMPONENT_NAME}--sizeDefault`]: props.size === 'default',
    'is-disabled': props.isDisabled,
    'is-active': props.isActive,
    'has-notification': props.hasNotification,
  }
})
</script>

<style lang="scss">
@use 'sass:math';

.PmResourceActionPure {
  $block: &;

  @include mixin.transition-hover((background-color), $block);
  @include cssVar.define($block, 'size', 17px);
  @include cssVar.define($block, 'colorText', inherit);
  @include cssVar.define($block, 'colorBackground', color.$gray-200--alpha);
  @include cssVar.define(
    $block,
    'colorBackground--hover',
    color.$gray-300--alpha
  );
  @include cssVar.define(
    $block,
    'colorBackground--active',
    color.$gray-400--alpha
  );

  &--danger {
    @include cssVar.define($block, 'colorText', color.$danger-50);
    @include cssVar.define($block, 'colorBackground', color.$danger-400);
    @include cssVar.define($block, 'colorBackground--hover', color.$danger-500);
    @include cssVar.define(
      $block,
      'colorBackground--active',
      color.$danger-600
    );
  }

  &--sizeDefault {
    @include cssVar.define($block, 'size', 20px);
  }

  min-width: cssVar.use($block, 'size');
  height: cssVar.use($block, 'size');
  border-radius: constant.$borderRadius-default;
  padding: 2px;
  color: cssVar.use($block, 'colorText');
  background-color: cssVar.use($block, 'colorBackground');
  position: relative;
  display: block;

  &:hover,
  &.is-hover {
    background-color: cssVar.use($block, 'colorBackground--hover');
  }

  &:active,
  &.is-active {
    background-color: cssVar.use($block, 'colorBackground--active');
  }

  &:focus,
  &.is-focus {
    outline: none;

    .is-keyboardNavigation & {
      box-shadow: 0 0 0 2px rgba(color.$key, 0.5);
    }
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &::before {
    $size: 6px;

    content: '';
    background-color: color.$danger-500;
    width: $size;
    height: $size;
    border-radius: $size;
    position: absolute;
    top: math.div($size, 3) * -1;
    right: math.div($size, 3) * -1;
  }

  &:not(.has-notification)::before {
    display: none;
  }

  &-icon {
    width: calc(cssVar.use($block, 'size') - 4px);
    height: calc(cssVar.use($block, 'size') - 4px);
  }

  &-label {
    font-size: constant.$fontSize-small;
    font-weight: bold;
    text-align: center;
  }
}
</style>
