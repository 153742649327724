<template>
  <PmDialogPure
    v-bind="separateAttrs($attrs).attributes"
    :class="$attrs.class"
    :title="title"
    :can-be-closed="!isLoading"
    :error-message="errorMessage"
    :error-details="errorDetails"
    :text="text"
    :buttons="buttons"
    @cancel="emit('cancel')"
    @confirm="emit('confirm')"
  >
    <slot />
  </PmDialogPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PmDialogPure, {
  type Props as PropsDialogPanelPure,
} from '@/components/basics/PmDialog/PmDialogPure.vue'
import { separateAttrs } from '@/utilities/misc'
import { lowerFirst } from 'lodash-es'
import type { Get } from 'type-fest'

export interface Props {
  entity?: string
  subject: string
  actionLabel: string
  isLoading?: boolean
  disabled?: boolean
  text?: string
  errorMessage?: PropsDialogPanelPure['errorMessage']
  errorDetails?: PropsDialogPanelPure['errorDetails']
  /** When the confirm button should submit a form */
  formId?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  confirm: []
  cancel: []
}>()

const title = computed(() => {
  return `${props.subject} ${lowerFirst(props.actionLabel)}?`
})

const buttons = computed(() => {
  if (props.errorMessage) return buttonsError.value
  return buttonsDefault.value
})

const buttonsDefault = computed<PropsDialogPanelPure['buttons']>(() => {
  let buttonConfirm: Get<PropsDialogPanelPure, 'buttons[0]'> = {
    label: props.actionLabel,
    action: 'confirm',
    variant: 'primary',
    icon: 'check',
    disabled: props.disabled,
    loading: props.isLoading,
  }

  if (props.formId) {
    buttonConfirm = {
      ...buttonConfirm,
      type: 'submit',
      form: props.formId,
    }
  }

  return [
    {
      label: 'Abbrechen',
      action: 'cancel',
      icon: 'close',
      disabled: props.isLoading || props.disabled,
    },
    buttonConfirm,
  ]
})

const buttonsError = computed<PropsDialogPanelPure['buttons']>(() => [
  {
    label: 'Schließen',
    action: 'cancel',
    icon: 'close',
  },
])
</script>
