import { ApolloLink } from '@apollo/client'
import { ApolloClient, HttpLink, Observable } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { from } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import extractFiles from 'extract-files/extractFiles.mjs'
import isExtractableFile from 'extract-files/isExtractableFile.mjs'

import { EVENT } from '@/constants/events'
import runtimeVariables from '@/utilities/runtimeVariables'
import store from '@/store'
import { cache } from '@/apolloCache'
import { resetLazyLoadedResourcesStatus } from '@/composition/useLazyloadedResourceDetails'
import eventHub from '@/eventHub'

const URL_BATCH = `${runtimeVariables.apiUrl}/graphql/batch`
const URL_DEBUG = `${runtimeVariables.apiUrl}/graphql/`
const URL = `${runtimeVariables.apiUrl}/graphql`

const authLink = setContext(() => {
  // @ts-expect-error store is not typed
  const token = store.state.auth.token

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

/**
 * Handle Auth error by interception the reqeusts and show a login modal,
 * which allows the user to provide login credentials again
 * @see github.com/apollographql/apollo-link/issues/137#issuecomment-335326859
 */
// Docs: https://www.apollographql.com/docs/react/api/link/apollo-link-error
const authErrorLink = onError(({ networkError, operation, forward }) => {
  if (!networkError) return
  if (!('response' in networkError)) return

  if (networkError && networkError.response?.status === 401) {
    return new Observable((observable) => {
      eventHub.$emit(EVENT.LOGIN_INTERCEPTION_START)

      eventHub.$once(EVENT.LOGIN_INTERCEPTION_SUCCESS, () => {
        // @ts-expect-error store is not typed
        const token = store.state.auth.token

        operation.setContext({
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        forward(operation).subscribe(observable)
      })

      // TODO: Maybe handle case where the user cancels the process (which is not possible at the moment)
      // store.commit('auth/resetAuth')
      // window.location.reload()
    })
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      console.error(`error on ${operation.operationName}`)
      console.error(error)
    })

  if (networkError) console.error(networkError)
})

/**
 * Everytime the calendarCache is refetched, we need to trigger a reset of
 * the lazyloaded resource details, in order to get those updated aswell
 */
const clearLazyLoaded = new ApolloLink((operation, forward) => {
  const isCalendarCache = operation.operationName === 'CalendarCache'

  if (isCalendarCache) {
    resetLazyLoadedResourcesStatus()
  }

  return forward(operation)
})

const batchHttpLink = new BatchHttpLink({
  uri: URL_BATCH,
  // batchMax: 5, // No more than 5 operations per batch
  // batchInterval: 20, // Wait no more than 20ms after first batched operation
})

const httpLink = new HttpLink({
  uri: URL_DEBUG,
})

const uploadLink = createUploadLink({
  uri: URL,
})

/**
 * Use upload-link together with batch link
 * @see https://github.com/jaydenseric/apollo-upload-client/issues/34#issuecomment-638901324
 */
const batchOrUploadLink = ApolloLink.split(
  // Returning true uses the first option
  (operation) => {
    const hasFiles = extractFiles(operation, isExtractableFile).files.size > 0
    if (hasFiles === true) return true
    return false
  },
  uploadLink,
  batchHttpLink
  // httpLink
)

// const uploadAndBatchHTTPLink = opts => ApolloLink.split(
//   operation => extractFiles(operation).files.size > 0,
//   createUploadLink(opts),
//   new BatchHttpLink(opts)
// );

// const uploadLink = createUploadLink()

// Create the apollo client
export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: from([
    authLink,
    errorLink,
    authErrorLink,
    clearLazyLoaded,
    batchOrUploadLink,
  ]),
  cache,
  // This is what enables cancelation, see PmPollingIndicator
  queryDeduplication: false,
})
