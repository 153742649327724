<template>
  <PmTableRowPure
    class="PmMultipleResourceAllocationsRequestItemPure"
    :disabled="isDisabled"
  >
    <template #cell(status)>
      <PmStatusPillPure
        type="resourceAllocation"
        :resource-allocation-status="status"
      />
    </template>
    <template #cell(project)>{{ id }} {{ project ?? '—' }} </template>
    <template #cell(job)> {{ job ?? '—' }} </template>
    <template #cell(appointment)>
      <template v-if="!appointments"> — </template>

      <template v-else>
        <template v-if="appointments.length > 1">
          <PmRichTextPure>
            <ul>
              <li v-for="appointment in appointments" :key="appointment">
                {{ appointment }}
              </li>
            </ul>
          </PmRichTextPure>
        </template>

        <template v-else>
          {{ appointments[0] }}
        </template>
      </template>
    </template>
    <template #cell(resourceFunction)> {{ resourceFunction ?? '—' }} </template>
    <template #cell(resonForIgnore)>
      <span class="PmMultipleResourceAllocationsRequestItemPure-bla">
        <template v-if="reasonForIgnore === 'statusIsNotConsidered'">
          Status ist nicht „Angedacht”
        </template>

        <template v-if="reasonForIgnore === 'resourceAllocationExistsAlready'">
          Es besteht eine Anfrage
        </template>
      </span>
    </template>
  </PmTableRowPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsRequestItemPure'

export const propTypes = {
  status: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  },
  reasonForIgnore: {
    allowed: ['statusIsNotConsidered', 'resourceAllocationExistsAlready'],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import type { Nilable } from '@/types/misc'

import {
  type ResourceAllocationStatus,
  STATUS_RESOURCE_ALLOCATION,
} from '@/constants/persoplan'

import PmTableRowPure from '@/components/basics/PmTable/PmTableRowPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'

export interface Props {
  id: number
  status?: ResourceAllocationStatus
  project?: Nilable<string>
  job?: Nilable<string>
  appointments?: Nilable<string[]>
  resourceFunction?: Nilable<string>
  reasonForIgnore?: (typeof propTypes.reasonForIgnore.allowed)[number]
}

const props = withDefaults(defineProps<Props>(), {})

const isDisabled = computed(() => {
  return props.reasonForIgnore ? true : false
})
</script>

<style lang="scss">
.PmMultipleResourceAllocationsRequestItemPure {
  $block: &;

  &-statusIcon {
    width: 20px;
    height: 20px;

    &--error {
      color: color.$danger-600;
    }

    &--success {
      color: color.$success-600;
    }
  }

  &-bla {
    @include mixin.textLabel;
  }
}
</style>
