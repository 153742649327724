<template>
  <div class="PmTimeIndicatorPure" :class="classes">
    <div class="PmTimeIndicatorPure-inner" :style="stylesInner as any">
      <div class="PmTimeIndicatorPure-content" :style="stylesContent as any">
        <div class="PmTimeIndicatorPure-lineContainer">
          <div
            v-if="showOverflowMarker && isConstrainedStart"
            class="PmTimeIndicatorPure-overflowIndicator PmTimeIndicatorPure-overflowIndicator--start"
          >
            <PmIconPure :name="ICONS.OVERFLOW_LEFT" />
          </div>

          <div class="PmTimeIndicatorPure-line">
            <div class="PmTimeIndicatorPure-lineNotAvailable"></div>

            <div class="PmTimeIndicatorPure-lineContent">
              <div v-show="isLabelVisible" class="PmTimeIndicatorPure-label">
                {{ label }}
              </div>

              <div
                v-if="hasNote && variant === 'large'"
                class="PmTimeIndicatorPure-icon"
              >
                <PmIconPure :name="ICONS.NOTE" />
              </div>
            </div>
          </div>

          <div
            v-if="showOverflowMarker && isConstrainedEnd"
            class="PmTimeIndicatorPure-overflowIndicator PmTimeIndicatorPure-overflowIndicator--end"
          >
            <PmIconPure :name="ICONS.OVERFLOW_RIGHT" />
          </div>
        </div>

        <div class="PmTimeIndicatorPure-time PmTimeIndicatorPure-time--start">
          {{ labelStart }}
        </div>

        <div class="PmTimeIndicatorPure-time PmTimeIndicatorPure-time--end">
          {{ labelEnd }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmTimeIndicatorPure'

export const propTypes = {
  variant: {
    allowed: ['default', 'large'] as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
// TODO: It might be better from a performance perspective to draw the line as SVG (couldn't figure out if possible yet)
import { computed } from 'vue'
import { differenceInMinutes } from 'date-fns'

import { ICONS } from '@/constants/icons'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

import {
  getHoursAsFloat,
  getTotalHoursOfDay,
  getAlternativeFormattingIfOutside,
} from '@/utilities/date'

import {
  props as propsDateConstrain,
  default as useDateConstrainComposable,
} from '@/composition/useDateConstrain'

export interface Props {
  startDate: Date
  endDate: Date
  label?: string
  variant?: (typeof propTypes.variant.allowed)[number]
  isNotAvailable?: boolean
  isTimeAlwaysVisible?: boolean
  hasNote?: boolean
  isConflict?: boolean
  showOverflowMarker?: boolean
  // propsDateConstrain
  useDateConstrain?: boolean
  containerStartDate?: Date
  containerEndDate?: Date
  itemsForContentBounds?: []
}

const props = withDefaults(defineProps<Props>(), {
  showOverflowMarker: true,
  // propsDateConstrain
  useDateConstrain: true,
})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const {
  isConstrainedStart,
  isConstrainedEnd,
  constrainedStartDate,
  constrainedEndDate,
  numberOfDaysConstrained,
  styles: stylesInner,
} = useDateConstrainComposable(props)

const classes = computed(() => {
  return {
    'PmTimeIndicatorPure--variantLarge': props.variant === 'large',
    'is-notAvailable': props.isNotAvailable,
    'is-timeAlwaysVisible': props.isTimeAlwaysVisible,
    'is-conflict': props.isConflict,
  }
})

const isLabelVisible = computed(() => {
  if (props.isNotAvailable) return false
  if (props.variant === 'large') return true

  return false
})

const labelStart = computed(() => {
  return getAlternativeFormattingIfOutside({
    date: props.startDate,
    start: constrainedStartDate.value,
    end: constrainedEndDate.value,
  })
})

const labelEnd = computed(() => {
  return getAlternativeFormattingIfOutside({
    date: props.endDate,
    start: constrainedStartDate.value,
    end: constrainedEndDate.value,
  })
})

const stylesContent = computed(() => {
  /** Start time of the day, as float hours */
  const startHours = getHoursAsFloat(constrainedStartDate.value)

  const durationInMinutes = differenceInMinutes(
    constrainedEndDate.value,
    constrainedStartDate.value
  )

  const durationInHours = durationInMinutes / 60

  /** The total hours of all days, where the indicator is visible */
  const totalHours = getTotalHoursOfDay() * numberOfDaysConstrained.value

  const left = startHours / totalHours
  const width = durationInHours / totalHours

  return {
    position: 'absolute',
    left: `${left * 100}%`,
    width: `${width * 100}%`,
  }
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

// TODO: Figure out a better way for hover states of parent components
.PmTimeIndicatorPure {
  $block: &;

  @include cssVar.define($block, 'height', 6px);
  @include cssVar.define($block, 'colorText', color.$text-default);
  @include cssVar.define($block, 'colorText--hover', color.$white);
  @include cssVar.define($block, 'colorLine', color.$gray-300);
  @include cssVar.define($block, 'colorLine--hover', color.$key);
  @include cssVar.define(
    $block,
    'colorBorder',
    cssVar.use($block, 'colorLine')
  );
  @include cssVar.define(
    $block,
    'colorBorder--hover',
    cssVar.use($block, 'colorLine--hover')
  );
  @include mixin.transition-hover(
    color,
    ('.PmAppointmentPure', '.PmResourceDayItemPure')
  );

  &.is-conflict {
    @include cssVar.define($block, 'colorLine', color.$danger-400);
    @include cssVar.define($block, 'colorLine--hover', color.$danger-600);
  }

  &--variantLarge {
    @include cssVar.define($block, 'height', 14px);
    @include cssVar.define($block, 'colorLine', color.$gray-300--alpha);
  }

  &.is-highlighted {
    @include cssVar.define(
      $block,
      'colorLine',
      cssVar.use($block, 'colorLine--hover')
    );
    @include cssVar.define(
      $block,
      'colorBorder',
      cssVar.use($block, 'colorBorder--hover')
    );
  }

  width: 100%;
  height: cssVar.use($block, 'height');
  z-index: 1;
  color: cssVar.use($block, 'colorText');
  position: relative;

  // TODO: Maybe use overflow: hidden, see: http://localhost:6006/?path=/story/basics-pmtimeindicatorpure--maximum

  // Debug:
  // outline: 1px solid red;
  // height: 30px;

  // Absolute positioning:
  // left: 0;
  // position: absolute;
  // top: 0;
  // transform: translateY(-50%);

  /* stylelint-disable */
  .PmAppointmentPure:hover &,
  .PmResourceDayItemPure:hover &,
  &:hover,
  &.is-hover {
    color: cssVar.use($block, 'colorText--hover');
  }

  .PmAppointmentPure:hover &.is-notAvailable,
  &.is-notAvailable:hover,
  &.is-notAvailable.is-hover {
    //
  }
  /* stylelint-enable */

  &-inner {
    height: cssVar.use($block, 'height');
  }

  &-content {
    position: absolute;
    left: 0;
    top: 0;
    height: cssVar.use($block, 'height');

    // Debug:
    // outline: 1px solid blue;
    // background-color: rgba(blue, 0.5);
  }

  &-lineContainer {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &-line {
    @include mixin.transition-hover(
      background-color,
      ($block, '.PmAppointmentPure', '.PmResourceDayItemPure')
    );

    border-radius: cssVar.use($block, 'height');
    background-color: cssVar.use($block, 'colorLine');
    flex-grow: 1;
    position: relative;

    #{$block}.is-notAvailable & {
      background-color: transparent;
    }

    /* stylelint-disable */
    .PmAppointmentPure:hover &,
    .PmResourceDayItemPure:hover &,
    #{$block}:hover &,
    #{$block}.is-hover & {
      background-color: cssVar.use($block, 'colorLine--hover');
    }
    /* stylelint-enable */

    /* stylelint-disable */
    .PmAppointmentPure:hover #{$block}.is-notAvailable &,
    .PmResourceDayItemPure:hover #{$block}.is-notAvailable &,
    #{$block}.is-notAvailable:hover &,
    #{$block}.is-notAvailable.is-hover & {
      background-color: transparent;
    }
    /* stylelint-enable */

    &::before {
      @include mixin.transition-hover(
        border-color background-color,
        ($block, '.PmAppointmentPure', '.PmResourceDayItemPure')
      );

      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid cssVar.use($block, 'colorBorder');
      border-radius: cssVar.use($block, 'height');
      display: none;

      #{$block}.is-notAvailable & {
        display: block;
      }
    }

    /* stylelint-disable */
    .PmAppointmentPure:hover &,
    .PmResourceDayItemPure:hover &,
    #{$block}:hover &,
    #{$block}.is-hover & {
      &::before {
        border-color: cssVar.use($block, 'colorBorder--hover');
      }
    }
    /* stylelint-enable */
  }

  &-lineNotAvailable {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;

    #{$block}.is-notAvailable & {
      display: block;
    }

    // Border and Image for is-notAvailable
    &::before,
    &::after {
      @include mixin.transition-hover(
        opacity,
        ($block, '.PmAppointmentPure', '.PmResourceDayItemPure')
      );

      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: cssVar.use($block, 'height');
      pointer-events: none;
      background-color: transparent;
    }

    // Default
    &::before {
      background-image: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 4px,
        #{cssVar.use($block, 'colorLine')} 4px,
        #{cssVar.use($block, 'colorLine')} 8px
      );
    }

    // Hover / Highlight
    &::after {
      background-image: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 4px,
        #{cssVar.use($block, 'colorLine--hover')} 4px,
        #{cssVar.use($block, 'colorLine--hover')} 8px
      );
      opacity: 0;
    }

    /* stylelint-disable */
    .PmAppointmentPure:hover &,
    .PmResourceDayItemPure:hover &,
    #{$block}.is-notAvailable:hover &,
    #{$block}.is-notAvailable.is-hover & {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
    /* stylelint-enable */
  }

  &-overflowIndicator {
    @include mixin.transition-hover(
      color,
      ($block, '.PmAppointmentPure', '.PmResourceDayItemPure')
    );
    @include cssVar.define($block, 'overflowIndicator-padding', 1px);

    box-sizing: content-box;
    width: 11px;
    height: cssVar.use($block, 'height');
    flex-shrink: 0;
    flex-grow: 0;
    color: cssVar.use($block, 'colorLine');

    /* stylelint-disable */
    .PmAppointmentPure:hover &,
    .PmResourceDayItemPure:hover &,
    #{$block}:hover &,
    #{$block}.is-hover & {
      color: cssVar.use($block, 'colorLine--hover');
    }
    /* stylelint-enable */

    #{$block}--variantLarge & {
      width: 22px;

      @include cssVar.define($block, 'overflowIndicator-padding', 3px);
    }

    &--start {
      padding-right: cssVar.use($block, 'overflowIndicator-padding');
    }

    &--end {
      padding-left: cssVar.use($block, 'overflowIndicator-padding');
    }
  }

  &-lineContent {
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &-label {
    @include mixin.truncateText;

    font-size: constant.$fontSize-small;
    cursor: default;
    color: currentColor;
    line-height: 100%;
  }

  &-icon {
    width: 14px;
    height: 14px;
    padding: 1px;
    flex: none;
  }

  &-time {
    @include shadow.default('very-low');
    @include mixin.transition-hover(
      opacity,
      (
        $block,
        '.PmAppointmentPure',
        '.PmAppointmentsContainerPure',
        '.PmResourceDayItemPure'
      )
    );

    transition-property: opacity;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: constant.$fontSize-smallest;
    z-index: 1;
    background-color: #fff;
    padding: 2px 5px;
    color: color.$text-default;
    pointer-events: none;
    user-select: none;
    border-radius: 20px;
    white-space: nowrap;
    opacity: 0;

    &--start {
      right: 100%;
    }

    &--end {
      left: 100%;
    }

    #{$block}.is-conflict & {
      display: none;
    }

    #{$block}.is-timeAlwaysVisible & {
      opacity: 1;
    }

    /* stylelint-disable */
    .PmAppointmentPure:hover &,
    // .PmAppointmentsContainerPure:hover &,
    .PmResourceDayItemPure:hover &,
    #{$block}:hover &,
    #{$block}.is-hover & {
      opacity: 1;
    }
    /* stylelint-enable */
  }
}
</style>
