// TODO: Make persistent values persistent via local storage or settings endpoint
const state = {
  variables: {
    sidebarLeftWidth: {
      value: 340,
      unit: 'px',
      persist: true,
      min: 200,
      max: 700,
    },
    sidebarRightWidth: {
      value: 350,
      unit: 'px',
      persist: true,
      min: 200,
      max: 450,
    },
    dayWidth: { value: 300, unit: 'px', persist: true, min: 200, max: 700 },
    navigationHeight: { value: 0, unit: 'px' },
    'PmCalendarPure-headerHeight': {
      value: 89,
      unit: 'px',
    },
    calendarHeight: { value: undefined, unit: 'px' },
    generalEventsHeight: { value: undefined, unit: 'px' },
    calendarEventsHeight: { value: undefined, unit: 'px' },
    birthdaysHeight: { value: undefined, unit: 'px' },
    freelancersHeight: { value: undefined, unit: 'px' },
    employeesHeight: { value: undefined, unit: 'px' },
    vehiclesHeight: { value: undefined, unit: 'px' },
    jobsHeight: { value: undefined, unit: 'px' },
    afterJobsHeight: { value: undefined, unit: 'px' },
    sidebarJobsHeight: { value: undefined, unit: 'px' },
    sidebarHeight: { value: undefined, unit: 'px' },
    verticalScrollbarWidth: { value: 0, unit: 'px' },
    horizontalScrollbarHeight: { value: 0, unit: 'px' },
    appHeight: { value: undefined, unit: 'px', fallback: '100vh' },
  },
}

const mutations = {
  set(state, { name, value }) {
    const variable = state.variables[name]
    variable.value = value
  },
}

const getters = {
  get: (state, getters) => (name) => {
    // Check normal variables first
    const variable = state.variables[name]
    if (variable) return variable

    // Check derived variables
    const derivedVariable = getters.derivedVariables[name]
    if (derivedVariable) return derivedVariable

    throw new Error(`The variable "${name}" does not exist`)
  },

  isSidebarRightVisible: (state, getters, rootState) => {
    if (rootState.persoplan.isEditing !== true) return false
    return rootState.persoplan.sidebarRightVisible
  },

  derivedVariables: (state, getters, rootState) => {
    const sidebarLeftWidthWithVisibility = {
      value: rootState.persoplan.sidebarLeftVisible
        ? state.variables.sidebarLeftWidth.value
        : 0,
      unit: state.variables.sidebarLeftWidth.unit,
      derivedFrom: 'sidebarLeftWidth',
    }

    const sidebarRightWidthWithVisibility = {
      value: getters.isSidebarRightVisible
        ? state.variables.sidebarRightWidth.value
        : 0,
      unit: state.variables.sidebarRightWidth.unit,
      derivedFrom: 'sidebarRightWidth',
    }

    return {
      sidebarLeftWidthWithVisibility,
      sidebarRightWidthWithVisibility,
    }
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
