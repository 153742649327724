<template>
  <div class="PmResourceStateEditPure">
    <PmLoadingPure v-if="isLoading" size="default" />

    <form
      v-if="isFormVisible"
      :id="formId"
      class="PmResourceStateEditPure-form"
      @submit.prevent="xstate.service.value.send('SAVE')"
    >
      <div class="PmResourceStateEditPure-inputs">
        <PmDropdownPure
          v-if="isAddressVisible"
          :label="labelAddress"
          :options="addressOptions"
          :value="selectedAddressId"
          :is-loading="isLoadingAddress"
          required
          @input="(id) => $emit('selectAddress', id)"
          @input-searchterm="(value) => $emit('updateSearchtermAddress', value)"
        />

        <PmDropdownPure
          label="Status"
          :options="stateOptions"
          :value="selectedStateId"
          required
          @input="(id) => $emit('selectState', id)"
        />

        <PmDateTimePickerPure
          label="Von"
          class="PmResourceStateEditPure-date"
          :value="startDateTime"
          :max="endDateTime"
          required
          @input="(date) => $emit('inputStartDateTime', date)"
        />

        <PmDateTimePickerPure
          label="Bis"
          class="PmResourceStateEditPure-date"
          :value="endDateTime"
          :min="startDateTime"
          required
          @input="(date) => $emit('inputEndDateTime', date)"
        />

        <PmTextareaPure
          label="Bemerkung"
          :value="note"
          :rows="3"
          @input="(note) => $emit('inputNote', note)"
        />
      </div>

      <div class="PmResourceStateEditPure-control">
        <PmErrorNotificationPure
          v-if="xstate.meta.value.error"
          class="PmResourceStateEditPure-notification"
          :message="errorMessage || xstate.meta.value.errorMessage"
          :details="errorDetails"
        />

        <PmAskForConfirmationPure
          v-if="xstate.state.value.matches('delete')"
          title="Ressourcen-Status löschen"
          :is-loading="xstate.state.value.matches('delete.deleting')"
          @delete="xstate.service.value.send('CONFIRM')"
          @cancel="xstate.service.value.send('CANCEL')"
        >
          Der Ressourcen-Status kann nicht wiederhergestellt werden.
        </PmAskForConfirmationPure>

        <portal
          :to="buttonsPortalName"
          :disabled="buttonsPortalName ? false : true"
        >
          <PmButtonListPure
            v-if="!xstate.state.value.matches('delete')"
            align="right"
            :full-width-on-mobile="true"
          >
            <PmButtonPure
              label="Abbrechen"
              variant="secondary"
              :disabled="xstate.state.value.matches('save.saving')"
              :icon="ICONS.CLOSE"
              @click="$emit('cancel')"
            />

            <PmButtonPure
              v-if="mode === 'edit'"
              label="Status löschen"
              variant="danger"
              :disabled="xstate.state.value.matches('save.saving')"
              :icon="ICONS.DELETE"
              @click="xstate.service.value.send('DELETE')"
            />

            <PmButtonPure
              :label="submitLabel"
              variant="primary"
              :loading="xstate.state.value.matches('save.saving')"
              :icon="ICONS.SAVE"
              type="submit"
              :disabled="isSaveDisabled"
              :form="formId"
            />
          </PmButtonListPure>
        </portal>
      </div>
    </form>

    <div v-if="xstate.state.value.matches('deleted')">
      <PmAppNotificationPure
        key="deletedNotification"
        variant="success"
        :icon="ICONS.DELETE"
        :timeout="1"
      >
        Der Ressourcen-Status wurde erfolgreich gelöscht
      </PmAppNotificationPure>
    </div>

    <div v-if="xstate.state.value.matches('save.saved')">
      <PmAppNotificationPure
        key="savedNotification"
        variant="success"
        :icon="ICONS.CHECK"
        :timeout="1"
      >
        Der Ressourcen-Status wurde erfolgreich gespeichert
      </PmAppNotificationPure>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRef, computed, ref, watch } from 'vue'
import { cloneDeep } from 'lodash-es'
import cuid from '@paralleldrive/cuid2'

import { ICONS } from '@/constants/icons'

import { useXState } from '@/composition/useXState'

import {
  PmResourceStateEditPureState,
  states,
} from '@/components/persoplan/PmResourceStateEdit/PmResourceStateEditPureState'

import PmDateTimePickerPure from '@/components/basics/PmDateTimePicker/PmDateTimePickerPure.vue'
import PmDropdownPure from '@/components/basics/PmDropdownPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmAskForConfirmationPure from '@/components/PmAskForConfirmation/PmAskForConfirmationPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'

export const propTypes = {
  mode: { allowed: ['create', 'edit'] },
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmResourceStateEditPure',
  components: {
    PmDateTimePickerPure,
    PmDropdownPure,
    PmTextareaPure,
    PmButtonPure,
    PmButtonListPure,
    PmAppNotificationPure,
    PmErrorNotificationPure,
    PmAskForConfirmationPure,
    PmLoadingPure,
  },

  props: {
    startDateTime: { type: Date, default: undefined },
    endDateTime: { type: Date, default: undefined },
    addressOptions: { type: Array, default: () => [] },
    selectedAddressId: { type: [Number, String], default: undefined },
    stateOptions: { type: Array, default: () => [] },
    selectedStateId: { type: [Number, String], default: undefined },
    note: { type: String, default: undefined },
    mode: { type: String, required: true },
    state: { type: String, default: undefined },
    errorMessage: { type: String, default: undefined },
    errorDetails: { type: Array, default: undefined },
    isLoading: { type: Boolean, default: false },
    isLoadingAddress: { type: Boolean, default: false },
    labelAddress: { type: String, default: 'Ressource' },
    isAddressVisible: { type: Boolean, default: false },
    buttonsPortalName: { type: String, default: undefined },
  },

  emits: [
    'save',
    'delete',
    'cancel',
    'selectState',
    'inputStartDateTime',
    'inputEndDateTime',
    'inputNote',
    'selectAddress',
    'updateSearchtermAddress',
  ],

  setup(props, context) {
    // const instance = getCurrentInstance()
    const initialCollectedValue = ref()

    const collectedValue = computed(() => {
      return {
        startDateTime: props.startDateTime,
        endDateTime: props.endDateTime,
        selectedStateId: props.selectedStateId,
        note: props.note,
      }
    })

    const resetInitialCollectedValue = () => {
      initialCollectedValue.value = undefined
      setInitialCollectedValue()
    }

    const setInitialCollectedValue = () => {
      if (initialCollectedValue.value) return
      initialCollectedValue.value = cloneDeep(collectedValue.value)
    }

    const xstate = useXState(PmResourceStateEditPureState, {
      actions: {
        emitSave: () => context.emit('save'),
        emitDelete: () => context.emit('delete'),
        resetInitialCollectedValue: () => {
          resetInitialCollectedValue()
        },
      },
      syncStateWith: toRef(props, 'state'),
    })

    watch(
      () => collectedValue,
      () => setInitialCollectedValue,
      { immediate: true }
    )

    return {
      ICONS,

      uid: cuid.createId(),
      xstate,
      initialCollectedValue,
      collectedValue,
    }
  },

  computed: {
    submitLabel() {
      return { create: 'Erstellen + Schließen', edit: 'Speichern + Schließen' }[
        this.mode
      ]
    },

    collectedValueHasChanged() {
      if (!this.initialCollectedValue) return false

      return (
        JSON.stringify(this.initialCollectedValue) !==
        JSON.stringify(this.collectedValue)
      )
    },

    isSaveDisabled() {
      if (this.mode === 'create') return false
      if (!this.collectedValueHasChanged) return true

      return false
    },

    isFormVisible() {
      if (this.isLoading) return false
      if (this.xstate.state.value.matches('save.saved')) return false
      if (this.xstate.state.value.matches('deleted')) return false

      return true
    },

    formId() {
      return `${this.$options.name}.${this.uid}`
    },
  },
})
</script>

<style lang="scss">
.PmResourceStateEditPure {
  $block: &;

  &-inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-dates {
    display: flex;
    gap: 8px;
  }

  &-date {
    flex-basis: 100%;
  }

  &-control {
    margin-top: 16px;
  }

  &-notification {
    margin-bottom: 8px;
  }
}
</style>
