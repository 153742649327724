<template>
  <div class="PmMultiSelectionDefaultPure">
    <div class="PmMultiSelectionDefaultPure-resources">
      <div class="PmMultiSelectionDefaultPure-resourcesLabel">
        {{ numberOfSelected }} Ausgewählte Resourcen
      </div>

      <div
        v-if="resourceFunctions.length"
        class="PmMultiSelectionDefaultPure-resourceFunctions"
      >
        <PmResourceShortPure
          v-for="resourceFunction in resourceFunctions"
          :key="resourceFunction.id"
          class="PmMultiSelectionDefaultPure-resourceFunction"
          :label="resourceFunction.label"
          :interactive="false"
        />
      </div>
    </div>

    <div class="PmMultiSelectionDefaultPure-actions">
      <PmButtonListPure>
        <PmButtonPure
          :icon="ICONS.CLOSE"
          label="Abbrechen"
          @click="$emit('cancel')"
        />

        <PmButtonPure
          :icon="ICONS.SEARCH"
          label="Suche nach Resource"
          @click="$emit('search')"
        />
      </PmButtonListPure>
    </div>

    <PmDropzonePure v-slot="props" :type="dragAndDropType" @drop="onDrop">
      <div
        class="PmMultiSelectionDefaultPure-dropzone"
        :class="{ ...props.classes }"
      >
        <div class="PmMultiSelectionDefaultPure-dropzoneLabel">
          Für Mehrfach-Zuordnung hier ablegen
        </div>
      </div>
    </PmDropzonePure>
  </div>
</template>

<script>
import { RESOURCE_TYPE, DRAG_AND_DROP_TYPE } from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmDropzonePure from '@/components/utilities/PmDropzonePure.vue'
import PmResourceShortPure from '@/components/persoplan/PmResource/PmResourceShortPure.vue'

const allowedDragAndDropTypes = [
  DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_ADDRESS,
  DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_VEHICLE,
]

export default {
  name: 'PmMultiSelectionDefaultPure',
  components: {
    PmButtonPure,
    PmButtonListPure,
    PmDropzonePure,
    PmResourceShortPure,
  },

  props: {
    type: { type: String, required: true },
    numberOfSelected: { type: Number, required: true },
    resourceFunctions: { type: Array, default: () => [] },
  },

  emits: ['cancel', 'search', 'dropAddress', 'dropVehicle'],

  setup() {
    return {
      ICONS,
    }
  },

  computed: {
    dragAndDropType() {
      if (this.type === RESOURCE_TYPE.ADDRESS)
        return DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_ADDRESS

      if (this.type === RESOURCE_TYPE.VEHICLE)
        return DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_VEHICLE

      return undefined
    },
  },

  methods: {
    onDrop(event) {
      if (!allowedDragAndDropTypes.includes(event.type)) {
        throw new Error('Not an allowed drag and drop type')
      }

      if (this.type === RESOURCE_TYPE.ADDRESS) {
        return this.$emit('dropAddress', event.data.id)
      }

      if (this.type === RESOURCE_TYPE.VEHICLE) {
        return this.$emit('dropVehicle', event.data.id)
      }
    },
  },
}
</script>

<style lang="scss">
.PmMultiSelectionDefaultPure {
  $block: &;

  &-resources {
    margin-bottom: 12px;
  }

  &-resourcesLabel {
    @include mixin.textLabel;
  }

  &-resourceFunctions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-left: -4px;
    margin-bottom: -4px;
  }

  &-resourceFunction {
    margin-left: 4px;
    margin-bottom: 4px;
  }

  &-actions {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  &-dropzone {
    border: 2px dashed color.$gray-400;
    background-color: color.$gray-100;
    border-radius: constant.$borderRadius-default;
    padding: 8px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.is-draggedOver {
      border-color: color.$primary-500;
      border-style: solid;
      background-color: rgba(color.$primary-500, 0.25);
    }
  }

  &-dropzoneLabel {
    @include mixin.textLabel;
  }
}
</style>
