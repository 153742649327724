import { ref, watch, shallowRef, triggerRef } from 'vue'
import { defineStore } from 'pinia'
import { useStore } from 'vuex'
import { addDays, areIntervalsOverlapping } from 'date-fns'

export type JumpTargetType = 'AppointmentsContainer' | 'ResourceAllocation'
type JumpTargetId = `${JumpTargetType}.${number | string}`

export interface JumpTargetBase {
  type: JumpTargetType
  id: number | string
}

export interface JumpTargetWithDates extends JumpTargetBase {
  startDate: Date
  endDate: Date
}

export const ERROR_NOT_AVAILABLE = 'ErrorNotAvailable'

interface JumpTarget extends JumpTargetBase {
  scrollIntoView: () => void
}

export const useJumpTargets = defineStore('jumpTargets', () => {
  const jumpTargets = shallowRef(new Map<JumpTargetId, JumpTarget>())
  const jumpToTargetIdWhenAvailable = ref<JumpTargetId>()
  const store = useStore()

  function getMapId(jumpTarget: JumpTargetBase) {
    const id: JumpTargetId = `${jumpTarget.type}.${jumpTarget.id}`
    return id
  }

  function register(jumpTarget: JumpTarget) {
    const mapId = getMapId({ type: jumpTarget.type, id: jumpTarget.id })
    jumpTargets.value.set(mapId, jumpTarget)
    triggerRef(jumpTargets)
  }

  function unregister(jumpTarget: JumpTargetBase) {
    const mapId = getMapId({ type: jumpTarget.type, id: jumpTarget.id })
    jumpTargets.value.delete(mapId)
    triggerRef(jumpTargets)
  }

  function jumpTo(
    jumpTarget: JumpTargetWithDates,
    options?: {
      /** Change the date if the current dates are not overlapping with the jump target yet */
      changeDate: true
    }
  ): void

  function jumpTo(
    jumpTarget: JumpTargetBase,
    options?: {
      /** Change the date if the current dates are not overlapping with the jump target yet */
      changeDate: false
    }
  ): void

  function jumpTo(
    jumpTarget: JumpTargetBase | JumpTargetWithDates,
    options?: {
      /** Change the date if the current dates are not overlapping with the jump target yet */
      changeDate?: boolean
    }
  ): void {
    const { changeDate = false } = options ?? {}

    if (isAvailable(jumpTarget)) {
      const mapId = getMapId({ type: jumpTarget.type, id: jumpTarget.id })
      jumpToId(mapId)

      return
    }

    if (changeDate !== true) {
      throw new Error(
        'jumpTarget is not available and option.changeDate is not used'
      )
    }

    if (!('startDate' in jumpTarget) || !('endDate' in jumpTarget)) {
      throw new Error('You need to provide startDate and endDate')
    }

    /**
     * Check if dates are already overlapping, in this case the target
     * should be available already
     */
    const isInVisibleDateRange = areIntervalsOverlapping(
      {
        start: jumpTarget.startDate,
        end: jumpTarget.endDate,
      },
      {
        start: store.state.persoplan.visibleStartDate,
        end: store.state.persoplan.visibleEndDate,
      }
    )

    if (isInVisibleDateRange) {
      throw new Error(
        `jumpTarget is already in visible date range, if it's not available, something else is wrong…`
      )
    }

    // Change dates and do the jump
    store.commit('persoplan/setVisibleDates', {
      startDate: jumpTarget.startDate,
      endDate: addDays(
        jumpTarget.startDate,
        store.getters['persoplan/numberOfVisibleDays'] - 1
      ),
    })

    jumpToWhenAvailable(jumpTarget)
  }

  function jumpToId(jumpTargetId: JumpTargetId) {
    // Reset target to jump to when available, not needed anymore, since
    // another jump was triggered
    jumpToTargetIdWhenAvailable.value = undefined

    const target = jumpTargets.value.get(jumpTargetId)
    if (!target) throw new Error('jump target ist not available')

    target.scrollIntoView()
  }

  function isAvailable(jumpTarget: JumpTargetBase) {
    const mapId = getMapId({ type: jumpTarget.type, id: jumpTarget.id })
    return isIdAvailable(mapId)
  }

  function isIdAvailable(jumpTargetId: JumpTargetId) {
    return jumpTargets.value.has(jumpTargetId)
  }

  function jumpToWhenAvailable(jumpTarget: JumpTargetBase) {
    if (isAvailable(jumpTarget)) {
      // It's already available, jump right away
      jumpTo(jumpTarget)
      return
    }

    const mapId = getMapId({ type: jumpTarget.type, id: jumpTarget.id })
    jumpToTargetIdWhenAvailable.value = mapId
  }

  // Maybe jump to jump target which is referenced for when available
  watch(jumpTargets, () => {
    if (!jumpToTargetIdWhenAvailable.value) return
    if (!isIdAvailable(jumpToTargetIdWhenAvailable.value)) return

    jumpToId(jumpToTargetIdWhenAvailable.value)
  })

  return {
    register,
    unregister,
    jumpTo,
    jumpToWhenAvailable,
    isAvailable,
  }
})
