import { createMachine } from 'xstate'

import type { Typegen0 } from './appState.typegen'

export const AppState = createMachine({
  tsTypes: {} as import('./appState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'LOGIN' }
      | { type: 'LOGIN_SUCCESSFUL' }
      | { type: 'STARTUP_DONE' }
      | { type: 'LOGGED_OUT' },
  },

  id: 'AppState',
  initial: 'default',

  states: {
    default: {
      on: {
        LOGIN: 'login',
      },
    },

    login: {
      on: {
        LOGIN_SUCCESSFUL: 'startup',
      },
    },

    startup: {
      on: {
        STARTUP_DONE: 'ready',
      },
    },

    ready: {
      on: {
        LOGGED_OUT: 'default',
      },
    },
  },
})

export type TState = Typegen0['matchesStates']
