import type { ScssVariables } from '@/assets/scss/export.module.d'

// @ts-ignore Don't know how to solive this
import scssVariables from '@/assets/scss/export.module.scss'

function getVariablesOfType<TResult>(type: string) {
  return Object.entries(scssVariables).reduce((result, current): TResult => {
    const key = current[0]
    const value = current[1] as string

    const search = `${type}-`

    if (key.startsWith(search)) {
      const normalizedKey = key.replace(search, '')
      let normalizedValue: string | number = value

      /**
       * Convert/Sanitize
       */
      // Example 0.2s
      if (value.endsWith('s')) {
        normalizedValue = Number(value.substring(0, value.length - 1))
      }

      // @ts-ignore Don't know how to solive this
      result[normalizedKey] = normalizedValue
    }

    return result
  }, {} as TResult)
}

export const color = getVariablesOfType<ScssVariables['color']>('color')
export const breakpoint =
  getVariablesOfType<ScssVariables['breakpoint']>('breakpoint')
export const constant =
  getVariablesOfType<ScssVariables['constant']>('constant')

export default {
  color,
  breakpoint,
  constant,
}
