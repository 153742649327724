<template>
  <div
    ref="elRoot"
    class="PmAppointmentsContainerPure"
    :class="classes"
    :style="styles"
  >
    <div class="PmAppointmentsContainerPure-header">
      <div
        v-if="isConstrainedStartMarkerVisible"
        class="PmAppointmentsContainerPure-constrainedMarker PmAppointmentsContainerPure-constrainedMarker--start"
        :title="`Start: ${startDateFormatted}`"
      >
        <PmIconPure :name="ICONS.ARROW_LEFT" />
      </div>

      <PmDraggablePure
        :type="DRAG_AND_DROP_TYPE.UPDATE_PROJECT_GROUP"
        :data="{ projectId: projectId, groupNumber: groupNumber }"
        :use-handle="true"
        class="PmAppointmentsContainerPure-headerDragAndDrop"
        classes-element="PmAppointmentsContainerPure-headerInner"
        tag="header"
      >
        <div class="PmAppointmentsContainerPure-headerContent">
          <div class="PmAppointmentsContainerPure-headerContentInner">
            <div class="PmAppointmentsContainerPure-meta">
              <template v-if="canEditResourceRequest">
                <PmContextNavigationPure
                  :items="contextNavigationItems"
                  @select-considered="selectConsidered"
                  @request-considered="emit('requestConsidered')"
                >
                  <template #trigger="slotProps">
                    <PmResourceActionPure
                      :icon="ICONS_SMALL.CONTEXT_NAVIGATION"
                      size="default"
                      :is-active="slotProps.isOpen"
                      @click="slotProps.togglePopover"
                    />
                  </template>
                </PmContextNavigationPure>
              </template>

              <PmPillPure
                v-if="alertsVisible && numberOfAlerts && numberOfAlerts > 0"
                variant="danger"
                :label="numberOfAlerts"
              />

              <PmPillPure
                v-if="number"
                class="PmAppointmentsContainerPure-number"
                :color="statusColorNormalized"
                :label="number"
              />

              <div
                v-if="collectsAllJobsOfProject"
                :title="`Alle Jobs des Projektes ${number} werden zusammengefasst`"
                class="PmAppointmentsContainerPure-icon"
              >
                <PmIconPure :name="ICONS_SMALL.COLLECTION" />
              </div>

              <PmNoteIconPure
                v-if="note"
                class="PmAppointmentsContainerPure-note"
                :note="note"
                @open="emit('openNote')"
              />

              <div
                v-if="updateGroupVisible"
                data-dragAndDrop-handle
                class="PmAppointmentsContainerPure-action"
                :title="titleDragHandle"
              >
                <PmIconPure
                  :name="ICONS_SMALL.GRIP"
                  class="PmAppointmentsContainerPure-icon"
                />
              </div>

              <button
                class="PmAppointmentsContainerPure-action"
                title="In Seitenleiste anzeigen"
                @click="emit('jump')"
              >
                <PmIconPure
                  :name="ICONS_SMALL.JUMP"
                  class="PmAppointmentsContainerPure-icon"
                />
              </button>

              <PmColorPickerPure
                v-if="alternativeColorVisible"
                :colors="colors"
                :selected-color-id="
                  alternativeColorNormalized
                    ? alternativeColorNormalized.id
                    : undefined
                "
                :is-random="
                  alternativeColorNormalized
                    ? alternativeColorNormalized.type === 'random'
                    : undefined
                "
                @select-color="(color) => emit('updateColor', color.id)"
                @use-random-color="emit('useRandomColor')"
              />
            </div>

            <div class="PmAppointmentsContainerPure-titleContainer">
              <div
                class="PmAppointmentsContainerPure-title"
                :title="title"
                @click="emit('showDetails')"
              >
                {{ title }}
              </div>

              <div
                class="PmAppointmentsContainerPure-subtitle"
                :title="subtitle"
              >
                {{ subtitle }}
              </div>
            </div>
          </div>
        </div>
      </PmDraggablePure>

      <div
        v-if="isConstrainedEnd"
        class="PmAppointmentsContainerPure-constrainedMarker PmAppointmentsContainerPure-constrainedMarker--end"
        :title="constrainedEndMarkerProps.title"
      >
        <PmIconPure :name="constrainedEndMarkerProps.icon" />
      </div>
    </div>

    <PmPackingContainerPure
      :id="PACKING_CONTAINER_ID.APPOINTMENTS_CONTAINER"
      v-slot="{ packingContainer }"
      class="PmAppointmentsContainerPure-appointments"
    >
      <slot
        :packing-container="packingContainer"
        :constrained-end-date="constrainedEndDate"
        :constrained-start-date="constrainedStartDate"
      />
    </PmPackingContainerPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmAppointmentsContainerPure'

export const propTypes = {
  alternativeColor: {
    allowed: ['default', 'alternative1', 'alternative2'],
  },

  type: {
    allowed: Object.values(CALENDAR_ITEM_TYPE),
  },

  jobStatus: {
    allowed: Object.values(STATUS),
  },

  projectStatus: {
    allowed: Object.values(PROJECT_STATUS),
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import {
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
  toRef,
  provide,
  type StyleValue,
} from 'vue'
import { debounce } from 'lodash-es'
import ColorManipulation from 'color'
import seedrandom from 'seedrandom'
import { useStore } from 'vuex'

import { EVENT } from '@/constants/events'
import {
  PACKING_CONTAINER_ID,
  CALENDAR_ITEM_TYPE,
  FORMAT_DATETIME_SHORT,
  STATUS,
  PROJECT_STATUS,
  DRAG_AND_DROP_TYPE,
  type PackingContainerId,
  type CalendarItemType,
} from '@/constants/persoplan'
import { ICONS, ICONS_SMALL } from '@/constants/icons'
import { FEATURE_FLAG } from '@/constants/featureFlags'
import { color } from '@/assets/scss/scssVariables'

import { formatWithLocale } from '@/utilities/date'
import EventHub from '@/eventHub'
import { default as useDateConstrainComposable } from '@/composition/useDateConstrain'
import { useJobStatus, type JobStatus } from '@/composition/useJobStatus'
import {
  useProjectStatus,
  type ProjectStatus,
} from '@/composition/useProjectStatus'
import { selectionProjectOrJobHelperKey } from '@/utilities/inject'
import { useJumpTarget } from '@/composition/useJumpTarget'

import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmPackingContainerPure from '@/components/persoplan/PmPackingContainerPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmDraggablePure from '@/components/utilities/PmDraggablePure.vue'
import PmColorPickerPure from '@/components/basics/PmColorPicker/PmColorPickerPure.vue'
import PmNoteIconPure from '@/components/PmNote/PmNoteIconPure.vue'
import PmContextNavigationPure, {
  type Props as PropsContextNavigationPure,
} from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'
import PmResourceActionPure from '@/components/persoplan/PmResourceActionPure.vue'

export interface Props {
  id: number
  title?: string
  subtitle?: string
  alertsVisible?: boolean
  numberOfAlerts?: number
  number?: string
  isHighlighted?: boolean
  jobStatus?: JobStatus
  projectStatus?: ProjectStatus
  startDate?: Date
  endDate?: Date
  collectsAllJobsOfProject?: boolean
  type: CalendarItemType
  groupNumber?: number
  projectId?: number
  updateGroupVisible?: boolean
  disableDateConstrainStyles?: boolean
  alternativeColor?: (typeof propTypes.alternativeColor.allowed)[number]
  alternativeColorVisible?: boolean
  note?: string
  // Props from useDateConstrain
  useDateConstrain?: boolean
  containerStartDate?: Date
  containerEndDate?: Date
  itemsForContentBounds?: { startDate: Date; endDate: Date }[]
  canEditResourceRequest?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  updateGroupVisible: true,
  useDateConstrain: true,
  itemsForContentBounds: () => [],
})

const emit = defineEmits<{
  (event: 'highlight'): void
  (event: 'openNote'): void
  (event: 'jump'): void
  (event: 'updateColor', id: string): void
  (event: 'useRandomColor'): void
  (event: 'showDetails'): void
  (event: 'requestConsidered'): void
}>()

const colorLookupFallback = {
  alternative1: color['gray-500'],
  default: color['gray-400'],
  alternative2: color['gray-300'],
} as const

const colorLookup = {
  [STATUS.CONFIRMED]: {
    alternative1: color['status-confirmed--alternative1'],
    default: color['status-confirmed'],
    alternative2: color['status-confirmed--alternative2'],
  },

  [STATUS.REQUESTED]: {
    alternative1: color['status-requested--alternative1'],
    default: color['status-requested'],
    alternative2: color['status-requested--alternative2'],
  },

  [STATUS.CANCELLED]: {
    alternative1: color['status-cancelled--alternative1'],
    default: color['status-cancelled'],
    alternative2: color['status-cancelled--alternative2'],
  },

  [STATUS.CONSIDERED]: {
    alternative1: color['status-considered--alternative1'],
    default: color['status-considered'],
    alternative2: color['status-considered--alternative2'],
  },

  [STATUS.OFFERED]: {
    alternative1: color['status-offered--alternative1'],
    default: color['status-offered'],
    alternative2: color['status-offered--alternative2'],
  },

  [STATUS.SERVICE]: {
    alternative1: color['status-service--alternative1'],
    default: color['status-service'],
    alternative2: color['status-service--alternative2'],
  },

  [STATUS.SUB_HIRE]: {
    alternative1: color['status-subhire--alternative1'],
    default: color['status-subhire'],
    alternative2: color['status-subhire--alternative2'],
  },

  [STATUS.ITEM_TRANSFER]: {
    alternative1: color['status-itemtransfer--alternative1'],
    default: color['status-itemtransfer'],
    alternative2: color['status-itemtransfer--alternative2'],
  },
} as const

const elRoot = ref<HTMLElement>()

const store = useStore()

const {
  containerNumberOfDays,
  isConstrainedStart,
  isConstrainedEnd,
  constrainedStartDate,
  constrainedEndDate,
  // constrainedEnd,
  // endFinal,
  constrainedStartInDirection,
  constrainedEndInDirection,
  styles: stylesDateConstrain,
} = useDateConstrainComposable(props)

const jobStatusBla = useJobStatus({
  status: toRef(props, 'jobStatus'),
})

const projectStatusBla = useProjectStatus({
  status: toRef(props, 'projectStatus'),
})

const statusNormalized = computed(() => {
  if (props.type === 'project') {
    return props.projectStatus
  }

  if (props.type == 'job') {
    return props.jobStatus
  }

  return undefined
})

const statusColorNormalized = computed(() => {
  if (props.type === 'project') {
    return projectStatusBla.color.value
  }

  if (props.type == 'job') {
    return jobStatusBla.color.value
  }

  return undefined
})

const classes = computed(() => {
  let statusClasses = {}

  if (props.type === 'project') {
    statusClasses = projectStatusBla.classes.value ?? {}
  }

  if (props.type == 'job') {
    statusClasses = jobStatusBla.classes.value ?? {}
  }

  return {
    ...statusClasses,
    'is-highlighted': props.isHighlighted,
  }
})

const styles = computed<StyleValue>(() => {
  const stylesDateConstrainNormalized = props.disableDateConstrainStyles
    ? {}
    : stylesDateConstrain.value

  return {
    ...stylesDateConstrainNormalized,
    [`--${COMPONENT_NAME}-colorBorder--alternativeColor`]: colorBorder.value,
    [`--${COMPONENT_NAME}-colorBackground--alternativeColor`]:
      colorBackground.value?.toString() ?? undefined,
  }
})

const titleDragHandle = computed(() => {
  if (props.type === CALENDAR_ITEM_TYPE.JOB) {
    return 'Gruppe für Projekt festlegen'
  }

  return 'Gruppe festlegen'
})

const startDateFormatted = computed(() => {
  if (!props.startDate) return
  return formatWithLocale(props.startDate, FORMAT_DATETIME_SHORT)
})

const endDateFormatted = computed(() => {
  if (!props.endDate) return
  return formatWithLocale(props.endDate, FORMAT_DATETIME_SHORT)
})

const colorLookupForStatus = computed(() => {
  if (!statusNormalized.value) return colorLookupFallback

  const statusInLookup = Object.prototype.hasOwnProperty.call(
    colorLookup,
    statusNormalized.value
  )

  if (!statusInLookup) {
    return colorLookupFallback
  }

  return colorLookup[statusNormalized.value]
})

/**
 * Get random alternative color by seed, so it stays the same
 */
const randomAlternativeColor = computed(() => {
  const numberOfAlternativeColors = propTypes.alternativeColor.allowed.length
  const seededRandomNumber = Math.abs(seedrandom(props.id.toString()).int32())
  const index = seededRandomNumber % numberOfAlternativeColors

  return propTypes.alternativeColor.allowed[index]
})

const alternativeColorNormalized = computed(() => {
  if (props.alternativeColor)
    return {
      type: 'manual',
      id: props.alternativeColor,
    }

  return {
    type: 'random',
    id: randomAlternativeColor.value,
  }
})

const colorBase = computed(() => {
  if (!colorLookupForStatus.value) return

  const color = {
    type: alternativeColorNormalized.value.type,
    id: alternativeColorNormalized.value.id,
    value: colorLookupForStatus.value[alternativeColorNormalized.value.id],
  }

  /**
   * Use the default color value which, in case a selected alternative
   * color ist nod defined for the current state
   */
  if (!color.value) {
    color.value = colorLookupForStatus.value.default
  }

  return color
})

const colorBorder = computed(() => {
  if (!colorBase.value?.value) return

  return colorBase.value.value
})

const colorBackground = computed(() => {
  if (!colorBase.value?.value) return

  const color = ColorManipulation(colorBase.value.value)
  return color.alpha(0.2)
})

const colors = computed(() => {
  if (!colorLookupForStatus.value) return

  const colors = Object.entries(colorLookupForStatus.value).map(
    ([id, color]) => {
      return {
        id,
        value: color,
      }
    }
  )

  return colors
})

const isConstrainedStartMarkerVisible = computed(() => {
  if (isConstrainedEndMarkerForBoth.value) return false
  return isConstrainedStart.value
})

const isConstrainedEndMarkerForBoth = computed(() => {
  return (
    isConstrainedStart.value &&
    constrainedStartInDirection.value === 'right' &&
    isConstrainedEnd.value &&
    constrainedEndInDirection.value === 'right'
  )
})

const constrainedEndMarkerProps = computed(() => {
  if (isConstrainedEndMarkerForBoth.value) {
    return {
      title: `${startDateFormatted.value} — ${endDateFormatted.value}`,
      icon: ICONS.DOUBLE_ARROW_RIGHT,
    }
  }

  return {
    title: `Ende: ${endDateFormatted.value}`,
    icon: ICONS.ARROW_RIGHT,
  }
})

const contextNavigationItems = computed(() => {
  let entity: undefined | 'Jobs' | 'Projekts' = undefined

  if (props.type === CALENDAR_ITEM_TYPE.JOB) {
    entity = 'Jobs'
  }

  if (props.type === CALENDAR_ITEM_TYPE.PROJECT) {
    entity = 'Projekts'
  }

  const items: PropsContextNavigationPure['items'] = [
    {
      id: 'selectConsidered',
      icon: ICONS.DUPLICATE,
      label: 'Angedachte auswählen',
      note: `Alle angedachten Personen des ${entity} auswählen. Nicht Angezeigte werden ignoriert.`,
    },
    {
      id: 'requestConsidered',
      icon: ICONS.MAIL_QUESTION,
      label: `Alle Angedachte des gesamten ${entity} anfragen.`,
      note: `Nicht Angezeigte werden eingeschlossen.`,
    },
  ]

  return items
})

/**
 * Selection helper
 */
const selectionTrigger = ref(0)

function selectConsidered() {
  selectionTrigger.value += 1
}

provide(selectionProjectOrJobHelperKey, {
  selectionTrigger: selectionTrigger,
})

/**
 * Jump target
 */
const jumpTarget = useJumpTarget({
  id: props.id,
  type: 'AppointmentsContainer',
  el: elRoot,
  align: {
    horizontal: 'center',
    vertical: 'center',
  },
  registerOnMounted: false,
})

/**
 * Wait for packing to be finished before registering as jump target
 * This is only relevant for the initial mount
 * */
// eslint-disable-next-line @typescript-eslint/no-empty-function
let packingFinishedDebounced = () => {}

function onPackingFinished({ id }: { id: PackingContainerId }) {
  if (id !== PACKING_CONTAINER_ID.CALENDAR_JOBS) return
  packingFinishedDebounced()
}

async function registerWhenPacked() {
  const promiseTimeout = new Promise((resolve) =>
    setTimeout(resolve, 3 * 1000, 'Resolved by timeout')
  )

  const promiseWaitForPacking = new Promise((resolve) => {
    packingFinishedDebounced = debounce(() => {
      EventHub.$off(EVENT.PACKING_FINISHED, onPackingFinished)
      resolve('Resolved by packing finished')
    }, 100)

    EventHub.$on(EVENT.PACKING_FINISHED, onPackingFinished)
  })

  await Promise.race([promiseTimeout, promiseWaitForPacking])
  await new Promise((resolve) => setTimeout(resolve, 50)) // Wait for a little bit more

  jumpTarget.register()
}

onBeforeUnmount(() => {
  EventHub.$off(EVENT.PACKING_FINISHED, onPackingFinished)
})

registerWhenPacked()
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmAppointmentsContainerPure {
  $block: &;
  $name: function.getBlockName($block);

  @include cssVar.define(
    $block,
    'colorBorder',
    cssVar.use($block, 'colorBorder--alternativeColor', color.$gray-400)
  );
  @include cssVar.define(
    $block,
    'colorBackground',
    cssVar.use(
      $block,
      'colorBackground--alternativeColor',
      #{rgba(color.$gray-400, 0.2)}
    )
  );
  @include mixin.transition-hover((border-color, box-shadow));

  box-shadow: inset 0 0 0 3px cssVar.use($block, 'colorBorder');
  border-top: (10px - 3px) solid cssVar.use($block, 'colorBorder');
  background-color: cssVar.use($block, 'colorBackground');
  padding: 3px + 10px;
  display: flex;
  flex-direction: column;

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .App:not(.is-dragAndDropInProgress) &:hover,
  .App:not(.is-dragAndDropInProgress) &.is-hover {
    @include cssVar.define($block, 'colorBorder', #{color.$key});

    z-index: 1;
  }

  &.is-highlighted {
    @include cssVar.define($block, 'colorBorder', #{color.$key});

    z-index: 1;
  }

  &-header {
    display: flex;
    gap: 4px;
  }

  &-constrainedMarker {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    padding: 4px;
    position: relative;

    &::after {
      content: '';
      inset: 0;
      position: absolute;
      border-radius: constant.$borderRadius-default + 2px;
      box-shadow: inset 0 0 0 2px color.$key;
      opacity: 0;
      transition: opacity constant.$duration-hoverOut;
    }

    &:hover {
      &::after {
        opacity: 1;
        transition-duration: constant.$duration-hoverIn;
      }
    }

    &--start {
      margin-left: -5px;
    }

    &--end {
      margin-right: -5px;
    }
  }

  &-headerDragAndDrop {
    width: 100%;
    display: flex;
    min-width: 0;
  }

  &-headerInner {
    position: sticky;
    left: calc(var(--sidebarLeftWidthWithVisibility) + 8px);
    min-width: 0;
  }

  &-headerContent {
    position: relative;

    &::before {
      @include shadow.drag($offset: -12px);

      border: 1px solid color.$gray-300--alpha;
      border-radius: constant.$borderRadius-default;
      pointer-events: none;
      opacity: 0;
      background-color: color.$white;
      z-index: 0;
    }

    &.is-dragging {
      &::before {
        opacity: 1;
      }
    }
  }

  &-headerContentInner {
    position: relative;
  }

  &-titleContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &-title {
    @include mixin.truncateText;

    font-size: constant.$fontSize-large;
    hyphens: auto;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &-subtitle {
    @include mixin.truncateText;

    hyphens: auto;
  }

  &-meta {
    display: flex;
    align-self: start;
    align-items: flex-start;
    margin-bottom: 5px;
    width: 100%;
    gap: 4px;
  }

  &-icon {
    width: 20px;
    height: 20px;
  }

  &-action {
    @include mixin.transition-hover;

    &:hover {
      color: color.$key;
    }
  }

  &-note {
    flex-shrink: 0;
  }

  &-vehicles {
    font-size: constant.$fontSize-default;
    opacity: 0.5;
    margin-left: -10px;
    margin-right: -10px;
    display: grid;
    grid-auto-columns: var(--dayWidth);
  }

  &-appointments {
    margin-top: 10px;
    margin-left: -13px;
    margin-right: -13px;
    margin-bottom: -13px + 3px;
    position: relative;
  }
}
</style>
