<template>
  <PmSidebarSectionPure
    class="PmSidebarGeneralEvents"
    title="Allgemeine Termine"
    :can-be-collapsed="true"
    :collapsed="collapsableArea.isCollapsed.value"
    @expand="collapsableArea.expand"
    @collapse="collapsableArea.collapse"
  >
    <div class="PmSidebarGeneralEvents-spacer"></div>
  </PmSidebarSectionPure>
</template>

<script>
import { useCollapsableArea } from '@/composition/useCollapsableArea'

import PmSidebarSectionPure from '@/components/persoplan/PmSidebarSection/PmSidebarSectionPure.vue'

export default {
  name: 'PmSidebarGeneralEvents',
  components: {
    PmSidebarSectionPure,
  },

  setup() {
    const collapsableArea = useCollapsableArea({ id: 'generalEvents' })
    return {
      collapsableArea,
    }
  },
}
</script>

<style lang="scss">
.PmSidebarGeneralEvents {
  $block: &;

  &-spacer {
    min-height: calc(var(--generalEventsHeight) - 41px);
  }
}
</style>
