<template>
  <PmGeneralPageContainerPure>
    <PmGeneralPagePure
      v-if="xstate.state.value.matches('overview')"
      title="Mein Konto"
    >
      <PmGeneralPageSectionPure title="Passwort">
        <PmButtonListPure>
          <PmButtonPure
            label="Passwort ändern"
            @click="xstate.service.value.send('CHANGE_PASSWORD')"
          />
        </PmButtonListPure>
      </PmGeneralPageSectionPure>

      <PmGeneralPageSectionPure title="Persoplan Standard-Ansicht">
        <PmDefaultViews />
      </PmGeneralPageSectionPure>
    </PmGeneralPagePure>

    <PmGeneralPagePure
      v-if="xstate.state.value.matches('changePassword')"
      title="Passwort ändern"
      :is-back-visible="true"
      @back="xstate.service.value.send('BACK')"
    >
      <PmGeneralPageSectionPure>
        <PmChangePassword />
      </PmGeneralPageSectionPure>
    </PmGeneralPagePure>
  </PmGeneralPageContainerPure>
</template>

<script>
import { useXState } from '@/composition/useXState'

import { PmAccountState } from '@/views/PmAccount/PmAccountState'

import PmGeneralPageContainerPure from '@/components/PmGeneralPage/PmGeneralPageContainerPure.vue'
import PmGeneralPagePure from '@/components/PmGeneralPage/PmGeneralPagePure.vue'
import PmGeneralPageSectionPure from '@/components/PmGeneralPage/PmGeneralPageSectionPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmChangePassword from '@/components/PmChangePassword/PmChangePassword.vue'
import PmDefaultViews from '@/components/PmDefaultViews/PmDefaultViews.vue'

export default {
  name: 'PmAccount',
  components: {
    PmGeneralPageContainerPure,
    PmGeneralPagePure,
    PmGeneralPageSectionPure,
    PmButtonListPure,
    PmButtonPure,
    PmChangePassword,
    PmDefaultViews,
  },

  setup() {
    const xstate = useXState(PmAccountState)

    return {
      xstate,
    }
  },
}
</script>
