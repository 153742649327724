import type { Ref, ComputedRef } from 'vue'
import { computed, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { ApolloCache } from '@apollo/client/cache'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es'

import {
  ADDRESS_RESOURCE_TYPE_ID_LOOKUP,
  ADDRESS_RESOURCE_TYPE,
} from '@/constants/persoplan'

import { throwFriendlyError } from '@/functional/error'
import { parseServerDateString } from '@/utilities/date'

import {
  DeleteConnectionToVehicleDocument,
  UpdateResourceAllocationAddressDocument,
  UpdateConnectionToVehicleDocument,
  type ResourceAllocationQuery,
  VehicleAllocationDocument,
  CreateResourceAllocationAddressDocument,
  AddressDocument,
  type CreateResourceAllocationAddressMutation,
  ResourceDocument,
} from '@/../generated/graphql'

import type {
  FormData,
  Props as PropsResourceAllocationEditPure,
} from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationEditPure.vue'

interface Options {
  formData: Ref<FormData>
  resourceAllocation: ComputedRef<ResourceAllocationQuery['resourceAllocation']>
  resourceId: Ref<number | undefined>
  addressId: Ref<number | undefined>
}

export function useResourceAllocationEditAddress({
  formData,
  resourceAllocation,
  resourceId,
  addressId,
}: Options) {
  const store = useStore()
  const newVehicleAllocationId = ref<number | null>()

  /**
   * Create
   */
  async function create() {
    const result = await createResourceAllocationAddress()

    if (newVehicleAllocationId.value) {
      await updateConnectionToVehicle({
        driverAllocationId: result?.resourceAllocationId,
      })
    }
  }

  const createResourceAllocationAddressMutation = useMutation(
    CreateResourceAllocationAddressDocument
  )

  const createResourceAllocationAddress = async () => {
    if (!resourceId.value) throw new Error('resourceId is undefined')
    if (!addressId.value) throw new Error('addressId is undefined')
    if (!formData.value.statusId) throw new Error('statusId is undefined')

    if (typeof formData.value.statusId !== 'number') {
      throw new Error('statusId is not a number')
    }

    try {
      const result = await createResourceAllocationAddressMutation.mutate(
        {
          resourceId: resourceId.value,
          addressId: addressId.value,
          resourceAllocationStateId: formData.value.statusId,
          notice: formData.value.note,
          travel: formData.value.travel,
          hotel: formData.value.hotel,
        },
        {
          update: (cache, result) => {
            const createdAllocation = result?.data?.createAddressAllocation
            if (!createdAllocation) return
            updateCacheAfterCreateResourceAllocation(cache, createdAllocation)
          },
        }
      )

      const resourceAllocationId = result?.data?.createAddressAllocation?.id

      return {
        resourceAllocationId,
      }
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  function updateCacheAfterCreateResourceAllocation(
    cache: ApolloCache<any>,
    result: CreateResourceAllocationAddressMutation['createAddressAllocation']
  ) {
    const queryVariables = {
      ...store.getters['queryVariables/calendar'],
      id: resourceId.value,
    }

    const readQueryResult = cache.readQuery({
      query: ResourceDocument,
      variables: queryVariables,
    })

    // readQuery is readonly, thus we need to create a deep copy
    const data = cloneDeep(readQueryResult)

    if (!data?.resource?.resourceAllocations) return

    // TODO: Fix this with better typing
    // @ts-expect-error This is wrongly types, but to lazy to fix
    data.resource.resourceAllocations.push(result)

    cache.writeQuery({
      query: ResourceDocument,
      variables: queryVariables,
      data,
    })
  }

  /**
   * Update
   */
  async function update() {
    await updateResourceAllocationAddress()

    if (newVehicleAllocationId.value === null) {
      await deleteConnectionToVehicle()
    }

    if (newVehicleAllocationId.value) {
      await updateConnectionToVehicle({
        driverAllocationId: resourceAllocation.value?.id,
      })
    }
  }

  /**
   * Update Resource Allocation
   */
  const updateResourceAllocationAddressMutation = useMutation(
    UpdateResourceAllocationAddressDocument
  )

  async function updateResourceAllocationAddress() {
    if (!resourceAllocation.value?.address?.id) {
      throw new Error('resourceAllocation.address.id is undefined')
    }

    if (!formData.value.statusId) throw new Error('statusId is undefined')

    if (typeof formData.value.statusId !== 'number') {
      throw new Error('statusId is not a number')
    }

    try {
      await updateResourceAllocationAddressMutation.mutate({
        resourceAllocationId: resourceAllocation.value.id,
        addressId: resourceAllocation.value.address.id,
        stateId: formData.value.statusId,
        notice: formData.value.note,
        travel: formData.value.travel,
        hotel: formData.value.hotel,
      })
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  /**
   * Update connection to vehicle
   */
  const updateConnectionToVehicleMutation = useMutation(
    UpdateConnectionToVehicleDocument
  )

  async function updateConnectionToVehicle({
    driverAllocationId,
  }: {
    driverAllocationId?: number
  }) {
    if (!newVehicleAllocationId.value)
      throw new Error('newVehicleAllocationId is undefined')

    if (!driverAllocationId) throw new Error('driverAllocationId is undefined')

    try {
      await updateConnectionToVehicleMutation.mutate({
        vehicleAllocationId: newVehicleAllocationId.value,
        driverAllocationId,
      })
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  /**
   * Delete connection to vehicle
   */
  const deleteConnectionToVehicleMutation = useMutation(
    DeleteConnectionToVehicleDocument
  )

  async function deleteConnectionToVehicle() {
    if (!resourceAllocation.value?.id)
      throw new Error('resourceAllocation is undefined')

    try {
      await deleteConnectionToVehicleMutation.mutate({
        driverAllocationId: resourceAllocation.value.id,
      })
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  const vehicleAllocationQuery = useQuery(
    VehicleAllocationDocument,
    // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
    () => {
      return {
        id: newVehicleAllocationId.value,
      }
    },
    () => ({
      enabled: newVehicleAllocationId.value ? true : false,
    })
  )

  const vehicleAllocation = computed(
    () => vehicleAllocationQuery.result.value?.vehicleAllocation
  )

  const vehicleLoading = computed(() => {
    return vehicleAllocationQuery.loading.value
  })

  const vehicleNormalized = computed(() => {
    // Allocated vehicle was unlinked
    if (newVehicleAllocationId.value === null) return undefined

    // New unsaved vehicle was selected
    if (unsavedVehicleNormalized.value) return unsavedVehicleNormalized.value

    // Already saved vehicle is present
    if (allocatedVehicleNormalized.value)
      return allocatedVehicleNormalized.value

    return undefined
  })

  const allocatedVehicleNormalized = computed(() => {
    if (!resourceAllocation.value?.resourceAllocationDriver?.vehicle) return

    const name =
      resourceAllocation.value.resourceAllocationDriver.vehicle.caption

    const number =
      resourceAllocation.value.resourceAllocationDriver
        .resourceFunctionAllocation?.job?.number

    return `${name}@${number}`
  })

  const unsavedVehicleNormalized = computed(() => {
    if (!vehicleAllocation.value?.vehicle) return

    const name = vehicleAllocation.value.vehicle.caption
    const number =
      vehicleAllocation.value.resourceFunctionAllocation?.job?.number

    return `${name}@${number}`
  })

  const addressIdNormalized = computed(() => {
    return addressId.value || resourceAllocation.value?.address?.id
  })

  const addressQuery = useQuery(
    AddressDocument,
    // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
    () => {
      return {
        id: addressIdNormalized.value,
      }
    },
    () => ({
      enabled: addressIdNormalized.value ? true : false,
    })
  )

  const address = computed(() => {
    return addressQuery.result.value?.addresses?.[0]
  })

  const label = computed(() => {
    if (!address.value) return
    return address.value.displayName as string
  })

  const isStaffAgency = computed(() => {
    if (!address.value?.addressResourceType?.id) return undefined

    return (
      address.value.addressResourceType.id ===
      ADDRESS_RESOURCE_TYPE_ID_LOOKUP[ADDRESS_RESOURCE_TYPE.STAFF_AGENCY]
    )
  })

  const isFreelancer = computed(() => {
    if (!address.value?.addressResourceType?.id) return undefined

    return (
      address.value.addressResourceType.id ===
      ADDRESS_RESOURCE_TYPE_ID_LOOKUP[ADDRESS_RESOURCE_TYPE.FREELANCER]
    )
  })

  const isConflictsVisible = computed(() => {
    if (isStaffAgency.value) return false

    return true
  })

  const resourceRequest = computed(() => {
    if (!resourceAllocation.value?.resourceRequest) return

    const resourceRequest = resourceAllocation.value.resourceRequest

    const result: PropsResourceAllocationEditPure['resourceRequest'] = {
      status: resourceRequest.state,
      statusFeedback: resourceRequest.feedback?.userState,
      availableStartDate: parseServerDateString(
        resourceRequest?.feedback?.availableFrom
      ),
      availableEndDate: parseServerDateString(
        resourceRequest?.feedback?.availableTo
      ),
      nameOfRequestedPerson: resourceAllocation.value.address?.displayName,
    }

    return result
  })

  return {
    update,
    create,
    vehicleNormalized,
    vehicleLoading,
    newVehicleAllocationId,
    label,
    isConflictsVisible,
    isStaffAgency,
    isFreelancer,
    resourceRequest,
  }
}
