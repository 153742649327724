import { default as mimeMatch } from 'mime-match'
import mime from 'mime/lite'
import prettyBytes from 'pretty-bytes'
import { round } from 'lodash-es'
import type { MimeType } from '@/types/mimeTypes'

/**
 * Check if a mimeType is an accepted file type
 *
 * @see https://github.com/transloadit/uppy/blob/main/packages/%40uppy/core/src/Restricter.ts
 */
export function checkIfAllowedFileType(options: {
  mimeType: string
  allowedFileTypes?: MimeType[]
}) {
  if (!options.allowedFileTypes) return true
  if (options.allowedFileTypes.length === 0) return true

  const isAllowedFileType = options.allowedFileTypes.some((fileType) => {
    const checkAgainstMimeType = fileType.includes('/')

    if (checkAgainstMimeType) {
      return mimeMatch(options.mimeType, fileType)
    }

    if (!checkAgainstMimeType) {
      if (!fileType.startsWith('.')) {
        throw new Error(`fileType "${fileType}" does not start with .`)
      }

      const mimeTypeOfFileType = mime.getType(fileType)
      if (!mimeTypeOfFileType) {
        throw new Error(`mimeType of ${fileType} could not be extracted`)
      }

      return mimeMatch(options.mimeType, mimeTypeOfFileType)
    }
  })

  return isAllowedFileType
}

export function convertSizeToMegaBytes(
  /** The file size in bytes */
  size: number
) {
  const megaBytes = size / (1000 * 1000)
  return round(megaBytes, 2)
}

export function convertSizeToBytes(
  /** The file size in mega bytes */
  size: number
) {
  const megaBytes = size * (1000 * 1000)
  return round(megaBytes, 2)
}

export function formatFileSizeToHumanReadable(
  /** The file size in bytes */
  size: number
) {
  return prettyBytes(size, { locale: 'de' })
}
