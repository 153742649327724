<template>
  <PmViewUpdatePure
    :loading="xstate.state.value.matches('update.updating')"
    :error="xstate.state.value.matches('update.failed')"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :view-name="viewTitle"
    :user-can-create-public-view="can('create', 'PublicViews')"
    :is-public-view="isPublicView"
    :existing-view-names="existingViewNames"
    @update="
      ({ name, isPublic }) =>
        xstate.service.value.send('UPDATE', { name, isPublic })
    "
    @close="emit('close')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmViewUpdate'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { ICONS } from '@/constants/icons'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { PmViewUpdateState } from '@/components/persoplan/PmViewUpdate/PmViewUpdateState'
import { useAppAbility } from '@/composition/useAppAbility'

import PmViewUpdatePure from '@/components/persoplan/PmViewUpdate/PmViewUpdatePure.vue'

import {
  ViewsDocument,
  CurrentUserDocument,
  UpdateViewDocument,
} from '@/../generated/graphql'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const xstate = useXState(PmViewUpdateState, {
  services: {
    updateView: (context, { name, isPublic }) => updateView({ name, isPublic }),
  },
})

const store = useStore()
const { can } = useAppAbility()

const viewsQuery = useQuery(ViewsDocument)
const views = computed(() => viewsQuery.result.value?.views)

const userQuery = useQuery(CurrentUserDocument)
const user = computed(() => userQuery.result.value?.user)

const viewTitle = computed(() => {
  return store.state.view.title
})

const isPublicView = computed(() => {
  if (!views.value) return

  const currentView = views.value.find((view) => {
    return view?.id === store.state.view.id
  })

  if (!currentView) {
    return false
  }

  return currentView.public === true ? true : false
})

const existingViewNames = computed(() => {
  if (!views.value) return

  const viewNames: string[] = []

  views.value.forEach((view) => {
    if (!view) return
    viewNames.push(view.title)
  })

  return viewNames
})

const updateViewMutation = useMutation(UpdateViewDocument)

async function updateView({
  name,
  isPublic,
}: {
  name: string
  isPublic: boolean
}) {
  if (!store.state.view.id) {
    throw new Error('no view id in store')
  }

  try {
    const query = JSON.stringify(store.state.view.currentView)

    await updateViewMutation.mutate(
      {
        id: store.state.view.id,
        title: name,
        public: isPublic,
        query: query,
      },
      {
        update: (_, result) => {
          const view = result.data?.updatePersoPlanView
          if (!view) return

          store.commit('view/setView', {
            id: view.id,
            title: view.title,
            query: view.query,
          })
        },
      }
    )
  } catch (error) {
    throwFriendlyError(error)
  }

  store.commit('notification/add', {
    variant: 'success',
    icon: ICONS.CHECK,
    title: 'Ansicht gespeichert',
  })

  emit('close')
}
</script>
