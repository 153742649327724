<template>
  <div class="PmButtonListPure" :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, toRef, provide, type Ref } from 'vue'

import { buttonListPureKey } from '@/utilities/inject'

export interface Props {
  layout?: 'distribute'
  align?: 'left' | 'right' | 'center'
  fullWidthOnMobile?: boolean
}

export interface Provide {
  align: Ref<Props['align']>
  layout: Ref<Props['layout']>
  fullWidthOnMobile: Ref<Props['fullWidthOnMobile']>
}

const props = withDefaults(defineProps<Props>(), {
  layout: undefined,
  align: 'left',
})

const COMPONENT_NAME = 'PmButtonListPure'

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--layoutDistribute`]: props.layout === 'distribute',
    [`${COMPONENT_NAME}--alignLeft`]: props.align === 'left',
    [`${COMPONENT_NAME}--alignRight`]: props.align === 'right',
    [`${COMPONENT_NAME}--alignCenter`]: props.align === 'center',
    [`${COMPONENT_NAME}--fullWidthOnMobile`]: props.fullWidthOnMobile === true,
  }
})

provide(buttonListPureKey, {
  align: toRef(props, 'align'),
  layout: toRef(props, 'layout'),
  fullWidthOnMobile: toRef(props, 'fullWidthOnMobile'),
})

defineExpose({
  // fullWidthOnMobile: props.fullWidthOnMobile,
})
</script>

<style lang="scss">
.PmButtonListPure {
  $block: &;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &--alignRight {
    justify-content: flex-end;
  }

  &--alignCenter {
    justify-content: center;
  }

  &--layoutDistribute {
    justify-content: space-between;
  }
}
</style>
