<template>
  <div
    ref="elRoot"
    class="PmDropdownPure"
    :class="[$attrs.class, classes.root]"
  >
    <div v-if="label" class="PmDropdownPure-label">{{ label }}</div>

    <div v-show="invalidMessage" class="PmDropdownPure-error">
      {{ invalidMessage }}
    </div>

    <div v-show="errorMessage" class="PmDropdownPure-error">
      {{ errorMessage }}
    </div>

    <PmOnClickOutsidePure :do="close" :disabled="!isOpen">
      <div ref="elInner" class="PmDropdownPure-inner">
        <select
          ref="elSelect"
          class="PmDropdownPure-select"
          v-bind="separateAttrs($attrs).attributes"
          :multiple="multiple"
          :required="required"
          tabindex="-1"
        >
          <option value="">{{ placeholder }}</option>
          <option
            v-for="option in options"
            :key="option.id"
            :value="option.id"
            :selected="isSelectOptionSelected(option)"
          >
            {{ option.label }}
          </option>
        </select>

        <div
          ref="elHeader"
          class="PmDropdownPure-header"
          tabindex="0"
          :title="title ?? ''"
          @click="toggle"
          @keydown.enter="open"
        >
          <div class="PmDropdownPure-selection">
            <div v-if="!multiple" class="PmDropdownPure-selectedLabelContainer">
              <div v-if="selectedOption" class="PmDropdownPure-selectedLabel">
                <PmPillPure
                  v-if="selectedOption.number"
                  class="PmDropdownPure-selectedLabelPill"
                  :label="selectedOption.number"
                />

                <div class="PmDropdownPure-selectedLabelText">
                  {{ selectedOption.label }}
                </div>

                <div
                  v-if="selectedOption.icon"
                  class="PmDropdownPure-selectedLabelIcon"
                  :title="selectedOption.iconTitle"
                >
                  <PmIconPure
                    :key="selectedOption.icon"
                    :name="selectedOption.icon"
                    size="small"
                  />
                </div>
              </div>

              <div
                v-if="!selectedOptions.length"
                class="PmDropdownPure-placeholder"
              >
                {{ placeholder }}
              </div>
            </div>

            <template v-if="multiple">
              <div
                v-if="!selectedOptions.length"
                class="PmDropdownPure-selectedLabelContainer"
              >
                <div class="PmDropdownPure-placeholder">
                  {{ placeholder }}
                </div>
              </div>

              <div v-if="selectedOptions.length" class="PmDropdownPure-tagList">
                <PmPillPure
                  v-for="option in selectedOptions"
                  :key="option.id"
                  :icon="ICONS_SMALL.CLOSE"
                  class="PmDropdownPure-tag"
                  :is-interactive="true"
                  :is-inactive="option.active === false"
                  :loading="option.loading"
                  tabindex="0"
                  :disabled="disabled"
                  :label="getTagPillLabel(option)"
                  @click-on-icon="removeOption(option)"
                  @click.stop
                  @keydown.delete="removeOption(option)"
                />
              </div>
            </template>
          </div>

          <div class="PmDropdownPure-iconContainer">
            <PmLoadingPure v-if="isLoading" class="PmDropdownPure-loading" />

            <PmIconPure
              v-if="!isLoading"
              :name="ICONS.ARROW_DOWN"
              class="PmDropdownPure-toggleIcon"
            />
          </div>

          <div class="PmDropdownPure-actions">
            <PmButtonPure
              v-if="resetActionVisible"
              class="PmDropdownPure-action"
              :icon="ICONS.CLOSE"
              variant="danger"
              size="small"
              @click.stop="reset"
            />
          </div>
        </div>

        <div
          v-if="isOpen"
          ref="elDropdown"
          class="PmDropdownPure-dropdown"
          :style="stylesDropdown"
        >
          <div class="PmDropdownPure-searchContainer">
            <input
              ref="elSearch"
              v-model="searchterm"
              type="text"
              class="PmDropdownPure-searchInput"
              placeholder="Suchen…"
              @input="emitInputSearchterm"
              @keydown.up.prevent="highlightPrevious"
              @keydown.down.prevent="highlightNext"
              @keydown.enter.stop.prevent="confirmHighlighted"
              @keydown.esc.stop="close"
              @keydown.tab.prevent
            />

            <PmIconPure
              :name="ICONS.SEARCH"
              class="PmDropdownPure-searchIcon"
            />
          </div>

          <ul class="PmDropdownPure-list">
            <li
              v-for="(option, index) in optionsFiltered"
              :key="option.id"
              ref="elOptions"
              class="PmDropdownPure-option"
              :class="{
                'is-highlighted': highlightedIndex === index,
                'is-inactive': option.active === false,
                'is-selected': normalizedValue.includes(option.id),
              }"
              @click="toggleOption(option)"
            >
              <PmPillPure
                v-if="option.number"
                class="PmDropdownPure-optionPill"
                :label="option.number"
              />

              {{ option.label }}

              <div
                v-if="normalizedValue.includes(option.id)"
                class="PmDropdownPure-optionSelectedIcon"
              >
                <PmIconPure name="check" />
              </div>

              <div
                v-if="option.icon"
                class="PmDropdownPure-optionIcon"
                :title="option.iconTitle"
              >
                <PmIconPure
                  :key="option.icon"
                  :name="option.icon"
                  size="small"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </PmOnClickOutsidePure>

    <div v-if="note" class="PmDropdownPure-note">{{ note }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmDropdownPure'

export const propTypes = {
  pillPosition: {
    allowed: ['left', 'right'],
    default: 'left',
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
  inheritAttrs: false,
})
</script>

<script setup lang="ts">
import { ref, computed, watch, onBeforeUnmount, nextTick, onMounted } from 'vue'
// import createFocusTrap from 'focus-trap'
import Fuse from 'fuse.js'
import { isNil, uniq } from 'lodash-es'

import {
  computePosition,
  autoUpdate,
  offset as offsetMiddleware,
  flip as flipMiddleware,
} from '@floating-ui/dom'

import { ICONS, ICONS_SMALL, type Icon } from '@/constants/icons'

import { separateAttrs } from '@/utilities/misc'
import { whatInputStateKey, injectStrict } from '@/utilities/inject'
import { normalizeSearchInput } from '@/utilities/persoplan'

import PmOnClickOutsidePure from '@/components/utilities/PmOnClickOutsidePure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  value?: number | string | any[]
  label?: string
  options: Option[]
  isLoading?: boolean // TODO: should be 'loading' or be streamlined whatever
  placeholder?: string
  multiple?: boolean
  note?: string
  error?: boolean
  errorMessage?: string
  hasReset?: boolean
  required?: boolean
  disabled?: boolean
  pillPosition: (typeof propTypes.pillPosition.allowed)[number]
}

export type Option = {
  number?: string
  id: string | number
  label?: string
  icon?: Icon
  iconTitle?: string
  active?: boolean
  loading?: boolean
  loadingblupp?: boolean
  hidden?: boolean
}

export interface Emit {
  input: Props['value']
  ['update:value']: Props['value']
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  placeholder: 'Bitte auswählen',
  pillPosition: propTypes.pillPosition.default,
})

const emit = defineEmits<{
  (event: 'input', value: Emit['input']): void
  (event: 'update:value', value: Emit['update:value']): void
  (event: 'close'): void
  (event: 'inputSearchterm', value: string): void
}>()

const isOpen = ref(false)
const highlightedIndex = ref(0)
const searchterm = ref<string | undefined>()
// const focusTrap = ref()
let fuse: Fuse<Option> | undefined = undefined
const isInvalid = ref<boolean>()
const invalidMessage = ref<string>()

const STRATEGY = 'absolute' as const

const whatInput = injectStrict(whatInputStateKey)
const elInner = ref()
const elDropdown = ref()
const dropdownPosition = ref({ x: 0, y: 0 })
const dropdownPlacement = ref()
const isFocus = ref(null)
let floatingUi: { destroy: () => void } | undefined | null
const elSelect = ref<HTMLSelectElement>()
const elSearch = ref<HTMLInputElement>()
const elRoot = ref<HTMLElement>()
const elHeader = ref<HTMLElement>()
const elOptions = ref<HTMLElement[]>()

const stylesDropdown = computed(() => {
  return {
    left: `${dropdownPosition.value.x}px`,
    top: `${dropdownPosition.value.y}px`,
    // transform: `translate(${dropdownPosition.value.x}px, ${dropdownPosition.value.y}px)`,
    position: STRATEGY,
  }
})

const placeDropdown = async () => {
  if (!elInner.value) return
  if (!elDropdown.value) return

  const { x, y, placement } = await computePosition(
    elInner.value,
    elDropdown.value,
    {
      strategy: STRATEGY,
      placement: 'bottom',
      middleware: [
        offsetMiddleware(),
        flipMiddleware(),

        // shiftMiddleware({
        //   padding: paddingOutside.value,
        // }),

        // sizeMiddleware({
        //   apply({ availableWidth, availableHeight }) {
        //     popoverMaxDimensions.value.width = availableWidth
        //     popoverMaxDimensions.value.height = availableHeight
        //   },
        //   padding: paddingOutside.value,
        // }),

        // arrowMiddleware({
        //   element: elArrow.value,
        //   padding: 12,
        // }),
      ],
    }
  )

  dropdownPosition.value.x = x
  dropdownPosition.value.y = y
  dropdownPlacement.value = placement
}

const initFloatingUi = () => {
  if (floatingUi) throw new Error('floatingUi is already initialized!')

  const destroy = autoUpdate(elInner.value, elDropdown.value, placeDropdown)

  floatingUi = {
    destroy,
  }
}

const destroyFloatingUi = () => {
  if (!floatingUi?.destroy) return
  floatingUi.destroy()
  floatingUi = null
}

const classes = computed(() => {
  return {
    root: {
      [`${COMPONENT_NAME}--multiple`]: props.multiple,
      [`${COMPONENT_NAME}--placementTop`]: dropdownPlacement.value === 'top',
      [`${COMPONENT_NAME}--placementBottom`]:
        dropdownPlacement.value === 'bottom',
      [`${COMPONENT_NAME}--pillPositionLeft`]: props.pillPosition === 'left',
      [`${COMPONENT_NAME}--pillPositionRight`]: props.pillPosition === 'right',
      'is-open': isOpen.value,
      'has-selection': normalizedValue.value.length,
      'has-error': props.error || isInvalid.value,
      'is-required': props.required,
      'is-disabled': props.disabled,
      'is-selectedOptionWithIcon': selectedOptionHasIcon.value === true,
      'is-focus': isFocus.value,
    },
  }
})

const title = computed(() => {
  // Single
  if (!props.multiple) {
    if (!selectedOptions.value.length) return

    // With number
    if (selectedOptions.value[0].number) {
      return `${selectedOptions.value[0].number} ${selectedOption.value?.label}`
    }

    return selectedOption.value?.label
  }

  return null
})

const normalizedValue = computed(() => {
  // No Value
  if (isNil(props.value)) return []

  // Value is no Array -> convert
  if (!Array.isArray(props.value)) return [props.value]

  // If it's an array make it unique
  return uniq(props.value)
})

const selectedOptions = computed(() => {
  // TODO: Respect selection order

  return normalizedValue.value.map((id) => {
    const option = props.options.find((option) => option.id === id)
    if (option) {
      return option
    } else {
      const fallback: Option = {
        id: id,
        loading: true,
      }

      return fallback
    }
  })

  // return props.options.filter(option =>
  //   normalizedValue.value.includes(option.id)
  // )
})

const selectedOption = computed(() => {
  if (selectedOptions.value.length === 0) return undefined
  return selectedOptions.value[0]
})

const selectedOptionHasIcon = computed(() => {
  return selectedOption.value?.icon ? true : false
})

const highlightedOption = computed(() => {
  return optionsFiltered.value[highlightedIndex.value]
})

const optionsFiltered = computed(() => {
  let optionsFiltered = props.options

  optionsFiltered = applySearch(optionsFiltered)
  // optionsFiltered = removeSelected(optionsFiltered)
  optionsFiltered = removeHidden(optionsFiltered)

  return optionsFiltered
})

const resetActionVisible = computed(() => {
  if (!props.hasReset) return false

  if (Array.isArray(props.value)) {
    return props.value.length > 0
  }

  return props.value ? true : false
})

watch(searchterm, (newValue) => {
  highlightedIndex.value = -1
  searchterm.value = normalizeSearchInput(newValue)
})

watch(
  () => props.options,
  () => {
    if (!fuse) return
    fuse.setCollection(props.options)
  }
)

watch(
  () => props.value,
  async () => {
    await nextTick() // Wait for nextTick in case this happens right away

    // We need to fire the input event on the original select manually when there was a change:
    if (!elSelect.value) throw new Error('elSelect is undefined')
    elSelect.value.dispatchEvent(new CustomEvent('input'))
  }
)

watch(isOpen, async () => {
  if (isOpen.value) {
    await nextTick()
    initFloatingUi()
  } else {
    destroyFloatingUi()
  }
})

watch(
  () => props.disabled,
  () => {
    if (props.disabled) close()
  }
)

onMounted(() => {
  fuse = new Fuse(props.options, {
    keys: ['label', 'number'],
    threshold: 0.4, // https://fusejs.io/api/options.html#threshold
    // includeMatches: true // TODO: Use matches to highlight searchresults
  })

  if (!elSelect.value) return
  elSelect.value.addEventListener('invalid', onInvalid)
  elSelect.value.addEventListener('input', onInput)
})

onBeforeUnmount(() => {
  if (!elSelect.value) return
  elSelect.value.removeEventListener('invalid', onInvalid)
  elSelect.value.removeEventListener('input', onInput)
})

// TODO: This should have a better solution, maybe refactor more of this components elements to own components?
function getTagPillLabel(option: Option) {
  if (option.number) {
    return `${option.number} ${option.label}`
  }

  return option.label
}

function toggle() {
  isOpen.value ? close() : open()
}

async function open() {
  isOpen.value = true
  highlightedIndex.value = -1

  // TODO: Mark commited options differently
  // if (selectedOption.value) {
  //   highlightedIndex.value = props.options.findIndex(
  //     option => option.id === props.value
  //   )
  // }

  await nextTick()
  focusSearchInput()

  // focusTrap = createFocusTrap(this.$refs.dropdown)
  // focusTrap.activate()
}

function focusSearchInput() {
  if (whatInput.isTouch) return
  if (!elSearch.value) throw new Error('elSearch is undefined')
  elSearch.value.focus()
}

function isFocusOnOrInside() {
  if (!elRoot.value) throw new Error('elRoot is undefined')

  if (document.activeElement === elRoot.value) return true
  if (elRoot.value.contains(document.activeElement)) return true

  return false
}

function close() {
  if (!isOpen.value) return

  isOpen.value = false
  searchterm.value = undefined
  highlightedIndex.value = -1

  if (isFocusOnOrInside()) {
    if (!elHeader.value) throw new Error('elHeader is undefined')
    elHeader.value.focus()
  }

  // focusTrap.deactivate()

  emit('close')
}

function highlightPrevious() {
  highlightedIndex.value = highlightedIndex.value - 1

  if (highlightedIndex.value < 0) {
    highlightedIndex.value = optionsFiltered.value.length - 1
  }
  scrollToHighlighted()
}

function highlightNext() {
  highlightedIndex.value = highlightedIndex.value + 1

  if (highlightedIndex.value > optionsFiltered.value.length - 1) {
    highlightedIndex.value = 0
  }

  scrollToHighlighted()
}

function scrollToHighlighted() {
  try {
    if (!elOptions.value) throw new Error('elOptions is undefined')

    elOptions.value[highlightedIndex.value].scrollIntoView({
      block: 'nearest',
    })
  } catch (error) {
    // silent error
  }
}

function confirmHighlighted() {
  if (!highlightedOption.value) return
  toggleOption(highlightedOption.value)
}

function toggleOption(option: Option) {
  const isSelected = normalizedValue.value.includes(option.id)

  if (isSelected) {
    removeOrDeselect(option)
  } else {
    addOrSelect(option)
  }
}

function addOrSelect(option: Option) {
  if (props.multiple) {
    // Make array unique
    const newValue = uniq([...normalizedValue.value, option.id])
    emitInput(newValue)
  } else {
    emitInput(option.id)
  }

  if (props.multiple) {
    return
  }
  close()
}

function removeOrDeselect(option: Option) {
  if (props.multiple) {
    removeOption(option)
  } else {
    emitInput(undefined)
  }

  if (props.multiple) {
    return
  }
  close()
}

function removeOption(option: Option) {
  // TODO: focus the previous option after removing
  const newValue = normalizedValue.value.filter((id) => {
    return id !== option.id
  })

  emitInput(newValue)
}

function applySearch(options: Option[]) {
  if (!searchterm.value) return options

  // Do search
  // Fuse collection gets updated as a side-effect of change in options
  if (!fuse) throw new Error('fuse is undefined')
  const results = fuse.search(searchterm.value)
  return results.map((result) => result.item)
}

function removeSelected(options: Option[]) {
  return options.filter((option) => {
    return !normalizedValue.value.includes(option.id)
  })
}

function removeHidden(options: Option[]) {
  return options.filter((option) => {
    return option.hidden !== true
  })
}

function reset() {
  emitInput(undefined)
}

function isSelectOptionSelected(option: Option) {
  if (isNil(props.value)) return

  if (props.multiple) {
    if (!Array.isArray(props.value)) {
      throw new Error('props.value is not an Array')
    }

    return props.value.includes(option.id)
  } else {
    return option.id === props.value
  }
}

function onInvalid(event: Event) {
  event.preventDefault()

  isInvalid.value = true

  if (!elSelect.value) throw new Error('elSelect is undefined')
  invalidMessage.value = elSelect.value.validationMessage
}

async function onInput() {
  await nextTick()

  if (!elSelect.value) throw new Error('elSelect is undefined')
  if (elSelect.value.validity.valid) {
    isInvalid.value = false
    invalidMessage.value = undefined
  }
}

function emitInput(value: Props['value']) {
  emit('input', value)
  emit('update:value', value)
}

async function emitInputSearchterm(event: Event) {
  if (!(event instanceof InputEvent)) throw new Error('Event is not InputEvent')
  if (!(event.target instanceof HTMLInputElement))
    throw new Error('event.target is not HTMLInputElement')

  // Wait for normalization to happen in watcher
  await nextTick()

  emit('inputSearchterm', event.target.value)
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmDropdownPure {
  $block: &;

  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'label error'
    'inner inner'
    'note note';

  &.is-disabled {
    pointer-events: none;
  }

  &-label {
    @include mixin.textLabel;

    grid-area: label;
    margin-bottom: 4px;
    justify-self: start;
    position: relative;

    #{$block}.is-required & {
      &::after {
        @include mixin.requiredAsterisk;
      }
    }
  }

  &-error {
    @include mixin.textLabel;

    grid-area: error;
    justify-self: end;
    color: color.$danger-500;
    opacity: 1;
    margin-bottom: 4px;
  }

  &-note {
    font-size: constant.$fontSize-default;
    font-weight: 500;
    color: color.$gray-700;
    grid-area: note;
    margin-top: 3px;
  }

  &-inner {
    grid-area: inner;
    min-width: 0;
  }

  &-select {
    @include mixin.visuallyHidden;
  }

  &-header {
    @include mixin.transition-hover(background-color);

    border: 1px solid color.$gray-300;
    border-radius: constant.$borderRadius-default;
    display: flex;
    background-color: color.$white;

    &:hover {
      background-color: color.$gray-100;
      border-color: color.$gray-400;
    }

    #{$block}.is-open &,
    &:focus {
      outline: none;

      .is-keyboardNavigation & {
        border-color: color.$key;
      }
    }

    #{$block}.is-open & {
      border-color: color.$primary-500;
      background-color: color.$primary-50;
      color: color.$primary-900;
    }

    #{$block}--placementBottom.is-open & {
      border-bottom-color: transparent;
      border-radius: constant.$borderRadius-default
        constant.$borderRadius-default 0 0;
    }

    #{$block}--placementTop.is-open & {
      border-top-color: transparent;
      border-radius: 0 0 constant.$borderRadius-default
        constant.$borderRadius-default;
    }

    #{$block}.has-error & {
      border-color: color.$danger-500;
      background-color: color.$danger-200;
    }

    #{$block}.is-disabled & {
      background-color: color.$gray-100;
      border-color: color.$gray-200;
    }
  }

  &-selection {
    min-height: 30px;
    display: flex;
    align-items: center;
    min-width: 0;
    flex-grow: 1;
  }

  &-selectedLabelContainer {
    padding: 7px;
    padding-bottom: 6px;
    min-height: 30px;
    line-height: constant.$lineHeight-default;
    cursor: default;
    min-width: 0;
    flex-grow: 1;

    #{$block}.is-disabled & {
      opacity: 0.5;
    }

    #{$block}.is-selectedOptionWithIcon & {
      padding-right: 0;
    }
  }

  &-placeholder {
    @include mixin.truncateText;

    opacity: 0.5;
  }

  &-selectedLabel {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &-selectedLabelPill {
    pointer-events: none;
    margin-top: -3px;
    margin-bottom: -3px;

    #{$block}--pillPositionRight & {
      margin-left: auto;
      order: 1;
    }
  }

  &-selectedLabelText {
    @include mixin.truncateText;
  }

  &-selectedLabelIcon {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-top: -2px;
    margin-bottom: -2px;
    flex-shrink: 0;
  }

  &-tagList {
    padding: 3px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
  }

  &-tag {
    margin-bottom: 3px;

    &:not(:last-child) {
      margin-right: 3px;
    }
  }

  &-actions {
    max-height: 30px;
    display: flex;
    align-items: center;
  }

  &-action {
    margin-right: 4px;
  }

  &-iconContainer {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-toggleIcon {
    @include mixin.transition-hover(opacity, $block);

    transform: rotate(0);
    transition: transform constant.$duration-fast;
    width: 12px;
    height: 12px;
    opacity: 0.3;

    #{$block}-header:hover & {
      opacity: 0.5;
    }

    #{$block}-header:active &,
    #{$block}.is-open & {
      opacity: 1;
      color: color.$primary-700;
    }

    #{$block}.is-open & {
      transform: rotate(180deg);
    }
  }

  &-loading {
    width: 20px;
    height: 20px;
  }

  &-dropdown {
    @include shadow.default('low', $outline: false);

    border: 1px solid color.$gray-400;
    width: 100%;
    background-color: color.$white;
    z-index: 1;
    display: grid;
    top: 0;
    left: 0;
    grid-template-rows: auto auto;

    #{$block}--placementBottom & {
      border-radius: 0 0 constant.$borderRadius-default
        constant.$borderRadius-default;
      grid-template-areas:
        'searchContainer'
        'list';
    }

    #{$block}--placementTop & {
      border-radius: constant.$borderRadius-default
        constant.$borderRadius-default 0 0;
      grid-template-areas:
        'list'
        'searchContainer';
    }

    &:focus {
      outline: none;
    }
  }

  &-searchContainer {
    grid-area: searchContainer;
    width: 100%;
    background-color: color.$gray-100;
    position: relative;
  }

  &-searchInput {
    width: 100%;
    padding: 7px;
    padding-bottom: 6px;
    height: 30px;
    padding-right: 32px;
    background-color: transparent;

    #{$block}--placementBottom & {
      border-bottom: 1px solid color.$gray-300;
    }

    #{$block}--placementTop & {
      border-top: 1px solid color.$gray-300;
    }

    &:focus {
      outline: none;
    }
  }

  &-searchIcon {
    width: 30px;
    height: 30px;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    color: color.$gray-800;
  }

  &-list {
    grid-area: list;
    margin: 0;
    overflow: auto;
    overscroll-behavior: contain;
    max-height: 25vh;
  }

  &-option {
    cursor: default;
    min-height: 30px;
    padding: 7px;
    padding-bottom: 6px;
    line-height: constant.$lineHeight-default;
    display: flex;
    gap: 4px;
    align-items: flex-start;

    &.is-highlighted {
      background-color: rgba(color.$key, 0.5);
    }

    &.is-inactive {
      color: color.$gray-500;
    }

    &:hover {
      // background-color: rgba(color.$key, 0.25);
      background-color: color.$primary-100;
      color: color.$primary-900;
    }

    &.is-selected:not(.is-highlighted, :hover) {
      background-color: color.$gray-200;
    }
  }

  &-optionPill {
    pointer-events: none;
    margin-top: -2px;
    margin-bottom: -2px;

    #{$block}--pillPositionRight & {
      margin-left: auto;
      order: 1;
    }
  }

  &-optionSelectedIcon {
    width: 12px;
    height: 12px;
    margin-top: 3px;
    flex-shrink: 0;
  }

  &-optionIcon {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-top: -2px;
    margin-bottom: -2px;
    flex-shrink: 0;
  }
}
</style>
