<template>
  <PmModalPure title="Suche" @close="$emit('close')">
    <div class="PmSearch">
      <PmTabNavigationPure
        v-model:selected-tab-id="selectedSearchOption"
        :tabs="searchOptions"
      />

      <PmSearchPure
        v-model:value="searchterm"
        :results="normalizedSearchResults"
        :now="now"
        :is-loading="isLoading"
        @select="select"
      />
    </div>
  </PmModalPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSearch'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { addYears, subYears, differenceInCalendarDays } from 'date-fns'
import { orderBy } from 'lodash-es'
import { useQuery } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { statusLookupJob } from '@/utilities/persoplan'
import { formatWithLocale, parseServerDateString } from '@/utilities/date'

import PmTabNavigationPure from '@/components/basics/PmTabNavigation/PmTabNavigationPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmSearchPure, {
  type Props as PropsSearchPure,
} from '@/components/persoplan/Search/PmSearchPure.vue'

import { SearchDocument } from '@/../generated/graphql'

export interface Props {
  now?: Date
}

export type NormalizedResult = PropsSearchPure['results'][number] & {
  projectId: number
}

export type Emit = {
  select: NormalizedResult
}

const props = withDefaults(defineProps<Props>(), {
  now: () => new Date(),
})

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'select', result: Emit['select'])
}>()

const store = useStore()
const searchterm = ref<string>()
const selectedSearchOption = ref<'past' | 'future' | undefined>('future')

const searchPeriodPast = computed(() => {
  const startDate = subYears(props.now, 1)
  const endDate = props.now
  const label = `${formatWithLocale(startDate)} bis ${formatWithLocale(
    props.now
  )}`

  return { startDate, endDate, label }
})

const searchPeriodAll = computed(() => {
  const startDate = subYears(props.now, 1)
  const endDate = addYears(props.now, 1)
  const label = `${formatWithLocale(startDate)} bis ${formatWithLocale(
    endDate
  )}`

  return { startDate, endDate, label }
})

const searchPeriodFuture = computed(() => {
  const startDate = props.now
  const endDate = addYears(props.now, 1)
  const label = `${formatWithLocale(props.now)} bis ${formatWithLocale(
    endDate
  )}`

  return { startDate, endDate, label }
})

const searchPeriod = computed(() => {
  // Default +/- 1 year
  if (selectedSearchOption.value === 'past') {
    return searchPeriodPast.value
  }

  if (selectedSearchOption.value === 'future') {
    return searchPeriodFuture.value
  }

  return searchPeriodAll.value
})

/**
 * Load data
 */
const isSearchResultsQueryEnabled = computed(() => {
  if (!searchterm.value) return false
  if (searchterm.value.length < 3) return false

  return true
})

const searchResultsQuery = useQuery(
  SearchDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => {
    return {
      searchterm: searchterm.value,
      startDate: searchPeriod.value.startDate ?? new Date(),
      endDate: searchPeriod.value.endDate ?? new Date(),
    }
  },
  () => ({
    enabled: isSearchResultsQueryEnabled,
    // notifyOnNetworkStatusChange is needed so isLoading gets updated properly
    notifyOnNetworkStatusChange: true,
    // Don't update the cache, which might change the calendar view.
    fetchPolicy: 'no-cache',
    debounce: 500,
  })
)

const isLoading = computed(() => searchResultsQuery.loading.value)
const searchResults = computed(
  () => searchResultsQuery.result.value?.searchResults
)

type SearchOption = {
  id: 'past' | 'all' | 'future'
  label: string
  title: string
}

const searchOptions = computed<SearchOption[]>(() => {
  return [
    {
      id: 'past',
      label: 'Vergangenheit',
      title: searchPeriodPast.value.label,
    },
    { id: 'all', label: 'Alles', title: searchPeriodAll.value.label },
    {
      id: 'future',
      label: 'Zukunft',
      title: searchPeriodFuture.value.label,
    },
  ]
})

const normalizedSearchResults = computed(() => {
  const normalizedResults: NormalizedResult[] = []

  searchResults.value?.forEach((result) => {
    if (!result) return

    const status = statusLookupJob({
      jobStateId: result.jobState?.id,
      jobTypeId: result.jobState?.jobType?.id,
    })

    const startDate = parseServerDateString(result.startDate)
    const endDate = parseServerDateString(result.endDate)

    if (!startDate) {
      throw new Error('startDate is undefined')
    }

    if (!endDate) {
      throw new Error('startDate is undefined')
    }

    if (!result.project?.id) {
      throw new Error('result.project.id is undefined')
    }

    const normalizedResult: NormalizedResult = {
      id: result.id,
      title: result.caption,
      startDate,
      endDate,
      project: result.project?.caption,
      projectLeader: result.arrangedBy?.displayName,
      projectNumber: result.project?.number,
      projectId: result.project?.id,
      // status,
      isCollectedWithAllJobsOfProject:
        result.project?.setting?.isJobLayerHidden,
    }

    normalizedResults.push(normalizedResult)
  })

  // Sort by days, less days to now (regardless if future or past) should be on top
  const sortedResults = orderBy(normalizedResults, (result) => {
    const daysToNow = Math.abs(
      differenceInCalendarDays(result.startDate, props.now)
    )
    return daysToNow
  })

  return sortedResults
})

function select(id: number) {
  const normalizedResult = normalizedSearchResults.value.find(
    (result) => result.id === id
  )

  if (!normalizedResult) {
    throw new Error('Result not found')
  }

  emit('select', normalizedResult)
}
</script>

<style lang="scss">
.PmSearch {
  $block: &;
}
</style>
