<template>
  <PmMultipleResourceAllocationsRequest
    :resource-allocation-ids="allConsideredOfProject"
    type="address"
    :has-no-results="hasNoResults"
    @close="emit('close')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmRequestAllOfProject'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import type { Get } from 'type-fest'

import {
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP,
  RESOURCE_TYPE_LOOKUP,
  RESOURCE_TYPE,
} from '@/constants/persoplan'

import PmMultipleResourceAllocationsRequest from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequest.vue'

import {
  AllConsideredOfProjectDocument,
  AllJobIdsOfProjectDocument,
  type AllConsideredOfProjectQuery,
} from '@/../generated/graphql'

export interface Props {
  projectId: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const allJobIdsOfProjectQuery = useQuery(
  AllJobIdsOfProjectDocument,
  () => ({
    projectId: props.projectId,
  }),
  () => ({
    fetchPolicy: 'network-only',
  })
)

const allJobIdsOfProject = computed(() => {
  const jobIds: number[] = []

  allJobIdsOfProjectQuery.result.value?.project?.jobs?.forEach((job) => {
    if (!job) return
    jobIds.push(job.id)
  })

  return jobIds
})

const allConsideredOfProjectQuery = useQuery(
  AllConsideredOfProjectDocument,
  () => ({
    jobIds: allJobIdsOfProject.value,
  }),
  () => ({
    enabled: allJobIdsOfProject.value.length > 0,
    fetchPolicy: 'network-only',
  })
)

function isVehicle(resource: Get<AllConsideredOfProjectQuery, 'resources[0]'>) {
  if (!resource) return

  const resourceTypeId = resource.resourceFunction?.resourceType?.id
  if (!resourceTypeId) {
    throw new Error('resourceTypeId is undefined')
  }

  const isVehicle =
    RESOURCE_TYPE_LOOKUP[resourceTypeId] == RESOURCE_TYPE.VEHICLE

  return isVehicle
}

const allConsideredOfProject = computed(() => {
  const resourceAllocationIds: number[] = []

  allConsideredOfProjectQuery.result.value?.resources?.forEach((resource) => {
    if (!resource) return
    if (isVehicle(resource)) return

    resource.resourceAllocations?.forEach((resourceAllocation) => {
      if (!resourceAllocation) return

      if (!resourceAllocation.resourceAllocationState?.id) {
        throw new Error('resourceAllocationState.id is undefined')
      }

      if (
        resourceAllocation.resourceAllocationState.id !==
        STATUS_RESOURCE_ALLOCATION_ID_LOOKUP['considered']
      ) {
        throw new Error('resourceAllocationState is not considered!')
      }

      resourceAllocationIds.push(resourceAllocation.id)
    })
  })

  return resourceAllocationIds
})

const hasNoResults = computed(() => {
  if (allConsideredOfProjectQuery.error.value) return false

  return (
    allConsideredOfProjectQuery.loading.value === false &&
    allConsideredOfProject.value.length === 0
  )
})
</script>
