import { watch, ref, watchEffect, computed } from 'vue'
import { useMagicKeys } from '@vueuse/core'

type ModifierKeys = 'alt' | 'ctrl' | 'meta' | 'shift'

export function useShortcut(keySequence: string[], callback: () => void) {
  let keyboardEvent: KeyboardEvent

  const isActive = ref(false)
  const modifierKeysPressed = ref<ModifierKeys[]>([])

  const keys = useMagicKeys({
    passive: false,
    onEventFired: (event) => {
      checkForModifierKeys(event)
      keyboardEvent = event
    },
  })

  function checkForModifierKeys(event: KeyboardEvent) {
    modifierKeysPressed.value = []

    if (event.altKey) modifierKeysPressed.value.push('alt')
    if (event.ctrlKey) modifierKeysPressed.value.push('ctrl')
    if (event.metaKey) modifierKeysPressed.value.push('meta')
    if (event.shiftKey) modifierKeysPressed.value.push('shift')
  }

  const keySequenceLowerCase = computed(() => {
    if (!keySequence) return
    return keySequence.map((key) => key.toLowerCase())
  })

  watchEffect(() => {
    if (!keySequence) return

    /**
     *  Check if any modifier keys, not part of the key sequence,
     *  were pressed at the same time
     */
    const modifierKeysNotPartOfKeySequence = modifierKeysPressed.value.filter(
      (modifierKey) => {
        if (!keySequenceLowerCase.value) return false

        const isPartOfKeySequence =
          keySequenceLowerCase.value.includes(modifierKey)

        return isPartOfKeySequence === false
      }
    )

    if (modifierKeysNotPartOfKeySequence.length > 0) return

    const sequenceAllPressed =
      keySequence.filter((character) => {
        const isPressed = keys[character].value === true
        // console.log(character, 'is pressed:', isPressed)
        return isPressed
      }).length === keySequence.length

    /**
     * Maybe prevent default event handling
     * This is useful, if you want to bind events to common browser shortcuts
     * for example Ctrl + F for searching the page
     */
    if (sequenceAllPressed === true && keyboardEvent) {
      keyboardEvent.preventDefault()
    }

    // Don't do anything if value has not changed
    if (isActive.value === sequenceAllPressed) return

    isActive.value = sequenceAllPressed
  })

  watch(isActive, () => {
    if (isActive.value === true) {
      callback()
    }
  })
}
