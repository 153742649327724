<template>
  <PmEventPure :name="name" :description="`${age} Jahre`" :text="text" />
</template>

<script>
import { differenceInCalendarYears } from 'date-fns'
import { formatWithLocale } from '@/utilities/date'

import PmEventPure from '@/components/persoplan/PmGeneralEvents/PmEventPure.vue'

export default {
  name: 'PmBirthdayPure',
  components: {
    PmEventPure,
  },

  props: {
    name: { type: String, required: true },
    date: { type: Date, required: true },
    dateOfBirth: { type: Date, required: true },
  },

  computed: {
    age() {
      return differenceInCalendarYears(this.date, this.dateOfBirth)
    },

    text() {
      return `Geburtstag: ${formatWithLocale(this.dateOfBirth)}`
    },
  },
}
</script>

<style lang="scss">
.PmBirthdayPure {
  $block: &;
}
</style>
