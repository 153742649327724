import { computed, type Ref } from 'vue'
import { isNil } from 'lodash-es'

import {
  type LeaveRequestStatus,
  LEAVE_REQUEST_STATUS_LABEL_LOOKUP,
} from '@/constants/leaveRequest'

export { type LeaveRequestStatus } from '@/constants/leaveRequest'
import scssVariables from '@/assets/scss/scssVariables'
import { useComponentClass } from '@thomasaull-shared/composables'

interface Options {
  status: Ref<LeaveRequestStatus | undefined | null>
}

export function useLeaveRequestStatus(options: Options) {
  const componentClass = useComponentClass()

  const statusNormalized = computed<LeaveRequestStatus>(() => {
    if (isNil(options.status.value)) return 'notProcessed'
    return options.status.value
  })

  const classes = computed(() => {
    return [
      componentClass.propModifier({
        name: 'status',
        value: statusNormalized.value,
      }),
    ]
  })

  const color = computed(() => {
    if (statusNormalized.value === 'approved') {
      return scssVariables.color['status-confirmed']
    }

    if (statusNormalized.value === 'rejected') {
      return scssVariables.color['status-cancelled']
    }

    return undefined
  })

  const label = computed(() => {
    return LEAVE_REQUEST_STATUS_LABEL_LOOKUP[statusNormalized.value]
  })

  return {
    classes,
    color,
    label,
  }
}
