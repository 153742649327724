<template>
  <div :class="[componentClass.root, ...classes]">
    <div v-if="isHeaderVisible" :class="componentClass.element('header')">
      <h2 v-if="title" :class="componentClass.element('title')">
        <div :class="componentClass.element('titleIcon')">
          <PmIconPure v-if="icon" :name="icon" />
        </div>
        {{ title }}
      </h2>

      <div :class="componentClass.element('control')">
        <slot name="control" />
      </div>
    </div>

    <div :class="componentClass.element('content')">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import PmIconPure, {
  type Props as PropsIcon,
} from '@/components/basics/PmIcon/PmIconPure.vue'
import { useHasSlotContent } from '@/composition/useHasSlotContent'

export interface Props {
  title?: string
  width?: 'full' | 'half'
  icon?: PropsIcon['name']
}

const props = withDefaults(defineProps<Props>(), {
  width: 'full',
})

const componentClass = useComponentClass()
const hasSlotContent = useHasSlotContent(['control'])

const classes = computed(() => {
  return [
    {
      [componentClass.modifier('half')]: props.width === 'half',
      [componentClass.modifier('titleWithIcon')]: !!props.icon,
    },
  ]
})

const isHeaderVisible = computed(() => {
  if (props.title) return true
  if (hasSlotContent.value.control) return true

  return false
})
</script>

<style lang="scss">
.PmGeneralPageSectionPure {
  $block: &;
  $iconSize: 1.5em;

  border-radius: constant.$borderRadius-large;
  background-color: color.$white;
  border: 1px solid color.$gray-200;
  padding: var(--space-smallest);

  &--half {
    width: 50%;
  }

  &-header {
    display: flex;
    margin-bottom: 12px;
    min-height: 32px;
  }

  &-title {
    @include typography.h3($includeMargin: false);

    position: relative;

    #{$block}--titleWithIcon & {
      padding-left: calc($iconSize + 0.5em);
    }
  }

  &-titleIcon {
    width: $iconSize;
    aspect-ratio: 1;
    position: absolute;
    top: calc((1em - $iconSize) / 2);
    left: 0;
  }

  &-control {
    margin-left: auto;
  }
}
</style>
