<template>
  <transition appear>
    <PmPopoverPure
      v-if="isVisible"
      v-bind="$attrs"
      class="PmPopoverWithTransitionPure"
    >
      <slot />
    </PmPopoverPure>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// import { constant } from '@/assets/scss/scssVariables'

import PmPopoverPure from '@/components/basics/PmPopover/PmPopoverPure.vue'

export interface Props {
  isVisible?: boolean
}

export default defineComponent({
  name: 'PmPopoverWithTransitionPure',
  components: {
    PmPopoverPure,
  },

  inheritAttrs: false,

  props: {
    isVisible: { type: Boolean, default: true },
  },

  setup() {
    /**
     * Enter/Leave transitions
     */
    // const onEnter = async (el, done) => {
    //   console.log('onEnter')
    //   if (!elContainer.value) {
    //     return done()
    //   }
    //   gsap.killTweensOf(elContainer.value)
    //   await nextTick()
    //   gsap.set(elContainer.value, {
    //     opacity: 0,
    //   })
    //   // Wait for first `placePopover` to happen:
    //   await new Promise((resolve) => setTimeout(resolve, 0))
    //   let offset = -20
    //   if (currentPlacement.value === 'bottom') offset = 20
    //   gsap.set(elContainer.value, {
    //     y: offset,
    //   })
    //   gsap.to(elContainer.value, {
    //     duration: constant['duration-fast'],
    //     y: 0,
    //     opacity: 1,
    //     onComplete: done,
    //   })
    // }
    // const onLeave = (el, done) => {
    //   console.log('onLeave', elContainer.value)
    //   if (!elContainer.value) {
    //     return done()
    //   }
    //   gsap.killTweensOf(elContainer.value)
    //   gsap.to(elContainer.value, {
    //     duration: constant['duration-fast'],
    //     opacity: 0,
    //     onComplete: done,
    //   })
    // }
  },
})
</script>

<style lang="scss">
.PmPopoverWithTransitionPure {
  $block: &;

  &.v-enter-active,
  &.v-leave-active {
    transition-duration: constant.$duration-fast;
    transition-property: opacity;
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}
</style>
