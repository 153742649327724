/**
 * How to restart state machines
 * @see https://github.com/statelyai/xstate/issues/1476#issuecomment-1164368550
 */

/**
 * @todo Add destroy method
 */
import { ref, type Ref, computed, watch, unref, type UnwrapRef } from 'vue'
import { useMachine } from '@xstate5/vue'
import {
  type StateValue,
  type ActorOptions,
  type AnyStateMachine,
  type SnapshotFrom,
  isMachineSnapshot,
  type AnyMachineSnapshot,
} from 'xstate5'
import { set } from 'lodash-es'
import { createBrowserInspector } from '@statelyai/inspect'
import type { Get } from 'type-fest'

/**
 * Type to extract all possible states to a string union with dot syntax
 * Adapted from: @see https://stackoverflow.com/a/68404823
 * @see https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
 * @see https://github.com/sindresorhus/type-fest/blob/main/source/paths.d.ts
 */
type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

type DotNestedKeys<T> = (
  T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}`
      }[Exclude<keyof T, symbol>]
    : T
) extends infer D
  ? Extract<D, string>
  : never

/**
 * State as object or string with dot notation
 * @example
 * ```
 * { myState: 'child' } // object
 * 'myState.child' // string
 * ```
 */
export type StatePathOrValue<TMachine> =
  | StateAsValue<TMachine>
  | StateAsPath<TMachine>

type StateAsValue<TMachine> = SnapshotFrom<TMachine>['value']
type StateAsPath<TMachine> = DotNestedKeys<SnapshotFrom<TMachine>['value']>

interface Options<TMachine extends AnyStateMachine> {
  /**
   * Initial state the machine should use. Can be passed as object
   * or string in dot notation
   */
  initialState?: StatePathOrValue<TMachine>
  syncStateWith?: Ref<StatePathOrValue<TMachine> | undefined>
  useInspector?: boolean
}

export function useXState<TMachine extends AnyStateMachine>(
  machine: TMachine,
  options: ActorOptions<TMachine> & Options<TMachine> = {}
) {
  let inspector: ReturnType<typeof createBrowserInspector> | undefined =
    undefined

  if (options?.useInspector && import.meta.env.DEV === true) {
    inspector = createBrowserInspector()
  }

  type Snapshot = SnapshotFrom<TMachine>
  type GetMetaReturn = ReturnType<Snapshot['getMeta']>
  type Meta = GetMetaReturn[keyof GetMetaReturn]

  const xstate = ref<ReturnType<typeof useMachine<TMachine>>>()
  const meta = ref<Meta>()
  const path = ref<StateAsPath<TMachine>>()

  function start(initialState?: StateValue) {
    const initialSnapshot = initialState
      ? createInitialSnapshot(initialState)
      : undefined

    xstate.value = useMachine(machine, {
      inspect: inspector?.inspect,
      snapshot: initialSnapshot,
    })

    // console.log(JSON.stringify(xstate.value.actorRef.getPersistedSnapshot()))

    if (!isMachineSnapshot(xstate.value.snapshot))
      throw new Error('is not machine snapshot')

    /**
     * Set initial values. We need to do this since when initializing the
     * state machhine with an initialSnapshot the subscribe method does not gets called
     */
    setMeta(xstate.value.snapshot)
    setPath(xstate.value.snapshot)

    xstate.value.actorRef.subscribe((value) => {
      const snapshot = value as any
      if (!isMachineSnapshot(snapshot)) {
        throw new Error('is not machine snapshot')
      }

      setMeta(snapshot)
      setPath(snapshot)
    })
  }

  function setMeta(snapshot: AnyMachineSnapshot) {
    meta.value = getMergedMeta(snapshot) as Meta
  }

  function setPath(snapshot: AnyMachineSnapshot) {
    path.value = getStatePath(snapshot.value) as StateAsPath<TMachine>
  }

  function stop() {
    if (!xstate.value) return
    xstate.value.actorRef.stop()
  }

  function restartWith(
    /**
     * state the machine should restart with. Can be passed as object
     * or string in dot notation
     */
    state: StatePathOrValue<TMachine>
  ) {
    stop()
    start(state)
  }

  /**
   * What options.syncStateWith for changes
   * and restart state machine
   */
  if (options.syncStateWith) {
    watch(
      options.syncStateWith,
      () => {
        if (options.syncStateWith?.value) {
          restartWith(options.syncStateWith.value)
        } else {
          start(options.initialState)
        }
      },
      {
        immediate: true,
      }
    )
  } else {
    start(options.initialState)
  }

  const xstateWithExtras = computed(() => {
    if (!xstate.value) throw new Error('xstate is undefined')
    if (!isMachineSnapshot(xstate.value.snapshot))
      throw new Error('is not machine snapshot')

    return {
      // Snapshot needs some massaging to have proper types on context
      snapshot: unref(xstate.value.snapshot) as UnwrapRef<
        NonNullable<Get<typeof xstate, 'value.snapshot'>>
      >,
      send: xstate.value.send,
      restartWith,
      meta,
      path,
    }
  })

  return xstateWithExtras
}

function getMergedMeta(snapshot: AnyMachineSnapshot) {
  const meta = snapshot.getMeta()

  // https://xstate.js.org/docs/guides/states.html#state-meta-data
  return Object.keys(meta).reduce((acc, key) => {
    const value = meta[key]

    // Assuming each meta value is an object
    Object.assign(acc, value)

    return acc
  }, {})
}

function createInitialSnapshot(state: StateValue) {
  const stateNormalized =
    typeof state === 'string' ? createStateValueFromStatePath(state) : state

  const snapshot = {
    status: 'active',
    value: stateNormalized,
    children: {},
  } as any

  return snapshot
}

function getStatePath(stateValue: StateValue) {
  const stateValueStrings = getStateValueStrings(stateValue)
  return stateValueStrings[stateValueStrings.length - 1]
}

/**
 * Migration for deprecated function `state.toStrings()`
 * @see https://stately.ai/docs/migration#use-stategetmeta-instead-of-statemeta
 */
function getStateValueStrings(stateValue: StateValue): string[] {
  if (typeof stateValue === 'string') {
    return [stateValue]
  }
  const valueKeys = Object.keys(stateValue)

  return valueKeys.concat(
    ...valueKeys.map((key) =>
      getStateValueStrings(stateValue[key]!).map((s) => key + '.' + s)
    )
  )
}

/**
 * Create a snapshot for xstate by providing a state path separated by dots
 * @param path 'path.toMy.nestedState`
 */
function createStateValueFromStatePath(path: string) {
  const items = path.split('.')
  let stateValue: StateValue = {}

  if (items.length === 1) {
    stateValue = path
  } else {
    const lastItem = items[items.length - 1]
    const pathWithoutLastItem = items.slice(0, -1).join('.')

    stateValue = set(stateValue, pathWithoutLastItem, lastItem)
  }

  return stateValue
}
