<template>
  <Portal to="modal">
    <PmModalPure :can-be-closed="false" title="Antrag Fremddienstleistung">
      <div :class="componentClass.root">
        <template v-if="!xstate.snapshot.matches({ create: 'success' })">
          <div :class="componentClass.element('warning')">
            <PmAppNotificationPure
              variant="warning"
              title="Nicht gemeldete Tätigkeiten führen zur fristlosen Kündigung!"
            />
          </div>

          <PmFormPure
            :id="formId"
            :disabled="isDisabled"
            @submit.prevent="onSubmit"
          >
            <PmDateRangePickerPure v-model:value="dates" :with-time="true" />

            <PmInputPure
              v-model:value="nameOfProduction"
              label="Name der Produktion"
              required
            />

            <PmInputPure v-model:value="client" label="Auftraggeber" required />

            <PmInputPure
              v-model:value="customerOfClient"
              label="Kunde des Auftraggebers"
              required
            />

            <PmInputPure v-model:value="location" label="Einsatzort" required />

            <PmInputPure
              v-model:value="task"
              label="Aufgabe/Tätigkeit"
              required
            />

            <PmTextareaPure v-model:value="note" label="Bemerkung" :rows="3" />

            <template v-if="xstate.meta.value?.error" #error>
              <PmErrorNotificationPure
                :message="errorMessage || xstate.meta.value?.message"
                :details="errorDetails"
              />
            </template>
          </PmFormPure>
        </template>
      </div>

      <template #footer>
        <PmButtonListPure
          v-if="!xstate.snapshot.matches({ create: 'success' })"
          layout="distribute"
        >
          <PmButtonPure
            label="Abbrechen"
            icon="close"
            :disabled="isDisabled"
            @click="emit('close')"
          />

          <PmButtonPure
            variant="primary"
            label="Einreichen"
            type="submit"
            :form="formId"
            icon="send"
            :loading="xstate.snapshot.matches({ create: 'saving' })"
            :disabled="isDisabled"
          />
        </PmButtonListPure>
      </template>
    </PmModalPure>
  </Portal>
</template>

<script setup lang="ts">
import { ref, toRef, computed } from 'vue'
import { Portal } from 'portal-vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import cuid from '@paralleldrive/cuid2'
import * as v from 'valibot'

import { useXState, type StatePathOrValue } from '@/composition/useXState5'
import { PmCreateLeaveRequestPureState } from '@/components/PmCreateLeaveRequest/PmCreateLeaveRequestPureState'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmDateRangePickerPure from '@/components/basics/PmDateRangePicker/PmDateRangePickerPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import type { RequireKeys } from '@/types/misc'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'

export interface Props {
  state?: StatePathOrValue<typeof PmCreateLeaveRequestPureState>
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

const formDataSchema = v.object({
  startDate: v.date(),
  endDate: v.date(),
  nameOfProduction: v.string(),
  client: v.string(),
  customerOfClient: v.string(),
  location: v.string(),
  task: v.string(),
  note: v.nullish(v.string()),
})

type FormData = v.InferOutput<typeof formDataSchema>

export type Emits = {
  submit: FormData
}

const emit = defineEmits<{
  close: []
  submit: [Emits['submit']]
}>()

const componentClass = useComponentClass()
const xstate = useXState(PmCreateLeaveRequestPureState, {
  syncStateWith: toRef(props, 'state'),
})

const dates = ref({
  start: new Date(),
  end: new Date(),
})

const formId = `${componentClass.root}.${cuid.createId()}`
const nameOfProduction = ref<string>()
const client = ref<string>()
const customerOfClient = ref<string>()
const location = ref<string>()
const task = ref<string>()
const note = ref<string>()

function onSubmit() {
  const data = v.parse(formDataSchema, {
    startDate: dates.value.start,
    endDate: dates.value.end,
    nameOfProduction: nameOfProduction.value,
    client: client.value,
    customerOfClient: customerOfClient.value,
    location: location.value,
    task: task.value,
    note: note.value,
  } satisfies RequireKeys<FormData>)

  emit('submit', data)
}

const isDisabled = computed(() => {
  if (xstate.value.snapshot.matches({ create: 'saving' })) return true
  return false
})
</script>

<style lang="scss">
.PmCreateExternalServiceRequestPure {
  $block: &;

  &-warning {
    margin-bottom: 20px;
  }
}
</style>
