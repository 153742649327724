import { DANGEROUSLY_USE_PRODUCTION_API } from '@/constants/general'

const hostname = window?.location?.hostname
let apiUrl

switch (hostname) {
  case 'inside.promusik.de':
  case 'inside-beta.promusik.de':
  case 'ext.promusik.de':
    apiUrl = ''
    break

  case 'localhost':
  case '127.0.0.1':
    if (DANGEROUSLY_USE_PRODUCTION_API === true) {
      apiUrl = 'https://inside.promusik.de'
      break
    }

    apiUrl = '//localhost:8000'
    break

  default:
    apiUrl = `//${__DEV_HOST_IP__}:8000`
    break
}

export default {
  apiUrl: apiUrl,
}
