<template>
  <div class="PmRadioPure" :class="classes">
    <label class="PmRadioPure-inner">
      <div class="PmRadioPure-checkboxContainer">
        <input
          ref="elInput"
          class="PmRadioPure-input"
          type="radio"
          :disabled="disabled"
          :checked="checked"
          :name="name"
          :required="required"
          @input="onInput"
          @invalid="onInvalid"
        />

        <div class="PmRadioPure-radio"></div>
      </div>

      <div class="PmRadioPure-content">
        <div class="PmRadioPure-label">
          <slot>{{ label }}</slot>
        </div>

        <div v-if="note" class="PmRadioPure-note">
          {{ note }}
        </div>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { useHasSlotContent } from '@/composition/useHasSlotContent'
import { useFormValidation } from '@/composition/useFormValidation'

export interface Props {
  id: string
  name?: string
  checked?: boolean
  disabled?: boolean
  size?: 'default' | 'small'
  label?: string
  note?: string
  required?: boolean
  triggerValidityCheck?: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'update:checked', checked: boolean): void
  (event: 'check', id: Props['id']): void
  (event: 'uncheck', id: Props['id']): void
  (event: 'invalid', payload: Event): void
}>()

const COMPONENT_NAME = 'PmRadioPure'

const elInput = ref<HTMLInputElement>()

const hasSlotContent = useHasSlotContent(['default'])

const formValidation = useFormValidation({
  elInput: elInput,
  type: 'radio',
})

watch(() => props.triggerValidityCheck, formValidation.checkValidity)

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--small`]: props.size === 'small',
    [`${COMPONENT_NAME}--noLabel`]:
      !hasSlotContent.value.default && !props.label,
    'is-disabled': props.disabled,
    'is-checked': props.checked,
    'is-required': props.required,
  }
})

function onInput(event: Event) {
  if (!(event.target instanceof HTMLInputElement)) {
    throw new Error('event.target is not HTMLInputElement')
  }

  const isChecked = event.target.checked
  emit('update:checked', event.target.checked)
  isChecked ? emit('check', props.id) : emit('uncheck', props.id)
}

function onInvalid(event: Event) {
  formValidation.onInvalid(event)
  emit('invalid', event)
}

defineExpose({
  elInput,
})
</script>

<style lang="scss">
.PmRadioPure {
  $block: &;

  @include cssVar.define($block, 'colorBorder', color.$gray-300);
  @include cssVar.define($block, 'colorBorder-hover', color.$gray-400);
  @include cssVar.define($block, 'colorDot', color.$white);

  &.is-disabled {
    @include cssVar.define($block, 'colorBorder', color.$gray-200);
    @include cssVar.define($block, 'colorDot', color.$gray-200);

    pointer-events: none;
  }

  &.is-checked:not(&.is-disabled) {
    @include cssVar.define($block, 'colorBorder', color.$primary-500);
    @include cssVar.define($block, 'colorBorder-hover', color.$primary-500);
  }

  display: flex;
  justify-content: flex-start;

  &-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: constant.$borderRadius-default;
    padding-block: 4px;
    padding-inline-end: 8px;

    #{$block}--noLabel & {
      padding: unset;
    }
  }

  &-checkboxContainer {
    height: #{constant.$lineHeight-default}em;
    display: flex;
    align-items: center;
  }

  &-input {
    @include mixin.visuallyHidden;
  }

  &-radio {
    @include mixin.transition-hover((border-color), $block);

    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 8px;
    background-color: color.$white;
    position: relative;
    border: 3px solid cssVar.use($block, 'colorBorder');

    &::after {
      transition: transform 0.1s ease-out;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: cssVar.use($block, 'colorDot');
      position: absolute;
      top: 3px;
      left: 3px;
      transform: scale(0);
    }

    #{$block}:hover &,
    #{$block}.is-hover & {
      border-color: cssVar.use($block, 'colorBorder-hover');
    }

    #{$block}-input:focus ~ &,
    #{$block}.is-focus & {
      .is-keyboardNavigation & {
        box-shadow: 0 0 0 2px rgba(color.$key, 0.5);
      }
    }

    #{$block}.is-checked & {
      background-color: cssVar.use($block, 'colorBorder');

      &::after {
        transform: scale(1);
      }
    }

    #{$block}--small & {
      width: 17px;
      height: 17px;

      &::after {
        width: 7px;
        height: 7px;
      }
    }

    #{$block}--noLabel & {
      margin-right: unset;
    }
  }

  &-icon {
    padding: 3px;
    display: none;
    color: cssVar.use($block, 'colorIcon');

    #{$block}-input:checked + #{$block}-checkbox &,
    #{$block}.is-checked & {
      display: block;
    }
  }

  &-content {
    #{$block}.is-disabled & {
      opacity: 0.7;
    }
  }

  &-label {
    #{$block}.is-required & {
      &::after {
        @include mixin.requiredAsterisk;
      }
    }
  }

  &-note {
    margin-top: 4px;
    font-size: constant.$fontSize-default;
    color: color.$gray-600;
    font-weight: 500;
  }
}
</style>
