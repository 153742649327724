<template>
  <PmDropdownPure
    v-model:value="selectedAddressId"
    :options="optionsNormalized"
    :label="labelNormalized"
    :required="required"
    :is-loading="isLoading"
    :has-reset="true"
    @input-searchterm="(value) => (searchTerm = value)"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { isNil, uniqBy } from 'lodash-es'

import {
  DropdownAddressDataDocument,
  DropdownAddressSearchQueryDocument,
} from '@/../generated/graphql'
import PmDropdownPure, {
  type Props as PropsDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import { getDisplayNameOfAddress } from '@/utilities/string'

export interface Props extends Pick<PropsDropdownPure, 'required' | 'label'> {
  type?: 'employees'
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const labelNormalized = computed(() => {
  if (props.label) return props.label
  if (props.type === 'employees') return 'Mitarbeiter'

  return undefined
})

const searchTerm = ref<string>()
const selectedAddressId = defineModel<number>('selectedAddressId')

const hasSearchTerm = computed(() => {
  if (isNil(searchTerm.value)) return false
  const hasMinLength = searchTerm.value.trim().length >= 3
  return hasMinLength
})

const addressSearchQuery = useQuery(
  DropdownAddressSearchQueryDocument,
  () => ({
    searchterm: searchTerm.value,
    onlyEmployees: props.type === 'employees' ? true : undefined,
  }),
  () => ({
    enabled: hasSearchTerm.value,
    debounce: 250,
  })
)

const addressQuery = useQuery(
  DropdownAddressDataDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: selectedAddressId.value,
  }),
  () => ({
    enabled: Boolean(selectedAddressId.value),
  })
)

const isLoading = computed(() => {
  if (addressSearchQuery.loading.value === true) return true
  if (addressQuery.loading.value === true) return true

  return false
})

const selectedAddressNormalized = computed(() => {
  if (!addressQuery.result.value?.address) return
  if (!selectedAddressId.value) return

  return {
    id: addressQuery.result.value.address.id,
    label: getDisplayNameOfAddress(addressQuery.result.value.address),
  }
})

const optionsNormalized = computed(() => {
  const searchResults =
    addressSearchQuery.result.value?.addresses?.reduce(
      (result: PropsDropdownPure['options'], address) => {
        if (!address) return result

        result.push({
          id: address.id,
          label: getDisplayNameOfAddress(address),
        })

        return result
      },
      []
    ) ?? []

  const addresses: PropsDropdownPure['options'] = []

  if (selectedAddressNormalized.value) {
    addresses.push(selectedAddressNormalized.value)
  }

  return uniqBy([...searchResults, ...addresses], 'id')
})
</script>
