<template>
  <div :class="componentClass.root">
    <dl :class="componentClass.element('list')">
      <slot />
    </dl>
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
</script>

<style lang="scss">
.PmMetaListPure {
  $block: &;

  @include mixin.textLabel;

  line-height: 1.3em;

  &-list {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.25em;
  }
}
</style>
