<template>
  <div class="PmMultiAllocationListItemPure">
    <div class="PmMultiAllocationListItemPure-content">
      <div class="PmMultiAllocationListItemPure-labelContainer">
        {{ jobLabel }} {{ appointmentLabel }}

        <PmResourceShortPure
          v-if="resourceFunctionLabel"
          :label="resourceFunctionLabel"
          :title="resourceFunctionTitle"
          :interactive="false"
          class="PmMultiAllocationListItemPure-resourceFunction"
        />

        <div class="PmMultiAllocationListItemPure-resourceAllocation">
          <PmStatusDotPure
            resource-allocation-status="confirmed"
            type="resourceAllocation"
            class="PmMultiAllocationListItemPure-resourceAllocationStatus"
            size="none"
          />

          {{ resourceAllocationLabel }}
        </div>
      </div>

      <div class="PmMultiAllocationListItemPure-control">
        <PmButtonPure
          v-if="false"
          :icon="ICONS.JUMP"
          alternative="ghost"
          size="small"
          title="Im Kalender anzeigen"
        />
      </div>

      <div class="PmMultiAllocationListItemPure-status">
        <PmLoadingPure v-if="xstate.state.value.matches('saving')" />

        <PmIconPure
          v-if="xstate.state.value.matches('success')"
          key="success"
          :name="ICONS.CHECK"
          class="PmMultiAllocationListItemPure-statusSuccess"
        />

        <PmIconPure
          v-if="xstate.meta.value.error"
          key="error"
          :name="ICONS.ALERT"
          class="PmMultiAllocationListItemPure-statusError"
        />
      </div>
    </div>

    <PmErrorNotificationPure
      v-if="xstate.meta.value.error"
      class="PmMultiAllocationListItemPure-notification"
      :message="errorMessage || xstate.meta.value.errorMessage"
      :details="errorDetails"
    >
    </PmErrorNotificationPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { states } from '@/components/persoplan/PmMultiSelectionController/PmMultiAllocationListItemPureState'

const COMPONENT_NAME = 'PmMultiAllocationListItemPure'

export const propTypes = {
  state: {
    allowed: states,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef } from 'vue'

import { ICONS } from '@/constants/icons'

import {
  PmMultiAllocationListItemPureState,
  type State,
} from '@/components/persoplan/PmMultiSelectionController/PmMultiAllocationListItemPureState'
import { useXState } from '@/composition/useXState'

import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmResourceShortPure from '@/components/persoplan/PmResource/PmResourceShortPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'

export interface Props {
  resourceFunctionLabel?: string
  resourceFunctionTitle: string
  jobLabel: string
  appointmentLabel: string
  resourceAllocationLabel?: string
  state: State
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const xstate = useXState(PmMultiAllocationListItemPureState, {
  syncStateWith: toRef(props, 'state'),
})
</script>

<style lang="scss">
.PmMultiAllocationListItemPure {
  $block: &;

  &-content {
    display: flex;
    align-items: center;
    min-height: 22px;
  }

  &-labelContainer {
    display: flex;
    align-items: flex-start;
    margin-right: auto;
  }

  &-resourceFunction {
    margin-right: 4px;
  }

  &-resourceAllocation {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &-resourceAllocationStatus {
    width: 10px;
    height: 10px;
    position: relative;
    top: 1px;
  }

  &-control {
    margin-right: 0;
    opacity: 0.1;

    #{$block}:hover & {
      opacity: 1;
    }
  }

  &-status {
    width: 22px;
    height: 22px;
    padding: 1px;
    flex-shrink: 0;
  }

  &-statusSuccess {
    color: color.$success-500;
  }

  &-statusError {
    color: color.$danger-700;
  }

  &-notification {
    margin-top: 8px;
  }
}
</style>
