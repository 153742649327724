import { computed, type Ref } from 'vue'
import scssVariables from '@/assets/scss/scssVariables'

import {
  STATUS_RESOURCE_REQUEST,
  type StatusResourceRequest,
  STATUS_RESOURCE_REQUEST_LOOKUP_LABEL,
} from '@/constants/persoplan'

import { lookup } from '@/utilities/misc'

export { type StatusResourceRequest, STATUS_RESOURCE_REQUEST }

interface Options {
  status: Ref<StatusResourceRequest | undefined | null>
}

export function useResourceRequestStatus(options: Options) {
  const classes = computed(() => {
    if (!options.status.value) return

    return {
      'is-statusAccepted':
        options.status.value === STATUS_RESOURCE_REQUEST.ACCEPT,
      'is-statusDeclined':
        options.status.value === STATUS_RESOURCE_REQUEST.DECLINE,
      'is-statusCanceled':
        options.status.value === STATUS_RESOURCE_REQUEST.CANCEL,
    }
  })

  const color = computed(() => {
    if (!options.status.value) return

    return scssVariables.color[`status-resourceRequest-${options.status.value}`]
  })

  const label = computed(() => {
    const result = lookup(
      options.status.value,
      STATUS_RESOURCE_REQUEST_LOOKUP_LABEL
    )

    if (!result) return 'Nicht beantwortet'
    return result
  })

  return {
    classes,
    color,
    label,
  }
}
