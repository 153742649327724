<template>
  <tbody class="PmTableGroupPure" :class="classes">
    <tr class="PmTableGroupPure-head">
      <th class="PmTableGroupPure-headContent" :colspan="columns?.length">
        <slot name="group" />
      </th>
    </tr>

    <slot />
  </tbody>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmTableGroupPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { injectStrict, tableKey } from '@/utilities/inject'
import { useHasSlotContent } from '@/composition/useHasSlotContent'

export interface Props {
  label?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const { columns } = injectStrict(tableKey)

const hasSlotContent = useHasSlotContent(['default'])

const isEmpty = computed(() => {
  const hasSlot = hasSlotContent.value.default === true
  return hasSlot === false
})

const classes = computed(() => {
  return {
    'is-empty': isEmpty.value === true,
  }
})
</script>

<style lang="scss">
.PmTableGroupPure {
  $block: &;
  $parent: '.PmTablePure';

  @include cssVar.define(
    $block,
    'cellPadding',
    cssVar.use($parent, 'cellPadding')
  );
  @include cssVar.define(
    $block,
    'colorBorder',
    cssVar.use($parent, 'colorBorder')
  );
  @include cssVar.define(
    $block,
    'colorBorderInline',
    cssVar.use($parent, 'colorBorderInline') // red
  );
  @include cssVar.define(
    $block,
    'borderRadius',
    cssVar.use($parent, 'borderRadius')
  );
  @include cssVar.define(
    $block,
    'headerHeight',
    cssVar.use($parent, 'headerHeight')
  );

  display: grid;
  grid-column: -1 / 1;
  grid-template-columns: subgrid;
  position: relative;
  z-index: 0;
  text-align: left;

  &-head {
    grid-column: -1 / 1;
    display: flex;
    position: sticky;
    top: calc(var(--navigationHeight) + cssVar.use($block, 'headerHeight'));
    z-index: 1;
    vertical-align: middle;
    color: cssVar.use($block, 'colorText');
    background-color: color.$gray-100;
    border-top: 1px solid cssVar.use($block, 'colorBorderInline');
    border-bottom: 1px solid cssVar.use($block, 'colorBorderInline');
    padding: cssVar.use($block, 'cellPadding');
  }

  &-headContent {
    width: 100%;
  }
}
</style>
