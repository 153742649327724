<template>
  <div class="PmStatusDotPure" :class="classes">
    <span class="PmStatusDotPure-dot" aria-hidden="true"></span>
    <span v-if="isLabelVisible && label" class="PmStatusDotPure-label">
      {{ label }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmStatusDotPure'

export const propTypes = {
  type: {
    allowed: [
      'job',
      'resourceAllocation',
      'resourceDay',
      'travel',
      'hotel',
      'resourceRequest',
      'resourceRequestFeedback',
      'webfleet',
    ],
  },

  jobStatus: {
    allowed: Object.values(STATUS),
  },

  resourceAllocationStatus: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  },

  resourceDayStatus: {
    allowed: Object.values(RESOURCE_STATUS),
  },

  travelStatus: {
    allowed: Object.values(STATUS_TRAVEL_AND_HOTEL),
  },

  hotelStatus: {
    allowed: Object.values(STATUS_TRAVEL_AND_HOTEL),
  },

  resourceRequestFeedbackStatus: {
    allowed: Object.values(STATUS_RESOURCE_REQUEST_FEEDBACK),
  },

  size: {
    allowed: ['text', 'none'],
    default: 'text',
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef, computed } from 'vue'
import Color from 'color'

import {
  type JobStatus,
  type ResourceAllocationStatus,
  STATUS,
  STATUS_RESOURCE_ALLOCATION,
  RESOURCE_STATUS,
  type ResourceStatus,
  STATUS_TRAVEL_AND_HOTEL,
  type StatusTravelAndHotel,
  STATUS_RESOURCE_REQUEST_FEEDBACK,
  type StatusResourceRequestFeedback,
  type StatusResourceRequest,
} from '@/constants/persoplan'

import { useJobStatus } from '@/composition/useJobStatus'
import { useResourceAllocationStatus } from '@/composition/useResourceAllocationStatus'
import { useResourceDayStatus } from '@/composition/useResourceDayStatus'
import { useTravelAndHotelStatus } from '@/composition/useTravelAndHotelStatus'
import { useResourceRequestStatus } from '@/composition/useResourceRequestStatus'
import { useResourceRequestFeedbackStatus } from '@/composition/useResourceRequestFeedbackStatus'
import {
  useWebfleetStatus,
  type WebfleetStatus,
} from '@/composition/useWebfleetStatus'

export interface Props {
  type: (typeof propTypes.type.allowed)[number]
  size?: (typeof propTypes.size.allowed)[number]
  jobStatus?: JobStatus
  resourceAllocationStatus?: ResourceAllocationStatus
  resourceDayStatus?: ResourceStatus
  travelStatus?: StatusTravelAndHotel
  hotelStatus?: StatusTravelAndHotel
  resourceRequestFeedbackStatus?: StatusResourceRequestFeedback
  resourceRequestStatus?: StatusResourceRequest
  webfleetStatus?: WebfleetStatus
  withBorder?: boolean
  label?: string
  isLabelVisible?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: propTypes.size.default,
})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const statusJob = useJobStatus({
  status: toRef(props, 'jobStatus'),
})

const statusResourceAllocation = useResourceAllocationStatus({
  status: toRef(props, 'resourceAllocationStatus'),
})

const statusResourceDay = useResourceDayStatus({
  status: toRef(props, 'resourceDayStatus'),
})

const statusTravel = useTravelAndHotelStatus({
  status: toRef(props, 'travelStatus'),
})

const statusHotel = useTravelAndHotelStatus({
  status: toRef(props, 'hotelStatus'),
})

const statusResourceRequest = useResourceRequestStatus({
  status: toRef(props, 'resourceRequestStatus'),
})

const statusResourceRequestFeedback = useResourceRequestFeedbackStatus({
  status: toRef(props, 'resourceRequestFeedbackStatus'),
})

const statusWebfleet = useWebfleetStatus({
  status: toRef(props, 'webfleetStatus'),
})

const status = computed(() => {
  if (props.type === 'job') {
    return statusJob
  }

  if (props.type === 'resourceAllocation') {
    return statusResourceAllocation
  }

  if (props.type === 'resourceDay') {
    return statusResourceDay
  }

  if (props.type === 'travel') {
    return statusTravel
  }

  if (props.type === 'hotel') {
    return statusHotel
  }

  if (props.type === 'resourceRequest') {
    return statusResourceRequest
  }

  if (props.type === 'resourceRequestFeedback') {
    return statusResourceRequestFeedback
  }

  if (props.type === 'webfleet') {
    return statusWebfleet
  }

  throw new Error('no status for this type defined')
})

const color = computed(() => {
  return status.value.color.value
})

const colorBorder = computed(() => {
  if (!color.value) return

  const modifiedColor = Color(color.value).darken(0.25)
  return modifiedColor
})

const classes = computed(() => {
  return {
    ...status.value.classes.value,
    [`${COMPONENT_NAME}--sizeText`]: props.size === 'text',
    [`${COMPONENT_NAME}--sizeNone`]: props.size === 'none',
    [`${COMPONENT_NAME}--withBorder`]: props.withBorder === true,
    [`${COMPONENT_NAME}--withLabel`]: !!label.value,
  }
})

const label = computed(() => {
  if ('label' in status.value) {
    return status.value.label.value
  }

  return props.label
})
</script>

<style lang="scss">
.PmStatusDotPure {
  $block: &;

  display: inline-flex;
  align-items: center;

  &-dot {
    background-color: v-bind('color');
    flex-shrink: 0;
    aspect-ratio: 1;

    #{$block}--withBorder & {
      border: 1px solid v-bind('colorBorder');
    }

    #{$block}--sizeNone & {
      border-radius: 100%;
      height: 100%;
    }

    #{$block}--sizeText & {
      width: 0.75em;
      height: 0.75em;
      border-radius: 0.75em;
    }
  }

  &-label {
    #{$block}--withLabel & {
      &::before {
        content: '\00a0';
      }
    }
  }
}
</style>
