import { computed, ref } from 'vue'
import {
  useQuery,
  useMutation,
  provideApolloClient,
} from '@vue/apollo-composable'
import { isNil } from 'lodash-es'
import type { Get } from 'type-fest'

import { apolloClient } from '@/vue-apollo'
import { useXState } from '@/composition/useXState'
import { NotificationsState } from '@/store/notifications/NotificationsState'
import { parseServerDateString } from '@/utilities/date'
import { throwFriendlyError } from '@/functional/friendlyErrors'
import {
  NotificationsDocument,
  type NotificationsQuery,
  UpdateNotificationStatusDocument,
  NotificationStatus,
  MarkAllNotificationsAsReadDocument,
  NotificationType,
} from '@/../generated/graphql'
import {
  type NotificationResourceRequestFeedbackStateUpdated,
  normalizeNotificationResourceRequestFeedbackStateUpdated,
  filterNotificationResourceRequestFeedbackStateUpdated,
} from '@/store/notifications/notificationTypes/resourceRequest'
import {
  normalizeNotificationLeaveRequestCreated,
  type NotificationLeaveRequestCreated,
  normalizeNotificationLeaveRequestUpdated,
  type NotificationLeaveRequestUpdated,
  normalizeNotificationLeaveRequestAccepted,
  type NotificationLeaveRequestAccepted,
  normalizeNotificationLeaveRequestDeclined,
  type NotificationLeaveRequestDeclined,
} from '@/store/notifications/notificationTypes/leaveRequest'
import {
  normalizeNotificationExternalServiceRequestCreated,
  type NotificationExternalServiceRequestCreated,
  normalizeNotificationExternalServiceRequestUpdated,
  type NotificationExternalServiceRequestUpdated,
  normalizeNotificationExternalServiceRequestAccepted,
  type NotificationExternalServiceRequestAccepted,
  normalizeNotificationExternalServiceRequestDeclined,
  type NotificationExternalServiceRequestDeclined,
} from '@/store/notifications/notificationTypes/externalServiceRequest'
import { appState } from '@/state/index'

export type NotificationFromQuery = NonNullable<
  Get<NotificationsQuery, 'notifications[0]'>
>

export type NotificationBase = {
  id: number
  isUnread: boolean
  date: Date
}

export type Notification = NotificationBase &
  (
    | NotificationResourceRequestFeedbackStateUpdated
    | NotificationLeaveRequestCreated
    | NotificationLeaveRequestUpdated
    | NotificationLeaveRequestAccepted
    | NotificationLeaveRequestDeclined
    | NotificationExternalServiceRequestCreated
    | NotificationExternalServiceRequestUpdated
    | NotificationExternalServiceRequestAccepted
    | NotificationExternalServiceRequestDeclined
  )

const showRead = ref(false)

const notificationsQuery = provideApolloClient(apolloClient)(() =>
  useQuery(
    NotificationsDocument,
    () => ({
      includeRead: showRead.value,
    }),
    () => {
      const isEnabled = appState.state.value.matches('ready')

      return {
        enabled: isEnabled,
        notifyOnNetworkStatusChange: true,
        /**
         * When this writes to the cache it disturbs an open edit popover, probably
         * because some values are not requested or it does not write to the cache properly
         * @see https://gitlab.dev.innovation-agents.de/pro-musik/frontend/-/issues/1025
         */
        fetchPolicy: 'no-cache',
      }
    }
  )
)

const isLoading = computed(() => notificationsQuery.loading.value)

async function getNotifications() {
  await notificationsQuery.refetch()
}

const xstate = useXState(NotificationsState, {
  services: {
    getNotifications: getNotifications,
  },
})

/**
 * This should not be used directly, use notificationsFiltered instead!
 */
const notifications = computed(() => {
  return notificationsQuery.result.value?.notifications
})

/**
 * Filter out specific notifications which should not be displayed anywhere
 * This is for example the case for notifications which should be deleted but are not yet
 */
const notificationsFiltered = computed(() => {
  if (!notifications.value) return

  const result = notifications.value.filter((notification) => {
    if (!notification) return false

    if (
      notification.type === NotificationType.ResourceRequestFeedbackStateUpdated
    ) {
      return filterNotificationResourceRequestFeedbackStateUpdated(notification)
    }

    if (notification.type === NotificationType.LeaveRequestCreated) return true
    if (notification.type === NotificationType.LeaveRequestUpdated) return true
    if (notification.type === NotificationType.LeaveRequestAccepted) return true
    if (notification.type === NotificationType.LeaveRequestDeclined) return true

    if (notification.type === NotificationType.ExternalServiceRequestCreated)
      return true
    if (notification.type === NotificationType.ExternalServiceRequestUpdated)
      return true
    if (notification.type === NotificationType.ExternalServiceRequestAccepted)
      return true
    if (notification.type === NotificationType.ExternalServiceRequestDeclined)
      return true
  })

  return result
})

const hasUnreadNotifications = computed(() => {
  if (!notificationsFiltered.value) return false

  const unreadNotifications = notificationsFiltered.value.filter(
    (notification) => {
      if (!notification) return false
      if (notification.state === 'unread') return true

      return false
    }
  )

  return unreadNotifications.length > 0
})

/**
 * Save Read/Unread Status
 */
const updateNotificationStatusMutation = provideApolloClient(apolloClient)(() =>
  useMutation(UpdateNotificationStatusDocument, () => ({
    refetchQueries: ['Notifications'],
  }))
)

function markAsRead(id: number) {
  // If notification is already read, do nothing
  if (notificationsNormalized.value?.get(id)?.isUnread === false) {
    return
  }

  return updateNotificationStatus(id, NotificationStatus.read)
}

function markAsUnread(id: number) {
  // If notification is already unread, do nothing
  if (notificationsNormalized.value?.get(id)?.isUnread === true) {
    return
  }

  return updateNotificationStatus(id, NotificationStatus.unread)
}

async function updateNotificationStatus(
  id: number,
  status: NotificationStatus
) {
  try {
    await updateNotificationStatusMutation.mutate({
      id: id,
      status: status,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}

/**
 * Mark all as read
 */
const markAllAsReadMutation = provideApolloClient(apolloClient)(() =>
  useMutation(MarkAllNotificationsAsReadDocument, () => ({
    refetchQueries: ['Notifications'],
  }))
)

async function markAllAsRead() {
  try {
    await markAllAsReadMutation.mutate()

    // Refetch query to reflect new state
    notificationsQuery.refetch()
  } catch (error) {
    throwFriendlyError(error)
  }
}

/**
 * Normalize Notifications
 */
const notificationsNormalized = computed(() => {
  if (!notificationsFiltered.value) return
  const notificationsNormalized = new Map<number, Notification>()

  notificationsFiltered.value.forEach((notification) => {
    if (!notification) return

    const notificationNormalized = normalizeNotification(notification)
    if (!notificationNormalized) return

    // Merge with base properties
    notificationsNormalized.set(notification.id, {
      ...normalizeNotificationBase(notification),
      ...notificationNormalized,
    })
  })

  return notificationsNormalized
})

function normalizeNotification(notification: NotificationFromQuery) {
  if (!notification) return

  if (
    notification.type === NotificationType.ResourceRequestFeedbackStateUpdated
  )
    return normalizeNotificationResourceRequestFeedbackStateUpdated(
      notification
    )

  if (notification.type === NotificationType.LeaveRequestCreated)
    return normalizeNotificationLeaveRequestCreated(notification)

  if (notification.type === NotificationType.LeaveRequestUpdated)
    return normalizeNotificationLeaveRequestUpdated(notification)

  if (notification.type === NotificationType.LeaveRequestAccepted)
    return normalizeNotificationLeaveRequestAccepted(notification)

  if (notification.type === NotificationType.LeaveRequestDeclined)
    return normalizeNotificationLeaveRequestDeclined(notification)

  if (notification.type === NotificationType.ExternalServiceRequestCreated)
    return normalizeNotificationExternalServiceRequestCreated(notification)

  if (notification.type === NotificationType.ExternalServiceRequestUpdated)
    return normalizeNotificationExternalServiceRequestUpdated(notification)

  if (notification.type === NotificationType.ExternalServiceRequestAccepted)
    return normalizeNotificationExternalServiceRequestAccepted(notification)

  if (notification.type === NotificationType.ExternalServiceRequestDeclined)
    return normalizeNotificationExternalServiceRequestDeclined(notification)

  throw new Error(
    `notificationNormalized is undefined, maybe normalization for this type (${notification.type}) is missing?`
  )
}

export function normalizeNotificationBase(
  notification: NotificationFromQuery
): NotificationBase {
  const createdDate = parseServerDateString(notification.createdAt)
  if (!createdDate) throw new Error('dateCreated is undefined')

  return {
    id: notification.id,
    date: createdDate,
    isUnread: notification.state === 'unread',
  }
}

const unreadNotificationsForResourceRequestFeedbackStateUpdated = computed(
  () => {
    if (!notificationsFiltered.value) return

    type ResourceAllocationId = number
    type NotificationId = number

    const unreadNotificationsByResourceAllocationId = new Map<
      ResourceAllocationId,
      NotificationId
    >()

    notificationsFiltered.value.forEach((notification) => {
      if (notification?.state !== 'unread') return
      if (notification.type !== 'ResourceRequestFeedbackStateUpdated') return
      if (notification.details?.__typename !== 'ResourceRequest') return

      notification.details.resourceAllocationIds?.forEach(
        (resourceAllocationId) => {
          if (isNil(resourceAllocationId)) return

          unreadNotificationsByResourceAllocationId.set(
            resourceAllocationId,
            notification.id
          )
        }
      )
    })

    return unreadNotificationsByResourceAllocationId
  }
)

function getNotificationForId(id: number) {
  if (!notificationsNormalized.value?.has(id)) return
  return notificationsNormalized.value.get(id)
}

export default {
  notifications: notificationsNormalized,
  isLoading,
  hasUnreadNotifications,
  getNotificationForId,
  markAsRead,
  markAsUnread,
  markAllAsRead,
  unreadNotificationsForResourceRequestFeedbackStateUpdated,
  getNotifications,
  xstate,
  showRead,
}
