import { clamp } from 'lodash-es'

export function map(
  value: number,
  low1: number,
  high1: number,
  low2: number,
  high2: number,
  options?: {
    clamp?: boolean
  }
) {
  let mappedValue = low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)

  if (options?.clamp) {
    const clampUpper = Math.max(low2, high2)
    const clampLower = Math.min(low2, high2)

    mappedValue = clamp(mappedValue, clampLower, clampUpper)
  }

  return mappedValue
}
