<template>
  <PmEventPure
    :name="name"
    :description="description"
    :text="text"
    :color="color"
    :is-constrained-start="isConstrainedStart"
    :is-constrained-end="isConstrainedEnd"
  />
</template>

<script>
import { startEndDate } from '@/utilities/date'
import { filterProps } from '@/utilities/props'

import PmEventPure from '@/components/persoplan/PmGeneralEvents/PmEventPure.vue'

export default {
  name: 'PmGeneralEventPure',
  components: {
    PmEventPure,
  },

  props: {
    ...filterProps(PmEventPure.props, {
      onlyUse: ['isConstrainedStart', 'isConstrainedEnd'],
    }),
    name: { type: String, required: true },
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    showDates: { type: Boolean, default: true },
    description: { type: String, default: undefined },
    company: { type: String, default: undefined },
    location: { type: String, default: undefined },
    color: { type: String, default: undefined },
  },

  computed: {
    text() {
      const text = []

      if (this.company) text.push(this.company)
      if (this.location) text.push(this.location)

      if (this.showDates) {
        text.push(startEndDate(this.startDate, this.endDate))
      }

      return text
    },
  },
}
</script>

<style lang="scss">
.PmGeneralEventPure {
  $block: &;
}
</style>
