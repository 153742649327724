<template>
  <div class="PmSectionWrapperPure">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PmSectionWrapperPure',
  components: {},
}
</script>

<style lang="scss">
.PmSectionWrapperPure {
  $block: &;

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
