import gsap from 'gsap'
import { random } from 'lodash-es'

import { map } from '@/utilities/math'

export async function jiggle(el: HTMLElement) {
  return new Promise<void>((resolve, reject) => {
    if (!el) {
      throw new Error('el is undefined')
      reject()
    }

    gsap.killTweensOf(el)

    const jiggleIntensity = calcJiggleIntensity(el)

    const timeline = gsap.timeline({
      onComplete: () => {
        resolve()
      },
    })

    const jiggle = gsap.timeline({
      defaults: { duration: 0.12, ease: 'sine.inOut' },
    })

    jiggle.to(el, {
      rotate: -jiggleIntensity,
      duration: 0.1,
    })

    const numberOfRepeats = 6
    for (let repeat = 0; repeat < numberOfRepeats; repeat += 1) {
      jiggle.to(el, {
        rotate:
          jiggleIntensity +
          random(-jiggleIntensity / 2, jiggleIntensity / 2, true),
      })

      jiggle.to(el, {
        rotate:
          -jiggleIntensity +
          random(-jiggleIntensity / 2, jiggleIntensity / 2, true),
      })
    }

    jiggle.to(el, {
      rotate: 0,
      duration: 0.1,
    })

    timeline.add(jiggle)

    timeline.to(
      el,
      {
        duration: 0.5,
        scale: 1.2,
        ease: 'back.out',
      },
      0.5
    )

    timeline.to(
      el,
      {
        duration: 0.5,
        scale: 1,
        ease: 'back.out',
      },
      1.25
    )
  })
}

function calcJiggleIntensity(el: HTMLElement) {
  const width = el.getBoundingClientRect()?.width
  if (!width) throw new Error('element not found')

  return map(width, 100, 1000, 2, 0.5, { clamp: true })
}
