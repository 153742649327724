<template>
  <div class="PmNoteIconPure">
    <PmTooltipPure>
      <button class="PmNoteIconPure-button">
        <PmIconPure
          class="PmNoteIconPure-icon"
          :name="ICONS.NOTE"
          @click="$emit('open')"
        />
      </button>

      <template #tooltip>
        <div class="PmNoteIconPure-content">
          <div class="PmNoteIconPure-note">
            <strong>{{ name }}:</strong><br />
            {{ note }}
          </div>

          <div v-if="noteAdditional" class="PmNoteIconPure-note--additional">
            <strong>{{ nameAdditional }}:</strong><br />
            {{ noteAdditional }}
          </div>

          <div v-if="canBeEdited" class="PmNoteIconPure-help">
            Klicken um die {{ name }} vollständig anzuzeigen oder zu bearbeiten
          </div>
        </div>
      </template>
    </PmTooltipPure>
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'

import PmTooltipPure from '@/components/basics/PmTooltipPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export default {
  name: 'PmNoteIconPure',
  components: {
    PmTooltipPure,
    PmIconPure,
  },

  props: {
    name: { type: String, default: 'Bemerkung' },
    nameAdditional: { type: String, default: 'weitere Bemerkung' },
    note: { type: String, default: undefined },
    noteAdditional: { type: String, default: undefined },
    canBeEdited: { type: Boolean, default: true }, // Temp prop until https://gitlab.com/pro-musik/frontend/-/issues/810 is done
  },

  emits: ['open'],

  setup() {
    return {
      ICONS,
    }
  },
}
</script>

<style lang="scss">
.PmNoteIconPure {
  $block: &;

  height: 20px;
  width: 20px;

  &-button {
    @include mixin.transition-hover(color);

    display: block;

    &:hover,
    #{$block}.is-hover & {
      color: color.$key;
    }
  }

  &-icon {
    // Empty
  }

  &-note {
    @include mixin.truncateText($lines: 10);

    white-space: pre-line;

    &--additional {
      margin-top: 8px;
    }
  }

  &-help {
    font-size: constant.$fontSize-default;
    font-weight: 500;
    color: color.$gray-500;
    grid-area: note;
    margin-top: 8px;
  }
}
</style>
