<template>
  <PmDataModalResourceRequestPure v-bind="propsResourceRequest" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmDataModalResourceRequest'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
  components: { PmDataModalResourceRequestPure },
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { isNil, uniq } from 'lodash-es'
import { watchOnce } from '@vueuse/core'

import {
  STATUS_JOB_LOOKUP,
  STATUS_PROJECT_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
} from '@/constants/persoplan'

import { parseServerDateString } from '@/utilities/date'
import { getDisplayNameOfAddress } from '@/utilities/string'
import { lookup } from '@/utilities/misc'
import notifications from '@/store/notifications/notifications'

import PmDataModalResourceRequestPure, {
  type Props as PropsDataModalResourceRequestPure,
  type ResourceAllocation,
  type Job,
} from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmDataModalResourceRequestPure.vue'

import { DataModalResourceRequestDocument } from '@/../generated/graphql'
import type { Nilable } from '@/types/misc'

export interface Props {
  /** This is in fact the resourceAllocationId */
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const resourceRequestQuery = useQuery(
  DataModalResourceRequestDocument,
  () => ({
    resourceAllocationId: props.id,
  }),
  () => ({
    // Don't update the cache, which might change the calendar view.
    fetchPolicy: 'no-cache',
    // notifyOnNetworkStatusChange is needed so $apollo.loading gets updated
    notifyOnNetworkStatusChange: true,
  })
)

const resourceRequest = computed(
  () => resourceRequestQuery.result.value?.resourceRequest
)

const isLoading = computed(() => {
  return resourceRequestQuery.loading.value === true
})

/**
 * A resource request can contain multiple resource allocations but tye all should
 * belong to the same address, job, project
 * This is a helper function to check if this is true
 */
// REFERENCE: This functions exists here aswell: wx02coi
function checkIfSingleton(type: 'project' | 'job' | 'address') {
  if (!resourceRequest.value) return
  const allIds: number[] = []

  resourceRequest.value.resourceAllocations?.forEach((resourceAllocation) => {
    if (!resourceAllocation) return
    let id: Nilable<number> = null

    if (type === 'project') {
      id = resourceAllocation.resourceFunctionAllocation?.job?.project?.id
    }

    if (type === 'job') {
      id = resourceAllocation.resourceFunctionAllocation?.job?.id
    }

    if (type === 'address') {
      id = resourceAllocation.address?.id
    }

    if (isNil(id)) return

    allIds.push(id)
  })

  // Check if same length
  if (allIds.length !== resourceRequest.value.resourceAllocations?.length) {
    throw new Error(`lengths don't match!`)
  }

  // Check if only on id
  const uniqueLength = uniq(allIds).length
  if (uniqueLength !== 1) {
    throw new Error('ids are not unique')
  }

  return true
}

const requestedAddress = computed(() => {
  if (!resourceRequest.value) return
  checkIfSingleton('address')

  const firstAddress = resourceRequest.value.resourceAllocations?.[0]?.address
  return firstAddress
})

const requestedAddressDisplayName = computed(() => {
  return getDisplayNameOfAddress(requestedAddress.value)
})

const project = computed(() => {
  if (!resourceRequest.value) return
  checkIfSingleton('project')

  const firstProject =
    resourceRequest.value.resourceAllocations?.[0]?.resourceFunctionAllocation
      ?.job?.project

  return firstProject
})

const responseMessage = computed(() => {
  let responseMessage: PropsDataModalResourceRequestPure['responseMessage'] =
    undefined

  if (resourceRequest.value?.feedback?.userState) {
    responseMessage = {
      from: requestedAddressDisplayName.value,
      body: resourceRequest.value.feedback.comment,
    }
  }

  return responseMessage
})

const resourceAllocations = computed(() => {
  if (!resourceRequest.value) return

  const result: PropsDataModalResourceRequestPure['resourceAllocations'] = []

  resourceRequest.value.resourceAllocations?.forEach((resourceAllocation) => {
    if (!resourceAllocation) return

    const resourceFunctionAllocation =
      resourceAllocation.resourceFunctionAllocation

    if (!resourceAllocation.resourceFunctionAllocation?.job?.id) {
      throw new Error('jobId is undefined')
    }

    const resultItem: ResourceAllocation = {
      id: resourceAllocation.id,
      jobId: resourceAllocation.resourceFunctionAllocation?.job?.id,
      startDate: parseServerDateString(resourceFunctionAllocation?.startDate),
      endDate: parseServerDateString(resourceFunctionAllocation?.endDate),
      title: resourceFunctionAllocation?.resourceFunction?.caption,
      status: lookup(
        resourceAllocation.resourceAllocationState?.id,
        STATUS_RESOURCE_ALLOCATION_LOOKUP
      ),
    }

    result.push(resultItem)
  })

  return result
})

const jobs = computed(() => {
  if (!resourceRequest.value) return

  const result = new Map<number, Job>()

  resourceRequest.value.resourceAllocations?.forEach((resourceAllocation) => {
    if (!resourceAllocation) return

    const job = resourceAllocation.resourceFunctionAllocation?.job
    if (!job) return

    const resultItem: Job = {
      id: job.id,
      number: job.number,
      label: job.caption,
      status: lookup(job.jobState?.id, STATUS_JOB_LOOKUP),
    }

    result.set(job.id, resultItem)
  })

  return Array.from(result.values())
})

const propsResourceRequest = computed(() => {
  const resourceRequestProps: PropsDataModalResourceRequestPure = {
    id: resourceRequest.value?.id,
    isLoading: isLoading.value,
    status: resourceRequest.value?.state,
    statusFeedback: resourceRequest.value?.feedback?.userState ?? 'unknown',
    availableStartDate: parseServerDateString(
      resourceRequest.value?.feedback?.availableFrom
    ),
    availableEndDate: parseServerDateString(
      resourceRequest.value?.feedback?.availableTo
    ),

    createdDate: parseServerDateString(resourceRequest.value?.created),
    createdBy: getDisplayNameOfAddress({
      firstName: resourceRequest.value?.createdBy?.firstName,
      surname: resourceRequest.value?.createdBy?.lastName,
    }),

    editedDate: parseServerDateString(resourceRequest.value?.updated),
    editedBy: getDisplayNameOfAddress({
      firstName: resourceRequest.value?.updatedBy?.firstName,
      surname: resourceRequest.value?.updatedBy?.lastName,
    }),

    dateOfExpire: parseServerDateString(resourceRequest.value?.dateOfExpire),

    requestMessage: {
      date: parseServerDateString(resourceRequest.value?.created),
      from: getDisplayNameOfAddress({
        firstName: resourceRequest.value?.createdBy?.firstName,
        surname: resourceRequest.value?.createdBy?.lastName,
      }),

      subject: resourceRequest.value?.title,
      body: resourceRequest.value?.message,
    },

    responseMessage: responseMessage.value,

    resource: {
      name: requestedAddressDisplayName.value,
    },

    // TODO: https://gitlab.dev.innovation-agents.de/pro-musik/frontend/-/issues/955#note_7155
    project: {
      number: project.value?.number,
      status: lookup(project.value?.projectState?.id, STATUS_PROJECT_LOOKUP),
      label: project.value?.caption,
    },

    jobs: jobs.value,
    resourceAllocations: resourceAllocations.value,
  }

  return resourceRequestProps
})

watchOnce(
  () => resourceRequest.value?.resourceAllocations,
  () => {
    resourceRequest.value?.resourceAllocations?.forEach(
      (resourceAllocation) => {
        if (!resourceAllocation) return

        const resourceAllocationID = resourceAllocation.id
        const notificationId =
          notifications.unreadNotificationsForResourceRequestFeedbackStateUpdated.value?.get(
            resourceAllocationID
          )

        if (!notificationId) return
        notifications.markAsRead(notificationId)
      }
    )
  }
)
</script>
