<template>
  <PmModalPure title="An Webfleet übertragen" :can-be-closed="false">
    <div :class="componentClass.root">
      <template v-if="isLoading">
        <PmLoadingPure size="default" />
      </template>

      <template v-if="!isLoading">
        <slot />

        <div :class="componentClass.element('status')">
          <PmKeyValueListPure>
            <PmKeyValuePure label="Status Übertragung">
              <PmStatusPillPure
                v-if="statusWebfleet"
                type="webfleet"
                :webfleet-status="statusWebfleet"
              />
            </PmKeyValuePure>
          </PmKeyValueListPure>

          <template v-if="isInPast">
            <PmAppNotificationPure
              title="Der Auftrag liegt in der Vergangenheit"
              variant="warning"
              icon="clockRewind"
            >
              Eine Übertragung an Webfleet ist deshalb nicht mehr möglich.
            </PmAppNotificationPure>
          </template>

          <template v-if="!isInPast">
            <PmAppNotificationPure
              v-if="
                statusWebfleet === 'created' || statusWebfleet === 'updated'
              "
              title="Es hat bereits eine Übertragung an Webfleet stattgefunden"
            >
              <p>
                Du kannst eine weitere Übertragung starten, falls du zum
                Beispiel die Bemerkung aktualisieren möchtest
              </p>
            </PmAppNotificationPure>

            <PmAppNotificationPure
              v-if="statusWebfleet === 'workInProgress'"
              title="Eine Übertragung an Webfleet findet gerade statt"
              variant="warning"
            >
              Sobald sie abgeschlossen ist, kannst du eine weitere Übertragung
              starten
            </PmAppNotificationPure>

            <PmAppNotificationPure
              v-if="statusWebfleet === 'aborted'"
              variant="danger"
              title="Die letzte Übertragung hat leider nicht funktioniert"
            >
              Du kannst es noch einmal versuchen. Falls es weiterhin nicht
              funktioniert, melde dich bitte über die Feedback Funktion bei uns!
            </PmAppNotificationPure>

            <PmAppNotificationPure
              v-if="statusWebfleet === 'deleted'"
              title="Der bestehende Auftrag in Webfleet wurde gelöscht"
            >
              Du kannst den Auftrag erneut an Webfleet übertragen
            </PmAppNotificationPure>
          </template>
        </div>

        <PmKeyValueListPure label="Diese Daten werden übertragen">
          <PmKeyValuePure label="Lieferadresse">
            <PmAddressPure :address="address" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Geplant">
            {{ formatToRelativeDate(orderDateTime) }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Auftragstext">
            <!-- eslint-disable-next-line vue/no-v-html This is created by the server, should be save -->
            <p v-html="orderText"></p>
          </PmKeyValuePure>

          <PmKeyValuePure label="Kontaktperson">
            <template v-if="contactPerson">
              {{ contactPerson.name }}<br />
              {{ contactPerson.phone }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Fahrzeug">
            {{ vehicle }}
          </PmKeyValuePure>
        </PmKeyValueListPure>

        <PmFormPure :disabled="isDisabled">
          <PmTextareaPure v-model:value="note" :rows="3" label="Bemerkung" />
        </PmFormPure>

        <PmAskForConfirmationPure
          v-if="
            xstate.state.value.matches('delete') &&
            !xstate.state.value.matches('delete.failed')
          "
          title="Auftrag in Webfleet löschen?"
          :is-loading="xstate.state.value.matches('delete.deleting')"
          @delete="xstate.service.value.send('CONFIRM')"
          @cancel="xstate.service.value.send('CANCEL')"
        >
          Der Auftrag kann nicht wiederhergestellt werden
        </PmAskForConfirmationPure>

        <PmErrorNotificationPure
          v-if="xstate.meta.value.error"
          class="PmMultipleResourceAllocationsDeletePure-notification"
          :message="errorMessage || xstate.meta.value.errorMessage"
          :details="errorDetails"
        />
      </template>
    </div>

    <template #footer>
      <PmButtonListPure align="right">
        <PmButtonPure
          label="Abbrechen"
          icon="close"
          :disabled="xstate.state.value.matches('send.sending')"
          @click="emit('cancel')"
        />

        <PmButtonListDividerPure />

        <PmButtonPure
          label="Auftrag in Webfleet löschen"
          icon="delete"
          variant="danger"
          :disabled="isDisabled || !canBeDeleted"
          @click="xstate.service.value.send('DELETE')"
        />

        <PmButtonPure
          label="An Webfleet übertragen"
          icon="forward"
          variant="primary"
          :disabled="isDisabled"
          :loading="xstate.state.value.matches('send.sending')"
          @click="emit('sendToWebfleet')"
        />
      </PmButtonListPure>
    </template>
  </PmModalPure>
</template>

<script setup lang="ts">
import { toRef, computed } from 'vue'
import { isBefore } from 'date-fns'

import type { WebfleetStatus } from '@/constants/persoplan'

import { useComponentClass } from '@thomasaull-shared/composables'
import { useXState } from '@/composition/useXState'
import { formatToRelativeDate } from '@/utilities/date'

import {
  PmSendToWebfleetPureState,
  type State,
} from '@/components/persoplan/PmSendToWebfleet/PmSendToWebfleetPureState'

import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmStatusPillPure from '../PmStatusPill/PmStatusPillPure.vue'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmAddressPure, {
  type Props as PropsAddressPure,
} from '@/components/basics/PmAddress/PmAddressPure.vue'
import type { Nilable } from '@/types/misc'
import PmAskForConfirmationPure from '@/components/PmAskForConfirmation/PmAskForConfirmationPure.vue'

export interface Props {
  state: State
  errorMessage?: string
  errorDetails?: string[]
  statusWebfleet?: Nilable<WebfleetStatus>
  address?: PropsAddressPure['address']
  orderText?: Nilable<string>
  orderDateTime?: Date
  contactPerson?: {
    name?: Nilable<string>
    phone?: Nilable<string>
  }
  vehicle?: Nilable<string>
}

const props = withDefaults(defineProps<Props>(), {})

const note = defineModel<string>('note')

const emit = defineEmits<{
  cancel: []
  sendToWebfleet: []
  deleteInWebfleet: []
}>()

const componentClass = useComponentClass()

const xstate = useXState(PmSendToWebfleetPureState, {
  syncStateWith: toRef(props, 'state'),
  actions: {
    emitDeleteInWebfleet: () => emit('deleteInWebfleet'),
  },
})

const isLoading = computed(() => {
  return xstate.state.value.matches('load.loading')
})

const isDisabled = computed(() => {
  if (isLoading.value) return true
  if (xstate.state.value.matches('load.failed')) return true
  if (props.statusWebfleet === 'workInProgress') return true
  if (
    xstate.state.value.matches('delete') &&
    !xstate.state.value.matches('delete.failed')
  )
    return true
  if (isInPast.value === true) return true

  return false
})

const canBeDeleted = computed(() => {
  if (props.statusWebfleet === 'created') return true
  if (props.statusWebfleet === 'updated') return true

  return false
})

const isInPast = computed(() => {
  if (!props.orderDateTime) return false
  return isBefore(props.orderDateTime, new Date())
})
</script>

<style lang="scss">
.PmSendToWebfleetPure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: var(--space-smallest);

  &-status {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
