<template>
  <div class="PmCalendarMonthPure" :style="styles">
    <div class="PmCalendarMonthPure-label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import { formatWithLocale } from '@/utilities/date'

export default {
  name: 'PmCalendarMonthPure',
  components: {},

  props: {
    date: { type: Date, required: true },
    numberOfDays: { type: Number, required: true },
  },

  computed: {
    label() {
      return formatWithLocale(this.date, 'MMMM yyyy')
    },

    styles() {
      const dayWidth = this.$store.state.cssVar.variables.dayWidth.value

      return {
        width: `${this.numberOfDays * dayWidth}px`,
      }
    },
  },
}
</script>

<style lang="scss">
.PmCalendarMonthPure {
  $block: &;

  display: flex;

  &-label {
    @include mixin.textLabel;

    position: sticky;
    left: var(--sidebarLeftWidth);
    width: auto;
    padding-left: var(--space-gutters);
    padding-right: var(--space-gutters);
    flex: none;
  }
}
</style>
