<template>
  <div class="PmCalendarWidgetWeekPure">
    <PmCalendarWidgetDayPure
      v-for="day in daysOfWeek"
      :key="day.date.getTime()"
      class="PmCalendarWidgetWeekPure-day"
      :date="day.date"
      :is-current-month="day.isCurrentMonth"
      :is-today="day.isToday"
      :is-selected="day.isSelected"
      :is-outside-of-range="day.isOutsideOfRange"
      @click="$emit('selectDate', { date: day.date })"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  eachDayOfInterval,
  endOfWeek,
  startOfWeek,
  isToday,
  isSameDay,
  isBefore,
  isAfter,
  isWithinInterval,
  startOfDay,
} from 'date-fns'

import PmCalendarWidgetDayPure from '@/components/PmCalendarWidget/PmCalendarWidgetDayPure.vue'

export interface Props {
  date: Date
  selectedDate?: Date
  startOfInterval: Date
  endOfInterval: Date
  disableBefore?: Date
  disableAfter?: Date
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'selectDate', payload: { date: Date }): void
}>()

const daysOfWeek = computed(() => {
  return eachDayOfInterval({
    start: startOfWeek(props.date, { weekStartsOn: 1 }),
    end: endOfWeek(props.date, { weekStartsOn: 1 }),
  }).map((date) => {
    const isOutsideOfRangeStart = props.disableBefore
      ? isBefore(date, startOfDay(props.disableBefore))
      : false

    const isOutsideOfRangeEnd = props.disableAfter
      ? isAfter(date, props.disableAfter)
      : false

    return {
      date,
      isCurrentMonth: isWithinInterval(date, {
        start: props.startOfInterval,
        end: props.endOfInterval,
      }),
      isToday: isToday(date),
      isSelected: props.selectedDate
        ? isSameDay(date, props.selectedDate)
        : false,
      isOutsideOfRange: isOutsideOfRangeStart || isOutsideOfRangeEnd,
    }
  })
})
</script>

<style lang="scss">
.PmCalendarWidgetWeekPure {
  $block: &;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  position: relative;
  z-index: 0;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   inset: 0;
  //   background-color: color.$gray-100;
  //   border-radius: constant.$borderRadius-default;
  //   opacity: 0;
  //   z-index: -1;
  // }

  // &:hover {
  //   &::before {
  //     opacity: 1;
  //   }
  // }
}
</style>
