<template>
  <PmCreateExternalServiceRequestPure
    :state="xstate.path.value"
    :error-message="xstate.snapshot.context.error"
    :error-details="xstate.snapshot.context.errorDetails"
    @close="emit('close')"
    @submit="
      (variables) => xstate.send({ type: 'create', variables: variables })
    "
  ></PmCreateExternalServiceRequestPure>
</template>

<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { fromPromise } from 'xstate5'
import { useStore } from 'vuex'

import { throwFriendlyError } from '@/functional/error'
import PmCreateExternalServiceRequestPure, {
  type Emits as EmitsCreateExternalServiceRequestPure,
} from '@/components/PmCreateExternalServiceRequest/PmCreateExternalServiceRequestPure.vue'
import { useXState } from '@/composition/useXState5'
import { PmCreateExternalServiceRequestState } from '@/components/PmCreateExternalServiceRequest/PmCreateExternalServiceRequestState'
import { formatToServerDateString } from '@/utilities/date'
import { CreateExternalServiceRequestDocument } from '@/../generated/graphql'

const emit = defineEmits<{
  close: []
}>()

const store = useStore()

const xstate = useXState(
  PmCreateExternalServiceRequestState.provide({
    actions: {
      showSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          title:
            'Dein Antrag auf Fremddienstleistung wurde erfolgreich eingereicht',
        })
      },
      emitClose: () => emit('close'),
    },

    actors: {
      createExternalServiceRequest: fromPromise(async ({ input }) =>
        createExternalServiceRequest(input.variables)
      ),
    },
  })
)

const createExternalServiceRequestMutation = useMutation(
  CreateExternalServiceRequestDocument,
  () => ({
    refetchQueries: ['MyRequestsList'],
  })
)

async function createExternalServiceRequest(
  variables: EmitsCreateExternalServiceRequestPure['submit']
) {
  try {
    await createExternalServiceRequestMutation.mutate({
      startDate: formatToServerDateString(variables.startDate, {
        type: 'dateTime',
      }),
      endDate: formatToServerDateString(variables.endDate, {
        type: 'dateTime',
      }),
      eventName: variables.nameOfProduction,
      client: variables.client,
      customerOfClient: variables.customerOfClient,
      location: variables.location,
      jobFunction: variables.task,
      note: variables.note,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>
