<template>
  <PmFloatingBarPure>
    <div class="PmMultipleResourceAllocationsControllerPure">
      <slot />

      <div class="PmMultipleResourceAllocationsControllerPure-title">
        {{ t('title', numberOfSelected) }}
      </div>

      <div class="PmMultipleResourceAllocationsControllerPure-actions">
        <PmButtonListPure>
          <PmButtonWithActionsPure
            label="Abbrechen"
            icon="close"
            :actions="[
              {
                id: 'clearSelection',
                icon: 'eraser',
                label: 'Auswahl aufheben',
                disabled: areButtonsDisabled,
              },
            ]"
            @click="emit('cancel')"
            @clear-selection="emit('clearSelection')"
          />

          <PmButtonPure
            label="Bearbeiten"
            variant="primary"
            :icon="ICONS.EDIT"
            :disabled="areButtonsDisabled"
            @click="emit('showUpdate')"
          />

          <template v-if="canEditResourceRequest">
            <PmButtonPure
              label="Anfragen"
              :icon="ICONS.MAIL_QUESTION"
              :disabled="areButtonsDisabled || type !== RESOURCE_TYPE.ADDRESS"
              @click="emit('showRequest')"
            />
          </template>

          <PmButtonPure
            label="Löschen"
            variant="danger"
            :icon="ICONS.DELETE"
            :disabled="areButtonsDisabled"
            @click="emit('showDelete')"
          />
        </PmButtonListPure>
      </div>
    </div>
  </PmFloatingBarPure>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsControllerPure'

export const propTypes = {
  state: {
    allowed: states,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { ICONS } from '@/constants/icons'
import { RESOURCE_TYPE, type ResourceType } from '@/constants/persoplan'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import { useXState } from '@/composition/useXState'

import {
  PmMultipleResourceAllocationsControllerPureState,
  states,
  type State,
} from '@/components/persoplan/PmMultipleResourceAllocationsController/PmMultipleResourceAllocationsControllerPureState'

import PmFloatingBarPure from '@/components/basics/PmFloatingBarPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonWithActionsPure from '@/components/basics/PmButtonWithActionsPure.vue'

export interface Props {
  numberOfSelected: number
  state: State
  type?: ResourceType
  canEditResourceRequest?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'showDelete'): void
  (event: 'showUpdate'): void
  (event: 'showRequest'): void
  (event: 'clearSelection'): void
}>()

const xstate = useXState(PmMultipleResourceAllocationsControllerPureState, {
  syncStateWith: toRef(props, 'state'),
})

const { t } = useI18n({
  messages: {
    de: {
      title:
        '{n} Ressourcen-Zuordnung ausgewählt | {n} Ressourcen-Zuordnung ausgewählt',
      text: 'Diese Zuordnung kollidiert mit einer anderen Zuordnung oder einem Status! | Diese Zuordnung kollidiert mit {n} anderen Zuordnungen oder Status!',
      buttonLabel: 'Konflikt anzeigen | Konflikte anzeigen',
    },
  },
})

const areButtonsDisabled = computed(() => {
  if (props.numberOfSelected === undefined) return true
  if (props.numberOfSelected === 0) return true

  return false
})
</script>

<style lang="scss">
.PmMultipleResourceAllocationsControllerPure {
  $block: &;

  &-title {
    // margin-bottom: 8px;
    @include typography.h4;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
