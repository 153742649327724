<template>
  <PmMultipleResourceAllocationsRequestPure
    :number-of-items="resourceAllocationsGrouped?.size ?? 0"
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :index-currently-requesting="indexCurrentlyRequesting"
    :start-date-of-job="startDateOfJob"
    :ignored-items="ignoredItems"
    @close="emit('close')"
    @confirm="
      (formData) =>
        xstate.service.value.send('REQUEST_RESOURCE_ALLOCATIONS', {
          formData: formData,
        })
    "
    @confirm-and-close="
      (formData) =>
        xstate.service.value.send({
          type: 'REQUEST_RESOURCE_ALLOCATIONS',
          closeAfterSuccess: true,
          formData: formData,
        })
    "
  >
    <template #table>
      <PmMultipleResourceAllocationsRequestGroupPure
        v-for="[id, resourceAllocationsGroup] in resourceAllocationsGrouped"
        :key="id"
        :name="resourceAllocationsGroup.name"
        :project="resourceAllocationsGroup.project"
        :state="resourceAllocationsGroup.state"
        :error-message="resourceAllocationsGroup.errorMessage"
        :error-details="resourceAllocationsGroup.errorDetails"
      >
        <PmMultipleResourceAllocationsRequestItemPure
          v-for="resourceAllocation in resourceAllocationsGroup.resourceAllocations"
          :key="resourceAllocation.id"
          v-bind="resourceAllocation"
          :state="stateForItems"
        />
      </PmMultipleResourceAllocationsRequestGroupPure>
    </template>
  </PmMultipleResourceAllocationsRequestPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsRequest'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { watchOnce } from '@vueuse/core'

import {
  type ResourceType,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
} from '@/constants/persoplan'
import { EVENT } from '@/constants/events'
import { ICONS } from '@/constants/icons'

import { useXState } from '@/composition/useXState'
import EventHub from '@/eventHub'
import { lookup } from '@/utilities/misc'
import {
  formatToServerDateString,
  parseServerDateString,
} from '@/utilities/date'
import {
  FriendlyError,
  getFriendlyErrors,
  throwFriendlyError,
} from '@/functional/friendlyErrors'

import { PmMultipleResourceAllocationsRequestState } from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequestState'

import PmMultipleResourceAllocationsRequestPure, {
  type FormData,
  type Props as PropsMultipleResourceAllocationsRequestPure,
} from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequestPure.vue'

import PmMultipleResourceAllocationsRequestItemPure, {
  type Props as PropsMultipleResourceAllocationsRequestItemPure,
} from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequestItemPure.vue'

import PmMultipleResourceAllocationsRequestGroupPure, {
  type Props as PropsMultipleResourceAllocationsRequestGroupPure,
} from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequestGroupPure.vue'

import {
  ResourceAllocationsDocument,
  CreateResourceRequestDocument,
  type ResourceAllocationsQuery,
  type CreateResourceRequestMutationVariables,
} from '@/../generated/graphql'

export interface Props {
  resourceAllocationIds?: number[]
  type?: ResourceType
  hasNoResults?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const store = useStore()

const { t } = useI18n({
  messages: {
    de: {
      successNotification:
        'Die Ressourcen-Zuordnung wurde angefragt | Die Ressourcen-Zuordnungen wurden angefragt',
    },
  },
})

const xstate = useXState(PmMultipleResourceAllocationsRequestState, {
  services: {
    // requestResourceAllocations: requestResourceAllocations,
    requestResourceAllocations: async (context, event) => {
      await createResourceRequests({ formData: event.formData })
    },
  },

  actions: {
    showSuccessNotification: () => {
      store.commit('notification/add', {
        variant: 'success',
        icon: ICONS.CHECK,
        title: t(
          'successNotification',
          props.resourceAllocationIds?.length ?? 0
        ),
      })
    },
    emitClose: () => emit('close'),
  },
})

watch(
  () => props.hasNoResults,
  () => {
    if (props.hasNoResults !== true) return
    xstate.service.value.send('NO_RESULTS')
  }
)

const resourceAllocationIdsInternal = ref<number[]>([
  ...(props.resourceAllocationIds ?? []),
])

/**
 * Only if the ids are not set initially, create a watcher to wait
 * for the first set and then stop watching the prop
 */
if (resourceAllocationIdsInternal.value.length === 0) {
  const unwatchPropResourceAllocationIds = watch(
    () => props.resourceAllocationIds,
    () => {
      if (
        !props.resourceAllocationIds ||
        props.resourceAllocationIds.length === 0
      ) {
        return
      }

      resourceAllocationIdsInternal.value = [...props.resourceAllocationIds]
      unwatchPropResourceAllocationIds()
    }
  )
}

const resourceAllocationsQuery = useQuery(
  ResourceAllocationsDocument,
  () => ({
    ids: resourceAllocationIdsInternal.value,
  }),
  () => ({
    enabled:
      resourceAllocationIdsInternal.value &&
      resourceAllocationIdsInternal.value.length > 0,
    fetchPolicy: 'no-cache',
  })
)

resourceAllocationsQuery.onResult(() => {
  xstate.service.value.send('LOADING_FINISHED')
})

type ResourceAllocation = Exclude<
  Exclude<
    ResourceAllocationsQuery['resourceAllocations'],
    null | undefined
  >[number],
  null
>

const resourceAllocations = computed(
  () => resourceAllocationsQuery.result.value?.resourceAllocations
)

const resourceAllocationsGrouped = computed(() => {
  if (!resourceAllocations.value) return

  type Group = {
    id: number
    name: PropsMultipleResourceAllocationsRequestGroupPure['name']
    project: PropsMultipleResourceAllocationsRequestGroupPure['project']
    state: PropsMultipleResourceAllocationsRequestGroupPure['state']
    errorMessage: PropsMultipleResourceAllocationsRequestGroupPure['errorMessage']
    errorDetails: PropsMultipleResourceAllocationsRequestGroupPure['errorDetails']
    groupKey: string
    resourceAllocations: ResourceAllocationNormalized[]
  }

  const groupedByAddressId = new Map<string, Group>()

  resourceAllocations.value.forEach((resourceAllocation) => {
    const addressId = resourceAllocation?.address?.id
    const vehicleId = resourceAllocation?.vehicle?.id
    const projectId =
      resourceAllocation?.resourceFunctionAllocation?.job?.project?.id

    /**
     * In theorey only resourceAllocationIds for resourceTypes which are not vehicles
     * should be passed to this component. We add an additional check to make sure. The
     * assumption is, if there's a vehicleId but no addressId it's a vehicle
     */
    const isVehicle = vehicleId && !addressId
    if (isVehicle) return

    if (!addressId)
      throw new Error('resourceAllocation.address.id is undefined')
    if (!projectId)
      throw new Error('resourceAllocation.address.id is undefined')

    const groupKey = `${projectId}.${addressId}`

    // Create entry if not present for this addressId
    if (!groupedByAddressId.has(groupKey)) {
      groupedByAddressId.set(`${projectId}.${addressId}`, {
        id: addressId,
        name: resourceAllocation.address?.displayName,
        project: {
          number:
            resourceAllocation.resourceFunctionAllocation?.job?.project?.number,
          title:
            resourceAllocation.resourceFunctionAllocation?.job?.project
              ?.caption,
        },
        groupKey: groupKey,
        state: requestStates.value.get(groupKey)?.state,
        errorMessage: requestStates.value.get(groupKey)?.errorMessage,
        errorDetails: requestStates.value.get(groupKey)?.errorDetails,
        resourceAllocations: [],
      })
    }

    const group = groupedByAddressId.get(groupKey)
    if (!group) throw new Error('group not found')

    // Normalize resourceAllocation first to figure out if a group needs to be created
    const resourceAllocationNormalized =
      normalizeResourceAllocation(resourceAllocation)

    if (!resourceAllocationNormalized) {
      throw new Error('resourceAllocationNormalized is undefined')
    }

    group.resourceAllocations.push(resourceAllocationNormalized)
  })

  return groupedByAddressId
})

type ResourceAllocationNormalized =
  PropsMultipleResourceAllocationsRequestItemPure

function normalizeResourceAllocation(
  resourceAllocation: ResourceAllocation
): ResourceAllocationNormalized | undefined {
  if (!resourceAllocation) return

  const status: ResourceAllocationNormalized['status'] = lookup(
    resourceAllocation.resourceAllocationState?.id,
    STATUS_RESOURCE_ALLOCATION_LOOKUP
  )

  /**
   * Figoure out reason for ignore
   */
  let resonForIgnore: ResourceAllocationNormalized['reasonForIgnore'] =
    undefined

  if (status !== 'considered') {
    resonForIgnore = 'statusIsNotConsidered'
  }

  const hasResourceRequest = resourceAllocation.resourceRequest?.id
    ? true
    : false

  if (hasResourceRequest) {
    resonForIgnore = 'resourceAllocationExistsAlready'
  }

  const appointments =
    resourceAllocation.resourceFunctionAllocation?.jobAppointments?.reduce<
      ResourceAllocationNormalized['appointments']
    >((result, jobAppointment) => {
      if (!jobAppointment) return result

      result?.push(jobAppointment.caption ?? 'n/a')
      return result
    }, [])

  return {
    id: resourceAllocation.id,
    status: status,
    project:
      resourceAllocation.resourceFunctionAllocation?.job?.project?.caption,
    job: resourceAllocation.resourceFunctionAllocation?.job?.caption,
    appointments: appointments,
    resourceFunction:
      resourceAllocation.resourceFunctionAllocation?.resourceFunction?.caption,
    reasonForIgnore: resonForIgnore,
  }
}

const resourceAllocationsNormalized = computed(() => {
  if (!resourceAllocations.value) return

  const data: ResourceAllocationNormalized[] = []

  resourceAllocations.value.forEach((resourceAllocation) => {
    if (!resourceAllocation) return

    const resourceAllocationNormalized =
      normalizeResourceAllocation(resourceAllocation)

    if (!resourceAllocationNormalized) {
      throw new Error('resourceAllocationNormalized is undefined')
    }

    data.push(resourceAllocationNormalized)
  })

  return data
})

const ignoredItems = computed(() => {
  const result: PropsMultipleResourceAllocationsRequestPure['ignoredItems'] = {
    statusIsNotConsidered: resourceAllocationsNormalized.value?.filter(
      (item) => item.reasonForIgnore === 'statusIsNotConsidered'
    ).length,

    resourceAllocationExistsAlready:
      resourceAllocationsNormalized.value?.filter(
        (item) => item.reasonForIgnore === 'resourceAllocationExistsAlready'
      ).length,
  }

  return result
})

const stateForItems = computed(
  // <PropsMultipleResourceAllocationsRequestItemPure['state']>
  () => {
    // if (xstate.state.value.matches('request.requesting')) return 'requesting'
    // if (xstate.state.value.matches('request.failed')) return 'error'
    // if (xstate.state.value.matches('request.success')) return 'success'
    // if (xstate.state.value.matches('request.successAndClose')) return 'success'

    return undefined
  }
)

/**
 * Figure out if startDateOfJob should be passed
 * Should only apply if a single job is used
 */

const jobStartDates = computed(() => {
  if (!resourceAllocations.value) return

  type JobId = number
  const jobStartDates = new Map<JobId, Date>()

  resourceAllocations.value.forEach((resourceAllocation) => {
    if (!resourceAllocation?.resourceFunctionAllocation?.job) return

    const job = resourceAllocation?.resourceFunctionAllocation.job
    const startDate = parseServerDateString(job.startDate)
    if (!startDate) return

    jobStartDates.set(job.id, startDate)
  })

  return jobStartDates
})

const startDateOfJob = computed(() => {
  if (!jobStartDates.value) return
  if (jobStartDates.value.size > 1) return

  const firstJobStartDate = Array.from(jobStartDates.value.values())[0]
  return firstJobStartDate
})

/**
 * Update Data
 */
const requestStates = ref(
  new Map<
    string,
    {
      state: PropsMultipleResourceAllocationsRequestGroupPure['state']
      errorMessage?: PropsMultipleResourceAllocationsRequestGroupPure['errorMessage']
      errorDetails?: PropsMultipleResourceAllocationsRequestGroupPure['errorDetails']
    }
  >()
)

const indexCurrentlyRequesting = ref<number>(0)
const hasErrors = ref(false)

async function createResourceRequests({ formData }: { formData: FormData }) {
  if (!resourceAllocationsGrouped.value) {
    throw new Error('No resourceAllocations to request')
  }

  // Clear updatStates
  requestStates.value.clear()

  const generalVariables = {
    dateOfExpire: formData.dateOfExpire
      ? formatToServerDateString(formData.dateOfExpire)
      : undefined,
    subject: formData.subject,
    message: formData.message,
  }

  // Create items to iterate over
  const items: {
    resourceAllocationIds: number[]
    groupKey: string
  }[] = []

  resourceAllocationsGrouped.value.forEach((resourceAllocationsGroup) => {
    if (!resourceAllocationsGroup)
      throw new Error('resourceAllocationsGroup is undefined')

    const resourceAllocationIds: number[] = []

    resourceAllocationsGroup.resourceAllocations.forEach(
      (resourceAllocation) => {
        // Skip resourceAllocations which should be ignored
        const shouldBeIgnored = resourceAllocation.reasonForIgnore
          ? true
          : false
        if (shouldBeIgnored) return

        resourceAllocationIds.push(resourceAllocation.id)
      }
    )

    if (!resourceAllocationIds.length) return

    items.push({
      resourceAllocationIds,
      groupKey: resourceAllocationsGroup.groupKey,
    })
  })

  if (!items.length) throw new Error('No resource allocations to request')

  indexCurrentlyRequesting.value = 0

  for (const item of items) {
    indexCurrentlyRequesting.value = indexCurrentlyRequesting.value + 1

    try {
      await createResourceRequest(
        {
          resourceAllocationIds: item.resourceAllocationIds,
          ...generalVariables,
        },
        item.groupKey
      )
    } catch (error) {
      hasErrors.value = true
    }
  }

  // Update Calendar
  EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)

  // Throw general error, when there was an error on one of the items
  if (hasErrors.value === true) {
    throw new FriendlyError({
      message: 'Es gab einen oder mehrere Fehler beim Anfragen der Ressourcen',
    })
  }
}

const createResourceRequestMutation = useMutation(CreateResourceRequestDocument)

async function createResourceRequest(
  mutationVariables: CreateResourceRequestMutationVariables,
  groupKey: string
) {
  requestStates.value.set(groupKey, { state: 'requesting' })

  try {
    await createResourceRequestMutation.mutate({
      resourceAllocationIds: mutationVariables.resourceAllocationIds,
      dateOfExpire: mutationVariables.dateOfExpire,
      subject: mutationVariables.subject,
      message: mutationVariables.message,
    })

    // await new Promise((resolve) => setTimeout(resolve, 2_000))

    // if (groupKey === '39964.10104') {
    //   throw new Error('random error')
    // }

    requestStates.value.set(groupKey, { state: 'success' })
  } catch (error) {
    const friendlyError = getFriendlyErrors(error)

    requestStates.value.set(groupKey, {
      state: 'error',
      errorMessage: friendlyError?.message,
      errorDetails: friendlyError?.details,
    })
    throwFriendlyError(error)
  }

  // Update Calendar
  EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
}
</script>
