import { get, cloneDeep } from 'lodash-es'

import { settingsToPersist } from '@/store/plugins/settingsConfig'
import store from '@/store'

export function getSettingsDataToSave(manualSettings = {}) {
  const state = cloneDeep(store.state)
  const settings = {}

  Object.keys(settingsToPersist).forEach((statePath) => {
    settings[statePath] = get(state, statePath)
  })

  Object.keys(manualSettings).forEach((statePath) => {
    settings[statePath] = manualSettings[statePath]
  })

  return settings
}
