<template>
  <PmCheckboxPure v-model:value="valueNormalized" :note="note">
    <slot />
  </PmCheckboxPure>
</template>

<script>
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'

export default {
  name: 'PmViewEditorCheckbox',
  components: {
    PmCheckboxPure,
  },

  props: {
    value: { type: Boolean, default: undefined },
    attribute: { type: String, required: true },
    note: { type: String, default: undefined },
  },

  emits: ['update'],

  computed: {
    valueNormalized: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update', {
          attribute: this.attribute,
          value: value,
        })
      },
    },
  },
}
</script>
