<template>
  <span class="PmDatePure" :title="dateFull">
    {{ dateRelative }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmDatePure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { formatWithLocale, formatToRelativeDate } from '@/utilities/date'

import { FORMAT_DATETIME_DEFAULT } from '@/constants/persoplan'

export interface Props {
  date?: Date
  type?: 'relative'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'relative',
})

const dateFull = computed(() => {
  return formatWithLocale(props.date, FORMAT_DATETIME_DEFAULT)
})

const dateRelative = computed(() => {
  return formatToRelativeDate(props.date)
})
</script>
