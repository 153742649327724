<template>
  <div v-if="resource" class="DataModalResource">
    <PmTabNavigationPure
      v-model:selected-tab-id="selectedTabId"
      class="DataModalResource-tabs"
      :expanded="true"
      :tabs="tabs"
    />

    <table v-if="selectedTabId === 'details'">
      <tbody>
        <div class="DataModalResource-content">
          <DataModalRow v-if="IS_DEVELOPMENT" label="Id" variant="debug">
            {{ resource.id }}
          </DataModalRow>

          <DataModalRow label="Resource">
            {{ resource.resourceFunction.caption }}
          </DataModalRow>

          <DataModalRow label="Typ">
            {{ resource.resourceFunction.resourceType.caption }}
          </DataModalRow>

          <DataModalRow label="Anzahl">{{ resource.quantity }}</DataModalRow>

          <DataModalRow label="Start">
            {{ startDate }}
          </DataModalRow>

          <DataModalRow label="Ende">
            {{ endDate }}
          </DataModalRow>

          <DataModalRow label="Erstellt">
            {{ created }}
          </DataModalRow>

          <DataModalRow label="Geändert">
            <template v-if="resource.updated">
              {{ updated }}
            </template>
          </DataModalRow>

          <!-- Does not gets updated, when settings change -->
          <!-- <DataModalRow label="Als fertig markiert">
            {{ timesScheduled }}
          </DataModalRow> -->

          <DataModalRow label="Projekt">
            <PmPillPure :label="resource.job.project.number" />

            <button
              @click="
                $eventHub.$emit(EVENT.DATA_MODAL_SHOW, {
                  type: DATA_MODAL_TYPE.PROJECT,
                  id: resource.job.project.id,
                })
              "
            >
              {{ resource.job.project.caption }}
            </button>
          </DataModalRow>

          <DataModalRow label="Job">
            <PmPillPure :label="resource.job.number" />

            <button
              @click="
                $eventHub.$emit(EVENT.DATA_MODAL_SHOW, {
                  type: DATA_MODAL_TYPE.JOB,
                  id: resource.job.id,
                })
              "
            >
              {{ resource.job.caption }}
            </button>
          </DataModalRow>

          <DataModalRow label="Termin">
            <template v-if="resource.jobAppointment">
              <button
                @click="
                  $eventHub.$emit(EVENT.DATA_MODAL_SHOW, {
                    type: DATA_MODAL_TYPE.APPOINTMENT,
                    id: resource.jobAppointment.id,
                  })
                "
              >
                {{ resource.jobAppointment.caption }}
              </button>
            </template>
            <template v-else>– ohne Termin –</template>
          </DataModalRow>

          <DataModalRow label="Ressourcen">
            <ul class="DataModal-list">
              <li
                v-for="resourceAllocation in resource.resourceAllocations"
                :key="resourceAllocation.id"
              >
                <button
                  @click="
                    $eventHub.$emit(EVENT.DATA_MODAL_SHOW, {
                      type: DATA_MODAL_TYPE.RESOURCE_ALLOCATION,
                      id: resourceAllocation.id,
                    })
                  "
                >
                  <template v-if="resourceAllocation.address">
                    {{ getDisplayNameOfAddress(resourceAllocation.address) }}
                    ({{ resourceAllocation.resourceAllocationState.caption }})
                  </template>

                  <template v-if="resourceAllocation.vehicle">
                    {{ resourceAllocation.vehicle.caption }}
                    <template
                      v-if="resourceAllocation.resourceAllocationDriver"
                    >
                      @{{
                        resourceAllocation.resourceAllocationDriver.address
                          .displayName
                      }}
                    </template>
                    ({{ resourceAllocation.resourceAllocationState.caption }})
                  </template>
                </button>
              </li>
            </ul>
          </DataModalRow>
        </div>
      </tbody>
    </table>

    <div v-if="selectedTabId === 'settings'" class="DataModalResource-settings">
      <PmDataModalResourceSettings :resource-id="id" />
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash-es'

import { FORMAT_DATETIME_DEFAULT, DATA_MODAL_TYPE } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import { formatWithLocale, parseServerDateString } from '@/utilities/date'
import { getDisplayNameOfAddress } from '@/utilities/string'
import { IS_DEVELOPMENT } from '@/constants/general'

import DataModalRow from '@/components/persoplan/DataModal/DataModalRow.vue'
// import DataModalLog from '@/components/persoplan/DataModal/DataModalLogs'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmTabNavigationPure from '@/components/basics/PmTabNavigation/PmTabNavigationPure.vue'
import PmDataModalResourceSettings from '@/components/persoplan/DataModal/DataModalResource/PmDataModalResourceSettings.vue'

import RESOURCE_DETAILS from '@/components/persoplan/DataModal/DataModalResourceDetailsQuery.graphql'

export default {
  name: 'DataModalResource',
  components: {
    DataModalRow,
    // DataModalLog,
    PmPillPure,
    PmTabNavigationPure,
    PmDataModalResourceSettings,
  },

  props: {
    id: { type: Number, required: true },
  },

  setup() {
    return {
      DATA_MODAL_TYPE,
      EVENT,
      IS_DEVELOPMENT,

      getDisplayNameOfAddress,
    }
  },

  data() {
    return {
      resource: undefined,
      tabs: [
        { id: 'details', label: 'Details' },
        { id: 'settings', label: 'Einstellungen' },
      ],
      selectedTabId: 'details',
    }
  },

  computed: {
    startDate() {
      const date = parseServerDateString(this.resource.startDate)
      return formatWithLocale(date, FORMAT_DATETIME_DEFAULT)
    },

    endDate() {
      const date = parseServerDateString(this.resource.endDate)
      return formatWithLocale(date, FORMAT_DATETIME_DEFAULT)
    },

    created() {
      const date = parseServerDateString(this.resource.created)
      return formatWithLocale(date, FORMAT_DATETIME_DEFAULT)
    },

    updated() {
      const date = parseServerDateString(this.resource.updated)
      return formatWithLocale(date, FORMAT_DATETIME_DEFAULT)
    },

    timesScheduled() {
      if (isNil(this.resource?.timesScheduled)) return
      return this.resource.timesScheduled === true ? 'Ja' : 'Nein'
    },
  },

  apollo: {
    resource: {
      query: RESOURCE_DETAILS,
      // Don't update the cache, which might change the calendar view.
      // notifyOnNetworkStatusChange is needed so $apollo.loading gets updated
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      variables() {
        return {
          id: this.id,
        }
      },
    },
  },
}
</script>

<style lang="scss">
.DataModalResource {
  $block: &;

  &-tabs {
    margin-bottom: 16px;
  }
}
</style>
