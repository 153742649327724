<template>
  <div
    ref="elRoot"
    class="PmResourceTimeline"
    data-type="resourceTimeline"
    :data-id="id"
    :data-resource-type="type"
  >
    <PmResourceTimelinePure
      :container-start-date="startDate"
      :container-end-date="endDate"
      :time-indicators="timeIndicators"
      :backgrounds="resourceDayStatus"
      :is-highlighted="isHighlighted"
      :highlighted-date="normalizedHighlightedDate"
      :label="label"
      @show-details="
        (event) =>
          showDetails({
            date: event.date,
            popoverElement: event.popoverElement,
          })
      "
      @highlight="store.commit('persoplan/setTimelineHighlightedId', id)"
      @un-highlight="store.commit('persoplan/clearTimelineHighlightedId')"
    />

    <portal to="resourceDayPopover">
      <PmResourceDay
        v-if="detailVisible"
        :key="keyPopover"
        :address-id="id"
        :vehicle-id="id"
        :date="detailDate"
        :type="type"
        :popover-element="detailPopoverElement"
        @close="closeDetails"
      />
    </portal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceTimeline'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import {
  isSameDay,
  isBefore,
  isAfter,
  differenceInMinutes,
  eachDayOfInterval,
} from 'date-fns'
import { orderBy } from 'lodash-es'

import {
  RESOURCE_TYPE,
  RESOURCE_STATUS,
  RESOURCE_STATUS_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  type ResourceStatus,
  type ResourceType,
  type ResourceDayItemType,
  type ResourceAllocationStatus,
} from '@/constants/persoplan'

import {
  startDateForServer,
  endDateForServer,
  parseServerDateString,
  formatWithLocale,
} from '@/utilities/date'

import { useResourceTimelines } from '@/pinia/resourceTimelines'
import { shouldBeVisibleInFreelancerArea } from '@/functional/freelancer'
import { getNameAbbreviation } from '@/utilities/string'
import { useCachedQuery } from '@/composition/useCachedQuery'
import { lookup, notEmpty, hasNoNilProperties } from '@/utilities/misc'
import { scrollToElement } from '@/utilities/scroll'

import {
  getResourceDayStatusForResourceAllocation,
  getResourceDayStatus,
} from '@/components/persoplan/PmResourceDay/useResourceDayShared'

import PmResourceTimelinePure from '@/components/persoplan/PmResourceTimeline/PmResourceTimelinePure.vue'
import PmResourceDay from '@/components/persoplan/PmResourceDay/PmResourceDay.vue'

import {
  ResourceTimelineAddressDocument,
  ResourceTimelineFreelancerDocument,
  ResourceTimelineVehicleDocument,
  AddressDocument,
  VehicleDocument,
} from '@/../generated/graphql'

export interface Props {
  id: number
  type: ResourceType
  startDate: Date
  endDate: Date
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()

const resourceTimelines = useResourceTimelines()
onMounted(() => {
  resourceTimelines.register({
    id: props.id,
    type: props.type,
    scrollIntoView: scrollIntoView,
  })
})

onBeforeUnmount(() => {
  resourceTimelines.unregister({
    id: props.id,
    type: props.type,
  })
})

const detailVisible = ref(false)
const detailPopoverElement = ref()
const detailDate = ref()
const elRoot = ref<HTMLElement>()

const resourceTimelineVariables = computed(() => ({
  startDate: startDateForServer(props.startDate),
  endDate: endDateForServer(props.endDate),
}))

const resourceTimelineAddressQuery = useCachedQuery(
  ResourceTimelineAddressDocument,
  resourceTimelineVariables,
  () => ({
    enabled: props.type === RESOURCE_TYPE.ADDRESS,
  })
)

const resourceTimelineAddress = computed(() =>
  resourceTimelineAddressQuery.result.value?.resourceTimeline?.filter(notEmpty)
)

const resourceTimelineFreelancerQuery = useCachedQuery(
  ResourceTimelineFreelancerDocument,
  resourceTimelineVariables,
  () => ({
    enabled: props.type === RESOURCE_TYPE.FREELANCER,
  })
)

const resourceTimelineFreelancer = computed(() =>
  resourceTimelineFreelancerQuery.result.value?.resourceTimeline?.filter(
    notEmpty
  )
)

const resourceTimelineVehicleQuery = useCachedQuery(
  ResourceTimelineVehicleDocument,
  resourceTimelineVariables,
  () => ({
    enabled: props.type === RESOURCE_TYPE.VEHICLE,
  })
)

const resourceTimelineVehicle = computed(() =>
  resourceTimelineVehicleQuery.result.value?.resourceTimeline?.filter(notEmpty)
)

const resourceTimeline = computed(() => {
  if (props.type === RESOURCE_TYPE.ADDRESS) {
    return resourceTimelineAddress.value
  }

  if (props.type === RESOURCE_TYPE.FREELANCER) {
    return resourceTimelineFreelancer.value
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    return resourceTimelineVehicle.value
  }

  return undefined
})

const addressQuery = useCachedQuery(
  AddressDocument,
  () => ({
    id: props.id,
  }),
  () => ({
    enabled:
      props.type === RESOURCE_TYPE.ADDRESS ||
      props.type === RESOURCE_TYPE.FREELANCER,
  })
)

const address = computed(() => {
  if (addressQuery.result.value?.addresses?.[0]?.__typename !== 'Address') {
    return undefined
  }

  return addressQuery.result.value.addresses[0]
})

const vehicleQuery = useCachedQuery(
  VehicleDocument,
  () => ({
    id: props.id,
  }),
  () => ({
    enabled: props.type === RESOURCE_TYPE.VEHICLE,
  })
)

const vehicle = computed(() => vehicleQuery.result.value?.vehicle)

const normalizedHighlightedDate = computed(() => {
  if (!detailVisible.value) return
  return detailDate.value
})

const itemsOfAddress = computed(() => {
  return resourceTimelineAddress.value?.filter((item) => {
    if (
      item?.__typename === 'ResourceAllocation' ||
      item?.__typename === 'ResourceState'
    ) {
      return item.address?.id === props.id
    }

    if (item?.__typename === 'Event') {
      return item.relatedAddresses.includes(props.id.toString())
    }
  })
})

const itemsOfFreelancer = computed(() => {
  const itemsOfId = resourceTimelineFreelancer.value?.filter((item) => {
    if (
      item?.__typename === 'ResourceAllocation' ||
      item?.__typename === 'ResourceState' ||
      item?.__typename === 'CalendarEvent'
    ) {
      return item.address?.id === props.id
    }

    if (item?.__typename === 'Event') {
      return item.relatedAddresses.includes(props.id.toString())
    }
  })

  const visibleItems = itemsOfId?.filter((item) => {
    // TODO: This is done multiple times, here and in filteredItems?
    return shouldBeVisibleInFreelancerArea(item)
  })

  return visibleItems
})

const itemsOfVehicle = computed(() => {
  return resourceTimelineVehicle.value?.filter((item) => {
    if (
      item?.__typename === 'ResourceAllocation' ||
      item?.__typename === 'ResourceState'
    ) {
      return item.vehicle?.id === props.id
    }

    return false
  })
})

const filteredItems = computed(() => {
  if (props.type === RESOURCE_TYPE.ADDRESS) {
    return itemsOfAddress.value
  }

  /**
   * For freelancer we only want to display relevant ResourceAllocations
   */
  if (props.type === RESOURCE_TYPE.FREELANCER) {
    return itemsOfFreelancer.value?.filter((item) => {
      // TODO: This is done multiple times, here and in itemsOfFreelancer?
      return shouldBeVisibleInFreelancerArea(item)
    })
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    return itemsOfVehicle.value
  }

  throw new Error('props.type is not one of the supported types')
})

interface NormalizedItem {
  startDate: Date
  endDate: Date
  label: string
  id: string
  durationInMinutes: number
  type: ResourceDayItemType
  hasNote?: boolean
  color?: string
  resourceDayStatus?: ResourceStatus
  resourceAllocationStatus?: ResourceAllocationStatus
}

const normalizedItems = computed(() => {
  if (!filteredItems.value) return

  const normalizedItems: NormalizedItem[] = []

  let index = -1 // Start at -1 because we increment first thing in the loop
  filteredItems.value.forEach(() => {
    /**
     * Workaround for TypeScript issue where the type would be `any` when
     * vehicle items are included
     */
    index += 1 // Increment in any case so we get the correct item
    const item = filteredItems.value?.[index]
    if (!item) return

    const normalizedItem: Partial<NormalizedItem> = {
      startDate: undefined,
      endDate: undefined,
      label: undefined,
      hasNote: undefined,
      id: undefined,
      durationInMinutes: undefined,
      color: undefined,
      resourceDayStatus: undefined,
      resourceAllocationStatus: undefined,
    }

    // Get data specific for type:
    if (item.__typename === 'ResourceAllocation') {
      normalizedItem.id = `ResourceAllocation.${item.id}`
      normalizedItem.type = 'resourceAllocation'
      normalizedItem.startDate = parseServerDateString(
        item.resourceFunctionAllocation?.startDate
      )
      normalizedItem.endDate = parseServerDateString(
        item.resourceFunctionAllocation?.endDate
      )
      normalizedItem.label = item.resourceFunctionAllocation?.job?.caption
      normalizedItem.resourceDayStatus =
        getResourceDayStatusForResourceAllocation({
          jobStatusId: item.resourceFunctionAllocation?.job?.jobState?.id,
          resourceAllocationStatusId: item.resourceAllocationState?.id,
        })

      normalizedItem.hasNote = item.extra?.notice ? true : false

      normalizedItem.resourceAllocationStatus = lookup(
        item.resourceAllocationState?.id,
        STATUS_RESOURCE_ALLOCATION_LOOKUP
      )
    }

    if (item.__typename === 'ResourceState') {
      normalizedItem.id = `ResourceState.${item.id}`
      normalizedItem.type = 'resourceState'
      normalizedItem.startDate = parseServerDateString(item.startDate)
      normalizedItem.endDate = parseServerDateString(item.endDate)
      normalizedItem.label = item.resourceStateType?.caption
      normalizedItem.color = item.resourceStateType?.color

      if (
        props.type === RESOURCE_TYPE.ADDRESS ||
        props.type === RESOURCE_TYPE.FREELANCER
      ) {
        normalizedItem.resourceDayStatus = lookup(
          item.resourceStateType?.id,
          RESOURCE_STATUS_LOOKUP
        )
        normalizedItem.hasNote = item.comment ? true : false
      }

      if (props.type === RESOURCE_TYPE.VEHICLE) {
        normalizedItem.resourceDayStatus = lookup(
          item.resourceStateType?.id,
          RESOURCE_STATUS_LOOKUP
        )

        normalizedItem.hasNote = item.comment ? true : false
      }
    }

    if (item.__typename === 'Event') {
      normalizedItem.id = `Event.${item.id}`
      normalizedItem.type = 'event'
      normalizedItem.startDate = parseServerDateString(item.startDate)
      normalizedItem.endDate = parseServerDateString(item.endDate)
      normalizedItem.label = item.caption ?? undefined
    }

    if (item.__typename === 'CalendarEvent') {
      normalizedItem.id = `CalendarEvent.${item.id}`
      normalizedItem.type = 'calendarEvent'
      normalizedItem.startDate = parseServerDateString(item.startDate)
      normalizedItem.endDate = parseServerDateString(item.endDate)
      normalizedItem.label = item.caption ?? undefined
      normalizedItem.resourceDayStatus = 'notAvailable'
    }

    // Add aggregated data
    if (!normalizedItem.startDate || !normalizedItem.endDate) {
      throw new Error('normalizedItem is missing startDate or endDate')
    }

    normalizedItem.durationInMinutes = differenceInMinutes(
      normalizedItem.endDate,
      normalizedItem.startDate
    )

    hasNoNilProperties(normalizedItem, {
      ignore: [
        'resourceDayStatus',
        'color',
        'hasNote',
        'resourceAllocationStatus',
      ],
    })

    normalizedItems.push(normalizedItem)
  })

  return normalizedItems
})

const timeIndicators = computed(() => {
  return normalizedItems.value
})

const resourceDayStatus = computed(() => {
  if (!normalizedItems.value) return
  const items = normalizedItems.value

  const days = eachDayOfInterval({
    start: props.startDate,
    end: props.endDate,
  })

  return days.map((day) => {
    const resourceDayStatus = getResourceDayStatus({
      items: items,
      day: day,
    })

    const status = {
      date: day,
      status: resourceDayStatus,
    }

    return status
  })
})

const label = computed(() => {
  if (props.type === RESOURCE_TYPE.ADDRESS) {
    if (!address.value) return

    return getNameAbbreviation({
      firstName: address.value.firstName,
      lastName: address.value.surname,
      company: address.value.company,
    })
  }

  if (props.type === RESOURCE_TYPE.FREELANCER) {
    if (!address.value) return

    return getNameAbbreviation({
      firstName: address.value.firstName,
      lastName: address.value.surname,
      company: address.value.company,
    })
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    if (!vehicle.value) return

    if (vehicle.value.abbreviation) {
      return vehicle.value.abbreviation
    }
  }

  return undefined
})

const keyPopover = computed(() => {
  const dateString = formatWithLocale(detailDate.value)
  const key = `${props.id}.${props.type}.${dateString}`
  return key
})

function showDetails({
  date,
  popoverElement,
}: {
  date: Date
  popoverElement: HTMLElement
}) {
  if (date === detailDate.value) {
    closeDetails()
    return
  }

  detailDate.value = date
  detailPopoverElement.value = popoverElement
  detailVisible.value = true
}

function closeDetails() {
  detailVisible.value = false
  detailDate.value = undefined
  detailPopoverElement.value = undefined
}

/**
 * Scroll into view
 */
const isHighlightedInternal = ref(false)
function scrollIntoView() {
  isHighlightedInternal.value = true

  scrollToElement(elRoot.value, {
    scrollAxis: 'y',
    offsetY: 100,
  })
}

const isHighlighted = computed(() => {
  if (store.state.persoplan.timelineHighlightedId === props.id) return true

  if (
    resourceTimelines.highlightedTimeline?.id === props.id &&
    resourceTimelines.highlightedTimeline.type === props.type
  ) {
    return true
  }

  return false
})
</script>
