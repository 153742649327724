<template>
  <div ref="elRoot" class="PmSidebarProjectPure" :class="classes.root">
    <PmDraggablePure
      display="fullWidth"
      :type="DRAG_AND_DROP_TYPE.UPDATE_PROJECT_GROUP"
      :use-handle="true"
      :data="{ projectId: id, groupNumber: groupNumber }"
      :disabled="updateGroupVisible === false"
    >
      <div class="PmSidebarProjectPure-header" :class="classes.header">
        <div class="PmSidebarProjectPure-numberAndAlerts">
          <PmStatusPillPure
            v-if="pill"
            class="PmSidebarProjectPure-number"
            :label="pill"
            type="project"
            :project-status="status"
          />

          <PmPillPure
            v-if="alertsVisible"
            :title="`${numberOfAlerts} fehlende Ressourcen`"
            :variant="hasAlerts ? 'danger' : undefined"
            class="PmSidebarProjectPure-alerts"
            :class="classes.alerts"
            :label="numberOfAlerts"
          />
        </div>

        <div
          class="PmSidebarProjectPure-title"
          :title="title"
          @click="emit('showDetails')"
        >
          {{ title }}
        </div>

        <div class="PmSidebarProjectPure-meta">
          <div
            v-if="collectsAllJobsOfProject"
            :title="`Alle Jobs des Projektes ${pill} werden zusammengefasst`"
            class="PmSidebarProjectPure-icon"
          >
            <PmIconPure :name="ICONS_SMALL.COLLECTION" />
          </div>

          <PmNoteIconPure v-if="note" :note="note" @open="emit('openNote')" />
        </div>

        <div class="PmSidebarProjectPure-actions">
          <div
            v-if="updateGroupVisible"
            data-dragAndDrop-handle
            class="PmSidebarProjectPure-action PmSidebarProjectPure-action--drag"
            title="Gruppe festlegen"
          >
            <PmIconPure :name="ICONS_SMALL.GRIP" />
          </div>

          <button
            v-if="collectsAllJobsOfProject"
            class="PmSidebarProjectPure-action"
            title="Im Kalender anzeigen"
            @click.stop="emit('jump')"
          >
            <PmIconPure :name="ICONS_SMALL.JUMP" />
          </button>
        </div>
      </div>
    </PmDraggablePure>

    <div class="PmSidebarProjectPure-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarProjectPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { EVENT } from '@/constants/events'
import {
  CALENDAR_ITEM_TYPE,
  DRAG_AND_DROP_TYPE,
  type CalendarItemType,
  type ProjectStatus,
} from '@/constants/persoplan'
import { ICONS_SMALL } from '@/constants/icons'

import { getScrollParent } from '@/functional/scrollParent'
import EventHub from '@/eventHub'

import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmDraggablePure from '@/components/utilities/PmDraggablePure.vue'
import PmNoteIconPure from '@/components/PmNote/PmNoteIconPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'

export interface Props {
  id: number
  title?: string
  pill?: string
  isHighlighted?: boolean
  numberOfAlerts?: number
  alertsVisible?: boolean
  groupNumber?: number
  collectsAllJobsOfProject?: boolean
  updateGroupVisible?: boolean
  note?: string
  status?: ProjectStatus
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Section Title',
  alertsVisible: true,
  updateGroupVisible: true,
})

const emit = defineEmits<{
  (event: 'showDetails'): void
  (event: 'openNote'): void
  (event: 'jump'): void
}>()

const classes = computed(() => {
  return {
    root: {
      'is-highlighted': props.isHighlighted,
      'is-alertsVisible': props.alertsVisible,
    },
    header: {
      'is-alertsVisible': props.alertsVisible,
    },
    alerts: {
      'is-noAlerts': !hasAlerts.value,
    },
  }
})

const hasAlerts = computed(() => {
  if (!props.numberOfAlerts) return false
  return props.numberOfAlerts > 0
})

onMounted(() => {
  EventHub.$on(EVENT.SIDEBAR_PROJECT_SCROLL_INTO_VIEW, maybeScrollIntoView)
})

onBeforeUnmount(() => {
  EventHub.$off(EVENT.SIDEBAR_PROJECT_SCROLL_INTO_VIEW, maybeScrollIntoView)
})

function maybeScrollIntoView({
  id,
  type,
}: {
  id: number
  type: CalendarItemType
}) {
  if (id !== props.id) return
  if (type !== CALENDAR_ITEM_TYPE.PROJECT) return

  scrollIntoView()
}

const elRoot = ref<HTMLElement>()

function scrollIntoView() {
  if (!elRoot.value) return

  const elScrollParent = getScrollParent(elRoot.value)
  const rectScrollParent = elScrollParent.getBoundingClientRect()
  const rectEl = elRoot.value.getBoundingClientRect()

  const relativeScrollOffset =
    rectEl.y + elScrollParent.scrollTop - rectScrollParent.y - 20

  elScrollParent.scrollTo({
    top: relativeScrollOffset,
    behavior: 'smooth',
  })
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmSidebarProjectPure {
  $block: &;
  $name: function.getBlockName($block);

  @include mixin.status(cssVar.name($block, 'colorBorder'));
  @include mixin.status(
    $property: cssVar.name($block, 'colorBackground'),
    $alpha: 0.2
  );

  min-height: 37px; // Why 37px?
  width: 100%;
  // z-index: 10; // TODO: What does this z-index do?
  background-color: color.$white;
  position: relative;
  // overflow: hidden; // This hides the dragShadow…

  &:last-child {
    padding-bottom: 4px;
  }

  // TODO
  // &.draggable-mirror {
  //   @include shadow.default('low', $outline: false);

  //   z-index: 1501;
  //   grid-template-columns: var(--sidebarPillWidth) 1fr auto;
  //   border: 1px solid color.$gray-300--alpha;
  //   border-radius: constant.$borderRadius-default;

  //   .sidebarSection-drag {
  //     display: flex;
  //   }
  //   .sidebarSection-toggle {
  //     display: none;
  //   }
  // }

  &-header {
    display: grid;
    grid-template-columns: var(--sidebarPillWidth, auto) 1fr auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'numberAndAlerts title meta actions'
      'content content content content';
    align-items: center;
    justify-items: start;
    padding: 6px 10px;
    background-color: color.$white;
    transition: background-color constant.$duration-fast linear;

    #{$block}.is-highlighted & {
      background-color: color.$primary-200;
    }

    &.is-alertsVisible {
      grid-template-columns: var(--sidebarPillWidthWithAlerts, auto) 1fr auto auto;
    }

    &::after {
      @include shadow.drag;

      border: 1px solid color.$gray-300--alpha;
      border-radius: constant.$borderRadius-default;
      pointer-events: none;
    }

    &.is-dragging {
      &::after {
        opacity: 1;
      }
    }
  }

  &-numberAndAlerts {
    grid-area: numberAndAlerts;
    display: flex;
    gap: 4px;
  }

  &-alerts {
    &.is-noAlerts {
      display: none;
    }
  }

  &-meta {
    grid-area: meta;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &-icon {
    width: 20px;
    height: 20px;
  }

  &-title {
    grid-area: title;
    font-size: constant.$fontSize-large;
    font-weight: 500;
    margin-right: auto;

    @include mixin.truncateText;

    max-width: 100%;
    padding-right: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &-actions {
    @include mixin.transition-hover(
      $properties: opacity,
      $rootElements: #{$block}-header
    );

    grid-area: actions;
    margin-left: 4px;
    opacity: 0.1;
    display: flex;
    pointer-events: none;
    gap: 2px;

    &:not(:last-child) {
      margin-right: 4px;
    }

    #{$block}-header:hover &,
    #{$block}.is-hover & {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-action {
    @include mixin.transition-hover(color);

    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: color.$key;
    }

    &--drag {
      cursor: grab;
    }
  }

  &-content {
    grid-area: content;
    justify-self: stretch;
    padding: 3px 10px;
    display: grid;
    grid-gap: 6px;
  }
}
</style>
