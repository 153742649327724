<template>
  <li
    class="PmSidebarResourceGroupItemPure"
    :class="classes"
    :title="label"
    @mouseover="emit('highlight')"
    @mouseout="emit('unHighlight')"
  >
    {{ label }}
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarResourceGroupItemPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

export interface Props {
  label?: string
  isHighlighted?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'highlight'): void
  (event: 'unHighlight'): void
}>()

const classes = computed(() => {
  return {
    'is-highlighted': props.isHighlighted,
  }
})
</script>

<style lang="scss">
.PmSidebarResourceGroupItemPure {
  $block: &;
  $paddingVertical: 12px;
  $paddingItemVertical: 5px;

  @include mixin.truncateText;

  font-size: constant.$fontSize-default;
  line-height: 1;
  padding-top: $paddingItemVertical;
  padding-bottom: $paddingItemVertical;
  padding-left: 10px;
  padding-right: 10px;
  list-style: none;

  // @include mixin.transition-hover(
  //   (color, background-color),
  //   null,
  //   'highlighted'
  // );

  &:hover,
  &.is-hover,
  &.is-highlighted {
    color: color.$primary-900;
    background-color: color.$primary-100;
  }
}
</style>
