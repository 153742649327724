<template>
  <PmInputContainerPure
    :class="[attrs.class]"
    :label="label"
    :error-message="errorMessage"
    :invalid-message="invalidMessageNormalized"
    :required="required"
    :note="noteNormalized"
  >
    <div :class="[componentClass.root, classes]">
      <div :class="componentClass.element('dropzone')">
        <PmFileDropzone
          :max-number-of-files="maxNumberOfFiles"
          :allowed-file-types="allowedFileTypes"
          @drop="addFiles"
        />
      </div>

      <div :class="componentClass.element('selectWhenNotEmpty')">
        <PmButtonListPure :align="hasFiles ? 'left' : 'center'">
          <template v-if="hasFiles">
            <PmButtonPure
              label="Alle entfernen"
              icon="delete"
              variant="danger"
              alternative="ghost"
              @click="emit('removeAllFiles')"
            />

            <PmButtonListDividerPure />
          </template>

          <PmButtonPure
            type="file"
            :label="labelButton"
            note="oder hier ablegen"
            :allowed-file-types="allowedFileTypes"
            :max-number-of-files="maxNumberOfFiles"
            :required="required"
            icon="filePlus"
            :files="files"
            :invalid-message="props.invalidMessage"
            @input="onInput"
            @update-invalid-message="
              (message) => (inputInvalidMessage = message)
            "
          />
        </PmButtonListPure>
      </div>

      <div v-if="hasFiles" :class="componentClass.element('files')">
        <div :class="componentClass.element('filesLabel')">
          <template v-if="isMultipleFilesSelected">
            Ausgewählte Dateien:
          </template>
          <template v-else> Ausgewählte Datei </template>
        </div>

        <div :class="componentClass.element('filesList')">
          <template v-for="file in filesInfo" :key="file.name">
            <PmPillPure
              :label="`${file.name} (${file.sizeHumanReadable})`"
              icon="close"
              :is-interactive="true"
              :variant="file.valid === false ? 'danger' : undefined"
              @click-on-icon="emit('removeFile', file.name)"
            />
          </template>
        </div>
      </div>
    </div>
  </PmInputContainerPure>
</template>

<script setup lang="ts">
import { useAttrs, ref, computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { upperFirst } from 'lodash-es'

import PmInputContainerPure, {
  type Props as PropsInputContainerPure,
} from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'
import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'
import PmFileDropzone from '@/components/basics/PmFileDropzone/PmFileDropzone.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'
import type { MimeType } from '@/types/mimeTypes'
import {
  convertSizeToBytes,
  formatFileSizeToHumanReadable,
} from '@/utilities/file'

export type PropsFileInput = {
  /**
   * Accepted file file types for file input
   * @example '.pdf', 'image/*', 'image/png'
   */
  allowedFileTypes?: MimeType[]
  /** Max size per file in MB */
  maxFileSize?: number
  maxNumberOfFiles?: number
  invalidMessage?: string | string[]
}

export type NormalizedFile = {
  name: string
}

type PickedPropsInputContainerPure = Pick<
  PropsInputContainerPure,
  'label' | 'errorMessage' | 'note'
>

type InvalidReason = 'fileToLarge' | 'wrongFileType' | 'tooManyFiles'

type FileInfo = {
  name: string
  /** Size in MB  */
  size: number
  /** Size in human readable format */
  sizeHumanReadable: string
  valid?: boolean
  invalidReasons: Record<InvalidReason, boolean>
}

export interface Props extends PickedPropsInputContainerPure, PropsFileInput {
  required?: boolean
  filesInfo?: FileInfo[]
  files?: Map<string, File>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  addFiles: [files: File[]]
  removeAllFiles: []
  removeFile: [name: string]
}>()

const componentClass = useComponentClass()
const attrs = useAttrs()
const inputInvalidMessage = ref<string>()

/**
 * Combines the invalid message of the input in the button as well as the
 * invalid messages in the props from the parent component
 */
const invalidMessageNormalized = computed(() => {
  const result: string[] = []

  if (inputInvalidMessage.value) {
    result.push(inputInvalidMessage.value)
  }

  if (props.invalidMessage) {
    Array.isArray(props.invalidMessage)
      ? result.push(...props.invalidMessage)
      : result.push(props.invalidMessage)
  }

  return result
})

const hasError = computed(() => {
  if (invalidMessageNormalized.value.length > 0) return true

  return false
})

const classes = computed(() => {
  return {
    'is-withoutFiles': !hasFiles.value,
    'is-withFiles': hasFiles.value,
    'is-error': hasError.value,
  }
})

const hasFiles = computed(() => {
  if (!props.filesInfo) return false
  return props.filesInfo.length > 0
})

const isMultipleFilesSelected = computed(() => {
  if (!props.filesInfo) return false
  return props.filesInfo.length > 1
})

const isMultipleFilesAllowed = computed(() => {
  return props.maxNumberOfFiles !== 1
})

const labelButton = computed(() => {
  if (isMultipleFilesAllowed.value) {
    return hasFiles.value ? 'Weitere Dateien auswählen' : 'Dateien auswählen'
  }

  return hasFiles.value ? 'Andere Datei auswählen' : 'Datei auswählen'
})

function onInput(event: Event) {
  if (!(event.target instanceof HTMLInputElement)) return
  if (!event.target.files) return

  const newFiles = [...event.target.files]
  addFiles(newFiles)
}

function addFiles(newFiles: File[]) {
  emit('addFiles', newFiles)
}

const noteRestrictions = computed(() => {
  const note: string[] = []

  if (props.maxFileSize) {
    const maxFileSizeHumanReadable = formatFileSizeToHumanReadable(
      convertSizeToBytes(props.maxFileSize)
    )
    note.push(`maximale Dateigröße: ${maxFileSizeHumanReadable}`)
  }

  if (props.maxNumberOfFiles) {
    note.push(`maximal ${props.maxNumberOfFiles} Dateien`)
  }

  if (props.allowedFileTypes) {
    note.push(`erlaubte Dateitypen: ${props.allowedFileTypes?.join(', ')}`)
  }

  let combined = note.join(', ')
  combined = upperFirst(combined)

  if (combined.trim().length === 0) return undefined
  return combined
})

const noteNormalized = computed(() => {
  const note: string[] = []

  if (noteRestrictions.value) {
    note.push(noteRestrictions.value)
  }

  if (props.note) {
    Array.isArray(props.note) ? note.push(...props.note) : note.push(props.note)
  }

  return note
})
</script>

<style lang="scss">
.PmInputFilePure {
  $block: &;

  outline: 2px dashed color.$gray-300;
  outline-offset: -2px;
  border-radius: constant.$borderRadius-large;
  position: relative;
  display: grid;

  &.is-withoutFiles {
    padding: 32px;
    grid-template-columns: 1fr;
    grid-template-areas: 'select';
  }

  &.is-withFiles {
    padding: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'select'
      'files';
  }

  &.is-error {
    border-color: color.$danger-500;
    background-color: color.$danger-50;
  }

  &-dropzone {
    position: absolute;
    // position: relative;
    inset: 0;
    z-index: 1;
    pointer-events: none;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    & > * {
      pointer-events: auto;
    }
  }

  &-selectWhenEmpty {
    grid-area: select;
    justify-self: center;
  }

  &-selectWhenNotEmpty {
    grid-area: select;
    align-self: start;
  }

  &-files {
    grid-area: 'files';
    margin-top: 20px;
  }

  &-filesLabel {
    @include mixin.textLabel;

    margin-bottom: 8px;
    position: relative;
  }

  &-filesList {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}
</style>
