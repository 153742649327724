<template>
  <PmModalPure :can-be-closed="false" title="Anmelden">
    <PmAppNotificationPure
      class="PmLoginInterceptorPure-explanation"
      :icon="ICONS.INFO"
    >
      Um fortzufahren, musst du dich erneut anmelden
    </PmAppNotificationPure>

    <PmLoginFormPure
      :title-visible="false"
      :state="state"
      :error-message="errorMessage"
      @login="$emit('login', $event)"
    />
  </PmModalPure>
</template>

<script>
import { ICONS } from '@/constants/icons'

import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmLoginFormPure from '@/components/PmLogin/PmLoginFormPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'

export default {
  name: 'PmLoginInterceptorPure',
  components: {
    PmModalPure,
    PmLoginFormPure,
    PmAppNotificationPure,
  },

  props: {
    state: { type: String, default: undefined },
    errorMessage: { type: String, default: undefined },
  },

  emits: ['login'],

  setup() {
    return {
      ICONS,
    }
  },
}
</script>

<style lang="scss">
.PmLoginInterceptorPure {
  $block: &;

  &-explanation {
    margin-bottom: 1em;
  }
}
</style>
