<template>
  <div
    ref="elRoot"
    class="PmResourceAllocationPure"
    :class="classes.root"
    :style="styles.root"
    :title="caption"
    data-type="resourceAllocation"
    :data-id="id"
  >
    <component
      :is="asButton ? 'button' : 'div'"
      class="PmResourceAllocationPure-inner"
      @click="asButton ? emit('click') : null"
    >
      <div class="PmResourceAllocationPure-content">
        <template v-if="isSkeleton">
          <PmResourceAllocationStatusPure
            class="PmResourceAllocationPure-status"
            :factor="factor"
            :is-skeleton="true"
          />

          <div class="PmResourceAllocationPure-titleContainer">
            <div
              v-for="index in numberOfDuplicates"
              :key="index"
              class="PmResourceAllocationPure-title"
            ></div>
          </div>
        </template>

        <template v-if="!isSkeleton">
          <PmResourceAllocationStatusPure
            class="PmResourceAllocationPure-status"
            :status="status"
            :factor="factor"
          />

          <div class="PmResourceAllocationPure-actions">
            <PmCheckboxPure
              v-if="isInEditMode && isInSelectionMode"
              key="selectionCheckbox"
              size="small"
              :value="selected"
              :disabled="canBeSelected === false || isUiDisabled === true"
              @select="emit('select')"
              @unselect="emit('unselect')"
            />

            <PmResourceActionPure
              v-show="isInEditMode && !isInSelectionMode"
              key="editButton"
              ref="edit"
              class="PmResourceAllocationPure-action"
              :icon="ICONS_SMALL.EDIT"
              :is-disabled="isUiDisabled"
              @click="emit('edit')"
            />

            <PmResourceActionPure
              v-if="dutyOrder"
              class="PmResourceAllocationPure-action"
              :label="dutyOrder"
              :variant="dutyOrderVariant"
              :is-disabled="dutyOrderButtonDisabled"
              @click="emit('clickOnDutyOrder')"
            />

            <PmContextNavigationPure
              :items="contextNavigationItems"
              :disabled="isUiDisabled"
              @jump-to-timeline="emit('jumpToTimeline')"
              @open="emit('contextNavigationOpen')"
              @edit="emit('edit')"
              @enter-select-mode="enterSelectMode"
              @exit-select-mode="exitSelectMode"
              @open-details="emit('showDetails')"
              @request="emit('createResourceRequest')"
              @send-to-webfleet="emit('sendToWebfleet')"
            >
              <template #trigger="slotProps">
                <PmResourceActionPure
                  class="PmResourceAllocationPure-action"
                  :icon="ICONS_SMALL.CONTEXT_NAVIGATION"
                  :is-disabled="slotProps.disabled"
                  :is-active="slotProps.isOpen"
                  :has-notification="hasNotificationForDetails"
                  @click="slotProps.togglePopover"
                />
              </template>
            </PmContextNavigationPure>
          </div>

          <div class="PmResourceAllocationPure-titleContainer">
            <div
              v-for="index in numberOfDuplicates"
              :key="index"
              class="PmResourceAllocationPure-title"
              @click="emit('showDetails')"
            >
              {{ title }}
            </div>
          </div>

          <div v-show="hasInfo" class="PmResourceAllocationPure-info">
            <div
              v-if="hasDriver"
              class="PmResourceAllocationPure-infoItem"
              @click="emit('jumpToDriver')"
              @mouseenter="emit('highlightDriver')"
              @mouseleave="emit('clearHighlightDriver')"
            >
              <PmIconPure
                class="PmResourceAllocationPure-infoIcon"
                :name="ICONS.DRIVER"
              />
            </div>

            <div
              v-if="isDriver"
              class="PmResourceAllocationPure-infoItem"
              @click="emit('jumpToVehicle')"
              @mouseenter="emit('highlightVehicle')"
              @mouseleave="emit('clearHighlightVehicle')"
            >
              <PmIconPure
                class="PmResourceAllocationPure-infoIcon"
                :name="ICONS.VEHICLE"
              />
            </div>

            <PmTooltipPure
              v-if="statusResourceRequestFeedback"
              class="PmResourceAllocationPure-infoItem"
            >
              <PmIconPure
                class="PmResourceAllocationPure-infoIcon"
                :name="
                  ICON_STATUS_RESOURCE_REQUEST_FEEDBACK_LOOKUP[
                    statusResourceRequestFeedback
                  ]
                "
                size="small"
                @click="emit('openResourceRequest')"
              />

              <template #tooltip>
                <PmPopoverContentDefaultPure
                  title="laufende Anfrage"
                  help="Klicken für mehr Details"
                >
                  <PmStatusPillPure
                    type="resourceRequestFeedback"
                    :resource-request-feedback-status="
                      statusResourceRequestFeedback
                    "
                  />
                </PmPopoverContentDefaultPure>
              </template>
            </PmTooltipPure>

            <PmTooltipPure
              v-if="note"
              class="PmResourceAllocationPure-infoItem"
            >
              <PmIconPure
                class="PmResourceAllocationPure-infoIcon"
                :name="ICONS.NOTE"
              />

              <template #tooltip>
                <strong>Bemerkung:</strong> {{ note }}
              </template>
            </PmTooltipPure>

            <PmTooltipPure
              v-if="travelNormalized"
              class="PmResourceAllocationPure-infoItem"
              :class="classes.infoItemTravel"
            >
              <PmIconPure
                class="PmResourceAllocationPure-infoIcon"
                :name="ICONS.DIRECTION"
              />

              <template #tooltip>
                <strong>Travel:</strong> {{ travelNormalized }}
              </template>
            </PmTooltipPure>

            <PmTooltipPure
              v-if="hotelNormalized"
              class="PmResourceAllocationPure-infoItem"
              :class="classes.infoItemHotel"
            >
              <PmIconPure
                class="PmResourceAllocationPure-infoIcon"
                :name="ICONS.BED"
              />

              <template #tooltip>
                <strong>Hotel:</strong> {{ hotelNormalized }}
              </template>
            </PmTooltipPure>
          </div>
        </template>
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceAllocationPure'

export const propTypes = {
  status: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  },
  statusResourceRequestFeedback: {
    allowed: Object.values(STATUS_RESOURCE_REQUEST_FEEDBACK),
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed, toRef } from 'vue'
import { random } from 'lodash-es'

import { ICONS, ICONS_SMALL, type Icon } from '@/constants/icons'
import {
  STATUS_TRAVEL_AND_HOTEL_LOOKUP,
  STATUS_TRAVEL_AND_HOTEL,
  type StatusResourceRequestFeedback,
  STATUS_RESOURCE_REQUEST_FEEDBACK,
  type ResourceType,
} from '@/constants/persoplan'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import {
  stringifyValueWithStatus,
  extractStatusAndValueFromString,
} from '@/utilities/string'
import { jiggle } from '@/utilities/jiggle'

import { lookup } from '@/utilities/misc'
import {
  useResourceAllocationStatus,
  STATUS_RESOURCE_ALLOCATION,
  type ResourceAllocationStatus,
} from '@/composition/useResourceAllocationStatus'
import { useJumpTarget } from '@/composition/useJumpTarget'

import PmResourceActionPure from '@/components/persoplan/PmResourceActionPure.vue'
import PmResourceAllocationStatusPure from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationStatusPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmContextNavigationPure, {
  type Props as PropsContextNavigationPure,
} from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'
import PmTooltipPure from '@/components/basics/PmTooltipPure.vue'
import PmPopoverContentDefaultPure from '@/components/basics/PmPopoverContentDefaultPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  id: number | string
  type: ResourceType | 'skeleton'
  title?: string
  caption?: string
  factor?: number
  note?: string
  travel?: string
  hotel?: string
  dutyOrder?: number
  hasResourceRequest?: boolean
  statusResourceRequestFeedback?: StatusResourceRequestFeedback | null
  alertOnDutyOrder?: boolean
  dutyOrderButtonDisabled?: boolean
  isInEditMode?: boolean
  isUiDisabled?: boolean
  isInSelectionMode?: boolean
  status?: ResourceAllocationStatus
  isHighlighted?: boolean
  isSkeleton?: boolean
  asButton?: boolean
  disabled?: boolean
  hasDriver?: boolean
  isDriver?: boolean
  isJumpToTimelinePossible?: boolean
  userCanEdit?: boolean
  selected?: boolean
  canBeSelected?: boolean
  hasNotificationForDetails?: boolean
  canEditResourceRequest?: boolean
  canCreateWebfleetOrder?: boolean
  isWebfleetVehicle?: Nilable<boolean>
  canEnterSelectMode?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'click'): void
  (event: 'edit'): void
  (event: 'clickOnDutyOrder'): void
  (event: 'showDetails'): void
  (event: 'highlightDriver'): void
  (event: 'jumpToDriver'): void
  (event: 'clearHighlightDriver'): void
  (event: 'jumpToVehicle'): void
  (event: 'highlightVehicle'): void
  (event: 'clearHighlightVehicle'): void
  (event: 'jumpToTimeline'): void
  (event: 'contextNavigationOpen'): void
  (event: 'select'): void
  (event: 'unselect'): void
  (event: 'enterSelectMode'): void
  (event: 'exitSelectMode'): void
  (event: 'openResourceRequest'): void
  (event: 'createResourceRequest'): void
  (event: 'sendToWebfleet'): void
}>()

const resourceAllocationStatus = useResourceAllocationStatus({
  status: toRef(props, 'status'),
})

const elRoot = ref<HTMLElement>()
const isJiggeling = ref(false)
const isHighlightedBecauseJiggeling = ref(false)

const classes = computed(() => {
  return {
    root: {
      ...resourceAllocationStatus.classes.value,

      [`${COMPONENT_NAME}--asButton`]: props.asButton,
      'is-highlighted': isHighlightedNormalized.value,
      'is-skeleton': props.isSkeleton,
      'is-disabled': props.disabled,
      'is-jiggeling': isJiggeling.value,
    },

    infoItemTravel: {
      'is-statusNeeded': travelStatus.value === STATUS_TRAVEL_AND_HOTEL.NEEDED,
      'is-statusRequested':
        travelStatus.value === STATUS_TRAVEL_AND_HOTEL.REQUESTED,
      'is-statusConfirmed':
        travelStatus.value === STATUS_TRAVEL_AND_HOTEL.CONFIRMED,
      'is-statusCommunicated':
        travelStatus.value === STATUS_TRAVEL_AND_HOTEL.COMMUNICATED,
    },

    infoItemHotel: {
      'is-statusNeeded': hotelStatus.value === STATUS_TRAVEL_AND_HOTEL.NEEDED,
      'is-statusRequested':
        hotelStatus.value === STATUS_TRAVEL_AND_HOTEL.REQUESTED,
      'is-statusConfirmed':
        hotelStatus.value === STATUS_TRAVEL_AND_HOTEL.CONFIRMED,
      'is-statusCommunicated':
        hotelStatus.value === STATUS_TRAVEL_AND_HOTEL.COMMUNICATED,
    },
  }
})

const styles = computed(() => {
  return {
    root: {
      [`--${COMPONENT_NAME}-skeletonWidth`]: random(20, 80),
    },
  }
})

const hasActions = computed(() => {
  if (props.isInEditMode) return true
  if (props.dutyOrder) return true
  return false
})

const hasInfo = computed(() => {
  if (props.hasDriver) return true
  if (props.isDriver) return true
  if (props.note) return true
  if (props.travel) return true
  if (props.hotel) return true
  if (props.statusResourceRequestFeedback) return true

  return false
})

const travelNormalized = computed(() => {
  return stringifyValueWithStatus(props.travel)
})

const travelStatus = computed(() => {
  const statusId = extractStatusAndValueFromString(props.travel)?.statusId
  if (!statusId) return

  return lookup(statusId, STATUS_TRAVEL_AND_HOTEL_LOOKUP)
})

const hotelNormalized = computed(() => {
  return stringifyValueWithStatus(props.hotel)
})

const hotelStatus = computed(() => {
  const statusId = extractStatusAndValueFromString(props.hotel)?.statusId
  if (!statusId) return

  return lookup(statusId, STATUS_TRAVEL_AND_HOTEL_LOOKUP)
})

const dutyOrderVariant = computed(() => {
  if (props.alertOnDutyOrder) return 'danger'
  return undefined
})

const canBeRequested = computed(() => {
  if (props.hasResourceRequest) return false
  if (props.status === 'considered') return true
  return false
})

const noteResourceRequest = computed(() => {
  if (props.hasResourceRequest) return 'Es besteht bereits eine Anfrage'
  if (canBeRequested.value !== true)
    return 'Für eine Anfrage muss der Status „Angedacht” sein'

  return undefined
})

const noteSendToWebfleet = computed(() => {
  if (props.isWebfleetVehicle !== true) return 'Fahrzeug ist nicht in Webfleet'

  return undefined
})

const contextNavigationItems = computed(() => {
  const isItemResourceRequestVisible =
    props.type === 'address' || props.type === 'freelancer'

  const items: PropsContextNavigationPure['items'] = [
    {
      id: 'edit',
      icon: ICONS.EDIT,
      label: 'Bearbeiten',
      disabled: props.userCanEdit === false,
    },
    {
      id: 'request',
      icon: ICONS.MAIL_QUESTION,
      label: 'Anfragen',
      disabled: canBeRequested.value !== true,
      note: noteResourceRequest.value,
      isVisible: isItemResourceRequestVisible && props.canEditResourceRequest,
    },
    {
      id: 'sendToWebfleet',
      icon: 'forward',
      label: 'An Webfleet übertragen',
      isVisible: props.canCreateWebfleetOrder && props.type === 'vehicle',
      disabled: props.isWebfleetVehicle !== true,
      note: noteSendToWebfleet.value,
    },
    {
      id: 'openDetails',
      icon: ICONS.INFO,
      label: 'Details',
      hasNotification: props.hasNotificationForDetails,
    },
    {
      id: 'enterSelectMode',
      icon: ICONS.DUPLICATE,
      label: 'Mehrfachauswahl starten',
      disabled:
        props.userCanEdit === false || props.canEnterSelectMode === false,
      isVisible: !props.isInSelectionMode,
    },
    {
      id: 'exitSelectMode',
      icon: ICONS.CLOSE,
      label: 'Mehrfachauswahl beenden',
      disabled: props.userCanEdit === false,
      isVisible: props.isInSelectionMode,
    },
    {
      id: 'jumpToTimeline',
      icon: ICONS.JUMP,
      label: 'Zu Timeline springen',
      disabled: props.isJumpToTimelinePossible === false,
      note:
        props.isJumpToTimelinePossible === false ? 'Nicht sichtbar' : undefined,
    },
  ]

  return items
})

const isHighlightedNormalized = computed(() => {
  if (isHighlightedBecauseJiggeling.value) return true
  if (props.isHighlighted) return true

  return false
})

const numberOfDuplicates = computed(() => {
  if (!props.factor) return 1
  return props.factor
})

const ICON_STATUS_RESOURCE_REQUEST_FEEDBACK_LOOKUP: Record<
  StatusResourceRequestFeedback,
  Icon
> = {
  accept: 'mailConfirm',
  decline: 'mailCancel',
  maybe: 'mailQuestion',
  unknown: 'mailArrowRight',
  not_available: 'mailNotAvailable',
} as const

/**
 * Jump target
 */
useJumpTarget({
  id: props.id,
  type: 'ResourceAllocation',
  el: elRoot,
  align: {
    horizontal: 'center',
    vertical: 'center',
  },
  dontScrollIfVisible: {
    horizontal: true,
  },
  hooks: {
    whenInViewport: createAttention,
  },
})

async function createAttention() {
  const el = elRoot.value
  if (!el) throw new Error('el is undefined')

  isHighlightedBecauseJiggeling.value = true
  isJiggeling.value = true

  await jiggle(el)

  isHighlightedBecauseJiggeling.value = false
  isJiggeling.value = false
}

/**
 * Selection
 */
function enterSelectMode() {
  emit('enterSelectMode')
  emit('select')
}

function exitSelectMode() {
  emit('exitSelectMode')
}
</script>

<style lang="scss">
.PmResourceAllocationPure {
  $block: &;
  $name: function.getBlockName($block);
  $minHeight: 17px;

  @include cssVar.define($block, 'color', color.$gray-400);
  @include mixin.status(cssVar.name($block, 'color'));
  @include cssVar.define($block, 'jiggleIntensity', 0);

  position: relative;
  font-size: constant.$fontSize-default;
  z-index: 0;

  // Highglihted/Dragged over styles
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -4px;
    bottom: -2px;
    left: -4px;
    border: 2px solid color.$primary-500;
    border-radius: constant.$borderRadius-default;
    opacity: 0;
    transition: opacity constant.$duration-fast;
    pointer-events: none;
    background: color.$primary-200;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-highlighted {
    color: color.$primary-900;

    &::before {
      opacity: 1;
    }
  }

  &.is-statusCancelled {
    text-decoration: line-through;
  }

  &.is-jiggeling {
    z-index: 1;
  }

  &.is-skeleton {
    width: calc(cssVar.use($block, 'skeletonWidth') * 1%);
  }

  &--asButton {
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &-inner {
    display: block;
    width: 100%;
    text-align: left;
  }

  &-content {
    display: flex;
    align-items: flex-start;
    // align-items: center;
    position: relative;
    min-height: $minHeight;
    gap: 4px;

    // outline: 1px solid fuchsia;

    #{$block}--asButton & {
      pointer-events: none;
    }
  }

  &-actions {
    display: flex;
    position: relative;
    gap: 2px;
  }

  &-action {
    flex: none;
  }

  &-titleContainer {
    flex-grow: 1;
    flex-shrink: 1;
    width: 0;
    z-index: 1;

    /* stylelint-disable */
    .App:not(.is-dragAndDropInProgress) .PmAppointmentPure:hover & {
      width: auto;
    }
    /* stylelint-enable */
  }

  &-title {
    @include mixin.truncateText;

    line-height: $minHeight;
    font-size: constant.$fontSize-default; // For some reason iOS safari randomly decides to display this in a larger font-size

    #{$block}:not(.is-skeleton) & {
      cursor: pointer;
    }

    #{$block}.is-skeleton & {
      min-height: $minHeight;
      position: relative;

      &::before {
        content: '';
        background-color: color.$gray-200--alpha;
        border-radius: constant.$borderRadius-default;
        inset: 3px 0;
        position: absolute;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &-info {
    display: flex;
    flex: none;
  }

  &-infoItem {
    position: relative;
    flex: none;
    // display: none;
    width: 17px;
    height: 17px;

    &:not(:last-child) {
      margin-right: 2px;
    }

    &.is-statusNeeded {
      color: color.$danger-500;
    }

    &.is-statusRequested {
      color: color.$status-requested--alternative1;
    }

    &.is-statusConfirmed {
      color: color.$status-considered;
    }

    &.is-statusCommunicated {
      color: color.$status-confirmed;
    }
  }

  &-infoIcon {
    border-radius: constant.$borderRadius-default;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: 0 0 0 2px color.$key;
    }
  }
}
</style>
