<template>
  <PmViewEditorDropdown
    label="Ressourcen"
    attribute="resourceAllocations"
    :options="normalizedResourceAllocations"
    :is-loading="isLoadingAny"
    v-bind="separateAttrs($attrs).attributes"
    :value="value"
    @close="onClose"
    @input-searchterm="(value) => (searchterm = value)"
  />
</template>

<script>
import { uniqBy } from 'lodash-es'

import { separateAttrs } from '@/utilities/misc'
import { useLoading } from '@/composition/useLoading'

import PmViewEditorDropdown from '@/components/persoplan/ViewEditor/PmViewEditorDropdown.vue'

import ViewEditorResourceAllocationsQuery from '@/components/persoplan/ViewEditor/ViewEditorResourceAllocationsQuery.graphql'
import ViewEditorResourceAllocationsSearchQuery from '@/components/persoplan/ViewEditor/ViewEditorResourceAllocationsSearchQuery.graphql'

export default {
  name: 'PmViewEditorResourceAllocations',
  components: {
    PmViewEditorDropdown,
  },

  props: {
    value: { type: Array, default: () => [] },
  },

  setup() {
    const { setLoading, isLoadingAny } = useLoading({
      loaders: ['resourceAllocations', 'resourceAllocationSearch'],
    })

    return {
      separateAttrs,
      setLoading,
      isLoadingAny,
    }
  },

  data() {
    return {
      searchterm: undefined,
      resourceAllocations: [],
      resourceAllocationsSearch: [],
    }
  },

  computed: {
    normalizedResourceAllocations() {
      const resourceAllocations = this.resourceAllocations.map(
        (resourceAllocation) => ({
          id: resourceAllocation.id,
          label: resourceAllocation.displayName,
        })
      )

      const resourceAllocationsSearch = this.resourceAllocationsSearch.map(
        (resourceAllocation) => ({
          id: resourceAllocation.id,
          label: resourceAllocation.displayName,
        })
      )

      // Remove any double values
      return uniqBy(
        [...resourceAllocations, ...resourceAllocationsSearch],
        'id'
      )
    },
  },

  methods: {
    onClose() {
      this.resourceAllocationsSearch = []
      this.searchterm = undefined
    },
  },

  apollo: {
    resourceAllocations: {
      query: ViewEditorResourceAllocationsQuery,

      variables() {
        // If there are no ids selected, use -1 to avoid getting results from query
        let ids = this.value
        if (!ids.length) ids = [-1]

        return {
          ids: ids,
        }
      },

      watchLoading(isLoading) {
        this.setLoading('resourceAllocations', isLoading)
      },
    },

    resourceAllocationsSearch: {
      query: ViewEditorResourceAllocationsSearchQuery,

      debounce: 250,

      variables() {
        return {
          searchterm: this.searchterm,
        }
      },

      skip() {
        if (!this.searchterm) return true
        return false
      },

      watchLoading(isLoading) {
        this.setLoading('resourceAllocationSearch', isLoading)
      },
    },
  },
}
</script>
