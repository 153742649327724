<template>
  <div class="PmResourceDayPure" :class="classes">
    <PmLoadingPure
      v-if="isLoading"
      class="PmResourceDayPure-loader"
      size="small"
    />

    <PmPopoverContentDefaultPure
      v-if="containerStartDate && containerEndDate"
      :title="title"
      :subtitle="subtitle"
      :with-close-button="true"
    >
      <template #header>
        <!-- Disabled after client request -->
        <template v-if="false">
          <div
            v-if="resourceDayStatus"
            class="PmResourceDayPure-resourceDayStatus"
          >
            <PmStatusDotPure
              :resource-day-status="resourceDayStatus"
              type="resourceDay"
            />

            {{ resourceDayStatusLabel }}
          </div>
        </template>
      </template>

      <PmResourceDayItemPure
        v-for="item in normalizedItems"
        :key="`${item.type}.${item.id}`"
        v-bind="item"
        :container-start-date="containerStartDate"
        :container-end-date="containerEndDate"
        @jump="emit('jump', item.id)"
        @edit="emit('edit', item.id)"
      />
    </PmPopoverContentDefaultPure>

    <div v-if="controlVisible" class="PmResourceDayPure-control">
      <PmButtonPure
        v-if="createResourceStateVisible"
        label="Ressourcen-Status erstellen"
        :icon="ICONS.PLUS_CIRCLE"
        variant="secondary"
        alternative="ghost"
        @click="emit('createResourceState')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceDayPure'

export const propTypes = {
  layout: {
    allowed: ['default', 'fullWidth'] as const,
    default: 'default' as const,
  },

  resourceDayStatus: {
    allowed: Object.values(RESOURCE_STATUS),
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy } from 'lodash-es'
import { isValid, isSameDay } from 'date-fns'

import { ICONS } from '@/constants/icons'

import { FORMAT_DATE_DEFAULT } from '@/constants/persoplan'
import {
  type ResourceType,
  type ResourceStatus,
  RESOURCE_STATUS,
  RESOURCE_STATUS_LOOKUP_LABEL,
} from '@/constants/persoplan'

import { formatWithLocale } from '@/utilities/date'
import { lookup } from '@/utilities/misc'

import PmPopoverContentDefaultPure from '@/components/basics/PmPopoverContentDefaultPure.vue'
import PmResourceDayItemPure from '@/components/persoplan/PmResourceDay/PmResourceDayItemPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'

import type { Props as PropsResourceDayItemPure } from '@/components/persoplan/PmResourceDay/PmResourceDayItemPure.vue'
import type { Nilable } from '@/types/misc'

export interface Props {
  title?: Nilable<string>
  layout?: (typeof propTypes.layout.allowed)[number]
  items: Omit<
    PropsResourceDayItemPure,
    'containerStartDate' | 'containerEndDate'
  >[]
  type: ResourceType
  isLoading?: boolean
  day?: Date
  startDate?: Date
  endDate?: Date
  userCanCreateResourceState?: boolean
  resourceDayStatus?: ResourceStatus
}

const props = withDefaults(defineProps<Props>(), {
  layout: propTypes.layout.default,
  userCanCreateResourceState: false,
})

const emit = defineEmits<{
  (event: 'jump', id: number): void
  (event: 'edit', id: number): void
  (event: 'createResourceState'): void
}>()

const dayNormalized = computed(() =>
  isValid(props.day) ? props.day : undefined
)
const startDateNormalized = computed(() =>
  isValid(props.startDate) ? props.startDate : undefined
)
const endDateNormalized = computed(() =>
  isValid(props.endDate) ? props.endDate : undefined
)

// Check required date props
const checkDateProps = () => {
  if (
    !startDateNormalized.value &&
    !endDateNormalized.value &&
    !dayNormalized.value
  ) {
    throw new Error('You need to define startDate and endDate or day')
  }

  if (dayNormalized.value) return

  if (!startDateNormalized.value)
    throw new Error('You need to define startDate')

  if (!endDateNormalized.value) throw new Error('You need to define endDate')
}
checkDateProps()

const containerStartDate = computed(() => {
  return dayNormalized.value || startDateNormalized.value
})

const containerEndDate = computed(() => {
  return dayNormalized.value || endDateNormalized.value
})

const subtitle = computed(() => {
  if (dayNormalized.value) {
    return formatWithLocale(dayNormalized.value, FORMAT_DATE_DEFAULT)
  }

  if (startDateNormalized.value && endDateNormalized.value) {
    if (isSameDay(startDateNormalized.value, endDateNormalized.value)) {
      return formatWithLocale(startDateNormalized.value, FORMAT_DATE_DEFAULT)
    }

    return `${formatWithLocale(
      startDateNormalized.value,
      FORMAT_DATE_DEFAULT
    )} — ${formatWithLocale(endDateNormalized.value, FORMAT_DATE_DEFAULT)}`
  }

  return ''
})

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--layoutDefault`]: props.layout === 'default',
    [`${COMPONENT_NAME}--layoutFullWidth`]: props.layout === 'fullWidth',
  }
})
const normalizedItems = computed(() => {
  return orderBy(props.items, ['startDate'], ['asc'])
})

const controlVisible = computed(() => {
  if (createResourceStateVisible.value) return true
  return false
})

const createResourceStateVisible = computed(() => {
  return props.userCanCreateResourceState
})

const resourceDayStatusLabel = computed(() => {
  return lookup(props.resourceDayStatus, RESOURCE_STATUS_LOOKUP_LABEL)
})
</script>

<style lang="scss">
.PmResourceDayPure {
  $block: &;

  position: relative;

  &--layoutDefault {
    width: var(--dayWidth);

    /**
    * 20px is the paddingOutside value in PmPopoverPure
    * 12px is the padding of the popover and the floating bar
    * @todo This should be dynamic
    */
    max-width: calc(100vw - 20px - 20px - 12px - 12px);
  }

  &-loader {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-control {
    margin-top: 16px;
  }

  &-resourceDayStatus {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: constant.$fontSize-default;
    margin-top: 4px;
  }
}
</style>
