<template>
  <PmNotePure
    v-if="appointment"
    :note="note"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :state="pureState"
    :edit-button-visible="$can('edit', 'persoplan')"
    @save="(value) => xstate.service.value.send('UPDATE', { value })"
    @cancel="xstate.service.value.send('CANCEL')"
  />
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import PmNotePure from '@/components/PmNote/PmNotePure.vue'

import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'

import { PmNoteState } from '@/components/PmNote/PmNoteState'

import NoteAppointmentQuery from '@/components/PmNote/NoteAppointmentQuery.graphql'
import UpdateNoteAppointmentMutation from '@/components/PmNote/UpdateNoteAppointmentMutation.graphql'

export default defineComponent({
  name: 'PmNoteJob',
  components: {
    PmNotePure,
  },

  props: {
    id: { type: Number, default: undefined },
  },

  setup() {
    const instance = getCurrentInstance()

    const xstate = useXState(PmNoteState, {
      services: {
        updateNote: (context, { value }) => instance.ctx.updateNote(value),
      },
    })
    return {
      xstate,
    }
  },

  data() {
    return {
      appointment: undefined,
    }
  },

  computed: {
    note() {
      return this.appointment?.comment
    },

    pureState() {
      if (this.xstate.state.value.matches('update.updating')) {
        return 'editing.saving'
      }

      if (this.xstate.state.value.matches('update.failed')) {
        return 'editing.failed'
      }

      if (this.xstate.state.value.matches('default')) {
        return 'default'
      }

      return undefined
    },
  },

  methods: {
    async updateNote(value) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateNoteAppointmentMutation,

          variables: {
            id: this.id,
            note: value,
          },
        })
      } catch (error) {
        throwFriendlyError(error)
      }
    },
  },

  apollo: {
    appointment: {
      query: NoteAppointmentQuery,
      fetchPolicy: 'cache-and-network',

      variables() {
        return {
          id: this.id,
        }
      },
    },
  },
})
</script>
