export const ICONS = {
  ACCOUNT: 'account',
  ADJUSTMENTS: 'adjustments',
  ALERT: 'alert',
  ALIEN: 'alien',
  ARCHIVE: 'archive',
  ARROW_DOWN: 'arrowDown',
  ARROW_LEFT: 'arrowLeft',
  ARROW_RIGHT: 'arrowRight',
  ARROW_UP: 'arrowUp',
  BED: 'bed',
  BELL: 'bell',
  BOOK: 'book',
  BOOKMARK: 'bookmark',
  CALENDAR: 'calendar',
  CHECK_CHECK: 'checkCheck',
  CHECK: 'check',
  CHECKBOX_CIRCLE_CHECKED: 'checkboxCircleChecked',
  CHECKBOX_CIRCLE: 'checkboxCircle',
  CLIPBOARD: 'clipboard',
  CLOCK_REWIND: 'clockRewind',
  CLOSE: 'close',
  COLLECTION: 'collection',
  CONFLICT: 'conflict',
  CONTEXT_NAVIGATION: 'contextNavigation',
  CURSOR: 'cursor',
  DELETE: 'delete',
  DIRECTION: 'direction',
  DISPLAY: 'display',
  DOUBLE_ARROW_LEFT: 'doubleArrowLeft',
  DOUBLE_ARROW_RIGHT: 'doubleArrowRight',
  DRIVER_SELECT: 'driverSelect',
  DRIVER: 'driver',
  DUPLICATE: 'duplicate',
  EDIT: 'edit',
  ERASER: 'eraser',
  EXCLUDE: 'exclude',
  EXPLICIT: 'explicit',
  EYE: 'eye',
  FEEDBACK: 'feedback',
  FILE_PLUS: 'filePlus',
  FILE_UP: 'fileUp',
  FINGER_PRINT: 'fingerPrint',
  FORWARD: 'forward',
  HELP: 'help',
  HOME: 'home',
  INFO: 'info',
  JUMP: 'jump',
  KEY: 'key',
  LOCK_OPEN: 'lockOpen',
  LOCK: 'lock',
  MAIL_ARROW_RIGHT: 'mailArrowRight',
  MAIL_CANCEL: 'mailCancel',
  MAIL_CONFIRM: 'mailConfirm',
  MAIL_NOT_AVAILABLE: 'mailNotAvailable',
  MAIL_QUESTION: 'mailQuestion',
  MAIL_WITH_STATUS: 'mailWithStatus',
  MAIL: 'mail',
  MARK_AS_UNREAD: 'markAsUnread',
  MENU: 'menu',
  MINUS: 'minus',
  MOVE: 'move',
  NOTE: 'note',
  OFFLINE: 'offline',
  OVERFLOW_LEFT: 'overflowLeft',
  OVERFLOW_RIGHT: 'overflowRight',
  PAUSE: 'pause',
  PIN: 'pin',
  PLUS_CIRCLE: 'plusCircle',
  PLUS: 'plus',
  PUBLIC: 'public',
  QUESTION_MARK: 'questionMark',
  RANDOM: 'random',
  RECEIPT_EURO: 'receiptEuro',
  RELOAD: 'reload',
  REPORT: 'report',
  RESET: 'reset',
  SAVE: 'save',
  SEARCH: 'search',
  SEND: 'send',
  SETTINGS: 'settings',
  SIGN_OUT: 'signOut',
  SNOOZE: 'snooze',
  SORT: 'sort',
  SPARKLES: 'sparkles',
  STAFF_REQUEST: 'staffRequest',
  STAR: 'star',
  SWITCH: 'switch',
  SYNC: 'sync',
  TERMINAL: 'terminal',
  TINT: 'tint',
  UNLINK: 'unlink',
  USER_SCAN: 'userScan',
  USER: 'user',
  VACATION: 'vacation',
  VEHICLE_SELECT: 'vehicleSelect',
  VEHICLE: 'vehicle',
  WRENCH: 'wrench',
} as const

export const ICONS_SMALL = {
  ADJUSTMENTS: 'adjustments.small',
  ALERT: 'alert.small',
  BOOK: 'book.small',
  CLOSE: 'close.small',
  COLLECTION: 'collection.small',
  CONFLICT: 'conflict.small',
  CONTEXT_NAVIGATION: 'contextNavigation.small',
  DELETE: 'delete.small',
  DUPLICATE: 'duplicate.small',
  EDIT: 'edit.small',
  FEEDBACK: 'feedback.small',
  FINGER_PRINT: 'fingerPrint.small',
  GRIP: 'grip.small',
  HOME: 'home.small',
  JUMP: 'jump.small',
  KEY: 'key.small',
  LOCK_OPEN: 'lockOpen.small',
  LOCK: 'lock.small',
  MAIL_ARROW_RIGHT: 'mailArrowRight.small',
  MAIL_CANCEL: 'mailCancel.small',
  MAIL_CONFIRM: 'mailConfirm.small',
  MAIL_NOT_AVAILABLE: 'mailNotAvailable.small',
  MAIL_QUESTION: 'mailQuestion.small',
  MENU: 'menu.small',
  PAUSE: 'pause.small',
  PLUS_CIRCLE: 'plusCircle.small',
  PLUS: 'plus.small',
  PUBLIC: 'public.small',
  RESET: 'reset.small',
  SAVE: 'save.small',
  SEARCH: 'search.small',
  SETTINGS: 'settings.small',
  SPARKLES: 'sparkles.small',
  SWITCH: 'switch.small',
  TINT: 'tint.small',
  USER: 'user.small',
} as const

type IconDefaultKeys = keyof typeof ICONS
export type IconDefault = (typeof ICONS)[IconDefaultKeys]

type IconSmallKeys = keyof typeof ICONS_SMALL
export type IconSmall = (typeof ICONS_SMALL)[IconSmallKeys]

export type Icon = IconDefault | IconSmall
