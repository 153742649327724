<template>
  <PmResourceStateEditPure
    v-bind="propsResourceStateEditPure"
    :is-loading="isLoading"
    v-on="listenersResourceStateEditPure"
  />
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import { RESOURCE_TYPE_LOOKUP, RESOURCE_TYPE } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import {
  // parseServerDateString,
  formatToServerDateString,
} from '@/utilities/date'
import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { checkIfResourceStateTypeIsVisible } from '@/functional/resourceStateType'
import EventHub from '@/eventHub'

import { PmResourceStateCreateState } from '@/components/persoplan/PmResourceStateEdit/PmResourceStateCreateState'

import ResourceStateTypesQuery from '@/graphql/ResourceStateTypesQuery.graphql'
import CreateResourceStateAddressMutation from '@/components/persoplan/PmResourceStateEdit/CreateResourceStateAddressMutation.graphql'
import CreateResourceStateVehicleMutation from '@/components/persoplan/PmResourceStateEdit/CreateResourceStateVehicleMutation.graphql'

import PmResourceStateEditPure from '@/components/persoplan/PmResourceStateEdit/PmResourceStateEditPure.vue'

export const propTypes = {
  type: {
    allowed: [
      RESOURCE_TYPE.ADDRESS,
      RESOURCE_TYPE.FREELANCER,
      RESOURCE_TYPE.VEHICLE,
    ],
  },
}

export default defineComponent({
  name: 'PmResourceStateCreate',
  components: {
    PmResourceStateEditPure,
  },

  props: {
    addressId: { type: Number, default: undefined },
    vehicleId: { type: Number, default: undefined },
    type: { type: String, required: true },
    suggestedStartDate: { type: Date, default: undefined },
    suggestedEndDate: { type: Date, default: undefined },
  },

  emits: ['close', 'cancel'],

  setup(props, context) {
    const instance = getCurrentInstance()

    const xstate = useXState(PmResourceStateCreateState, {
      services: {
        createResourceState: () => instance.ctx.createResourceState(),
      },

      actions: {
        emitClose: () => context.emit('close'),
      },
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      resourceStateTypes: [],
      resourceStates: undefined,
      resourceState: undefined,
      value: undefined,
      isLoadingRresourceStateTypes: undefined,
    }
  },

  computed: {
    stateOptionsNormalized() {
      return this.resourceStateTypes.map((resourceStateType) => {
        return {
          id: resourceStateType.id,
          label: resourceStateType.caption,

          hidden: !checkIfResourceStateTypeIsVisible({
            applyForType: this.type,
            resourceStateTypeId: resourceStateType.id,
            resourceType:
              RESOURCE_TYPE_LOOKUP[resourceStateType.resourceType.id],
          }),
        }
      })
    },

    isLoading() {
      return this.isLoadingRresourceStateTypes
    },

    propsResourceStateEditPure() {
      return {
        ...this.value,
        stateOptions: this.stateOptionsNormalized,
        state: this.xstate.path.value,
        mode: 'create',
        errorMessage: this.xstate.state.value.context.error,
        errorDetails: this.xstate.state.value.context.errorDetails,
      }
    },

    listenersResourceStateEditPure() {
      return {
        inputStartDateTime: (date) => (this.value.startDateTime = date),
        inputEndDateTime: (date) => (this.value.endDateTime = date),
        selectState: (id) => (this.value.selectedStateId = id),
        inputNote: (note) => (this.value.note = note),
        cancel: () => this.$emit('cancel'),
        save: () => this.xstate.service.value.send('SAVE'),
      }
    },
  },

  created() {
    this.checkProps()

    this.value = {
      startDateTime: this.suggestedStartDate,
      endDateTime: this.suggestedEndDate,
      selectedStateId: undefined,
      note: undefined,
    }
  },

  methods: {
    checkProps() {
      if (this.type === RESOURCE_TYPE.ADDRESS) {
        if (!this.addressId)
          throw new Error(
            `addressId must be provided for type ${RESOURCE_TYPE.ADDRESS}`
          )
      }

      if (this.type === RESOURCE_TYPE.FREELANCER) {
        if (!this.addressId)
          throw new Error(
            `addressId must be provided for type ${RESOURCE_TYPE.FREELANCER}`
          )
      }

      if (this.type === RESOURCE_TYPE.VEHICLE) {
        if (!this.vehicleId)
          throw new Error(
            `vehicleId must be provided for type ${RESOURCE_TYPE.VEHICLE}`
          )
      }
    },

    async createResourceState() {
      const variables = {}
      let mutation

      if (
        this.type === RESOURCE_TYPE.ADDRESS ||
        this.type === RESOURCE_TYPE.FREELANCER
      ) {
        variables.addressId = this.addressId
        mutation = CreateResourceStateAddressMutation
      }

      if (this.type === RESOURCE_TYPE.VEHICLE) {
        variables.vehicleId = this.vehicleId
        mutation = CreateResourceStateVehicleMutation
      }

      try {
        await this.$apollo.mutate({
          mutation,
          refetchQueries: ['ResourceDayAddress', 'ResourceDayVehicle'],

          variables: {
            ...variables,
            resourceStateTypeId: this.value.selectedStateId,
            startDate: formatToServerDateString(this.value.startDateTime),
            endDate: formatToServerDateString(this.value.endDateTime),
            note: this.value.note,
          },
        })

        EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
      } catch (error) {
        throwFriendlyError(error, { operation: 'create' })
      }
    },
  },

  apollo: {
    resourceStateTypes: {
      query: ResourceStateTypesQuery,

      watchLoading(isLoading) {
        this.isLoadingRresourceStateTypes = isLoading
      },
    },
  },
})
</script>
