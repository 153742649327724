<template>
  <PmDataModalJobPure
    :id="id"
    :is-loading="isLoading"
    :name="job?.caption"
    :number="job?.number"
    :status="status"
    :start-date="parseServerDateString(job?.startDate)"
    :end-date="parseServerDateString(job?.endDate)"
    :created-date="parseServerDateString(job?.created)"
    :created-by="job?.createdBy?.displayName"
    :edited-date="parseServerDateString(job?.updated)"
    :edited-by="job?.updatedBy?.displayName"
    :job-leader="normalizeAddress(job?.arrangedBy?.address)"
    :service="job?.jobService?.caption"
    :location="normalizeAddress(job?.deliveryAddress)"
    :location-note="job?.deliveryAddressNote"
    :contact="normalizeContact(job?.deliveryAddress?.contact)"
    :customer="normalizeAddress(job?.project?.customer)"
    :project="project"
    :all-jobs="allJobs"
    :resources-without-appointment="resourcesWithoutAppointment"
    @open-project="
      (projectId) =>
        EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
          type: DATA_MODAL_TYPE.PROJECT,
          id: projectId,
        })
    "
    @open-job="
      (jobId) =>
        EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
          type: DATA_MODAL_TYPE.JOB,
          id: jobId,
        })
    "
  >
    <template #note>
      <PmNoteJob
        :id="id"
        no-v-if="$can('edit', 'persoplan')"
        class="PmDataModalJob-note"
      />
    </template>
  </PmDataModalJobPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'DataModalJob'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'

import {
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  STATUS_PROJECT_LOOKUP,
  STATUS_JOB_LOOKUP,
  RESOURCE_TYPE,
  DATA_MODAL_TYPE,
} from '@/constants/persoplan'
import { EVENT } from '@/constants/events'
import { getDisplayNameOfAddress } from '@/utilities/string'
import { normalizeContact, normalizeAddress } from '@/utilities/address'
import EventHub from '@/eventHub'
import { lookup } from '@/utilities/misc'
import { parseServerDateString } from '@/utilities/date'

import PmDataModalJobPure, {
  type Props as PropsDataModalJobPure,
} from '@/components/persoplan/DataModal/DataModalJob/PmDataModalJobPure.vue'
import PmNoteJob from '@/components/PmNote/PmNoteJob.vue'

import { DataModalJobDocument } from '@/../generated/graphql'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const jobQuery = useQuery(
  DataModalJobDocument,
  () => ({
    id: props.id,
  }),
  () => ({
    // Don't update the cache, which might change the calendar view.
    fetchPolicy: 'no-cache',
    // notifyOnNetworkStatusChange is needed so $apollo.loading gets updated
    notifyOnNetworkStatusChange: true,
  })
)

const job = computed(() => jobQuery.result.value?.job)

const isLoading = computed(() => {
  return jobQuery.loading.value === true
})

/**
 * Status
 */
const jobStatus = computed(() => {
  return lookup(job.value?.jobState?.id, STATUS_JOB_LOOKUP)
})

const status = computed(() => {
  if (!jobStatus.value) return

  const label = job.value?.jobState?.caption
  if (!label) return

  const result: PropsDataModalJobPure['status'] = {
    status: jobStatus.value,
    label: label,
  }

  return result
})

/**
 * Project
 */
const projectStatus = computed(() => {
  return lookup(job.value?.project?.projectState?.id, STATUS_PROJECT_LOOKUP)
})

const project = computed(() => {
  if (!job.value?.project) return

  const result: PropsDataModalJobPure['project'] = {
    id: job.value?.project?.id,
    number: job.value?.project?.number,
    label: `${job.value?.project?.caption} (${job.value?.project?.projectState?.caption})`,
    status: projectStatus.value,
  }

  return result
})

/**
 * All jobs of this project
 */
const allJobs = computed(() => {
  const result: PropsDataModalJobPure['allJobs'] = []

  job.value?.project?.jobs?.forEach((job) => {
    if (!job) return

    const jobNormalized: Exclude<
      PropsDataModalJobPure['allJobs'],
      undefined
    >[number] = {
      id: job.id,
      title: job.caption,
      number: job.number,
      status: lookup(job.jobState?.id, STATUS_JOB_LOOKUP),
    }

    result.push(jobNormalized)
  })

  return result
})

/**
 * Resources without appointment
 */
const resourcesWithoutAppointment = computed(() => {
  const result: PropsDataModalJobPure['resourcesWithoutAppointment'] = []

  job.value?.resourceFunctionAllocations?.forEach((resource) => {
    if (!resource) return

    const resourceNormalized: Exclude<
      PropsDataModalJobPure['resourcesWithoutAppointment'],
      undefined
    >[number] = {
      id: resource.id,
      title: resource?.resourceFunction?.caption,
      startDate: parseServerDateString(resource.startDate),
      endDate: parseServerDateString(resource.endDate),
    }

    result.push(resourceNormalized)
  })

  return result
})
</script>
