<template>
  <div class="PmResourceDay">
    <PmResourceDayContainerPure
      :mode="showIn"
      :popover-element="popoverElement"
      :is-loading="isLoading"
      :can-be-closed="canBeClosed"
      @close="$emit('close')"
    >
      <PmResourceDayPure
        v-if="xstate.state.value.matches('default') && propsResourceDayPure"
        v-bind="propsResourceDayPure"
        :layout="showIn === 'none' ? 'fullWidth' : undefined"
        :is-loading="showIn === 'none' ? isLoading : undefined"
        v-on="listenersResourceDayPure"
      />

      <PmResourceStateEdit
        v-if="
          xstate.state.value.matches('editResourceState') &&
          xstate.state.value.context.editResourceStateId
        "
        :id="xstate.state.value.context.editResourceStateId"
        :key="xstate.state.value.context.editResourceStateId"
        :type="type"
        @cancel="xstate.service.value.send('CANCEL')"
        @close="xstate.service.value.send('CLOSE')"
      />

      <PmResourceStateCreate
        v-if="xstate.state.value.matches('createResourceState')"
        :popover-element="popoverElement"
        :address-id="addressId"
        :vehicle-id="vehicleId"
        :suggested-start-date="suggestedStartDate"
        :suggested-end-date="suggestedEndDate"
        :type="type"
        @cancel="xstate.service.value.send('CANCEL')"
        @close="xstate.service.value.send('CLOSE')"
      />

      <portal to="notifications">
        <PmAppNotificationPure
          v-if="isNotificationVisible"
          key="notification01"
          title="Nicht sichtbar"
          :icon="ICONS.EYE"
          :timeout="5"
          :with-shadow="true"
          :can-be-closed="true"
          @close="closeNotification"
          @timeout-complete="closeNotification"
        >
          Ein Sprung zu dieser Resourcen-Zuordnung ist nicht möglich. Sie wird
          vielleicht durch die Ansichtseinstellungen nicht angezeigt oder die
          entsprechende Gruppe ist eingeklappt.

          <template #actions>
            <PmButtonPure
              label="Alle Gruppen ausklappen"
              @click="store.commit('persoplan/projectGroupExpandAll')"
            />

            <PmButtonPure
              label="Ansichtseinstellungen öffnen"
              @click="store.commit('persoplan/showViewEditor')"
            />
          </template>
        </PmAppNotificationPure>
      </portal>
    </PmResourceDayContainerPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceDay'

export const propTypes = {
  showIn: {
    allowed: propTypesContainer.mode.allowed,
  },
  type: {
    allowed: RESOURCE_TYPES,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, toRef, reactive } from 'vue'
import { set, isValid } from 'date-fns'
import { useQuery } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { RESOURCE_TYPES, RESOURCE_TYPE } from '@/constants/persoplan'
import type { ResourceType } from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'

import { startDateForServer, getFirstValidDate } from '@/utilities/date'

import { useXState } from '@/composition/useXState'

import { PmResourceDayState } from '@/components/persoplan/PmResourceDay/PmResourceDayState'

import useResourceDayAddress from '@/components/persoplan/PmResourceDay/useResourceDayAddress'
import useResourceDayVehicle from '@/components/persoplan/PmResourceDay/useResourceDayVehicle'
import type { ConflictItem } from '@/components/persoplan/PmResourceDay/useResourceDayShared'

import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmResourceStateEdit from '@/components/persoplan/PmResourceStateEdit/PmResourceStateEdit.vue'
import PmResourceStateCreate from '@/components/persoplan/PmResourceStateEdit/PmResourceStateCreate.vue'
import PmResourceDayPure from '@/components/persoplan/PmResourceDay/PmResourceDayPure.vue'
import PmResourceDayContainerPure, {
  propTypes as propTypesContainer,
} from '@/components/persoplan/PmResourceDay/PmResourceDayContainerPure.vue'
import type { Props as PropsResourceDayContainerPure } from '@/components/persoplan/PmResourceDay/PmResourceDayContainerPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

import {
  ResourceDayAddressDocument,
  ResourceDayVehicleDocument,
} from '@/../generated/graphql'

export interface Props {
  addressId?: number
  vehicleId?: number
  date?: Date
  startDate?: Date
  endDate?: Date
  popoverElement?: any
  showIn?: PropsResourceDayContainerPure['mode']
  type: ResourceType
  showAllInfo?: boolean
  additionalConflictItems?: ConflictItem[]
}

const props = withDefaults(defineProps<Props>(), {
  showIn: 'popover',
})

/**
 * Check if there's either an addressId or an vehicleId
 */
if (!props.addressId && !props.vehicleId) {
  throw new Error('You either need to provide an addressId or a vehicleId')
}

const emit = defineEmits<{
  (event: 'close'): void
}>()

if (!props.type) {
  throw new Error('prop.type is undefined')
}

/**
 *  Check if either date or startDate and endDate are set
 */
function checkDateProps() {
  if (
    isValid(props.date) &&
    isValid(props.startDate) &&
    isValid(props.endDate)
  ) {
    throw new Error('You should either use date or startDate and endDate')
  }

  // startDate and endDate are set
  if (isValid(props.startDate) && isValid(props.endDate)) {
    return true
  }
  // date is set
  if (isValid(props.date)) {
    return true
  }

  // throw new Error('You need to either set date or startDate and endDate')
  console.error('error in checkDateProps')
}
checkDateProps()

const store = useStore()
const xstate = useXState(PmResourceDayState)

function getDateQueryVariables() {
  const dateVariables: {
    date?: string
    startDate?: string
    endDate?: string
  } = {}

  if (props.date && isValid(props.date)) {
    dateVariables.date = startDateForServer(props.date)
  }

  if (
    props.startDate &&
    isValid(props.startDate) &&
    props.endDate &&
    isValid(props.endDate)
  ) {
    dateVariables.startDate = startDateForServer(props.startDate)
    dateVariables.endDate = startDateForServer(props.endDate)
  }

  return dateVariables
}

const startDateNormalized = computed(() => {
  if (isValid(props.startDate)) return props.startDate
  if (isValid(props.date)) return props.date

  return undefined
})

const endDateNormalized = computed(() => {
  if (isValid(props.endDate)) return props.endDate
  if (isValid(props.date)) return props.date

  return undefined
})

/**
 * Address
 */
const isResourceDayAddressQueryEnabled = computed(() => {
  if (
    props.type !== RESOURCE_TYPE.ADDRESS &&
    props.type !== RESOURCE_TYPE.FREELANCER
  ) {
    return false
  }

  if (!props.addressId) return false

  return true
})

const resourceDayAddressQuery = useQuery(
  ResourceDayAddressDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => {
    return {
      ...getDateQueryVariables(),
      id: props.addressId,
      excludeInactive: props.showAllInfo === true ? false : undefined,
    }
  },
  {
    fetchPolicy: 'cache-and-network',
    enabled: isResourceDayAddressQueryEnabled.value,
  }
)

const agendaAddress = computed(() => {
  return resourceDayAddressQuery.result.value?.agenda
})

const resourceDayAddress = reactive(
  useResourceDayAddress({
    addressId: toRef(props, 'addressId'),
    agenda: agendaAddress,
    additionalConflictItems: toRef(props, 'additionalConflictItems'),
    startDate: startDateNormalized,
    endDate: endDateNormalized,
  })
)

/**
 * Vehicle
 */
const isResourceDayVehicleQueryEnabled = computed(() => {
  if (props.type !== RESOURCE_TYPE.VEHICLE) {
    return false
  }

  if (!props.vehicleId) return false

  return true
})

const resourceDayVehicleQuery = useQuery(
  ResourceDayVehicleDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => {
    return {
      ...getDateQueryVariables(),
      id: props.vehicleId,
      excludeInactive: props.showAllInfo === true ? false : undefined,
    }
  },
  {
    fetchPolicy: 'cache-and-network',
    enabled: isResourceDayVehicleQueryEnabled.value,
  }
)

const agendaVehicle = computed(() => {
  return resourceDayVehicleQuery.result.value?.agenda
})

const resourceDayVehicle = reactive(
  useResourceDayVehicle({
    vehicleId: toRef(props, 'vehicleId'),
    agenda: agendaVehicle,
    additionalConflictItems: toRef(props, 'additionalConflictItems'),
    startDate: startDateNormalized,
    endDate: endDateNormalized,
  })
)

const propsResourceDayPure = computed(() => {
  const shared = {
    day: props.date,
    startDate: props.startDate,
    endDate: props.endDate,
  }

  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    return {
      ...shared,
      key: props.addressId,
      type: RESOURCE_TYPE.ADDRESS,
      title: resourceDayAddress.normalizedTitle,
      items: resourceDayAddress.normalizedItems,
      userCanCreateResourceState:
        resourceDayAddress.userCanCreateOrEditResourceState,
      resourceDayStatus: resourceDayAddress.resourceDayStatus,
    }
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    return {
      ...shared,
      key: props.vehicleId,
      type: RESOURCE_TYPE.VEHICLE,
      title: resourceDayVehicle.normalizedTitle,
      items: resourceDayVehicle.normalizedItems,
      userCanCreateResourceState:
        resourceDayVehicle.userCanCreateOrEditResourceState,
      resourceDayStatus: resourceDayVehicle.resourceDayStatus,
    }
  }

  return undefined
})

const isLoading = computed(() => {
  if (props.type == RESOURCE_TYPE.ADDRESS)
    return resourceDayAddressQuery.loading.value

  if (props.type == RESOURCE_TYPE.VEHICLE)
    return resourceDayVehicleQuery.loading.value

  return false
})

const isNotificationVisible = computed(() => {
  if (props.type == RESOURCE_TYPE.ADDRESS)
    return resourceDayAddress.isNotificationVisible

  if (props.type == RESOURCE_TYPE.VEHICLE)
    return resourceDayVehicle.isNotificationVisible

  return false
})

const closeNotification = () => {
  if (props.type == RESOURCE_TYPE.ADDRESS) {
    resourceDayAddress.isNotificationVisible = false
  }

  if (props.type == RESOURCE_TYPE.VEHICLE) {
    resourceDayVehicle.isNotificationVisible = false
  }
}

const jumpToResourceAllocationInCalendar = (id: number) => {
  if (
    props.type == RESOURCE_TYPE.ADDRESS ||
    props.type == RESOURCE_TYPE.FREELANCER
  ) {
    resourceDayAddress.jumpToResourceAllocationInCalendar(id)
  }

  if (props.type == RESOURCE_TYPE.VEHICLE) {
    resourceDayVehicle.jumpToResourceAllocationInCalendar(id)
  }
}

const suggestedStartDate = computed(() => {
  const date = getFirstValidDate([props.date, props.startDate])
  if (!date) return

  return set(date, {
    hours: 9,
    minutes: 0,
    seconds: 0,
  })
})

const suggestedEndDate = computed(() => {
  const date = getFirstValidDate([props.date, props.startDate])
  if (!date) return

  return set(date, {
    hours: 18,
    minutes: 0,
    seconds: 0,
  })
})

const listenersResourceDayPure = computed(() => {
  return {
    jump: jumpToResourceAllocationInCalendar,
    edit: (resourceStateId: number) =>
      xstate.service.value.send('EDIT_RESOURCE_STATE', {
        resourceStateId,
      }),
    createResourceState: () =>
      xstate.service.value.send('CREATE_RESOURCE_STATE'),
  }
})

const canBeClosed = computed(() => {
  if (xstate.state.value.matches('default')) return true
  return false
})
</script>

<style lang="scss">
.PmResourceDay {
  $block: &;
}
</style>
