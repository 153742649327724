<template>
  <div class="PmContextNavigationListPure">
    <template v-for="item in items" :key="item.id">
      <PmContextNavigationItemPure
        v-if="item.isVisible !== false"
        class="PmContextNavigationListPure-item"
        v-bind="item"
        :disabled="disabled === true ? true : item.disabled"
        @click="$emit('clickOnItem', item.id)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmContextNavigationListPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import PmContextNavigationItemPure, {
  type Props as PropsContextNavigationItemPure,
} from '@/components/basics/PmContextNavigation/PmContextNavigationItemPure.vue'

export interface Item extends PropsContextNavigationItemPure {
  id: string
  href?: PropsContextNavigationItemPure['href']
}

export interface Props {
  label?: string
  items: Item[]
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'clickOnItem', id: string): void
}>()
</script>

<style lang="scss">
.PmContextNavigationListPure {
  $block: &;

  display: flex;
  flex-direction: column;

  &-item {
    position: relative;
    padding: 12px;

    &:not(:last-child) {
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: color.$gray-300;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}
</style>
