<template>
  <PmEventsPure :container-start-date="startDate" :container-end-date="endDate">
    <PmEventsItemPure
      v-for="event in normalizedEvents"
      :key="event.id"
      v-slot="{ styles, isConstrainedStart, isConstrainedEnd }"
      :start-date="event.startDate"
      :end-date="event.endDate"
      :container-start-date="startDate"
      :container-end-date="endDate"
    >
      <PmGeneralEventPure
        :style="styles"
        v-bind="event"
        :is-constrained-start="isConstrainedStart"
        :is-constrained-end="isConstrainedEnd"
      />
    </PmEventsItemPure>
  </PmEventsPure>
</template>

<script>
import {
  startDateForServer,
  endDateForServer,
  parseServerDateString,
} from '@/utilities/date'

import { getLocationForAddress } from '@/utilities/string'

import PmEventsPure from '@/components/persoplan/PmGeneralEvents/PmEventsPure.vue'
import PmEventsItemPure from '@/components/persoplan/PmGeneralEvents/PmEventsItemPure.vue'
import PmGeneralEventPure from '@/components/persoplan/PmGeneralEvents/PmGeneralEventPure.vue'

import GeneralEventsQuery from '@/components/persoplan/PmGeneralEvents/GeneralEventsQuery.graphql'

export default {
  name: 'PmGeneralEvents',
  components: {
    PmEventsPure,
    PmEventsItemPure,
    PmGeneralEventPure,
  },

  props: {
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
  },

  data() {
    return {
      publicHolidays: [],
      easyJobEvents: [],
    }
  },

  computed: {
    normalizedEvents() {
      // TODO: Sort by duration, so long events are on top
      return [...this.normalizedPublicHolidays, ...this.normalizedEasyJobEvents]
    },

    normalizedPublicHolidays() {
      return this.publicHolidays.map((publicHoliday) => {
        return {
          id: publicHoliday.id,
          name: publicHoliday.caption,
          startDate: parseServerDateString(publicHoliday.startDate),
          endDate: parseServerDateString(publicHoliday.endDate),
          showDates: false,
        }
      })
    },

    normalizedEasyJobEvents() {
      return this.easyJobEvents.map((easyJobEvent) => {
        const basics = {
          id: easyJobEvent.id,
          name: easyJobEvent.caption,
          startDate: parseServerDateString(easyJobEvent.startDate),
          endDate: parseServerDateString(easyJobEvent.endDate),
        }

        if (easyJobEvent.calendar.caption === 'Feiertag') {
          return {
            ...basics,
            description: easyJobEvent.description,
          }
        }

        if (easyJobEvent.calendar.caption === 'Veranstaltung') {
          return {
            ...basics,
            description: easyJobEvent.description,
            company: easyJobEvent.address?.displayName,
            location: getLocationForAddress(easyJobEvent.address),
            color: easyJobEvent.color,
          }
        }

        if (easyJobEvent.calendar.caption === 'Kalendereintrag') {
          return {
            ...basics,
            description: easyJobEvent.description,
            company: easyJobEvent.address?.displayName,
            location: getLocationForAddress(easyJobEvent.address),
            color: easyJobEvent.color,
          }
        }

        return basics
      })
    },
  },

  apollo: {
    events: {
      query: GeneralEventsQuery,

      variables() {
        return {
          startDate: startDateForServer(this.startDate),
          endDate: endDateForServer(this.endDate),
        }
      },

      update(data) {
        this.publicHolidays = data.publicHolidays
        this.easyJobEvents = data.easyJobEvents
      },
    },
  },
}
</script>

<style lang="scss">
.PmGeneralEvents {
  $block: &;
}
</style>
