import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

import { USE_SENTRY } from '@/constants/general'

import { attachStuffToAppInstance } from '@/shared'
import App from '@/App.vue'
import { router } from '@/router'
import '@/routes'

import VERSION from '@/../VERSION?raw'

// Vue.config.productionTip = false

const app = createApp(App)

if (USE_SENTRY) {
  Sentry.init({
    app,
    dsn: 'https://b7254c0f743849769cbd6bb4744e975c@o208571.ingest.sentry.io/1330138',
    release: VERSION,
    environment: import.meta.env.VITE_ENVIRONMENT,
    // integrations: [
    //   new Integrations.BrowserTracing({
    //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    //     // TODO: Put urls in a constants file
    //     tracingOrigins: [
    //       'localhost',
    //       'inside.promusik.de',
    //       'inside-beta.promusik.de',
    //       'ext.promusik.de',
    //       /^\//,
    //     ],
    //   }),o
    // ],

    // Logs vue errors to console when using sentry
    logErrors: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    /**
     * @see: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/sampling/
     */
    tracesSampleRate: 1.0,
  })
}

attachStuffToAppInstance(app)
app.use(router)

/**
 * Mount app only after router is ready, to have every query variable available
 * @see https://router.vuejs.org/guide/migration/#all-navigations-are-now-always-asynchronous
 */
router.isReady().then(() => {
  app.mount('#app')
})
