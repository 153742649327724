<template>
  <PmNotificationContainerPure
    notification-type="leaveRequest"
    :date="date"
    :is-unread="isUnread"
    message="Dein Urlaubsantrag wurde abgelehnt"
  >
    <template #meta>
      <PmMetaListItemPure label="Typ">
        {{ LEAVE_REQUEST_TYPE_LABEL_LOOKUP[type] }}
      </PmMetaListItemPure>

      <PmMetaListItemPure label="Datum">
        <template v-if="startDate && endDate">
          {{
            startEndDateForText(startDate, endDate, {
              showTime: false,
            })
          }}
        </template>
      </PmMetaListItemPure>
    </template>

    <template #actions>
      <PmButtonListPure>
        <PmButtonPure label="Details" @click="emit('openDetails')" />
      </PmButtonListPure>
    </template>
  </PmNotificationContainerPure>
</template>

<script setup lang="ts">
import PmNotificationContainerPure, {
  type Props as PropsNotificationContainerPure,
} from '@/components/PmNotification/PmNotificationContainerPure.vue'
import PmMetaListItemPure from '@/components/PmMetaList/PmMetaListItemPure.vue'
import { startEndDateForText } from '@/utilities/date'
import {
  type LeaveRequestType,
  LEAVE_REQUEST_TYPE_LABEL_LOOKUP,
} from '@/constants/leaveRequest'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import type { Nilable } from '@/types/misc'

export interface Props
  extends Pick<PropsNotificationContainerPure, 'date' | 'isUnread'> {
  leaveRequestId: number
  startDate: Nilable<Date>
  endDate: Nilable<Date>
  type: LeaveRequestType
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  openDetails: []
}>()
</script>
