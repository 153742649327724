<template>
  <div
    class="PmTooltipPure"
    @mouseenter="isVisible = true"
    @mouseleave="isVisible = false"
  >
    <div ref="elElement" class="PmTooltipPure-content">
      <slot />
    </div>

    <portal to="tooltip">
      <PmPopoverWithTransitionPure
        v-if="isVisible"
        :is-visible="isVisible"
        :element="elElement"
        is-positioned-on="element"
        :class="[`${$options.name}-tooltip`]"
      >
        <slot name="tooltip" />
      </PmPopoverWithTransitionPure>
    </portal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmTooltipPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref } from 'vue'

import PmPopoverWithTransitionPure from '@/components/basics/PmPopover/PmPopoverWithTransitionPure.vue'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const isVisible = ref(false)
const elElement = ref<HTMLElement | undefined>()
</script>

<style lang="scss">
.PmTooltipPure {
  $block: &;

  &-content {
    // display: inline-block; // This causes some problems in PmResourceAllocationPure
  }

  &-tooltip {
    max-width: 600px;
  }
}
</style>
