<template>
  <PmMultipleResourceAllocationsControllerPure
    :number-of-selected="selectionResourceAllocations.size"
    :type="selectionResourceAllocations.selectionType"
    :state="xstate.path.value"
    :can-edit-resource-request="can('edit', 'resourceRequest')"
    @cancel="selectionResourceAllocations.exitSelectionMode"
    @show-delete="emit('openDeleteDialog')"
    @show-update="emit('openUpdateDialog')"
    @show-request="emit('openRequestDialog')"
    @clear-selection="selectionResourceAllocations.clear"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsControllerPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { useAppAbility } from '@/composition/useAppAbility'

import { useSelectionResourceAllocations } from '@/pinia/selectionResourceAllocations'
import { useXState } from '@/composition/useXState'

import { PmMultipleResourceAllocationsControllerState } from '@/components/persoplan/PmMultipleResourceAllocationsController/PmMultipleResourceAllocationsControllerState'

import PmMultipleResourceAllocationsControllerPure from '@/components/persoplan/PmMultipleResourceAllocationsController/PmMultipleResourceAllocationsControllerPure.vue'

export interface Props {
  exampleArg?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openDeleteDialog'): void
  (event: 'openUpdateDialog'): void
  (event: 'openRequestDialog'): void
}>()

const { can } = useAppAbility()
const selectionResourceAllocations = useSelectionResourceAllocations()

const xstate = useXState(PmMultipleResourceAllocationsControllerState)
</script>

<style lang="scss">
.PmMultipleResourceAllocationsController {
  $block: &;
}
</style>
