<template>
  <PmSidebarProjectPure
    v-if="stockTypeService"
    :id="stockTypeService.id"
    :title="stockTypeService.caption"
    :alerts-visible="false"
    :pill="stockTypeService.number"
    :update-group-visible="false"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarStockTypeService'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { useCachedQuery } from '@/composition/useCachedQuery'

import PmSidebarProjectPure from '@/components/persoplan/PmSidebarProject/PmSidebarProjectPure.vue'

import { SidebarStockTypeServiceDocument } from '@/../generated/graphql'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const stockTypeServiceQuery = useCachedQuery(
  SidebarStockTypeServiceDocument,
  () => ({
    id: props.id,
  })
)

const stockTypeService = computed(
  () => stockTypeServiceQuery.result.value?.stockTypeService
)
</script>
