<template>
  <div class="PmDataModal">
    <portal to="modal">
      <DataModal
        v-if="visible"
        :id="id"
        ref="dataModal"
        title="Data Modal"
        :type="type"
        :note="note"
        @close="visible = false"
      />
    </portal>
  </div>
</template>

<script>
import { EVENT } from '@/constants/events'

import DataModal from '@/components/persoplan/DataModal/DataModal.vue'

export default {
  name: 'PmDataModal',
  components: {
    DataModal,
  },

  data() {
    return {
      visible: false,
      type: undefined,
      id: undefined,
      note: undefined,
    }
  },

  created() {
    this.$eventHub.$on(EVENT.DATA_MODAL_SHOW, this.showDataModal)
  },

  beforeUnmount() {
    this.$eventHub.$on(EVENT.DATA_MODAL_SHOW, this.showDataModal)
  },

  methods: {
    async showDataModal(event) {
      // Properly unmount the old one first, before showing the next one:
      this.visible = false

      await this.$nextTick()

      this.type = event.type
      this.id = event.id
      this.note = event.note
      this.visible = true
    },
  },
}
</script>

<style lang="scss">
.PmDataModal {
  $block: &;
}
</style>
