<template>
  <PmSidebarContainerPure name="sidebarLeft" class="PmSidebarPure">
    <div class="PmSidebarPure-settings">
      <slot name="settings"></slot>
    </div>

    <div ref="content" class="PmSidebarPure-content" :style="stylesContent">
      <div v-show="contentVisible" class="PmSidebarPure-contentInner">
        <slot />

        <div class="PmSidebarPure-jobsContainer">
          <div class="PmSidebarPure-jobsShadowsContainer">
            <div
              class="PmSidebarPure-jobsShadows"
              :style="stylesJobShadows"
            ></div>
          </div>

          <div
            ref="jobsContainerScroll"
            class="PmSidebarPure-jobsContainerScroll"
          >
            <div
              ref="sentinelStart"
              class="PmSidebarPure-sentinel PmSidebarPure-sentinel--start"
            ></div>

            <div ref="jobs" class="PmSidebarPure-jobs">
              <slot name="jobs" />
            </div>

            <div
              ref="sentinelEnd"
              class="PmSidebarPure-sentinel PmSidebarPure-sentinel--end"
            ></div>
          </div>
        </div>

        <slot name="bottom" />
      </div>
    </div>
  </PmSidebarContainerPure>
</template>

<script>
// TODO: Consider horizontal scrollbar height

import PmSidebarContainerPure from '@/components/persoplan/PmSidebarContainer/PmSidebarContainerPure.vue'

export default {
  name: 'PmSidebarPure',
  components: {
    PmSidebarContainerPure,
  },

  props: {
    contentVisible: { type: Boolean, default: true },
  },

  data() {
    return {
      mounted: undefined,
      resizeObserverJobs: undefined,
      resizeObserverSidebar: undefined,
      intersectionObserver: undefined,
      shadowStartVisibility: 0,
      shadowEndVisibility: 0,
      contentHeight: undefined,
    }
  },

  computed: {
    stylesContent() {
      return {
        height: this.contentHeight ? `${this.contentHeight}px` : undefined,
      }
    },

    stylesJobShadows() {
      return {
        [`--${this.$options.name}-shadowStartVisibility`]:
          this.shadowStartVisibility,
        [`--${this.$options.name}-shadowEndVisibility`]:
          this.shadowEndVisibility,
      }
    },
  },

  watch: {
    contentVisible(value, oldValue) {
      /**
       * Save and restore content height, otherwise the sidebar will collapse
       */
      if (value === false && oldValue === true) {
        this.contentHeight = this.$refs.content.getBoundingClientRect().height
      }

      if (value === true && oldValue === false) {
        this.contentHeight = null
      }
    },
  },

  mounted() {
    this.mounted = true

    this.resizeObserverJobs = new ResizeObserver(this.onResizeJobs)
    this.resizeObserverJobs.observe(this.$refs.jobs)

    this.resizeObserverSidebar = new ResizeObserver(this.onResizeSidebar)
    this.resizeObserverSidebar.observe(this.$el)

    const treshold = new Array(101)
      .fill(undefined)
      .map((item, index) => index / 100)

    this.intersectionObserver = new IntersectionObserver(this.onIntersection, {
      root: this.$refs.jobsContainerScroll,
      threshold: treshold,
    })
    this.intersectionObserver.observe(this.$refs.sentinelStart)
    this.intersectionObserver.observe(this.$refs.sentinelEnd)
  },

  beforeUnmount() {
    this.resizeObserverJobs.disconnect()
    this.resizeObserverSidebar.disconnect()
  },

  methods: {
    onResizeJobs() {
      if (!this.contentVisible) return
      const height = this.$refs.jobs.getBoundingClientRect().height

      this.$store.commit('cssVar/set', {
        name: `sidebarJobsHeight`,
        value: height,
      })
    },

    onResizeSidebar() {
      const height = this.$el.getBoundingClientRect().height

      this.$store.commit('cssVar/set', {
        name: `sidebarHeight`,
        value: height,
      })
    },

    onIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.target === this.$refs.sentinelStart) {
          this.shadowStartVisibility = (1 - entry.intersectionRatio).toFixed(2)
        }

        if (entry.target === this.$refs.sentinelEnd) {
          this.shadowEndVisibility = (1 - entry.intersectionRatio).toFixed(2)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmSidebarPure {
  $block: &;

  // stylelint-disable-next-line scss/operator-no-newline-after
  $removeFromHeight: var(--PmCalendarPure-headerHeight) + ' - ' +
    var(--navigationHeight) + ' - ' + var(--horizontalScrollbarHeight);

  @supports not (min-height: 100dvh) {
    @include cssVar.define(
      $block,
      'maxHeight',
      calc(var(--appHeight) - $removeFromHeight)
    );
  }

  @include cssVar.define($block, 'maxHeight', calc(100dvh - $removeFromHeight));

  &-settings {
    background-color: color.$gray-100;
    border-bottom: 1px solid color.$gray-300--alpha;

    // TODO: this causes performance problems in Firefox
    // position: fixed;
    position: sticky;
    top: var(--navigationHeight);
    left: 0;
    min-width: 0;
    width: var(--sidebarLeftWidth);
    z-index: 6;
  }

  &-content {
    position: relative;
  }

  &-jobsShadowsContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
  }

  &-jobsShadows {
    @include cssVar.define($block, 'shadowStartVisibility', 0);
    @include cssVar.define($block, 'shadowEndVisibility', 0);

    width: 100%;
    position: sticky;
    top: calc(var(--PmCalendarPure-headerHeight) + var(--navigationHeight));
    overflow: hidden;
    height: var(--sidebarJobsHeight);
    max-height: cssVar.use($block, 'maxHeight');

    $height: 50px;

    &::before,
    &::after {
      content: '';
      height: $height;
      position: absolute;
      width: calc(100% + 40px);
      left: -20px;
    }

    // TODO: Use https://larsenwork.com/easing-gradients/#editor
    &::before {
      @include shadow.default('low');

      top: -$height;
      opacity: cssVar.use($block, 'shadowStartVisibility');
    }

    &::after {
      @include shadow.default('low');

      bottom: -$height;
      transform: scaleY(-1);
      opacity: cssVar.use($block, 'shadowEndVisibility');
    }
  }

  &-jobsContainer {
    position: relative;
    min-height: var(--jobsHeight);
  }

  &-jobsContainerScroll {
    @include mixin.betterScrollbars;

    max-height: cssVar.use($block, 'maxHeight');
    overflow-y: auto;
    overscroll-behavior: contain;
    position: sticky;
    top: calc(var(--PmCalendarPure-headerHeight) + var(--navigationHeight));
  }

  &-sentinel {
    $height: 40px;

    height: $height;
    max-height: 100%;
    width: 100%;

    &--start {
      top: 0;
      margin-bottom: -$height;
    }

    &--end {
      bottom: 0;
      margin-top: -$height;
    }
  }
}
</style>
