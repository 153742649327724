<template>
  <div ref="elRoot" class="PmCheckboxPure" :class="classes">
    <label class="PmCheckboxPure-inner">
      <div class="PmCheckboxPure-checkboxContainer">
        <input
          class="PmCheckboxPure-input"
          type="checkbox"
          :disabled="disabled"
          :checked="typeof value === 'boolean' && value"
          :indeterminate="value === 'indeterminate'"
          :required="required"
          @input="onInput"
        />

        <div class="PmCheckboxPure-checkbox">
          <PmIconPure v-if="icon" :name="icon" class="PmCheckboxPure-icon" />
        </div>
      </div>

      <div class="PmCheckboxPure-content">
        <div class="PmCheckboxPure-label">
          <slot>
            {{ label }}
          </slot>
        </div>

        <div v-if="note" class="PmCheckboxPure-note">
          {{ note }}
        </div>
      </div>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmCheckboxPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, useSlots, ref } from 'vue'

import { type Icon } from '@/constants/icons'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  value?: boolean | 'indeterminate'
  disabled?: boolean
  size?: 'default' | 'small'
  /** Label for checkbox. If a slot is present, this will be overridden */
  label?: string | null
  note?: string
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'default',
})

const emit = defineEmits<{
  (event: 'input', value: boolean): void
  (event: 'update:value', value: boolean): void
  (event: 'select'): void
  (event: 'unselect'): void
}>()

const slots = useSlots()
const elRoot = ref<HTMLDivElement>()

defineExpose({
  elRoot,
})

function onInput(event: Event) {
  if (!(event.target instanceof HTMLInputElement)) {
    throw new Error('event.target is not HTMLInputElement')
  }

  emit('input', event.target.checked)
  emit('update:value', event.target.checked)

  event.target.checked ? emit('select') : emit('unselect')
}

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--sizeDefault`]: props.size === 'default',
    [`${COMPONENT_NAME}--sizeSmall`]: props.size === 'small',
    [`${COMPONENT_NAME}--noLabel`]: !hasLabel.value,
    'is-disabled': props.disabled,
    'is-checked': props.value,
    'is-required': props.required,
  }
})

const hasLabel = computed(() => {
  if (props.label) return true
  return slots.default ? true : false
})

const icon = computed<Icon | undefined>(() => {
  if (props.value === true) return 'check'
  if (props.value === 'indeterminate') {
    return 'minus'
  }

  return undefined
})
</script>

<style lang="scss">
.PmCheckboxPure {
  $block: &;

  @include cssVar.define($block, 'colorBackground', color.$gray-200--alpha);
  @include cssVar.define(
    $block,
    'colorBackground-hover',
    color.$gray-300--alpha
  );
  @include cssVar.define($block, 'colorIcon', color.$white);

  &--sizeDefault {
    @include cssVar.define($block, 'size', 20px);
  }

  &--sizeSmall {
    @include cssVar.define($block, 'size', 17px);
  }

  &.is-disabled {
    pointer-events: none;

    @include cssVar.define($block, 'colorBackground', color.$gray-100--alpha);
    @include cssVar.define($block, 'colorIcon', color.$gray-400--alpha);
  }

  &.is-checked:not(&.is-disabled) {
    @include cssVar.define($block, 'colorBackground', color.$primary-500);
    @include cssVar.define($block, 'colorBackground-hover', color.$primary-600);
  }

  display: flex;
  justify-content: flex-start;

  &-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: constant.$borderRadius-default;

    #{$block}--noLabel & {
      padding: unset;
    }
  }

  &-checkboxContainer {
    display: flex;
    align-items: center;
    min-height: cssVar.use($block, 'size');

    #{$block}:not(#{$block}--noLabel) & {
      // Alignment for multi-line labels
      height: #{constant.$lineHeight-default}em;
    }
  }

  &-input {
    @include mixin.visuallyHidden;
  }

  &-checkbox {
    // @include mixin.transition-hover((background-color), $block);

    margin-right: 8px;
    background-color: cssVar.use($block, 'colorBackground');
    border-radius: constant.$borderRadius-default;
    width: cssVar.use($block, 'size');
    height: cssVar.use($block, 'size');

    #{$block}:hover &,
    #{$block}.is-hover & {
      background-color: cssVar.use($block, 'colorBackground-hover');
    }

    #{$block}-input:focus ~ &,
    #{$block}.is-focus & {
      .is-keyboardNavigation & {
        box-shadow: 0 0 0 2px rgba(color.$key, 0.5);
      }
    }

    #{$block}--noLabel & {
      margin-right: unset;
    }
  }

  &-icon {
    padding: 3px;
    display: none;
    color: cssVar.use($block, 'colorIcon');

    #{$block}-input:checked + #{$block}-checkbox &,
    #{$block}.is-checked & {
      display: block;
    }
  }

  &-content {
    #{$block}--sizeDefault & {
      padding-top: 2px;
    }

    #{$block}.is-disabled & {
      opacity: 0.7;
    }
  }

  &-label {
    #{$block}.is-required & {
      &::after {
        @include mixin.requiredAsterisk;
      }
    }
  }

  &-note {
    margin-top: 4px;
    font-size: constant.$fontSize-default;
    color: color.$gray-600;
    font-weight: 500;
  }
}
</style>
