/**
 * @todo Check if everything is working properly
 */

import { onBeforeUnmount, reactive, watch } from 'vue'
import { debounce } from 'lodash-es'
import { useStore } from 'vuex'

import { SCROLL_DIRECTION } from '@/constants/general'
import type { TScrollDirection } from '@/constants/general'
import { EVENT } from '@/constants/events'

import EventHub from '@/eventHub'

interface IViewport {
  mouseX: number
  mouseY: number
  scrollTop: number
  scrollLeft: number
  scrollDirection?: TScrollDirection
  horizontalScrollbarHeight: number
  verticalScrollbarWidth: number
  appHeight?: number
}

export const viewport = reactive<IViewport>({
  mouseX: 0,
  mouseY: 0,
  scrollTop: 0,
  scrollLeft: 0,
  scrollDirection: undefined,
  horizontalScrollbarHeight: 0,
  verticalScrollbarWidth: 0,
  appHeight: undefined,
})

export function useViewportObserver() {
  const store = useStore()

  const updateScrollbarDimensionsDebounced = debounce(() => {
    updateScrollbarDimensions()
  }, 100)

  EventHub.$on(
    EVENT.UPDATE_SCROLLBAR_DIMENSIONS,
    updateScrollbarDimensionsDebounced
  )

  function setMouseCoordinates(event: MouseEvent) {
    viewport.mouseX = event.clientX
    viewport.mouseY = event.clientY
  }

  function setScrollTop() {
    viewport.scrollTop = document?.scrollingElement?.scrollTop || 0
  }

  function setScrollLeft() {
    viewport.scrollTop = document?.scrollingElement?.scrollLeft || 0
  }

  watch(() => viewport.scrollTop, setScrollDirection)
  function setScrollDirection(to: number, from: number) {
    viewport.scrollDirection =
      to > from ? SCROLL_DIRECTION.DOWN : SCROLL_DIRECTION.UP
  }

  function updateScrollbarDimensions() {
    viewport.horizontalScrollbarHeight =
      window.innerHeight - document.documentElement.clientHeight

    store.commit('cssVar/set', {
      name: `horizontalScrollbarHeight`,
      value: viewport.horizontalScrollbarHeight,
    })

    viewport.verticalScrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth

    store.commit('cssVar/set', {
      name: `verticalScrollbarWidth`,
      value: viewport.verticalScrollbarWidth,
    })
  }

  async function setAppHeight() {
    viewport.appHeight = window.innerHeight

    store.commit('cssVar/set', {
      name: `appHeight`,
      value: viewport.appHeight,
    })
  }

  const create = async () => {
    window.addEventListener('mousemove', setMouseCoordinates, { passive: true })
    window.addEventListener('scroll', setScrollTop, { passive: true })
    window.addEventListener('scroll', setScrollLeft, { passive: true })
    window.addEventListener('resize', updateScrollbarDimensionsDebounced)
    window.addEventListener('resize', setAppHeight)

    /**
     * This is necessary, otherwise there's some problems because this vue instance gets created too early
     * Error is something like „don't use vue composition api functions before Vue.use(VueCompositionAPI)
     * @todo This component should be a composable instead
     * @todo Check if the error still persistes with the composable
     */
    await new Promise((resolve) => setTimeout(resolve, 0))

    updateScrollbarDimensionsDebounced()
    setAppHeight()
  }

  const destroy = () => {
    window.removeEventListener('mousemove', setMouseCoordinates)
    window.removeEventListener('scroll', setScrollTop)
    window.removeEventListener('scroll', setScrollLeft)
    window.removeEventListener('resize', updateScrollbarDimensionsDebounced)
    window.removeEventListener('resize', setAppHeight)
  }

  create()
  onBeforeUnmount(destroy)
}
