import { isNil, uniq } from 'lodash-es'
import { getDisplayNameOfAddress } from '@/utilities/string'
import type { Nilable } from '@/types/misc'

import {
  type NotificationBase,
  type NotificationFromQuery,
} from '@/store/notifications/notifications'
import type { Props as PropsNotificationResourceRequestFeedback } from '@/components/PmNotification/PmNotificationResourceRequestFeedback/PmNotificationResourceRequestFeedback.vue'
import { parseServerDateString } from '@/utilities/date'

type ResourceRequestFromQuery = Extract<
  NotificationFromQuery['details'],
  { __typename?: 'ResourceRequest' }
>

export type NotificationResourceRequestFeedbackStateUpdated = {
  type: 'ResourceRequestFeedbackStateUpdated'
  props: Omit<PropsNotificationResourceRequestFeedback, keyof NotificationBase>
}

export function normalizeNotificationResourceRequestFeedbackStateUpdated(
  notification: NotificationFromQuery
) {
  if (notification.details?.__typename !== 'ResourceRequest') {
    throw new Error('notification.details.__typename is not ResourceRequest')
  }

  if (notification.meta?.__typename !== 'ResourceRequestFeedbackStateUpdated') {
    throw new Error(
      'notification.meta.__typename is not ResourceRequestFeedbackStateUpdated'
    )
  }

  const isNew = notification.meta?.stateBefore === null

  // Normalize resourceAllocationIds
  const resourceAllocationIds: NotificationResourceRequestFeedbackStateUpdated['props']['resourceAllocationIds'] =
    []

  notification.details.resourceAllocationIds?.forEach(
    (resourceAllocationId) => {
      if (isNil(resourceAllocationId)) return
      resourceAllocationIds.push(resourceAllocationId)
    }
  )

  /**
   * Normalize name
   */
  checkIfSingleton(notification.details, 'address')
  const firstAddress = notification.details.resourceAllocations?.[0]?.address
  const name = getDisplayNameOfAddress(firstAddress)
  if (!name) throw new Error('name is not defined')

  /**
   * Normalize job
   */
  type JobNormalized = Required<
    NotificationResourceRequestFeedbackStateUpdated['props']
  >['jobs'][number]

  const jobsNormalized = new Map<number, JobNormalized>()

  notification.details.resourceAllocations?.forEach((resourceAllocation) => {
    const job = resourceAllocation?.resourceFunctionAllocation?.job
    if (!job) return

    const startDate = parseServerDateString(job.startDate)
    if (!startDate) throw new Error('startDate is undefined')
    const endDate = parseServerDateString(job.endDate)
    if (!endDate) throw new Error('endDate is undefined')

    const jobNormalized: JobNormalized = {
      id: job.id,
      startDate,
      endDate,
    }

    jobsNormalized.set(job.id, jobNormalized)
  })

  /**
   * Normalize projectLeader and other stuff on project
   */
  checkIfSingleton(notification.details, 'project')

  const firstProject =
    notification.details.resourceAllocations?.[0]?.resourceFunctionAllocation
      ?.job?.project

  if (!firstProject) throw new Error('firstProject is undefined')
  if (!firstProject.arrangedBy)
    throw new Error('firstProject.arrangedBy is undefined')

  const projectStartDate = parseServerDateString(firstProject.startDate)
  if (!projectStartDate) throw new Error('projectStartDate is undefined')

  const projectEndDate = parseServerDateString(firstProject.endDate)
  if (!projectEndDate) throw new Error('projectEndDate is undefined')

  const projectLeader = {
    firstName: firstProject.arrangedBy.firstName ?? 'n/a',
    lastName: firstProject.arrangedBy.surname ?? 'n/a',
  }

  /**
   * Normalize calendar item
   * This is needed for jumping
   */
  const calendarItemType = firstProject.setting?.isJobLayerHidden
    ? 'project'
    : 'job'

  let calendarItem:
    | NotificationResourceRequestFeedbackStateUpdated['props']['calendarItem']
    | undefined = undefined

  if (calendarItemType === 'project') {
    calendarItem = {
      type: calendarItemType,
      id: firstProject.id,
      startDate: projectStartDate,
      endDate: projectEndDate,
    }
  }

  if (calendarItemType === 'job') {
    if (jobsNormalized.size === 1) {
      const firstJobNormalized = Array.from(jobsNormalized.values())[0]

      calendarItem = {
        type: calendarItemType,
        id: firstJobNormalized.id,
        startDate: firstJobNormalized.startDate,
        endDate: firstJobNormalized.endDate,
      }
    }
  }

  console.log('resourceAllocationIds', resourceAllocationIds)

  const result: NotificationResourceRequestFeedbackStateUpdated = {
    type: 'ResourceRequestFeedbackStateUpdated',

    props: {
      type: isNew ? 'created' : 'updated',
      statusBefore: notification.meta?.stateBefore,
      statusAfter: notification.meta?.stateAfter,
      name: name,
      projectLeader: projectLeader,
      projectNumber: firstProject.number,
      projectName: firstProject.caption,
      jobs: Array.from(jobsNormalized.values()),
      status: {
        updatedBy: getDisplayNameOfAddress({
          firstName: notification.details.updatedBy?.firstName,
          surname: notification.details.updatedBy?.surname,
        }),
        updatedAt: parseServerDateString(notification.details.updated),
        status: notification.details.state,
      },
      resourceAllocationIds,
      calendarItem: calendarItem,
    },
  }

  return result
}

/**
 * A resource request can contain multiple resource allocations but tye all should
 * belong to the same address, job, project
 * This is a helper function to check if this is true
 */
// REFERENCE: This functions exists here aswell: wx02coi
function checkIfSingleton(
  resourceRequest: ResourceRequestFromQuery,
  type: 'project' | 'job' | 'address'
) {
  if (!resourceRequest) return

  const allIds: number[] = []

  resourceRequest.resourceAllocations?.forEach((resourceAllocation) => {
    if (!resourceAllocation) return
    let id: Nilable<number> = null

    if (type === 'project') {
      id = resourceAllocation.resourceFunctionAllocation?.job?.project?.id
    }

    if (type === 'job') {
      id = resourceAllocation.resourceFunctionAllocation?.job?.id
    }

    if (type === 'address') {
      id = resourceAllocation.address?.id
    }

    if (isNil(id)) return

    allIds.push(id)
  })

  // Check if same length
  if (allIds.length !== resourceRequest.resourceAllocations?.length) {
    throw new Error(`lengths don't match!`)
  }

  // Check if only on id
  const uniqueLength = uniq(allIds).length
  if (uniqueLength !== 1) {
    throw new Error('ids are not unique')
  }

  return true
}

/**
 * Filters for all notifications of type notification: ResourceRequestFeedbackStateUpdated
 */
export function filterNotificationResourceRequestFeedbackStateUpdated(
  notification: NotificationFromQuery
) {
  if (isNotificationForDeletedResourceAllocation(notification)) return false

  return true
}

/**
 * In case there details.resourceAllocations is empty and/or not the same length
 * as details.resourceAllocationIds the Notification belongs to an resourceAllocation
 * which was deleted. The API deletes notifications when deleting an allocation but
 * when deleted via easyjob the cron runs after some time only which could lead to this state
 * @see https://gitlab.dev.innovation-agents.de/pro-musik/api/-/issues/248
 */
function isNotificationForDeletedResourceAllocation(
  notification: NotificationFromQuery
) {
  // When details is not available, resource allocation is probably deleted
  if (isNil(notification.details)) return true

  if (notification.details?.__typename !== 'ResourceRequest') {
    throw new Error('notification.details.__typename is not ResourceRequest')
  }

  if (notification.meta?.__typename !== 'ResourceRequestFeedbackStateUpdated') {
    throw new Error(
      'notification.meta.__typename is not ResourceRequestFeedbackStateUpdated'
    )
  }

  if (!notification.details.resourceAllocationIds) {
    throw new Error('resourceAllocationIds is undefined')
  }

  if (!notification.details.resourceAllocations) {
    throw new Error('resourceAllocations is undefined')
  }

  return (
    notification.details.resourceAllocationIds.length !==
    notification.details.resourceAllocations.length
  )
}
