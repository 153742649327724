<template>
  <div :class="componentClass.root">
    <div v-if="hasHeader" :class="componentClass.element('header')">
      <div
        v-if="hasSlotContent.header"
        :class="componentClass.element('headerSlot')"
      >
        <slot name="header" />
      </div>

      <div :class="componentClass.element('meta')">
        {{ label }}<template v-if="label && date">, </template>

        <template v-if="date">{{ formatToRelativeDate(date) }} </template>
      </div>
    </div>

    <div
      v-if="additionalMetaNormalized && additionalMetaNormalized.length > 0"
      :class="componentClass.element('additionalMeta')"
    >
      <PmRichTextPure>
        <ul>
          <li v-for="item in additionalMetaNormalized" :key="item">
            {{ item }}
          </li>
        </ul>
      </PmRichTextPure>
    </div>

    <div
      v-if="hasSlotContent.default"
      :class="componentClass.element('content')"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { formatToRelativeDate } from '@/utilities/date'
import { useHasSlotContent } from '@/composition/useHasSlotContent'
import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'

export interface Props {
  label?: string
  date?: Date
  additionalMeta?: string | string[]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
const hasSlotContent = useHasSlotContent(['header', 'default'])

const hasHeader = computed(() => {
  if (hasSlotContent.value.header) return true
  if (props.label) return true
  if (props.date) return true
  return false
})

const additionalMetaNormalized = computed(() => {
  if (Array.isArray(props.additionalMeta)) {
    if (props.additionalMeta.length === 0) return
    return props.additionalMeta
  }

  if (!Array.isArray(props.additionalMeta)) {
    if (!props.additionalMeta) return
    if (props.additionalMeta.trim().length === 0) return
    return [props.additionalMeta]
  }

  return undefined
})
</script>

<style lang="scss">
.PmTimelineItemPure {
  $block: &;

  width: 100%;
  position: relative;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &::before {
    $size: 12px;

    content: '';
    width: $size;
    height: $size;
    border-radius: $size;
    background-color: color.$white;
    border: 3px solid color.$gray-300;
    outline: px solid color.$white;
    position: absolute;
    left: -4px;
    top: 3px;
  }

  &-header {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    min-height: 17px;
  }

  &-headerSlot {
    max-height: 17px;
    display: flex;
    align-items: center;
  }

  &-meta {
    @include mixin.textLabel;

    line-height: 14px * 1.2;
  }

  &-additionalMeta {
    @include mixin.textLabel;
  }

  &-status {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
</style>
