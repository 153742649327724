import { createMachine } from 'xstate'

import type { Typegen0 } from './PmLoginState.typegen'
export type TState = Typegen0['matchesStates']

export const PmLoginState = createMachine({
  tsTypes: {} as import('./PmLoginState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'CHECK_IF_LOGGED_IN' } | { type: 'LOGIN' },
    services: {} as {
      checkIfLoggedIn: any
      autoLogin: any
      loginWithCredentials: any
    },
    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmLoginState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        CHECK_IF_LOGGED_IN: 'checkIfLoggedIn',
      },
    },

    checkIfLoggedIn: {
      invoke: {
        id: 'check-if-logged-in',
        src: 'checkIfLoggedIn',
        onError: 'autoLogin',
        onDone: 'login.success',
      },
    },

    autoLogin: {
      invoke: {
        id: 'check-if-logged-in',
        src: 'autoLogin',
        onError: 'login',
        onDone: 'login.success',
      },
    },

    login: {
      initial: 'default',

      states: {
        default: {
          on: {
            LOGIN: 'loggingIn',
          },
        },

        loggingIn: {
          invoke: {
            id: 'login-with-credentials',
            src: 'loginWithCredentials',

            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },

            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {
          on: {
            LOGIN: 'loggingIn',
          },
        },
      },

      onDone: {
        actions: 'loginSuccessful',
      },
    },
  },
})
