<template>
  <div class="PmViewEditorDropdown" :class="$attrs.class">
    <div v-if="label" class="PmViewEditorDropdown-label">{{ label }}</div>

    <div class="PmViewEditorDropdown-content">
      <PmDropdownPure
        :options="options"
        class="PmViewEditorDropdown-dropdown"
        :multiple="true"
        v-bind="separateAttrs($attrs).attributes"
        @input-searchterm="$emit('inputSearchterm', $event)"
        @close="$emit('close')"
        @input="(ids) => $emit('update', { attribute, ids })"
      />

      <PmButtonPure
        nodisabled="modeSwitchDisabled"
        :icon="modeIcon"
        class="PmViewEditorDropdown-toggle"
        :title="title"
        @click="$emit('toggleMode', { attribute })"
      />
    </div>
  </div>
</template>

<script>
import { VIEW_SETTING_MODE } from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'

import { separateAttrs } from '@/utilities/misc'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmDropdownPure from '@/components/basics/PmDropdownPure.vue'

export const propTypes = {
  mode: {
    allowed: [VIEW_SETTING_MODE.EXPLICIT, VIEW_SETTING_MODE.EXCLUDE],
  },
}

export default {
  name: 'PmViewEditorDropdown',
  components: {
    PmButtonPure,
    PmDropdownPure,
  },

  inheritAttrs: false,

  props: {
    options: { type: Array, default: () => [] },
    attribute: { type: String, required: true },
    label: { type: String, default: undefined },
    mode: { type: String, required: true },
  },

  emits: ['inputSearchterm', 'close', 'update', 'toggleMode'],

  setup() {
    return {
      separateAttrs,
    }
  },

  computed: {
    modeIcon() {
      if (this.mode === VIEW_SETTING_MODE.EXPLICIT) return ICONS.EXPLICIT
      if (this.mode === VIEW_SETTING_MODE.EXCLUDE) return ICONS.EXCLUDE

      return undefined
    },

    title() {
      return this.mode === VIEW_SETTING_MODE.EXCLUDE
        ? 'werden ausgeschlossen'
        : 'werden ausschließlich angezeigt'
    },
  },
}
</script>

<style lang="scss">
.PmViewEditorDropdown {
  $block: &;

  &-label {
    @include mixin.textLabel;

    margin-bottom: 4px;
  }

  &-content {
    display: flex;
  }

  &-dropdown {
    flex-grow: 1;
  }

  &-toggle {
    margin-left: 10px;
    flex-shrink: 0;
  }
}
</style>
