// Detect if a user is navigating with the keyboard
function checkForKeyboardNavigation(event) {
  if (event.key === 'Tab') enableKeyboardNavigationStyles()
  if (event.key === 'Escape') disableKeyboardNavigationStyles()
}

function enableKeyboardNavigationStyles() {
  document.documentElement.classList.add('is-keyboardNavigation')
}

function disableKeyboardNavigationStyles() {
  document.documentElement.classList.remove('is-keyboardNavigation')
}

document.addEventListener('keydown', checkForKeyboardNavigation)
document.addEventListener('mousedown', disableKeyboardNavigationStyles)
