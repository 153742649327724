<template>
  <PmViewDeletePure
    class="PmViewEditorControlPure-section"
    :loading="xstate.state.value.matches('delete.deleting')"
    :error="xstate.state.value.matches('delete.failed')"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :view-name="viewTitle"
    :is-public-view-note-visible="isPublicView"
    @delete="xstate.service.value.send('DELETE')"
    @close="emit('close')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmViewDelete'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { cloneDeep } from 'lodash-es'
import { useStore } from 'vuex'
import { useQuery, useMutation } from '@vue/apollo-composable'

import { ICONS } from '@/constants/icons'
import { ERROR } from '@/constants/errors'

import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { PmViewDeleteState } from '@/components/persoplan/PmViewDelete/PmViewDeleteState'

import PmViewDeletePure from '@/components/persoplan/PmViewDelete/PmViewDeletePure.vue'

import {
  ViewsDocument,
  CurrentUserDocument,
  DeleteViewDocument,
} from '@/../generated/graphql'

const emit = defineEmits<{
  (event: 'close'): void
}>()

const store = useStore()

const xstate = useXState(PmViewDeleteState, {
  services: {
    deleteView: deleteView,
  },
})

const viewsQuery = useQuery(ViewsDocument)
const views = computed(() => viewsQuery.result.value?.views)

const userQuery = useQuery(CurrentUserDocument)
const user = computed(() => userQuery.result.value?.user)

const viewTitle = computed(() => {
  return store.state.view.title
})

const isPublicView = computed(() => {
  if (!views.value) return

  const currentView = views.value.find((view) => {
    return view?.id === store.state.view.id
  })

  if (!currentView) {
    return false
  }

  return currentView.public === true ? true : false
})

const deleteViewMutation = useMutation(DeleteViewDocument)

async function deleteView() {
  const id = store.state.view.id

  if (!id) {
    throw new Error('no view id in store')
  }

  try {
    await deleteViewMutation.mutate(
      {
        id: id,
      },
      {
        update: (store) => {
          const readQueryResult = store.readQuery({ query: ViewsDocument })

          // readQuery is readonly, thus we need to create a deep copy
          const data = cloneDeep(readQueryResult)
          if (!data?.views) return

          const index = data.views.findIndex((view) => view?.id === id)
          if (index === -1) throw new Error(ERROR.CACHEUPDATE_FAILED)

          data.views.splice(index, 1)
          store.writeQuery({ query: ViewsDocument, data })
        },
      }
    )

    store.commit('view/reset')
  } catch (error) {
    throwFriendlyError(error)
  }

  store.commit('notification/add', {
    variant: 'success',
    icon: ICONS.DELETE,
    title: 'Ansicht gelöscht',
  })

  emit('close')
}
</script>
