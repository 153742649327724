export const settingsToPersist = {
  'persoplan.projectGroupsCollapsed': { type: Object },
  'cssVar.variables.dayWidth.value': {},
  'view.defaultSavedViewIdMobile': {},
  'view.defaultSavedViewIdDesktop': {},
} as const

export const urlParamsToPersist = {
  'persoplan.visibleStartDate': { type: Date, id: 'start-date' },
  'persoplan.visibleEndDate': { type: Date, id: 'end-date' },
  'view.id': { type: Number, id: 'view-id' },
} as const
