import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultiAllocationListItemPureState.typegen'

export type State = Typegen0['matchesStates']

export const PmMultiAllocationListItemPureState = createMachine({
  tsTypes:
    {} as import('./PmMultiAllocationListItemPureState.typegen').Typegen0,
  schema: {},

  id: 'PmMultiAllocationListItemPureState',
  initial: 'default',
  states: {
    default: {},
    saving: {},
    success: {},
    failed: {
      meta: {
        error: true,
        errorMessage:
          'Es gab einen unbekannten Fehler beim Speichern der Zuordnung',
      },
    },
  },
})

export const states = extractAllStates(PmMultiAllocationListItemPureState)
