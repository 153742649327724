<template>
  <div class="PmTagPure" :class="classes">
    <PmIconPure
      v-if="icon"
      :key="icon"
      :name="icon"
      size="small"
      class="PmTagPure-icon"
    />

    <div class="PmTagPure-label">{{ label }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmTagPure'

export const propTypes = {
  variant: {
    allowed: ['secondary', 'primary', 'light', 'success', 'warning', 'danger'],
    default: 'secondary',
  },
  icon: {
    allowed: propTypesIcon.name.allowed,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import PmIconPure, {
  propTypes as propTypesIcon,
  type Props as PropsIconPure,
} from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  label: string
  variant?: (typeof propTypes.variant.allowed)[number]
  icon?: PropsIconPure['name']
}

const props = withDefaults(defineProps<Props>(), {
  variant: propTypes.variant.default,
})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--variantSecondary`]: props.variant === 'secondary',
    [`${COMPONENT_NAME}--variantPrimary`]: props.variant === 'primary',
    [`${COMPONENT_NAME}--variantLight`]: props.variant === 'light',
    [`${COMPONENT_NAME}--variantSuccess`]: props.variant === 'success',
    [`${COMPONENT_NAME}--variantWarning`]: props.variant === 'warning',
    [`${COMPONENT_NAME}--variantDanger`]: props.variant === 'danger',
    [`${COMPONENT_NAME}--withIcon`]: props.icon ? true : false,
  }
})
</script>

<style lang="scss">
.PmTagPure {
  $block: &;

  @include cssVar.define($block, 'colorBackground', rgba(fuchsia, 0.25));
  @include cssVar.define($block, 'colorText', inherit);
  @include cssVar.define($block, 'paddingLabel', 4px);

  &--variantSecondary {
    @include cssVar.define($block, 'colorBackground', color.$gray-200);
    @include cssVar.define($block, 'colorText', color.$gray-600);
  }

  &--variantPrimary {
    @include cssVar.define($block, 'colorBackground', color.$primary-100);
    @include cssVar.define($block, 'colorText', color.$primary-700);
  }

  &--variantLight {
    @include cssVar.define($block, 'colorBackground', color.$white);
    @include cssVar.define($block, 'colorText', color.$gray-700);
  }

  &--variantSuccess {
    @include cssVar.define($block, 'colorBackground', color.$success-100);
    @include cssVar.define($block, 'colorText', color.$success-800);
  }

  &--variantWarning {
    @include cssVar.define($block, 'colorBackground', color.$warning-100);
    @include cssVar.define($block, 'colorText', color.$warning-700);
  }

  &--variantDanger {
    @include cssVar.define($block, 'colorBackground', color.$danger-100);
    @include cssVar.define($block, 'colorText', color.$danger-600);
  }

  border-radius: constant.$borderRadius-default;
  background-color: cssVar.use($block, 'colorBackground');
  color: cssVar.use($block, 'colorText');
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  font-size: constant.$fontSize-small;
  min-height: 18px;

  &-icon {
    padding-left: calc(cssVar.use($block, 'paddingLabel') - 2px);
    flex-shrink: 0;
    box-sizing: content-box;
    width: 14px;
    height: 14px;
  }

  &-label {
    padding-left: cssVar.use($block, 'paddingLabel');
    padding-right: cssVar.use($block, 'paddingLabel');

    #{$block}--withIcon & {
      padding-left: calc(cssVar.use($block, 'paddingLabel') - 2px);
    }

    #{$block}--sizeSmall & {
      min-height: 18px;
      line-height: 18px;
    }
  }
}
</style>
