<template>
  <div
    :class="[componentClass.root, classes]"
    @dragenter="emit('dragenter')"
    @dragleave="emit('dragleave')"
  >
    <div :class="componentClass.element('content')">
      <div :class="componentClass.element('icon')">
        <PmIconPure :name="icon" />
      </div>

      <div :class="componentClass.element('label')">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import PmIconPure, {
  type Props as PropsIconPure,
} from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  multiple?: boolean
  isDraggedOver?: boolean
  numberOfDraggedFiles?: number
  rejectedBecause?:
    | 'onlyOneFileAllowed'
    | 'notAcceptedFileTypeAll'
    | 'notAcceptedFileTypeSome'
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  dragenter: []
  dragleave: []
}>()

const componentClass = useComponentClass()

const isError = computed(() => {
  return !!props.rejectedBecause
})
const isDraggingMultiple = computed(() => {
  if (!props.numberOfDraggedFiles) return false
  return props.numberOfDraggedFiles > 1
})

const classes = computed(() => {
  return {
    'is-draggedOver': props.isDraggedOver,
    'is-error': isError.value,
  }
})

const icon = computed<PropsIconPure['name']>(() => {
  if (isError.value) return 'close'
  return 'filePlus'
})

const label = computed(() => {
  if (props.rejectedBecause === 'onlyOneFileAllowed') {
    return 'Nur einzelne Datei erlaubt'
  }

  if (props.rejectedBecause === 'notAcceptedFileTypeAll') {
    return isDraggingMultiple.value
      ? 'Dateien haben einen unerlaubten Typ'
      : 'Datei hat einen unerlaubten Typ'
  }

  if (props.rejectedBecause === 'notAcceptedFileTypeSome') {
    return isDraggingMultiple.value
      ? 'Einige Dateien haben einen unerlaubten Typ'
      : 'Datei hat einen unerlaubten Typ'
  }

  return props.multiple ? 'Dateien hier ablegen' : 'Datei hier ablegen'
})
</script>

<style lang="scss">
.PmFileDropzonePure {
  $block: &;

  @include cssVar.define($block, 'colorText', color.$primary-900);
  @include cssVar.define($block, 'colorBorder', color.$primary-500);
  @include cssVar.define(
    $block,
    'colorBackground',
    rgba(color.$primary-100, 0.85)
  );
  @include cssVar.define(
    $block,
    'colorBackground-draggedOver',
    rgba(color.$primary-200, 0.85)
  );

  &.is-error {
    @include cssVar.define($block, 'colorText', color.$danger-900);
    @include cssVar.define($block, 'colorBorder', color.$danger-500);
    @include cssVar.define(
      $block,
      'colorBackground',
      rgba(color.$danger-100, 0.85)
    );
    @include cssVar.define(
      $block,
      'colorBackground-draggedOver',
      rgba(color.$danger-200, 0.85)
    );
  }

  border: 2px solid cssVar.use($block, 'colorBorder');
  border-radius: constant.$borderRadius-default;
  background-color: cssVar.use($block, 'colorBackground');
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-draggedOver {
    background-color: cssVar.use($block, 'colorBackground-draggedOver');
  }

  &-content {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    color: cssVar.use($block, 'colorText');
  }

  &-icon {
    width: 48px;
    height: 48px;
  }

  &-label {
    font-weight: bold;
    text-align: center;
  }
}
</style>
