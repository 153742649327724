import { computed, type Ref } from 'vue'
import scssVariables from '@/assets/scss/scssVariables'

import {
  STATUS_TRAVEL_AND_HOTEL,
  type StatusTravelAndHotel,
} from '@/constants/persoplan'

export { type StatusTravelAndHotel, STATUS_TRAVEL_AND_HOTEL }

interface Options {
  status: Ref<StatusTravelAndHotel | undefined>
}

export function useTravelAndHotelStatus(options: Options) {
  const classes = computed(() => {
    if (!options.status.value) return

    return {
      'is-statusTravelAndHotelNeeded':
        options.status.value === STATUS_TRAVEL_AND_HOTEL.NEEDED,
      'is-statusTravelAndHotelRequested':
        options.status.value === STATUS_TRAVEL_AND_HOTEL.REQUESTED,
      'is-statusTravelAndHotelConfirmed':
        options.status.value === STATUS_TRAVEL_AND_HOTEL.CONFIRMED,
      'is-statusTravelAndHotelCommunicated':
        options.status.value === STATUS_TRAVEL_AND_HOTEL.COMMUNICATED,
    }
  })

  const color = computed(() => {
    if (!options.status.value) return 'nono'

    return scssVariables.color[`status-travelAndHotel-${options.status.value}`]
  })

  return {
    classes,
    color,
  }
}
