<template>
  <PmSidebarProjectPure
    v-if="project"
    :id="project.id"
    :title="project?.caption"
    :pill="project.number"
    :number-of-alerts="project?.numberOfMissingResources ?? undefined"
    :alerts-visible="store.state.view.currentView.showMissingResourcesWarning"
    :group-number="groupNumber ?? undefined"
    :update-group-visible="can('edit', 'persoplan')"
    :collects-all-jobs-of-project="
      project?.setting?.isJobLayerHidden ?? undefined
    "
    :is-highlighted="isHighlighted"
    :note="project?.comment ?? undefined"
    :status="statusNormalized"
    @show-details="showDetails"
    @jump="jumpToProject"
    @open-note="showDetails"
  >
    <PmSidebarJob v-for="jobId in jobIds" :id="jobId" :key="jobId" />
  </PmSidebarProjectPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarProject'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'

import { EVENT } from '@/constants/events'
import { CALENDAR_ITEM_TYPE, DATA_MODAL_TYPE } from '@/constants/persoplan'
import { useCachedQuery } from '@/composition/useCachedQuery'
import { statusLookupProject } from '@/utilities/persoplan'
import EventHub from '@/eventHub'
import { useAppAbility } from '@/composition/useAppAbility'
import { useJumpTargets } from '@/pinia/jumpTargets'

import PmSidebarProjectPure from '@/components/persoplan/PmSidebarProject/PmSidebarProjectPure.vue'
import PmSidebarJob from '@/components/persoplan/PmSidebarJob/PmSidebarJob.vue'

import { SidebarProjectDocument } from '@/../generated/graphql'

export interface Props {
  id: number
  jobIds?: number[]
}

const props = withDefaults(defineProps<Props>(), {
  jobIds: () => [],
})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()
const { can } = useAppAbility()

const projectQuery = useCachedQuery(SidebarProjectDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']

  return {
    id: props.id,
    startDate: queryVariables.startDate,
    endDate: queryVariables.endDate,
  }
})

const project = computed(() => projectQuery.result.value?.project)

const groupNumber = computed(() => {
  return project.value?.group?.number
})

const statusNormalized = computed(() => {
  if (!project.value?.projectState?.id) return

  const status = statusLookupProject({
    projectStateId: project.value.projectState.id,
  })

  return status
})

const isHighlighted = computed({
  get: function () {
    return store.getters['persoplan/isItemHighlighted']({
      id: props.id,
      type: CALENDAR_ITEM_TYPE.PROJECT,
    })
  },
  set: function (value) {
    if (value === true) {
      store.commit('persoplan/addToHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.PROJECT,
      })
    } else {
      store.commit('persoplan/removeFromHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.PROJECT,
      })
    }
  },
})

function showDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.PROJECT,
    id: props.id,
  })
}

const jumpTargets = useJumpTargets()

function jumpToProject() {
  const jumpTarget = {
    type: 'AppointmentsContainer',
    id: props.id,
  } as const

  if (jumpTargets.isAvailable(jumpTarget)) {
    jumpTargets.jumpTo(jumpTarget)
  } else {
    throw new Error('jumpTarget is not available')
  }

  store.commit('persoplan/clearHighlightedItems')
  isHighlighted.value = true
}
</script>
