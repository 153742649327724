<template>
  <PmResourceRequestOverviewPure>
    <template #filter>
      <PmResourceRequestOverviewFilter
        @update:filter="(values) => (filter = values)"
        @initial-loading-finished="endLoading('filter')"
      />
    </template>

    <template #list>
      <PmResourceRequestOverviewList
        v-if="filter.startDate && filter.endDate"
        :filter="filter"
        :selected-resource-requests="selectedResourceRequests"
        :active-resource-request="activeResourceRequest"
        @click-on-resource-request="clickOnResourceRequest"
        @select-resource-request="addToSelectedResourceRequests"
        @unselect-resource-request="removeFromSelectedResourceRequests"
        @update-visible-resource-requests="
          (value) => (visibleResourceRequests = value)
        "
        @initial-loading-finished="endLoading('list')"
      />
    </template>

    <template #details>
      <PmResourceRequestOverviewDetails
        v-if="selectedAndActiveResourceRequests.size > 0"
        :resource-requests="selectedAndActiveResourceRequests"
        :active-resource-request="activeResourceRequest"
      />
    </template>
  </PmResourceRequestOverviewPure>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import { useLoading } from '@/composition/useLoading'

import PmResourceRequestOverviewPure from '@/components/PmResourceRequestOverview/PmResourceRequestOverviewPure.vue'
import PmResourceRequestOverviewFilter, {
  type Emit as EmitResourceRequestOverviewFilter,
} from '@/components/PmResourceRequestOverviewFilter/PmResourceRequestOverviewFilter.vue'
import PmResourceRequestOverviewList from '@/components/PmResourceRequestOverviewList/PmResourceRequestOverviewList.vue'
import PmResourceRequestOverviewDetails from '@/components/PmResourceRequestOverviewDetails/PmResourceRequestOverviewDetails.vue'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  initialLoadingFinsihed: []
}>()

const { endLoading, isLoadingAny, isLoading } = useLoading({
  loaders: ['filter', 'list'],
  autoStart: true,
  onLoadingFinished: () => emit('initialLoadingFinsihed'),
})

const activeResourceRequest = ref<number | null>(null)
const selectedResourceRequests = ref(new Set<number>())
const visibleResourceRequests = ref(new Set<number>())

function clickOnResourceRequest(resourceAllocationId: number) {
  if (activeResourceRequest.value === resourceAllocationId) {
    activeResourceRequest.value = null
    return
  }

  activeResourceRequest.value = resourceAllocationId
}

function clearSelectedAndActiveFromNotVisible() {
  // Clean all not visible from selected
  selectedResourceRequests.value.forEach((id) => {
    if (!visibleResourceRequests.value.has(id)) {
      selectedResourceRequests.value.delete(id)
    }
  })

  // Remove active if it's no longer visible
  if (!activeResourceRequest.value) return

  if (!visibleResourceRequests.value.has(activeResourceRequest.value)) {
    activeResourceRequest.value = null
  }
}

watch(visibleResourceRequests, clearSelectedAndActiveFromNotVisible)

const selectedAndActiveResourceRequests = computed(() => {
  const active = new Set<number>()
  if (activeResourceRequest.value) active.add(activeResourceRequest.value)
  return new Set<number>([...selectedResourceRequests.value, ...active])
})

const activeAndVisibleResourceRequest = computed(() => {
  if (!activeResourceRequest.value) return
  if (!visibleResourceRequests.value.has(activeResourceRequest.value)) return

  return activeResourceRequest.value
})

function addToSelectedResourceRequests(resourceAllocationId: number) {
  selectedResourceRequests.value.add(resourceAllocationId)
}

function removeFromSelectedResourceRequests(resourceAllocationId: number) {
  selectedResourceRequests.value.delete(resourceAllocationId)
}

const filter = ref<Partial<EmitResourceRequestOverviewFilter['filter']>>({})
</script>

<style lang="scss">
.PmResourceRequestOverview {
  $block: &;
}
</style>
