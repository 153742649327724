import { createMachine } from 'xstate'

import type { Typegen0 } from './PmResourceState.typegen'

export type State = Typegen0['matchesStates']

export const PmResourceState = createMachine({
  tsTypes: {} as import('./PmResourceState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'CREATE_RESOURCE_ALLOCATION_ADDRESS'; addressId: number }
      | { type: 'CREATE_RESOURCE_ALLOCATION_VEHICLE'; vehicleId: number }
      | { type: 'FOO'; value: string }
      | { type: 'FINISH' },

    services: {} as {
      invokeTest: any
      invokeTestWithReturn: {
        data: { id: string }
      }
    },

    context: {} as {
      addressId: number | undefined
      vehicleId: number | undefined
    },
  },

  id: 'PmResourceState',
  initial: 'default',

  states: {
    default: {
      on: {
        CREATE_RESOURCE_ALLOCATION_ADDRESS: {
          target: 'createResourceAllocation',
          actions: (context, event) => {
            if (!event.addressId)
              throw new Error('event.addressId is undefined')

            context.addressId = event.addressId
          },
        },

        CREATE_RESOURCE_ALLOCATION_VEHICLE: {
          target: 'createResourceAllocation',
          actions: (context, event) => {
            if (!event.vehicleId)
              throw new Error('event.addressId is undefined')

            context.vehicleId = event.vehicleId
          },
        },
      },
    },

    createResourceAllocation: {
      on: {
        FINISH: 'default',
      },
    },
  },
})
