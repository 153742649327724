<template>
  <div
    class="PmResourceTimelinePure"
    :class="classes"
    @mouseover="emit('highlight')"
    @mouseout="emit('unHighlight')"
  >
    <div class="PmResourceTimelinePure-timeIndicators">
      <PmResourceTimelineStatePure
        v-for="timeIndicator in timeIndicators"
        :key="timeIndicator.id"
        :start-date="timeIndicator.startDate"
        :end-date="timeIndicator.endDate"
        :label="timeIndicator.label"
        :container-start-date="containerStartDate"
        :container-end-date="containerEndDate"
        :has-note="timeIndicator.hasNote"
        :is-highlighted="isHighlighted"
        :type="timeIndicator.type"
        :resource-allocation-status="timeIndicator.resourceAllocationStatus"
        class="PmResourceTimelinePure-timeIndicator"
      />
    </div>

    <div class="PmResourceTimelinePure-backgrounds">
      <PmResourceTimelineBackgroundPure
        v-for="background in backgrounds"
        :key="background.date.getTime()"
        ref="elsBackground"
        :status="background.status"
        :label="label"
        :is-highlighted="isDateHighlighted(background.date)"
        @click="(event) => showDetails(event, { date: background.date })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceTimelinePure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { isSameDay } from 'date-fns'

import PmResourceTimelineStatePure, {
  type Props as PropsResourceTimelineStatePure,
} from '@/components/persoplan/PmResourceTimeline/PmResourceTimelineStatePure.vue'

import PmResourceTimelineBackgroundPure, {
  type Props as PropsResourceTimelineBackground,
} from '@/components/persoplan/PmResourceTimeline/PmResourceTimelineBackgroundPure.vue'

export interface TimeIndicator
  extends Pick<
    PropsResourceTimelineStatePure,
    | 'startDate'
    | 'endDate'
    | 'label'
    | 'hasNote'
    | 'type'
    | 'resourceAllocationStatus'
  > {
  id: number | string
}

export interface Background extends PropsResourceTimelineBackground {
  date: Date
}

export interface Props {
  containerStartDate: Date
  containerEndDate: Date
  timeIndicators?: TimeIndicator[]
  backgrounds?: Background[]
  isHighlighted?: boolean
  highlightedDate?: Date
  label?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (
    event: 'showDetails',
    data: { date: Date; popoverElement: HTMLElement }
  ): void
  (event: 'highlight'): void
  (event: 'unHighlight'): void
}>()

const elsBackground = ref<HTMLElement[]>([])

const classes = computed(() => {
  return {
    'is-highlighted': props.isHighlighted,
  }
})

function isDateHighlighted(date: Date) {
  if (!props.highlightedDate) return false
  return isSameDay(date, props.highlightedDate)
}

function showDetails(event: Event, { date }: { date: Date }) {
  if (!(event.currentTarget instanceof HTMLElement)) {
    throw new Error('popoverElement is undefined or not an HTMLElement')
  }

  emit('showDetails', {
    date: date,
    popoverElement: event.currentTarget,
  })
}
</script>

<style lang="scss">
.PmResourceTimelinePure {
  // position: absolute;
  height: 22px;
  // overflow: hidden; // Possible Sideffects?
  width: 100%;
  position: relative;

  // background-color: rgba(color.$gray-900, 0.04);
  // @include mixin.transition-hover(background-color, null, 'highlighted');

  &.is-highlighted {
    background-color: rgba(color.$key, 0.3);
  }

  // TODO
  // &.-timeUnitsHidden .timeUnit {
  //   display: none;
  // }

  // TODO
  // &.-isFirst::before {
  //   content: '';
  //   width: 100vw;
  //   height: 1px;
  //   background-color: color.$gray-300--alpha;
  //   position: absolute;
  //   top: -5px;
  //   transform: translateY(-21px);
  //   left: 0;
  // }

  &-timeIndicators {
    pointer-events: none;
    height: 100%;
    position: relative;
  }

  &-timeIndicator {
    position: absolute;
    top: 4px;
  }

  &-backgrounds {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &-resourceDays {
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
