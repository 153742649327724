<template>
  <div class="PmInputWithStatusPure">
    <PmInputPure
      class="PmInputWithStatusPure-input"
      :value="valueNormalized"
      :label="label"
      :disabled="disabled"
      @input="setText"
    />

    <PmDropdownPure
      class="PmInputWithStatusPure-dropdown"
      :options="statusOptions"
      :value="status"
      :label="label ? `${label} Status` : undefined"
      :has-reset="true"
      :disabled="disabled"
      @input="setStatus"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmInputWithStatusPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import {
  STATUS_TRAVEL_AND_HOTEL_LOOKUP,
  STATUS_TRAVEL_AND_HOTEL_LOOKUP_LABEL,
} from '@/constants/persoplan'

import { extractStatusAndValueFromString } from '@/utilities/string'
import { lookup } from '@/utilities/misc'

import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmDropdownPure, {
  type Emit as EmitDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  value?: Nilable<string>
  label: string
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'input', value: Nilable<string | number>): void
  (event: 'update:value', value: Nilable<string | number>): void
}>()

const statusOptions = Object.entries(STATUS_TRAVEL_AND_HOTEL_LOOKUP).map(
  ([id, value]) => {
    const label = lookup(value, STATUS_TRAVEL_AND_HOTEL_LOOKUP_LABEL)

    return {
      id: id,
      label: label,
    }
  }
)

const status = computed(() => {
  if (!props.value) return

  const statusId = extractStatusAndValueFromString(props.value)?.statusId

  return statusId
})

const valueNormalized = computed(() => {
  if (!props.value) return

  const valueWithoutStatus = extractStatusAndValueFromString(
    props.value
  )?.valueWithoutStatus

  return valueWithoutStatus
})

function setStatus(statusId: EmitDropdownPure['input']) {
  if (typeof statusId !== 'string') throw new Error('newValue is not string')

  const text = valueNormalized.value ? valueNormalized.value : ''

  // If status was removed, only emit text value
  if (!statusId) {
    return emitInput(text)
  }

  const value = `${text}|${statusId}`
  emitInput(value)
}

function setText(text: Nilable<string | number | Date>) {
  if (text instanceof Date) {
    throw new Error('text should not be a Date')
  }

  let value: Nilable<string | number> = undefined

  if (status.value) {
    value = `${text}|${status.value}`
  } else {
    value = text
  }

  emitInput(value)
}

function emitInput(value: Nilable<string | number>) {
  emit('input', value)
  emit('update:value', value)
}
</script>

<style lang="scss">
.PmInputWithStatusPure {
  $block: &;

  display: flex;
  gap: 8px;
  min-width: 0;
  align-items: flex-end;

  @include mixin.media('<=400px') {
    flex-direction: column;
    align-items: flex-start;
  }

  &-input {
    width: 70%;

    @include mixin.media('<=400px') {
      width: 100%;
    }
  }

  &-dropdown {
    width: 30%;

    @include mixin.media('<=400px') {
      width: 100%;
    }
  }
}
</style>
