import { computed, ref } from 'vue'
import { startOfDay } from 'date-fns'
import { defineStore } from 'pinia'
import { useIntervalFn } from '@vueuse/core'

export const useTime = defineStore('time', () => {
  const now = ref(new Date())

  function updateTime() {
    now.value = new Date()
  }

  useIntervalFn(updateTime, 1000 * 60) // Update time every minute)

  const today = computed(() => {
    return startOfDay(now.value)
  })

  return {
    now,
    today,
  }
})
