import { createMachine, send } from 'xstate'

import type { Typegen0 } from './PmResourceStateEditState.typegen'

export type TState = Typegen0['matchesStates']

export const PmResourceStateEditState = createMachine({
  tsTypes: {} as import('./PmResourceStateEditState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'SAVE' } | { type: 'DELETE' } | { type: 'CLOSE' },

    services: {} as {
      updateResourceState: any
      deleteResourceState: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmResourceStateEditState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        SAVE: 'save',
        DELETE: 'delete',
      },
    },

    save: {
      initial: 'saving',

      states: {
        saving: {
          invoke: {
            id: 'save-resource-state',
            src: 'updateResourceState',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
            onDone: 'saved',
          },
        },

        failed: {
          on: { SAVE: 'saving' },
        },

        saved: {
          entry: ['emitClose'],
          always: '#PmResourceStateEditState.default',
        },
      },
    },

    delete: {
      initial: 'deleting',

      states: {
        deleting: {
          invoke: {
            id: 'delete-resource-state',
            src: 'deleteResourceState',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },

            onDone: '#PmResourceStateEditState.deleted',
          },
        },

        failed: {
          on: {
            DELETE: 'deleting',
          },
        },
      },
    },

    deleted: {
      entry: [send('CLOSE', { delay: 1000 })],

      on: {
        CLOSE: {
          actions: 'emitClose',
        },
      },
    },
  },
})
