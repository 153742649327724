import { ref, shallowRef, watch, computed, type Ref } from 'vue'
import { intersection } from 'lodash-es'

export const RESOURCE_DETAILS_LAZYLOAD_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADED: 'loaded',
} as const

export type ResourceDetailsLazyloadStatus =
  (typeof RESOURCE_DETAILS_LAZYLOAD_STATUS)[keyof typeof RESOURCE_DETAILS_LAZYLOAD_STATUS]

export const resourceIdsInViewport = ref<number[]>([])

/**
 * Keeps track which ids were already fetches from the server and a status.
 * This gets cleared regularly in order to update date e.g. after a mutation
 */
interface LazyLoadedResourceStatus {
  id: number
  status: ResourceDetailsLazyloadStatus
}
const lazyLoadResourceStatus = shallowRef<
  Record<number, LazyLoadedResourceStatus>
>({})

function createLazyLoadResourceStatusIfNotPresent(id: number) {
  if (lazyLoadResourceStatus.value[id]) return

  lazyLoadResourceStatus.value[id] = {
    id,
    status: RESOURCE_DETAILS_LAZYLOAD_STATUS.IDLE,
  }
}

export function setLazyLoadedResourcesStatus(
  ids: number[],
  { status }: { status: ResourceDetailsLazyloadStatus }
) {
  ids.forEach((id) => {
    createLazyLoadResourceStatusIfNotPresent(id)
    lazyLoadResourceStatus.value[id].status = status
  })
}

export function resetLazyLoadedResourcesStatus() {
  lazyLoadResourceStatus.value = {}

  // Fill with ids currently visible in viewport
  resourceIdsInViewport.value.forEach((id) => {
    createLazyLoadResourceStatusIfNotPresent(id)
  })
}

export const resourceIdsToLoad = computed(() => {
  // Filter resourceIds which are not loaded or are currently loading yet
  const idleItemsIds = Object.values(lazyLoadResourceStatus.value).reduce(
    (result: number[], item) => {
      if (item.status !== RESOURCE_DETAILS_LAZYLOAD_STATUS.IDLE) return result

      result.push(item.id)
      return result
    },
    []
  )

  // Cross reference with items currently in viewport
  const idleItemsInViewportIds = intersection(
    idleItemsIds,
    resourceIdsInViewport.value
  )

  return idleItemsInViewportIds
})

const setResourceIsInViewport = (idToAdd: number) => {
  resourceIdsInViewport.value.push(idToAdd)
  createLazyLoadResourceStatusIfNotPresent(idToAdd)
}

const setResourceIdIsNotInViewport = (idToRemove: number) => {
  const index = resourceIdsInViewport.value.findIndex((id) => id === idToRemove)
  if (index === -1) return
  resourceIdsInViewport.value.splice(index, 1)
}

export default function useLazyloadedResourceDetails({
  isInViewport,
  id,
}: {
  isInViewport: Ref<boolean>
  id: Ref<number>
}) {
  watch(
    () => isInViewport.value,
    () => {
      isInViewport.value === true
        ? setResourceIsInViewport(id.value)
        : setResourceIdIsNotInViewport(id.value)
    },
    {
      immediate: true,
    }
  )
}
