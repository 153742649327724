<template>
  <PmEventPure
    :name="name"
    :description="description"
    :text="text"
    :is-constrained-start="isConstrainedStart"
    :is-constrained-end="isConstrainedEnd"
  />
</template>

<script>
import { startEndDate } from '@/utilities/date'
import { filterProps } from '@/utilities/props'

import PmEventPure from '@/components/persoplan/PmGeneralEvents/PmEventPure.vue'

export default {
  name: 'PmCalendarEventPure',
  components: {
    PmEventPure,
  },

  props: {
    ...filterProps(PmEventPure.props, {
      onlyUse: ['isConstrainedStart', 'isConstrainedEnd'],
    }),
    name: { type: String, required: true },
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    showDates: { type: Boolean, default: true },
    description: { type: String, default: undefined },
    location: { type: String, default: undefined },
    priority: { type: String, default: undefined },
    attendees: { type: Array, default: () => [] },
  },

  computed: {
    text() {
      let text = []

      if (this.priority) text.push(`Priorität: ${this.priority}`)
      if (this.location) text.push(`Ort: ${this.location}`)

      if (this.showDates) {
        text.push(startEndDate(this.startDate, this.endDate))
      }

      if (this.attendees.length) {
        text.push('Teilnehmer:')
        text = [...text, ...this.normalizedAttendees]
      }

      return text
    },

    normalizedAttendees() {
      return this.attendees.map((attendee) => {
        if (!attendee.status) return attendee.name

        return `${attendee.name} (${attendee.status})`
      })
    },
  },
}
</script>

<style lang="scss">
.PmCalendarEventPure {
  $block: &;
}
</style>
