import { reactive, ref, watch } from 'vue'

const areaIds = [
  'generalEvents',
  'calendarEvents',
  'birthdays',
  'freelancers',
  'employees',
  'vehicles',
  'stockTypeServices',
] as const

type AreaId = (typeof areaIds)[number]
type CollapsedAreas = Record<AreaId, boolean | undefined>

export const collapsedAreas = reactive<CollapsedAreas>({
  generalEvents: undefined,
  calendarEvents: undefined,
  birthdays: undefined,
  freelancers: undefined,
  employees: undefined,
  vehicles: undefined,
  stockTypeServices: undefined,
})

export function useCollapsableArea({ id }: { id: AreaId }) {
  const isCollapsed = ref(false)
  collapsedAreas[id] = isCollapsed.value

  watch(isCollapsed, () => {
    collapsedAreas[id] = isCollapsed.value
  })

  const collapse = () => {
    isCollapsed.value = true
  }

  const expand = () => {
    isCollapsed.value = false
  }

  return {
    isCollapsed,
    collapsedAreas,

    collapse,
    expand,
  }
}
