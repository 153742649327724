import { createMachine } from 'xstate'

import type { Typegen0 } from './PmMultiSelectionControllerState.typegen'

export type TState = Typegen0['matchesStates']

export const PmMultiSelectionControllerState = createMachine({
  tsTypes: {} as import('./PmMultiSelectionControllerState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'ALLOCATE' }
      | { type: 'CANCEL' }
      | { type: 'CONFIRM' },

    services: {} as {
      createResourceAllocations: any
    },

    context: {} as {
      error: string | undefined
    },
  },

  id: 'PmMultiSelectionControllerState',
  initial: 'default',

  context: {
    error: undefined,
  },

  states: {
    default: {
      on: {
        ALLOCATE: 'allocate',
      },
      entry: 'reset',
    },

    allocate: {
      initial: 'askForConfirmation',
      states: {
        askForConfirmation: {
          on: {
            CANCEL: '#PmMultiSelectionControllerState.default',
            CONFIRM: 'saving',
          },
        },
        saving: {
          invoke: {
            id: 'save-multi-allocation',
            src: 'createResourceAllocations',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                console.error(error.data)
                context.error = error.data.message
              },
            },
            onDone: 'success',
          },
        },

        success: {
          on: {
            CANCEL: '#PmMultiSelectionControllerState.default',
          },
        },

        failed: {
          on: {
            CANCEL: '#PmMultiSelectionControllerState.default',
          },
        },
      },
    },
  },
})
