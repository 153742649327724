<template>
  <div class="PmLayoutDefault">
    <PmMainNavigation class="PmLayoutDefault-navigation" />

    <div class="PmLayoutDefault-notifications">
      <slot name="notifications" />
    </div>

    <div class="PmLayoutDefault-content">
      <slot />
    </div>
  </div>
</template>

<script>
import PmMainNavigation from '@/components/PmMainNavigation/PmMainNavigation.vue'

export default {
  name: 'PmLayoutDefault',
  components: {
    PmMainNavigation,
  },
}
</script>

<style lang="scss">
.PmLayoutDefault {
  @supports not (min-height: 100dvh) {
    min-height: calc(var(--appHeight) - var(--horizontalScrollbarHeight));
  }

  min-height: calc(100dvh - var(--horizontalScrollbarHeight));
  display: grid;
  grid-template-rows: var(--navigationHeight) 1fr;
  grid-template-areas:
    'navigation'
    'content';

  &-navigation {
    grid-area: navigation;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
  }

  &-content {
    grid-area: content;
    // padding-top: var(--navigationHeight);
  }

  &-notifications {
    position: fixed;
    z-index: constant.$zIndex-notifications;
    top: var(--navigationHeight);
    right: 0;
    width: 100%;
    max-width: 400px;
    pointer-events: none;
  }
}
</style>
