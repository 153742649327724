<template>
  <PmPauseOverlayPure
    class="PmPause-overlay"
    :is-visible="!userIsActiveNormalized"
    tabindex="0"
    :inactive-since="inactiveSince"
  />
</template>

<script setup lang="ts">
/**
 * Maybe do the idle detection via vue-use?
 * @see https://vueuse.org/core/useidle/#useidle
 */
import { onMounted, onBeforeUnmount, watch, ref, computed } from 'vue'

import { useTimestamp } from '@vueuse/core'
import { formatDistance } from 'date-fns'
import { throttle } from 'lodash-es'

import { dateFnsLocale } from '@/utilities/date'

import PmPauseOverlayPure from '@/components/persoplan/PmPauseOverlay/PmPauseOverlayPure.vue'

const ONE_SECOND = 1000
const INACTIVE_AFTER = 5 * 60 * ONE_SECOND

export interface Props {
  isEnabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isEnabled: true,
})

const emit = defineEmits<{
  (event: 'userIsInactive'): void
  (event: 'userIsActive'): void
}>()

const activityEvents = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
  'resize',
  'wheel',
] as const

let intervalToCheckFocus
let timeoutToCheckUserActivity

const documentHasFocus = ref(true)
const documentIsVisible = ref(true)
const userIsActive = ref(true)
const timestampWhenUserWentInactive = ref(new Date().getTime())
const currentTimestamp = useTimestamp({ interval: 1_000 })

watch(
  () => props.isEnabled,
  (to, from) => {
    if (from === false && to === true) {
      userDidSomething()
    }
  },
  {}
)

const userIsActiveNormalized = computed(() => {
  if (props.isEnabled === false) return true
  if (documentIsVisible.value === false) return false
  if (userIsActive.value === false) return false

  return true
})

const inactiveSince = computed(() => {
  if (userIsActiveNormalized.value === true) return

  const result = formatDistance(
    timestampWhenUserWentInactive.value,
    currentTimestamp.value,
    { locale: dateFnsLocale }
  )
  return result
})

watch(userIsActiveNormalized, (to, from) => {
  if (from === true && to === false) {
    timestampWhenUserWentInactive.value = new Date().getTime()
    emit('userIsInactive')
  }

  if (from === false && to === true) {
    emit('userIsActive')
  }
})

const checkDocumentFocus = () => {
  documentHasFocus.value = document.hasFocus()
}

const onVisibilityChange = () => {
  if (document.visibilityState === 'hidden') {
    documentIsVisible.value = false
  }

  if (document.visibilityState === 'visible') {
    documentIsVisible.value = true
  }
}

const userSeemsInactive = () => {
  userIsActive.value = false
}

const userDidSomething = () => {
  userIsActive.value = true

  clearTimeout(timeoutToCheckUserActivity)
  timeoutToCheckUserActivity = setTimeout(userSeemsInactive, INACTIVE_AFTER)
}

const userDidSomethingThrottled = throttle(userDidSomething, 1_000)

onMounted(async () => {
  documentIsVisible.value = document.visibilityState === 'visible'
  documentHasFocus.value = document.hasFocus()

  // Detect user activity
  activityEvents.forEach((eventName) => {
    document.addEventListener(eventName, userDidSomethingThrottled, {
      passive: true,
    })
  })

  intervalToCheckFocus = setInterval(checkDocumentFocus, 500)

  if (props.isEnabled) {
    userDidSomething()
  }

  document.addEventListener('focusin', checkDocumentFocus)
  document.addEventListener('focusout', checkDocumentFocus)
  document.addEventListener('visibilitychange', onVisibilityChange)
})

onBeforeUnmount(() => {
  document.removeEventListener('focusin', checkDocumentFocus)
  document.removeEventListener('focusout', checkDocumentFocus)
  document.removeEventListener('visibilitychange', onVisibilityChange)

  // Remove events for detecting user activity
  activityEvents.forEach((eventName) => {
    document.removeEventListener(eventName, userDidSomethingThrottled, {})
  })

  clearInterval(intervalToCheckFocus)
})
</script>

<style lang="scss">
.PmPause {
  $block: &;

  &-overlay {
    z-index: constant.$zIndex-pauseOverlay;
  }
}
</style>
