<template>
  <PmResourceTimelinesPure label="Freelancer">
    <template v-if="freelancersSorted.length > 0">
      <div :class="componentClass.root">
        <PmResourceTimeline
          v-for="freelancer in freelancersSorted"
          :id="freelancer.id"
          :key="freelancer.id"
          :class="componentClass.element('timeline')"
          :start-date="startDate"
          :end-date="endDate"
          :type="RESOURCE_TYPE.FREELANCER"
        />
      </div>
    </template>
  </PmResourceTimelinesPure>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { orderBy } from 'lodash-es'
import { useComponentClass } from '@thomasaull-shared/composables'

import { RESOURCE_TYPE } from '@/constants/persoplan'

import { startDateForServer, endDateForServer } from '@/utilities/date'
import { getReducedFreelancerTimelineData } from '@/functional/freelancer'
import { useCachedQuery } from '@/composition/useCachedQuery'

import PmResourceTimeline from '@/components/persoplan/PmResourceTimeline/PmResourceTimeline.vue'
import PmResourceTimelinesPure from '@/components/persoplan/PmResourceTimelines/PmResourceTimelinesPure.vue'

import { ResourceTimelineFreelancerDocument } from '@/../generated/graphql'

export interface Props {
  startDate: Date
  endDate: Date
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()

const resourceTimelineFreelancerQuery = useCachedQuery(
  ResourceTimelineFreelancerDocument,
  () => ({
    startDate: startDateForServer(props.startDate),
    endDate: endDateForServer(props.endDate),
  })
)

const freelancerTimelines = computed(() => {
  return resourceTimelineFreelancerQuery.result.value?.resourceTimeline
})

const freelancersNormalized = computed(() => {
  if (!freelancerTimelines.value) return

  const reducedFreelancerTimelineData = getReducedFreelancerTimelineData(
    freelancerTimelines.value
  )

  if (!reducedFreelancerTimelineData) return []
  const filtered = Object.values(reducedFreelancerTimelineData)

  // TODO: Remove `any` when 'functional/freelancer.ts' is fully converted to TypeScript
  // REFERENCE: 6a0kcd8
  return Object.values(filtered) as any
})

const freelancersSorted = computed(() => {
  /**
   * If you change something here, also change in
   * src/components/persoplan/PmSidebarFreelancers/PmSidebarFreelancers.vue
   */
  return orderBy(
    freelancersNormalized.value,
    ['lastName', 'firstName', 'title'],
    ['asc', 'asc', 'asc']
  )
})
</script>

<style lang="scss">
.PmResourceTimelinesFreelancers {
  $block: &;

  padding-bottom: 8px;
}
</style>
