import { cloneDeep } from 'lodash-es'
import cuid from '@paralleldrive/cuid2'

const state = {
  errors: [],
  readUntilIndex: 0,
}

const mutations = {
  add(state, error) {
    const normalizedError = cloneDeep(error)
    normalizedError.id = cuid.createId()

    state.errors = [...state.errors, normalizedError]
  },

  remove(state, id) {
    const index = state.errors.findIndex((error) => error.id === id)
    if (index === -1) throw new Error('index not found')

    state.errors.splice(index, 1)
  },
}

const getters = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
