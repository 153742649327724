import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmGroupManagerPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmGroupManagerPureState = createMachine({
  tsTypes: {} as import('./PmGroupManagerPureState.typegen').Typegen0,
  schema: {},

  id: 'PmGroupManagerPureState',
  initial: 'default',

  states: {
    default: {},
    save: {
      initial: 'saving',
      states: {
        saving: {},
        success: {},
        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Ändern der Gruppe',
          },
        },
      },
    },
  },
})

export const states = extractAllStates(PmGroupManagerPureState)
