<template>
  <div class="PmCalendar">
    <PmCalendarPure
      :start-date="store.state.persoplan.visibleStartDate"
      :end-date="store.state.persoplan.visibleEndDate"
      :core-hours="coreHoursNormalized"
      :opening-hours="openingHoursNormalized"
      :public-holidays-in-germany="publicHolidaysInGermany"
      :public-holidays-in-luxemburg="publicHolidaysInLuxemburg"
      :content-visible="!isDraggingDayWidth"
      :is-general-events-visible="
        store.state.view.currentView.showGeneralEvents
      "
      :is-calendar-events-visible="
        store.state.view.currentView.showCalendarEvents
      "
      :is-birthdays-visible="store.state.view.currentView.showBirthdays"
      :is-freelancers-visible="store.state.view.currentView.showFreelancers"
      :is-employees-visible="store.state.view.currentView.showEmployees"
      :is-vehicles-visible="store.state.view.currentView.showVehicles"
      @update-container-height="updateContainerHeight"
    >
      <template #control>
        <PmPollingIndicatorCalendar
          :initial-loading-finished="initialLoadingFinished"
          :is-updating-calendar-cache="isLoading.cache"
          :user-is-idle="userIsIdle"
        />
      </template>

      <template #settings>
        <PmSettings
          v-if="store.state.persoplan.sidebarLeftVisible === false"
          @open-search="emit('openSearch')"
          @open-date-controller="emit('openDateController')"
          @create-resource-state-freelancer="
            emit('createResourceStateFreelancer')
          "
        />
      </template>

      <template #generalEvents>
        <PmGeneralEvents
          v-if="
            store.state.view.currentView.showGeneralEvents &&
            collapsedAreas.generalEvents !== true
          "
          :start-date="store.state.persoplan.visibleStartDate"
          :end-date="store.state.persoplan.visibleEndDate"
        />
      </template>

      <template #calendarEvents>
        <PmCalendarEvents
          v-if="
            store.state.view.currentView.showCalendarEvents &&
            collapsedAreas.calendarEvents !== true
          "
          :start-date="store.state.persoplan.visibleStartDate"
          :end-date="store.state.persoplan.visibleEndDate"
        />
      </template>

      <template #birthdays>
        <PmBirthdays
          v-if="
            store.state.view.currentView.showBirthdays &&
            collapsedAreas.birthdays !== true
          "
          :start-date="store.state.persoplan.visibleStartDate"
          :end-date="store.state.persoplan.visibleEndDate"
        />
      </template>

      <template #freelancers>
        <PmResourceTimelinesFreelancers
          v-if="
            store.state.view.currentView.showFreelancers &&
            collapsedAreas.freelancers !== true
          "
          :start-date="store.state.persoplan.visibleStartDate"
          :end-date="store.state.persoplan.visibleEndDate"
        />
      </template>

      <template #employees>
        <PmResourceTimelinesAddresses
          v-if="
            store.state.view.currentView.showEmployees &&
            collapsedAreas.employees !== true
          "
          :start-date="store.state.persoplan.visibleStartDate"
          :end-date="store.state.persoplan.visibleEndDate"
        />
      </template>

      <template #vehicles>
        <PmResourceTimelinesVehicles
          v-if="
            store.state.view.currentView.showVehicles &&
            collapsedAreas.vehicles !== true
          "
          :start-date="store.state.persoplan.visibleStartDate"
          :end-date="store.state.persoplan.visibleEndDate"
        />
      </template>

      <template #jobs>
        <PmGroupContainerPure
          v-for="group in groupedJobIds"
          :key="group.number"
          :collapsed="
            store.state.persoplan.projectGroupsCollapsed[group.number]
          "
          :label="`Gruppe ${group.number}`"
          @collapse="
            store.commit('persoplan/projectGroupCollapse', group.number)
          "
          @expand="store.commit('persoplan/projectGroupExpand', group.number)"
        >
          <PmPackingContainerPure
            :id="PACKING_CONTAINER_ID.CALENDAR_JOBS"
            v-slot="{ packingContainer }"
          >
            <PmPackingElementPure
              v-for="item in group.items"
              :key="item.id"
              v-slot="{ styles }"
              :packing-container="packingContainer"
            >
              <PmCalendarItem
                :id="item.id"
                :type="item.type"
                :style="styles"
                :container-start-date="store.state.persoplan.visibleStartDate"
                :container-end-date="store.state.persoplan.visibleEndDate"
              />
            </PmPackingElementPure>
          </PmPackingContainerPure>
        </PmGroupContainerPure>

        <template v-if="FEATURE_FLAG.OPERATIONS">
          <PmPackingContainerPure
            :id="PACKING_CONTAINER_ID.CALENDAR_OPERATIONS"
            v-slot="{ packingContainer }"
          >
            <template v-for="stockTypeService in cache?.stockTypeServices">
              <PmPackingElementPure
                v-if="stockTypeService"
                :key="stockTypeService.id"
                v-slot="{ styles }"
                :packing-container="packingContainer"
              >
                <PmStockTypeService
                  :id="stockTypeService.id"
                  :key="stockTypeService.id"
                  :container-start-date="store.state.persoplan.visibleStartDate"
                  :container-end-date="store.state.persoplan.visibleEndDate"
                  :style="styles"
                />
              </PmPackingElementPure>
            </template>

            <template v-for="purchaseOrder in cache?.purchaseOrders">
              <PmPackingElementPure
                v-if="purchaseOrder"
                :key="purchaseOrder.id"
                v-slot="{ styles }"
                :packing-container="packingContainer"
              >
                <PmPurchaseOrder
                  v-if="purchaseOrder"
                  :id="purchaseOrder.id"
                  :key="purchaseOrder.id"
                  :container-start-date="store.state.persoplan.visibleStartDate"
                  :container-end-date="store.state.persoplan.visibleEndDate"
                  :style="styles"
                />
              </PmPackingElementPure>
            </template>
          </PmPackingContainerPure>
        </template>
      </template>
    </PmCalendarPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmCalendar'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, provide, ref, watch, onMounted } from 'vue'
import { differenceInCalendarDays, format, subSeconds } from 'date-fns'
import { groupBy, debounce } from 'lodash-es'
import { useQuery, useApolloClient } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import {
  FORMAT_TIME_SERVER,
  CALENDAR_ITEM_TYPE,
  DRAG_AND_DROP_TYPE,
  PACKING_CONTAINER_ID,
} from '@/constants/persoplan'

import { FEATURE_FLAG } from '@/constants/featureFlags'
import { PERFORMANCE } from '@/constants/performance'

import {
  parseServerDateString,
  transformToAlternativeEndOfDay,
  formatToServerDateString,
} from '@/utilities/date'

import {
  addMark,
  measure,
  showPerformanceResult,
} from '@/utilities/performance'

import {
  resourceIdsInViewport,
  resourceIdsToLoad,
  setLazyLoadedResourcesStatus,
  resetLazyLoadedResourcesStatus,
  RESOURCE_DETAILS_LAZYLOAD_STATUS,
} from '@/composition/useLazyloadedResourceDetails'

import { collapsedAreas } from '@/composition/useCollapsableArea'
import { useLoading } from '@/composition/useLoading'
import { useDragAndDrop } from '@/pinia/dragAndDrop'

import { packingContainerSettingsKey } from '@/utilities/inject'

import PmCalendarPure from '@/components/persoplan/PmCalendarPure.vue'
import PmPackingContainerPure from '@/components/persoplan/PmPackingContainerPure.vue'
import PmPackingElementPure from '@/components/persoplan/PmPackingElementPure.vue'
import PmResourceTimelinesFreelancers from '@/components/persoplan/PmResourceTimeline/PmResourceTimelinesFreelancers.vue'
import PmResourceTimelinesAddresses from '@/components/persoplan/PmResourceTimeline/PmResourceTimelinesAddresses.vue'
import PmResourceTimelinesVehicles from '@/components/persoplan/PmResourceTimeline/PmResourceTimelinesVehicles.vue'
import PmBirthdays from '@/components/persoplan/PmGeneralEvents/PmBirthdays.vue'
import PmGeneralEvents from '@/components/persoplan/PmGeneralEvents/PmGeneralEvents.vue'
import PmCalendarEvents from '@/components/persoplan/PmGeneralEvents/PmCalendarEvents.vue'
import PmGroupContainerPure from '@/components/persoplan/PmGroupContainer/PmGroupContainerPure.vue'
import PmPollingIndicatorCalendar from '@/components/basics/PmPollingIndicator/PmPollingIndicatorCalendar.vue'
import PmCalendarItem from '@/components/persoplan/PmCalendar/PmCalendarItem.vue'
import PmSettings from '@/components/persoplan/PmSettings/PmSettings.vue'
import PmStockTypeService from '@/components/persoplan/PmStockTypeService/PmStockTypeService.vue'
import PmPurchaseOrder from '@/components/persoplan/PmPurchaseOrder/PmPurchaseOrder.vue'

import {
  CalendarCacheDocument,
  ResourceDetailsDocument,
  AppSettingsDocument,
  GeneralEventsDocument,
  type EasyJobEventFragment,
} from '@/../generated/graphql'

export interface Props {
  initialLoadingFinished?: boolean
  userIsIdle?: boolean
}

export interface PackingContainerSettings {
  observeResize: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openSearch'): void
  (event: 'openDateController'): void
  (event: 'createResourceStateFreelancer'): void
  (event: 'initialLoadingFinished'): void
}>()

const store = useStore()
const { client: apolloClient } = useApolloClient()

// This gets injected to PmPackingContainerPure
const packingContainerSettings = ref<PackingContainerSettings>({
  observeResize: true,
})

provide(packingContainerSettingsKey, packingContainerSettings)

const { endLoading, setLoading, isLoading } = useLoading({
  loaders: ['cache', 'appSettings', 'events'],
  autoStart: true,
  onLoadingFinished: () => {
    emit('initialLoadingFinished')
  },
})

const dragAndDrop = useDragAndDrop()

const isDraggingDayWidth = computed(() => {
  return dragAndDrop.type === DRAG_AND_DROP_TYPE.CHANGE_DAY_WIDTH
})

watch(isDraggingDayWidth, () => {
  packingContainerSettings.value.observeResize = !isDraggingDayWidth.value
})

/**
 * Load calendar cache
 */
const calendarCacheQuery = useQuery(
  CalendarCacheDocument,
  () => {
    addMark(PERFORMANCE.MARK.VARIABLES)

    const queryVariables = store.getters['queryVariables/calendar']

    const startDateAlternativeStartOfDay = formatToServerDateString(
      transformToAlternativeEndOfDay(store.state.persoplan.visibleStartDate)
    )

    console.log('skip Operations', queryVariables.skipOperations)

    return {
      ...queryVariables,
      startDateAlternativeStartOfDay,
    }
  },
  () => ({
    fetchPolicy: 'network-only',
  })
)

watch(calendarCacheQuery.loading, () => {
  if (calendarCacheQuery.loading.value === false) {
    endLoading('cache')
  }
})

calendarCacheQuery.onResult(() => {
  addMark(PERFORMANCE.MARK.CACHE)

  measure(PERFORMANCE.METRIC.INIT_TO_CACHE)
  measure(PERFORMANCE.METRIC.VARIABLES_TO_CACHE)

  if (PERFORMANCE.MEASURE) {
    window.requestIdleCallback(() => {
      addMark(PERFORMANCE.MARK.IDLE)

      measure(PERFORMANCE.METRIC.INIT_TO_IDLE)
      measure(PERFORMANCE.METRIC.CACHE_TO_IDLE)

      showPerformanceResult()
    })
  }
})

const cache = computed(() => calendarCacheQuery.result.value)

/**
 * Load app settings
 * @todo: This should be moved higher up in the component hierarchy as soon as those settings are needed elsewhere
 */
const appSettingsQuery = useQuery(AppSettingsDocument)

watch(appSettingsQuery.loading, () => {
  if (appSettingsQuery.loading.value === false) {
    endLoading('appSettings')
  }
})

const appSettings = computed(() => appSettingsQuery.result.value?.appSettings)

/**
 * Load events
 */

const eventsQuery = useQuery(GeneralEventsDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']

  return {
    startDate: queryVariables.startDate,
    endDate: queryVariables.endDate,
  }
})

watch(eventsQuery.loading, () => {
  if (eventsQuery.loading.value === false) {
    endLoading('events')
  }
})

const easyJobEvents = computed(() => eventsQuery.result.value?.easyJobEvents)

const groupedJobIds = computed(() => {
  if (!cache.value?.projects?.length) return []

  const groupedProjects = groupBy(cache.value.projects, 'group.number')

  type GroupItems = {
    type: typeof CALENDAR_ITEM_TYPE.PROJECT | typeof CALENDAR_ITEM_TYPE.JOB
    id: number
  }

  type Group = {
    number: number
    items: GroupItems[]
  }

  const groupedJobIds = Object.entries(groupedProjects).reduce(
    (groups: Group[], [number, projects]) => {
      /**
       * Expected output for a group is something like this:
       * [
       *  { type: 'project', id: 123 },
       *  { type: 'job', id: 42 },
       *  { type: 'job', id: 43 }
       * ]
       */

      const groupItems: GroupItems[] = []

      projects.forEach((project) => {
        if (!project) return

        // Jobs are collected, only return the project layer
        if (project.setting?.isJobLayerHidden) {
          return groupItems.push({
            type: CALENDAR_ITEM_TYPE.PROJECT,
            id: project.id,
          })
        }

        const jobs: { type: typeof CALENDAR_ITEM_TYPE.JOB; id: number }[] = []

        // Default, show all jobs individually
        project.jobs?.forEach((job) => {
          if (!job) return

          groupItems.push({
            type: CALENDAR_ITEM_TYPE.JOB,
            id: job.id,
          })
        })
      })

      groups.push({
        number: Number(number),
        items: groupItems,
      })

      return groups
    },
    []
  )

  return groupedJobIds
})

const coreHoursNormalized = computed(() => {
  if (!appSettings.value) return []
  if (!store.state.view.currentView.showCoreHours) return []

  const coreHours = [
    {
      startTime: parseServerDateString(appSettings.value.coureHoursStart, {
        type: 'time',
      }),
      endTime: parseServerDateString(appSettings.value.coreHoursEnd, {
        type: 'time',
      }),
    },
  ]

  return coreHours
})

const openingHoursNormalized = computed(() => {
  if (!appSettings.value) return []
  if (!store.state.view.currentView.showOpeningHours) return []

  type OpeningHour = {
    dayOfTheWeek: number
    startTime: Date
    endTime: Date
  }

  const openingHours: OpeningHour[] = []

  appSettings.value?.openingHours?.forEach((item) => {
    if (!item) return

    const dayOfTheWeek = item.day
    if (!dayOfTheWeek) throw new Error('dayOfTheWeek is undefined')

    const startTime = parseServerDateString(item.openingTime, {
      type: 'time',
    })
    if (!startTime) throw new Error('startTime is undefined')

    const endTime = parseServerDateString(item.closingTime, {
      type: 'time',
    })
    if (!endTime) throw new Error('endTime is undefined')

    const openingHour: OpeningHour = {
      dayOfTheWeek,
      startTime,
      endTime,
    }

    openingHours.push(openingHour)
  })

  return openingHours
})

const publicHolidaysInGermany = computed(() => {
  if (!easyJobEvents.value) return []

  const publicHolidays = easyJobEvents.value.filter((event) => {
    return event?.calendar?.caption === 'Feiertag'
  })

  const publicHolidaysNormalized: ReturnType<typeof normalizePublicHoliday>[] =
    []

  publicHolidays.forEach((event) => {
    if (!event) return
    publicHolidaysNormalized.push(normalizePublicHoliday(event))
  })

  return publicHolidaysNormalized
})

const publicHolidaysInLuxemburg = computed(() => {
  if (!easyJobEvents.value) return []

  const publicHolidays = easyJobEvents.value.filter((event) => {
    if (event?.calendar?.caption === 'Feiertag') return false
    return event?.caption?.startsWith('Lux ')
  })

  const publicHolidaysNormalized: ReturnType<typeof normalizePublicHoliday>[] =
    []

  publicHolidays.forEach((event) => {
    if (!event) return
    publicHolidaysNormalized.push(normalizePublicHoliday(event))
  })

  return publicHolidaysNormalized
})

function updateContainerHeight({ id, height }: { id: string; height: number }) {
  store.commit('cssVar/set', {
    name: `${id}Height`,
    value: height,
  })
}

function normalizePublicHoliday(event: EasyJobEventFragment) {
  const startDate = parseServerDateString(event.startDate)
  let endDate = parseServerDateString(event.endDate)

  if (!startDate) throw new Error('startDate is undefined')
  if (!endDate) throw new Error('endDate is undefined')

  /**
   * Manipulate dates which are from created wrong in Easyjob
   * Day1 00:00 — Day2 0:00 → Day1 00:00 — Day1 23:59
   */
  const isMultipleDays =
    differenceInCalendarDays(endDate, startDate) > 0 ? true : false

  if (isMultipleDays) {
    const endTimeString = format(endDate, FORMAT_TIME_SERVER)
    if (endTimeString === '00:00:00') {
      endDate = subSeconds(endDate, 1)
    }
  }

  return {
    startDate,
    endDate,
  }
}

/**
 * Lazyload resource details
 * This is just to fill the cache
 *
 * We can't use a smart query here, because for some reason, if two queries with different
 * variables are running simultaneously the earlier one to arrive gets discarded
 */
async function lazyloadResourceDetails() {
  const queryVariables = store.getters['queryVariables/calendar']

  if (resourceIdsToLoad.value.length === 0) {
    // Nothing to load
    return
  }

  setLazyLoadedResourcesStatus([...resourceIdsToLoad.value], {
    status: RESOURCE_DETAILS_LAZYLOAD_STATUS.LOADING,
  })

  const result = await apolloClient.query({
    query: ResourceDetailsDocument,
    fetchPolicy: 'network-only',
    variables: {
      ...queryVariables,
      ids: resourceIdsToLoad.value,
    },
  })

  const idsLoaded =
    result.data?.lazyloadedResourceDetails?.reduce((result: number[], item) => {
      if (!item) return result

      result.push(item.id)
      return result
    }, []) ?? []

  setLazyLoadedResourcesStatus(idsLoaded, {
    status: RESOURCE_DETAILS_LAZYLOAD_STATUS.LOADED,
  })
}

const lazyloadResourceDetailsDebounced = debounce(() => {
  lazyloadResourceDetails()
}, 250)

watch(resourceIdsToLoad, lazyloadResourceDetailsDebounced)
onMounted(lazyloadResourceDetailsDebounced)
</script>
