<template>
  <div class="PmMultipleResourceAllocationsDeletePure">
    <PmTablePure
      :columns="columns"
      class="PmMultipleResourceAllocationsDeletePure-table"
    >
      <slot name="table" />
    </PmTablePure>

    <PmAskForConfirmationPure
      v-if="xstate.state.value.matches('askForConfirmation')"
      :title="t('title', numberOfItems)"
      @cancel="emit('cancel')"
      @delete="emit('confirm')"
    >
      {{ t('text', numberOfItems) }}
    </PmAskForConfirmationPure>

    <PmAppNotificationPure
      v-if="xstate.state.value.matches('delete.deleting')"
      :is-loading="true"
      :title="`${t(
        'titleDeleting',
        numberOfItems
      )}: ${indexCurrentlyDeleting} von ${numberOfItems}`"
    />

    <PmErrorNotificationPure
      v-if="xstate.meta.value.error"
      class="PmMultipleResourceAllocationsDeletePure-notification"
      :message="errorMessage || xstate.meta.value.errorMessage"
      :details="errorDetails"
    />

    <PmAppNotificationPure
      v-if="xstate.state.value.matches('delete.success')"
      variant="success"
      :title="t('success', numberOfItems)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsDeletePure'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})

export const columns: TableColumns = [
  { id: 'project', label: 'Project' },
  { id: 'job', label: 'Job' },
  { id: 'appointment', label: 'Termin' },
  { id: 'resourceFunction', label: 'Ressourcenfunktion' },
  { id: 'name', label: 'Name' },
  { id: 'state', width: 45 },
]
</script>

<script setup lang="ts">
import { toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { ICONS } from '@/constants/icons'

import { useXState } from '@/composition/useXState'

import {
  PmMultipleResourceAllocationsDeletePureState,
  states,
  type State,
} from '@/components/persoplan/PmMultipleResourceAllocationsDelete/PmMultipleResourceAllocationsDeletePureState'

import PmTablePure, {
  type TableColumns,
} from '@/components/basics/PmTable/PmTablePure.vue'

import PmAskForConfirmationPure from '@/components/PmAskForConfirmation/PmAskForConfirmationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'

export interface Props {
  errorMessage?: string
  errorDetails?: string[]
  numberOfItems: number
  indexCurrentlyDeleting: number
  state?: State
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'confirm'): void
}>()

const xstate = useXState(PmMultipleResourceAllocationsDeletePureState, {
  syncStateWith: toRef(props, 'state'),
})

const { t } = useI18n({
  messages: {
    de: {
      title: 'Zuordnung löschen | Zuordnungen löschen',
      text: 'Die aufgelistete Zuordnung wird gelöscht und kann nicht wiederhergestellt werden. | Die aufgelisteten Zuordnungen werden gelöscht und können nicht wiederhergestellt werden.',
      success: 'Die Zuordnung wurde gelöscht | Die Zuordnungen wurden gelöscht',
      titleDeleting:
        'Ressourcen-Zuordnung wird gelöscht | Ressourcen-Zuordnungen werden gelöscht',
    },
  },
})
</script>

<style lang="scss">
.PmMultipleResourceAllocationsDeletePure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
