<template>
  <PmViewCreatePure
    class="PmViewEditorControlPure-section"
    :loading="xstate.state.value.matches('create.creating')"
    :error="xstate.state.value.matches('create.failed')"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :user-can-create-public-view="can('create', 'PublicViews')"
    :existing-view-names="existingViewNames"
    @create="
      ({ name, isPublic }) =>
        xstate.service.value.send('CREATE', { name, isPublic })
    "
    @close="emit('close')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'ExampleComponentBarebones'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { cloneDeep } from 'lodash-es'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { ICONS } from '@/constants/icons'

import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { PmViewCreateState } from '@/components/persoplan/PmViewCreate/PmViewCreateState'
import { useAppAbility } from '@/composition/useAppAbility'

import PmViewCreatePure from '@/components/persoplan/PmViewCreate/PmViewCreatePure.vue'

import {
  ViewsDocument,
  CurrentUserDocument,
  CreateViewDocument,
} from '@/../generated/graphql'

const emit = defineEmits<{
  (event: 'close'): void
}>()

const store = useStore()
const { can } = useAppAbility()

const viewsQuery = useQuery(ViewsDocument)
const views = computed(() => viewsQuery.result.value?.views)

const userQuery = useQuery(CurrentUserDocument)
const user = computed(() => userQuery.result.value?.user)

const xstate = useXState(PmViewCreateState, {
  services: {
    createView: (context, { name, isPublic }) => createView({ name, isPublic }),
  },
})

const existingViewNames = computed(() => {
  if (!views.value) return

  const viewNames: string[] = []

  views.value.forEach((view) => {
    if (!view) return
    viewNames.push(view.title)
  })

  return viewNames
})

const createViewMutation = useMutation(CreateViewDocument)

async function createView({
  name,
  isPublic,
}: {
  name: string
  isPublic: boolean
}) {
  try {
    const query = JSON.stringify(store.state.view.currentView)

    await createViewMutation.mutate(
      {
        title: name,
        public: isPublic,
        query: query,
      },
      {
        update: (cache, result) => {
          const view = result.data?.createPersoPlanView
          if (!view) throw new Error('view is undefined')

          const readQueryResult = cache.readQuery({ query: ViewsDocument })

          // readQuery is readonly, thus we need to create a deep copy
          const data = cloneDeep(readQueryResult)
          if (!data?.views) throw new Error('data.views is undefined')

          data.views.push(view)

          cache.writeQuery({ query: ViewsDocument, data })

          store.commit('view/setView', {
            id: view.id,
            title: view.title,
            query: view.query,
          })
        },
      }
    )
  } catch (error) {
    throwFriendlyError(error)
  }

  store.commit('notification/add', {
    variant: 'success',
    icon: ICONS.CHECK,
    title: 'Ansicht erstellt',
  })

  emit('close')
}
</script>
