<template>
  <div class="PmRatingPure">
    <PmIconPure
      v-for="index in rating"
      :key="index"
      class="PmRatingPure-star"
      :name="ICONS.STAR"
    />
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export default {
  name: 'PmRatingPure',
  components: {
    PmIconPure,
  },

  props: {
    rating: { type: Number, default: undefined },
  },

  setup() {
    return {
      ICONS,
    }
  },
}
</script>

<style lang="scss">
.PmRatingPure {
  $block: &;

  display: flex;
  align-items: center;

  &-star {
    color: #fc0;
    width: 10px;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}
</style>
