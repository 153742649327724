<template>
  <div>
    <PmMultiSelectionControllerPure
      :number-of-selected="$store.state.persoplan.resourceSelectionIds.length"
      :type="$store.state.persoplan.resourceSelectionType"
      :resource-functions="normalizedResourceFunctions"
      :state="xstate.path.value"
      :resources-to-allocate="normalizedResourcesToAllocate"
      :resource-title="resourceTitle"
      :error-message="xstate.state.value.context.error"
      :status-options="resourceAllocationStatesNormalized"
      :selected-status-id="selectedStatusId"
      @init-multi-allocation-address="initMultiAllocationAddress"
      @init-multi-allocation-vehicle="initMultiAllocationVehicle"
      @clear-selection="$store.commit('persoplan/clearResourceSelection')"
      @search-resource="searchResource"
      @confirm-multi-allocation="xstate.service.value.send('CONFIRM')"
      @cancel-multi-allocation="xstate.service.value.send('CANCEL')"
      @select-status="(statusId) => (selectedStatusId = statusId)"
    />
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { uniqBy, sortBy, cloneDeep } from 'lodash-es'

import { EVENT } from '@/constants/events'
import {
  RESOURCE_TYPE,
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP,
  STATUS_RESOURCE_ALLOCATION,
  STATUS_RESOURCE_ALLOCATION_SORT_ORDER,
} from '@/constants/persoplan'

import { PmMultiSelectionControllerState } from '@/components/persoplan/PmMultiSelectionController/PmMultiSelectionControllerState'
import { useXState } from '@/composition/useXState'
import { getFriendlyErrors } from '@/functional/friendlyErrors'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import EventHub from '@/eventHub'

import PmMultiSelectionControllerPure from '@/components/persoplan/PmMultiSelectionController/PmMultiSelectionControllerPure.vue'

import ResourcesQuery from '@/components/persoplan/PmMultiSelectionController/ResourcesQuery.graphql'
import AddressQuery from '@/components/persoplan/PmMultiSelectionController/AddressQuery.graphql'
import VehicleQuery from '@/components/persoplan/PmMultiSelectionController/VehicleQuery.graphql'
import ResourceQuery from '@/components/persoplan/PmResource/ResourceQuery.graphql'

import CreateResourceAllocationAddressMutation from '@/components/persoplan/PmResource/CreateResourceAllocationAddressMutation.graphql'
import CreateResourceAllocationVehicleMutation from '@/components/persoplan/PmResource/CreateResourceAllocationVehicleMutation.graphql'
import ResourceAllocationStatesQuery from '@/components/persoplan/PmResourceAllocation/ResourceAllocationStatesQuery.graphql'

export default defineComponent({
  name: 'PmMultiSelectionController',
  components: {
    PmMultiSelectionControllerPure,
  },

  setup() {
    const persoplanState = injectStrict(persoplanStateKey)
    const resourcesToAllocateAdditionalData = ref({})
    const addressId = ref()
    const vehicleId = ref()

    const instance = getCurrentInstance()

    const xstate = useXState(PmMultiSelectionControllerState, {
      services: {
        createResourceAllocations: () =>
          instance.ctx.createResourceAllocations(),
      },

      actions: {
        reset: () => {
          // Reset data used for allocation
          resourcesToAllocateAdditionalData.value = {}
          addressId.value = undefined
          vehicleId.value = undefined
        },
      },
    })

    const resourceType = ref()
    const selectedStatusId = ref()

    return {
      persoplanState,
      xstate,
      resourcesToAllocateAdditionalData,
      addressId,
      vehicleId,
      resourceType,
      selectedStatusId,
    }
  },

  data() {
    return {
      resources: [],
      resourceAllocationStates: [],
    }
  },

  computed: {
    normalizedResourceFunctions() {
      let resourceFunctions = this.resources.map((resource) => {
        const abbreviation = resource?.resourceFunction?.abbreviation
        const label = abbreviation
          ? abbreviation
          : resource?.resourceFunction?.caption

        return {
          id: resource.resourceFunction.id,
          label: label,
          title: resource.resourceFunction.caption,
        }
      })

      resourceFunctions = uniqBy(resourceFunctions, 'id')

      return resourceFunctions
    },

    normalizedResourcesToAllocate() {
      return this.resources.map((resource) => {
        const additionalData =
          this.resourcesToAllocateAdditionalData[resource.id]

        const abbreviation = resource?.resourceFunction?.abbreviation
        const label = abbreviation
          ? abbreviation
          : resource?.resourceFunction?.caption

        const jobAppointmentCaption = resource.jobAppointment
          ? resource.jobAppointment?.captoin
          : '– ohne Termin –'

        return {
          id: resource.id,
          resourceFunctionLabel: label,
          resourceFunctionTitle: resource.resourceFunction.caption,
          label: `${resource.job.caption} (${jobAppointmentCaption})`,
          ...additionalData,
        }
      })
    },

    resourceTitle() {
      const type = this.$store.state.persoplan.resourceSelectionType

      if (type === RESOURCE_TYPE.ADDRESS) {
        return this.address?.displayName
      }

      if (type === RESOURCE_TYPE.VEHICLE) {
        return this.vehicle?.caption
      }

      return null
    },

    persoplanIsInEditMode() {
      return this.persoplanState.state.value.matches('edit')
    },

    resourceAllocationStatesNormalized() {
      const states = this.resourceAllocationStates.map((state) => ({
        id: state.id,
        label: state.caption,
      }))

      const statesOrdered = sortBy(states, (state) => {
        const index = STATUS_RESOURCE_ALLOCATION_SORT_ORDER.indexOf(state.id)
        return index
      })

      return statesOrdered
    },
  },

  watch: {
    persoplanIsInEditMode() {
      if (!this.persoplanIsInEditMode) {
        this.$store.commit('persoplan/clearResourceSelection')
      }
    },
  },

  methods: {
    searchResource() {
      const settings = {
        type: this.$store.state.persoplan.resourceSelectionType,
        resourceFunctionIds: this.normalizedResourceFunctions.map(
          (resourceFunction) => resourceFunction.id
        ),
      }

      this.$eventHub.$emit(EVENT.ACTIONBAR_SET_FILTER, settings)
    },

    initMultiAllocationAddress(addressId) {
      this.addressId = addressId
      this.resourceType = RESOURCE_TYPE.ADDRESS
      this.selectedStatusId =
        STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[
          STATUS_RESOURCE_ALLOCATION.CONSIDERED
        ]

      this.xstate.service.value.send('ALLOCATE')
    },

    initMultiAllocationVehicle(vehicleId) {
      this.vehicleId = vehicleId
      this.resourceType = RESOURCE_TYPE.VEHICLE
      this.selectedStatusId =
        STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[
          STATUS_RESOURCE_ALLOCATION.REQUESTED
        ]

      this.xstate.service.value.send('ALLOCATE')
    },

    async createResourceAllocations() {
      const type = this.$store.state.persoplan.resourceSelectionType

      if (type === RESOURCE_TYPE.ADDRESS) {
        return this.createResourceAllocationsAddress()
      }

      if (type === RESOURCE_TYPE.VEHICLE) {
        return this.createResourceAllocationsVehicle()
      }

      throw new Error('createResourceAllocations: No type defined')
    },

    async createResourceAllocationsAddress() {
      await Promise.all(
        this.resources.map(async (resource) => {
          return this.createResourceAllocationAddress({
            resourceId: resource.id,
            addressId: this.addressId,
          })
        })
      )
    },

    async createResourceAllocationsVehicle() {
      await Promise.all(
        this.resources.map(async (resource) => {
          return this.createResourceAllocationVehicle({
            resourceId: resource.id,
            vehicleId: this.vehicleId,
          })
        })
      )
    },

    async createResourceAllocationAddress({ resourceId, addressId }) {
      this.$store.commit('persoplan/addToResourceShowSkeletonIds', resourceId)

      // Augment normalizedResourcesToAllocate with state
      this.resourcesToAllocateAdditionalData[resourceId] = {
        state: 'saving',
      }

      try {
        await this.$apollo.mutate({
          mutation: CreateResourceAllocationAddressMutation,

          variables: {
            resourceId: resourceId,
            addressId: addressId,
            resourceAllocationStateId: this.selectedStatusId,
          },

          update: (store, result) => {
            result = result.data.createAddressAllocation
            this.updateCacheAfterCreateResourceAllocation(
              store,
              result,
              resourceId
            )

            this.resourcesToAllocateAdditionalData[resourceId].state = 'success'
          },
        })

        EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
      } catch (error) {
        const friendlyErrors = getFriendlyErrors(error)

        this.resourcesToAllocateAdditionalData[resourceId].state = 'failed'
        this.resourcesToAllocateAdditionalData[resourceId].errorMessage =
          friendlyErrors.message
        this.resourcesToAllocateAdditionalData[resourceId].errorDetails =
          friendlyErrors.details

        throw new Error('Es sind Fehler aufgetreten.', { cause: error })
      } finally {
        this.$store.commit(
          'persoplan/removeFromResourceShowSkeletonIds',
          resourceId
        )
      }
    },

    async createResourceAllocationVehicle({ resourceId, vehicleId }) {
      this.$store.commit('persoplan/addToResourceShowSkeletonIds', resourceId)

      this.resourcesToAllocateAdditionalData[resourceId] = {
        state: 'saving',
      }

      try {
        await this.$apollo.mutate({
          mutation: CreateResourceAllocationVehicleMutation,

          variables: {
            resourceId: resourceId,
            vehicleId: vehicleId,
            resourceAllocationStateId: this.selectedStatusId,
          },

          update: (store, result) => {
            result = result.data.createVehicleAllocation
            this.updateCacheAfterCreateResourceAllocation(
              store,
              result,
              resourceId
            )

            this.resourcesToAllocateAdditionalData[resourceId].state = 'success'
          },
        })

        EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
      } catch (error) {
        const friendlyErrors = getFriendlyErrors(error)

        this.resourcesToAllocateAdditionalData[resourceId].state = 'failed'
        this.resourcesToAllocateAdditionalData[resourceId].errorMessage =
          friendlyErrors.message
        this.resourcesToAllocateAdditionalData[resourceId].errorDetails =
          friendlyErrors.details

        throw new Error('Es sind Fehler aufgetreten.', { cause: error })
      } finally {
        this.$store.commit(
          'persoplan/removeFromResourceShowSkeletonIds',
          resourceId
        )
      }
    },

    updateCacheAfterCreateResourceAllocation(store, result, resourceId) {
      const queryVariables = {
        ...this.$store.getters['queryVariables/calendar'],
        id: resourceId,
      }

      const readQueryResult = store.readQuery({
        query: ResourceQuery,
        variables: queryVariables,
      })

      // readQuery is readonly, thus we need to create a deep copy
      const data = cloneDeep(readQueryResult)

      data.resource.resourceAllocations.push(result)

      store.writeQuery({
        query: ResourceQuery,
        variables: queryVariables,
        data,
      })
    },
  },

  apollo: {
    resourceAllocationStates: {
      query: ResourceAllocationStatesQuery,
    },

    resources: {
      query: ResourcesQuery,

      variables() {
        return {
          ids: this.$store.state.persoplan.resourceSelectionIds,
        }
      },

      deep: true,
    },

    address: {
      query: AddressQuery,

      skip() {
        return !this.addressId ? true : false
      },

      variables() {
        return {
          id: this.addressId,
        }
      },

      update(data) {
        return data.addresses[0]
      },
    },

    vehicle: {
      query: VehicleQuery,

      skip() {
        return !this.vehicleId ? true : false
      },

      variables() {
        return {
          id: this.vehicleId,
        }
      },
    },
  },
})
</script>

<style lang="scss">
.PmMultiSelectionController {
  $block: &;
}
</style>
