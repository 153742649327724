<template>
  <PmGeneralPageContainerPure>
    <template #control>
      <PmPollingIndicatorResourceRequests
        :initial-loading-finished="initialLoadingFinished"
      />
    </template>

    <PmGeneralPagePure title="Anfragen" layout="fullWidth">
      <PmGeneralPageSectionPure>
        <PmResourceRequestOverview
          @initial-loading-finsihed="initialLoadingFinished = true"
        />
      </PmGeneralPageSectionPure>
    </PmGeneralPagePure>
  </PmGeneralPageContainerPure>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import PmGeneralPageContainerPure from '@/components/PmGeneralPage/PmGeneralPageContainerPure.vue'
import PmGeneralPagePure from '@/components/PmGeneralPage/PmGeneralPagePure.vue'
import PmGeneralPageSectionPure from '@/components/PmGeneralPage/PmGeneralPageSectionPure.vue'
import PmResourceRequestOverview from '@/components/PmResourceRequestOverview/PmResourceRequestOverview.vue'
import PmPollingIndicatorResourceRequests from '@/components/basics/PmPollingIndicator/PmPollingIndicatorResourceRequests.vue'

const initialLoadingFinished = ref(false)
</script>
