import { createMachine } from 'xstate'

import type { Typegen0 } from './PmViewCreateState.typegen'

export type TState = Typegen0['matchesStates']

export const PmViewCreateState = createMachine({
  tsTypes: {} as import('./PmViewCreateState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'CREATE' },

    services: {} as {
      createView: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmViewCreateState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        CREATE: 'create',
      },
    },

    create: {
      initial: 'creating',
      states: {
        creating: {
          invoke: {
            id: 'create-view',
            src: 'createView',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },

            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {
          on: {
            CREATE: 'creating',
          },
        },
      },
    },
  },
})
