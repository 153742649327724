<template>
  <component :is="tag" class="PmDraggablePure" :class="classes">
    <div
      ref="elDraggable"
      class="PmDraggablePure-element"
      :class="classesElementNormalized"
    >
      <slot />
    </div>

    <portal v-if="isMirrorVisible" to="dragMirror">
      <div
        ref="elMirrorContainer"
        class="PmDraggablePure-mirrorContainer"
        :style="stylesMirror"
        :class="classesMirror"
      ></div>
    </portal>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmDraggablePure'

export const propTypes = {
  display: {
    allowed: ['autoWidth', 'fullWidth'] as const,
  },

  constrain: {
    allowed: ['x', 'y'] as const,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, reactive, computed } from 'vue'

import { useDraggable, onKeyStroke, useCurrentElement } from '@vueuse/core'
import type { Position } from '@vueuse/core'

import type { DragAndDropType } from '@/constants/persoplan'
import { useDragAndDrop } from '@/pinia/dragAndDrop'
import { useGlobalClasses } from '@/pinia/globalClasses'

export interface Props {
  tag?: string
  display?: (typeof propTypes.display.allowed)[number]
  type: DragAndDropType
  data: any
  usePlaceholder?: boolean
  canBeDropped?: boolean
  constrain?: (typeof propTypes.constrain.allowed)[number]
  /** Element need to implement a Element with `data-dragAndDrop-handle` attribute */
  useHandle?: boolean
  useMirror?: boolean
  classesElement?: string | Record<string, unknown>
  disabled?: boolean
}

export interface Delta {
  x: number
  y: number
}

const DEBUG = false

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  display: 'autoWidth',
  data: () => ({}),
  usePlaceholder: true,
  canBeDropped: true,
  useMirror: true,
})

const emit = defineEmits<{
  (event: 'dragStart'): void
  (event: 'dragEnd'): void
  (event: 'dragCancel'): void
  (event: 'dragMove', payload: { delta: Delta }): void
}>()

const dragAndDrop = useDragAndDrop()
const globalClasses = useGlobalClasses()

const el = useCurrentElement()
const elDraggable = ref<HTMLElement>()
const isTransitionActive = ref(false)
const isDragging = ref(false)
const isCancelled = ref(false)
const isMirrorOnPointer = ref(false)
const dragStartPointerPosition = ref({
  x: 0,
  y: 0,
})
const isDebugModeEnabled = ref(false)

const draggableElementDimensions = ref({
  width: 0,
  height: 0,
})

const draggableElementOffset = ref({
  x: 0,
  y: 0,
})

const scrollOffset = ref({
  x: 0,
  y: 0,
})

const draggable = reactive(
  useDraggable(elDraggable, {
    onStart: (position, event) => {
      if (props.disabled === true) return false
      if (!isDragAllowed(event)) return false

      onDragStart(position, event)
    },

    onMove: (position, event) => onDragMove(position, event),
    onEnd: () => onDragEnd(),
  })
)

onKeyStroke('Escape', () => {
  if (!isDragging.value) return
  cancelDrag()
})

if (DEBUG && import.meta.env.DEV === true) {
  onKeyStroke('d', () => {
    if (!isDragging.value) return
    isDebugModeEnabled.value = true

    globalClasses.$patch((state) => {
      state.classes.isDragAndDropInProgress = false
    })
  })
}

const dragPosition = computed(() => {
  let x = draggable.x + scrollOffset.value.x
  let y = draggable.y + scrollOffset.value.y

  if (props.constrain === 'x') {
    y = draggableElementOffset.value.y + scrollOffset.value.y
  }

  if (props.constrain === 'y') {
    x = draggableElementOffset.value.x + scrollOffset.value.x
  }

  return {
    x,
    y,
  }
})

function isDragAllowed(event: PointerEvent) {
  if (!props.useHandle) return true

  const elHandle = findHandle()

  if (!elHandle) throw new Error('elHandle is undefined')
  if (!(event.target instanceof Node))
    throw new Error('event.target ist not a Node')

  const isClickOnHandle =
    elHandle.contains(event.target) || elHandle === event.target

  return isClickOnHandle
}

async function onDragStart(position: Position, event: PointerEvent) {
  if (!elDraggable.value) throw new Error('elDraggable is undefined')

  isCancelled.value = false

  dragStartPointerPosition.value = {
    x: event.pageX,
    y: event.pageY,
  }

  const rect = elDraggable.value.getBoundingClientRect()

  draggableElementDimensions.value = {
    width: rect.width,
    height: rect.height,
  }

  draggableElementOffset.value = {
    x: rect.left,
    y: rect.top,
  }

  scrollOffset.value = {
    x: window.scrollX,
    y: window.scrollY,
  }

  isMirrorOnPointer.value = true

  if (props.useMirror) {
    await createMirror()
  } else {
    const child = elDraggable.value.children[0]
    child.classList.add('is-hover')
    child.classList.add('is-dragging')
  }

  isDragging.value = true
  emit('dragStart')

  dragAndDrop.start({
    type: props.type,
    canBeDropped: props.canBeDropped,
    data: props.data,
  })
}

function getDragDelta(currentPosition: Position) {
  const delta = {
    x: currentPosition.x - dragStartPointerPosition.value.x,
    y: currentPosition.y - dragStartPointerPosition.value.y,
  }

  return delta
}

function onDragMove(position: Position, event: PointerEvent) {
  const delta: Delta = getDragDelta({ x: event.pageX, y: event.pageY })
  emit('dragMove', { delta })
}

async function onDragEnd() {
  if (isDebugModeEnabled.value === true) return
  if (isCancelled.value === true) return

  if (!elDraggable.value) {
    throw new Error('elDraggable is undefined')
  }

  if (props.useMirror) {
    mirrorClone.value?.classList.remove('is-hover')
    mirrorClone.value?.classList.remove('is-dragging')
  } else {
    const child = elDraggable.value.children[0]
    child.classList.remove('is-hover')
    child.classList.remove('is-dragging')
  }

  /**
   * We need to wait a bit, so the onDrop events on the dropzone fire
   * before the store is resetted
   */
  await new Promise((resolve) => setTimeout(resolve, 0))

  isMirrorOnPointer.value = false

  emit('dragEnd')
  dragAndDrop.end()

  await maybeAnimateMirrorBack()
  destroyMirror()

  isDragging.value = false
}

function cancelDrag() {
  onDragEnd()
  isCancelled.value = true
  emit('dragCancel')
}

/**
 * Root
 */
const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--displayAutoWidth`]: props.display === 'autoWidth',
    [`${COMPONENT_NAME}--displayFullWidth`]: props.display === 'fullWidth',
    [`${COMPONENT_NAME}--withHandle`]: props.useHandle,
    [`${COMPONENT_NAME}--withMirror`]: props.useMirror,
    [`${COMPONENT_NAME}--withPlaceholder`]: props.usePlaceholder,
  }
})

/**
 * Draggable Element
 */
const classesElementNormalized = computed(() => {
  let classesElement
  if (typeof props.classesElement === 'string') {
    classesElement = {
      [props.classesElement]: true,
    }
  }

  if (typeof props.classesElement === 'object') {
    classesElement = props.classesElement
  }

  return {
    ...classesElement,
    'is-dragging': isDragging.value,
    'is-transitionActive': isTransitionActive.value,
  }
})

/**
 * Handle
 */
function findHandle() {
  if (!(el.value instanceof HTMLElement)) return
  const elHandle = el.value.querySelector('[data-dragAndDrop-handle]')

  if (!elHandle) {
    throw new Error(
      'useHandle is set to true, but el with name [data-dragAndDrop-handle] was not found`'
    )
  }

  if (!(elHandle instanceof HTMLElement)) {
    throw new Error('elDragHandle is not a HTMLElement')
  }

  return elHandle
}

/**
 * Placeholder
 */
const stylesPlaceholder = computed(() => {
  return {
    width: `${draggableElementDimensions.value.width}px`,
    height: `${draggableElementDimensions.value.height}px`,
  }
})

/**
 * Mirror
 */
const elMirrorContainer = ref<HTMLElement>()
const mirrorClone = ref<HTMLElement | null>()
const isMirrorVisible = ref(false)

async function createMirror() {
  if (!elDraggable.value) throw new Error('elDraggable is undefined')

  isMirrorVisible.value = true

  /**
   * It could be the case that it's enough to wait for the macro tick in onDragStart, but I'm not sure
   * how to debug this at the moment, so leaving this in just in
   */
  await new Promise((resolve) => setTimeout(resolve, 0))

  if (!elMirrorContainer.value)
    throw new Error('elMirrorContainer is undefined')

  const clone = elDraggable.value.children[0].cloneNode(true)
  if (!(clone instanceof HTMLElement)) {
    throw Error('mirrorClone is not a HTMLElement')
  }

  mirrorClone.value = clone
  elMirrorContainer.value.appendChild(mirrorClone.value)

  // Stop transitionend events on children
  mirrorClone.value.addEventListener('transitionend', (event) => {
    event.stopPropagation()
  })

  mirrorClone.value.classList.add('is-hover')
  mirrorClone.value.classList.add('is-dragging')
}

function destroyMirror() {
  if (!props.useMirror) return
  if (!elMirrorContainer.value) {
    throw new Error('elMirrorContainer is undefined')
  }

  /**
   * Delete everything inside of mirrorContainer
   * @see https://stackoverflow.com/a/40606838
   */
  while (elMirrorContainer.value && elMirrorContainer.value.firstChild) {
    elMirrorContainer.value.firstChild.remove()
  }

  mirrorClone.value = null
  // isMirrorVisible.value = false
}

async function maybeAnimateMirrorBack() {
  if (!props.useMirror) return

  isTransitionActive.value = true

  const waitForTimeout = new Promise((resolve) => setTimeout(resolve, 300))

  const waitForTransitionEnd = new Promise((resolve) => {
    if (!elMirrorContainer.value) {
      throw new Error('elMirrorContainer is undefined')
    }

    elMirrorContainer.value.addEventListener('transitionend', resolve, {
      once: true,
    })
  })

  await Promise.race([waitForTimeout, waitForTransitionEnd])

  isTransitionActive.value = false
}

const classesMirror = computed(() => {
  return {
    'is-transitionActive': isTransitionActive.value,
  }
})

const stylesMirror = computed(() => {
  let x = 0
  let y = 0

  if (isMirrorOnPointer.value) {
    x = dragPosition.value.x
    y = dragPosition.value.y
  } else {
    // This applies when the mirror transitions back
    if (!elDraggable.value) throw new Error('elDraggable is undefined')

    const rect = elDraggable.value.getBoundingClientRect()
    x = rect.x + window.scrollX
    y = rect.y + window.scrollY
  }

  return {
    transform: `translate(${x}px, ${y}px)`,
    width: `${draggableElementDimensions.value.width}px`,
    height: `${draggableElementDimensions.value.height}px`,
  }
})
</script>

<style lang="scss">
.PmDraggablePure {
  $block: &;

  position: relative;

  &-element {
    // touch-action: none; // This disables scroll on mobile, when the scroll was initialized on this element
    user-select: none;
    min-height: 20px;

    #{$block}:not(#{$block}--withHandle) & {
      cursor: grab;
    }

    #{$block}--displayAutoWidth & {
      display: inline-block;
    }

    #{$block}--displayFullWidth & {
      display: block;
    }

    &.is-dragging {
      pointer-events: none;

      #{$block}--withMirror & {
        // stylelint-disable-next-line plugin/selector-bem-pattern
        > * {
          outline: 1px solid red;
          opacity: 0;
        }
      }

      #{$block}--withMirror#{$block}--withPlaceholder & {
        // Placeholder Styles
        &::before {
          content: '';
          position: absolute;
          width: v-bind('stylesPlaceholder.width');
          height: v-bind('stylesPlaceholder.height');
          border: 2px dashed color.$gray-400;
          background-color: color.$gray-100;
          border-radius: constant.$borderRadius-default;
        }
      }
    }

    &.is-transitionActive {
      pointer-events: none;
    }
  }

  &-handle {
    cursor: grab;
  }

  &-mirrorContainer {
    will-change: transform;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;

    &.is-transitionActive {
      transition: transform 0.2s;
    }

    #{$block}--autoWidth & {
      display: inline-block;
    }

    #{$block}--fullWidth & {
      display: block;
    }
  }
}
</style>
