<template>
  <div class="PmSidebarContainerPure" :style="styles" :class="classes">
    <div v-show="isVisible" class="PmSidebarContainerPure-inner">
      <div ref="content" class="PmSidebarContainerPure-content">
        <div
          v-show="contentVisible"
          class="PmSidebarContainerPure-contentInner"
        >
          <slot />
          <slot name="bottom" />
        </div>
      </div>
    </div>

    <PmSidebarControllerPure
      class="PmSidebarContainerPure-controller"
      :width="cssVar.width"
      :min-width="cssVar.min"
      :max-width="cssVar.max"
      :collapsed="!isVisible"
      :align="align"
      :is-collapsed="!isVisible"
      @drag-start="onDragStart"
      @drag="onDrag"
      @drag-end="onDragEnd"
      @toggle="toggle"
    />
  </div>
</template>

<script>
/**
 * @todo: This is not a pure component, needs to be renamed to PmSidebarContainer
 */

import PmSidebarControllerPure from '@/components/persoplan/PmSidebarController/PmSidebarControllerPure.vue'

export const propTypes = {
  align: {
    allowed: ['left', 'right'],
    default: 'left',
  },
  height: {
    allowed: ['auto', 'fixed'],
    default: 'auto',
  },
}

export default {
  name: 'PmSidebarContainerPure',
  components: {
    PmSidebarControllerPure,
  },

  props: {
    contentVisible: { type: Boolean, default: true },
    name: { type: String, required: true },
    align: { type: String, default: propTypes.align.default },
    height: { type: String, default: propTypes.height.default },
  },

  data() {
    return {
      isDragging: false,
    }
  },

  computed: {
    classes() {
      return {
        [`${this.$options.name}--alignLeft`]: this.align === 'left',
        [`${this.$options.name}--alignRight`]: this.align === 'right',
        [`${this.$options.name}--heightAuto`]: this.height === 'auto',
        [`${this.$options.name}--heightFixed`]: this.height === 'fixed',
        'is-dragging': this.isDragging === true,
      }
    },

    cssVarWidthName() {
      return `${this.name}Width`
    },

    isVisibleStoreProperty() {
      return `${this.name}Visible`
    },

    isVisible() {
      return this.$store.state.persoplan[this.isVisibleStoreProperty]
    },

    cssVar() {
      const value = this.$store.getters['cssVar/get'](this.cssVarWidthName)
      if (!value) return

      return {
        width: value.value,
        min: value.min,
        max: value.max,
      }
    },

    styles() {
      return {
        [`--${this.$options.name}-width`]: `var(--${this.cssVarWidthName}WithVisibility)`,
      }
    },
  },

  watch: {
    isVisible(to, from) {
      if (from === false && to === true) {
        this.setSensibleSidebarWidth()
      }
    },
  },

  created() {
    this.setSensibleSidebarWidth()
  },

  methods: {
    onDragStart() {
      this.isDragging = true
    },

    onDrag(newWidth) {
      // Update css directly to prevent flooding the vuex store
      document.documentElement.style.setProperty(
        `--${this.cssVarWidthName}`,
        `${newWidth}px`
      )

      document.documentElement.style.setProperty(
        `--${this.cssVarWidthName}WithVisibility`,
        `${newWidth}px`
      )
    },

    onDragEnd(width) {
      this.isDragging = false

      this.$store.commit('cssVar/set', {
        name: this.cssVarWidthName,
        value: width,
      })
    },

    toggle() {
      const isVisible = this.$store.state.persoplan[this.isVisibleStoreProperty]

      isVisible
        ? this.$store.commit('persoplan/hideSidebar', { name: this.name })
        : this.$store.commit('persoplan/showSidebar', { name: this.name })
    },

    setSensibleSidebarWidth() {
      const maxWidth = document.documentElement.clientWidth
      const padding = 50

      if (this.cssVar.width + padding < maxWidth) return

      const newWidth = maxWidth - padding

      this.$store.commit('cssVar/set', {
        name: this.cssVarWidthName,
        value: newWidth,
      })
    },
  },
}
</script>

<style lang="scss">
.PmSidebarContainerPure {
  $block: &;

  @include cssVar.define($block, 'width'); // Filled by vue

  z-index: 10;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;

  &--alignLeft {
    justify-content: flex-start;
  }

  &--alignRight {
    justify-content: flex-end;
  }

  &--heightAuto {
    height: auto;
    position: absolute;
    top: 0;
    min-height: var(--PmPersoplan-minHeight);
  }

  &--heightFixed {
    position: fixed;
    top: var(--navigationHeight);
    height: var(--PmPersoplan-minHeight);
  }

  &-inner {
    background-color: color.$white;
    width: cssVar.use($block, 'width');
    pointer-events: auto;

    #{$block}--alignLeft & {
      left: 0;
    }
    #{$block}--alignRight & {
      right: 0;
    }

    #{$block}--heightAuto & {
      position: sticky;
      min-height: var(--PmPersoplan-minHeight);
      height: auto;
    }

    #{$block}--heightFixed & {
      height: var(--PmPersoplan-minHeight);
      position: fixed;
    }

    #{$block}.is-dragging & {
      pointer-events: none;
    }

    // Gradient and Border
    &::after {
      content: '';
      width: 50px;
      position: absolute;
      top: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 10;
      background: linear-gradient(
          #{cssVar.use($block, 'gradientAngle')},
          rgba(#000, 0.06),
          ease-out,
          rgba(#000, 0)
        ),
        linear-gradient(
          #{cssVar.use($block, 'gradientAngle')},
          rgba(#000, 0.1),
          cubic-bezier(0, 0, 0, 1),
          rgba(#000, 0)
        );

      #{$block}--alignLeft & {
        @include cssVar.define($block, 'gradientAngle', 90deg);

        left: 100%;
      }

      #{$block}--alignRight & {
        @include cssVar.define($block, 'gradientAngle', -90deg);

        right: 100%;
      }
    }
  }

  &-content {
    position: relative;

    #{$block}--heightFixed & {
      height: 100%;
    }
  }

  &-contentInner {
    #{$block}--heightFixed & {
      height: 100%;
    }
  }

  &-controller {
    position: fixed;
    top: var(--navigationHeight);
    height: 100%;
    pointer-events: auto;

    #{$block}--alignLeft & {
      left: cssVar.use($block, 'width');
    }

    #{$block}--alignRight & {
      right: cssVar.use($block, 'width');
    }
  }
}
</style>
