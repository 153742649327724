<template>
  <Portal to="dialog">
    <transition appear @after-leave="emitReset" @before-enter="emitReset">
      <div :v-if="isVisible" :class="[componentClass.root, classes]">
        <div :class="componentClass.element('inner')">
          <PmDialogPanelPure
            v-bind="{ ...$attrs, ...$props }"
            class="PmDialogPure-panel"
            @close="emit('close')"
          >
            <slot />
          </PmDialogPanelPure>
        </div>
      </div>
    </transition>
  </Portal>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Portal } from 'portal-vue'

import PmDialogPanelPure, {
  type Props as PropsDialogPanelPure,
} from '@/components/basics/PmDialog/PmDialogPanelPure.vue'
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props extends PropsDialogPanelPure {
  layout: 'default' | 'fullWidth'
  isVisible?: boolean
}

const COMPONENT_NAME = 'PmDialogPure'

const props = withDefaults(defineProps<Props>(), {
  layout: 'default',
  isVisible: true,
  canBeClosed: undefined,
})

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'reset'): void
}>()

const componentClass = useComponentClass()

// const emit = defineEmits(['confirm', 'reset'])

const classes = computed(() => ({
  [`${COMPONENT_NAME}--layoutDefault`]: props.layout === 'default',
  [`${COMPONENT_NAME}--layoutFullWidth`]: props.layout === 'fullWidth',
}))

function emitReset() {
  emit('reset')
}
</script>

<style lang="scss">
.PmDialogPure {
  $block: &;

  position: fixed;
  inset: 0;
  z-index: constant.$zIndex-dialog;

  &.v-enter-active,
  &.v-leave-active {
    transition-duration: constant.$duration-default;
  }

  &::after {
    content: '';
    inset: 0;
    position: fixed;
    background-color: color.$gray-300--alpha;
    opacity: 1;
    transition: opacity constant.$duration-default;
    pointer-events: none;
  }

  &.v-enter-from::after,
  &.v-leave-to::after {
    opacity: 0;
  }

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: var(--space-smallest);

    &::before {
      content: '';
      flex-grow: 1;
    }

    &::after {
      content: '';
      flex-grow: 2;
    }
  }

  &-panel {
    z-index: 1;
    width: 100%;

    #{$block}--layoutDefault & {
      max-width: 600px;
    }

    #{$block}.v-enter-active &,
    #{$block}.v-leave-active & {
      transition-duration: constant.$duration-default;
    }

    #{$block}.v-enter-from &,
    #{$block}.v-leave-to & {
      opacity: 0;
      transform: translateY(20px);
    }
  }
}
</style>
