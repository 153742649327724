<template>
  <div :class="componentClass.root">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
</script>

<style lang="scss">
.PmTimelinePure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    background-color: color.$gray-200;
    width: 3px;
    border-radius: 4px;
    height: 100%;
    position: absolute;
    top: 4px;
    left: 0;
  }
}
</style>
