import {
  LeaveRequestType as EnumLeaveRequestType,
  LeaveRequestState,
} from '@/../generated/graphql'

export type LeaveRequestType = `${EnumLeaveRequestType}`

export const LEAVE_REQUEST_TYPE_LABEL_LOOKUP = {
  vacation: 'Urlaub',
  specialVacation: 'Sonderurlaub',
  overtimeCompensation: 'Stundenausgleich',
} as const satisfies Record<EnumLeaveRequestType, string>

export type LeaveRequestTypeLabel =
  (typeof LEAVE_REQUEST_TYPE_LABEL_LOOKUP)[keyof typeof LEAVE_REQUEST_TYPE_LABEL_LOOKUP]

export type LeaveRequestStatus =
  | 'notProcessed'
  | 'inProgress'
  | 'aborted'
  | 'approved'
  | 'rejected'

export const LEAVE_REQUEST_STATUS_LABEL_LOOKUP = {
  notProcessed: 'Nicht bearbeitet',
  inProgress: 'In Bearbeitung',
  aborted: 'Zurückgezogen',
  approved: 'Genehmigt',
  rejected: 'Abgelehnt',
} as const satisfies Record<LeaveRequestStatus, string>

/**
 * Lookup frontend status from backend status
 */
export const LEAVE_REQUEST_STATUS_LOOKUP = {
  created: 'notProcessed',
  updated: 'inProgress',
  aborted: 'aborted',
  accepted: 'approved',
  declined: 'rejected',
} as const satisfies Record<`${LeaveRequestState}`, LeaveRequestStatus>

export function isLeaveRequest(type: string): type is EnumLeaveRequestType {
  return Object.values(EnumLeaveRequestType).includes(type as any)
}
