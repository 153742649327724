import { createMachine } from 'xstate'

import type { Typegen0 } from './PmViewDuplicateState.typegen'

export type TState = Typegen0['matchesStates']

export const PmViewDuplicateState = createMachine({
  tsTypes: {} as import('./PmViewDuplicateState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'DUPLICATE' },

    services: {} as {
      duplicateView: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },
  id: 'PmViewDuplicateState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        DUPLICATE: 'duplicate',
      },
    },

    duplicate: {
      initial: 'duplicating',
      states: {
        duplicating: {
          invoke: {
            id: 'duplicate-view',
            src: 'duplicateView',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },

            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {
          on: {
            DUPLICATE: 'duplicating',
          },
        },
      },
    },
  },
})
