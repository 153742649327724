<template>
  <div class="PmNotePure" :class="classes">
    <div v-if="titleVisible" class="PmNotePure-title">
      {{ subjectNormalized }}
    </div>

    <div class="PmNotePure-controlPermanent">
      <PmButtonPure
        v-if="!xstate.state.value.matches('editing') && editButtonVisible"
        class="PmNotePure-edit"
        :icon="ICONS.EDIT"
        variant="warning"
        alternative="ghost"
        :title="`${name} bearbeiten`"
        size="small"
        :disabled="isLoading"
        @click="xstate.service.value.send('EDIT')"
      />
    </div>

    <div class="PmNotePure-contentContainer">
      <template v-if="!xstate.state.value.matches('editing')">
        <div v-if="hasContent && !isLoading" class="PmNotePure-content">
          {{ note }}
        </div>

        <div
          v-if="!hasContent && !isLoading"
          class="PmNotePure-content PmNotePure-content--placeholder"
        >
          Keine {{ name }} vorhanden
        </div>

        <PmLoadingPure v-if="isLoading" size="small" />
      </template>

      <PmTextareaPure
        v-if="xstate.state.value.matches('editing')"
        ref="textarea"
        v-model:value="value"
        class="PmNotePure-textarea"
        :label="`${name} bearbeiten`"
        :rows="3"
        :disabled="xstate.state.value.matches('editing.saving')"
        :focus-input-on-mount="true"
        @keydown.esc.stop="xstate.service.value.send('CANCEL')"
      />
    </div>

    <div
      v-if="xstate.state.value.matches('editing')"
      class="PmNotePure-control"
    >
      <PmErrorNotificationPure
        v-if="xstate.state.value.matches('editing.failed')"
        class="PmDataModalProjectSettingsPure-notification"
        :message="errorMessage || xstate.meta.value.errorMessage"
        :details="errorDetails"
      />

      <PmButtonListPure>
        <PmButtonPure
          label="Speichern"
          variant="primary"
          :loading="xstate.state.value.matches('editing.saving')"
          @click="$emit('save', value)"
        />

        <PmButtonPure
          label="Abbrechen"
          variant="secondary"
          :disabled="xstate.state.value.matches('editing.saving')"
          @click="xstate.service.value.send('CANCEL')"
        />
      </PmButtonListPure>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmNotePure'

export const propTypes = {
  state: {
    allowed: states,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef, ref, computed } from 'vue'
import { ICONS } from '@/constants/icons'

import { useXState } from '@/composition/useXState'

import { PmNotePureState, states } from '@/components/PmNote/PmNotePureState'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  isLoading?: boolean
  note?: Nilable<string>
  errorMessage?: string
  errorDetails?: string[]
  state?: (typeof propTypes.state.allowed)[number]
  name?: Nilable<string>
  subject?: Nilable<string>
  editButtonVisible?: boolean
  compact?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  name: 'Bemerkung',
})

const emit = defineEmits<{
  (event: 'save', payload: typeof value.value): void
  (event: 'cancel'): void
}>()

const value = ref<string | undefined>()

const xstate = useXState(PmNotePureState, {
  actions: {
    initializeEdit: () => {
      value.value = props.note ?? undefined
    },

    emitCancel: () => {
      emit('cancel')
    },
  },
  syncStateWith: toRef(props, 'state'),
})

const classes = computed(() => {
  return {
    'is-editing': xstate.state.value.matches('editing'),
    [`${COMPONENT_NAME}--compact`]: props.compact,
  }
})

const hasContent = computed(() => {
  if (!props.note) return false
  return props.note.length > 0
})

const titleVisible = computed(() => {
  if (xstate.state.value.matches('editing')) return false
  if (subjectNormalized.value === null) return false

  return true
})

const subjectNormalized = computed(() => {
  if (props.subject !== undefined) return props.subject
  return props.name
})
</script>

<style lang="scss">
@use 'sass:color' as sassColor;
@use '@/assets/scss/shadows.scss' as shadow;

.PmNotePure {
  $block: &;

  @include shadow.default(
    'low',
    $outline: true,
    $outlineColor: sassColor.adjust(color.$warning-200, $saturation: -40%)
  );

  background-color: color.$warning-100;
  color: color.$warning-900;
  padding: 16px;
  border-radius: constant.$borderRadius-default;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'title controlPermanent'
    'contentContainer contentContainer'
    'control control';

  &.is-editing {
    border: 1px solid color.$gray-300;
    background-color: color.$white;
    color: color.$text-default;
  }

  &--compact {
    padding: 12px;
  }

  &-title {
    grid-area: title;
    font-weight: 700;
    margin-bottom: 0.5em;

    #{$block}--compact & {
      font-size: constant.$fontSize-default;
    }
  }

  &-controlPermanent {
    grid-area: controlPermanent;
  }

  &-edit {
    position: relative;
    margin: -4px;
  }

  &-icon {
    width: 20px;
    height: 20px;
    position: relative;
    color: color.$warning-900;
  }

  &-contentContainer {
    grid-area: contentContainer;
  }

  &-content {
    font-weight: 500;
    margin: 0;
    display: block;
    border: 1px solid transparent;
    line-height: constant.$lineHeight-default;
    white-space: pre-line;
    word-break: break-word;
    hyphens: auto;

    &--placeholder {
      opacity: 0.5;
    }

    #{$block}--compact & {
      font-size: constant.$fontSize-default;
    }
  }

  &-control {
    grid-area: control;
    margin-top: 16px;
  }
}
</style>
