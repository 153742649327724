<template>
  <PmLogoutPure
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useXState } from '@/composition/useXState'

import { PmLogoutState } from '@/views/PmLogout/PmLogoutState'
import { appStateKey, injectStrict } from '@/utilities/inject'

import PmLogoutPure from '@/views/PmLogout/PmLogoutPure.vue'

export default defineComponent({
  name: 'PmLogout',
  components: {
    PmLogoutPure,
  },

  setup() {
    const store = useStore()
    const appState = injectStrict(appStateKey)

    const xstate = useXState(PmLogoutState, {
      services: {
        logout: () => store.dispatch('auth/logout'),
      },

      actions: {
        logoutSuccessful: () => {
          appState.service.value.send('LOGGED_OUT')
        },
      },
    })

    onMounted(() => {
      xstate.service.value.send('SIGN_OUT')
    })

    return {
      xstate,
    }
  },
})
</script>

<style lang="scss">
.PmLogout {
  $block: &;
}
</style>
