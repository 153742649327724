<template>
  <PmNotificationContainerPure
    notification-type="resourceRequestFeedback"
    :date="date"
    :is-unread="isUnread"
    :message="message"
    class="PmNotificationResourceRequestFeedbackPure"
  >
    <template #content>
      <template v-if="type === 'created'">
        <div class="PmNotificationResourceRequestFeedbackPure-status">
          <PmStatusPillPure
            type="resourceRequestFeedback"
            :resource-request-feedback-status="statusAfter"
          />
        </div>
      </template>

      <template v-if="type === 'updated'">
        <PmFromToPure>
          <template #from>
            <PmStatusPillPure
              type="resourceRequestFeedback"
              :resource-request-feedback-status="statusBefore"
            />
          </template>

          <template #to>
            <PmStatusPillPure
              type="resourceRequestFeedback"
              :resource-request-feedback-status="statusAfter"
            />
          </template>
        </PmFromToPure>
      </template>
    </template>

    <template #meta>
      <PmMetaListItemPure label="Projekt">
        {{ projectNumber }} {{ projectName }}

        <template v-if="jobs">
          <template v-if="isSingleJob">
            für einen Job am {{ timeframe }}</template
          >

          <template v-else>
            für {{ jobs.length }} Jobs zwischen {{ timeframe }}
          </template>
        </template>
      </PmMetaListItemPure>

      <PmMetaListItemPure label="Leitung">
        {{ projectLeader.firstName }} {{ projectLeader.lastName }}
      </PmMetaListItemPure>

      <PmMetaListItemPure v-if="status && status.status" label="Status">
        <PmStatusDotPure
          type="resourceRequest"
          :resource-request-status="status.status"
          :is-label-visible="true"
        />&nbsp;von {{ status.updatedBy }}
        <PmDatePure :date="status.updatedAt" />
      </PmMetaListItemPure>
    </template>

    <template #actions>
      <PmButtonListPure>
        <PmButtonPure label="Details" @click="emit('openDetails')" />

        <PmButtonPure
          icon="jump"
          :title="jumpButtonTitle"
          :disabled="isJumpDisabled"
          @click="emit('jump')"
        />
      </PmButtonListPure>
    </template>
  </PmNotificationContainerPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { min, max } from 'date-fns'

import type {
  StatusResourceRequest,
  StatusResourceRequestFeedback,
} from '@/constants/persoplan'

import { startEndDate } from '@/utilities/date'

import PmNotificationContainerPure, {
  type Props as PropsNotificationContainerPure,
} from '@/components/PmNotification/PmNotificationContainerPure.vue'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'
import PmDatePure from '@/components/basics/PmDate/PmDatePure.vue'
import PmMetaListItemPure from '@/components/PmMetaList/PmMetaListItemPure.vue'
import type { ResourceAllocation } from 'generated/graphql'
import type { Nilable } from '@/types/misc'
import PmFromToPure from '@/components/persoplan/PmFromTo/PmFromToPure.vue'

export interface Job {
  id: number
  startDate: Date
  endDate: Date
}

export interface Status {
  status?: Nilable<StatusResourceRequest>
  updatedAt?: Date
  updatedBy?: string
}

export interface Props
  extends Pick<PropsNotificationContainerPure, 'date' | 'isUnread'> {
  type: 'created' | 'updated'
  name: string
  statusBefore: Nilable<StatusResourceRequestFeedback>
  statusAfter: Nilable<StatusResourceRequestFeedback>
  projectNumber: string
  projectName: string
  jobs?: Job[]
  projectLeader: {
    firstName: string
    lastName: string
  }
  status?: Status
  resourceAllocations?: ResourceAllocation[]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openDetails'): void
  (event: 'jump'): void
}>()

const message = computed(() => {
  if (props.type === 'created') {
    return `${props.name} hat auf eine Ressourcen-Anfrage geantwortet`
  }

  if (props.type === 'updated') {
    return `${props.name} hat seine/ihre Antwort auf eine Ressourcen-Anfrage geändert`
  }

  return undefined
})

const isSingleJob = computed(() => {
  if (!props.jobs) return
  return props.jobs.length <= 1
})

const timeframe = computed(() => {
  if (!props.jobs) return
  if (props.jobs.length === 0) return

  if (isSingleJob.value) {
    const firstJob = props.jobs[0]
    return startEndDate(firstJob.startDate, firstJob.endDate, {
      showDateAnyway: true,
    })
  }

  // Now it's multiple jobs
  const allStartDates = props.jobs.map((job) => job.startDate)
  const earliestStartDate = min(allStartDates)

  const allEndDates = props.jobs.map((job) => job.endDate)
  const latestEndDate = max(allEndDates)

  return startEndDate(earliestStartDate, latestEndDate, {
    showDateAnyway: true,
  })
})

const isJumpDisabled = computed(() => {
  if (isSingleJob.value !== true) return true
  return false
})

const jumpButtonTitle = computed(() => {
  if (isSingleJob.value !== true) {
    return 'blupp'
  }

  return 'zum Job springen'
})
</script>

<style lang="scss">
.PmNotificationResourceRequestFeedbackPure {
  $block: &;
}
</style>
