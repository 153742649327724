<template>
  <PmEventsPure
    :container-start-date="startDate"
    :container-end-date="endDate"
    class="PmCalendarEvents"
  >
    <PmEventsItemPure
      v-for="event in normalizedEvents"
      :key="event.id"
      v-slot="{ styles, isConstrainedStart, isConstrainedEnd }"
      :start-date="event.startDate"
      :end-date="event.endDate"
      :container-start-date="startDate"
      :container-end-date="endDate"
    >
      <PmCalendarEventPure
        :style="styles"
        v-bind="event"
        :is-constrained-start="isConstrainedStart"
        :is-constrained-end="isConstrainedEnd"
      />
    </PmEventsItemPure>
  </PmEventsPure>
</template>

<script>
import {
  startDateForServer,
  endDateForServer,
  parseServerDateString,
} from '@/utilities/date'

import PmEventsPure from '@/components/persoplan/PmGeneralEvents/PmEventsPure.vue'
import PmEventsItemPure from '@/components/persoplan/PmGeneralEvents/PmEventsItemPure.vue'
import PmCalendarEventPure from '@/components/persoplan/PmGeneralEvents/PmCalendarEventPure.vue'

import CalendarEventsQuery from '@/components/persoplan/PmGeneralEvents/CalendarEventsQuery.graphql'

export default {
  name: 'PmCalendarEvents',
  components: {
    PmEventsPure,
    PmEventsItemPure,
    PmCalendarEventPure,
  },

  props: {
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
  },

  data() {
    return {
      calendarEvents: [],
    }
  },

  computed: {
    normalizedEvents() {
      return this.calendarEvents.map((calendarEvent) => {
        const attendees = calendarEvent.attendees.map((attendee) => {
          return {
            name:
              attendee.address?.displayName || attendee.displayName || 'n/a',
            status: attendee.status,
          }
        })

        return {
          name: calendarEvent.caption,
          startDate: parseServerDateString(calendarEvent.startDate),
          endDate: parseServerDateString(calendarEvent.endDate),
          location: calendarEvent.location,
          priority: calendarEvent.importance,
          attendees: attendees,
        }
      })
    },
  },

  apollo: {
    calendarEvents: {
      query: CalendarEventsQuery,

      variables() {
        return {
          startDate: startDateForServer(this.startDate),
          endDate: endDateForServer(this.endDate),
        }
      },
    },
  },
}
</script>

<style lang="scss">
.PmCalendarEvents {
  $block: &;
}
</style>
