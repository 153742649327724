<template>
  <div class="PmCheckboxListPure">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PmCheckboxListPure',
  components: {},
}
</script>

<style lang="scss">
.PmCheckboxListPure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
