<template>
  <PmSidebarContainerPure
    name="sidebarRight"
    align="right"
    height="fixed"
    class="PmActionbarPure"
  >
    <div class="PmActionbarPure-scrolling">
      <div class="PmActionbarPure-content">
        <div class="PmActionbarPure-header">
          <div class="PmActionbarPure-tabs">
            <PmTabNavigationPure
              :expanded="true"
              :tabs="tabs"
              :selected-tab-id="selectedTabId"
              @update:selected-tab-id="(id) => $emit('onTabChange', id)"
            />
          </div>

          <div class="PmActionbarPure-filter">
            <PmActionbarFilterPure
              v-if="selectedTabId === RESOURCE_TYPE.ADDRESS"
              :options="filterAddressOptions"
              :value="filterAddressValues"
              :is-search-loading="isSearchAddressLoading"
              :is-options-loading="isFilterAddressOptionsLoading"
              :type="RESOURCE_TYPE.ADDRESS"
              @input="$emit('updateFilterAddress', $event)"
            />

            <PmActionbarFilterPure
              v-if="selectedTabId === RESOURCE_TYPE.VEHICLE"
              :options="filterVehicleOptions"
              :value="filterVehicleValues"
              :is-search-loading="isSearchVehicleLoading"
              :type="RESOURCE_TYPE.VEHICLE"
              @input="$emit('updateFilterVehicle', $event)"
            />
          </div>
        </div>

        <div class="PmActionbarPure-list">
          <PmActionbarListPure
            v-if="selectedTabId === RESOURCE_TYPE.ADDRESS"
            :items="itemsAddressFiltered"
            :type="RESOURCE_TYPE.ADDRESS"
          />

          <PmActionbarListPure
            v-if="selectedTabId === RESOURCE_TYPE.VEHICLE"
            :items="itemsVehicleFiltered"
            :type="RESOURCE_TYPE.VEHICLE"
          />
        </div>
      </div>
    </div>
  </PmSidebarContainerPure>
</template>

<script>
import Fuse from 'fuse.js'

import propValidator from '@/functional/propValidator'
import { RESOURCE_TYPE } from '@/constants/persoplan'

import PmSidebarContainerPure from '@/components/persoplan/PmSidebarContainer/PmSidebarContainerPure.vue'
import PmTabNavigationPure from '@/components/basics/PmTabNavigation/PmTabNavigationPure.vue'
import PmActionbarFilterPure from '@/components/persoplan/Actionbar/PmActionbarFilterPure.vue'
import PmActionbarListPure from '@/components/persoplan/Actionbar/PmActionbarListPure.vue'

export default {
  name: 'PmActionbarPure',
  components: {
    PmSidebarContainerPure,
    PmTabNavigationPure,
    PmActionbarFilterPure,
    PmActionbarListPure,
  },

  props: {
    selectedTabId: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, RESOURCE_TYPE),
    },
    itemsAddress: { type: Array, default: () => [] },
    itemsVehicle: { type: Array, default: () => [] },
    filterAddressOptions: { type: Object, default: () => ({}) },
    filterAddressValues: { type: Object, default: () => ({}) },
    filterVehicleOptions: { type: Object, default: () => ({}) },
    filterVehicleValues: { type: Object, default: () => ({}) },
    isSearchAddressLoading: { type: Boolean, default: undefined },
    isSearchVehicleLoading: { type: Boolean, default: undefined },
    isFilterAddressOptionsLoading: { type: Boolean, default: undefined },
  },

  emits: ['onTabChange', 'updateFilterAddress', 'updateFilterVehicle'],

  setup() {
    return {
      RESOURCE_TYPE,
    }
  },

  data() {
    return {
      tabs: [
        { id: RESOURCE_TYPE.ADDRESS, label: 'Personal' },
        { id: RESOURCE_TYPE.VEHICLE, label: 'Fahrzeug' },
      ],
      fuseAddress: undefined, // TODO: Use shallowRef
      fuseVehicle: undefined, // TODO: Use shallowRef
    }
  },

  computed: {
    itemsAddressFiltered() {
      if (!this.filterAddressValues.searchterm) return this.itemsAddress

      // Do search
      // Fuse collection gets updated as a side-effect of change in options
      const results = this.fuseAddress.search(
        this.filterAddressValues.searchterm
      )
      return results.map((result) => result.item)
    },

    itemsVehicleFiltered() {
      if (!this.filterVehicleValues.searchterm) return this.itemsVehicle

      // Do search
      // Fuse collection gets updated as a side-effect of change in options
      const results = this.fuseVehicle.search(
        this.filterVehicleValues.searchterm
      )
      return results.map((result) => result.item)
    },
  },

  watch: {
    itemsAddress() {
      this.fuseAddress.setCollection(this.itemsAddress)
    },

    itemsVehicle() {
      this.fuseVehicle.setCollection(this.itemsVehicle)
    },
  },

  created() {
    const fuseOptions = {
      keys: ['title'],
      threshold: 0.4, // https://fusejs.io/api/options.html#threshold
      shouldSort: false,
      // includeMatches: true // TODO: Use matches to highlight searchresults
    }

    this.fuseAddress = new Fuse(this.itemsAddress, fuseOptions)
    this.fuseVehicle = new Fuse(this.itemsVehicle, fuseOptions)
  },
}
</script>

<style lang="scss">
.PmActionbarPure {
  &-scrolling {
    @include mixin.betterScrollbars;

    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    // overflow-x: hidden;
  }

  &-content {
    width: 100%;
  }

  &-header {
    padding: 10px;
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid color.$gray-300--alpha;
    z-index: 1;
  }

  &-tabs {
    margin-bottom: var(--space-gutters);
  }

  &-list {
    padding: 10px;
  }
}
</style>
