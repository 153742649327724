import axios from 'axios'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'
import { indexOf, has } from 'lodash-es'

import { USE_SENTRY } from '@/constants/general'

import { apolloClient } from '@/vue-apollo'
import runtimeVariables from '@/utilities/runtimeVariables'
import { throwFriendlyError } from '@/functional/error'

const AUTH_KEY = 'promusik-auth'

axios.defaults.baseURL = runtimeVariables.apiUrl + '/api'
axios.defaults.withCredentials = true
// TODO: Needed?
// axios.defaults.headers.common['Accept'] = 'application/json'
// TODO: Needed?
// axios.defaults.headers.common['Content-Type'] = 'application/json'

const getDefaultState = () => ({
  token: undefined,
  data: {},
})

const state = getDefaultState()

const getters = {
  isLoggedIn: (state, getters) => {
    if (state.token === null) {
      return false
    }

    return state.token && !getters.hasToChangePassword
  },

  hasRole: (state) => (role) => {
    return indexOf(state.data.roles, role) > 0
  },

  hasToChangePassword: (state) => {
    // eslint-disable-next-line
    state.data.hasOwnProperty('changePasswordNextLogin') &&
      state.data.changePasswordNextLogin === true
  },

  name: (state) => (state.data === null ? null : state.data.name),
}

const mutations = {
  setAuth(state, result) {
    state.token = result.token
    state.data = result.data

    axios.defaults.headers.common['Authorization'] = state.token

    if (USE_SENTRY) {
      Sentry.configureScope((scope) => {
        scope.setUser({ username: state.data?.username })
      })
    }

    // write auth data to local storage:
    const data = JSON.stringify({
      token: state.token,
      data: state.data,
    })

    // TODO: Use vuex plugin for this
    window.localStorage.setItem(AUTH_KEY, data)
  },

  resetAuth(state) {
    delete axios.defaults.headers.common['Authorization']
    Object.assign(state, getDefaultState()) // reset state
    localStorage.removeItem(AUTH_KEY) // remove localStorage data
  },

  setChangePasswordNextLogin(state, value) {
    state.data.changePasswordNextLogin = value
  },
}

const actions = {
  passwordChanged({ commit, dispatch }) {
    commit('setChangePasswordNextLogin', false)
    dispatch('persistToLocalStorage')
  },

  async logout({ commit, dispatch }) {
    // console.log(
    //   'axios token logout:',
    //   axios.defaults.headers.common['authorization'],
    //   state
    // )

    try {
      await axios.get('auth/logout')
    } catch (error) {
      const transformedError = new Error(error.response.data.error)
      throwFriendlyError(transformedError)
    }

    if (USE_SENTRY) {
      Sentry.configureScope((scope) => {
        scope.setUser(null)
      })
    }

    commit('resetAuth')
    await dispatch('clearApolloStore')

    // commit('decrementLoadingCount', null, { root: true })

    // reload because there's no way to stop pending requests at the moment
    // Status: https://github.com/apollographql/apollo-feature-requests/issues/40
    // If anyone removes this reload, one has to make sure to reset
    // the vuex store to default values!
    // router.push({ name: 'login' })
    // router.push({ name: 'login' })
    // window.location.reload()
  },

  /**
   * client.resetStore clears the store AND refetches all active queries
   */
  async resetApolloStore() {
    try {
      await apolloClient.resetStore()
    } catch (error) {
      throwFriendlyError(error)
    }
  },

  /**
   * client.clearStore only clears the store and does not refetch active queries
   */
  async clearApolloStore() {
    try {
      apolloClient.clearStore()
    } catch (error) {
      throwFriendlyError(error)
    }
  },

  async loginWithCredentials(
    { commit, dispatch },
    { username, password, resetApolloStore = true }
  ) {
    try {
      const result = await axios
        .post('auth/login', {
          username,
          password,
        })
        .catch((error) => {
          throw new Error(error.response.data.error)
        })

      if (resetApolloStore) {
        await dispatch('resetApolloStore')
      }

      commit('setAuth', result.data)
    } catch (error) {
      throwFriendlyError(error)
    }
  },

  async loginWithSso({ commit, dispatch }) {
    try {
      const cookie = Cookies.get('intranet_user')
      if (!cookie) throw new Error('No Cookie')

      const result = await axios
        .get('auth/sso', { withCredentials: true })
        .catch((error) => {
          throw new Error(error.response.data.error)
        })

      commit('setAuth', result.data)

      await dispatch('resetApolloStore')
    } catch (error) {
      throwFriendlyError(error)
    }
  },

  async loginWithLocalStorage({ commit }) {
    console.log('get token from local storage')

    let auth = localStorage.getItem(AUTH_KEY)
    if (!auth) throw new Error('no data in local storage')

    auth = JSON.parse(auth)

    if (!has(auth, 'token')) throw new Error('no token in local storage')
    if (!has(auth, 'data.username'))
      throw new Error('no username in local storage')
    if (!has(auth, 'data.roles')) throw new Error('no roles in local storage')

    commit('setAuth', auth)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
