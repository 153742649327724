<template>
  <div class="PmLoginFormPure">
    <div v-if="titleVisible" class="PmLoginFormPure-title">Anmelden</div>

    <PmErrorNotificationPure
      v-if="xstate.meta.value.error"
      class="PmLoginFormPure-error"
      :message="errorMessage || xstate.meta.value.errorMessage"
      :details="errorDetails"
    />

    <form class="PmLoginFormPure-form" @submit.prevent="login">
      <div class="PmLoginFormPure-inputs">
        <PmInputPure
          v-model:value="username"
          label="Benutzername"
          class="PmLoginFormPure-input"
          placeholder="Max Mustermann"
          :disabled="xstate.state.value.matches('loggingIn')"
          :icon="ICONS.USER_SCAN"
          required
        />

        <PmInputPure
          v-model:value="password"
          label="Password"
          class="PmLoginFormPure-input"
          type="password"
          placeholder="•••"
          :disabled="xstate.state.value.matches('loggingIn')"
          :icon="ICONS.KEY"
          required
        />
      </div>

      <div class="PmLoginFormPure-control">
        <PmButtonPure
          label="Anmelden"
          variant="primary"
          class="PmLoginFormPure-submit"
          :loading="isLoading"
          type="submit"
        />

        <PmLinkPure
          class="PmLoginFormPure-forgotPassword"
          label="Passwort vergessen?"
          :href="forgotPasswordMailto"
          variant="light"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, toRef } from 'vue'

import { ICONS } from '@/constants/icons'

import {
  PmLoginFormPureState,
  states,
} from '@/components/PmLogin/PmLoginFormPureState'

import { useXState } from '@/composition/useXState'
import propValidator from '@/functional/propValidator'

import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmLinkPure from '@/components/basics/PmLink/PmLinkPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmLoginFormPure',
  components: {
    PmErrorNotificationPure,
    PmInputPure,
    PmButtonPure,
    PmLinkPure,
  },

  props: {
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    errorMessage: { type: String, default: undefined },
    errorDetails: { type: Array, default: undefined },
    titleVisible: { type: Boolean, default: true },
  },

  emits: ['login'],

  setup(props) {
    const xstate = useXState(PmLoginFormPureState, {
      syncStateWith: toRef(props, 'state'),
    })

    return {
      ICONS,

      xstate,
    }
  },

  data() {
    return {
      username: undefined,
      password: undefined,
    }
  },

  computed: {
    isLoading() {
      if (this.xstate.state.value.matches('loggingIn')) return true

      return false
    },

    forgotPasswordMailto() {
      const to = 'wkornberg@promusik.de'
      const subject = encodeURIComponent('Persoplan Passwort vergessen')

      let body = `Ich habe mein Passwort für den Persoplan vergessen und brauche Hilfe um mich wieder einloggen zu können.

        So können sie mich kontaktieren:

        Name: 
        Email: 
        `

      /**
       * Match every whitespace character except newline, two or more occurances
       * @see https://regex101.com/r/ucDdk4/1
       */
      const regex = /[^\S\r\n]{2,}/gm
      body = body.replace(regex, '')
      body = encodeURIComponent(body)

      const mailto = `mailto:${to}?subject=${subject}&body=${body}`
      return mailto
    },
  },

  methods: {
    login() {
      this.$emit('login', { username: this.username, password: this.password })
    },
  },
})
</script>

<style lang="scss">
.PmLoginFormPure {
  $block: &;

  &-title {
    @include typography.h3;

    margin-bottom: 1em;
  }

  &-error {
    margin-bottom: 28px;
  }

  &-inputs {
    margin-bottom: 20px;
  }

  &-input {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  &-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
