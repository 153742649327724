<template>
  <div class="PmNotFoundPure">Not Found</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PmNotFoundPure',
  components: {},
})
</script>

<style lang="scss">
.PmNotFoundPure {
  $block: &;
}
</style>
