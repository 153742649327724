<template>
  <PmAppointmentsContainerPure
    v-if="propsAppointmentContainer"
    v-slot="{ packingContainer, constrainedStartDate, constrainedEndDate }"
    class="PmPurchaseOrder"
    v-bind="propsAppointmentContainer"
    :container-start-date="containerStartDate"
    :container-end-date="containerEndDate"
    :update-group-visible="false"
    todo-items-for-content-bounds="itemsForContentBounds"
    todo-is-highlighted="isHighlighted"
    todo-at-show-details="showDetails"
    todo-at-jump="jumpToProjectInSidebar"
  >
    <PmPackingElementPure
      v-slot="{ styles }"
      todo-v-for="appointment in combinedAppointmentsNormalized"
      todo-key="appointment.id"
      :packing-container="packingContainer"
    >
      <PmAppointmentPure
        v-if="startDate && endDate"
        todo-is="appointment.component"
        :style="styles"
        :container-start-date="constrainedStartDate"
        :container-end-date="constrainedEndDate"
        todo-v-bind="appointment.props"
        title="- ohne Termin -"
        :container-start-date-unconstrained="startDate"
        :container-end-date-unconstrained="endDate"
        :start-date="startDate"
        :end-date="endDate"
        :is-with-details="false"
      >
        <template v-if="purchaseOrder">
          <template v-for="resource in purchaseOrder.resources">
            <PmResource
              v-if="resource"
              :id="resource.id"
              :key="resource.id"
              :edit-buttons-visible="persoplanState.state.value.matches('edit')"
              todo-container-start-date="containerStartDate"
              :container-start-date="startDate"
            />
          </template>
        </template>
      </PmAppointmentPure>
    </PmPackingElementPure>
  </PmAppointmentsContainerPure>

  <div v-else class="PmPurchaseOrder" style="height: 123px">
    Hello Purchse Order
    <!-- We need a empty div for packing in any case -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmPurchaseOrder'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
/**
 * @note Don't use v-if on PmAppointmentsContainer, it might break packing!
 */
import { computed } from 'vue'
import { useStore } from 'vuex'

import { CALENDAR_ITEM_TYPE } from '@/constants/persoplan'

import { parseServerDateString } from '@/utilities/date'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import { useCachedQuery } from '@/composition/useCachedQuery'

import PmAppointmentsContainerPure, {
  type Props as PropsAppointmentContainerPure,
} from '@/components/persoplan/PmAppointmentsContainer/PmAppointmentsContainerPure.vue'
import PmPackingElementPure from '@/components/persoplan/PmPackingElementPure.vue'
import PmAppointmentPure from '@/components/persoplan/PmAppointment/PmAppointmentPure.vue'
import PmResource from '@/components/persoplan/PmResource/PmResource.vue'

import { PurchaseOrderDocument } from '@/../generated/graphql'

export interface Props {
  containerStartDate: Date
  containerEndDate: Date
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()
const persoplanState = injectStrict(persoplanStateKey)

const startDate = computed(() => {
  return parseServerDateString(purchaseOrder.value?.orderedAt)
})

const endDate = computed(() => {
  return parseServerDateString(purchaseOrder.value?.orderedAt)
})

const purchaseOrderQuery = useCachedQuery(PurchaseOrderDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']

  return {
    ...queryVariables,
    id: props.id,
  }
})

const purchaseOrder = computed(
  () => purchaseOrderQuery.result.value?.purchaseOrder
)

const propsAppointmentContainer = computed(() => {
  if (!purchaseOrder.value) return
  if (!startDate.value) throw new Error('startDate is undefined')
  if (!endDate.value) throw new Error('endDate is undefined')

  const result: PropsAppointmentContainerPure = {
    id: props.id,
    type: CALENDAR_ITEM_TYPE.STOCK_TYPE_SERVICE,
    startDate: startDate.value,
    endDate: endDate.value,
    title: purchaseOrder.value?.caption ?? undefined,
    number: purchaseOrder.value?.number ?? undefined,
  }

  return result
})
</script>
