<template>
  <div class="PmVersionInfoPure">
    <div class="PmVersionInfoPure-list">
      <div class="PmVersionInfoPure-item">
        <PmIconPure :name="ICONS.DISPLAY" class="PmVersionInfoPure-icon" />
        <div class="PmVersionInfoPure-content">
          <div class="PmVersionInfoPure-label">
            Frontend: {{ versionFrontend }}<br />
          </div>

          <div class="PmVersionInfoPure-small">Build: {{ buildFrontend }}</div>
        </div>
      </div>

      <div class="PmVersionInfoPure-item">
        <PmIconPure :name="ICONS.TERMINAL" class="PmVersionInfoPure-icon" />
        <div class="PmVersionInfoPure-content">
          <div class="PmVersionInfoPure-label">API: {{ versionApi }}</div>
        </div>
      </div>
    </div>

    <PmButtonPure
      v-if="updateAvailable"
      label="Update verfügbar"
      variant="primary"
      class="PmVersionInfoPure-update"
    />
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

export default {
  name: 'PmVersionInfoPure',
  components: {
    PmIconPure,
    PmButtonPure,
  },

  props: {
    versionFrontend: { type: String, default: undefined },
    buildFrontend: { type: String, default: undefined },
    versionApi: { type: String, default: undefined },
    updateAvailable: { type: Boolean, default: undefined },
  },

  setup() {
    return {
      ICONS,
    }
  },
}
</script>

<style lang="scss">
.PmVersionInfoPure {
  $block: &;

  &-item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &-content {
    padding-top: 2px;
  }

  &-label {
    font-size: constant.$fontSize-default;
  }

  &-small {
    font-size: constant.$fontSize-small;
    margin-top: 0.2em;
  }

  &-update {
    margin-top: 12px;
  }
}
</style>
