<template>
  <div :class="componentClass.root">
    <dt>{{ label }}</dt>

    <dd>
      <slot />
    </dd>
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props {
  label: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
</script>

<style lang="scss">
.PmMetaListItemPure {
  $block: &;

  display: contents;

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  & dt {
    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    &::after {
      content: ':\00a0';
    }
  }
  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  & dd {
    display: inline;
  }
}
</style>
