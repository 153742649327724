import {
  max,
  min,
  startOfDay,
  endOfDay,
  differenceInCalendarDays,
  isBefore,
  isAfter,
  isSameMinute,
  isSameDay,
  set,
} from 'date-fns'
import { isNil } from 'lodash-es'
import { computed, type CSSProperties } from 'vue'

import {
  isValidDate,
  alternativeEndOfDay,
  formatToServerDateString,
} from '@/utilities/date'

import {
  contentStartDate as getContentStartDate,
  contentEndDate as getContentEndDate,
} from '@/functional/contentBounds'

type DateToCompare = {
  type: string
  date: Date
}

/**
 * @deprecated This should be switched with a interface extends when vue 3.3 is used
 * @todo Remove this and use a interface extgend instead
 */
export const props = {
  useDateConstrain: { type: Boolean, default: true },
  containerStartDate: { type: Date, default: undefined },
  containerEndDate: { type: Date, default: undefined },
  itemsForContentBounds: { type: Array, default: () => [] },
}

export type ItemForContentBounds = { startDate: Date; endDate: Date }

export type Props = {
  startDate?: Date
  endDate?: Date
  useDateConstrain?: boolean
  containerStartDate?: Date
  containerEndDate?: Date
  itemsForContentBounds?: ItemForContentBounds[]
}

export const propDefaults = {
  useDateConstrain: true,
} satisfies Partial<Props>

type Options = {
  useAlternativeEndOfDay?: boolean
}

export default function useDateConstrain(props: Props, options?: Options) {
  const endDateAlternativeEndOfDay = computed(() => {
    return alternativeEndOfDay(props.endDate)
  })

  const endDateAtMidnight = computed(() => {
    if (!props.endDate) return

    return set(props.endDate, {
      hours: 0,
      minutes: 0,
      seconds: 0,
    })
  })

  const startDateAlternativeEndOfDay = computed(() => {
    return alternativeEndOfDay(props.startDate)
  })

  const contentStartDate = computed(() => {
    return getContentStartDate({
      items: props.itemsForContentBounds,
      containerStartDate: props.startDate,
      containerEndDate: props.endDate,
    })
  })

  const contentEndDate = computed(() => {
    return getContentEndDate({
      items: props.itemsForContentBounds,
      containerStartDate: props.startDate,
      containerEndDate: props.endDate,
    })
  })

  const contentStartDateStartOfDay = computed(() => {
    if (!isValidDate(contentStartDate.value)) return
    return startOfDay(contentStartDate.value)
  })

  /**
   * contentEndDate end of day
   * Incorporates alternative end of day
   *
   */
  const contentEndDateNormalized = computed(() => {
    if (!isValidDate(contentEndDate.value)) return

    const dateAlternativeEndOfDay = alternativeEndOfDay(contentEndDate.value)

    if (dateAlternativeEndOfDay) {
      return endOfDay(dateAlternativeEndOfDay)
    }

    return endOfDay(contentEndDate.value)
  })

  const containerStartDateNormalized = computed(() => {
    if (!props.containerStartDate) return undefined
    return startOfDay(props.containerStartDate)
  })

  const containerEndDateNormalized = computed(() => {
    if (!props.containerEndDate) return undefined
    return endOfDay(props.containerEndDate)
  })

  const constrainedStart = computed(() => {
    /**
     * Fill all dates to add to the comparison for the constrainedStart
     */
    const datesToCompare: DateToCompare[] = []

    if (containerStartDateNormalized.value) {
      datesToCompare.push({
        type: 'containerStartDateNormalized',
        date: containerStartDateNormalized.value,
      })
    }

    /**
     * Use alternative end of day if applicable but only if constrainedEndDate is also
     * before alternative end of day on the same day
     */
    const endDateIsOnSameDay = isValidDate(startDateAlternativeEndOfDay.value)
      ? isSameDay(constrainedEnd.value.date, startDateAlternativeEndOfDay.value)
      : false

    if (
      startDateAlternativeEndOfDay.value &&
      constrainedEnd.value.constrainedWithAlternativeEndOfDay === true &&
      endDateIsOnSameDay
    ) {
      datesToCompare.push({
        type: 'startDateAlternativeEndOfDay',
        date: startDateAlternativeEndOfDay.value,
      })
    } else {
      if (props.startDate) {
        datesToCompare.push({ type: 'startDate', date: props.startDate })
      }
    }

    // Maybe add content start date
    if (contentStartDateStartOfDay.value) {
      datesToCompare.push({
        type: 'contentStartDate',
        date: contentStartDateStartOfDay.value,
      })
    }

    const date = max(datesToCompare.map((item) => item.date))
    // debugDatesToCompare(null, datesToCompare)

    const isStartDate = isValidDate(props.startDate)
      ? isSameMinute(date, props.startDate)
      : false

    const isAlternativeEndOfDay = isValidDate(
      startDateAlternativeEndOfDay.value
    )
      ? isSameMinute(date, startDateAlternativeEndOfDay.value)
      : false

    const isContentStartDate = isValidDate(contentStartDateStartOfDay.value)
      ? isSameMinute(date, contentStartDateStartOfDay.value)
      : false

    const isContainerStartDate = isValidDate(containerStartDateNormalized.value)
      ? isSameMinute(date, containerStartDateNormalized.value)
      : false

    const isConstrained =
      !isStartDate &&
      (isContentStartDate || isContainerStartDate || isAlternativeEndOfDay)

    let constrainedInDirection: null | 'left' | 'right' = null

    if (props.startDate && isConstrained) {
      constrainedInDirection = isBefore(date, props.startDate)
        ? 'right'
        : 'left'
    }

    return {
      date,
      isConstrained,
      constrainedInDirection,
      constrainedWithContent: isContentStartDate,
      constrainedWithContainer: isContainerStartDate,
      constrainedWithAlternativeEndOfDay: isAlternativeEndOfDay,
    }
  })

  function useAlternativeEndOfDayForConstrainedEnd() {
    if (!endDateAlternativeEndOfDay.value) return false
    if (options?.useAlternativeEndOfDay === false) return false

    const endDateAlternativeEndOfDayIsBeforeContainerStart = isValidDate(
      props.containerStartDate
    )
      ? isBefore(endDateAlternativeEndOfDay.value, props.containerStartDate)
      : false

    return endDateAlternativeEndOfDayIsBeforeContainerStart === false
  }

  const constrainedEnd = computed(() => {
    const datesToCompare: DateToCompare[] = []

    if (containerEndDateNormalized.value) {
      datesToCompare.push({
        type: 'containerEndDateNormalized',
        date: containerEndDateNormalized.value,
      })
    }

    /**
     * Use alternative start of day if applicable
     */
    if (
      endDateAlternativeEndOfDay.value &&
      useAlternativeEndOfDayForConstrainedEnd()
    ) {
      datesToCompare.push({
        type: 'endDateAlternativeEndOfDay',
        date: endDateAlternativeEndOfDay.value,
      })
    } else {
      if (props.endDate) {
        datesToCompare.push({ type: 'endDate', date: props.endDate })
      }
    }

    // Maybe add content end date
    if (contentEndDateNormalized.value) {
      datesToCompare.push({
        type: 'contentEndDateNormalized',
        date: contentEndDateNormalized.value,
      })
    }

    const date = min(datesToCompare.map((item) => item.date))
    // debugDatesToCompare(null, datesToCompare)

    const isEndDate = isValidDate(props.endDate)
      ? isSameMinute(date, props.endDate)
      : false

    const isAlternativeEndOfDay = isValidDate(endDateAlternativeEndOfDay.value)
      ? isSameMinute(date, endDateAlternativeEndOfDay.value)
      : false

    const isContentEndDate = isValidDate(contentEndDateNormalized.value)
      ? isSameMinute(date, contentEndDateNormalized.value)
      : false

    const isContainerEndDate = isValidDate(containerEndDateNormalized.value)
      ? isSameMinute(date, containerEndDateNormalized.value)
      : false

    const isConstrained =
      !isEndDate &&
      (isContentEndDate || isContainerEndDate || isAlternativeEndOfDay)

    let constrainedInDirection: null | 'left' | 'right' = null

    if (props.endDate && isConstrained) {
      constrainedInDirection = isAfter(date, props.endDate) ? 'left' : 'right'
    }

    return {
      date,
      isConstrained,
      constrainedInDirection,
      constrainedWithContent: isContentEndDate,
      constrainedWithContainer: isContainerEndDate,
      constrainedWithAlternativeEndOfDay: isAlternativeEndOfDay,
    }
  })

  const startsOnRelativeDay = computed(() => {
    if (!containerStartDateNormalized.value) return
    if (!props.startDate) return

    return differenceInCalendarDays(
      props.startDate,
      containerStartDateNormalized.value
    )
  })

  const startsOnRelativeDayConstrained = computed(() => {
    if (!containerStartDateNormalized.value) return

    return differenceInCalendarDays(
      constrainedStart.value.date,
      containerStartDateNormalized.value
    )
  })

  const numberOfDays = computed(() => {
    if (!props.startDate) return
    if (!props.endDate) return

    return differenceInCalendarDays(props.endDate, props.startDate) + 1
  })

  const numberOfDaysConstrained = computed(() => {
    return (
      differenceInCalendarDays(
        constrainedEnd.value.date,
        constrainedStart.value.date
      ) + 1
    )
  })

  const containerNumberOfDays = computed(() => {
    if (!containerEndDateNormalized.value) return
    if (!containerStartDateNormalized.value) return

    return (
      differenceInCalendarDays(
        containerEndDateNormalized.value,
        containerStartDateNormalized.value
      ) + 1
    )
  })

  const styles = computed<CSSProperties | undefined>(() => {
    if (!isValidDate(containerStartDateNormalized.value)) return
    if (!isValidDate(containerEndDateNormalized.value)) return
    if (isNil(startsOnRelativeDay.value)) return
    if (isNil(containerNumberOfDays.value)) return
    if (isNil(startsOnRelativeDayConstrained.value)) return
    if (isNil(numberOfDays.value)) return

    const left = startsOnRelativeDay.value / containerNumberOfDays.value
    const width = numberOfDays.value / containerNumberOfDays.value

    const leftConstrained =
      startsOnRelativeDayConstrained.value / containerNumberOfDays.value

    const widthConstrained =
      numberOfDaysConstrained.value / containerNumberOfDays.value

    const stylesBase: CSSProperties = {
      position: 'absolute',
      left: `${left * 100}%`,
      width: `${width * 100}%`,
    }

    const stylesConstrained: CSSProperties = {
      left: `${leftConstrained * 100}%`,
      width: `${widthConstrained * 100}%`,
    }

    if (props.useDateConstrain) {
      return {
        ...stylesBase,
        ...stylesConstrained,
      }
    }

    return stylesBase
  })

  return {
    isConstrainedStart: computed(() => {
      if (!props.useDateConstrain) return false
      return constrainedStart.value.isConstrained
    }),
    isConstrainedEnd: computed(() => {
      if (!props.useDateConstrain) return false
      return constrainedEnd.value.isConstrained
    }),
    constrainedStartInDirection: computed(() => {
      if (!props.useDateConstrain) return null
      return constrainedStart.value.constrainedInDirection
    }),
    constrainedEndInDirection: computed(() => {
      if (!props.useDateConstrain) return null
      return constrainedEnd.value.constrainedInDirection
    }),
    containerNumberOfDays,
    constrainedStartDate: computed(() => constrainedStart.value.date),
    constrainedEndDate: computed(() => constrainedEnd.value.date),
    numberOfDaysConstrained,
    startsOnRelativeDay, // TODO: This should not be exported
    constrainedEnd,
    styles,
  }
}

// eslint-disable-next-line no-unused-vars
function debugDatesToCompare(
  label: string | null = null,
  dates: DateToCompare[]
) {
  const datesToCompareDebug: Record<string, string> = {}
  if (label) {
    datesToCompareDebug.label = label
  }

  dates.forEach((item) => {
    datesToCompareDebug[item.type] = formatToServerDateString(item.date)
  })

  console.table(datesToCompareDebug)
}
