<template>
  <portal to="modal">
    <PmModalPure
      title="Ressourcen-Zuordnungen löschen"
      :can-be-closed="!xstate.state.value.matches('delete.deleting')"
      @close="emit('close')"
    >
      <PmMultipleResourceAllocationsDeletePure
        :number-of-items="resourceAllocationIds?.length ?? 0"
        :index-currently-deleting="indexCurrentlyDeleting"
        :state="xstate.path.value"
        :error-message="xstate.state.value.context.error"
        :error-details="xstate.state.value.context.errorDetails"
        @cancel="emit('close')"
        @confirm="xstate.service.value.send('CONFIRM')"
      >
        <template #table>
          <PmMultipleResourceAllocationsDeleteItemPure
            v-for="resourceAllocation in resourceAllocationsNormalizedCached"
            :key="resourceAllocation.id"
            v-bind="resourceAllocation"
          />
        </template>
      </PmMultipleResourceAllocationsDeletePure>
    </PmModalPure>
  </portal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsDelete'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useCached } from '@vueuse/core'

import { type ResourceType, RESOURCE_TYPE } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import { useXState } from '@/composition/useXState'
import { FriendlyError, throwFriendlyError } from '@/functional/friendlyErrors'
import EventHub from '@/eventHub'
import { useSelectionResourceAllocations } from '@/pinia/selectionResourceAllocations'

import { PmMultipleResourceAllocationsDeleteState } from '@/components/persoplan/PmMultipleResourceAllocationsDelete/PmMultipleResourceAllocationsDeleteState'

import PmMultipleResourceAllocationsDeletePure from '@/components/persoplan/PmMultipleResourceAllocationsDelete/PmMultipleResourceAllocationsDeletePure.vue'

import PmMultipleResourceAllocationsDeleteItemPure, {
  type Props as PropsMultipleResourceAllocationsDeleteItemPure,
} from '@/components/persoplan/PmMultipleResourceAllocationsDelete/PmMultipleResourceAllocationsDeleteItemPure.vue'

import PmModalPure from '@/components/basics/PmModalPure.vue'

import {
  ResourceAllocationsDocument,
  DeleteResourceAllocationDocument,
} from '@/../generated/graphql'

export interface Props {
  resourceAllocationIds?: number[]
  type?: ResourceType
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

if (!props.resourceAllocationIds) {
  throw new Error('No resourceAllocations selected')
}

const xstate = useXState(PmMultipleResourceAllocationsDeleteState, {
  services: {
    deleteResourceAllocations: deleteResourceAllocations,
  },
})

const selectionResourceAllocations = useSelectionResourceAllocations()
const resourceAllocationIdsInternal = ref<number[]>([
  ...props.resourceAllocationIds,
])

const resourceAllocationsQuery = useQuery(
  ResourceAllocationsDocument,
  () => ({
    ids: resourceAllocationIdsInternal.value,
  }),
  () => ({
    enabled:
      resourceAllocationIdsInternal.value &&
      resourceAllocationIdsInternal.value.length > 0,
  })
)

const resourceAllocations = computed(
  () => resourceAllocationsQuery.result.value?.resourceAllocations
)

// watch(resourceAllocations, normalizeResourceAllocations, {
//   deep: true,
//   immediate: true,
// })

type ResourceAllocationNormalized =
  PropsMultipleResourceAllocationsDeleteItemPure & {
    id: number
  }

// const resourceAllocationsNormalized = ref<ResourceAllocationNormalized[]>()

const deleteStates = ref(
  new Map<number, PropsMultipleResourceAllocationsDeleteItemPure['state']>()
)

const resourceAllocationsNormalized = computed(() => {
  if (!resourceAllocations.value) return

  const data: ResourceAllocationNormalized[] = []

  resourceAllocations.value.forEach((resourceAllocation) => {
    if (!resourceAllocation) return

    let name: string | undefined = undefined

    if (
      props.type === RESOURCE_TYPE.ADDRESS ||
      props.type === RESOURCE_TYPE.FREELANCER
    ) {
      name = resourceAllocation.address?.displayName ?? '—'
    }

    if (props.type === RESOURCE_TYPE.VEHICLE) {
      name = resourceAllocation.vehicle?.caption ?? '—'
    }

    if (!name) return

    data.push({
      id: resourceAllocation.id,
      project:
        resourceAllocation.resourceFunctionAllocation?.job?.project?.caption,
      job: resourceAllocation.resourceFunctionAllocation?.job?.caption,
      appointment:
        resourceAllocation.resourceFunctionAllocation?.jobAppointment?.caption,
      resourceFunction:
        resourceAllocation.resourceFunctionAllocation?.resourceFunction
          ?.caption,
      name: name,
      state: deleteStates.value.get(resourceAllocation.id),
    })
  })

  return data
})

const resourceAllocationsNormalizedCached = useCached(
  resourceAllocationsNormalized,
  (current, previous) => {
    // Returning false invalidates the cache
    if (!current || current.length === 0) return true
    return false
  },
  {
    deep: true,
    immediate: true,
  }
)

const indexCurrentlyDeleting = ref<number>(0)
const hasErrors = ref(false)

async function deleteResourceAllocations() {
  if (!resourceAllocations.value) {
    throw new Error('No resource allocations to delete')
  }

  const items: { resourceAllocationId: number }[] = []

  resourceAllocations.value.forEach((resourceAllocation) => {
    if (!resourceAllocation) throw new Error('resourceAllocation is undefined')

    const resourceAllocationId = resourceAllocation.id
    if (!resourceAllocationId)
      throw new Error('resourceAllocationId is undefined')

    items.push({
      resourceAllocationId,
    })
  })

  if (!items.length) throw new Error('No resource allocations to delete')

  indexCurrentlyDeleting.value = 0

  for (const item of items) {
    indexCurrentlyDeleting.value = indexCurrentlyDeleting.value + 1

    try {
      await deleteResourceAllocation({
        resourceAllocationId: item.resourceAllocationId,
      })
    } catch (error) {
      hasErrors.value = true
    }
  }

  // Update Calendar
  EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)

  // Update selection
  deleteStates.value.forEach((status, resourceAllocationId) => {
    if (status === 'success') {
      selectionResourceAllocations.remove(resourceAllocationId)
    }
  })

  // Throw general error, when there was an error on one of the items
  if (hasErrors.value === true) {
    throw new FriendlyError({
      message:
        'Es gab einen oder mehrere Fehler beim Löschen der Ressourcen-Zuordnungen',
      details: [
        'Die Ressourcen-Zuordnungen mit Problemen wurden in der Liste mit einem Symbol markiert. Bitte prüfe im Kalender ob deine Änderungen durchgeführt wurden und versuche es gegebenenfalls noch einmal',
      ],
    })
  }
}

const deleteResourceAllocationMutation = useMutation(
  DeleteResourceAllocationDocument
)

async function deleteResourceAllocation({
  resourceAllocationId,
}: {
  resourceAllocationId: number
}) {
  deleteStates.value.set(resourceAllocationId, 'deleting')

  try {
    await deleteResourceAllocationMutation.mutate({
      resourceAllocationId,
    })

    deleteStates.value.set(resourceAllocationId, 'success')
  } catch (error) {
    deleteStates.value.set(resourceAllocationId, 'error')
    throwFriendlyError(error)
  }
}
</script>
