<template>
  <div ref="elRoot" :class="{ [componentClass.root]: true, ...classes }">
    <PmButtonPure
      v-if="isCloseButtonVisible"
      :disabled="!canBeClosed"
      icon="close"
      alternative="ghost"
      :class="componentClass.element('close')"
      @click="emit('close')"
    />

    <div class="PmDialogPanelPure-content">
      <div class="PmDialogPanelPure-title">
        {{ title }}
      </div>

      <div v-if="loading" :class="componentClass.element('loading')">
        <PmLoadingPure size="large" />
      </div>

      <template v-if="!loading">
        <div v-if="text" :class="componentClass.element('text')">
          {{ text }}
        </div>

        <div
          v-if="hasSlotContent($slots.default)"
          :class="componentClass.element('slot')"
        >
          <slot />
        </div>

        <PmErrorNotificationPure
          v-if="errorMessage"
          :message="errorMessage"
          :details="errorDetails"
          :class="componentClass.element('error')"
        />

        <PmButtonListPure
          v-if="(buttons && buttons.length) || hasPortalContent"
          :class="componentClass.element('control')"
          layout="distribute"
        >
          <PmButtonPure
            v-for="button in buttons"
            :key="button.label"
            :label="button.label"
            :variant="button.variant"
            :icon="button.icon"
            :alternative="button.alternative"
            :loading="button.loading"
            :disabled="isButtonDisabled(button)"
            :shadow="false"
            :type="button.type"
            :form="button.form"
            @click="
              () => {
                if (button.type === 'submit') return
                onButtonClick(button.action)
              }
            "
          />

          <portal-target name="dialogControl" multiple />
        </PmButtonListPure>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, onBeforeUnmount } from 'vue'
import { createFocusTrap, type FocusTrap } from 'focus-trap'
import { onKeyStroke } from '@vueuse/core'
import { Wormhole } from 'portal-vue'

import { hasSlotContent } from '@/utilities/misc'
import { useComponentClass } from '@/composition/useComponentClass'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'

export interface Button {
  label: PropsButtonPure['label']
  icon?: PropsButtonPure['icon']
  variant?: PropsButtonPure['variant']
  alternative?: PropsButtonPure['alternative']
  loading?: PropsButtonPure['loading']
  disabled?: PropsButtonPure['disabled']
  type?: PropsButtonPure['type']
  form?: PropsButtonPure['form']
  action: string
}

export interface Props {
  title?: string
  text?: string
  variant?: 'default' | 'danger'
  buttons?: Button[]
  disabled?: boolean
  errorMessage?: string
  errorDetails?: string[]
  canBeClosed?: boolean
  isCloseButtonVisible?: boolean
  loading?: boolean
}

const COMPONENT_NAME = 'PmDialogPanelPure'

const props = withDefaults(defineProps<Props>(), {
  canBeClosed: true,
})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const componentClass = useComponentClass()
const elRoot = ref()
let focusTrap: FocusTrap

onKeyStroke('Escape', () => {
  if (!props.canBeClosed) return
  emit('close')
})

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--variantDanger`]: props.variant === 'danger',
  }
})

const isButtonDisabled = (button: Button) => {
  if (button.loading) return undefined
  if (props.disabled) return true
  if (button.disabled) return true

  return false
}

const hasPortalContent = computed(() => {
  return Wormhole.transports.get('dialogControl') ? true : false
})

onMounted(() => {
  // focusTrap = createFocusTrap(elRoot.value)
  // focusTrap.activate()
})

onBeforeUnmount(() => {
  // focusTrap.deactivate()
})

function onButtonClick(action: string) {
  // @ts-expect-error No way to define general emits?
  emit(action)
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmDialogPanelPure {
  $block: &;

  @include cssVar.define($block, 'colorBackground', color.$white);
  @include cssVar.define($block, 'colorTextTitle', inherit);

  &--variantDanger {
    @include cssVar.define($block, 'colorTextTitle', color.$danger-600);
  }

  position: relative;

  &-close {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  &-content {
    @include shadow.default('medium');

    padding: 28px;
    border-radius: constant.$borderRadius-large;
    background-color: cssVar.use($block, 'colorBackground');
  }

  &-loading {
    display: flex;
    justify-content: center;
  }

  &-title {
    @include typography.h3($includeMargin: true);

    color: cssVar.use($block, 'colorTextTitle');
  }

  &-text {
    // Empty
  }

  &-slot {
    margin-top: 20px;
  }

  &-error {
    margin-top: 20px;
  }

  &-control {
    margin-top: 36px;
  }
}
</style>
