<template>
  <div class="PmResourceShortPure" :title="title" :class="classes">
    <div class="PmResourceShortPure-inner">
      <div v-if="icon" class="PmResourceShortPure-icon">
        <PmIconPure :key="icon" :name="icon" />
      </div>

      <div class="PmResourceShortPure-text" @click="$emit('click')">
        {{ label }}
      </div>

      <div
        v-if="quantityVisible"
        class="PmResourceShortPure-quantity"
        :title="`${quantityNeeded} Benötigt / ${quantityAchieved} Bestätigt (${quantityUnclear} Angedacht oder Angefragt)`"
      >
        {{ labelQuantity }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useComponentClass } from '@/composition/useComponentClass'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import type { Icon } from '@/constants/icons'

export interface Props {
  label: string
  title?: string
  icon?: Icon
  interactive?: boolean
  quantityNeeded?: number
  quantityAchieved?: number
  quantityUnclear?: number
  useMinWidth?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  interactive: true,
  useMinWidth: true,
})

const emit = defineEmits<{
  (event: 'click'): void
}>()

const componentClass = useComponentClass()

const classes = computed(() => {
  return {
    'is-notInteractive': props.interactive === false,
    'is-danger': isDanger.value,
    [`${componentClass.root}--minWidth`]: props.useMinWidth === true,
  }
})

const isDanger = computed(() => {
  if (!props.quantityNeeded) return false
  if (props.quantityAchieved === undefined) return false
  if (props.quantityAchieved < props.quantityNeeded) return true

  return false
})

const quantityVisible = computed(() => {
  if (!props.quantityNeeded) return false
  if (props.quantityNeeded <= 1) return false

  return true
})

const labelQuantity = computed(() => {
  return `${props.quantityNeeded}/${labelQuantityAchieved.value}(${labelQuantityUnclear.value})`
})

const labelQuantityAchieved = computed(() => {
  if (props.quantityAchieved === undefined) return '?'
  return props.quantityAchieved
})

const labelQuantityUnclear = computed(() => {
  if (!props.quantityUnclear === undefined) return '?'
  return props.quantityUnclear
})
</script>

<style lang="scss">
.PmResourceShortPure {
  $block: &;

  font-weight: 700;

  &--minWidth {
    min-width: 23px;
  }

  &-inner {
    border-radius: constant.$borderRadius-default;
    padding: 1px 3px;
    background-color: cssVar.use(
      $block,
      'colorBackground',
      color.$gray-200--alpha
    );
    text-align: center;
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: background-color constant.$duration-fast;

    #{$block}.is-danger & {
      background-color: color.$danger-600;
      color: color.$white;
    }
  }

  &-icon {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    flex: none;
  }

  &-text {
    min-width: 0;
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      text-decoration: underline;
    }

    #{$block}.is-notInteractive & {
      pointer-events: none;
    }
  }

  &-quantity {
    padding: 1px 3px;
    background-color: color.$gray-300--alpha;
    border-radius: 0 constant.$borderRadius-default
      constant.$borderRadius-default 0;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-right: -3px;
    margin-left: 3px;
    flex-shrink: 0;

    #{$block}.is-danger & {
      background-color: color.$danger-800;
    }
  }
}
</style>
