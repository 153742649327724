export const FEATURE_FLAG = {
  OPERATIONS: false,
  /**
   * Automatically refresh calendar data nad notifications?
   * Currently this should only be used for testing purposes
   */
  AUTO_REFRESH_DATA: true, // TODO: Remove feature flag
}

console.table(FEATURE_FLAG)
