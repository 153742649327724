<template>
  <PmAppointmentsContainerPure
    :id="id"
    v-slot="{ packingContainer, constrainedStartDate, constrainedEndDate }"
    v-bind="propsAppointmentContainer"
    :start-date="startDate"
    :end-date="endDate"
    :alerts-visible="store.state.view.currentView.showMissingResourcesWarning"
    :container-start-date="containerStartDate"
    :container-end-date="containerEndDate"
    :items-for-content-bounds="itemsForContentBounds"
    :is-highlighted="isHighlighted"
    :collects-all-jobs-of-project="true"
    :type="CALENDAR_ITEM_TYPE.PROJECT"
    :update-group-visible="can('edit', 'persoplan')"
    :alternative-color-visible="can('edit', 'persoplan')"
    :can-edit-resource-request="can('edit', 'resourceRequest')"
    @show-details="showDetails"
    @jump="jumpToProjectInSidebar"
    @update-color="updateAlternativeColor"
    @use-random-color="deleteAlternativeColor"
    @open-note="showDetails"
    @request-considered="isRequestAllOfProjectVisible = true"
  >
    <PmPackingElementPure
      v-for="appointment in combinedAppointmentsNormalized"
      :key="appointment.id"
      v-slot="{ styles }"
      :packing-container="packingContainer"
    >
      <component
        :is="appointment.component"
        :style="styles"
        :container-start-date="constrainedStartDate"
        :container-end-date="constrainedEndDate"
        v-bind="appointment.props"
      >
        <template
          v-if="
            appointment.component == PmAppointmentPure &&
            'resources' in appointment
          "
        >
          <PmResource
            v-for="resource in appointment.resources"
            :id="resource.id"
            :key="resource.id"
            :edit-buttons-visible="persoplanState.state.value.matches('edit')"
            :container-start-date="containerStartDate"
          />
        </template>
      </component>
    </PmPackingElementPure>

    <PmRequestAllOfProject
      v-if="isRequestAllOfProjectVisible === true"
      :project-id="id"
      @close="isRequestAllOfProjectVisible = false"
    />
  </PmAppointmentsContainerPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmProject'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
/**
 * @note Don't use v-if on PmAppointmentsContainer, it might break packing!
 */
import { computed, ref } from 'vue'
import { orderBy, cloneDeep } from 'lodash-es'
import { useStore } from 'vuex'
import { useMutation } from '@vue/apollo-composable'
import cuid from '@paralleldrive/cuid2'

import { EVENT } from '@/constants/events'
import { DATA_MODAL_TYPE, CALENDAR_ITEM_TYPE } from '@/constants/persoplan'

import {
  parseServerDateString,
  transformToAlternativeEndOfDay,
  formatToServerDateString,
  startDateForServer,
  endDateForServer,
} from '@/utilities/date'

import { useAppAbility } from '@/composition/useAppAbility'
import { statusLookupProject } from '@/utilities/persoplan'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import { handleMutationError } from '@/functional/handleMutationError'
import { createArtificialAppointments } from '@/functional/artificialAppointments'
import { useCachedQuery } from '@/composition/useCachedQuery'
import EventHub from '@/eventHub'

import PmAppointmentsContainerPure, {
  type Props as PropsAppointmentsContainerPure,
} from '@/components/persoplan/PmAppointmentsContainer/PmAppointmentsContainerPure.vue'
import PmPackingElementPure from '@/components/persoplan/PmPackingElementPure.vue'
import PmAppointment from '@/components/persoplan/PmAppointment/PmAppointment.vue'
import PmAppointmentPure from '@/components/persoplan/PmAppointment/PmAppointmentPure.vue'
import PmResource from '@/components/persoplan/PmResource/PmResource.vue'
import PmRequestAllOfProject from '@/components/persoplan/PmRequestAllOfProject/PmRequestAllOfProject.vue'

import {
  ProjectDocument,
  UpdateAlternativeColorDocument,
} from '@/../generated/graphql'

export interface Props {
  containerStartDate: Date
  containerEndDate: Date
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const persoplanState = injectStrict(persoplanStateKey)
const store = useStore()
const { can } = useAppAbility()
const isRequestAllOfProjectVisible = ref(false)

const projectQuery = useCachedQuery(ProjectDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']

  const startDateAlternativeStartOfDay = formatToServerDateString(
    transformToAlternativeEndOfDay(props.containerStartDate)
  )

  return {
    ...queryVariables,
    id: props.id,
    startDate: startDateForServer(props.containerStartDate),
    endDate: endDateForServer(props.containerEndDate),
    startDateAlternativeStartOfDay,
    resourceTypeIds: queryVariables.resourceTypeIds,
    excludeResourceTypeIds: queryVariables.excludeResourceTypeIds,
    resourceFunctionIds: queryVariables.resourceFunctionIds,
    excludeResourceFunctionIds: queryVariables.excludeResourceFunctionIds,
    showOnlyWithResources: queryVariables.showOnlyWithResources,
  }
})

const project = computed(() => projectQuery.result.value?.project)

const startDate = computed(() => {
  return parseServerDateString(project.value?.startDate)
})

const endDate = computed(() => {
  return parseServerDateString(project.value?.endDate)
})

const propsAppointmentContainer = computed(() => {
  if (!project.value) return

  const title = project.value?.caption
  const projectStateId = project.value?.projectState?.id

  const status = projectStateId
    ? statusLookupProject({
        projectStateId: projectStateId,
      })
    : undefined

  const result: Partial<PropsAppointmentsContainerPure> = {
    title,
    projectStatus: status,
    number: project.value?.number,
    numberOfAlerts: project.value?.numberOfMissingResources ?? undefined,
    projectId: props.id ?? undefined,
    groupNumber: project.value?.group?.number ?? undefined,
    alternativeColor: settings.value?.alternativeColor,
    note: project.value?.comment ?? undefined,
  }

  return result
})

const normalizedAppointments = computed(() => {
  if (!appointments.value) return []

  type Appointment = {
    id: number
    startDate: Date
    endDate: Date
  }

  const normalizedAppointments = appointments.value.reduce(
    (result: Appointment[], appointment) => {
      if (!appointment) return result

      const startDate = parseServerDateString(appointment.startDate)
      const endDate = parseServerDateString(appointment.endDate)

      if (!startDate) throw new Error('startDate is undefined')
      if (!endDate) throw new Error('endDate is undefined')

      result.push({
        id: appointment.id,
        startDate, // Used for sorting
        endDate, // Used for sorting
      })

      return result
    },
    []
  )

  const sortedAppointments = orderBy(
    normalizedAppointments,
    ['startDate'],
    ['asc']
  )

  return sortedAppointments
})

const combinedAppointmentsNormalized = computed(() => {
  // Add component type and props
  const appointmentsNormalized = cloneDeep(normalizedAppointments.value).map(
    (appointment) => {
      return {
        ...appointment,
        component: PmAppointment,

        props: {
          id: appointment.id,
          containerStartDateUnconstrained: startDate.value,
          containerEndDateUnconstrained: endDate.value,
          startDate: appointment.startDate,
          endDate: appointment.endDate,
          withJobInformation: true,
        },
      }
    }
  )

  const artificialAppointmentsNormalized =
    (artificialAppointments.value &&
      cloneDeep(artificialAppointments.value).map((artificialAppointment) => {
        const id = cuid.createId()

        return {
          ...artificialAppointment,
          component: PmAppointmentPure,
          id: id,

          props: {
            id: id,
            title: '- ohne Termin -',
            containerStartDateUnconstrained: startDate.value,
            containerEndDateUnconstrained: endDate.value,
            startDate: artificialAppointment.startDate,
            endDate: artificialAppointment.endDate,
            isWithDetails: false,
          },
        }
      })) ??
    []

  const sortedAppointments = orderBy(
    [...appointmentsNormalized, ...artificialAppointmentsNormalized],
    ['startDate'],
    ['asc']
  )

  return sortedAppointments
})

const appointments = computed(() => {
  if (!project.value?.jobs) return

  // Copied from generated/graphql.ts
  type JobAppointment = {
    __typename?: 'JobAppointment' | undefined
    id: number
    startDate?: any
    endDate?: any
  }

  const appointments = project.value.jobs.reduce(
    (result: JobAppointment[], job) => {
      if (!job) return result
      if (!job?.appointments) return result

      const appointmentsOfJob = job.appointments.reduce(
        (result: JobAppointment[], appointment) => {
          if (!appointment) return result

          result.push(appointment)
          return result
        },
        []
      )

      return [...result, ...appointmentsOfJob]
    },
    []
  )

  return appointments
})

const itemsForContentBounds = computed(() => {
  return [
    ...normalizedAppointments.value,
    ...(artificialAppointments.value ?? []),
  ].map((item) => {
    return {
      startDate: item.startDate,
      endDate: item.endDate,
    }
  })
})

const settings = computed(() => {
  const json = project.value?.globalSetting?.value
  if (!json) return null

  try {
    return JSON.parse(json)
  } catch {
    console.warn('Could not parse json for global Settings')
  }

  return null
})

const isHighlighted = computed({
  get() {
    return store.getters['persoplan/isItemHighlighted']({
      id: props.id,
      type: CALENDAR_ITEM_TYPE.PROJECT,
    })
  },
  set(value) {
    if (value === true) {
      store.commit('persoplan/addToHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.PROJECT,
      })
    } else {
      store.commit('persoplan/removeFromHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.PROJECT,
      })
    }
  },
})

/**
 * Artifical appointments for resources without an appointment
 */
const artificialAppointments = computed(() => {
  if (!project.value?.jobs?.length) return []

  // Copied from generated/graphql.ts
  type ResourceWithoutAppointment = {
    id: number
    startDate?: any
    endDate?: any
    resourceFunction?:
      | {
          sortOrder?: number | null | undefined
        }
      | null
      | undefined
  }

  const resourcesOfAllJobs = project.value.jobs.reduce(
    (result: ResourceWithoutAppointment[], job) => {
      if (!job) return result
      if (!job.resourcesWithoutAppointment) return result

      job.resourcesWithoutAppointment.forEach((resource) => {
        if (!resource) return

        result.push(resource)
      })

      return result
    },
    []
  )

  return createArtificialAppointments({ resources: resourcesOfAllJobs })
})

function showDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.PROJECT,
    id: props.id,
  })
}

function jumpToProjectInSidebar() {
  EventHub.$emit(EVENT.SIDEBAR_PROJECT_SCROLL_INTO_VIEW, {
    id: props.id,
    type: CALENDAR_ITEM_TYPE.PROJECT,
  })

  store.commit('persoplan/clearHighlightedItems')
  isHighlighted.value = true
}

const updateAlternativeColorMutation = useMutation(
  UpdateAlternativeColorDocument,

  () => ({
    update: (cache, result) => {
      const newValue = result.data?.saveGlobalSetting?.value
      if (!newValue) return

      const cacheId = cache.identify({
        __typename: 'Project',
        id: props.id,
      })

      cache.modify({
        id: cacheId,
        fields: {
          globalSetting(cachedValue) {
            return {
              ...cachedValue,
              value: newValue,
            }
          },
        },
      })
    },
  })
)

async function updateAlternativeColor(color: string | null) {
  const key = `Project:${props.id}`
  const value = JSON.stringify({
    alternativeColor: color,
  })

  try {
    await updateAlternativeColorMutation.mutate({
      key,
      value,
    })
  } catch (error) {
    handleMutationError(error)
  }
}

function deleteAlternativeColor() {
  updateAlternativeColor(null)
}
</script>

<style lang="scss">
.PmProject {
  $block: &;
}
</style>
