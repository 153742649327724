import * as Sentry from '@sentry/vue'
import { differenceInSeconds } from 'date-fns'
import { createMachine, actions } from 'xstate'
const { pure, send } = actions

import { POLLING_INTERVAL } from '@/constants/persoplan'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import type { Typegen0 } from './PmPollingIndicatorState.typegen'

export type TState = Typegen0['matchesStates']

export const PmPollingIndicatorState = createMachine(
  {
    tsTypes: {} as import('./PmPollingIndicatorState.typegen').Typegen0,
    schema: {
      events: {} as
        | { type: 'USER_WENT_IDLE' }
        | { type: 'GO_OFFLINE' }
        | { type: 'INITIAL_LOADING_FINISHED' }
        | { type: 'ERROR' }
        | { type: 'FINISHED' }
        | { type: 'UPDATE' }
        | { type: 'UPDATE_FROM_EXTERNAL' }
        | { type: 'UPDATE_FROM_EXTERNAL_FINISHED' }
        | { type: 'GO_ONLINE' }
        | { type: 'USER_WENT_ACTIVE' }
        | { type: 'WAIT' },

      services: {} as {
        startPollingInterval: any
        update: any
      },

      context: {} as {
        timestampWhenUserWentIdleOrOffline: number | undefined
      },
    },

    id: 'PmPollingIndicatorState',
    initial: 'initialLoading',

    context: {
      timestampWhenUserWentIdleOrOffline: undefined,
    },

    on: {
      USER_WENT_IDLE: 'inactive',
      GO_OFFLINE: 'offline',
      UPDATE_FROM_EXTERNAL: 'updatingFromExternal',
    },

    states: {
      initialLoading: {
        on: {
          INITIAL_LOADING_FINISHED: 'waiting',
          ERROR: 'failed',
        },
      },

      inactive: {
        entry: [
          (context) => {
            context.timestampWhenUserWentIdleOrOffline = new Date().getTime()
          },
          'resetPolling',
        ],

        on: {
          USER_WENT_ACTIVE: {
            actions: 'maybeUpdate',
          },
          UPDATE: 'updating',
          WAIT: 'waiting',
        },
      },

      waiting: {
        on: {
          UPDATE: 'updating',
        },

        invoke: {
          src: 'startPollingInterval',
          onDone: [
            { target: 'updating', cond: 'shouldBePolling' },
            { target: 'waiting' },
          ],
        },
      },

      updating: {
        entry: ['resetPolling'],

        on: {
          FINISHED: 'waiting',
          ERROR: 'failed',
        },

        invoke: {
          src: 'update',
          onError: {
            target: 'failed',
            actions: (context, error) => {
              console.error(error.data)
              Sentry.captureException(error.data)
            },
          },
          onDone: 'waiting',
        },
      },

      updatingFromExternal: {
        entry: 'resetPolling',
        on: {
          UPDATE_FROM_EXTERNAL_FINISHED: 'waiting',
        },
      },

      failed: {
        on: {
          UPDATE: 'updating',
        },
      },

      offline: {
        entry: [
          (context) => {
            context.timestampWhenUserWentIdleOrOffline = new Date().getTime()
          },
          'resetPolling',
        ],

        on: {
          GO_ONLINE: {
            actions: ['maybeUpdate'],
          },
          UPDATE: 'updating',
          WAIT: 'waiting',
        },
      },
    },
  },
  {
    actions: {
      maybeUpdate: pure((context) => {
        if (!context.timestampWhenUserWentIdleOrOffline) {
          return send('WAIT')
        }

        const secondsUserHasBeenIdle = differenceInSeconds(
          new Date(),
          context.timestampWhenUserWentIdleOrOffline
        )

        return secondsUserHasBeenIdle > POLLING_INTERVAL
          ? send('UPDATE')
          : send('WAIT')
      }),
    },

    guards: {
      shouldBePolling: () => {
        return FEATURE_FLAG.AUTO_REFRESH_DATA !== false
      },
    },
  }
)
