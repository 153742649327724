import { inject, type Ref, type InjectionKey } from 'vue'

import type { UseMachineReturnInitialized } from '@/composition/useXState'

import type { AppState } from '@/state/appState'
export type AppState = UseMachineReturnInitialized<typeof AppState>

import type { PersoplanState } from '@/state/persoplanState'
export type PersoplanState = UseMachineReturnInitialized<typeof PersoplanState>

import type { WhatInputState } from '@/App.vue'
import type { QuerySettings } from '@/views/PmPersoplan/PmPersoplan.vue'

import type { Provide as ProvideButtonListPure } from '@/components/basics/PmButtonListPure.vue'
import type { Provide as ProvideTablePure } from '@/components/basics/PmTable/PmTablePure.vue'
import type { PackingContainerSettings } from '@/components/persoplan/PmCalendar/PmCalendar.vue'
import type { Provide as ProvideFomPure } from '@/components/basics/PmForm/PmFormPure.vue'
import type { Provide as ProvideKeyValueListPure } from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import { type Provided as ProvidedForPollingIndicator } from '@/components/basics/PmPollingIndicator/PmPollingIndicator.vue'

export const appStateKey = Symbol('appState') as InjectionKey<AppState>

export const persoplanStateKey = Symbol(
  'persoplanState'
) as InjectionKey<PersoplanState>

export const whatInputStateKey = Symbol(
  'whatInputState'
) as InjectionKey<WhatInputState>

export const querySettingsKey = Symbol(
  'querySettings'
) as InjectionKey<QuerySettings>

export const buttonListPureKey = Symbol(
  'buttonListPure'
) as InjectionKey<ProvideButtonListPure>

export const tableKey = Symbol('table') as InjectionKey<ProvideTablePure>

export const packingContainerSettingsKey = Symbol(
  'packingContainerSettings'
) as InjectionKey<Ref<PackingContainerSettings>>

export const selectionAppointmentHelperKey = Symbol(
  'selectionAppointmentHelper'
) as InjectionKey<{
  selectionTrigger: Ref<number>
}>

export const selectionProjectOrJobHelperKey = Symbol(
  'selectionProjectOrJobHelper'
) as InjectionKey<{
  selectionTrigger: Ref<number>
}>

export const formKey = Symbol('form') as InjectionKey<ProvideFomPure>

export const keyValueListPureKey = Symbol(
  'keyValueList'
) as InjectionKey<ProvideKeyValueListPure>

export const pollingIndicatorKey = Symbol(
  'pollingIndicator'
) as InjectionKey<ProvidedForPollingIndicator>

/**
 * Provide/Inject strict
 *  @see https://logaretm.com/blog/2020-12-23-type-safe-provide-inject/
 */

/**
 * Workaround for bug T | () => T does not work
 * @see https://github.com/microsoft/TypeScript/issues/37663
 */
export function injectStrictWithFactory<T>(
  key: InjectionKey<T>,
  fallback?: () => T
): T {
  const fallbackValue: T | undefined = fallback ? fallback() : undefined
  return injectStrict(key, fallbackValue)
}

export function injectStrict<T>(key: InjectionKey<T>, fallback?: T): T {
  //  Runtime check to make sure fallback is not a factory function
  if (typeof fallback === 'function') {
    throw new Error(
      'If you want to use a factory function for "injectStrict" please use "injectStrictWithFactory" instead'
    )
  }

  const resolved = inject(key, fallback)

  if (resolved === undefined) {
    throw new Error(`Could not resolve ${key.description}`)
  }

  return resolved
}
