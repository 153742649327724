import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmSendToWebfleetPureState.typegen'

export type State = Typegen0['matchesStates']

export const PmSendToWebfleetPureState = createMachine({
  tsTypes: {} as import('./PmSendToWebfleetPureState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'DELETE' } | { type: 'CANCEL' } | { type: 'CONFIRM' },
  },

  id: 'PmSendToWebfleetStatePure',
  initial: 'load',

  states: {
    load: {
      initial: 'loading',
      states: {
        loading: {},
        failed: {
          meta: {
            error: true,
            errorMessage: 'Es gab einen unbekannten Fehler',
          },
        },
      },
    },

    default: {
      on: {
        DELETE: 'delete',
      },
    },

    send: {
      states: {
        sending: {},
        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler bei der Übertragung Webfleet',
          },
        },
        success: {},
      },
    },

    delete: {
      initial: 'askForConfirmation',
      states: {
        askForConfirmation: {
          on: {
            CANCEL: '#PmSendToWebfleetStatePure.default',
            CONFIRM: {
              actions: ['emitDeleteInWebfleet'],
            },
          },
        },

        deleting: {},

        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab as einen unbekannten Fehler beim Löschen in Webfleet',
          },

          on: {
            DELETE: 'askForConfirmation',
          },
        },

        success: {},
      },
    },
  },
})

export const states = extractAllStates(PmSendToWebfleetPureState)
