<template>
  <div :class="[componentClass.root, classes]">
    <header :class="componentClass.element('header')">
      <div :class="componentClass.element('title')">{{ title }}</div>

      <PmButtonGroupPure :class="componentClass.element('control')">
        <PmButtonPure
          :icon="ICONS.ARROW_LEFT"
          title="Vorherige Woche (ALT+V)"
          :in-group="true"
          :disabled="disabled"
          @click="emit('showPreviousMonth')"
        />

        <PmButtonPure
          :icon="ICONS.ARROW_RIGHT"
          title="Nächste Woche (ALT+N)'"
          :in-group="true"
          :disabled="disabled"
          @click="emit('showNextMonth')"
        />
      </PmButtonGroupPure>
    </header>

    <div :class="componentClass.element('content')">
      <PmCalendarWidgetWeekdaysPure
        :class="componentClass.element('weekdays')"
      />

      <div :class="componentClass.element('weeks')">
        <PmCalendarWidgetWeekPure
          v-for="week in weeksOfCurrentMonth"
          :key="week.getTime()"
          :date="week"
          :selected-date="selectedDate"
          :start-of-interval="startOfInterval"
          :end-of-interval="endOfInterval"
          :disable-before="disableBefore"
          :disable-after="disableAfter"
          @select-date="emit('selectDate', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { startOfMonth, eachWeekOfInterval, endOfMonth } from 'date-fns'
import { useComponentClass } from '@thomasaull-shared/composables'

import { ICONS } from '@/constants/icons'

import PmCalendarWidgetWeekdaysPure from '@/components/PmCalendarWidget/PmCalendarWidgetWeekdaysPure.vue'
import PmCalendarWidgetWeekPure from '@/components/PmCalendarWidget/PmCalendarWidgetWeekPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonGroupPure from '@/components/basics/PmButtonGroupPure.vue'
import { formatWithLocale } from '@/utilities/date'

export interface Props {
  date: Date
  selectedDate?: Date
  rangeStartDate?: Date
  rangeEndDate?: Date
  range?: 'both' | 'start' | 'end'
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'showNextMonth'): void
  (event: 'showPreviousMonth'): void
  (event: 'selectDate', payload: { date: Date }): void
}>()

const componentClass = useComponentClass()
const startOfInterval = computed(() => startOfMonth(props.date))
const endOfInterval = computed(() => endOfMonth(props.date))

const weeksOfCurrentMonth = computed(() => {
  return eachWeekOfInterval(
    {
      start: startOfInterval.value,
      end: endOfInterval.value,
    },
    {
      weekStartsOn: 1,
    }
  )
})

const title = computed(() => {
  return formatWithLocale(props.date, 'LLLL yyyy')
})

const disableBefore = computed(() => {
  if (props.range !== 'start' && props.range !== 'both') return
  return props.rangeStartDate
})

const disableAfter = computed(() => {
  if (props.range !== 'end' && props.range !== 'both') return
  return props.rangeEndDate
})

const classes = computed(() => {
  return {
    'is-disabled': props.disabled,
  }
})
</script>

<style lang="scss">
.PmCalendarWidgetPure {
  $block: &;

  &.is-disabled {
    pointer-events: none;
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &-title {
    @include typography.h4($includeMargin: false);

    margin-right: auto;
  }

  &-content {
    border: 2px solid color.$gray-200;
    border-radius: constant.$borderRadius-large;
    padding: 8px;
  }

  &-weekdays {
    margin-bottom: 8px;
  }

  &-weeks {
    #{$block}.is-disabled & {
      opacity: 0.5;
    }
  }
}
</style>
