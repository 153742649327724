import type { Address, Contact } from '@/../generated/graphql'
import type { Props as PropsAddressPure } from '@/components/basics/PmAddress/PmAddressPure.vue'
import type { Nilable } from '@/types/misc'

type AddressSubset = Pick<
  Address,
  | 'firstName'
  | 'surname'
  | 'company'
  | 'street'
  | 'zip'
  | 'city'
  | 'country'
  | 'phone'
  | 'phoneCompany'
  | 'phoneMobile'
  | 'phonePrivate'
  | 'wwwAddress'
  | 'email'
>

type ContactSubset = Pick<
  Contact,
  'firstName' | 'surname' | 'mobile' | 'phone' | 'email'
>

export function normalizeAddress(address?: Nilable<AddressSubset>) {
  if (!address) return

  const normalized: PropsAddressPure['address'] = {
    firstName: address.firstName,
    lastName: address.surname,
    company: address.company,
    street: address.street,
    zip: address.zip,
    city: address.city,
    country: address.country?.caption,
    phone: address.phone,
    phoneCompany: address.phoneCompany,
    phoneMobile: address.phoneMobile,
    phonePrivate: address.phonePrivate,
    url: address.wwwAddress,
    email: address.email,
  }

  return normalized
}

export function normalizeContact(contact?: Nilable<ContactSubset>) {
  if (!contact) return

  const normalized: PropsAddressPure['address'] = {
    firstName: contact.firstName,
    lastName: contact.surname,
    phone: contact.phone,
    phoneMobile: contact.mobile,
    email: contact.email,
  }

  return normalized
}
