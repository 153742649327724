<template>
  <component
    :is="link_tag"
    :class="classes"
    v-bind="link_props"
    class="PmLinkPure"
    @click="$emit('click')"
    >{{ label }}</component
  >
</template>

<script>
import link from '@/mixins/link.js'
import propValidator from '@/functional/propValidator'

export const propTypes = {
  variant: {
    allowed: ['default', 'light'],
  },
}

export default {
  name: 'PmLinkPure',
  components: {},
  mixins: [link],

  props: {
    ...link.props,
    label: { type: String, required: true },
    variant: {
      type: String,
      default: 'default',
      validator: (value) =>
        propValidator.oneOf(value, propTypes.variant.allowed),
    },
  },

  emits: ['click'],

  computed: {
    classes() {
      return {
        [`${this.$options.name}--variantLight`]: this.variant === 'light',
      }
    },
  },
}
</script>

<style lang="scss">
.PmLinkPure {
  $block: &;

  @include cssVar.define($block, 'colorText', color.$text-default);
  @include cssVar.define(
    $block,
    'colorText--hover',
    // color.$text-default--hover
    color.$text-default
  );

  &--variantLight {
    @include cssVar.define($block, 'colorText', color.$text-light);
    @include cssVar.define(
      $block,
      'colorText--hover',
      color.$text-light--hover
    );
  }

  color: cssVar.use($block, 'colorText');

  &:hover,
  &.is-hover {
    color: cssVar.use($block, 'colorText--hover');
    text-decoration: underline;
  }
}
</style>
