<template>
  <div class="PmChangePasswordFormPure">
    <div v-if="hasNotifications" class="PmChangePasswordFormPure-notifications">
      <PmAppNotificationPure
        v-if="isNotificationSuccessVisible"
        variant="success"
        :timeout="useTimeout ? 2 : undefined"
      >
        Dass Passwort wurde erfolgreich geändert.
      </PmAppNotificationPure>

      <PmErrorNotificationPure
        v-if="isNotificationErrorVisible"
        :message="errorMessage || xstate.meta.value.errorMessage"
        :details="errorDetails"
      />
    </div>

    <form class="PmChangePasswordFormPure-form" @submit.prevent="login">
      <div class="PmChangePasswordFormPure-inputs">
        <PmInputPure
          v-model:value="oldPassword"
          label="Altes Passwort"
          class="PmChangePasswordFormPure-input"
          placeholder="•••"
          required
          :type="passwordInputType"
          :disabled="isLoading"
        />

        <PmInputPure
          v-model:value="newPassword"
          label="Neues Passwort"
          class="PmChangePasswordFormPure-input"
          placeholder="•••"
          required
          v-bind="newPasswordProps"
          :type="passwordInputType"
          :pattern="newPasswordRegex"
          :validation-messages="{
            patternMismatch:
              'Das neue Passwort darf nicht identisch mit dem alten Passwort sein.',
          }"
          :disabled="isLoading"
        />

        <PmInputPure
          v-model:value="newPasswordConfirmation"
          label="Neues Passwort bestätigen"
          class="PmChangePasswordFormPure-input"
          placeholder="•••"
          required
          v-bind="newPasswordProps"
          :type="passwordInputType"
          :pattern="newPasswordConfirmationRegex"
          :validation-messages="{
            patternMismatch:
              'Das bestätigte Passwort muss mit dem neuen Passwort übereinstimmen.',
          }"
          :disabled="isLoading"
        />
      </div>

      <div class="PmChangePasswordFormPure-control">
        <PmButtonPure
          label="Passwort ändern"
          variant="primary"
          class="PmChangePasswordFormPure-submit"
          :loading="isLoading"
          type="submit"
        />

        <PmCheckboxPure v-model:value="isPasswordsVisible">
          Passwörter anzeigen
        </PmCheckboxPure>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, toRef, getCurrentInstance } from 'vue'

import { escapeRegExp } from 'lodash-es'

import { useXState } from '@/composition/useXState'
import propValidator from '@/functional/propValidator'

import {
  PmChangePasswordFormPureState,
  states,
} from '@/components/PmChangePassword/PmChangePasswordFormPureState'

import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmChangePasswordFormPure',
  components: {
    PmAppNotificationPure,
    PmErrorNotificationPure,
    PmInputPure,
    PmButtonPure,
    PmCheckboxPure,
  },

  props: {
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    errorMessage: { type: String, default: undefined },
    errorDetails: { type: Array, default: undefined },
    useTimeout: { type: Boolean, default: false },
  },

  emits: ['submit'],

  setup(props) {
    const instance = getCurrentInstance()

    const newPasswordProps = {
      minlength: 8,
    }

    const xstate = useXState(PmChangePasswordFormPureState, {
      actions: {
        resetForm: () => instance.ctx.resetForm(),
      },
      syncStateWith: toRef(props, 'state'),
    })

    return {
      newPasswordProps,
      xstate,
    }
  },

  data() {
    return {
      isPasswordsVisible: false,
      oldPassword: undefined,
      newPassword: undefined,
      newPasswordConfirmation: undefined,
    }
  },

  computed: {
    passwordInputType() {
      return this.isPasswordsVisible ? 'text' : 'password'
    },

    newPasswordRegex() {
      /**
       * Should not match the old password
       * @see https://stackoverflow.com/questions/52005748/html5-validation-valid-when-the-value-is-not-equal-to-a-certain-string/52006367
       */

      const string = escapeRegExp(this.oldPassword)
      const pattern = `^(?!${string}$).*`

      return pattern
    },

    newPasswordConfirmationRegex() {
      return escapeRegExp(this.newPassword)
    },

    isLoading() {
      if (this.xstate.state.value.matches('saving')) return true

      return false
    },

    isNotificationSuccessVisible() {
      return this.xstate.state.value.matches('success')
    },

    isNotificationErrorVisible() {
      return this.xstate.meta.value.error ? true : false
    },

    hasNotifications() {
      if (this.isNotificationSuccessVisible) return true
      if (this.isNotificationErrorVisible) return true

      return false
    },
  },

  methods: {
    login() {
      this.$emit('submit', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      })
    },

    resetForm() {
      this.oldPassword = undefined
      this.newPassword = undefined
      this.newPasswordConfirmation = undefined
    },
  },
})
</script>

<style lang="scss">
.PmChangePasswordFormPure {
  $block: &;

  &-title {
    @include typography.h3;

    margin-bottom: 1em;
  }

  &-notifications {
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-inputs {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-input {
    // Empty
  }

  &-control {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
