// TODO: Include old algorithm for groups but improve it, so it'll actually work
// TODO: Use Javscript methods instead of lodash

import cuid from '@paralleldrive/cuid2'
import {
  cloneDeep,
  clone,
  isNil,
  indexOf,
  each,
  filter,
  sortBy,
  find,
} from 'lodash-es'

const debug = false
// How much are you willing to expand the dimensions of a slot to fit an element?
const leeway = 0.9

export function packing(elements, options) {
  // if (!options.containerWidth) {
  if (isNil(options.containerWidth)) {
    console.error('containerWidth needs to be defined!')
    return
  }

  const defaultOptions = {
    maintainOrder: true,
    containerWidth: undefined,
    roundElementDimensions: false,
  }
  options = { ...defaultOptions, ...options }

  // Clone elements so originals won't be affected in any way
  elements = cloneDeep(elements)

  // Round element dimensions if wanted by options
  if (options.roundElementDimensions) {
    elements.forEach((element) => {
      element.width = Math.round(element.width)
      element.height = Math.round(element.height)
    })
  }

  // Set initial slot
  let slots = [
    {
      x: 0,
      y: 0,
      width: options.containerWidth,
      height: Infinity,
      id: 'initialSlot',
    },
  ]

  const packedElements = []

  // Do packing:
  elements.forEach((element) => {
    options.minY = 0

    const data = getSlot(element, slots, options)

    if (data) {
      if (data.slots) slots = data.slots
      if (data.element) packedElements.push(data.element)
    } else console.warn('no slot found for element!')
  })

  // get the height of all the packed elements
  const heightOfPackedElements = packedElements.reduce((currentY, element) => {
    const yOfBottomOfElement = element.y + element.height

    if (yOfBottomOfElement > currentY) return yOfBottomOfElement
    return currentY
  }, 0)

  return {
    packedElements,
    remainingSlots: slots,
    heightOfPackedElements,
  }
}

function getSlot(element, slots, options) {
  // Clone slots, so original slots won't be affected
  slots = clone(slots)

  debugLog('--------------')

  const newSlots = []

  debugLog(
    `finding slot for: ${packingExplodeSlot(element)}, minY:${options.minY}`
  )

  // Try finding a slot for the item, by iterating through all available slots
  // and check if the element fits inside the dimensions
  const slot = find(slots, (slot) => {
    debugLog('check' + packingExplodeSlot(slot))

    // Decline slots, where the element.x is on the left of slot.x
    if (element.x < slot.x - leeway) {
      // Exception: Slot is NOT on far left and element might just overflow there
      // TODO: I don't fully understand this tbh:
      if (!(slot.x <= 0)) {
        debugLog('Not using because slot starts too far right')
        return false
      }
    }

    // Decline slots, where the elements width exceeds the width of the slot
    if (element.x + element.width > slot.x + slot.width + leeway) {
      // Exception: Slot is NOT on the far right and element might just overflow there:
      if (!(slot.x + slot.width >= options.containerWidth)) {
        debugLog('not using because element too wide for slot')
        return false
      }
    }

    // Decline slots, where the element is greater in height than the slot itself
    if (element.height > slot.height + leeway) {
      debugLog('not using because element is too tall for slot')
      return false
    }

    return true
  })

  // Slot was found! Hooray!
  if (slot) {
    debugLog('slot found, using: ' + packingExplodeSlot(slot), 'success')
    debugLog(`slot.y: ${slot.y}, minY: ${options.minY}`)

    element.y = slot.y
    if (slot.y < options.minY) element.y = options.minY

    // Remove the used slot from slots collection
    debugLog('remove used slot: ' + packingExplodeSlot(slot))
    removeSlotFromCollection(slot, slots)

    // Split slot = Create new slots dependend where the element was placed in the slot:
    debugLog('do slot split afer placement')
    if (checkSplitLeft(slot, element)) newSlots.push(doSplitLeft(slot, element))

    if (checkSplitRight(slot, element))
      newSlots.push(doSplitRight(slot, element))

    // When elements should positioned in the correct order, don't do a split over
    if (!options.maintainOrder && checkSplitOver(slot, element))
      newSlots.push(doSplitOver(slot, element))

    if (checkSplitUnder(slot, element))
      newSlots.push(doSplitUnder(slot, element))
  }

  // Check if places element overlaps with any old slots, if so, split them into smaller pieces:
  const overlappingSlots = filter(slots, (slot) => {
    if (doRectsOverlap(slot, element)) {
      debugLog('overlap found, slot: ' + packingExplodeSlot(slot))

      if (checkSplitLeft(slot, element))
        newSlots.push(doSplitLeft(slot, element))

      if (checkSplitRight(slot, element))
        newSlots.push(doSplitRight(slot, element))

      // When elements should positioned in the correct order, don't do a split over
      if (!options.maintainOrder && checkSplitOver(slot, element))
        newSlots.push(doSplitOver(slot, element))

      if (checkSplitUnder(slot, element))
        newSlots.push(doSplitUnder(slot, element))

      return true
    }
  })

  debugLog('remove overlapping slots:')
  each(overlappingSlots, (slot) => {
    debugLog(packingExplodeSlot(slot))
  })

  packingRemoveSlotsFromCollection(overlappingSlots, slots)

  // When the order should be maintained, modify all slots which start before the current minY
  if (options.maintainOrder) {
    each(slots, (slot) => {
      if (slot.y < options.minY) {
        slot.height = slot.height - options.minY
        slot.y = options.minY
      }
    })
  }

  // Integrate new slots
  slots = [...slots, ...newSlots]

  // Remove all slots which are too small to possibly fit any elements:
  slots = filter(slots, (slot) => {
    if (slot.width < 1) return false
    if (slot.height < 1) return false

    if (slot.width < 10) return false
    if (slot.height < 50) return false

    return true
  })

  removeNonMaximalSlots(slots)

  // Sort all free slots by y position, so new elements use the top-most slots first:
  slots = sortBy(slots, 'y')

  return {
    element: element,
    slots: slots,
  }
}

const checkSplitLeft = (slot, element) => element.x > slot.x

const checkSplitRight = (slot, element) =>
  element.x + element.width < slot.x + slot.width

const checkSplitOver = (slot, element) => element.y > slot.y

const checkSplitUnder = (slot, element) =>
  element.y + element.height < slot.y + slot.height

function doSplitLeft(slot, element) {
  const newSlot = {
    x: slot.x,
    y: slot.y,
    width: element.x - slot.x,
    height: slot.height,
    id: cuid.createId(),
  }

  debugLog('split left: ' + packingExplodeSlot(newSlot))

  return newSlot
}

function doSplitRight(slot, element) {
  const newSlot = {
    x: element.x + element.width,
    y: slot.y,
    width: slot.width - element.width - element.x + slot.x,
    height: slot.height,
    id: cuid.createId(),
  }

  debugLog('split right: ' + packingExplodeSlot(newSlot))

  return newSlot
}

function doSplitOver(slot, element) {
  const newSlot = {
    x: slot.x,
    y: slot.y,
    width: slot.width,
    height: element.y - slot.y,
    id: cuid.createId(),
  }

  debugLog('split over: ' + packingExplodeSlot(newSlot))

  return newSlot
}

function doSplitUnder(slot, element) {
  const newSlot = {
    x: slot.x,
    y: element.y + element.height,
    width: slot.width,
    height: slot.height - element.height - element.y + slot.y,
    id: cuid.createId(),
  }

  debugLog('split under: ' + packingExplodeSlot(newSlot))

  return newSlot
}

// Check if there are non maximal rects and remove them:
// Source: https://github.com/haltu/muuri/blob/master/muuri.js#L5728
function removeNonMaximalSlots(slots) {
  // TODO: It's very unclear what's actually happening here
  let i = slots.length
  let ii
  let rectA
  let rectB

  while (i--) {
    rectA = slots[i]
    ii = slots.length
    while (ii--) {
      rectB = slots[ii]
      if (i !== ii && rectIsWithinRect(rectA, rectB)) {
        slots.splice(i, 1)
        break
      }
    }
  }
}

function removeSlotFromCollection(slotToRemove, slots) {
  const slotIndex = indexOf(slots, slotToRemove)
  slots.splice(slotIndex, 1)
}

function packingRemoveSlotsFromCollection(slotsToRemove, slots) {
  each(slotsToRemove, (slot) => removeSlotFromCollection(slot, slots))
}

// Source: https://github.com/haltu/muuri/blob/master/muuri.js#L5690
function doRectsOverlap(recA, recB) {
  return !(
    recA.x + recA.width <= recB.x ||
    recB.x + recB.width <= recA.x ||
    recA.y + recA.height <= recB.y ||
    recB.y + recB.height <= recA.y
  )
}

// Source: https://github.com/haltu/muuri/blob/master/muuri.js#L5705
function rectIsWithinRect(rectA, rectB) {
  return (
    rectA.x >= rectB.x &&
    rectA.y >= rectB.y &&
    rectA.x + rectA.width <= rectB.x + rectB.width &&
    rectA.y + rectA.height <= rectB.y + rectB.height
  )
}

function packingExplodeSlot(slot) {
  return `{x: ${slot.x}, y: ${slot.y}, width: ${slot.width}, height: ${slot.height}, id: ${slot.id}}`
}

function debugLog(message, type) {
  if (!debug) return
  if (type === 'warn') return console.warn(message)

  if (type === 'success') {
    return console.log(`%c ${message}`, 'background: green; color: #fff')
  }

  console.log(message)
}
