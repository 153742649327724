<template>
  <ol class="PmCalendarWidgetWeekdaysPure">
    <li
      v-for="weekday in weekdays"
      :key="weekday"
      class="PmCalendarWidgetWeekdaysPure-day"
    >
      {{ weekday }}
    </li>
  </ol>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { addDays, eachDayOfInterval } from 'date-fns'

const monday = addDays(new Date(0), 4)
const sunday = addDays(monday, 6)
const weekdays = eachDayOfInterval({
  start: monday,
  end: sunday,
}).map((date) => {
  return date.toLocaleString(window.navigator.language, {
    weekday: 'short',
  })
})

export default defineComponent({
  name: 'PmCalendarWidgetWeekdaysPure',
  components: {},

  setup() {
    return {
      weekdays,
    }
  },
})
</script>

<style lang="scss">
.PmCalendarWidgetWeekdaysPure {
  $block: &;

  display: grid;
  grid-template-columns: repeat(7, 1fr);

  &-day {
    padding: 8px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
