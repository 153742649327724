import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmLoginPureState.typegen'

export const PmLoginPureState = createMachine({
  tsTypes: {} as import('./PmLoginPureState.typegen').Typegen0,
  schema: {},

  id: 'PmLoginPureState',
  initial: 'default',

  states: {
    default: {},
    checkIfLoggedIn: {},
    autoLogin: {},

    login: {
      initial: 'default',
      states: {
        default: {},
        loggingIn: {},
        success: {},
        failed: {},
      },
    },
  },
})

export const states = extractAllStates(PmLoginPureState)
export type TState = Typegen0['matchesStates']
