<template>
  <PmDialogConfirmPure
    :subject="SUBJECT_LOOKUP[requestType]"
    action-label="Genehmigen"
    :is-loading="isLoading"
    :error-message="errorMessage"
    :error-details="errorDetails"
    :form-id="formId"
    @cancel="emit('cancel')"
    @confirm="emit('confirm')"
  >
    <div :class="componentClass.root">
      <PmFormPure :id="formId" :disabled="isLoading" @submit.prevent="onSubmit">
        <template v-if="requestType === 'leaveRequest'">
          <PmActivatePure
            v-if="approvalType == 'hr'"
            label="Art ändern"
            @deactivated="resetSelectedTypeId"
          >
            <PmRadioListPure
              v-model:checkedId="selectedTypeId"
              required
              label="Art des Urlaubs"
              name="leaveRequestType"
              :items="optionsType"
            />
          </PmActivatePure>
        </template>

        <PmTextareaPure v-model:value="note" label="Bemerkung" :rows="3" />
      </PmFormPure>
    </div>
  </PmDialogConfirmPure>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import type { Entries } from 'type-fest'
import cuid from '@paralleldrive/cuid2'

import PmDialogConfirmPure from '@/components/basics/PmDialog/PmDialogConfirmPure.vue'
import type { LeaveRequestApprovalType } from '@/../generated/graphql'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmRadioListPure, {
  type Props as PropsRadioListPure,
} from '@/components/basics/PmRadioList/PmRadioListPure.vue'
import { LEAVE_REQUEST_TYPE_LABEL_LOOKUP } from '@/constants/leaveRequest'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import type { Nilable } from '@/types/misc'
import PmActivatePure from '@/components/basics/PmActivate/PmActivatePure.vue'

export interface Props {
  isLoading?: boolean
  errorMessage?: string
  errorDetails?: string[]
  requestType: 'leaveRequest' | 'externalServiceRequest'
  approvalType?: Nilable<`${LeaveRequestApprovalType}`>
  initialFormData?: {
    selectedTypeId?: SelectedTypeId
  }
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  submit: {
    type?: SelectedTypeId
    note?: string
  }
}

const emit = defineEmits<{
  cancel: []
  confirm: []
  submit: [Emits['submit']]
}>()

const componentClass = useComponentClass()
const formId = cuid.createId()

const SUBJECT_LOOKUP: Record<Props['requestType'], string> = {
  leaveRequest: 'Urlaubsantrag',
  externalServiceRequest: 'Fremddienstleistung',
}

const optionsType = (
  Object.entries(LEAVE_REQUEST_TYPE_LABEL_LOOKUP) as Entries<
    typeof LEAVE_REQUEST_TYPE_LABEL_LOOKUP
  >
).map(([id, label]) => {
  return {
    id,
    label,
  }
}) satisfies PropsRadioListPure['items']

type SelectedTypeId = (typeof optionsType)[number]['id']
const selectedTypeId = ref<SelectedTypeId | undefined>(
  props.initialFormData?.selectedTypeId
)
const note = ref<string>()

function resetSelectedTypeId() {
  selectedTypeId.value = props.initialFormData?.selectedTypeId
}

function onSubmit() {
  emit('submit', {
    type: selectedTypeId.value,
    note: note.value,
  })
}
</script>

<style lang="scss">
.PmDialogRequestApprovePure {
  $block: &;
}
</style>
