import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './ResourceAllocationConflictPureState.typegen'

export type State = Typegen0['matchesStates']

export const ResourceAllocationConflictPureState = createMachine({
  tsTypes:
    {} as import('./ResourceAllocationConflictPureState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'OPEN_DETAILS' } | { type: 'CLOSE_DETAILS' },
  },

  id: 'ResourceAllocationConflictPureState',
  initial: 'default',

  states: {
    default: {},

    tooManyDays: {},

    check: {},

    error: {
      meta: {
        error: true,
        errorMessage:
          'Es gab einen unbekannten Fehler bei der Suche nach Zeitkonflikten',
      },
    },

    conflicts: {
      initial: 'default',

      states: {
        default: {
          on: {
            OPEN_DETAILS: 'visible',
          },
        },

        visible: {
          on: {
            CLOSE_DETAILS: 'default',
          },
        },
      },
    },
    noConflicts: {},
  },
})

export const states = extractAllStates(ResourceAllocationConflictPureState)
