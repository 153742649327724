<template>
  <PmDialogConfirmPure
    :subject="t('subject', numberOfItems)"
    :action-label="actionLabel"
    :is-loading="xstate.state.value.matches('save.saving')"
    :error-message="errorMessage || xstate.meta.value.errorMessageror"
    :error-details="errorDetails"
    @cancel="emit('close')"
    @confirm="emit('confirm')"
  >
    <div :class="componentClass.root">
      <div :class="componentClass.element('content')">
        <PmAppNotificationPure :title="textEmail" />
      </div>
    </div>
  </PmDialogConfirmPure>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { useComponentClass } from '@thomasaull-shared/composables'
import { useXState } from '@/composition/useXState'

import PmAppNotificationPure from '../basics/PmAppNotification/PmAppNotificationPure.vue'

import {
  PmResourceRequestUpdatePureState,
  type State,
} from '@/components/PmResourceRequestUpdate/PmResourceRequestUpdatePureState'
import PmDialogConfirmPure from '@/components/basics/PmDialog/PmDialogConfirmPure.vue'

export interface Props {
  numberOfItems: number
  action: 'confirm' | 'reserve' | 'decline'
  errorMessage?: string
  errorDetails?: string[]
  state?: State
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'close'): void
  (event: 'confirm'): void
}>()

const componentClass = useComponentClass()

const xstate = useXState(PmResourceRequestUpdatePureState, {
  syncStateWith: toRef(props, 'state'),
})

const { t } = useI18n({
  messages: {
    de: {
      subject: 'Ressourcen-Anfrage | {n} Ressourcen-Anfragen',
      confirmTextEmail:
        'Die Ressource wird per E-Mail über die Zusage informiert. | Die Ressourcen werden per E-Mail über die Zusage informiert',
      confirmActionLabel: 'Bestätigen',
      reserveTextEmail:
        'Die Ressource wird per E-Mail über die Reservierung informiert. | Die Ressourcen werden per E-Mail über die Reservierung informiert',
      reserveActionLabel: 'Reservieren',
      declineTextEmail:
        'Die Ressource wird per E-Mail über die Absage informiert. | Die Ressourcen werden per E-Mail über die Absage informiert',
      declineActionLabel: 'Absagen',
    },
  },
})

const actionLabel = computed(() => {
  if (props.action === 'confirm')
    return t('confirmActionLabel', props.numberOfItems)
  if (props.action === 'reserve')
    return t('reserveActionLabel', props.numberOfItems)
  if (props.action === 'decline')
    return t('declineActionLabel', props.numberOfItems)

  throw new Error('actionLabel needs to be defined')
})

const textEmail = computed(() => {
  if (props.action === 'confirm')
    return t('confirmTextEmail', props.numberOfItems)
  if (props.action === 'reserve')
    return t('reserveTextEmail', props.numberOfItems)
  if (props.action === 'decline')
    return t('declineTextEmail', props.numberOfItems)

  return undefined
})
</script>

<style lang="scss">
.PmResourceRequestUpdatePure {
  $block: &;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
