// TODO: Add generic functions for adding/remove/clearing id collection without which don't need sideeffects
import deepmerge from 'deepmerge'
import { startOfDay, endOfDay, differenceInCalendarDays } from 'date-fns'

import { getDefaultStartDate, getDefaultEndDate } from '@/utilities/date'

import { BREAKPOINT } from '@/constants/general'

// import { RESOURCE_TYPE } from '@/constants/persoplan'

const getDefaultState = () => ({
  visibleStartDate: getDefaultStartDate(),
  visibleEndDate: getDefaultEndDate(),
  resourceSelectionType: undefined,
  resourceSelectionIds: [],
  resourceShowSkeletonIds: [],
  highlightedItems: [],
  timelineHighlightedId: undefined,
  sidebarLeftVisible: true,
  sidebarRightVisible: true,
  projectGroupsCollapsed: {},
  resourceAllocationEditPopoverIsOpen: undefined,
  viewEditorVisible: false,
  isEditing: undefined,
})

/** Overwrites the getDefaultState on some properties */
const getDefaultStateMobile = () => ({
  visibleEndDate: getDefaultEndDate({ additionalDays: 3 }),
  sidebarLeftVisible: false,
})

const isMobile = window.matchMedia(`(max-width: ${BREAKPOINT.MOBILE})`).matches

const state = isMobile
  ? deepmerge(getDefaultState(), getDefaultStateMobile())
  : getDefaultState()

const mutations = {
  setVisibleDates(state, { startDate, endDate }) {
    state.visibleStartDate = startOfDay(startDate)
    state.visibleEndDate = endOfDay(endDate)
  },

  addToResourceSelection(state, { type, id }) {
    if (!state.resourceSelectionType) {
      state.resourceSelectionType = type
    }

    if (state.resourceSelectionType !== type) {
      throw new Error('wrong type')
    }

    state.resourceSelectionIds.push(id)
  },

  removeFromResourceSelection(state, id) {
    const index = state.resourceSelectionIds.indexOf(id)

    if (index === -1) {
      console.warn('removeFromResourceSelection: index not found')
      return
    }

    state.resourceSelectionIds.splice(index, 1)

    if (state.resourceSelectionIds.length === 0) {
      state.resourceSelectionType = undefined
    }
  },

  clearResourceSelection(state) {
    state.resourceSelectionIds = []
    state.resourceSelectionType = undefined
  },

  addToResourceShowSkeletonIds(state, id) {
    state.resourceShowSkeletonIds.push(id)
  },

  removeFromResourceShowSkeletonIds(state, id) {
    const index = state.resourceShowSkeletonIds.indexOf(id)

    if (index === -1) {
      console.warn('removeFromResourceShowSkeletonIds: index not found')
      return
    }

    state.resourceShowSkeletonIds.splice(index, 1)
  },

  addToHighlightedItems(state, item) {
    state.highlightedItems.push(item)
  },

  clearHighlightedItems(state) {
    state.highlightedItems = []
  },

  removeFromHighlightedItems(state, { type, id }) {
    const index = state.highlightedItems.findIndex((item) => {
      return item.id === id && item.type === type
    })

    if (index === -1) {
      console.warn('removeFromHighlightedItems: index not found')
      return
    }
    state.highlightedItems.splice(index, 1)
  },

  setTimelineHighlightedId(state, id) {
    state.timelineHighlightedId = id
  },

  clearTimelineHighlightedId(state) {
    state.timelineHighlightedId = undefined
  },

  showSidebar(state, { name }) {
    state[`${name}Visible`] = true
  },

  hideSidebar(state, { name }) {
    state[`${name}Visible`] = false
  },

  projectGroupCollapse(state, groupNumber) {
    state.projectGroupsCollapsed[groupNumber] = true
  },

  projectGroupExpand(state, groupNumber) {
    // eslint-disable-next-line
    if (!state.projectGroupsCollapsed.hasOwnProperty(groupNumber)) return

    delete state.projectGroupsCollapsed[groupNumber]
  },

  projectGroupExpandAll(state) {
    state.projectGroupsCollapsed = getDefaultState().projectGroupsCollapsed
  },

  resourceAllocationEditPopoverIsOpen(state) {
    state.resourceAllocationEditPopoverIsOpen = true
  },

  resourceAllocationEditPopoverIsClosed(state) {
    state.resourceAllocationEditPopoverIsOpen = false
  },

  showViewEditor(state) {
    state.viewEditorVisible = true
  },

  hideViewEditor(state) {
    state.viewEditorVisible = false
  },

  setEditState(state, isEditing) {
    state.isEditing = isEditing
  },
}

const getters = {
  numberOfVisibleDays(state) {
    return (
      differenceInCalendarDays(state.visibleEndDate, state.visibleStartDate) + 1
    )
  },

  isItemHighlighted:
    (state) =>
    ({ id, type }) => {
      return state.highlightedItems.find(
        (item) => item.id === id && item.type === type
      )
        ? true
        : false
    },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
