<template>
  <PmNotificationDropdownPure
    v-model:show-read="notifications.showRead.value"
    :has-unread-notifications="notifications.hasUnreadNotifications.value"
    :is-loading="notifications.isLoading.value"
    @mark-all-as-read="notifications.markAllAsRead"
  >
    <template #notifications="slotProps">
      <template v-if="notificationsSorted">
        <PmNotification
          v-for="notification in notificationsSorted"
          :id="notification.id"
          :key="notification.id"
          :class="slotProps.class"
        />
      </template>
    </template>
  </PmNotificationDropdownPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmNotificationDropdown'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy } from 'lodash-es'

import notifications from '@/store/notifications/notifications'
import PmNotificationDropdownPure from '@/components/PmNotificationDropdown/PmNotificationDropdownPure.vue'
import PmNotification from '@/components/PmNotification/PmNotification.vue'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const notificationsSorted = computed(() => {
  if (!notifications.notifications.value) return

  return orderBy(
    Array.from(notifications.notifications.value.values()),
    ['date'],
    ['desc']
  )
})
</script>
