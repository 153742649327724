<template>
  <PmSidebarJobPure
    v-if="job"
    :id="id"
    :number="job.number"
    :title="job.caption"
    :status="statusNormalized"
    :is-collected-with-all-jobs-of-project="
      job?.project?.setting?.isJobLayerHidden ?? undefined
    "
    :note="job.comment ?? undefined"
    :number-of-alerts="job.numberOfMissingResources ?? undefined"
    :alerts-visible="store.state.view.currentView.showMissingResourcesWarning"
    :is-highlighted="isHighlighted"
    @jump="jumpToJobInCalendar"
    @show-details="showDetails"
    @open-note="showDetails"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarJob'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
/**
 * @todo Make alerts visible by store setting
 */

import { computed } from 'vue'
import { useStore } from 'vuex'

import { EVENT } from '@/constants/events'
import { CALENDAR_ITEM_TYPE, DATA_MODAL_TYPE } from '@/constants/persoplan'

import { statusLookupJob } from '@/utilities/persoplan'
import { useCachedQuery } from '@/composition/useCachedQuery'
import EventHub from '@/eventHub'
import { useJumpTargets } from '@/pinia/jumpTargets'

import PmSidebarJobPure from '@/components/persoplan/PmSidebarJob/PmSidebarJobPure.vue'

import { SidebarJobDocument } from '@/../generated/graphql'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()

const jobQuery = useCachedQuery(SidebarJobDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']
  return {
    id: props.id,
    startDate: queryVariables.startDate,
    endDate: queryVariables.endDate,
  }
})

const job = computed(() => jobQuery.result.value?.job)

const statusNormalized = computed(() => {
  if (!job.value) return

  const status = statusLookupJob({
    jobStateId: job.value?.jobState?.id,
    jobTypeId: job.value?.jobState?.jobType?.id,
  })

  return status
})

const isHighlighted = computed({
  get: function () {
    return store.getters['persoplan/isItemHighlighted']({
      id: props.id,
      type: CALENDAR_ITEM_TYPE.JOB,
    })
  },

  set: function (value) {
    if (value === true) {
      store.commit('persoplan/addToHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.JOB,
      })
    } else {
      store.commit('persoplan/removeFromHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.JOB,
      })
    }
  },
})

const jumpTargets = useJumpTargets()

function jumpToJobInCalendar() {
  const jumpTarget = {
    type: 'AppointmentsContainer',
    id: props.id,
  } as const

  if (jumpTargets.isAvailable(jumpTarget)) {
    jumpTargets.jumpTo(jumpTarget)
  } else {
    throw new Error('jumpTarget is not available')
  }

  store.commit('persoplan/clearHighlightedItems')
  isHighlighted.value = true
}

function showDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.JOB,
    id: props.id,
  })
}
</script>
