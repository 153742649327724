<template>
  <div class="PmDayWidthControllerPure"></div>
</template>

<script>
export default {
  name: 'PmDayWidthControllerPure',
}
</script>

<style lang="scss">
.PmDayWidthControllerPure {
  $block: &;

  width: 20px;
  height: 30px;
  cursor: col-resize;
  position: relative;

  // Visual Indicator:
  &::after {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background-color: color.$key;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.4;
    transition: all 0.1s linear;
    transition-property: opacity, height;
  }

  &.is-hover,
  &:hover {
    &::after {
      opacity: 1;
      height: 100%;
    }
  }

  // TODO: Disable for touch
  // .-touchInterface & {
  //   display: none;
  // }
}
</style>
