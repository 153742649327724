import { get, cloneDeep } from 'lodash-es'

import { apolloClient } from '@/vue-apollo'
import { getSettingsDataToSave } from '@/functional/settings'
import { settingsToPersist } from '@/store/plugins/settingsConfig'

import SaveSettingMutation from '@/graphql/SaveSettingMutation.graphql'

/**
 * @todo Create hash of last saved settings, to avoid saving multiple times
 * @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API/Non-cryptographic_uses_of_subtle_crypto
 */

export default (store) => {
  let previousState = cloneDeep(store.state)

  store.subscribe((mutation, state) => {
    // Ignore the mutation which applies the actual settings
    if (mutation.type === 'applyPersistedSettings') {
      return
    }

    const changedSettings = Object.keys(settingsToPersist).filter(
      (statePath) => {
        const options = settingsToPersist[statePath]
        const valuePrevious = get(previousState, statePath)
        const valueCurrent = get(state, statePath)

        if (options.type === Date) {
          return hasDateChanged(valuePrevious, valueCurrent)
        }

        if (options.type === Object) {
          return hasObjectchanged(valuePrevious, valueCurrent)
        }

        // If code runs until here, it's a primitive value
        return valuePrevious !== valueCurrent
      }
    )

    // Nothing has changed
    if (!changedSettings.length) return

    persistSettings()
    previousState = cloneDeep(state)
  })
}

function hasDateChanged(previous, current) {
  return previous.getTime() !== current.getTime()
}

function hasObjectchanged(previous, current) {
  return JSON.stringify(previous) !== JSON.stringify(current)
}

async function persistSettings() {
  // TODO: Indicator for loading
  // TODO: Error handling

  const settings = getSettingsDataToSave()

  try {
    await apolloClient.mutate({
      mutation: SaveSettingMutation,
      variables: {
        values: JSON.stringify(settings),
      },
    })
  } catch (error) {
    throw new Error(error)
    // dispatch('errors/handle', { error }, { root: true })
  }
}
