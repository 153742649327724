/**
 * @todo Some of these values can be extracted automatically from the grpahql schema and thus have a single source of truth
 */

import { isNil } from 'lodash-es'

import type { Nilable } from '@/types/misc'
import {
  WebfleetState,
  ResourceRequestUserStatus,
} from '@/../generated/graphql'

export const constants = {
  siteNavWidth: 76,
}

/**
 * @todo This should only be address and vehicle, freelancer should instead
 * be used from ADDRESS_RESOURCE_TYPE
 *
 */
export const RESOURCE_TYPE = {
  ADDRESS: 'address',
  FREELANCER: 'freelancer',
  VEHICLE: 'vehicle',
} as const

export type ResourceType = (typeof RESOURCE_TYPE)[keyof typeof RESOURCE_TYPE]

export const RESOURCE_TYPES = Object.values(RESOURCE_TYPE)

export const RESOURCE_TYPE_LOOKUP = {
  1: RESOURCE_TYPE.ADDRESS,
  2: RESOURCE_TYPE.VEHICLE,
} as const

export const ADDRESS_RESOURCE_TYPE = {
  EMPLOYEE: 'employee',
  FREELANCER: 'freelancer',
  STAFF_AGENCY: 'staffAgency',
} as const

/** Lookup addressResourceType.id  by providing ADDRESS_RESOURCE_TYPE */
export const ADDRESS_RESOURCE_TYPE_ID_LOOKUP = {
  [ADDRESS_RESOURCE_TYPE.EMPLOYEE]: 1,
  [ADDRESS_RESOURCE_TYPE.FREELANCER]: 2,
  [ADDRESS_RESOURCE_TYPE.STAFF_AGENCY]: 3,
} as const

/** Lookup ADDRESS_RESOURCE_TYPE by providing the addressResourceType.id  */
export const ADDRESS_RESOURCE_TYPE_LOOKUP = {
  1: ADDRESS_RESOURCE_TYPE.EMPLOYEE,
  2: ADDRESS_RESOURCE_TYPE.FREELANCER,
  3: ADDRESS_RESOURCE_TYPE.STAFF_AGENCY,
} as const

/** Time between updates in seconds */
export const POLLING_INTERVAL = 60

export const END_OF_DAY = '05:30'
export const END_OF_DAY_IS_AFTER_MIDNIGHT = true
export const END_OF_DAY_HOURS = parseInt(END_OF_DAY.split(':')[0])
export const END_OF_DAY_MINUTES = parseInt(END_OF_DAY.split(':')[1])
export const END_OF_DAY_HOURS_FLOAT =
  (END_OF_DAY_HOURS * 60 + END_OF_DAY_MINUTES) / 60

export const FORMAT_TIME_DEFAULT = "HH'h'mm"
export const FORMAT_DATE_DEFAULT = 'dd.MM.yyyy'
export const FORMAT_DATE_SHORT = 'dd.MM' as const
export const FORMAT_TIME_SERVER = 'HH:mm:ss' as const
export const FORMAT_DATETIME_DEFAULT = "dd.MM.yyyy, HH'h'mm"
export const FORMAT_DATETIME_SHORT = "dd.MM. HH'h'mm" as const
export const FORMAT_DATETIME_SERVER = 'yyyy-MM-dd HH:mm:ss' as const
export const FORMAT_DATE_SERVER = 'yyyy-MM-dd' as const

export const REDUCED_VIEW_DAYS_TRESHOLD = 30

/**
 * @todo: Rename to JOB_STATUS
 */
export const STATUS = {
  CONFIRMED: 'confirmed',
  REQUESTED: 'requested',
  CANCELLED: 'cancelled',
  CONSIDERED: 'considered',
  OFFERED: 'offered',
  SERVICE: 'service',
  SUB_HIRE: 'subHire', // Zumietung = ZM
  ITEM_TRANSFER: 'itemTransfer', // Materialumschlag = MU
} as const

// Alias for migration
export const STATUS_JOB = STATUS

export type JobStatus = (typeof STATUS)[keyof typeof STATUS]

export const PROJECT_STATUS = {
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  OFFERED: 'offered',
  SERVICE: 'service',
} as const

export const STATUS_PROJECT_LOOKUP = {
  1: PROJECT_STATUS.CONFIRMED,
  2: PROJECT_STATUS.OFFERED,
  3: PROJECT_STATUS.CANCELLED,
  4: PROJECT_STATUS.SERVICE,
} as const

export type ProjectStatus = (typeof PROJECT_STATUS)[keyof typeof PROJECT_STATUS]

export const STATUS_RESOURCE_ALLOCATION = {
  CONFIRMED: 'confirmed',
  REQUESTED: 'requested',
  CANCELLED: 'cancelled',
  CONSIDERED: 'considered',
  RESERVED: 'reserved',
} as const

export type ResourceAllocationStatus =
  (typeof STATUS_RESOURCE_ALLOCATION)[keyof typeof STATUS_RESOURCE_ALLOCATION]

export const STATUS_RESOURCE_ALLOCATION_LOOKUP = {
  1: STATUS_RESOURCE_ALLOCATION.CONFIRMED,
  2: STATUS_RESOURCE_ALLOCATION.REQUESTED,
  3: STATUS_RESOURCE_ALLOCATION.CANCELLED,
  4: STATUS_RESOURCE_ALLOCATION.CONSIDERED,
  5: STATUS_RESOURCE_ALLOCATION.RESERVED,
} as const

/**
 * @todo This should be generated from the (live?) API on build
 */
export const STATUS_RESOURCE_ALLOCATION_LOOKUP_LABEL = {
  [STATUS_RESOURCE_ALLOCATION.CONFIRMED]: 'Bestätigt',
  [STATUS_RESOURCE_ALLOCATION.REQUESTED]: 'Anfrage',
  [STATUS_RESOURCE_ALLOCATION.CANCELLED]: 'Abgesagt',
  [STATUS_RESOURCE_ALLOCATION.CONSIDERED]: 'Angedacht',
  [STATUS_RESOURCE_ALLOCATION.RESERVED]: 'Reserviert',
} as const

/**
 * This could probably removed when a lookup table in the format of
 * [STATUS]: [ID] is used in STATUS_RESOURCE_ALLOCATION
 * @todo: Refactor
 */
export const STATUS_RESOURCE_ALLOCATION_ID_LOOKUP = {
  [STATUS_RESOURCE_ALLOCATION.CONFIRMED]: 1,
  [STATUS_RESOURCE_ALLOCATION.REQUESTED]: 2,
  [STATUS_RESOURCE_ALLOCATION.CANCELLED]: 3,
  [STATUS_RESOURCE_ALLOCATION.CONSIDERED]: 4,
  [STATUS_RESOURCE_ALLOCATION.RESERVED]: 5,
} as const

/**
 * Sort order for using in dropdown menus
 */
export const STATUS_RESOURCE_ALLOCATION_SORT_ORDER = [
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.CONSIDERED],
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.REQUESTED],
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.RESERVED],
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.CONFIRMED],
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP[STATUS_RESOURCE_ALLOCATION.CANCELLED],
] as const

export const STATUS_RESOURCE_ALLOCATION_SORT_ORDER_CALENDAR: ResourceAllocationStatus[] =
  ['confirmed', 'requested', 'reserved', 'considered', 'cancelled']

export const STATUS_JOB_LOOKUP = {
  1: STATUS.CONFIRMED,
  5: STATUS.CONFIRMED,
  18: STATUS.CONFIRMED,

  9: STATUS.REQUESTED,

  3: STATUS.CANCELLED,
  12: STATUS.CANCELLED,
  19: STATUS.CANCELLED,

  16: STATUS.CONSIDERED,

  2: STATUS.OFFERED,
  6: STATUS.OFFERED,
  17: STATUS.OFFERED,

  7: STATUS.SERVICE,
} as const

/**
 * @see: https://gitlab.com/pro-musik/graphql-api/-/issues/141#note_805963790
 */
export const STATUS_JOB_TYPE_LOOKUP = {
  // 1: 'Vermietung/Rental'
  2: STATUS.SUB_HIRE,
  // 3: 'Verkauf/Sales'
  4: STATUS.ITEM_TRANSFER,
} as const

/**
 * @todo This should be renamed to ResourceDayStatus or something similar
 */
export const RESOURCE_STATUS = {
  NOT_AVAILABLE: 'notAvailable', // Definitley not available
  AVAILABLE_IF_URGENT: 'availableIfUrgent', // Not available, but in urgent cases, can help
  AVAILABLE: 'available', // Is doing something, but can be used for project related stuff
  ON_PROJECT: 'onProject', // Doing stuff for projects
  STORAGE: 'storage',
} as const

export type ResourceStatus =
  (typeof RESOURCE_STATUS)[keyof typeof RESOURCE_STATUS]

export const RESOURCE_STATUS_LOOKUP_LABEL = {
  [RESOURCE_STATUS.NOT_AVAILABLE]: 'Nicht verfügbar',
  [RESOURCE_STATUS.AVAILABLE_IF_URGENT]: 'Verfügbar, falls dringend',
  [RESOURCE_STATUS.AVAILABLE]: 'Verfügbar',
  [RESOURCE_STATUS.ON_PROJECT]: 'Arbeitet für Projekt',
  [RESOURCE_STATUS.STORAGE]: 'Im Lager',
} as const

/**
 * `resourceStateTypes` query run against production on 2022-02-10
 */

//  45: - Ausgleich -
//  46: - frei -
//  28: - nicht verfügbar -
//  53: - verfügbar -
//  27: Anderes Fahrzeug gelenkt
//  24: Büro
//  31: Büro Frontdesk
//  44: Büro HomeOffice
//  47: Büro Lux
//  30: Büro Projekt
//  38: Defekt (Fahrzeug)
//   4: Fortbildung
//  20: Freelancer
//  41: Inspektion (Fahrzeug)
//  50: Install (ExHouse)
//  48: Install (InHouse)
//   2: Krank
//  23: Lager
//  42: Lager Projekt
//  36: Lagerhelfer
//  32: LAGERLEITUNG
//  43: MAV
//  34: Praktikum
//  26: Ruhezeit
//   3: Schule
//  51: Sonderfahrt (Fahrzeug)
//  21: Sonstige dienstliche Gründe
//  33: Termin (ExHouse)
//  29: Termin (inHouse)
//  49: U.-Anfrage
//   1: Urlaub
//   5: Wartung (Fahrzeug)
//  22: Wartung (GenRes)
//  35: Werkstatt
//   6: Werkstatt (Fahrzeug)
//  40: WERKSTATTLEITUNG

export const RESOURCE_STATUS_LOOKUP = {
  // NOT AVAILABLE
  28: RESOURCE_STATUS.NOT_AVAILABLE, // - nicht verfügbar -
  38: RESOURCE_STATUS.NOT_AVAILABLE, // Defekt (Fahrzeug)
  2: RESOURCE_STATUS.NOT_AVAILABLE, // Krank
  26: RESOURCE_STATUS.NOT_AVAILABLE, // Ruhezeit
  3: RESOURCE_STATUS.NOT_AVAILABLE, // Schule
  1: RESOURCE_STATUS.NOT_AVAILABLE, // Urlaub
  41: RESOURCE_STATUS.NOT_AVAILABLE, // Inspektion (Fahrzeug)
  5: RESOURCE_STATUS.NOT_AVAILABLE, // Wartung (Fahrzeug)
  45: RESOURCE_STATUS.NOT_AVAILABLE, // Ausgleich
  46: RESOURCE_STATUS.NOT_AVAILABLE, // Frei

  // AVAILABLE IF URGENT
  43: RESOURCE_STATUS.AVAILABLE_IF_URGENT, // MAV
  49: RESOURCE_STATUS.AVAILABLE_IF_URGENT, // Urlaubs-Anfrage
  4: RESOURCE_STATUS.AVAILABLE_IF_URGENT, // Fortbildung

  // AVAILABLE
  53: RESOURCE_STATUS.AVAILABLE, // - verfügbar -
  24: RESOURCE_STATUS.AVAILABLE, // Büro
  31: RESOURCE_STATUS.AVAILABLE, // Büro Frontdesk
  44: RESOURCE_STATUS.AVAILABLE, // Büro HomeOffice
  47: RESOURCE_STATUS.AVAILABLE, // Büro Lux
  48: RESOURCE_STATUS.AVAILABLE, // Install (InHouse)
  34: RESOURCE_STATUS.AVAILABLE, // Praktikum
  54: RESOURCE_STATUS.AVAILABLE, // - optioniert -

  // LAGER
  23: RESOURCE_STATUS.STORAGE, // Lager
  36: RESOURCE_STATUS.STORAGE, // Lagerhelfer
  32: RESOURCE_STATUS.STORAGE, // LAGERLEITUNG
  35: RESOURCE_STATUS.STORAGE, // Werkstatt
  40: RESOURCE_STATUS.STORAGE, // WERKSTATTLEITUNG

  // ON PROJECT
  30: RESOURCE_STATUS.ON_PROJECT, // Büro Projekt
  50: RESOURCE_STATUS.ON_PROJECT, // Install (ExHouse)
  42: RESOURCE_STATUS.ON_PROJECT, // Lager Projekt
  33: RESOURCE_STATUS.ON_PROJECT, // Termin (ExHouse)
  55: RESOURCE_STATUS.ON_PROJECT, // - GEBUCHT -"
  // Plus Jobs/Projects
} as const

export const STATUS_TRAVEL_AND_HOTEL = {
  NEEDED: 'needed',
  REQUESTED: 'requested',
  CONFIRMED: 'confirmed',
  COMMUNICATED: 'communicated',
} as const

export const STATUS_TRAVEL_AND_HOTEL_LOOKUP_LABEL = {
  [STATUS_TRAVEL_AND_HOTEL.NEEDED]: 'Wird benötigt',
  [STATUS_TRAVEL_AND_HOTEL.REQUESTED]: 'Angefragt',
  [STATUS_TRAVEL_AND_HOTEL.CONFIRMED]: 'Bestätigt',
  [STATUS_TRAVEL_AND_HOTEL.COMMUNICATED]: 'Bestätigt und kommuniziert',
} as const

export type StatusTravelAndHotel =
  (typeof STATUS_TRAVEL_AND_HOTEL)[keyof typeof STATUS_TRAVEL_AND_HOTEL]

export const STATUS_TRAVEL_AND_HOTEL_LOOKUP = {
  w: STATUS_TRAVEL_AND_HOTEL.NEEDED,
  '?': STATUS_TRAVEL_AND_HOTEL.REQUESTED,
  o: STATUS_TRAVEL_AND_HOTEL.CONFIRMED,
  '*': STATUS_TRAVEL_AND_HOTEL.COMMUNICATED,
} as const

export type StatusIdTravelAndHotel = keyof typeof STATUS_TRAVEL_AND_HOTEL_LOOKUP

/**
 * Status Resource Request Feedback
 * Status of feedback from requested resource
 */
export const STATUS_RESOURCE_REQUEST_FEEDBACK = {
  unknown: 'unknown',
  accept: 'accept',
  maybe: 'maybe',
  decline: 'decline',
  not_available: 'not_available',
} as const satisfies Record<ResourceRequestUserStatus, string>

export type StatusResourceRequestFeedback =
  (typeof STATUS_RESOURCE_REQUEST_FEEDBACK)[keyof typeof STATUS_RESOURCE_REQUEST_FEEDBACK]

export const STATUS_RESOURCE_REQUEST_FEEDBACK_LOOKUP_LABEL = {
  [STATUS_RESOURCE_REQUEST_FEEDBACK.unknown]: 'Nicht beantwortet',
  [STATUS_RESOURCE_REQUEST_FEEDBACK.accept]: 'Zugesagt',
  [STATUS_RESOURCE_REQUEST_FEEDBACK.maybe]: 'Teilweise zugesagt',
  [STATUS_RESOURCE_REQUEST_FEEDBACK.decline]: 'Abgesagt',
  [STATUS_RESOURCE_REQUEST_FEEDBACK.not_available]:
    'Nicht verfügbar (not available)',
} as const

/**
 * Status Resource Request
 * Status from ProMusik regarding the resource request
 */
export const STATUS_RESOURCE_REQUEST = {
  NOT_ANSWERED: 'notAnswered',
  ACCEPT: 'accept',
  DECLINE: 'decline',
  CANCEL: 'cancel',
  RESERVE: 'reserve',
} as const

export type StatusResourceRequest =
  (typeof STATUS_RESOURCE_REQUEST)[keyof typeof STATUS_RESOURCE_REQUEST]

export function assertStatusResourceRequest(
  value: Nilable<string>
): asserts value is StatusResourceRequest {
  if (isNil(value)) throw new Error('value is nil')

  const validOptions: string[] = Object.values(STATUS_RESOURCE_REQUEST)
  const isValid = value && validOptions.includes(value)

  if (!isValid) throw new Error('value is not valid')
}

export const STATUS_RESOURCE_REQUEST_LOOKUP_LABEL = {
  [STATUS_RESOURCE_REQUEST.NOT_ANSWERED]: 'Nicht beantwortet',
  [STATUS_RESOURCE_REQUEST.ACCEPT]: 'Zugesagt',
  [STATUS_RESOURCE_REQUEST.DECLINE]: 'Abgesagt',
  [STATUS_RESOURCE_REQUEST.CANCEL]: 'Abgebrochen',
  [STATUS_RESOURCE_REQUEST.RESERVE]: 'Reserviert',
} as const

/**
 * Status Webfleet
 *
 * Possible states:
 * - `draft` = preview before any order is created
 * - `created` = was created successfully
 * - `workInProgress` = transmission is in progress
 * - `updated` = an existing transmission was updated
 * - `aborted` = not transmitted because of an error
 *
 * @see https://gitlab.dev.innovation-agents.de/pro-musik/organisation/-/issues/124#note_19597
 */
export type WebfleetStatus = `${WebfleetState}`

export const STATUS_WEBFLEET_LOOKUP_LABEL = {
  draft: 'Entwurf',
  created: 'Erstellt',
  updated: 'Aktualisiert',
  workInProgress: 'In Bearbeitung',
  aborted: 'Abgebrochen/Fehler',
  deleted: 'Gelöscht',
} as const satisfies Record<WebfleetStatus, string>

export const DRAG_AND_DROP_TYPE = {
  CREATE_RESOURCE_ALLOCATION_ADDRESS: 'createResourceAllocationAddress',
  CREATE_RESOURCE_ALLOCATION_VEHICLE: 'createResourceAllocationVehicle',
  CHANGE_DAY_WIDTH: 'changeDayWidth',
  CHANGE_SIDEBAR_LEFT_WIDTH: 'changeLeftSidebarWidth',
  UPDATE_PROJECT_GROUP: 'updateProjectGroup',
} as const

export type DragAndDropType =
  (typeof DRAG_AND_DROP_TYPE)[keyof typeof DRAG_AND_DROP_TYPE]

export const DRAG_AND_DROP_TYPES = Object.values(DRAG_AND_DROP_TYPE)

export const DATA_MODAL_TYPE = {
  PROJECT: 'project',
  JOB: 'job',
  APPOINTMENT: 'appointment',
  RESOURCE: 'resource',
  RESOURCE_ALLOCATION: 'resourceAllocation',
  RESOURCE_REQUEST: 'resourceRequest',
} as const

export const PACKING_CONTAINER_ID = {
  CALENDAR_JOBS: 'CalendarJobs',
  CALENDAR_OPERATIONS: 'CalendarOperations',
  APPOINTMENTS_CONTAINER: 'AppointmentsContainer',
} as const

export type PackingContainerId =
  (typeof PACKING_CONTAINER_ID)[keyof typeof PACKING_CONTAINER_ID]

export const CALENDAR_ITEM_TYPE = {
  PROJECT: 'project',
  JOB: 'job',
  STOCK_TYPE_SERVICE: 'stockTypeService',
} as const

export type CalendarItemType =
  (typeof CALENDAR_ITEM_TYPE)[keyof typeof CALENDAR_ITEM_TYPE]

export const RESOURCE_DAY_ITEM_TYPE = {
  RESOURCE_ALLOCATION: 'resourceAllocation',
  RESOURCE_STATE: 'resourceState',
  EVENT: 'event',
  CALENDAR_EVENT: 'calendarEvent',
} as const

export type ResourceDayItemType =
  (typeof RESOURCE_DAY_ITEM_TYPE)[keyof typeof RESOURCE_DAY_ITEM_TYPE]

export const VIEW_SETTING_MODE = {
  EXPLICIT: 'explicit',
  EXCLUDE: 'exclude',
} as const

export const RESOURCE_ALLOCATION_HIGHLIGHT_REASON = {
  SCROLL_INTO_VIEW: 'scrollIntoView',
  VEHICLE_DRIVER_CONNECTION: 'vehicleDriverConnection',
} as const

export const RESOURCE_STATE_TYPE_IDS_FILTERED_OUT = [
  27, // Anderes Fahrzeug gelenkt
  20, // Freelancer
  26, // Ruhezeit
  21, // Sonstige dienstliche Gründe
] as const

export const ALLOWED_RESOURCE_STATE_TYPE_IDS_FREELANCERS = [
  28, // - nicht verfügbar -
  53, // - verfügbar -
  54, // - optioniert -
  55, // - GEBUCHT -
] as const

export const VISIBLE_RESOURCE_STATE_TYPE_IDS_FREELANCERS = [
  28, // - nicht verfügbar -
  53, // - verfügbar -
  54, // - optioniert -
  55, // - GEBUCHT -
] as const

export type Shortcut = {
  readonly label: string
  readonly start: number
  readonly end: number
  readonly isVisibleOnMobile: boolean
}

export const SHORTCUTS_VISIBLE_TIMEFRAME: Shortcut[] = [
  {
    label: '-30 Tage',
    start: -30,
    end: 1,
    isVisibleOnMobile: false,
  },
  {
    label: '-14 Tage',
    start: -14,
    end: 1,
    isVisibleOnMobile: true,
  },
  {
    label: '-7 Tage',
    start: -7,
    end: 1,
    isVisibleOnMobile: true,
  },
  {
    label: '-3 Tage',
    start: -3,
    end: 1,
    isVisibleOnMobile: true,
  },
  {
    label: '+3 Tage',
    start: -1,
    end: 3,
    isVisibleOnMobile: true,
  },
  {
    label: '+7 Tage',
    start: -1,
    end: 7,
    isVisibleOnMobile: true,
  },
  {
    label: '+14 Tage',
    start: -1,
    end: 14,
    isVisibleOnMobile: true,
  },
  {
    label: '+30 Tage',
    start: -1,
    end: 30,
    isVisibleOnMobile: false,
  },
]

/** Maximum number of days to show conflicts and resource dayfor */
export const RESOURCE_DAY_MAX_DAYS = 5
