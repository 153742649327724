<template>
  <div class="PmEventPure">
    <PmPillPure
      ref="referenceElement"
      class="PmEventPure-pill"
      :label="name"
      :color="color"
      :arrow-start="isConstrainedStart"
      :arrow-end="isConstrainedEnd"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @click="onClick"
    />

    <portal to="popover">
      <PmOnClickOutsidePure :disabled="!popoverVisible" :do="maybeClose">
        <PmPopoverWithTransitionPure
          :is-visible="popoverVisible"
          :is-positioned-on="whatInput.isTouch ? 'element' : 'pointer'"
          :element="referenceElement"
          :is-close-button-visible="whatInput.isTouch"
          @close="popoverVisible = false"
        >
          <PmPopoverContentDefaultPure
            :title="name"
            :subtitle="description"
            :text="text"
            :with-close-button="whatInput.isTouch"
          />
        </PmPopoverWithTransitionPure>
      </PmOnClickOutsidePure>
    </portal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { whatInputStateKey, injectStrict } from '@/utilities/inject'

import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmPopoverWithTransitionPure from '@/components/basics/PmPopover/PmPopoverWithTransitionPure.vue'
import PmPopoverContentDefaultPure from '@/components/basics/PmPopoverContentDefaultPure.vue'
import PmOnClickOutsidePure from '@/components/utilities/PmOnClickOutsidePure.vue'

export default defineComponent({
  name: 'PmEventPure',
  components: {
    PmPillPure,
    PmPopoverWithTransitionPure,
    PmPopoverContentDefaultPure,
    PmOnClickOutsidePure,
  },

  props: {
    name: { type: String, required: true },
    description: { type: String, default: undefined },
    text: { type: [String, Array], default: () => [] },
    color: { type: String, default: undefined },
    isConstrainedStart: { type: Boolean, default: undefined },
    isConstrainedEnd: { type: Boolean, default: undefined },
  },

  setup() {
    const whatInput = injectStrict(whatInputStateKey)

    return {
      whatInput,
    }
  },

  data() {
    return {
      popoverVisible: false,
      referenceElement: undefined,
    }
  },

  mounted() {
    this.referenceElement = this.$refs.referenceElement.$el
  },

  methods: {
    onMouseEnter() {
      if (this.whatInput.isTouch) return
      this.open()
    },

    onMouseLeave() {
      if (this.whatInput.isTouch) return
      this.close()
    },

    async onClick() {
      if (!this.whatInput.isTouch) return
      this.popoverVisible ? this.close() : this.openByClick()
    },

    open() {
      this.popoverVisible = true
    },

    openByClick() {
      this.popoverVisible = true
    },

    close() {
      this.popoverVisible = false
    },

    maybeClose() {
      if (!this.whatInput.isTouch) return
      this.close()
    },
  },
})
</script>

<style lang="scss">
.PmEventPure {
  $block: &;

  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;

  &-pill {
    width: 100%;
  }
}
</style>
