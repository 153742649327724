import { createMachine } from 'xstate'

import type { Typegen0 } from './PmViewUpdateState.typegen'

export type TState = Typegen0['matchesStates']

export const PmViewUpdateState = createMachine({
  tsTypes: {} as import('./PmViewUpdateState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'UPDATE' },

    services: {} as {
      updateView: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmViewUpdateState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        UPDATE: 'update',
      },
    },

    update: {
      initial: 'updating',
      states: {
        updating: {
          invoke: {
            id: 'update-view',
            src: 'updateView',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },

            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {
          on: {
            UPDATE: 'updating',
          },
        },
      },
    },
  },
})
