<template>
  <PmRequestDetailPure
    :id="id"
    :details="details"
    :is-loading="isLoading"
    :type="type"
    @close="emit('close')"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Get } from 'type-fest'

import PmRequestDetailPure, {
  type Props as PropsMyRequestsDetailPure,
} from '@/components/PmRequestDetail/PmRequestDetailPure.vue'
import {
  LEAVE_REQUEST_STATUS_LOOKUP,
  LEAVE_REQUEST_TYPE_LABEL_LOOKUP,
  type LeaveRequestStatus,
} from '@/constants/leaveRequest'
import { useQuery } from '@vue/apollo-composable'
import { parseServerDateString } from '@/utilities/date'
import {
  LeaveRequestDetailsDocument,
  type LeaveRequestDetailsQuery,
  type LeaveRequestState,
  LeaveRequestAction,
  ExternalServiceRequestDetailsDocument,
  type ExternalServiceRequestDetailsQuery,
} from '@/../generated/graphql'
import type { Nilable } from '@/types/misc'

export interface Props {
  id: number
  type: 'leaveRequest' | 'externalServiceRequest'
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
}>()

const leaveRequestDetailsQuery = useQuery(
  LeaveRequestDetailsDocument,
  () => ({
    id: props.id,
  }),
  () => {
    return {
      enabled: props.type === 'leaveRequest',
      fetchPolicy: 'cache-and-network',
    }
  }
)

const externalServiceRequestDetailsQuery = useQuery(
  ExternalServiceRequestDetailsDocument,
  () => ({
    id: props.id,
  }),
  () => {
    return {
      enabled: props.type === 'externalServiceRequest',
      fetchPolicy: 'cache-and-network',
    }
  }
)

const isLoading = computed(() => {
  if (props.type === 'leaveRequest')
    return leaveRequestDetailsQuery.loading.value
  if (props.type === 'externalServiceRequest')
    return externalServiceRequestDetailsQuery.loading.value

  return false
})

const details = computed(() => {
  if (props.type === 'leaveRequest') return normalizeLeaveRequest()
  if (props.type === 'externalServiceRequest')
    return normalizeExternalServiceRequest()

  throw new Error(`normalize ${props.type} is not implemented`)
})

/**
 * Normalize Leave Request
 */
function normalizeLeaveRequest() {
  const leaveRequest = leaveRequestDetailsQuery.result.value?.leaveRequest
  if (!leaveRequest) return

  const startDate = parseServerDateString(leaveRequest.startDate)
  const endDate = parseServerDateString(leaveRequest.endDate)

  if (!startDate || !endDate)
    throw new Error('startDate or endDate is undefined')

  const result: PropsMyRequestsDetailPure['details'] = {
    type: leaveRequest.type,
    name: leaveRequest.user.fullName,
    startDate,
    endDate,
    status: LEAVE_REQUEST_STATUS_LOOKUP[leaveRequest.state],
  }

  // Normalize communication
  const communication: Get<PropsMyRequestsDetailPure, 'details.communication'> =
    []

  leaveRequest.communications?.forEach((communicationsItem) => {
    const communicationItemNormalized =
      normalizeCommunicationItem(communicationsItem)

    if (!communicationItemNormalized) return
    communication.push(communicationItemNormalized)
  })

  result.communication = communication

  return result
}

/**
 * Normalize External Service Reqeust
 */
function normalizeExternalServiceRequest() {
  const externalServiceRequest =
    externalServiceRequestDetailsQuery.result.value?.externalServiceRequest
  if (!externalServiceRequest) return

  const startDate = parseServerDateString(externalServiceRequest.startDate)
  const endDate = parseServerDateString(externalServiceRequest.endDate)

  if (!startDate || !endDate)
    throw new Error('startDate or endDate is undefined')

  const result: PropsMyRequestsDetailPure['details'] = {
    name: externalServiceRequest.user.fullName,
    startDate,
    endDate,
    status: LEAVE_REQUEST_STATUS_LOOKUP[externalServiceRequest.state],
  }

  // Normalize communication
  const communication: Get<PropsMyRequestsDetailPure, 'details.communication'> =
    []

  externalServiceRequest.communications?.forEach((communicationsItem) => {
    const communicationItemNormalized =
      normalizeCommunicationItem(communicationsItem)

    if (!communicationItemNormalized) return
    communication.push(communicationItemNormalized)
  })

  result.communication = communication

  return result
}

type CommunicationItemNormalized = Get<
  PropsMyRequestsDetailPure,
  'details.communication[0]'
>

type LeaveRequestCommunicationItem = Get<
  LeaveRequestDetailsQuery,
  'leaveRequest.communications[0]'
>

type ExternalServiceRequestCommunicationItem = Get<
  ExternalServiceRequestDetailsQuery,
  'externalServiceRequest.communications[0]'
>

function addName(message: string | null, name: Nilable<string>) {
  if (!message && name) return `von ${name}`
  if (name) return `${message} von ${name}`
  return message ?? undefined
}

function normalizeLabel(status: `${LeaveRequestState}`, name: Nilable<string>) {
  if (!status) return
  if (status === 'created') return addName('Antrag erstellt', name)
  if (status === 'aborted') return addName(null, name)
  if (status === 'updated') return addName(null, name)
  if (status === 'accepted') return addName(null, name)
  if (status === 'declined') return addName(null, name)
}

const LOOKUP_STATUS_COMMUNICATION = {
  accept: 'approved',
  decline: 'rejected',
  abort: 'aborted',
} as const satisfies Partial<
  Record<`${LeaveRequestAction}`, LeaveRequestStatus>
>

function normalizeCommunicationItem(
  data: LeaveRequestCommunicationItem | ExternalServiceRequestCommunicationItem
) {
  if (!data) return

  const date = parseServerDateString(data.timestamp)
  if (!date) throw new Error('date is undefined')

  const result: CommunicationItemNormalized = {
    status: LOOKUP_STATUS_COMMUNICATION[data.action],
    label: normalizeLabel(data.newState, data.user.fullName),
    date,
    note: data.comment,
  }

  if (data.__typename === 'LeaveRequestCommunication') {
    if (data.previousType) result.typeBefore = data.previousType
    if (data.newType) result.typeAfter = data.newType
  }

  return result
}
</script>
