import { EVENT } from '@/constants/events'

import EventHub from '@/eventHub'

/**
 * Event when packing is finished
 *
 * @param {String} id
 */
export function emitPackingFinished(id) {
  if (!id) throw new Error('id not defined')

  EventHub.$emit(EVENT.PACKING_FINISHED, { id: id })
}
