import store from '@/store'

export function scrollToElement(
  element?: HTMLElement,
  options:
    | {
        considerSidebarLeft?: boolean
        considerHeader?: boolean
        considerNavigation?: boolean
        scrollAxis?: 'x' | 'y' | 'both'
        offsetX?: number
        offsetY?: number
      }
    | undefined = {}
) {
  if (!element) return
  if (!document.scrollingElement) return

  const normalizedOptions = {
    considerSidebarLeft: true,
    considerHeader: true,
    considerNavigation: true,
    scrollAxis: 'y',
    offsetX: 0,
    offsetY: 0,
    ...options,
  }
  // Make sure options are not used:
  options = undefined

  const rect = element.getBoundingClientRect()

  const sidebarLeftWidthWithVisibility = normalizedOptions.considerSidebarLeft
    ? store.getters['cssVar/get']('sidebarLeftWidthWithVisibility').value
    : 0

  const navigationHeight = normalizedOptions.considerNavigation
    ? store.getters['cssVar/get']('navigationHeight').value
    : 0

  const headerHeight = normalizedOptions.considerHeader
    ? store.getters['cssVar/get']('PmCalendarPure-headerHeight').value
    : 0

  let scrollX: number | undefined
  let scrollY: number | undefined

  if (
    normalizedOptions.scrollAxis === 'x' ||
    normalizedOptions.scrollAxis === 'both'
  ) {
    scrollX =
      rect.x +
      document.scrollingElement.scrollLeft -
      sidebarLeftWidthWithVisibility +
      normalizedOptions.offsetX
  }

  if (
    normalizedOptions.scrollAxis === 'y' ||
    normalizedOptions.scrollAxis === 'both'
  ) {
    scrollY =
      rect.y +
      document.scrollingElement.scrollTop -
      navigationHeight -
      headerHeight -
      normalizedOptions.offsetY
  }

  document.scrollingElement.scrollTo({
    left: scrollX,
    top: scrollY,
    behavior: 'smooth',
  })
}
