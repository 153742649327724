import { createMachine } from 'xstate'

import type { Typegen0 } from './PmAccountState.typegen'

export type TState = Typegen0['matchesStates']

export const PmAccountState = createMachine({
  tsTypes: {} as import('./PmAccountState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'CHANGE_PASSWORD' } | { type: 'BACK' },
  },

  id: 'PmAccountState',
  initial: 'overview',

  states: {
    overview: {
      on: {
        CHANGE_PASSWORD: 'changePassword',
      },
    },

    changePassword: {
      on: {
        BACK: 'overview',
      },
    },
  },
})
