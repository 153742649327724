<template>
  <tr class="DataModalRow" :class="classes">
    <td class="DataModalRow-label">{{ label }}:</td>
    <td class="DataModalRow-content"><slot /></td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import type { PropType } from 'vue'

export const propTypes = {
  variant: {
    allowed: ['default', 'debug'] as const,
    default: 'default',
  },
}

export interface Props {
  label: string
  variant: (typeof propTypes.variant.allowed)[number]
}

const COMPONENT_NAME = 'DataModalRow'
export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    label: { type: String, required: true },
    variant: {
      type: String as PropType<Props['variant']>,
      default: propTypes.variant.default,
    },
  },

  setup(props) {
    const classes = computed(() => {
      return {
        [`${COMPONENT_NAME}--variantDebug`]: props.variant === 'debug',
      }
    })

    return {
      classes,
    }
  },
})
</script>

<style lang="scss">
.DataModal {
  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  & b {
    font-weight: bold !important;
  }
}

.DataModalRow {
  $block: &;

  &--variantDebug {
    color: color.$gray-400;
  }

  &-label {
    font-weight: bold;
  }

  &-content {
    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    & b {
      font-weight: bold !important;
    }

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    & p {
      margin-bottom: 1em;
    }
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  button {
    cursor: pointer;
    color: currencolor;

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    &:hover {
      text-decoration: underline;
    }
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  & .PmPillPure {
    margin-right: 4px;
  }
}
</style>
