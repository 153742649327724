<template>
  <div class="PmPopoverContentDefaultPure" :class="classes.root">
    <div class="PmPopoverContentDefaultPure-header">
      <div v-if="title" class="PmPopoverContentDefaultPure-title">
        {{ title }}
      </div>

      <div v-if="subtitle" class="PmPopoverContentDefaultPure-subtitle">
        {{ subtitle }}
      </div>

      <slot name="header" />
    </div>

    <div class="PmPopoverContentDefaultPure-content">
      <div
        v-if="normalizedText.length"
        class="PmPopoverContentDefaultPure-textContainer"
      >
        <div
          v-for="textItem in normalizedText"
          :key="textItem"
          class="PmPopoverContentDefaultPure-text"
        >
          {{ textItem }}
        </div>
      </div>

      <div v-if="$slots.default" class="PmPopoverContentDefaultPure-slot">
        <slot />
      </div>

      <div v-if="help" class="PmPopoverContentDefaultPure-help">{{ help }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmPopoverContentDefaultPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  title?: Nilable<string>
  subtitle?: Nilable<string>
  text?: string | string[]
  variant?: 'small'
  help?: string

  withCloseButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{}>()

const classes = computed(() => {
  return {
    root: {
      [`${COMPONENT_NAME}--small`]: props.variant === 'small',
      [`${COMPONENT_NAME}--withCloseButton`]: props.withCloseButton === true,
    },
  }
})

const normalizedText = computed(() => {
  if (Array.isArray(props.text)) {
    return props.text
  }

  if (!props.text) return []
  return [props.text]
})
</script>

<style lang="scss">
.PmPopoverContentDefaultPure {
  $block: &;

  @include cssVar.define($block, 'spaceHeader', 0);

  &--withCloseButton {
    @include cssVar.define($block, 'spaceHeader', 30px);
  }

  &-header {
    padding-right: cssVar.use($block, 'spaceHeader');
    margin-bottom: 12px;
  }

  &-title {
    font-weight: 500;
    font-size: constant.$fontSize-large;
    margin-right: auto;

    #{$block}--small & {
      font-size: constant.$fontSize-default;
    }
  }

  &-subtitle {
    margin-top: 2px;
    font-size: constant.$fontSize-default;
  }

  &-content {
    // Empty
  }

  &-textContainer {
    font-size: constant.$fontSize-default;
    color: color.$gray-600;
    margin-top: 8px;
  }

  &-slot {
    // Empty
  }

  &-help {
    font-size: constant.$fontSize-default;
    font-weight: 500;
    color: color.$gray-500;
    grid-area: note;
    margin-top: 8px;
  }
}
</style>
