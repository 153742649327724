<template>
  <div class="PmDayWidthController">
    <PmDraggablePure
      :type="DRAG_AND_DROP_TYPE.CHANGE_DAY_WIDTH"
      :use-placeholder="false"
      :can-be-dropped="false"
      :use-mirror="false"
      constrain="x"
      class="PmDayWidthController-dragAndDrop"
      @drag-start="onDragStart"
      @drag-move="onDragMove"
      @drag-end="onDragEnd"
    >
      <PmDayWidthControllerPure class="PmDayWidthController-controller" />
    </PmDraggablePure>
  </div>
</template>

<script>
import { DRAG_AND_DROP_TYPE } from '@/constants/persoplan'

import PmDayWidthControllerPure from '@/components/persoplan/PmDayWidthController/PmDayWidthControllerPure.vue'
import PmDraggablePure from '@/components/utilities/PmDraggablePure.vue'

export default {
  name: 'PmDayWidthController',
  components: {
    PmDayWidthControllerPure,
    PmDraggablePure,
  },

  props: {
    index: { type: Number, default: 0 },
  },

  setup() {
    return {
      DRAG_AND_DROP_TYPE,
    }
  },

  data() {
    return {
      initialDayWidth: undefined,
      minDayWidth: undefined,
      maxDayWidth: undefined,
      intermediateDayWidth: undefined,
      isDragging: false,
    }
  },

  methods: {
    onDragStart() {
      const variable = this.$store.getters['cssVar/get']('dayWidth')
      this.initialDayWidth = variable.value
      this.minDayWidth = variable.min
      this.maxDayWidth = variable.max

      this.isDragging = true
    },

    onDragMove({ delta }) {
      this.intermediateDayWidth =
        this.initialDayWidth + delta.x / (this.index + 1)

      if (this.intermediateDayWidth < this.minDayWidth) {
        this.intermediateDayWidth = this.minDayWidth
      }

      if (this.intermediateDayWidth > this.maxDayWidth) {
        this.intermediateDayWidth = this.maxDayWidth
      }

      window.requestAnimationFrame(() => {
        if (!this.isDragging) return

        document.documentElement.style.setProperty(
          `--dayWidth`,
          `${this.intermediateDayWidth}px`
        )
      })
    },

    onDragEnd() {
      this.isDragging = false

      this.$store.commit('cssVar/set', {
        name: 'dayWidth',
        value: this.intermediateDayWidth,
      })

      this.initialDayWidth = null
      this.minDayWidth = null
      this.maxDayWidth = null
      this.intermediateDayWidth = null
    },
  },
}
</script>

<style lang="scss">
.PmDayWidthController {
  $block: &;
}
</style>
