<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('inputContainer')">
      <template v-if="isCalendarVisible">
        <PmInputPure
          v-model:value="inputValue"
          :class="[
            componentClass.element('input'),
            componentClass.className({ element: 'input', modifier: 'date' }),
          ]"
          :error="hasErrorNormalized"
          :error-message="errorLocal"
          v-bind="separateAttrs($attrs).attributes"
          type="text"
          :label="label"
          :note="NOTE"
          :disabled="isDisabledNormalized"
          no-tabindex="1"
          :required="required"
          :icon="ICONS.CALENDAR"
          @blur="parseDate"
          @keydown.enter="parseDateAndConfirm"
          @keydown.up.prevent="incrementDay"
          @keydown.down.prevent="decrementDay"
        />
      </template>

      <template v-if="!isCalendarVisible">
        <PmInputPure
          :class="[
            componentClass.element('input'),
            componentClass.className({ element: 'input', modifier: 'date' }),
          ]"
          :error="hasErrorNormalized"
          :error-message="errorLocal"
          v-bind="separateAttrs($attrs).attributes"
          type="date"
          :label="label"
          :disabled="isDisabledNormalized"
          :required="required"
          :value="value"
          :icon="ICONS.CALENDAR"
          @input="onInputNative"
        />
      </template>

      <PmInputPure
        v-if="withTime"
        type="time"
        label="Uhrzeit"
        :value="value"
        :class="[
          componentClass.element('input'),
          componentClass.className({ element: 'input', modifier: 'time' }),
        ]"
        :disabled="isDisabledNormalized"
        @input="emitTime"
        @keydown.enter="parseDateAndConfirm"
      />
    </div>

    <PmCalendarWidgetPure
      v-if="isCalendarVisible"
      :date="date"
      :selected-date="value"
      :range-start-date="rangeStartDate"
      :range-end-date="rangeEndDate"
      :range="range"
      :disabled="isDisabledNormalized"
      @show-next-month="showNextMonth"
      @show-previous-month="showPreviousMonth"
      @select-date="selectDate"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  format,
  subDays,
  isValid,
  addDays,
  addMonths,
  subMonths,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
} from 'date-fns'

/**
 * Sugar is not maintained anymore
 * Alternatives are
 * chrono: https://github.com/wanasit/chrono
 * sherlock: https://github.com/neilgupta/Sherlock
 */
// @ts-ignore sugar is not typed
import Sugar from 'sugar-date'
import { useComponentClass } from '@thomasaull-shared/composables'

import { FORMAT_DATE_DEFAULT } from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'
import { separateAttrs } from '@/utilities/misc'
import { whatInputStateKey, injectStrict } from '@/utilities/inject'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmCalendarWidgetPure, {
  type Props as PropsCalendarWidgetPure,
} from '@/components/PmCalendarWidget/PmCalendarWidgetPure.vue'
import type { Nilable } from '@/types/misc'
import { useForm } from '@/composition/useForm'

const NOTE = `Format: "01.08.2017" oder "2017-08-01`

export interface Props {
  value?: Date
  rangeStartDate?: Date
  rangeEndDate?: Date
  range?: PropsCalendarWidgetPure['range']
  hasError?: boolean
  disabled?: boolean
  required?: boolean
  withTime?: boolean
  label?: string
}

const props = withDefaults(defineProps<Props>(), {
  value: () => new Date(),
  label: 'Datum',
})

const emit = defineEmits<{
  input: [Date]
  'update:value': [Date]
  confirm: []
}>()

Sugar.Date.setLocale('de')
const componentClass = useComponentClass()
const whatInput = injectStrict(whatInputStateKey)
const date = ref(new Date(props.value.getTime()))
const errorLocal = ref()

// Update visible calendar month when value was updated
watch(
  () => props.value,
  () => {
    date.value = new Date(props.value.getTime())
  }
)

function showNextMonth() {
  date.value = addMonths(date.value, 1)
}

function showPreviousMonth() {
  date.value = subMonths(date.value, 1)
}

function selectDate({ date }: { date: Date }) {
  emitInput(date, { type: 'date' })
}

function emitInput(value: Date, options: { type: 'date' | 'time' }) {
  // Use time from existing value
  if (options.type === 'date') {
    const hours = getHours(props.value)
    const minutes = getMinutes(props.value)

    value = setHours(value, hours)
    value = setMinutes(value, minutes)
  }

  emit('input', value)
  emit('update:value', value)
}

function emitTime(date: Nilable<Date | string | number>) {
  if (!date) return
  if (!(date instanceof Date)) {
    throw new Error('value needs to be a Date')
  }

  // Just use the time component of the Date
  const hours = getHours(date)
  const minutes = getMinutes(date)

  let newDate = new Date(props.value)
  newDate = setHours(newDate, hours)
  newDate = setMinutes(newDate, minutes)

  emitInput(newDate, { type: 'time' })
}

const formattedDate = computed(() => {
  return format(props.value, FORMAT_DATE_DEFAULT)
})

const inputValue = ref<string | undefined>(formattedDate.value)

const hasErrorNormalized = computed(() => {
  return errorLocal.value || props.hasError ? true : false
})

const isCalendarVisible = computed(() => {
  return whatInput.isTouch === false
})

/**
 * Disabled state
 */
const form = useForm()
const isDisabledNormalized = computed(() => {
  if (props.disabled) return true
  if (form.disabled?.value) return true
  return undefined
})

watch(
  () => props.value,
  () => {
    inputValue.value = formattedDate.value
    errorLocal.value = undefined
  }
)

function parseDate(event: Event) {
  errorLocal.value = undefined
  inputValue.value = undefined

  if (!(event.target instanceof HTMLInputElement)) {
    throw new Error('event.target is not HTMLInputElement')
  }

  const date = Sugar.Date.create(event.target.value)

  // ERROR
  if (!isValid(date)) {
    console.warn('date is not valid')
    errorLocal.value = 'Ungültiges Datum'
    inputValue.value = event.target.value
    event.target.select()

    return
  }

  emitInput(date, { type: 'date' })
}

function parseDateAndConfirm(event: Event) {
  parseDate(event)
  emit('confirm')
}

function incrementDay() {
  const newDate = addDays(props.value, 1)
  emitInput(newDate, { type: 'date' })
}

function decrementDay() {
  const newDate = subDays(props.value, 1)
  emitInput(newDate, { type: 'date' })
}

function onInputNative(date: Nilable<Date>) {
  if (!date) return
  emitInput(date, { type: 'date' })
}
</script>

<style lang="scss">
.PmDatePickerPure {
  $block: &;

  &-inputContainer {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    gap: var(--space-gutters);
  }

  &-input {
    &--date {
      flex-basis: 80%;
      flex-grow: 1;
    }

    &--time {
      flex-basis: 20%;
    }
  }
}
</style>
