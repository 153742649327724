<template>
  <PmAppNotificationPure
    :variant="variant"
    :title="title"
    @delete="$emit('delete')"
    @cancel="$emit('cancel')"
  >
    <slot />

    <template #actions>
      <PmButtonPure
        label="Abbrechen"
        variant="secondary"
        :disabled="isLoading"
        @click="$emit('cancel')"
      />

      <PmButtonPure
        label="Ja, löschen"
        :variant="variant"
        :loading="isLoading"
        @click="$emit('delete')"
      />
    </template>
  </PmAppNotificationPure>
</template>

<script>
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

export const propTypes = {
  variant: {
    allowed: ['success', 'warning', 'danger'],
  },
}

export default {
  name: 'PmResourceAllocationDeletePure',
  components: {
    PmAppNotificationPure,
    PmButtonPure,
  },

  props: {
    title: { type: String, required: true },
    isLoading: { type: Boolean, default: undefined },
    variant: { type: String, default: 'danger' },
  },

  emits: ['delete', 'cancel'],
}
</script>
