<template>
  <PmSidebarSectionPure
    class="PmSidebarBirthdays"
    title="Geburtstage"
    :can-be-collapsed="true"
    :collapsed="collapsableArea.isCollapsed.value"
    @expand="collapsableArea.expand"
    @collapse="collapsableArea.collapse"
  >
    <div class="PmSidebarBirthdays-spacer"></div>
  </PmSidebarSectionPure>
</template>

<script>
import PmSidebarSectionPure from '@/components/persoplan/PmSidebarSection/PmSidebarSectionPure.vue'

import { useCollapsableArea } from '@/composition/useCollapsableArea'

export default {
  name: 'PmSidebarBirthdays',
  components: {
    PmSidebarSectionPure,
  },

  setup() {
    const collapsableArea = useCollapsableArea({ id: 'birthdays' })
    return {
      collapsableArea,
    }
  },
}
</script>

<style lang="scss">
.PmSidebarBirthdays {
  $block: &;

  &-spacer {
    min-height: calc(var(--birthdaysHeight) - 41px);
  }
}
</style>
