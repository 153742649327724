<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('input')">
      <PmDatePickerPure
        v-model:value="startDate"
        label="Anfang"
        :range-start-date="startDate"
        :range-end-date="endDate"
        :has-error="hasError"
        :required="true"
        :with-time="withTime"
        @confirm="onConfirm"
      />

      <PmDatePickerPure
        v-model:value="endDate"
        label="Ende"
        range="start"
        :range-start-date="startDate"
        :range-end-date="endDate"
        :has-error="hasError"
        :required="true"
        :with-time="withTime"
        @confirm="onConfirm"
      />
    </div>

    <div
      v-if="hasNotifications"
      :class="componentClass.element('notifications')"
    >
      <PmErrorNotificationPure v-if="localError" :message="localError" />
      <slot name="notifications" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, nextTick, watch } from 'vue'
import {
  differenceInCalendarDays,
  addDays,
  setHours,
  setMinutes,
  isBefore,
  isSameDay,
} from 'date-fns'
import { useComponentClass } from '@thomasaull-shared/composables'

import PmDatePickerPure from '@/components/basics/PmDatePicker/PmDatePickerPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import { useHasSlotContent } from '@/composition/useHasSlotContent'

export interface Props {
  value?: {
    start: Date
    end: Date
  }
  error?: string
  withTime?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: () => ({
    start: new Date(),
    end: addDays(new Date(), 0),
  }),
})

export type Emits = {
  input: {
    start: Date
    end: Date
  }
  'update:value': {
    start: Date
    end: Date
  }
}

const emit = defineEmits<{
  confirm: []
  input: [Emits['input']]
  'update:value': [Emits['update:value']]
  valid: []
  invalid: []
}>()

const componentClass = useComponentClass()
const hasSlotContent = useHasSlotContent(['notifications'])
const localError = ref<string>()

const startDate = computed({
  get() {
    return props.value.start
  },

  set(date: Date) {
    // move end date aswell, but keep time
    const hours = props.value.end.getHours()
    const minutes = props.value.end.getMinutes()
    let endDate = addDays(date, rangeInDays.value)
    endDate = setHours(endDate, hours)
    endDate = setMinutes(endDate, minutes)

    emitInput({
      start: date,
      end: endDate,
    })
  },
})

const endDate = computed({
  get() {
    return props.value.end
  },

  set(date: Date) {
    emitInput({ ...props.value, end: date })
  },
})

const rangeInDays = computed(() => {
  return differenceInCalendarDays(endDate.value, startDate.value)
})

function emitInput(value: { start: Date; end: Date }) {
  emit('input', value)
  emit('update:value', value)
}

/**
 * Validate that endDate is after startDate
 */
watch(() => props.value, validate, { deep: true })

async function validate() {
  await nextTick()

  const isStartBeforeEnd = checkIfStartIsBeforeEnd()
  if (!isStartBeforeEnd) {
    localError.value = 'Das Startdatum muss vor dem Enddatum liegen!'
    emit('invalid')
    return false
  }

  localError.value = undefined
  emit('valid')
  return true
}

function checkIfStartIsBeforeEnd() {
  const isStartBeforeEnd = isBefore(props.value.start, props.value.end)
  const isStartAndEndOnSameDay = isSameDay(props.value.start, props.value.end)

  if (props.withTime) {
    return isStartBeforeEnd
  }

  /**
   * When time is not used, we can ignore it if the end ise before the start
   * when it's on the same day anyway
   */
  if (isStartBeforeEnd === false) return isStartAndEndOnSameDay
  return true
}

const hasError = computed(() => {
  if (props.error) return true
  if (localError.value) return true
  return false
})

async function onConfirm() {
  const isValid = await validate()
  if (!isValid) return
  emit('confirm')
}

const hasNotifications = computed(() => {
  if (hasError.value) return true
  if (hasSlotContent.value.notifications) return true
  return false
})
</script>

<style lang="scss">
.PmDateRangePickerPure {
  $block: &;

  &-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    @include mixin.media('<=800px') {
      grid-template-columns: auto;
      grid-gap: 10px;
    }
  }

  &-notifications {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--space-gutters);
  }
}
</style>
