<template>
  <div class="PmSidebarSectionPure" :class="classes">
    <div
      class="PmSidebarSectionPure-header"
      :title="title"
      :data-id="id"
      @click.alt.exact="toggleAlt"
      @click.exact="toggle"
    >
      <div class="PmSidebarSectionPure-title">
        {{ title }}
      </div>

      <PmButtonPure
        v-if="hasAction"
        class="PmSidebarSectionPure-action"
        :title="actionTitle"
        :icon="actionIcon"
        variant="secondary"
        alternative="ghost"
        @click.prevent.stop="$emit('executeAction')"
        @mouseenter="isHeaderDisabled = true"
        @mouseleave="isHeaderDisabled = false"
      />

      <div v-if="canBeCollapsed" class="PmSidebarSectionPure-toggle">
        <PmIconPure
          :name="ICONS.ARROW_DOWN"
          class="PmSidebarSectionPure-toggleIcon"
        />
      </div>
    </div>

    <template v-if="!isCollapsedNormalized">
      <div v-if="!$slots.default" class="PmSidebarSectionPure-empty">
        Kein {{ title }} vorhanden
      </div>

      <div v-if="$slots.default" class="PmSidebarSectionPure-content">
        <slot />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarSectionPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'

import { ICONS, type Icon } from '@/constants/icons'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

export interface Props {
  title?: string
  id?: number
  collapsed?: boolean
  markWhenCollapsed?: boolean
  canBeCollapsed?: boolean
  hasAction?: boolean
  actionTitle?: string
  actionIcon?: Icon
}

const props = withDefaults(defineProps<Props>(), {
  canBeCollapsed: true,
  withLine: true,
})

const emit = defineEmits<{
  (event: 'toggle', collapsed: boolean): void
  (event: 'update:collapsed', collapsed: boolean): void
  (event: 'collapse'): void
  (event: 'expand'): void
  (event: 'executeAction'): void
}>()

onMounted(() => {
  // Do initial checks when hasAction is true
  if (props.hasAction === true) {
    if (!props.actionTitle) throw new Error('You need to define an actionTitle')
    if (!props.actionIcon) throw new Error('You need to define an actionTitle')
  }
})

const isHeaderDisabled = ref(false)

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--markWhenCollapsed`]: props.markWhenCollapsed,
    [`${COMPONENT_NAME}--canNotBeCollapsed`]: props.canBeCollapsed === false,
    'is-expanded': !props.collapsed,
    'is-collapsed': props.collapsed,
    'is-headerDisabled': isHeaderDisabled.value,
  }
})

const isCollapsedNormalized = computed(() => {
  if (!props.canBeCollapsed) return false
  return props.collapsed
})

function toggle() {
  if (!props.canBeCollapsed) return

  emit('toggle', props.collapsed ? false : true)
  emit('update:collapsed', props.collapsed ? false : true)

  if (props.collapsed) expand()
  else collapse()
}

function expand() {
  if (!props.canBeCollapsed) return
  emit('expand')
}

function collapse() {
  if (!props.canBeCollapsed) return
  emit('collapse')
}

function toggleAlt() {
  if (!props.canBeCollapsed) return
  // TODO:
  // this.collapsed ? this.$emit('expandAll') : this.$emit('collapseAll')
}
</script>

<style lang="scss">
.PmSidebarSectionPure {
  $block: &;

  $colorLine: color.$gray-200;

  min-height: 37px; // TODO
  width: 100%;
  background-color: color.$white;
  position: relative;

  // The bottom line, when expanded
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    z-index: 2;
    background-color: $colorLine;
  }

  &-header {
    padding-left: 10px;
    padding-right: 10px;
    user-select: none;
    z-index: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    justify-items: start;
    overflow: hidden;
    min-height: 41px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 5px;
      top: 0;
      bottom: 0;
      background-color: color.$key;
      display: none;
    }

    // The bottom line when collapsed or the line between header and content when expanded
    border-bottom: 1px solid transparent;

    #{$block}.is-collapsed > & {
      border-color: $colorLine;
    }

    #{$block}--canNotBeCollapsed > & {
      border-color: $colorLine;
    }

    #{$block}--markWhenCollapsed.is-collapsed & {
      background-color: color.$gray-200;
    }
  }

  &-title {
    font-size: constant.$fontSize-large;
    font-weight: 500;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    #{$block}--markWhenCollapsed.is-collapsed & {
      opacity: 0.5;
    }
  }

  &-action {
    @include mixin.transition-hover(opacity, $block);

    opacity: 0.5;

    #{$block}:hover & {
      opacity: 1;
    }
  }

  &-toggle {
    @include mixin.transition-hover(opacity, $block);

    opacity: 0.3;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    #{$block}-header:hover & {
      opacity: 0.5;
    }

    #{$block}:not(.is-headerDisabled) #{$block}-header:hover & {
      opacity: 1;
    }
  }

  &-toggleIcon {
    transform: rotate(90deg);
    transition: transform constant.$duration-fast;
    width: 50%;
    height: 50%;

    #{$block}.is-expanded > #{$block}-header & {
      transform: rotate(0deg);
    }
  }

  &-content {
    // Empty
  }

  &-empty {
    @include mixin.textLabel;

    padding: 10px;
  }
}
</style>
