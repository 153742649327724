const state = {
  type: undefined,
  canBeDropped: undefined,
  data: undefined,
}

const mutations = {
  start(state, { type, canBeDropped, data }) {
    state.type = type
    state.canBeDropped = canBeDropped
    state.data = data
  },

  stop(state) {
    state.type = undefined
    state.canBeDropped = undefined
    state.data = undefined
  },
}

const getters = {}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
