<template>
  <PmPillPure :color="status.color.value" :label="label" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmStatusPillPure'

export const propTypes = {
  type: {
    allowed: [
      'job',
      'project',
      'resourceAllocation',
      // 'resourceDay',
      //  'travel',
      //  'hotel'
      'resourceRequestFeedback',
      'resourceRequest',
      'webfleet',
      'leaveRequest',
    ],
  },

  projectStatus: {
    allowed: Object.values(PROJECT_STATUS),
  },

  jobStatus: {
    allowed: Object.values(STATUS_JOB),
  },

  resourceAllocationStatus: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  },

  // resourceDayStatus: {
  //   allowed: Object.values(RESOURCE_STATUS),
  // },

  // travelStatus: {
  //   allowed: Object.values(STATUS_TRAVEL_AND_HOTEL),
  // },

  // hotelStatus: {
  //   allowed: Object.values(STATUS_TRAVEL_AND_HOTEL),
  // },

  resourceRequestStatus: {
    allowed: Object.values(STATUS_RESOURCE_REQUEST),
  },

  resourceRequestFeedbackStatus: {
    allowed: Object.values(STATUS_RESOURCE_REQUEST_FEEDBACK),
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef, computed } from 'vue'

import {
  type JobStatus,
  type ResourceAllocationStatus,
  type ProjectStatus,
  STATUS_JOB,
  STATUS_RESOURCE_ALLOCATION,
  PROJECT_STATUS,
  // RESOURCE_STATUS,
  // type ResourceStatus,
  // STATUS_TRAVEL_AND_HOTEL,
  // type StatusTravelAndHotel,
} from '@/constants/persoplan'

import { useProjectStatus } from '@/composition/useProjectStatus'
import { useJobStatus } from '@/composition/useJobStatus'
import { useResourceAllocationStatus } from '@/composition/useResourceAllocationStatus'
// import { useResourceDayStatus } from '@/composition/useResourceDayStatus'
// import { useTravelAndHotelStatus } from '@/composition/useTravelAndHotelStatus'
import {
  useResourceRequestFeedbackStatus,
  type StatusResourceRequestFeedback,
  STATUS_RESOURCE_REQUEST_FEEDBACK,
} from '@/composition/useResourceRequestFeedbackStatus'

import {
  useResourceRequestStatus,
  type StatusResourceRequest,
  STATUS_RESOURCE_REQUEST,
} from '@/composition/useResourceRequestStatus'

import {
  useWebfleetStatus,
  type WebfleetStatus,
} from '@/composition/useWebfleetStatus'

import {
  useLeaveRequestStatus,
  type LeaveRequestStatus,
} from '@/composition/useLeaveRequestStatus'

import PmPillPure from '@/components/basics/PmPillPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  type: (typeof propTypes.type.allowed)[number]
  label?: Nilable<string>
  projectStatus?: ProjectStatus
  jobStatus?: Nilable<JobStatus>
  jobStatusLabel?: Nilable<string>
  resourceAllocationStatus?: Nilable<ResourceAllocationStatus>
  // resourceDayStatus?: ResourceStatus
  // travelStatus?: StatusTravelAndHotel
  // hotelStatus?: StatusTravelAndHotel
  resourceRequestFeedbackStatus?: Nilable<StatusResourceRequestFeedback>
  resourceRequestStatus?: Nilable<StatusResourceRequest>
  webfleetStatus?: Nilable<WebfleetStatus>
  leaveRequestStatus?: Nilable<LeaveRequestStatus>
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const statusProject = useProjectStatus({
  status: toRef(props, 'projectStatus'),
})

const statusJob = useJobStatus({
  status: toRef(props, 'jobStatus'),
})

const statusResourceAllocation = useResourceAllocationStatus({
  status: toRef(props, 'resourceAllocationStatus'),
})

// const statusResourceDay = useResourceDayStatus({
//   status: toRef(props, 'resourceDayStatus'),
// })

// const statusTravel = useTravelAndHotelStatus({
//   status: toRef(props, 'travelStatus'),
// })

// const statusHotel = useTravelAndHotelStatus({
//   status: toRef(props, 'hotelStatus'),
// })

const statusResourceRequestFeedback = useResourceRequestFeedbackStatus({
  status: toRef(props, 'resourceRequestFeedbackStatus'),
})

const statusResourceRequest = useResourceRequestStatus({
  status: toRef(props, 'resourceRequestStatus'),
})
const statusWebfleet = useWebfleetStatus({
  status: toRef(props, 'webfleetStatus'),
})

const statusLeaveRequest = useLeaveRequestStatus({
  status: toRef(props, 'leaveRequestStatus'),
})

const status = computed(() => {
  if (props.type === 'project') {
    return statusProject
  }

  if (props.type === 'job') {
    return statusJob
  }

  if (props.type === 'resourceAllocation') {
    return statusResourceAllocation
  }

  // if (props.type === 'resourceDay') {
  //   return statusResourceDay
  // }

  // if (props.type === 'travel') {
  //   return statusTravel
  // }

  // if (props.type === 'hotel') {
  //   return statusHotel
  // }

  if (props.type === 'resourceRequestFeedback') {
    return statusResourceRequestFeedback
  }

  if (props.type === 'resourceRequest') {
    return statusResourceRequest
  }

  if (props.type === 'webfleet') {
    return statusWebfleet
  }

  if (props.type === 'leaveRequest') {
    return statusLeaveRequest
  }

  throw new Error('no status for this type defined')
})

const label = computed(() => {
  if ('label' in status.value) {
    return status.value.label.value
  }

  return props.label
})

const color = computed(() => {
  return status.value.color.value
})

const classes = computed(() => {
  return {
    ...status.value.classes.value,
  }
})
</script>
