<template>
  <PmResourceAllocationConflictPure
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :number-of-conflicts="numberOfConflicts"
  >
    <template #resourceDay="{ closeDetails }">
      <PmResourceDay
        v-if="
          xstate.state.value.matches('conflicts') &&
          resourceStartDate &&
          resourceEndDate
        "
        :address-id="addressId"
        :vehicle-id="vehicleId"
        :start-date="resourceStartDate"
        :end-date="resourceEndDate"
        :additional-conflict-items="additionalConflictItems"
        :type="type"
        show-in="none"
        @close="closeDetails"
      />
    </template>
  </PmResourceAllocationConflictPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceAllocationConflict'

export const propTypes = {
  type: {
    allowed: [
      RESOURCE_TYPE.ADDRESS,
      RESOURCE_TYPE.VEHICLE,
      RESOURCE_TYPE.FREELANCER,
    ] as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { isValid } from 'date-fns'
import { useApolloClient } from '@vue/apollo-composable'

import {
  RESOURCE_TYPE,
  FORMAT_DATETIME_SERVER,
  RESOURCE_DAY_MAX_DAYS,
} from '@/constants/persoplan'

import { useXState } from '@/composition/useXState'
import { ResourceAllocationConflictState } from '@/components/persoplan/PmResourceAllocationConflict/ResourceAllocationConflictState'

import { throwFriendlyError } from '@/functional/error'

import {
  parseServerDateString,
  formatToServerDateString,
} from '@/utilities/date'

import {
  ResourceDayAddressDocument,
  ResourceDayVehicleDocument,
  ResourceDocument,
} from '@/../generated/graphql'

import type {
  ResourceDayAddressQuery,
  ResourceDayVehicleQuery,
} from '@/../generated/graphql'

import { getConflictingTimeEntries } from '@/components/persoplan/PmResourceDay/useResourceDayShared'

import PmResourceDay from '@/components/persoplan/PmResourceDay/PmResourceDay.vue'
import PmResourceAllocationConflictPure from '@/components/persoplan/PmResourceAllocationConflict/PmResourceAllocationConflictPure.vue'

export interface Props {
  resourceId: number | null
  resourceAllocationId?: number | null
  addressId?: number
  vehicleId?: number
  type: (typeof propTypes.type.allowed)[number]
  numberOfDays?: number
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const { client: apolloClient } = useApolloClient()

const resourceStartDate = ref<Date>()
const resourceEndDate = ref<Date>()
const numberOfConflicts = ref<number>()

// Check for address/vehicle id
if (
  props.type === RESOURCE_TYPE.ADDRESS ||
  props.type === RESOURCE_TYPE.FREELANCER
) {
  if (!props.addressId) throw new Error('You need to set an addressId')
}

if (props.type === RESOURCE_TYPE.VEHICLE && !props.vehicleId) {
  throw new Error('You need to set a vehicleId')
}

const additionalConflictItems = computed(() => {
  if (props.resourceAllocationId) return
  if (!resourceStartDate.value || !resourceEndDate.value) return

  return [
    { startDate: resourceStartDate.value, endDate: resourceEndDate.value },
  ]
})

const searchForConflicts = async () => {
  if (!props.resourceId) {
    throw new Error('resourceId is undefined')
  }

  try {
    // Get info about resource
    const resource = await apolloClient.query({
      query: ResourceDocument,
      variables: {
        id: props.resourceId,
      },
    })

    if (!resource.data?.resource) throw new Error('Resource not found')

    const startDate = parseServerDateString(resource.data?.resource?.startDate)
    const endDate = parseServerDateString(resource.data?.resource?.endDate)

    if (!isValid(startDate)) throw new Error('startDate is not a valid date')
    if (!isValid(endDate)) throw new Error('endDate is not a valid date')

    // Save values for later
    resourceStartDate.value = startDate
    resourceEndDate.value = endDate

    // Get Agenda to search for conflicts
    const queryLookup = {
      [RESOURCE_TYPE.ADDRESS]: ResourceDayAddressDocument,
      [RESOURCE_TYPE.FREELANCER]: ResourceDayAddressDocument,
      [RESOURCE_TYPE.VEHICLE]: ResourceDayVehicleDocument,
    }

    if (
      props.type === RESOURCE_TYPE.ADDRESS ||
      props.type === RESOURCE_TYPE.FREELANCER
    ) {
      if (!props.addressId) throw new Error('You need to provide an addressId')
    }

    if (props.type === RESOURCE_TYPE.VEHICLE) {
      if (!props.vehicleId) throw new Error('You need to provide an vehicleId')
    }

    const idLookup = {
      [RESOURCE_TYPE.ADDRESS]: props.addressId,
      [RESOURCE_TYPE.FREELANCER]: props.addressId,
      [RESOURCE_TYPE.VEHICLE]: props.vehicleId,
    }

    const id = idLookup[props.type]
    if (!id) throw new Error('id is undefined')
    if (!startDate) throw new Error('startDate is undefined')
    if (!endDate) throw new Error('endDate is undefined')

    const resourceDay = await apolloClient.query({
      query: queryLookup[props.type],
      variables: {
        id: id,
        startDate: formatToServerDateString(startDate),
        endDate: formatToServerDateString(endDate),
      },
      fetchPolicy: 'network-only',
    })

    if (!resourceDay.data.agenda) throw new Error('agenda is undefined')

    const allItems = getAllItems(resourceDay.data.agenda, {
      excludeResourceAllocationId: props.resourceAllocationId,
    })
    if (!allItems) return

    const conflicts = getConflictingTimeEntries(
      {
        startDate,
        endDate,
      },
      allItems
    )

    numberOfConflicts.value = conflicts.length
    const hasConflicts = conflicts.length > 0

    return {
      hasConflicts,
    }
  } catch (error) {
    throwFriendlyError(error)
  }
}

function isAddressAgenda(
  agenda: ResourceDayAddressQuery['agenda'] | ResourceDayVehicleQuery['agenda']
): agenda is ResourceDayAddressQuery['agenda'] {
  return Object.prototype.hasOwnProperty.call(agenda, 'events')
}

const getAllItems = (
  agenda: ResourceDayAddressQuery['agenda'] | ResourceDayVehicleQuery['agenda'],
  {
    excludeResourceAllocationId,
  }: { excludeResourceAllocationId?: number | null }
) => {
  if (!agenda) return

  interface Item {
    type: 'ResourceAllocation' | 'ResourceState' | 'Event'
    id: number
    startDate: Date
    endDate: Date
  }

  const allItems: Item[] = []

  // Add ResourceAllocations
  agenda.resourceAllocations?.forEach((resourceAllocation) => {
    if (!resourceAllocation || !resourceAllocation.resourceFunctionAllocation)
      return

    if (!resourceAllocation.resourceFunctionAllocation.startDate) return
    if (!resourceAllocation.resourceFunctionAllocation.endDate) return
    if (resourceAllocation.id === excludeResourceAllocationId) return

    const startDate = parseServerDateString(
      resourceAllocation.resourceFunctionAllocation.startDate
    )
    const endDate = parseServerDateString(
      resourceAllocation.resourceFunctionAllocation.endDate
    )

    if (!startDate) throw new Error('startDate is undefined')
    if (!endDate) throw new Error('endDate is undefined')

    allItems.push({
      type: 'ResourceAllocation',
      id: resourceAllocation.id,
      startDate,
      endDate,
    })
  })

  // Add ResourceStates
  agenda.resourceStates?.forEach((resourceState) => {
    if (!resourceState) return

    const startDate = parseServerDateString(resourceState.startDate)
    const endDate = parseServerDateString(resourceState.endDate)

    if (!startDate) return
    if (!endDate) return

    allItems.push({
      type: 'ResourceState',
      id: resourceState.id,
      startDate,
      endDate,
    })
  })

  if (!isAddressAgenda(agenda)) return allItems

  agenda.events?.forEach((event) => {
    if (!event) return

    const startDate = parseServerDateString(event.startDate)
    const endDate = parseServerDateString(event.endDate)

    if (!startDate) return
    if (!endDate) return

    allItems.push({
      type: 'Event',
      id: event.id,
      startDate,
      endDate,
    })
  })

  return allItems
}

const xstate = useXState(ResourceAllocationConflictState, {
  services: {
    searchForConflicts: () => searchForConflicts,
  },
})

/**
 * Check if too many days and react accordingly
 */

const istooManyDays = props.numberOfDays
  ? props.numberOfDays > RESOURCE_DAY_MAX_DAYS
  : false

istooManyDays
  ? xstate.service.value.send('IS_TOO_MANY_DAYS')
  : xstate.service.value.send('CHECK')
</script>
