import { FEATURE_FLAG } from '@/constants/featureFlags'
import { VIEW_SETTING_MODE } from '@/constants/persoplan'

import { startDateForServer, endDateForServer } from '@/utilities/date'

const state = {}

const mutations = {}

const getters = {
  calendar: (state, getters, rootState) => {
    const startDate = startDateForServer(rootState.persoplan.visibleStartDate)
    const endDate = endDateForServer(rootState.persoplan.visibleEndDate)

    return {
      startDate,
      endDate,

      // Client
      clientIds: rootState.view.currentView.clients.ids,
      excludeClientIds:
        rootState.view.currentView.clients.mode === VIEW_SETTING_MODE.EXCLUDE,

      // Project leader
      projectLeaderIds: rootState.view.currentView.projectLeaders.ids,
      excludeProjectLeaderIds:
        rootState.view.currentView.projectLeaders.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Project
      projectIds: rootState.view.currentView.projects.ids,
      excludeProjectIds:
        rootState.view.currentView.projects.mode === VIEW_SETTING_MODE.EXCLUDE,

      // Project state
      projectStateIds: rootState.view.currentView.projectStates.ids,
      excludeProjectStateIds:
        rootState.view.currentView.projectStates.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Project type
      projectTypeIds: rootState.view.currentView.projectTypes.ids,
      excludeProjectTypeIds:
        rootState.view.currentView.projectTypes.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Job leader
      jobLeaderIds: rootState.view.currentView.jobLeaders.ids,
      excludeJobLeaderIds:
        rootState.view.currentView.jobLeaders.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Job state
      jobStateIds: rootState.view.currentView.jobStates.ids,
      excludeJobStateIds:
        rootState.view.currentView.jobStates.mode === VIEW_SETTING_MODE.EXCLUDE,

      // Job service
      jobServiceIds: rootState.view.currentView.jobServices.ids,
      excludeJobServiceIds:
        rootState.view.currentView.jobServices.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Resource types
      resourceTypeIds: rootState.view.currentView.resourceTypes.ids,
      excludeResourceTypeIds:
        rootState.view.currentView.resourceTypes.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Resource functions
      resourceFunctionIds: rootState.view.currentView.resourceFunctions.ids,
      excludeResourceFunctionIds:
        rootState.view.currentView.resourceFunctions.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Resource allocations
      resourceAllocationIds: rootState.view.currentView.resourceAllocations.ids,
      excludeResourceAllocationIds:
        rootState.view.currentView.resourceAllocations.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Resource allocation states
      resourceAllocationStateIds:
        rootState.view.currentView.resourceAllocationStates.ids,
      excludeResourceAllocationStateIds:
        rootState.view.currentView.resourceAllocationStates.mode ===
        VIEW_SETTING_MODE.EXCLUDE,

      // Misc
      showOnlyWithResources: rootState.view.currentView.showOnlyWithResources,
      showOnlyActive: rootState.view.currentView.showOnlyActive,

      // Skip part of the query
      skipFreelancers: rootState.view.currentView.showFreelancers === false,
      skipEmployees: rootState.view.currentView.showEmployees === false,
      skipVehicles: rootState.view.currentView.showVehicles === false,
      skipOperations:
        FEATURE_FLAG.OPERATIONS === false ||
        rootState.view.currentView.showOperations === false,
    }
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
