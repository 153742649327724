<template>
  <div class="PmColorPickerPure" :class="classes">
    <button
      class="PmColorPickerPure-button"
      title="Farbe ändern"
      @click="showPicker"
    >
      <PmIconPure
        ref="button"
        :name="ICONS_SMALL.TINT"
        class="PmColorPickerPure-icon"
        :is-loading="isLoading"
      />
    </button>

    <portal to="popover">
      <PmOnClickOutsidePure :do="onClickOutside" :disabled="!isPickerVisible">
        <PmPopoverWithTransitionPure
          :is-visible="isPickerVisible"
          is-positioned-on="element"
          :element="$refs.button.$el"
          class="PmColorPickerPure-popover"
        >
          <PmColorPickerPopoverPure
            :colors="colors"
            :selected-color-id="selectedColorId"
            :is-random="isRandom"
            @select-color="(color) => $emit('selectColor', color)"
            @use-random-color="$emit('useRandomColor')"
          />
        </PmPopoverWithTransitionPure>
      </PmOnClickOutsidePure>
    </portal>
  </div>
</template>

<script>
import { ICONS_SMALL } from '@/constants/icons'

import PmPopoverWithTransitionPure from '@/components/basics/PmPopover/PmPopoverWithTransitionPure.vue'
import PmOnClickOutsidePure from '@/components/utilities/PmOnClickOutsidePure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmColorPickerPopoverPure from '@/components/basics/PmColorPicker/PmColorPickerPopoverPure.vue'

export default {
  name: 'PmColorPickerPure',
  components: {
    PmPopoverWithTransitionPure,
    PmOnClickOutsidePure,
    PmIconPure,
    PmColorPickerPopoverPure,
  },

  model: {
    prop: 'selectedColorId',
    event: 'selectColor',
  },

  props: {
    colors: { type: Array, default: () => [] },
    selectedColorId: { type: String, default: undefined },
    isRandom: { type: Boolean, default: undefined },
    isLoading: { type: Boolean, default: undefined },
  },

  emits: ['useRandomColor', 'selectColor'],

  setup() {
    return {
      ICONS_SMALL,
    }
  },

  data() {
    return {
      isPickerVisible: false,
    }
  },

  computed: {
    classes() {
      return {
        'is-pickerVisible': this.isPickerVisible,
      }
    },
  },

  methods: {
    showPicker() {
      if (this.isPickerVisible) return
      this.isPickerVisible = true
    },

    onClickOutside() {
      this.isPickerVisible = false
    },
  },
}
</script>

<style lang="scss">
.PmColorPickerPure {
  $block: &;

  &-button {
    &:hover,
    #{$block}.is-pickerVisible & {
      color: color.$key;
    }
  }

  &-icon {
    @include mixin.transition-hover;

    width: 20px;
    height: 20px;
  }
}
</style>
