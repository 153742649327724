<template>
  <PmStartupPure :is-loading="isLoading">
    <PmStartupNeedsActionPure
      v-if="xstate.state.value.matches('changePassword')"
      message="Bevor es weitergeht, musst du dein Passwort ändern!"
    >
      <PmChangePassword
        :use-timeout="true"
        @success="xstate.service.value.send('PASSWORD_CHANGED')"
      />
    </PmStartupNeedsActionPure>
  </PmStartupPure>
</template>

<script>
import { defineComponent, getCurrentInstance, onMounted } from 'vue'
import { cloneDeep, isEqual } from 'lodash-es'
import deepmerge from 'deepmerge'

import { BREAKPOINT } from '@/constants/general'

import { addUserAbility } from '@/services/ability'
import { settingsToPersist } from '@/store/plugins/settingsConfig'
import { urlParamsToPersist } from '@/store/plugins/settingsConfig'
import { useXState } from '@/composition/useXState'

import { PmStartupState } from '@/components/PmStartup/PmStartupState'

import PmStartupPure from '@/components/PmStartup/PmStartupPure.vue'
import PmStartupNeedsActionPure from '@/components/PmStartup/PmStartupNeedsActionPure.vue'
import PmChangePassword from '@/components/PmChangePassword/PmChangePassword.vue'

import GetSettingsQuery from '@/graphql/GetSettingsQuery.graphql'
import CurrentUserQuery from '@/graphql/CurrentUserQuery.graphql'
import StaticDataQuery from '@/graphql/StaticDataQuery.graphql'
import ViewsQuery from '@/components/persoplan/PmSettings/ViewsQuery.graphql'

export default defineComponent({
  name: 'PmStartup',
  components: {
    PmStartupPure,
    PmStartupNeedsActionPure,
    PmChangePassword,
  },

  inject: ['isStorybook'],
  emits: ['done'],

  setup(props, context) {
    /**
     * This could be done in a reactive way via vueuse/useMediaQuery
     * @see https://vueuse.org/core/usemediaquery/
     */
    const isMobile = window.matchMedia(
      `(max-width: ${BREAKPOINT.MOBILE})`
    ).matches

    const instance = getCurrentInstance()

    const xstate = useXState(PmStartupState, {
      actions: {
        loadInitialData: () => instance.ctx.loadInitialData(),
        emitDone: () => context.emit('done'),
      },
    })

    onMounted(() => xstate.service.value.send('MOUNTED'))

    return {
      isMobile,
      xstate,
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    isLoading() {
      return this.xstate.state.value.matches('initialLoading')
    },
  },

  methods: {
    async loadInitialData() {
      const waitForSetting = this.getAndApplySettings()
      const waitForUser = this.getUserAndApplyAbility()
      const waitForStaticData = this.getStaticData()
      const waitForViews = this.getViews()

      const settings = await waitForSetting
      const { userNeedsToChangePassword } = await waitForUser
      await waitForStaticData
      const views = await waitForViews

      if (userNeedsToChangePassword) {
        return this.xstate.service.value.send('CHANGE_PASSWORD')
      }

      this.applyView({ views, settings })
      this.setDayWidth()

      return this.xstate.service.value.send('INITIAL_LOADING_FINISHED')
    },

    async getUserAndApplyAbility() {
      const queryCurrentUser = await this.$apollo.query({
        query: CurrentUserQuery,
        fetchPolicy: 'network-only',
      })

      addUserAbility({
        roles: queryCurrentUser.data.user.roles,
        id: queryCurrentUser.data.user.id,
        addressId: queryCurrentUser.data.user.address.id,
      })

      return {
        userNeedsToChangePassword:
          queryCurrentUser.data?.user?.changePasswordNextLogin,
      }
    },

    async getAndApplySettings() {
      const querySettings = await this.$apollo.query({
        query: GetSettingsQuery,
        fetchPolicy: 'network-only',
      })

      const persoplanSettings = querySettings.data.settings.find(
        (setting) => setting.name === 'persoplan'
      )

      // User does not have any persoplan settings yet
      if (!persoplanSettings) {
        return
      }

      const persistedSettings = this.getPersistedSettings(
        persoplanSettings.value
      )
      const urlSettings = this.getUrlSettings()
      const mergedSettings = deepmerge(persistedSettings, urlSettings)

      this.$store.commit('applyPersistedSettings', mergedSettings)

      return mergedSettings
    },

    getPersistedSettings(values) {
      const parsedSettings = this.parseSettings({
        values: JSON.parse(values),
        settings: settingsToPersist,
      })

      this.removeUnreferencedSettings({
        settings: parsedSettings,
        options: settingsToPersist,
      })

      const hasSettingsToApply = Object.keys(parsedSettings).length > 0
      if (!hasSettingsToApply) return

      return parsedSettings
    },

    getUrlSettings() {
      if (this.isStorybook) return {}

      const query = this.$route.query

      // Find all items in url query, which have no counterpart in the settings
      const orphanedQueryIds = Object.keys(query).filter((id) => {
        const index = Object.values(urlParamsToPersist).findIndex(
          (item) => item.id === id
        )

        if (index === -1) return true
        return false
      })

      /**
       * Replace URL-friendly keys with actual statePaths
       */
      const mappedQuery = {}

      Object.entries(query).forEach(([id, value]) => {
        // If it's an orphaned item we skip it
        if (orphanedQueryIds.includes(id)) {
          return
        }

        const settingKey = Object.keys(urlParamsToPersist).find((key) => {
          const value = urlParamsToPersist[key]
          return value.id === id
        })

        if (!settingKey) throw new Error('settingKey not found')

        mappedQuery[settingKey] = value
      })

      // Remove unreferenced settings from url
      const newQuery = cloneDeep(query)
      Object.keys(newQuery).forEach((key) => {
        if (!orphanedQueryIds.includes(key)) return
        delete newQuery[key]
      })

      // Only update url if anything has changed
      const isSameQuery = isEqual(this.$route.query, newQuery)
      if (!isSameQuery) {
        this.$router.replace({ query: newQuery })
      }

      // Parse and apply settings
      const parsedSettings = this.parseSettings({
        values: mappedQuery,
        settings: urlParamsToPersist,
      })

      return parsedSettings
    },

    parseSettings({ values, settings }) {
      const result = cloneDeep(values)

      Object.entries(result).forEach(([key, value]) => {
        const type = settings[key]?.type
        if (!type) return

        if (type === Date) {
          result[key] = new Date(value)
          return
        }

        if (type === Number) {
          result[key] = parseInt(value)
        }
      })

      return result
    },

    removeUnreferencedSettings({ settings, options }) {
      const allowedSettings = Object.keys(options)

      const idsOfRemovedSettings = []

      Object.keys(settings).forEach((statePath) => {
        const isAllowed = allowedSettings.includes(statePath)
        if (isAllowed) return

        delete settings[statePath]
        idsOfRemovedSettings.push(statePath)
      })

      return idsOfRemovedSettings
    },

    async getStaticData() {
      await this.$apollo.query({
        query: StaticDataQuery,
        fetchPolicy: 'network-only',
      })
    },

    async getViews() {
      const result = await this.$apollo.query({
        query: ViewsQuery,
        fetchPolicy: 'network-only',
      })

      return result.data.views
    },

    applyView({ views, settings }) {
      // New users don't have settings, in this case do nothing
      if (!settings) return

      const savedViewId = settings['view.id']
      const defaultViewMobile = settings['view.defaultSavedViewIdMobile']
      const defaultViewDesktop = settings['view.defaultSavedViewIdDesktop']

      let viewIdToSearchFor = savedViewId

      if (!viewIdToSearchFor) {
        viewIdToSearchFor = this.isMobile
          ? defaultViewMobile
          : defaultViewDesktop
      }

      const view = views.find((view) => view.id === viewIdToSearchFor)
      if (!view) {
        console.warn('view not found')
        return
      }

      this.$store.commit('view/setView', {
        id: view.id,
        title: view.title,
        query: view.query,
      })
    },

    setDayWidth() {
      if (!this.isMobile) return

      const overlap = 40
      const treshold = 500 + overlap

      const showOneDay = window.matchMedia(`(max-width: ${treshold}px)`).matches
      const showTwoDays = window.matchMedia(
        `(min-width: ${treshold}px)`
      ).matches

      if (showOneDay) {
        this.$store.commit('cssVar/set', {
          name: `dayWidth`,
          value: window.innerWidth - overlap,
        })

        return
      }

      if (showTwoDays) {
        this.$store.commit('cssVar/set', {
          name: `dayWidth`,
          value: (window.innerWidth - overlap) / 2,
        })

        return
      }
    },
  },
})
</script>
