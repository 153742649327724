import { computed, type PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import type { Nilable } from '@/types/misc'

export interface Props {
  target?: Nilable<'_blank'>
  href?: Nilable<string>
  to?: Nilable<string | RouteLocationRaw>
  linkOpenInNewWindow?: Nilable<boolean>
}

/**
 * @deprecated Use typescript props
 */
export const propsLegacy = {
  target: { type: String as PropType<Props['target']>, default: undefined },
  href: { type: String as PropType<Props['href']>, default: undefined },
  to: { type: [String, Object] as PropType<Props['to']>, default: undefined },
  linkOpenInNewWindow: {
    type: Boolean as PropType<Props['linkOpenInNewWindow']>,
    default: false,
  },
}

export function useLink(props: Props) {
  const tag = computed(() => {
    if (props.to) return 'router-link'
    if (props.href) return 'a'

    return 'button'
  })

  const linkProps = computed(() => {
    const baseProps: Props = {}

    if (props.linkOpenInNewWindow) {
      baseProps.target = '_blank'
    }

    if (tag.value === 'router-link') {
      return {
        ...baseProps,
        to: props.to,
      }
    }

    if (tag.value === 'a') {
      return {
        ...baseProps,
        href: props.href,
      }
    }

    return {}
  })

  return {
    tag,
    props: linkProps,
  }
}
