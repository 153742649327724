<template>
  <div :class="componentClass.root">
    <PmGroupManager />

    <PmSidebarSectionPure title="Projekte / Jobs" :can-be-collapsed="false">
      <PmSidebarSectionPure
        v-for="group in groupedProjectIds"
        :key="group.number"
        :title="`Gruppe ${group.number}`"
        :collapsed="store.state.persoplan.projectGroupsCollapsed[group.number]"
        :mark-when-collapsed="true"
        @collapse="store.commit('persoplan/projectGroupCollapse', group.number)"
        @expand="store.commit('persoplan/projectGroupExpand', group.number)"
      >
        <PmSidebarProject
          v-for="projectId in group.projectIds"
          :id="projectId"
          :key="projectId"
          :job-ids="getJobIds(projectId)"
        />
      </PmSidebarSectionPure>
    </PmSidebarSectionPure>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, nextTick } from 'vue'
import { groupBy } from 'lodash-es'
import { useStore } from 'vuex'
import { useQuery } from '@vue/apollo-composable'
import { useComponentClass } from '@thomasaull-shared/composables'

import PmSidebarSectionPure from '@/components/persoplan/PmSidebarSection/PmSidebarSectionPure.vue'
import PmSidebarProject from '@/components/persoplan/PmSidebarProject/PmSidebarProject.vue'
import PmGroupManager from '@/components/persoplan/PmGroupManager/PmGroupManager.vue'

import {
  SidebarProjectsDocument,
  type SidebarProjectsQueryVariables,
} from '@/../generated/graphql'

interface GroupedProjectItem {
  number: string
  projectIds: number[]
}

export interface Props {
  ids?: {
    projectIds: {
      [key: number]: {
        jobIds: number[]
      }
    }
  }
}

const props = withDefaults(defineProps<Props>(), {})
const store = useStore()
const componentClass = useComponentClass()

const projectIds = computed(() => {
  if (!props.ids?.projectIds) return

  const result = Object.keys(props.ids.projectIds).map((projectId) => {
    return parseInt(projectId)
  })

  if (result.length === 0) return
  return result
})

const groupedProjectIds = computed(() => {
  if (!sidebarProjects.value?.length) return

  const groupedProjects = groupBy(sidebarProjects.value, 'group.number')

  const groupedProjectIds = Object.entries(groupedProjects).reduce<
    GroupedProjectItem[]
  >((result, [number, projects]) => {
    const projectIds = projects.reduce<number[]>((result, project) => {
      if (!project) return result
      result.push(project.id)
      return result
    }, [])

    result.push({
      number: number,
      projectIds: projectIds,
    })

    return result
  }, [])

  return groupedProjectIds
})

const getJobIds = (projectId: number) => {
  try {
    return props.ids?.projectIds[projectId].jobIds
  } catch {
    // Silent error
  }
}

/**
 * For some reason apollo fires two query at the same time, one with projectIds
 * and one without, even though it should not be enabled. This is a workaround for this:
 */
const isQueryEnabled = ref(false)
watch(
  projectIds,
  async () => {
    if (projectIds.value) {
      await nextTick()
      isQueryEnabled.value = true
    }
  },
  {
    immediate: true,
  }
)

const sidebarProjectsQuery = useQuery(
  SidebarProjectsDocument,
  () => {
    const variables: SidebarProjectsQueryVariables = {
      projectIds: projectIds.value ?? [],
    }

    return variables
  },
  () => {
    const isEnabled = isQueryEnabled.value === true
    const hasProjectIds =
      projectIds.value !== undefined && projectIds.value.length > 0

    return {
      enabled: isEnabled && hasProjectIds,
    }
  }
)

const sidebarProjects = computed(
  () => sidebarProjectsQuery.result.value?.projects
)
</script>
