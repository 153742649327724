<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import cuid from '@paralleldrive/cuid2'
import { find } from 'lodash-es'
import { useCurrentElement } from '@vueuse/core'

export default {
  name: 'PmPackingElementPure',

  props: {
    packingContainer: { type: Object, default: () => {} },
  },

  setup(props, context) {
    const el = useCurrentElement()
    const id = cuid.createId()
    const dimensions = ref({
      x: undefined,
      width: undefined,
      height: undefined,
    })

    const packedElement = computed(() => {
      return find(props.packingContainer.packedElements, {
        id,
      })
    })

    const styles = computed(() => {
      const top = packedElement.value?.y

      return {
        top: top ? `${top}px` : undefined,
        // 'contain-intrinsic-width': `${dimensions.value.width}px`,
        // 'contain-intrinsic-height': `${dimensions.value.height}px`,
      }
    })

    const requestSlot = async () => {
      await new Promise((resolve) => setTimeout(resolve, 0))

      props.packingContainer.addElement({
        ...getDimensions(),
        id: id,
        updateDimensions: updateDimensions,
        el: el.value,
      })
    }

    const unrequestSlot = () => {
      props.packingContainer.removeElement({
        id: id,
      })
    }

    const getDimensions = () => {
      const rect = el.value.getBoundingClientRect()

      dimensions.value = {
        x: el.value.offsetLeft,
        width: rect.width,
        height: rect.height,
      }

      return dimensions.value
    }

    const updateDimensions = () => {
      props.packingContainer.updateDimensionsOfElement({
        ...getDimensions(),
        id: id,
      })
    }

    onMounted(() => {
      requestSlot()

      // Re-pack if webfonts where loaded (possible change in dimensions!)
      document.fonts.ready.then(updateDimensions)
    })

    onBeforeUnmount(() => {
      unrequestSlot()
    })

    return () => {
      return context.slots.default({
        styles: styles.value,
      })[0]
    }
  },
}
</script>
