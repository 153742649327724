<template>
  <PmNotificationExternalServiceRequestCreatedPure
    v-bind="{ ...$attrs, ...props }"
    :is-jump-visible="can('approve', 'requests')"
    @open-details="isDetailsVisible = true"
  />

  <portal to="modal">
    <PmModalPure
      v-if="isDetailsVisible"
      title="Antrag Fremddienstleistung"
      @close="isDetailsVisible = false"
    >
      <PmRequestDetail
        v-if="isDetailsVisible"
        :id="props.externalServiceRequestId"
        type="externalServiceRequest"
      />
    </PmModalPure>
  </portal>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import PmNotificationExternalServiceRequestCreatedPure, {
  type Props as PropsNotificationExternalServiceRequestCreatedPure,
} from '@/components/PmNotification/PmNotificationExternalServiceRequestCreated/PmNotificationExternalServiceRequestCreatedPure.vue'
import PmRequestDetail from '@/components/PmRequestDetail/PmRequestDetail.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import { useAppAbility } from '@/composition/useAppAbility'
import type { Except } from 'type-fest'

type OmitProps = 'isJumpVisible'
type OmitSafeguard = Except<
  PropsNotificationExternalServiceRequestCreatedPure,
  OmitProps
>
type SelectedPropsNotificationExternalServiceRequestCreatedPure = Omit<
  PropsNotificationExternalServiceRequestCreatedPure,
  OmitProps
>

export interface Props
  extends SelectedPropsNotificationExternalServiceRequestCreatedPure {}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const isDetailsVisible = ref(false)
const { can } = useAppAbility()
</script>
