import { createMachine } from 'xstate'

export const PersoplanState = createMachine({
  tsTypes: {} as import('./persoplanState.typegen').Typegen0,

  schema: {
    events: {} as
      | { type: 'START_EDIT' }
      | { type: 'FINISH_EDIT' }
      | {
          type: 'START_VEHICLE_ALLOCATION'
          jobId: number
          allocatedVehicleId: number
        }
      | {
          type: 'START_DRIVER_ALLOCATION'
          jobId: number
          allocatedDriverId: number
        }
      | { type: 'FINISH_ALLOCATON' },

    context: {} as {
      jobId: number | undefined
      allocatedVehicleId: number | undefined
      allocatedDriverId: number | undefined
    },
  },

  id: 'PersoplanState',
  initial: 'view',

  context: {
    jobId: undefined,
    allocatedVehicleId: undefined,
    allocatedDriverId: undefined,
  },

  states: {
    view: {
      on: {
        START_EDIT: 'edit',
      },
    },

    edit: {
      initial: 'default',

      on: {
        FINISH_EDIT: '#PersoplanState.view',
      },

      states: {
        default: {
          on: {
            START_VEHICLE_ALLOCATION: 'vehicleAllocation',
            START_DRIVER_ALLOCATION: 'driverAllocation',
          },
        },

        vehicleAllocation: {
          on: {
            FINISH_ALLOCATON: 'default',
          },

          entry: (context, event) => {
            if (event.type !== 'START_VEHICLE_ALLOCATION') return

            context.jobId = event.jobId
            context.allocatedVehicleId = event.allocatedVehicleId
          },

          exit: (context) => {
            context.jobId = undefined
            context.allocatedVehicleId = undefined
          },
        },

        driverAllocation: {
          on: {
            FINISH_ALLOCATON: 'default',
          },

          entry: (context, event) => {
            if (event.type !== 'START_DRIVER_ALLOCATION') return

            context.jobId = event.jobId
            context.allocatedDriverId = event.allocatedDriverId
          },

          exit: (context) => {
            context.jobId = undefined
            context.allocatedDriverId = undefined
          },
        },
      },
    },
  },
})
