<template>
  <div class="PmStartupPure">
    <div class="PmStartupPure-container">
      <div class="PmStartupPure-content">
        <PmStatusPure v-if="isLoading" :loading="true">
          Lade Einstellungen
        </PmStatusPure>

        <template v-if="!isLoading">
          <slot />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PmStatusPure from '@/components/basics/PmStatusPure.vue'

export default {
  name: 'PmStartupPure',
  components: {
    PmStatusPure,
  },

  props: {
    isLoading: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmStartupPure {
  $block: &;

  background-color: color.$gray-200;
  height: 100%;

  &-container {
    // Shift container bit to the top for better vertical alignment
    transform: translateY(-3%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: var(--space-default);
    padding-right: var(--space-default);
  }

  &-content {
    @include shadow.default('medium');

    padding: var(--space-small);
    background-color: color.$white;
    border-radius: constant.$borderRadius-large;
    width: 100%;
    max-width: 450px;
    min-height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
