import { computed } from 'vue'
import type { Ref } from 'vue'
import {
  areIntervalsOverlapping,
  isBefore,
  isAfter,
  isSameDay,
  differenceInMinutes,
} from 'date-fns'

import { orderBy } from 'lodash-es'

import {
  RESOURCE_DAY_ITEM_TYPE,
  STATUS_JOB_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  RESOURCE_STATUS_LOOKUP,
  RESOURCE_STATUS,
  STATUS,
  STATUS_RESOURCE_ALLOCATION,
  type ResourceStatus,
} from '@/constants/persoplan'

import { lookup } from '@/utilities/misc'

import { parseServerDateString } from '@/utilities/date'
import type { ResourceDayAddressQuery } from '@/../generated/graphql'
import type { Props as PropsResourceDayItemPure } from '@/components/persoplan/PmResourceDay/PmResourceDayItemPure.vue'

export type ItemNormalized = Omit<
  PropsResourceDayItemPure,
  'containerStartDate' | 'containerEndDate'
> & { durationInMinutes: number; resourceDayStatus: ResourceStatus | undefined }

export function useResourceDayShared({
  agenda,
  userCanCreateOrEditResourceState,
}: {
  agenda: Ref<ResourceDayAddressQuery['agenda']>
  userCanCreateOrEditResourceState: Ref<boolean>
}) {
  const normalizedResourceAllocations = computed(() => {
    if (!agenda.value?.resourceAllocations) return []

    return agenda.value.resourceAllocations.reduce<ItemNormalized[]>(
      (resourceAllocations, resourceAllocation) => {
        if (!resourceAllocation) return resourceAllocations

        const subtitle =
          resourceAllocation.resourceFunctionAllocation?.jobAppointment?.caption

        const jobState =
          resourceAllocation.resourceFunctionAllocation?.job?.jobState?.caption

        const jobCaption =
          resourceAllocation.resourceFunctionAllocation?.job?.caption

        const alertOnDutyOrder =
          (resourceAllocation?.dutyOrder?.overlappingAllocationIds?.length ||
            0) > 0

        const jobStatusId =
          resourceAllocation.resourceFunctionAllocation?.job?.jobState?.id

        const jobStatus = jobStatusId
          ? STATUS_JOB_LOOKUP[jobStatusId]
          : undefined

        const resourceAllocationStatusId =
          resourceAllocation.resourceAllocationState?.id

        const resourceAllocationStatus = lookup(
          resourceAllocationStatusId,
          STATUS_RESOURCE_ALLOCATION_LOOKUP
        )

        const startDate = parseServerDateString(
          resourceAllocation?.resourceFunctionAllocation?.startDate
        )

        const endDate = parseServerDateString(
          resourceAllocation?.resourceFunctionAllocation?.endDate
        )

        if (!startDate) throw new Error('startDate is undefined')
        if (!endDate) throw new Error('endDate is undefined')

        const item: ItemNormalized = {
          id: resourceAllocation.id,
          type: RESOURCE_DAY_ITEM_TYPE.RESOURCE_ALLOCATION,
          title: `${jobCaption} (${jobState})`,
          subtitle: subtitle ? subtitle : '- ohne Termin -',

          jobNumber: resourceAllocation.resourceFunctionAllocation?.job?.number,
          jobStatus,
          jobStatusLabel:
            resourceAllocation.resourceFunctionAllocation?.job?.jobState
              ?.caption,

          resourceAllocationStatus,
          resourceAllocationStatusLabel:
            resourceAllocation.resourceAllocationState?.caption,

          startDate,
          endDate,
          note: resourceAllocation.extra?.notice,
          dutyOrder: resourceAllocation.dutyOrder?.order,
          alertOnDutyOrder,
          conflictingItems: [],
          durationInMinutes: differenceInMinutes(endDate, startDate),
          resourceDayStatus: getResourceDayStatusForResourceAllocation({
            jobStatusId:
              resourceAllocation.resourceFunctionAllocation?.job?.jobState?.id,
            resourceAllocationStatusId: resourceAllocationStatusId,
          }),
        }

        resourceAllocations.push(item)
        return resourceAllocations
      },
      []
    )
  })

  const normalizedResourceStates = computed(() => {
    if (!agenda.value?.resourceStates) return []

    return agenda.value.resourceStates.reduce<ItemNormalized[]>(
      (resourceStates, resourceState) => {
        if (!resourceState) return resourceStates

        const startDate = parseServerDateString(resourceState.startDate)
        const endDate = parseServerDateString(resourceState.endDate)

        if (!startDate) throw new Error('startDate is undefined')
        if (!endDate) throw new Error('endDate is undefined')

        const item: ItemNormalized = {
          id: resourceState.id,
          type: RESOURCE_DAY_ITEM_TYPE.RESOURCE_STATE,
          title: resourceState.resourceStateType?.caption,
          startDate,
          endDate,
          note: resourceState.comment,
          userCanEdit: userCanCreateOrEditResourceState.value,
          conflictingItems: [],
          durationInMinutes: differenceInMinutes(endDate, startDate),
          resourceDayStatus: lookup(
            resourceState.resourceStateType?.id,
            RESOURCE_STATUS_LOOKUP
          ),
          isLocked: resourceState.locked === true,
        }

        resourceStates.push(item)
        return resourceStates
      },
      []
    )
  })

  return {
    normalizedResourceAllocations,
    normalizedResourceStates,
  }
}

export interface ConflictItem {
  startDate: Date
  endDate: Date
}

export function getConflictingTimeEntries(
  item: ConflictItem,
  allItems: ConflictItem[],
  additionalArtificialItems: ConflictItem[] = []
) {
  const overlappingItems = [...allItems, ...additionalArtificialItems].filter(
    (itemToCompare) => {
      // Don't use the item, because of course it would overlap with itself
      if (item === itemToCompare) return false

      return areIntervalsOverlapping(
        {
          start: item.startDate,
          end: item.endDate,
        },
        {
          start: itemToCompare.startDate,
          end: itemToCompare.endDate,
        }
      )
    }
  )

  const conflictingItems = overlappingItems.map((item) => {
    return {
      startDate: item.startDate,
      endDate: item.endDate,
    }
  })

  return conflictingItems
}

export function isOnThisDay(
  item: { startDate: Date; endDate: Date },
  day: Date
) {
  // It starts on this day
  if (isSameDay(item.startDate, day)) return true
  // It ends on this day
  if (isSameDay(item.endDate, day)) return true

  // The day is in between start and end
  if (isBefore(item.startDate, day) && isAfter(item.endDate, day)) {
    return true
  }
}

export function getResourceDayStatusForResourceAllocation({
  jobStatusId,
  resourceAllocationStatusId,
}: {
  jobStatusId?: number
  resourceAllocationStatusId?: number
}) {
  if (!jobStatusId) return
  if (!resourceAllocationStatusId) return

  const jobStatus = lookup(jobStatusId, STATUS_JOB_LOOKUP)
  const resourceAllocationStatus = lookup(
    resourceAllocationStatusId,
    STATUS_RESOURCE_ALLOCATION_LOOKUP
  )

  if (jobStatus !== STATUS.CONFIRMED) return RESOURCE_STATUS.AVAILABLE
  if (resourceAllocationStatus !== STATUS_RESOURCE_ALLOCATION.CONFIRMED)
    return RESOURCE_STATUS.AVAILABLE

  return RESOURCE_STATUS.ON_PROJECT

  // const isJobOfferedOrConfirmed =
  //   jobStatus === STATUS.OFFERED || jobStatus === STATUS.CONFIRMED
  // const isResourceAllocationConsideredOrRequested =
  //   resourceAllocationStatus === STATUS_RESOURCE_ALLOCATION.CONSIDERED ||
  //   resourceAllocationStatus === STATUS_RESOURCE_ALLOCATION.REQUESTED

  // if (isJobOfferedOrConfirmed && isResourceAllocationConsideredOrRequested) {
  //   return RESOURCE_STATUS.AVAILABLE
  // }

  // const isJobConfirmed = jobStatus === STATUS.CONFIRMED
  // const isResourceAllocationConfirmed =
  //   resourceAllocationStatus === STATUS_RESOURCE_ALLOCATION.CONFIRMED

  // if (isJobConfirmed && isResourceAllocationConfirmed) {
  //   return RESOURCE_STATUS.ON_PROJECT
  // }

  // return RESOURCE_STATUS.AVAILABLE
}

export function getResourceDayStatus({
  items,
  day,
}: {
  items: {
    startDate: Date
    endDate: Date
    resourceDayStatus?: ResourceStatus | undefined
  }[]
  day: Date
}) {
  const itemsOnThisDay = items.filter((item) => {
    return isOnThisDay(item, day)
  })

  if (itemsOnThisDay.length === 0) return

  const longestItemOnThisDay = orderBy(
    itemsOnThisDay,
    ['durationInMinutes'],
    ['desc']
  )[0]

  return longestItemOnThisDay.resourceDayStatus
}
