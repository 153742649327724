<template>
  <div class="PmActionbarFilterPure">
    <PmInputPure
      placeholder="Suchbegriff…"
      label="Suche"
      type="search"
      :value="value.searchterm"
      :loading="isSearchLoading"
      :has-reset="true"
      @input="(value) => onInput('searchterm', value)"
    />

    <PmDropdownPure
      v-if="options.resourceFunctions"
      label="Ressourcenfunktion"
      :options="options.resourceFunctions"
      :is-loading="isOptionsLoading"
      :value="value.resourceFunctionIds"
      :multiple="true"
      :has-reset="true"
      @input="(value) => onInput('resourceFunction', value)"
    />

    <PmDropdownPure
      v-if="options.qualifications"
      label="Qualifikation"
      :options="options.qualifications"
      :is-loading="isOptionsLoading"
      :value="value.qualificationIds"
      :multiple="true"
      :has-reset="true"
      @input="(value) => onInput('qualification', value)"
    />

    <PmDropdownPure
      v-if="options.addressResourceTypes"
      label="Adresse Ressourcentyp"
      :options="options.addressResourceTypes"
      :is-loading="isOptionsLoading"
      :value="value.addressResourceTypeId"
      :has-reset="true"
      @input="(value) => onInput('addressResourceType', value)"
    />

    <PmCheckboxPure
      v-if="isShowOnlyWithResourceTypeVisible"
      :value="value.showOnlyWithResourceType"
      @input="(value) => onInput('showOnlyWithResourceType', value)"
    >
      Nur Personen mit Resourcentyp anzeigen
    </PmCheckboxPure>
  </div>
</template>

<script>
import { cloneDeep, isNil } from 'lodash-es'

import { RESOURCE_TYPE } from '@/constants/persoplan'

import propValidator from '@/functional/propValidator'

import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmDropdownPure from '@/components/basics/PmDropdownPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'

export const propTypes = {
  type: { allowed: [RESOURCE_TYPE.ADDRESS, RESOURCE_TYPE.VEHICLE] },
}

export default {
  name: 'PmActionbarFilterPure',
  components: {
    PmInputPure,
    PmDropdownPure,
    PmCheckboxPure,
  },

  props: {
    options: { type: Object, default: () => ({}) },
    value: { type: Object, default: () => ({}) },
    isSearchLoading: { type: Boolean, default: false },
    isOptionsLoading: { type: Boolean, default: undefined },
    type: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.type.allowed),
    },
  },

  emits: ['input', 'update:value'],

  computed: {
    isShowOnlyWithResourceTypeVisible() {
      if (this.type !== RESOURCE_TYPE.ADDRESS) return false
      return isNil(this.value.addressResourceTypeId)
    },
  },

  methods: {
    onInput(type, value) {
      const newValue = cloneDeep(this.value)

      if (type === 'searchterm') newValue.searchterm = value
      if (type === 'resourceFunction') newValue.resourceFunctionIds = value
      if (type === 'qualification') newValue.qualificationIds = value
      if (type === 'addressResourceType') newValue.addressResourceTypeId = value
      if (type === 'showOnlyWithResourceType')
        newValue.showOnlyWithResourceType = value

      this.$emit('input', newValue)
      this.$emit('update:value', newValue)
    },
  },
}
</script>

<style lang="scss">
.PmActionbarFilterPure {
  $block: &;

  display: grid;
  grid-gap: 8px;
}
</style>
