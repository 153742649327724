export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddressCalendarItem: [
      'CalendarEvent',
      'Event',
      'ResourceAllocation',
      'ResourceState',
    ],
    FilterEntity: [
      'AddressResourceType',
      'JobAppointmentType',
      'JobService',
      'JobState',
      'JobType',
      'LogAction',
      'Priority',
      'ProjectState',
      'ProjectType',
      'ResourceAllocationState',
      'ResourceStateType',
      'ResourceType',
      'StockTypeServiceType',
    ],
    NotificationDetailItem: [
      'ExternalServiceRequest',
      'Job',
      'LeaveRequest',
      'Project',
      'ResourceRequest',
    ],
    NotificationMetaItem: [
      'ExternalServiceRequestAccepted',
      'ExternalServiceRequestCreated',
      'ExternalServiceRequestDeclined',
      'ExternalServiceRequestUpdated',
      'LeaveRequestAccepted',
      'LeaveRequestCreated',
      'LeaveRequestDeclined',
      'LeaveRequestUpdated',
      'ResourceRequestFeedbackStateUpdated',
      'ResourceRequestUpdated',
    ],
    VehicleCalendarItem: ['ResourceAllocation', 'ResourceState'],
  },
}
export default result
