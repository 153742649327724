<template>
  <div v-if="notification && propsNotificationBase">
    <PmNotificationResourceRequestFeedback
      v-if="notification.type === 'ResourceRequestFeedbackStateUpdated'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationLeaveRequestCreated
      v-if="notification.type === 'NotificationLeaveRequestCreated'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationLeaveRequestUpdated
      v-if="notification.type === 'NotificationLeaveRequestUpdated'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationLeaveRequestAccepted
      v-if="notification.type === 'NotificationLeaveRequestAccepted'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationLeaveRequestDeclined
      v-if="notification.type === 'NotificationLeaveRequestDeclined'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationExternalServiceRequestCreated
      v-if="notification.type === 'NotificationExternalServiceRequestCreated'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationExternalServiceRequestUpdated
      v-if="notification.type === 'NotificationExternalServiceRequestUpdated'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationExternalServiceRequestAccepted
      v-if="notification.type === 'NotificationExternalServiceRequestAccepted'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />

    <PmNotificationExternalServiceRequestDeclined
      v-if="notification.type === 'NotificationExternalServiceRequestDeclined'"
      v-bind="{ ...propsNotificationBase, ...notification.props }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmNotification'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import notifications from '@/store/notifications/notifications'
import { type Props as PropsNotificationContainerPure } from '@/components/PmNotification/PmNotificationContainerPure.vue'
import PmNotificationResourceRequestFeedback from '@/components/PmNotification/PmNotificationResourceRequestFeedback/PmNotificationResourceRequestFeedback.vue'
import PmNotificationLeaveRequestCreated from '@/components/PmNotification/PmNotificationLeaveRequestCreated/PmNotificationLeaveRequestCreated.vue'
import PmNotificationLeaveRequestUpdated from '@/components/PmNotification/PmNotificationLeaveRequestUpdated/PmNotificationLeaveRequestUpdated.vue'
import PmNotificationLeaveRequestAccepted from '@/components/PmNotification/PmNotificationLeaveRequestAccepted/PmNotificationLeaveRequestAccepted.vue'
import PmNotificationLeaveRequestDeclined from '@/components/PmNotification/PmNotificationLeaveRequestDeclined/PmNotificationLeaveRequestDeclined.vue'
import PmNotificationExternalServiceRequestCreated from '@/components/PmNotification/PmNotificationExternalServiceRequestCreated/PmNotificationExternalServiceRequestCreated.vue'
import PmNotificationExternalServiceRequestUpdated from '@/components/PmNotification/PmNotificationExternalServiceRequestUpdated/PmNotificationExternalServiceRequestUpdated.vue'
import PmNotificationExternalServiceRequestAccepted from '@/components/PmNotification/PmNotificationExternalServiceRequestAccepted/PmNotificationExternalServiceRequestAccepted.vue'
import PmNotificationExternalServiceRequestDeclined from '@/components/PmNotification/PmNotificationExternalServiceRequestDeclined/PmNotificationExternalServiceRequestDeclined.vue'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const notification = computed(() => {
  return notifications.notifications.value?.get(props.id)
})

const propsNotificationBase = computed(() => {
  if (!notification.value) return

  const container = {
    date: notification.value.date,
    isUnread: notification.value.isUnread,
  } satisfies Pick<PropsNotificationContainerPure, 'date' | 'isUnread'>

  return {
    ...container,
    onMarkAsRead: markAsRead,
    onMarkAsUnread: markAsUnread,
  }
})

function markAsRead() {
  if (!notification.value) return
  notifications.markAsRead(notification.value.id)
}

function markAsUnread() {
  if (!notification.value) return
  notifications.markAsUnread(notification.value.id)
}

const isSavingReadStatus = computed(() => {
  return false
})
</script>
