import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmResourceStateEditPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmResourceStateEditPureState = createMachine({
  tsTypes: {} as import('./PmResourceStateEditPureState.typegen').Typegen0,
  schema: {},

  id: 'PmResourceStateEditPureState',
  initial: 'default',

  states: {
    default: {
      entry: ['resetInitialCollectedValue'],

      on: {
        DELETE: 'delete',
        SAVE: 'save',
      },
    },

    save: {
      initial: 'saving',
      states: {
        saving: {
          entry: 'emitSave',
        },

        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Speichern des Status',
          },
        },

        saved: {
          entry: ['resetInitialCollectedValue'],
        },
      },
    },

    delete: {
      initial: 'askForConfirmation',
      states: {
        askForConfirmation: {
          on: {
            CONFIRM: 'deleting',
            CANCEL: '#PmResourceStateEditPureState.default',
          },
        },

        deleting: {
          entry: 'emitDelete',
        },

        failed: {
          on: {
            CONFIRM: 'deleting',
            CANCEL: '#PmResourceStateEditPureState.default',
          },
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Löschen des Status',
          },
        },
      },
    },

    deleted: {
      type: 'final',
    },
  },
})

export const states = extractAllStates(PmResourceStateEditPureState)
