import { lookup } from '@/utilities/misc'
import { computed, type Ref, unref, ref } from 'vue'
import { orderBy, cloneDeep, isNil } from 'lodash-es'

import type { SortOptions } from '@/components/basics/PmTable/PmTablePure.vue'

type Options<TableSchema extends TableSchemaBase> = {
  data: Ref<TableSchema[] | undefined> | TableSchema[] | undefined
  /** When the actual data is not at the root level, e.g. when using groups */
  dataInNestedProperty?: string
  /** Callbacks function to lookup values for sorting */
  lookupValue?: Partial<
    Record<keyof TableSchema, (item: TableSchema) => NonNullable<any>>
  >
}

type TableSchemaBase = Record<string, any>

const directionLookup = {
  ascending: 'asc',
  descending: 'desc',
} as const

export function useSortedTableData<TableSchema extends TableSchemaBase>(
  options: Options<TableSchema>
) {
  const sortOptions = ref<SortOptions>({
    id: null,
    direction: null,
    options: null,
  })

  const dataSorted = computed(() => {
    const originalData = unref(options.data)
    const data = cloneDeep(originalData)

    if (!data) return undefined
    if (!sortOptions.value.id) return originalData
    if (!sortOptions.value.direction) return originalData

    const direction = directionLookup[sortOptions.value.direction]

    if (!options.dataInNestedProperty) {
      return orderBy(data, (item) => sort(item, sortOptions.value), [direction])
    } else {
      data.forEach((item) => {
        if (!options.dataInNestedProperty) return item

        const hasProperty = options.dataInNestedProperty in item
        if (!hasProperty) return item

        const dataNestedProperty = item[options.dataInNestedProperty]
        if (!Array.isArray(dataNestedProperty)) return item

        const nestedDataSorted = orderBy(
          dataNestedProperty,
          (item) => sort(item, sortOptions.value),
          [direction]
        )

        // @ts-expect-error I don't know how to type this properly
        item[options.dataInNestedProperty] = nestedDataSorted
      })

      return data
    }

    throw new Error('Code should not run until here')
  })

  function sort(item: TableSchema, sortOptions: SortOptions) {
    // TODO: This does not work when a value is defined but `undefined`, for example status of `externalServiceRequest` where undefined maps to "Nicht bearbeitet"
    let value: any = undefined

    const useCallback =
      !isNil(sortOptions.id) &&
      !isNil(options.lookupValue) &&
      sortOptions.id in options.lookupValue

    if (useCallback) {
      if (isNil(sortOptions.id)) throw new Error('sortOptions.id is null')
      if (isNil(options.lookupValue))
        throw new Error('lookup callback is undefined')

      const callback = options.lookupValue[sortOptions.id]
      if (!callback) throw new Error('callback is undefined')

      value = callback(item)
    } else {
      value = lookup(sortOptions.id, item)
    }
    if (!value) throw new Error(`value is undefined or null`)

    return normalizeSortingProperty(value, sortOptions)
  }

  type Primitive = string | boolean | number | Date

  function normalizeSortingProperty(
    value: NonNullable<any>,
    sortOptions: SortOptions
  ): Primitive {
    // Handle primitive value
    if (isPrimitive(value)) return value

    /**
     * Handle array bu using the first array item
     */
    if (Array.isArray(value)) {
      return normalizeSortingProperty(value[0], sortOptions)
    }

    if (typeof value !== 'object') throw new Error('An object is expected here')

    /**
     *  From here on we are pretty certain data is actually an object
     */

    // Use the provided property key if provided
    if (sortOptions.options && sortOptions.options?.property) {
      const result = lookup(sortOptions.options.property, value)
      if (!isPrimitive(result)) {
        throw new Error('result ist not a primitive value')
      }

      return result
    }

    // Use the first item of the object if no key is provided
    const firstValue = Object.values(value)[0]
    if (!isPrimitive(firstValue)) {
      throw new Error('firstValue is not a primitive value')
    }

    return firstValue
  }

  function isPrimitive(value: unknown): value is Primitive {
    if (typeof value === 'string') return true
    if (typeof value === 'number') return true
    if (typeof value === 'boolean') return true
    if (value instanceof Date) return true

    return false
  }

  function updateSortOptions(options: SortOptions) {
    sortOptions.value = {
      id: options.id,
      direction: options.direction,
      options: cloneDeep(options.options),
    }
  }

  return {
    data: dataSorted,
    updateSortOptions,
  }
}
