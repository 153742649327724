<template>
  <div class="PmSidebarFreelancers">
    <PmSidebarSectionPure
      title="Externe Dienstleister"
      :has-action="true"
      :action-icon="ICONS.PLUS_CIRCLE"
      action-title="Resourcen-Status für externe Dienstleister erstellen"
      :can-be-collapsed="true"
      :collapsed="collapsableArea.isCollapsed.value"
      @expand="collapsableArea.expand"
      @collapse="collapsableArea.collapse"
      @execute-action="emit('createResourceState')"
    >
      <PmSidebarResourceGroupPure
        v-if="freelancersSorted.length > 0"
        :resources="freelancersSorted"
        :type="RESOURCE_TYPE.ADDRESS"
        @highlight="
          (id) => store.commit('persoplan/setTimelineHighlightedId', id)
        "
        @un-highlight="store.commit('persoplan/clearTimelineHighlightedId')"
      />
    </PmSidebarSectionPure>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy } from 'lodash-es'
import { useStore } from 'vuex'

import { RESOURCE_TYPE } from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'

import { useCachedQuery } from '@/composition/useCachedQuery'
import { startDateForServer, endDateForServer } from '@/utilities/date'
import { getReducedFreelancerTimelineData } from '@/functional/freelancer'
import { useCollapsableArea } from '@/composition/useCollapsableArea'
import { useResourceTimelines } from '@/pinia/resourceTimelines'

import PmSidebarSectionPure from '@/components/persoplan/PmSidebarSection/PmSidebarSectionPure.vue'
import PmSidebarResourceGroupPure from '@/components/persoplan/PmSidebarResourceGroupPure.vue' // type Resource,

import { SidebarFreelancersDocument } from '@/../generated/graphql'

export interface Props {
  startDate: Date
  endDate: Date
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'createResourceState'): void
}>()

const store = useStore()
const collapsableArea = useCollapsableArea({ id: 'freelancers' })
const resourceTimelines = useResourceTimelines()

const freelancerCalendarQuery = useCachedQuery(
  SidebarFreelancersDocument,
  () => ({
    startDate: startDateForServer(props.startDate),
    endDate: endDateForServer(props.endDate),
  })
)

const freelancerCalendar = computed(
  () => freelancerCalendarQuery.result.value?.freelancerCalendar
)

const freelancersNormalized = computed(() => {
  if (!freelancerCalendar.value) return []

  const reducedFreelancerTimelineData = getReducedFreelancerTimelineData(
    freelancerCalendar.value
  )
  if (!reducedFreelancerTimelineData) return []
  // TODO: Remove `any` when 'functional/freelancer.ts' is fully converted to TypeScript
  // REFERENCE: 6a0kcd8
  const filtered = Object.values(reducedFreelancerTimelineData) as any

  filtered.forEach((item) => {
    let isHighlighted = false

    if (store.state.persoplan.timelineHighlightedId === item.id) {
      isHighlighted = true
    }

    if (
      resourceTimelines.highlightedTimeline?.id === item.id &&
      resourceTimelines.highlightedTimeline?.type === 'address'
    ) {
      isHighlighted = true
    }

    item.isHighlighted = isHighlighted
  })

  return filtered
})

const freelancersSorted = computed(() => {
  /**
   * If you change something here, also change in
   * src/components/persoplan/PmResourceTimeline/PmResourceTimelinesFreelancers.vue
   */
  return orderBy(
    freelancersNormalized.value,
    ['lastName', 'firstName', 'title'],
    ['asc', 'asc', 'asc']
  )
})
</script>

<style lang="scss">
.PmSidebarFreelancers {
  $block: &;

  &-control {
    padding: 10px;
  }
}
</style>
