<template>
  <div class="PmGroupContainerPure" :class="classes">
    <div class="PmGroupContainerPure-header">
      <PmPillPure
        :key="icon"
        :label="label"
        :icon="icon"
        :is-interactive="true"
        class="PmGroupContainerPure-pill"
        @click-on-icon="toggle"
      />
    </div>

    <div v-if="!collapsed" class="PmGroupContainerPure-content">
      <slot />
    </div>
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'

import PmPillPure from '@/components/basics/PmPillPure.vue'

export default {
  name: 'PmGroupContainerPure',
  components: {
    PmPillPure,
  },

  model: {
    prop: 'collapsed',
    event: 'toggle',
  },

  props: {
    label: { type: String, required: true },
    collapsed: { type: Boolean, default: false },
  },

  emits: ['toggle', 'expand', 'collapse'],

  computed: {
    icon() {
      return this.collapsed ? ICONS.PLUS : ICONS.MINUS
    },

    classes() {
      return {
        'is-collapsed': this.collapsed,
      }
    },
  },

  methods: {
    toggle() {
      this.$emit('toggle', this.collapsed ? false : true)

      if (this.collapsed) this.expand()
      else this.collapse()
    },

    expand() {
      this.$emit('expand')
    },

    collapse() {
      this.$emit('collapse')
    },
  },
}
</script>

<style lang="scss">
.PmGroupContainerPure {
  $block: &;

  &-header {
    height: 41px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 0;

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      background-color: color.$gray-200--alpha;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    #{$block}.is-collapsed & {
      background-color: color.$gray-200--alpha;
    }
  }

  &-pill {
    position: sticky;
    z-index: 1;
    left: 50%;
    transform: translateX(
      calc((var(--sidebarLeftWidthWithVisibility) / 2) - 50%)
    );
  }
}
</style>
