<template>
  <PmViewDuplicatePure
    class="PmViewEditorControlPure-section"
    :loading="xstate.state.value.matches('duplicate.duplicating')"
    :error="xstate.state.value.matches('duplicate.failed')"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :is-public-view-checkbox-visible="can('create', 'PublicViews')"
    :user-name-of-view-owner="userNameOfViewOwner"
    :existing-view-names="existingViewNames"
    @duplicate="
      ({ name, isPublic }) =>
        xstate.service.value.send('DUPLICATE', { name, isPublic })
    "
    @close="emit('close')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmViewDuplicate'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { cloneDeep } from 'lodash-es'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { ICONS } from '@/constants/icons'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { PmViewDuplicateState } from '@/components/persoplan/PmViewDuplicate/PmViewDuplicateState'
import { useAppAbility } from '@/composition/useAppAbility'

import PmViewDuplicatePure from '@/components/persoplan/PmViewDuplicate/PmViewDuplicatePure.vue'

import {
  ViewsDocument,
  CurrentUserDocument,
  CreateViewDocument,
} from '@/../generated/graphql'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const store = useStore()
const { can } = useAppAbility()

const xstate = useXState(PmViewDuplicateState, {
  services: {
    duplicateView: (context, { name, isPublic }) =>
      duplicateView({ name, isPublic }),
  },
})

const viewsQuery = useQuery(ViewsDocument)
const views = computed(() => viewsQuery.result.value?.views)

const userQuery = useQuery(CurrentUserDocument)
const user = computed(() => userQuery.result.value?.user)

const viewTitle = computed(() => {
  return store.state.view.title
})

const isPublicView = computed(() => {
  if (!views.value) return

  const currentView = views.value.find((view) => {
    return view?.id === store.state.view.id
  })

  if (!currentView) {
    return false
  }

  return currentView.public === true ? true : false
})

const userNameOfViewOwner = computed(() => {
  // TODO: Use actual username of view owner
  return 'Samson'
})

const existingViewNames = computed(() => {
  if (!views.value) return

  const viewNames: string[] = []

  views.value.forEach((view) => {
    if (!view) return
    viewNames.push(view.title)
  })

  return viewNames
})

const createViewMutation = useMutation(CreateViewDocument)

async function duplicateView({
  name,
  isPublic,
}: {
  name: string
  isPublic: boolean
}) {
  if (!store.state.view.id) {
    throw new Error('no view id in store')
  }

  try {
    const query = JSON.stringify(store.state.view.currentView)

    await createViewMutation.mutate(
      {
        title: name,
        public: isPublic,
        query: query,
      },
      {
        update: (cache, result) => {
          const view = result.data?.createPersoPlanView
          if (!view) throw new Error('view is undefined')

          const readQueryResult = cache.readQuery({ query: ViewsDocument })

          // readQuery is readonly, thus we need to create a deep copy
          const data = cloneDeep(readQueryResult)
          if (!data?.views) throw new Error('data.views is undefined')

          data.views.push(view)

          cache.writeQuery({ query: ViewsDocument, data })

          store.commit('view/setView', {
            id: view.id,
            title: view.title,
            query: view.query,
          })
        },
      }
    )
  } catch (error) {
    throwFriendlyError(error)
  }

  store.commit('notification/add', {
    variant: 'success',
    icon: ICONS.DUPLICATE,
    title: 'Ansicht dupliziert',
  })

  emit('close')
}
</script>
