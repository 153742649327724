<template>
  <tr :class="{ [componentClass.root]: true, ...classes }">
    <td
      v-for="column in columns"
      :key="column.id"
      :class="{
        [componentClass.element('cell')]: true,
        ...getCellClasses({
          padding: column.padding,
        }),
      }"
    >
      <slot :name="`cell(${column.id})`" />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmTableRowPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { injectStrict, tableKey } from '@/utilities/inject'
import { useComponentClass } from '@/composition/useComponentClass'

export interface Props {
  disabled?: boolean
  selected?: boolean
  active?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const { columns, isTableHeadVisible } = injectStrict(tableKey)

const componentClass = useComponentClass()

const classes = computed(() => {
  return {
    'is-disabled': props.disabled,
    'is-selected': props.selected,
    'is-active': props.active,
    [`${componentClass.modifier('tableHasHead')}`]:
      isTableHeadVisible.value === true,
  }
})

function getCellClasses(options?: { padding?: boolean }) {
  return {
    [`${COMPONENT_NAME}-cell--withPadding`]: options?.padding !== false,
  }
}
</script>

<style lang="scss">
.PmTableRowPure {
  $block: &;
  $parent: '.PmTablePure';

  $borderRadius: constant.$borderRadius-default;

  @include cssVar.define(
    $block,
    'cellPadding',
    cssVar.use($parent, 'cellPadding')
  );
  @include cssVar.define(
    $block,
    'colorBorder',
    cssVar.use($parent, 'colorBorder')
  );
  @include cssVar.define(
    $block,
    'colorBorderInline',
    cssVar.use($parent, 'colorBorderInline')
  );
  @include cssVar.define(
    $block,
    'borderRadius',
    cssVar.use($parent, 'borderRadius')
  );
  @include cssVar.define($block, 'colorBackground', inherit);
  @include cssVar.define($block, 'colorBackground--hover', color.$gray-50);
  @include cssVar.define($block, 'colorText', inherit);
  @include cssVar.define($block, 'colorOutline', transparent);

  &.is-active {
    @include cssVar.define($block, 'colorBackground', color.$primary-50);
    @include cssVar.define(
      $block,
      'colorBackground--hover',
      color.$primary-100
    );
    @include cssVar.define($block, 'colorOutline', color.$primary-500);
  }

  display: grid;
  grid-column: -1 / 1;
  grid-template-columns: subgrid;
  outline: 2px solid cssVar.use($block, 'colorOutline');
  outline-offset: -1px;
  background-color: cssVar.use($block, 'colorBackground');

  // background-color: color.$primary-50;

  &:not(:last-child) {
    border-bottom: 1px solid cssVar.use($block, 'colorBorderInline');
  }

  &:not(#{$block}--tableHasHead) {
    &:first-child {
      border-top-left-radius: cssVar.use($block, 'borderRadius');
      border-top-right-radius: cssVar.use($block, 'borderRadius');
    }
  }

  &:last-child {
    border-bottom-left-radius: cssVar.use($block, 'borderRadius');
    border-bottom-right-radius: cssVar.use($block, 'borderRadius');
  }

  /**
   * Unset the border-radius for bottom corners, when it's the last child but
   * in a grouped table, where it's not the last group
   */
  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  @at-root .PmTableGroupPure:not(:last-child) &:last-child {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  &:hover {
    background-color: cssVar.use($block, 'colorBackground--hover');
  }

  &.is-disabled {
    @include cssVar.define($block, 'colorText', color.$text-light);

    pointer-events: none;
  }

  &-cell {
    vertical-align: middle;
    color: cssVar.use($block, 'colorText');

    &--withPadding {
      padding: cssVar.use($block, 'cellPadding');
    }

    #{$block}.is-disabled & {
      // Give non-text contents opacity of 0.5
      // stylelint-disable-next-line plugin/selector-bem-pattern
      & > * {
        opacity: 0.5;
      }
    }
  }
}
</style>
