import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultipleResourceAllocationsControllerState.typegen'

export type State = Typegen0['matchesStates']

export const PmMultipleResourceAllocationsControllerState = createMachine({
  tsTypes:
    {} as import('./PmMultipleResourceAllocationsControllerState.typegen').Typegen0,
  schema: {},

  id: 'PmMultipleResourceAllocationsControllerState',
  initial: 'default',

  states: {
    default: {},
  },
})

export const states = extractAllStates(
  PmMultipleResourceAllocationsControllerState
)
