<template>
  <component
    :is="tag"
    class="PmPillPure"
    :style="styles"
    :class="classes"
    :tabindex="isInteractive ? 0 : -1"
    :title="title || label"
  >
    <PmLoadingPure v-if="loading" class="PmPillPure-loading" />

    <template v-if="!loading">
      <div v-if="arrowStart" class="PmPillPure-arrow PmPillPure-arrow--start">
        <PmIconPure :name="ICONS.ARROW_LEFT" />
      </div>

      <div class="PmPillPure-content">
        <div class="PmPillPure-text">
          <slot />
          {{ labelNormalized }}
        </div>

        <div
          v-if="icon"
          class="PmPillPure-icon"
          @click.stop="isInteractive ? emit('clickOnIcon') : null"
        >
          <PmIconPure :key="icon" :name="icon" />
        </div>
      </div>

      <div v-if="arrowEnd" class="PmPillPure-arrow PmPillPure-arrow--end">
        <PmIconPure :name="ICONS.ARROW_RIGHT" />
      </div>
    </template>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmPillPure'

export const propTypes = {
  variant: {
    allowed: ['danger', 'primary'] as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
// TODO: Add title to root element, but this needs a refactoriong from slot to prop for the label

import { computed, useAttrs } from 'vue'
import Color from 'color'

import { ICONS } from '@/constants/icons'
import scssVariables from '@/assets/scss/scssVariables'

import { separateAttrs } from '@/utilities/misc'

import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

import type { Props as PropsIconPure } from '@/components/basics/PmIcon/PmIconPure.vue'
import type { Nilable } from '@/types/misc'

export interface Props {
  color?: string
  icon?: PropsIconPure['name']
  disabled?: boolean
  isInactive?: boolean
  isInteractive?: boolean
  loading?: boolean
  variant?: (typeof propTypes.variant.allowed)[number]
  label?: Nilable<string | number>
  title?: string
  arrowStart?: boolean
  arrowEnd?: boolean
  stickyContent?: boolean
  underlineOnHover?: boolean
}

const props = withDefaults(defineProps<Props>(), {})
const attrs = useAttrs()

const emit = defineEmits<{
  (event: 'clickOnIcon'): void
}>()

const labelNormalized = computed(() => {
  if (!props.label) return

  if (typeof props.label === 'number' && Number.isInteger(props.label)) {
    if (props.label > 99) {
      return '99+'
    }
  }

  return props.label
})

const normalizedColor = computed(() => {
  if (props.variant === 'danger') return scssVariables.color['danger-600']
  if (props.variant === 'primary') return scssVariables.color['primary-600']

  return props.color
})

const colorText = computed(() => {
  if (!normalizedColor.value) return undefined

  const color = Color(normalizedColor.value)

  if (color.isLight()) return color.darken(0.75)
  else return color.darken(0.5)
})

const colorBackground = computed(() => {
  if (!normalizedColor.value) return undefined

  const color = Color(normalizedColor.value)

  if (color.isLight()) return color.alpha(0.4)
  return color.alpha(0.3)
})

const colorBackgroundHover = computed(() => {
  if (!normalizedColor.value) return undefined

  const color = Color(normalizedColor.value)

  if (color.isLight()) return color.alpha(0.6)
  return color.alpha(0.4)
})

const colorBorder = computed(() => {
  if (!normalizedColor.value) return undefined
  if (!colorText.value) return undefined

  return colorText.value.alpha(0.2)
})

const styles = computed(() => {
  return {
    [`--${COMPONENT_NAME}-colorText`]: colorText.value,
    [`--${COMPONENT_NAME}-colorBackground`]: colorBackground.value,
    [`--${COMPONENT_NAME}-colorBackground--hover`]: colorBackgroundHover.value,
    [`--${COMPONENT_NAME}-colorBorder`]: colorBorder.value,
  }
})

const tag = computed(() => {
  const hasClickEventListener = Object.keys(
    separateAttrs(attrs).listeners
  ).includes('onClick')

  if (hasClickEventListener) return 'button'
  return 'div'
})

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--withIcon`]: props.icon,
    [`${COMPONENT_NAME}--arrowStart`]: props.arrowStart,
    [`${COMPONENT_NAME}--arrowEnd`]: props.arrowEnd,
    [`${COMPONENT_NAME}--stickyContent`]: props.stickyContent,
    [`${COMPONENT_NAME}--underlineOnHover`]: props.underlineOnHover,
    'is-disabled': props.disabled,
    'is-inactive': props.isInactive,
    'is-interactive': props.isInteractive,
    'is-button': tag.value === 'button',
    'is-moreThanTwoCharacterLabel': props.label
      ? props.label?.toString().length > 2
      : false,
  }
})
</script>

<style lang="scss">
.PmPillPure {
  $block: &;

  @include cssVar.define($block, 'colorBase', transparent);
  @include cssVar.define($block, 'colorText', color.$text-default);
  @include cssVar.define($block, 'colorBackground', color.$gray-100);
  @include cssVar.define($block, 'colorBackground--hover', color.$gray-200);
  @include cssVar.define($block, 'colorBorder', color.$gray-300);
  @include cssVar.define($block, 'colorBorder--hover', color.$gray-400);
  @include mixin.transition-hover(
    $properties: (
      background-color,
    )
  );

  min-width: 20px;
  max-height: 20px;
  max-width: 100%;
  text-align: center;
  background-color: cssVar.use($block, 'colorBackground');
  font-weight: 700;
  font-size: constant.$fontSize-default;
  color: cssVar.use($block, 'colorText');
  border-radius: 20px;
  position: relative;
  white-space: nowrap;
  border: 1px solid cssVar.use($block, 'colorBorder');
  cursor: default;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &.is-moreThanTwoCharacterLabel {
    min-width: 30px;
  }

  &--arrowStart {
    padding-left: 16px;
  }

  &--arrowEnd {
    padding-right: 16px;
  }

  &.is-disabled {
    opacity: 0.5;
  }

  &.is-inactive {
    opacity: 0.5;
  }

  &:hover,
  &.is-hover {
    background-color: cssVar.use($block, 'colorBackground--hover');
    border-color: cssVar.use($block, 'colorBorder--hover');
  }

  &:focus,
  &.is-focus {
    outline: none;

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    .is-keyboardNavigation & {
      border-color: color.$key;
      box-shadow: 0 0 0 3px rgba(color.$key, 0.5);
    }
  }

  &.is-button {
    cursor: pointer;
  }

  &-loading {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      width: 55%;
      height: 55%;
    }
  }

  &-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 0;

    #{$block}--stickyContent & {
      position: sticky;
      left: 8px;
      right: 8px;
    }
  }

  &-text {
    @include mixin.truncateText;

    padding: 2px 5px;

    #{$block}--withIcon & {
      padding-right: 2px;
    }

    #{$block}--underlineOnHover:hover &,
    #{$block}--underlineOnHover.is-hover & {
      text-decoration: underline;
    }
  }

  &-icon {
    @include mixin.transition-hover(color);

    margin-right: 2px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    & svg {
      width: 55%;
      height: 55%;
    }

    #{$block}.is-interactive & {
      &:hover {
        color: color.$text-default--hover;
      }
    }
  }

  &-arrow {
    width: 16px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    & svg {
      width: 55%;
      height: 55%;
    }

    &--start {
      left: 0;
    }

    &--end {
      right: 0;
    }
  }
}
</style>
