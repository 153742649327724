<template>
  <div class="PmGeneralPageContainerPure">
    <slot />

    <div class="PmGeneralPageContainerPure-control">
      <slot name="control" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.PmGeneralPageContainerPure {
  $block: &;

  padding-left: var(--space-small);
  padding-right: var(--space-small);
  background-color: color.$gray-100;
  min-height: 100%;

  &-control {
    position: fixed;
    top: calc(var(--navigationHeight) + 4px);
    right: 4px;
    z-index: 2;
  }
}
</style>
