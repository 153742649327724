import { applyPatch } from 'fast-json-patch'

/**
 * JSON-Patch
 * @see http://jsonpatch.com/
 */

export const migrations = [
  {
    from: '2.0.0',
    to: '2.0.1',
    up: (schema) => {
      const patch = [
        {
          op: 'replace',
          path: '/version',
          value: '2.0.1',
        },
        {
          op: 'remove',
          path: '/showJobs',
        },
      ]

      applyPatch(schema, patch)
      return schema
    },
  },
]
