<template>
  <Portal to="modal">
    <PmModalPure :can-be-closed="false" title="Urlaubsantrag">
      <div :class="componentClass.root">
        <PmFormPure
          v-if="!xstate.snapshot.matches({ create: 'success' })"
          :id="formId"
          :disabled="isDisabled"
          @submit.prevent="onSubmit"
        >
          <PmRadioListPure
            v-model:checkedId="selectedTypeId"
            :required="true"
            label="Art des Urlaubs"
            name="leaveRequestType"
            :items="optionsType"
          />

          <PmDateRangePickerPure v-model:value="dates" />
          <PmTextareaPure v-model:value="note" label="Bemerkung" :rows="4" />

          <template v-if="xstate.meta.value?.error" #error>
            <PmErrorNotificationPure
              :message="errorMessage || xstate.meta.value?.message"
              :details="errorDetails"
            />
          </template>
        </PmFormPure>
      </div>

      <template #footer>
        <PmButtonListPure
          v-if="!xstate.snapshot.matches({ create: 'success' })"
          layout="distribute"
        >
          <PmButtonPure
            label="Abbrechen"
            icon="close"
            :disabled="isDisabled"
            @click="emit('close')"
          />

          <PmButtonPure
            variant="primary"
            label="Einreichen"
            type="submit"
            :form="formId"
            icon="send"
            :loading="xstate.snapshot.matches({ create: 'saving' })"
            :disabled="isDisabled"
          />
        </PmButtonListPure>
      </template>
    </PmModalPure>
  </Portal>
</template>

<script setup lang="ts">
import { ref, toRef, computed } from 'vue'
import { Portal } from 'portal-vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import cuid from '@paralleldrive/cuid2'
import type { Entries } from 'type-fest'

import { useXState, type StatePathOrValue } from '@/composition/useXState5'
import { PmCreateLeaveRequestPureState } from '@/components/PmCreateLeaveRequest/PmCreateLeaveRequestPureState'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmRadioListPure, {
  type Props as PropsRadioListPure,
} from '@/components/basics/PmRadioList/PmRadioListPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'
import PmDateRangePickerPure from '@/components/basics/PmDateRangePicker/PmDateRangePickerPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import { LEAVE_REQUEST_TYPE_LABEL_LOOKUP } from '@/constants/leaveRequest'

export interface Props {
  state?: StatePathOrValue<typeof PmCreateLeaveRequestPureState>
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  submit: {
    startDate: Date
    endDate: Date
    type: SelectedTypeId
    note?: string
  }
}

const emit = defineEmits<{
  close: []
  submit: [Emits['submit']]
}>()

const componentClass = useComponentClass()
const xstate = useXState(PmCreateLeaveRequestPureState, {
  syncStateWith: toRef(props, 'state'),
})

const dates = ref({
  start: new Date(),
  end: new Date(),
})

const formId = `${componentClass.root}.${cuid.createId()}`

const optionsType = (
  Object.entries(LEAVE_REQUEST_TYPE_LABEL_LOOKUP) as Entries<
    typeof LEAVE_REQUEST_TYPE_LABEL_LOOKUP
  >
).map(([id, label]) => {
  return {
    id,
    label,
  }
}) satisfies PropsRadioListPure['items']

type SelectedTypeId = (typeof optionsType)[number]['id']
const selectedTypeId = ref<SelectedTypeId>()
const note = ref<string>()

function onSubmit() {
  if (!selectedTypeId.value) throw new Error('selectedTypeId is undefined')

  emit('submit', {
    startDate: dates.value.start,
    endDate: dates.value.end,
    type: selectedTypeId.value,
    note: note.value,
  })
}

const isDisabled = computed(() => {
  if (xstate.value.snapshot.matches({ create: 'saving' })) return true
  return false
})
</script>

<style lang="scss">
.PmCreateLeaveRequestPure {
  $block: &;
}
</style>
