import { createStore } from 'vuex'
import { has, set } from 'lodash-es'

import { default as view, defaultView } from '@/store/modules/view'
import error from '@/store/modules/error'
import notification from '@/store/modules/notification'
import dragAndDrop from '@/store/modules/dragAndDrop'
import persoplan from '@/store/modules/persoplan'
import cssVar from '@/store/modules/cssVar'
import queryVariables from '@/store/modules/queryVariables'
import auth from '@/store/modules/auth'

import syncCssVarsToDocument from '@/store/plugins/syncCssVarsToDocument'
import persistSettings from '@/store/plugins/persistSettings'
import persistInUrl from '@/store/plugins/persistInUrl'

import { migrateType } from '@/migrations/runMigration'
import { MIGRATION_TYPE } from '@/migrations/constants'

export default createStore({
  // strict: debug,
  strict: false,

  state: {},

  mutations: {
    applyPersistedSettings(state, settings) {
      Object.entries(settings).forEach(([statePath, value]) => {
        const hasStatePath = has(state, statePath)

        if (!hasStatePath) {
          console.warn(
            `state does not have statePath "${statePath}". Skip setting`
          )
        }

        // Run migrations on view settings
        if (
          statePath === 'view.currentView' ||
          statePath === 'view.savedView'
        ) {
          value = migrateType({
            schema: value,
            type: MIGRATION_TYPE.VIEW,
            to: defaultView.version,
          })
        }

        set(state, statePath, value)
      })
    },
  },

  modules: {
    view,
    error,
    notification,
    dragAndDrop,
    persoplan,
    cssVar,
    queryVariables,
    auth,
  },

  plugins: [syncCssVarsToDocument, persistSettings, persistInUrl],
})
