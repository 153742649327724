import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultiSelectionControllerPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmMultiSelectionControllerPureState = createMachine({
  tsTypes:
    {} as import('./PmMultiSelectionControllerPureState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'DO_ALLOCATION' },
  },

  id: 'PmMultiSelectionControllerPureState',
  initial: 'default',
  states: {
    default: {
      on: {
        DO_ALLOCATION: 'allocate',
      },
    },

    allocate: {
      initial: 'askForConfirmation',
      states: {
        askForConfirmation: {},
        saving: {},
        success: {},
        failed: {},
      },
    },
  },
})

export const states = extractAllStates(PmMultiSelectionControllerPureState)
