import { Comment, Text } from 'vue'
import type { Slot, VNode } from 'vue'
import type { PartiallyOptional } from '@/types/misc'

// Copied from @vue/runtime-core
export type Data = Record<string, unknown>

/**
 * Check if a slot has content
 * @see https://github.com/vuejs/core/issues/4733#issuecomment-1024816095
 */
export function hasSlotContent(
  slot: Slot | undefined,
  slotProps = {},
  options?: { allowEmptyText?: boolean }
): boolean {
  if (!slot) return false

  return slot(slotProps).some((vnode: VNode) => {
    if (vnode.type === Comment) return false

    if (Array.isArray(vnode.children) && !vnode.children.length) return false

    if (vnode.type !== Text) return true

    if (typeof vnode.children === 'string') {
      if (options?.allowEmptyText === true) return true
      if (vnode.children.trim() !== '') return true
    }

    return false
  })
}

/**
 * @see https://forum.vuejs.org/t/vue-3-how-can-i-filter-listeners-from-attrs/113078
 */
export function separateAttrs(
  attrs: Data,
  options: { includeClass?: boolean } = {}
) {
  const { includeClass = false } = options

  const regex = /^on[^a-z]/
  const attributes: { [key: string]: unknown } = {}
  const listeners: { [key: string]: unknown } = {}

  for (const property in attrs) {
    if (regex.test(property)) {
      listeners[property] = attrs[property]
    } else {
      attributes[property] = attrs[property]
    }
  }

  if (!includeClass) {
    delete attributes.class
  }

  return { attributes, listeners }
}

type LookupTableBase = { [key: string | number]: string | number | unknown }
type LookupResult<LookupTable> = LookupTable[keyof LookupTable]

export function lookup<
  Key extends string | number,
  LookupTable extends LookupTableBase,
>(
  key: Key | undefined | null,
  lookupTable: LookupTable
): LookupResult<LookupTable> | undefined {
  if (!key) return

  const keyExists = key in lookupTable

  if (!keyExists) {
    // console.trace(`key ${key} does not exist in lookupTable`)
    return undefined
  }

  return lookupTable[key]
}

/**
 * Filter out all array items which are null or undefined
 * @example myArray.filter(notEmpty)
 */
export function notEmpty<Value>(
  value: Value | null | undefined
): value is Value {
  if (value === null || value === undefined) return false

  const testDummy: Value = value
  return true
}

/**
 * Checks if an object has no undefined or null properties
 * You need to make sure, that all properties of your object, which
 * should be checked are filled with some value
 */
export function hasNoNilProperties<
  ObjectToCheck extends Record<string, unknown>,
  Options extends { ignore: readonly (keyof ObjectToCheck)[] },
>(
  objectToCheck: ObjectToCheck,
  options?: Options
  // @ts-expect-error Can't solive this one
): asserts objectToCheck is PartiallyOptional<
  Required<ObjectToCheck>,
  Options['ignore'][number]
> {
  const propertiesWhichAreNil = Object.keys(objectToCheck).filter((key) => {
    const value = objectToCheck[key]

    // Cancel early if key is in the options.ignored
    const keyShouldBeIgnored = options?.ignore?.includes(key)
    if (keyShouldBeIgnored) return false

    // value is nil
    if (value === null || value === undefined) return true

    return false
  })

  if (propertiesWhichAreNil.length > 0) {
    const missingProps = propertiesWhichAreNil.join(', ')
    throw new Error(`Not all properties are filled, missing: ${missingProps}`)
  }
}
