<template>
  <PmSidebarPure
    :collapsed="!store.state.persoplan.sidebarVisible"
    :content-visible="!isDraggingDayWidth"
  >
    <template #settings>
      <PmSettings
        v-if="store.state.persoplan.sidebarLeftVisible === true"
        @open-search="emit('openSearch')"
        @open-date-controller="emit('openDateController')"
        @create-resource-state-freelancer="
          emit('createResourceStateFreelancer')
        "
      />
    </template>

    <PmSidebarGeneralEvents
      v-if="store.state.view.currentView.showGeneralEvents"
    />

    <PmSidebarCalendarEvents
      v-if="store.state.view.currentView.showCalendarEvents"
    />

    <PmSidebarBirthdays v-if="store.state.view.currentView.showBirthdays" />

    <PmSidebarFreelancers
      v-if="store.state.view.currentView.showFreelancers"
      :start-date="store.state.persoplan.visibleStartDate"
      :end-date="store.state.persoplan.visibleEndDate"
      @create-resource-state="emit('createResourceStateFreelancer')"
    />

    <PmSidebarEmployees v-if="store.state.view.currentView.showEmployees" />

    <PmSidebarVehicles v-if="store.state.view.currentView.showVehicles" />

    <template v-if="true" #jobs>
      <PmSidebarProjects :ids="normalizedIds" />

      <template v-if="FEATURE_FLAG.OPERATIONS">
        <PmSidebarOperations
          v-if="store.state.view.currentView.showOperations"
          :start-date="store.state.persoplan.visibleStartDate"
          :end-date="store.state.persoplan.visibleEndDate"
          :show-only-with-resources="
            store.getters['queryVariables/calendar'].showOnlyWithResources
          "
        />
      </template>
    </template>
  </PmSidebarPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebar'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import { DRAG_AND_DROP_TYPE } from '@/constants/persoplan'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import { useLoading } from '@/composition/useLoading'
import { useDragAndDrop } from '@/pinia/dragAndDrop'

import PmSidebarPure from '@/components/persoplan/PmSidebarPure.vue'
import PmSettings from '@/components/persoplan/PmSettings/PmSettings.vue'
import PmSidebarProjects, {
  type Props as PropsSidebarProjects,
} from '@/components/persoplan/PmSidebarProjects/PmSidebarProjects.vue'
import PmSidebarFreelancers from '@/components/persoplan/PmSidebarFreelancers/PmSidebarFreelancers.vue'
import PmSidebarEmployees from '@/components/persoplan/PmSidebarEmployees/PmSidebarEmployees.vue'
import PmSidebarVehicles from '@/components/persoplan/PmSidebarVehicles/PmSidebarVehicles.vue'
import PmSidebarBirthdays from '@/components/persoplan/PmSidebar/PmSidebarBirthdays.vue'
import PmSidebarGeneralEvents from '@/components/persoplan/PmSidebar/PmSidebarGeneralEvents.vue'
import PmSidebarCalendarEvents from '@/components/persoplan/PmSidebar/PmSidebarCalendarEvents.vue'
import PmSidebarOperations from '@/components/persoplan/PmSidebarOperations/PmSidebarOperations.vue'

import { SidebarCacheDocument } from '@/../generated/graphql'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openSearch'): void
  (event: 'openDateController'): void
  (event: 'createResourceStateFreelancer'): void
  (event: 'initialLoadingFinished'): void
}>()

const store = useStore()

const { endLoading } = useLoading({
  loaders: ['cache'],
  autoStart: true,
  onLoadingFinished: () => {
    emit('initialLoadingFinished')
  },
})

const dragAndDrop = useDragAndDrop()

const isDraggingDayWidth = computed(() => {
  return dragAndDrop.type === DRAG_AND_DROP_TYPE.CHANGE_DAY_WIDTH
})

const normalizedIds = computed(() => {
  if (!cache.value?.projects) return

  const ids: PropsSidebarProjects['ids'] = {
    projectIds: {},
  }

  cache.value?.projects.forEach((project) => {
    if (!project?.id) return
    if (!project.jobs) return

    const jobIds = project.jobs.reduce<number[]>((result, job) => {
      if (!job?.id) return result

      result.push(job.id)
      return result
    }, [])

    ids.projectIds[project.id] = {
      jobIds,
    }
  })

  return ids
})

const cacheQuery = useQuery(
  SidebarCacheDocument,
  () => {
    const queryVariables = store.getters['queryVariables/calendar']
    return queryVariables
  },
  () => ({
    fetchPolicy: 'network-only',
  })
)

watch(
  () => cacheQuery.loading.value,
  () => endLoading('cache')
)

const cache = computed(() => cacheQuery.result.value)
</script>
