<template>
  <div :class="[componentClass.root, classes]">
    <div :class="componentClass.element('inner')">
      <div v-if="isBackVisible" :class="componentClass.element('back')">
        <PmButtonPure
          :icon="ICONS.ARROW_LEFT"
          :label="backLabel"
          @click="$emit('back')"
        />
      </div>

      <header :class="componentClass.element('header')">
        <div :class="componentClass.element('headerContent')">
          <h1 :class="componentClass.element('title')">{{ title }}</h1>

          <div :class="componentClass.element('headerSlot')">
            <slot name="header" />
          </div>
        </div>
      </header>

      <div :class="componentClass.element('content')">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

const COMPONENT_NAME = 'PmGeneralPagePure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import PmGeneralPageSectionPure from '@/components/PmGeneralPage/PmGeneralPageSectionPure.vue'

import { ICONS } from '@/constants/icons'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

export interface Props {
  title: string
  isBackVisible?: boolean
  backLabel?: string
  layout: 'default' | 'fullWidth'
}

const props = withDefaults(defineProps<Props>(), {
  backLabel: 'Zurück',
  layout: 'default',
})

const emit = defineEmits<{
  (event: 'back'): void
}>()

const componentClass = useComponentClass()

const classes = computed(() => {
  return {
    [`${[COMPONENT_NAME]}--layoutDefault`]: props.layout === 'default',
    [`${[COMPONENT_NAME]}--layoutFullWidth`]: props.layout === 'fullWidth',
  }
})
</script>

<style lang="scss">
.PmGeneralPagePure {
  $block: &;

  @include cssVar.define($block, 'maxWidth', unset);

  &--layoutDefault {
    @include cssVar.define($block, 'maxWidth', constant.$viewportMaxWidth);
  }

  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-inner {
    margin-top: var(--space-large);
    max-width: cssVar.use($block, 'maxWidth');
    width: 100%;
    // outline: 1px solid red;
    position: relative;
  }

  &-back {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    padding-bottom: var(--space-smallest);
  }

  &-header {
    display: flex;
    align-items: start;

    // Psuedo Element to provide margin bottom but make aligning flex items easier
    &::before {
      @include typography.h1;

      content: '\00a0';
      width: 0;
    }
  }

  &-headerContent {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &-headerSlot {
    margin-left: auto;
  }

  &-title {
    @include typography.h1($includeMargin: false);
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
