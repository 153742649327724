import { ref, computed, nextTick, onBeforeUnmount, type ComputedRef } from 'vue'
import type { Ref } from 'vue'
import { subject } from '@casl/ability'
import { differenceInMinutes, isSameDay } from 'date-fns'

import {
  RESOURCE_DAY_ITEM_TYPE,
  RESOURCE_ALLOCATION_HIGHLIGHT_REASON,
} from '@/constants/persoplan'

import { EVENT } from '@/constants/events'

import { parseServerDateString } from '@/utilities/date'
import EventHub from '@/eventHub'

import { useAppAbility } from '@/composition/useAppAbility'
import {
  useResourceDayShared,
  getConflictingTimeEntries,
  type ConflictItem,
  type ItemNormalized,
  getResourceDayStatus,
} from '@/components/persoplan/PmResourceDay/useResourceDayShared'
import { useJumpTargets, type JumpTargetBase } from '@/pinia/jumpTargets'

import type { ResourceDayAddressQuery } from '@/../generated/graphql'

export default function useResourceDayAddress({
  addressId,
  agenda,
  additionalConflictItems,
  startDate,
  endDate,
}: {
  addressId: Ref<number | undefined>
  agenda: Ref<ResourceDayAddressQuery['agenda']>
  additionalConflictItems?: Ref<ConflictItem[] | undefined>
  startDate?: ComputedRef<Date | undefined>
  endDate?: ComputedRef<Date | undefined>
}) {
  const { can } = useAppAbility()

  const isNotificationVisible = ref(false)

  const userCanCreateOrEditResourceState = computed(() => {
    return can('create', 'ResourceStateAddress')
  })

  const { normalizedResourceAllocations, normalizedResourceStates } =
    useResourceDayShared({
      agenda,
      userCanCreateOrEditResourceState,
    })

  const normalizedEvents = computed(() => {
    if (!agenda.value?.events) return []

    const result = agenda.value.events.reduce<ItemNormalized[]>(
      (events, event) => {
        if (!event) return events

        const startDate = parseServerDateString(event.startDate)
        const endDate = parseServerDateString(event.endDate)

        if (!startDate) throw new Error('startDate is undefined')
        if (!endDate) throw new Error('endDate is undefined')

        const item: ItemNormalized = {
          id: event.id,
          type: RESOURCE_DAY_ITEM_TYPE.EVENT,
          title: event.caption,
          subtitle: event.location,
          startDate,
          endDate,
          conflictingItems: [],
          durationInMinutes: differenceInMinutes(endDate, startDate),
          resourceDayStatus: undefined, // TODO: Maybe event.attendee.status can be mapped to a resourceDayStatus?
        }

        events.push(item)
        return events
      },
      []
    )

    return result
  })

  const normalizedCalendarEvents = computed(() => {
    if (!agenda.value?.calendarEvents) return []

    const result = agenda.value.calendarEvents.reduce<ItemNormalized[]>(
      (events, calendarEvent) => {
        if (!calendarEvent) return events

        const startDate = parseServerDateString(calendarEvent.startDate)
        const endDate = parseServerDateString(calendarEvent.endDate)

        if (!startDate) throw new Error('startDate is undefined')
        if (!endDate) throw new Error('endDate is undefined')

        const item: ItemNormalized = {
          id: calendarEvent.id,
          type: 'calendarEvent',
          title: calendarEvent.caption,
          startDate,
          endDate,
          conflictingItems: [],
          durationInMinutes: differenceInMinutes(endDate, startDate),
          resourceDayStatus: 'notAvailable',
        }

        events.push(item)
        return events
      },
      []
    )

    return result
  })

  const normalizedItems = computed(() => {
    const items = [
      ...normalizedResourceAllocations.value,
      ...normalizedResourceStates.value,
      ...normalizedEvents.value,
      ...normalizedCalendarEvents.value,
    ]

    // Augment overlapping time entries
    items.forEach((item) => {
      item.conflictingItems = getConflictingTimeEntries(
        item,
        items,
        additionalConflictItems?.value
      )
    })

    return items
  })

  const normalizedTitle = computed(() => {
    if (!agenda.value?.address?.displayName) return
    return agenda.value.address.displayName as string
  })

  const resourceDayStatus = computed(() => {
    if (!startDate?.value || !endDate?.value) return
    if (!isSameDay(startDate.value, endDate.value)) {
      // console.log('No resourceDayStatus for multiple days')
      return
    }

    // From here on, we know startDate and endDate are on the same day
    const day = startDate.value

    return getResourceDayStatus({
      items: normalizedItems.value,
      day: day,
    })
  })

  const jumpTargets = useJumpTargets()

  const jumpToResourceAllocationInCalendar = (id: number) => {
    const jumpTarget: JumpTargetBase = {
      id: id,
      type: 'ResourceAllocation',
    }

    // Check if element is present and visible
    if (!jumpTargets.isAvailable(jumpTarget)) {
      return canNotJumpToResourceAllocation()
    }

    // Clear all previous highlights
    EventHub.$emit(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, {
      reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW,
    })

    EventHub.$emit(EVENT.RESOURCE_ALLOCATION_HIGHLIGHT, {
      id,
      reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW,
    })

    jumpTargets.jumpTo(jumpTarget)
  }

  const canNotJumpToResourceAllocation = async () => {
    if (isNotificationVisible.value === true) {
      isNotificationVisible.value = false
      await nextTick()
    }

    isNotificationVisible.value = true
  }

  onBeforeUnmount(() => {
    EventHub.$emit(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, {
      reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW,
    })
  })

  return {
    userCanCreateOrEditResourceState,
    normalizedItems,
    normalizedResourceAllocations,
    normalizedTitle,
    jumpToResourceAllocationInCalendar,
    isNotificationVisible,
    resourceDayStatus,
  }
}
