import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmDefaultViewsPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmDefaultViewsPureState = createMachine({
  tsTypes: {} as import('./PmDefaultViewsPureState.typegen').Typegen0,
  schema: {},

  id: 'PmDefaultViewsPureState',
  initial: 'default',

  states: {
    default: {},
    saving: {},
    success: {},
    failed: {
      meta: {
        error: true,
        errorMessage:
          'Es gab einen unbekannten Fehler beim Speichern der Standard-Ansichten',
      },
    },
  },
})

export const states = extractAllStates(PmDefaultViewsPureState)
