<template>
  <PmChangePasswordFormPure
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :use-timeout="useTimeout"
    @submit="
      ({ oldPassword, newPassword }) =>
        xstate.service.value.send('SUBMIT', { oldPassword, newPassword })
    "
  />
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'

import { PmChangePasswordState } from '@/components/PmChangePassword/PmChangePasswordState'

import PmChangePasswordFormPure from '@/components/PmChangePassword/PmChangePasswordFormPure.vue'

import ChangePasswordMutation from '@/components/PmChangePassword/ChangePasswordMutation.graphql'

export default defineComponent({
  name: 'PmChangePassword',
  components: {
    PmChangePasswordFormPure,
  },

  props: {
    useTimeout: { type: Boolean, default: false },
  },

  emits: ['success'],

  setup(props, context) {
    const instance = getCurrentInstance()

    const xstate = useXState(PmChangePasswordState, {
      services: {
        changePassword: (context, { oldPassword, newPassword }) =>
          instance.ctx.changePassword({ oldPassword, newPassword }),
      },

      actions: {
        emitSuccess: () => context.emit('success'),
      },
    })

    return {
      xstate,
    }
  },

  methods: {
    async changePassword({ oldPassword, newPassword }) {
      try {
        await this.$apollo.mutate({
          mutation: ChangePasswordMutation,

          variables: {
            oldPassword,
            newPassword,
          },
        })
      } catch (error) {
        throwFriendlyError(error, {
          operation: 'changePassword',
        })
      }
    },
  },
})
</script>
