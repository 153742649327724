<template>
  <component
    :is="tag"
    class="PmTableHeadItemPure"
    :class="classes"
    :title="label"
    @click="onClick"
  >
    <span class="PmTableHeadItemPure-label">
      {{ label }}
    </span>

    <div v-if="sortable" class="PmTableHeadItemPure-sortIconContainer">
      <SvgSort
        :key="props.sortDirection"
        class="PmTableHeadItemPure-sortIcon"
      />
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmTableHeadItemPure'

export const propTypes = {
  sortDirection: {
    allowed: ['ascending', 'descending'],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import SvgSort from '@/assets/svg/sort.svg?component'

export interface Props {
  label: string
  sortable?: boolean
  sortDirection?: (typeof propTypes.sortDirection.allowed)[number]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'sortDescending'): void
  (event: 'sortAscending'): void
  (event: 'resetSorting'): void
}>()

const classes = computed(() => {
  return {
    'is-usedForSorting': isUsedForSorting.value,
    'is-sortDirectionDescending': props.sortDirection === 'descending',
    'is-sortDirectionAscending': props.sortDirection === 'ascending',
  }
})

const isUsedForSorting = computed(() => {
  return props.sortDirection !== undefined
})

const tag = computed(() => {
  if (props.sortable === true) return 'button'
  return 'div'
})

function onClick() {
  if (props.sortable !== true) return

  if (props.sortDirection === undefined) {
    return emit('sortAscending')
  }

  if (props.sortDirection === 'ascending') {
    return emit('sortDescending')
  }

  emit('resetSorting')
}
</script>

<style lang="scss">
.PmTableHeadItemPure {
  $block: &;
  $parent: '.PmTablePure';

  @include mixin.textLabel;
  @include cssVar.define($block, 'opacityArrowUp', 1);
  @include cssVar.define($block, 'colorArrowUp', color.$gray-400);
  @include cssVar.define($block, 'opacityArrowDown', 1);
  @include cssVar.define($block, 'colorArrowDown', color.$gray-400);

  &.is-sortDirectionAscending {
    @include cssVar.define($block, 'colorArrowUp', color.$gray-600);
  }

  &.is-sortDirectionDescending {
    @include cssVar.define($block, 'colorArrowDown', color.$gray-600);
  }

  color: color.$text-default;
  display: flex;
  gap: 4px;
  align-items: center;
  padding-top: cssVar.use($parent, 'cellPadding-header');
  padding-bottom: cssVar.use($parent, 'cellPadding-header');
  width: 100%;

  &-label {
    @include mixin.truncateText;
  }

  &-sortIconContainer {
    $size: 14px;

    width: $size;
    height: $size;
    opacity: 0;
    transition: opacity constant.$duration-fast linear;
    flex-shrink: 0;

    #{$block}:hover & {
      opacity: 1;
    }

    #{$block}.is-usedForSorting & {
      opacity: 1;
    }
  }

  &-sortIcon {
    @include mixin.svgIcon;

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    & [id*='.up'] {
      opacity: cssVar.use($block, 'opacityArrowUp');
      color: cssVar.use($block, 'colorArrowUp');
    }

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    & [id*='.down'] {
      opacity: cssVar.use($block, 'opacityArrowDown');
      color: cssVar.use($block, 'colorArrowDown');
    }
  }
}
</style>
