<template>
  <div class="PmDayBackgroundMarkerPure" :style="style"></div>
</template>

<script>
import { getHoursAsFloat } from '@/utilities/date'

export default {
  name: 'PmDayBackgroundMarkerPure',

  props: {
    startTime: { type: Date, required: true },
    endTime: { type: Date, required: true },
  },

  computed: {
    style() {
      const left = (100 / 24) * getHoursAsFloat(this.startTime)
      const width = (100 / 24) * getHoursAsFloat(this.endTime) - left

      return {
        left: `${left}%`,
        width: `${width}%`,
      }
    },
  },
}
</script>

<style lang="scss">
.PmDayBackgroundMarkerPure {
  $block: &;

  height: 100%;
  min-height: 200px;
  position: absolute;
  background-color: color.$openingHours;
  opacity: 0.5;
}
</style>
