<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('main')">
      <div :class="componentClass.element('filter')">
        <slot name="filter" />
      </div>

      <div :class="componentClass.element('list')">
        <slot name="list" />

        <div :class="componentClass.element('help')">
          Strg+Shift+Klick um mehrere Einträge zu markieren
        </div>
      </div>
    </div>

    <div :class="componentClass.element('details')">
      <slot name="details" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceRequestOverviewPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { useSlots } from 'vue'
import { useComponentClass } from '@/composition/useComponentClass'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const slots = useSlots()

const componentClass = useComponentClass()
</script>

<style lang="scss">
.PmResourceRequestOverviewPure {
  $block: &;

  display: flex;
  width: 100%;
  gap: 20px;

  &-main {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-help {
    @include mixin.textLabel;

    margin-top: 8px;
  }

  &-details {
    width: 40%;
    align-self: flex-start;
    position: sticky;
    top: calc(var(--navigationHeight) + var(--space-smallest));
  }

  &-detailsEmpty {
    height: 100%;
    background-color: color.$gray-100;
    border-radius: constant.$borderRadius-large;
  }
}
</style>
