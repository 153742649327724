<template>
  <d-tabs v-if="event">
    <d-tab title="Details" active>
      <table class="table m-0 border-top-0">
        <tbody>
          <div class="DataModalEvent">
            <DataModalRow label="Titel">{{ event.caption }}</DataModalRow>

            <DataModalRow label="Start">{{
              formatWithLocale(event.startDate, FORMAT_DATETIME_DEFAULT)
            }}</DataModalRow>
            <DataModalRow label="Ende">{{
              formatWithLocale(event.endDate, FORMAT_DATETIME_DEFAULT)
            }}</DataModalRow>

            <DataModalRow label="Ort">{{ event.location }}</DataModalRow>
            <DataModalRow label="Priorität">{{
              event.importance
            }}</DataModalRow>

            <DataModalRow label="Beschreibung">
              <div v-html="event.description"></div>
            </DataModalRow>

            <DataModalRow label="Teilnehmer">
              <ul class="DataModal-list">
                <li
                  v-for="attendee in event.attendees"
                  :key="attendee.displayName"
                >
                  {{ attendee.displayName }} ({{ attendee.status }})
                </li>
              </ul>
            </DataModalRow>
          </div>
        </tbody>
      </table>
    </d-tab>
  </d-tabs>
</template>

<script>
import { FORMAT_DATETIME_DEFAULT } from '@/constants/persoplan'

import { formatWithLocale } from '@/utilities/date'

import DataModalRow from '@/components/persoplan/DataModal/DataModalRow.vue'

import EVENT_DETAILS from '@/components/persoplan/DataModal/DataModalEventDetailsQuery.graphql'

export default {
  name: 'DataModalEvent',
  components: { DataModalRow },

  props: {
    id: { type: Number, required: true },
  },

  setup() {
    return {
      FORMAT_DATETIME_DEFAULT,
      formatWithLocale,
    }
  },

  apollo: {
    event: {
      query: EVENT_DETAILS,

      variables() {
        return {
          id: this.id,
        }
      },
    },
  },
}
</script>
