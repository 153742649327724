<template>
  <PmViewEditorDropdown
    attribute="clients"
    :options="normalizedClients"
    :is-loading="isLoadingAny"
    v-bind="separateAttrs($attrs).attributes"
    :value="value"
    @close="onClose"
    @input-searchterm="(value) => (searchterm = value)"
  />
</template>

<script>
import { uniqBy } from 'lodash-es'
import PmViewEditorDropdown from '@/components/persoplan/ViewEditor/PmViewEditorDropdown.vue'

import { separateAttrs } from '@/utilities/misc'
import { useLoading } from '@/composition/useLoading'

import ViewEditorClientsQuery from '@/components/persoplan/ViewEditor/ViewEditorClientsQuery.graphql'
import ViewEditorClientsSearchQuery from '@/components/persoplan/ViewEditor/ViewEditorClientsSearchQuery.graphql'

export default {
  name: 'PmViewEditorClients',
  components: {
    PmViewEditorDropdown,
  },

  props: {
    value: { type: Array, default: () => [] },
  },

  setup() {
    const { setLoading, isLoadingAny } = useLoading({
      loaders: ['clients', 'clientsSearch'],
    })

    return {
      separateAttrs,
      setLoading,
      isLoadingAny,
    }
  },

  data() {
    return {
      searchterm: undefined,
      clients: [],
      clientsSearch: [],
    }
  },

  computed: {
    normalizedClients() {
      const clients = this.clients.map((client) => ({
        id: client.id,
        label: client.displayName,
      }))

      const clientsSearch = this.clientsSearch.map((client) => ({
        id: client.id,
        label: client.displayName,
      }))

      // Remove any double values
      return uniqBy([...clients, ...clientsSearch], 'id')
    },
  },

  methods: {
    onClose() {
      this.clientsSearch = []
      this.searchterm = undefined
    },
  },

  apollo: {
    clients: {
      query: ViewEditorClientsQuery,

      variables() {
        // If there are no ids selected, use -1 to avoid getting results from query
        let ids = this.value
        if (!ids.length) ids = [-1]

        return {
          ids: ids,
        }
      },

      watchLoading(isLoading) {
        this.setLoading('clients', isLoading)
      },
    },

    clientsSearch: {
      query: ViewEditorClientsSearchQuery,

      debounce: 250,

      variables() {
        return {
          searchterm: this.searchterm,
        }
      },

      skip() {
        if (!this.searchterm) return true
        return false
      },

      watchLoading(isLoading) {
        this.setLoading('clientsSearch', isLoading)
      },
    },
  },
}
</script>
