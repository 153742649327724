import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

export const useGlobalClasses = defineStore('globalClasses', () => {
  const classes = reactive({
    isDragAndDropInProgress: false,
    isDragAndDropTypeChangeDayWidth: false,
  })

  type Classes = keyof typeof classes

  interface Option {
    className: string
  }

  const options: Record<Classes, Option> = {
    isDragAndDropInProgress: {
      className: 'is-dragAndDropInProgress',
    },
    isDragAndDropTypeChangeDayWidth: {
      className: 'is-dragAndDropTypeChangeDayWidth',
    },
  } as const

  type OptionsKeys = keyof typeof options

  const globalClasses = computed(() => {
    const result: string[] = []

    const optionKeys = Object.keys(options)

    Object.entries(classes).forEach(([key, value]) => {
      if (!optionKeys.includes(key)) {
        throw new Error(`${key} is not part of options`)
      }

      const optionKey = key as OptionsKeys
      const option = options[optionKey]

      if (value !== true) {
        return
      }

      result.push(option.className)
    })

    return result
  })

  return {
    classes,
    globalClasses,
  }
})
