<template>
  <PmMainNavigationPure
    :reports="normalizedReports"
    :reports-loading="isReportsLoading"
    :username="store.getters['auth/name']"
    :url-admin="urlAdmin"
    :version-frontend="versionFrontend"
    :build-frontend="BUILD"
    :version-api="apiVersion"
    :admin-area-visible="can('view', 'adminArea')"
    :feature-requests-visible="can('view', 'featureRequests')"
    :environment-badge="environmentBadge.label"
    :environment-badge-variant="environmentBadge.variant"
    :feedback-name="feedbackName"
    :feedback-email="feedbackEmail"
    :has-unread-notifications="notifications.hasUnreadNotifications.value"
    :can-view-resource-request-overview="can('use', 'resourceRequestOverview')"
    :can-view-request-overview="can('approve', 'requests')"
  >
    <template #notifications>
      <PmNotificationDropdown />
    </template>
  </PmMainNavigationPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMainNavigation'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuery } from '@vue/apollo-composable'

import { DANGEROUSLY_USE_PRODUCTION_API } from '@/constants/general'
import { lookup } from '@/utilities/misc'
import runtimeVariables from '@/utilities/runtimeVariables'
import { version as versionAtBuildTime } from '@/../package.json'
import BUILD from '@/../BUILD?raw'

import { useAppAbility } from '@/composition/useAppAbility'
import notifications from '@/store/notifications/notifications'

import PmMainNavigationPure, {
  type Props as PropsMainNavigationPure,
} from '@/components/PmMainNavigation/PmMainNavigationPure.vue'
import PmNotificationDropdown from '@/components/PmNotificationDropdown/PmNotificationDropdown.vue'

import {
  ReportsDocument,
  ApiVersionDocument,
  CurrentUserDocument,
} from '@/../generated/graphql'

export interface Props {
  myProp?: string
}

const environmentBadgeLookup = {
  beta: 'Beta',
  development: 'Dev',
} as const

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()
const { can } = useAppAbility()

const environmentBadge = getEnvironmentBadge()

type EnvironmentBadge = {
  label: PropsMainNavigationPure['environmentBadge']
  variant: PropsMainNavigationPure['environmentBadgeVariant']
}

function getEnvironmentBadge(): EnvironmentBadge {
  if (DANGEROUSLY_USE_PRODUCTION_API === true)
    return {
      label: 'Danger! Production API',
      variant: 'danger',
    }

  return {
    label: lookup(import.meta.env.VITE_ENVIRONMENT, environmentBadgeLookup),
    variant: 'default',
  }
}

const versionFrontend = versionAtBuildTime

const normalizedReports = computed(() => {
  const result: PropsMainNavigationPure['reports'] = []

  reports.value?.forEach((item) => {
    if (!item) return

    const report: Required<PropsMainNavigationPure>['reports'][number] = {
      id: item.label,
      label: item.label,
      href: item.href,
      linkOpenInNewWindow: true,
    }

    result.push(report)
  })

  return result
})

const urlAdmin = computed(() => {
  return runtimeVariables.apiUrl + '/admin'
})

const feedbackName = computed(() => {
  const firstName = user.value?.firstName ? user.value.firstName : ''
  const surname = user.value?.surname ? user.value.surname : ''
  const name = `${firstName} ${surname}`

  return name
})

const feedbackEmail = computed(() => {
  return user.value?.email
})

const reportsQuery = useQuery(ReportsDocument)
const reports = computed(() => reportsQuery.result.value?.reports)
const isReportsLoading = computed(() => reportsQuery.loading.value)

const apiVersionQuery = useQuery(ApiVersionDocument)
const apiVersion = computed(
  () => apiVersionQuery.result.value?.currentRelease?.api
)

const userQuery = useQuery(CurrentUserDocument)
const user = computed(() => userQuery.result.value?.user)
</script>
