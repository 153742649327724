import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultipleResourceAllocationsRequestPureState.typegen'

export type State = Typegen0['matchesStates']

export const PmMultipleResourceAllocationsRequestPureState = createMachine({
  tsTypes:
    {} as import('./PmMultipleResourceAllocationsRequestPureState.typegen').Typegen0,
  schema: {},

  id: 'PmMultipleResourceAllocationsRequestPureState',
  initial: 'default',

  states: {
    loading: {},
    empty: {},

    default: {
      on: {},
    },

    request: {
      initial: 'requesting',

      states: {
        requesting: {},

        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Anfragen der Ressourcen-Zuordnungen',
          },
        },

        success: {},
        successAndClose: {},
      },
    },
  },
})

export const states = extractAllStates(
  PmMultipleResourceAllocationsRequestPureState
)
