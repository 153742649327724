<template>
  <PmNotificationContainerPure
    notification-type="externalServiceRequest"
    :date="date"
    :is-unread="isUnread"
    message="Dein Antrag auf Fremddienstleistung wurde akzeptiert"
  >
    <template #meta>
      <PmMetaListItemPure label="Datum">
        <template v-if="startDate && endDate">
          {{ startEndDateForText(startDate, endDate) }}
        </template>
      </PmMetaListItemPure>
    </template>

    <template #actions>
      <PmButtonListPure>
        <PmButtonPure label="Details" @click="emit('openDetails')" />
      </PmButtonListPure>
    </template>
  </PmNotificationContainerPure>
</template>

<script setup lang="ts">
import PmNotificationContainerPure, {
  type Props as PropsNotificationContainerPure,
} from '@/components/PmNotification/PmNotificationContainerPure.vue'
import PmMetaListItemPure from '@/components/PmMetaList/PmMetaListItemPure.vue'
import { startEndDateForText } from '@/utilities/date'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import type { Nilable } from '@/types/misc'

export interface Props
  extends Pick<PropsNotificationContainerPure, 'date' | 'isUnread'> {
  externalServiceRequestId: number
  startDate: Nilable<Date>
  endDate: Nilable<Date>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  openDetails: []
}>()
</script>
