import { inject } from 'vue'
import { formKey } from '@/utilities/inject'

export function useForm() {
  const form = inject(formKey)

  return {
    disabled: form?.disabled,
  }
}
