<template>
  <div :class="classes">
    <PmLoadingOverlayPure v-if="isLoading" />

    <div v-if="label" :class="componentClass.element('label')">
      {{ label }}:
    </div>

    <div
      v-if="!hasSlotContent.default"
      :class="componentClass.element('empty')"
    >
      <template v-if="!isLoading"> Keine Daten vorhanden </template>
    </div>

    <dl v-show="hasSlotContent.default" :class="componentClass.element('list')">
      <slot />
    </dl>
  </div>
</template>

<script setup lang="ts">
import { computed, toRef, provide, type Ref } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { keyValueListPureKey } from '@/utilities/inject'
import { useHasSlotContent } from '@/composition/useHasSlotContent'
import PmLoadingOverlayPure from '@/components/basics/PmLoadingOverlay/PmLoadingOverlayPure.vue'

export type Provide = {
  appearance: Ref<Props['appearance']>
}

export interface Props {
  label?: string
  appearance?: 'default' | 'naked'
  labelWidth?: 'default' | 'address'
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  appearance: 'default',
})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

provide(keyValueListPureKey, {
  appearance: toRef(props, 'appearance'),
})

const componentClass = useComponentClass()
const hasSlotContent = useHasSlotContent(['default'])

const classes = computed(() => {
  return [
    componentClass.root,
    componentClass.propModifier({
      name: 'appearance',
      value: props.appearance,
    }),
    componentClass.propModifier({
      name: 'labelWidth',
      value: props.labelWidth,
    }),
  ]
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmKeyValueListPure {
  $block: &;

  @include cssVar.define($block, 'padding', 12px);
  @include cssVar.define($block, 'colorBorder', color.$gray-400);
  @include cssVar.define($block, 'colorBorderInline', color.$gray-300);
  @include cssVar.define($block, 'borderRadius', constant.$borderRadius-large);

  position: relative;

  &-label {
    @include mixin.textLabel;

    margin-bottom: 4px;
  }

  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: color.$gray-500;

    #{$block}--appearanceDefault > & {
      border: 1px solid cssVar.use($block, 'colorBorder');
      border-radius: cssVar.use($block, 'borderRadius');
    }
  }

  &-list {
    width: 100%;
    min-width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    z-index: 0;
    position: relative;

    #{$block}--appearanceDefault > & {
      border: 1px solid cssVar.use($block, 'colorBorder');
      border-radius: cssVar.use($block, 'borderRadius');
    }

    #{$block}--labelWidthAddress > & {
      grid-template-columns: 10ch 1fr;
    }
  }
}
</style>
