import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmResourceRequestUpdateState.typegen'

export type State = Typegen0['matchesStates']

export const PmResourceRequestUpdateState = createMachine({
  tsTypes: {} as import('./PmResourceRequestUpdateState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'CONFIRM' },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },

    services: {} as {
      save: any
    },
  },

  id: 'PmResourceRequestUpdateState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        CONFIRM: 'save',
      },
    },

    save: {
      initial: 'saving',

      states: {
        saving: {
          invoke: {
            id: 'save',
            src: 'save',
            onDone: 'success',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                console.error(error.data)
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
          },
        },

        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Aktualisieren der Ressourcen-Anfragen',
          },
        },

        success: {
          type: 'final',
          entry: ['showSuccessNotification', 'emitClose'],
        },
      },
    },
  },
})

export const states = extractAllStates(PmResourceRequestUpdateState)
