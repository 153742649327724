import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmNotePureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmNotePureState = createMachine({
  tsTypes: {} as import('./PmNotePureState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'EDIT' } | { type: 'CANCEL' },
  },

  id: 'PmNotePureState',
  initial: 'default',

  states: {
    default: {
      on: {
        EDIT: 'editing',
      },
    },

    editing: {
      initial: 'default',

      on: {
        CANCEL: {
          target: '#PmNotePureState.default',
          actions: ['emitCancel'],
        },
      },

      states: {
        default: {
          entry: ['initializeEdit'],
        },
        saving: {},
        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Speichern der Notiz',
          },
        },
      },
    },
  },
})

export const states = extractAllStates(PmNotePureState)
