import type { App } from 'vue'

// import Vue from 'vue'
import PortalVue from 'portal-vue'
import { abilitiesPlugin } from '@casl/vue'

// @ts-ignore sugar is not typed
import Sugar from 'sugar-date'
import 'sugar-date/locales/de'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApolloProvider } from '@vue/apollo-option'

import '@/assets/scss/reset.scss'
import '@/assets/scss/browserfixes.scss'
import '@/assets/scss/default.scss'

import { ability } from '@/services/ability'
import EventHub from '@/eventHub'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'

// Sprachen
Sugar.Date.setLocale('de')

// Set global keycodes
// Vue.config.keyCodes = {
//   d: 68,
//   e: 69,
//   s: 83,
//   p: 80,
//   n: 78,
//   v: 86,
// }

// Global event bus
// Vue.prototype.$eventHub = EventHub

export function attachStuffToAppInstance(app: App) {
  const pinia = createPinia()
  app.use(pinia)

  app.use(PortalVue)
  app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  })

  const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
  })

  app.use(apolloProvider)
  app.provide(DefaultApolloClient, apolloProvider.defaultClient)

  app.use(store)

  const i18n = createI18n({
    legacy: false,
    locale: 'de',
  })
  app.use(i18n)

  // Globals
  app.config.globalProperties.$eventHub = EventHub
}
