import { createMachine, actions } from 'xstate'
const { pure, send } = actions

import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './ResourceAllocationConflictState.typegen'

export type State = Typegen0['matchesStates']

export const ResourceAllocationConflictState = createMachine({
  tsTypes: {} as import('./ResourceAllocationConflictState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'IS_TOO_MANY_DAYS' }
      | { type: 'CHECK' }
      | { type: 'HAS_CONFLICTS' }
      | { type: 'HAS_NO_CONFLICTS' },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },

    services: {} as {
      searchForConflicts: {
        data: {
          hasConflicts: boolean
        }
      }
    },
  },

  id: 'ResourceAllocationConflictState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        IS_TOO_MANY_DAYS: 'tooManyDays',
        CHECK: 'check',
      },
    },

    tooManyDays: {},

    check: {
      invoke: {
        id: 'searchForConflicts',
        src: 'searchForConflicts',
        onDone: {
          actions: pure((context, event) => {
            const hasConflicts = event.data.hasConflicts

            return hasConflicts
              ? send('HAS_CONFLICTS')
              : send('HAS_NO_CONFLICTS')
          }),
        },
        onError: {
          target: 'error',
          actions: (context, error) => {
            context.error = error.data.message
            context.errorDetails = error.data.details
          },
        },
      },

      on: {
        HAS_CONFLICTS: 'conflicts',
        HAS_NO_CONFLICTS: 'noConflicts',
      },
    },
    error: {},
    conflicts: {},
    noConflicts: {},
  },
})

export const states = extractAllStates(ResourceAllocationConflictState)
