import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultipleResourceAllocationsDeleteState.typegen'

export type State = Typegen0['matchesStates']

export const PmMultipleResourceAllocationsDeleteState = createMachine({
  tsTypes:
    {} as import('./PmMultipleResourceAllocationsDeleteState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'CONFIRM' },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmMultipleResourceAllocationsDeleteState',
  initial: 'askForConfirmation',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    askForConfirmation: {
      on: {
        CONFIRM: 'delete',
      },
    },
    delete: {
      initial: 'deleting',

      states: {
        deleting: {
          invoke: {
            id: 'deleteResourceAllocationsi',
            src: 'deleteResourceAllocations',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {},
      },
    },
  },
})

export const states = extractAllStates(PmMultipleResourceAllocationsDeleteState)
