<template>
  <div class="PmSidebarControllerPure" :class="classes">
    <PmDraggablePure
      v-if="!isCollapsed"
      :type="DRAG_AND_DROP_TYPE.CHANGE_SIDEBAR_LEFT_WIDTH"
      :use-placeholder="false"
      :can-be-dropped="false"
      :use-mirror="false"
      constrain="x"
      class="PmSidebarControllerPure-dragAndDrop"
      classes-element="PmSidebarControllerPure-dragContainer"
      @drag-start="onDragStart"
      @drag-move="onDragMove"
      @drag-end="onDragEnd"
    >
      <div class="PmSidebarControllerPure-drag"></div>
    </PmDraggablePure>

    <button class="PmSidebarControllerPure-toggle" @click="$emit('toggle')">
      <div class="PmSidebarControllerPure-toggleInner">
        <PmIconPure
          class="PmSidebarControllerPure-toggleIcon"
          :name="ICONS.ARROW_LEFT"
        />
      </div>
    </button>
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'
import { DRAG_AND_DROP_TYPE } from '@/constants/persoplan'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmDraggablePure from '@/components/utilities/PmDraggablePure.vue'

export const propTypes = {
  align: {
    allowed: ['left', 'right'],
    default: 'right',
  },
}

export default {
  name: 'PmSidebarControllerPure',
  components: {
    PmIconPure,
    PmDraggablePure,
  },

  props: {
    collapsed: { type: Boolean, default: undefined },
    width: { type: Number, required: true },
    minWidth: { type: Number, default: 0 },
    maxWidth: { type: Number, default: undefined },
    align: { type: String, default: propTypes.align.default },
    isCollapsed: { type: Boolean, default: false },
  },

  emits: ['toggle', 'dragStart', 'dragEnd', 'drag'],

  setup() {
    return {
      ICONS,
      DRAG_AND_DROP_TYPE,
    }
  },

  data() {
    return {
      initialWidth: undefined,
      intermediateWidth: undefined,
      isDragging: false,
    }
  },

  computed: {
    classes() {
      return {
        [`${this.$options.name}--alignLeft`]: this.align === 'left',
        [`${this.$options.name}--alignRight`]: this.align === 'right',
        'is-collapsed': this.collapsed,
      }
    },
  },

  mounted() {
    const normalizedWidth = this.normalizeWidth(this.width)
    if (normalizedWidth === this.width) return
    this.$emit('dragEnd', normalizedWidth)
  },

  methods: {
    onDragStart() {
      this.initialWidth = this.width
      this.isDragging = true

      this.$emit('dragStart')
    },

    onDragMove({ delta }) {
      let deltaX
      if (this.align === 'left') deltaX = delta.x
      if (this.align === 'right') deltaX = delta.x * -1

      this.intermediateWidth = this.normalizeWidth(this.initialWidth + deltaX)

      window.requestAnimationFrame(() => {
        if (!this.isDragging) return
        this.$emit('drag', this.intermediateWidth)
      })
    },

    onDragEnd() {
      this.$emit('dragEnd', this.intermediateWidth)

      this.initialWidth = null
      this.intermediateWidth = null
      this.isDragging = false
    },

    normalizeWidth(width) {
      if (width < this.minWidth) {
        return this.minWidth
      }

      if (width > this.maxWidth) {
        return this.maxWidth
      }

      return width
    },
  },
}
</script>

<style lang="scss">
.PmSidebarControllerPure {
  $block: &;

  $shadowPadding: 10px;
  $toggleWidth: 18px;
  $toggleHeight: 30px;

  width: 8px;
  height: 100%;
  position: relative;

  &-dragAndDrop {
    height: 100%;
  }

  &-dragContainer {
    height: 100%;
    width: 2px;
  }

  &-drag {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: col-resize;
    z-index: 2;

    &::after {
      @include mixin.transition-hover(
        $properties: opacity,
        $rootElements: $block
      );

      content: '';
      opacity: 0;
      background-color: color.$key;
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;

      #{$block}:hover &,
      #{$block}.is-hover &,
      #{$block}-dragContainer.is-dragging & {
        opacity: 1;
      }

      #{$block}--alignLeft & {
        left: -1px;
      }

      #{$block}--alignRight & {
        right: -1px;
      }
    }
  }

  &-toggle {
    @include mixin.transition-hover($properties: color);

    position: absolute;
    top: calc(var(--PmCalendarPure-headerHeight) - #{$shadowPadding});
    width: $toggleWidth + $shadowPadding;
    height: $toggleHeight + $shadowPadding * 2;
    z-index: 1;
    pointer-events: none;
    overflow: hidden;

    // Active click area
    &::before {
      content: '';
      position: absolute;
      width: $toggleWidth;
      height: $toggleHeight;
      top: $shadowPadding;
      background-color: color.$white;
      pointer-events: auto;

      #{$block}--alignLeft & {
        left: 0;
      }

      #{$block}--alignRight & {
        right: 0;
      }
    }

    &:hover {
      color: color.$key;
    }

    #{$block}--alignLeft & {
      left: 0;
    }

    #{$block}--alignRight & {
      right: 0;
    }
  }

  &-toggleInner {
    position: absolute;
    width: $toggleWidth;
    height: $toggleHeight;
    top: $shadowPadding;
    box-shadow:
      4px 2px 6px 0 rgba(#000, 0.1),
      1px 3px 3px 0 rgba(#000, 0.1);

    #{$block}--alignLeft & {
      left: 0;
      border-left: 1px solid color.$gray-300;
    }

    #{$block}--alignRight & {
      right: 0;
      border-right: 1px solid color.$gray-300;
    }
  }

  &-toggleIcon {
    padding: 2px;

    #{$block}--alignLeft.is-collapsed & {
      transform: rotate(180deg);
    }

    #{$block}--alignRight:not(.is-collapsed) & {
      transform: rotate(180deg);
    }
  }
}
</style>
