<template>
  <div class="PmInfoPure">
    <div class="PmInfoPure-iconContainer">
      <PmIconPure :name="ICONS.INFO" class="PmInfoPure-icon" />
    </div>

    <slot />
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export default {
  name: 'PmInfoPure',
  components: {
    PmIconPure,
  },

  setup() {
    return {
      ICONS,
    }
  },
}
</script>

<style lang="scss">
.PmInfoPure {
  $block: &;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &-iconContainer {
    height: #{constant.$lineHeight-default}em;
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  &-icon {
    width: 20px;
    height: 20px;
    flex: none;
  }
}
</style>
