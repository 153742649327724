import { computed, type Ref } from 'vue'

import { PROJECT_STATUS, type ProjectStatus } from '@/constants/persoplan'
import scssVariables from '@/assets/scss/scssVariables'

export type { ProjectStatus } from '@/constants/persoplan'

interface Options {
  status: Ref<ProjectStatus | undefined | null>
}

export function useProjectStatus(options: Options) {
  const classes = computed(() => {
    if (!options.status.value) return

    return {
      'is-statusConfirmed': options.status.value === PROJECT_STATUS.CONFIRMED,
      'is-statusCancelled': options.status.value === PROJECT_STATUS.CANCELLED,
      'is-statusOffered': options.status.value === PROJECT_STATUS.OFFERED,
      'is-statusService': options.status.value === PROJECT_STATUS.SERVICE,
    }
  })

  const color = computed(() => {
    if (!options.status.value) return

    return scssVariables.color[`status-${options.status.value}`]
  })

  return {
    id: options.status.value,
    classes,
    color,
  }
}
