import { type Ref, isRef, reactive, computed, unref, watch, ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import type { ReactiveFunction } from '@vue/apollo-composable/dist/util/ReactiveFunction.d.ts'

import type {
  DocumentParameter,
  VariablesParameter,
  OptionsParameter,
  UseQueryReturn,
  UseQueryOptions,
} from '@vue/apollo-composable/dist/useQuery.d.ts'
import type { OperationVariables } from '@apollo/client/core'

import { querySettingsKey, injectStrict } from '@/utilities/inject'

/**
 * Copied from @vue/apollo-composable
 * @see https://github.com/vuejs/apollo/blob/v4/packages/vue-apollo-composable/src/util/paramToReactive.ts
 */
type TObject = object

export function paramToReactive<T extends TObject>(
  param: T | Ref<T> | ReactiveFunction<T>
): T | Ref<T> {
  if (isRef(param)) {
    return param
  } else if (typeof param === 'function') {
    return computed(param as ReactiveFunction<T>)
  } else if (param) {
    return reactive(param) as T
  } else {
    return param
  }
}

/**
 * Types are copied from useQueryImpl
 * @see https://github.com/vuejs/apollo/blob/v4/packages/vue-apollo-composable/src/useQuery.ts#L135
 */
export function useCachedQuery<TResult, TVariables extends OperationVariables>(
  document: DocumentParameter<TResult, TVariables>,
  variables?: VariablesParameter<TVariables>,
  options: OptionsParameter<TResult, TVariables> = {}
): UseQueryReturn<TResult, TVariables> {
  const querySettings = injectStrict(querySettingsKey, { useCache: false })

  const currentOptions = ref<UseQueryOptions<TResult, TVariables>>({})
  const optionsRef = paramToReactive(options)

  watch(
    () => unref(optionsRef),
    (value) => {
      currentOptions.value = {
        ...value,
        enabled: unref(value.enabled),
      }

      if (querySettings.useCache) {
        currentOptions.value.fetchPolicy = 'cache-only'
      }
    },
    {
      deep: true,
      immediate: true,
    }
  )

  const query = useQuery<TResult, TVariables>(
    document,
    // @ts-expect-error useQueryImpl which would be needed is missing
    variables,
    currentOptions
  )

  // @ts-expect-error useQueryImpl which would be needed is missing
  return query
}
