import {
  RESOURCE_TYPE,
  RESOURCE_STATE_TYPE_IDS_FILTERED_OUT,
  ALLOWED_RESOURCE_STATE_TYPE_IDS_FREELANCERS,
} from '@/constants/persoplan'

export function checkIfResourceStateTypeIsVisible({
  applyForType,
  resourceStateTypeId,
  resourceType,
}) {
  // Special case for freelancer, where we want to show only a couple of options
  if (applyForType === RESOURCE_TYPE.FREELANCER) {
    return ALLOWED_RESOURCE_STATE_TYPE_IDS_FREELANCERS.includes(
      resourceStateTypeId
    )
  }

  const isFilteredOutId =
    RESOURCE_STATE_TYPE_IDS_FILTERED_OUT.includes(resourceStateTypeId)

  if (isFilteredOutId) return false

  return resourceType === applyForType
}
