<template>
  <PmButtonPure
    :id="id"
    :class="componentClass.root"
    :label="label"
    :in-group="true"
    :type="type"
    :group="uid"
    :checked="isSelected"
    :active="isSelected"
    :title="title"
    :icon="icon"
    :to="to"
    :expanded="expanded"
    :centered="expanded"
    @input-id="(id) => emit('selectTab', id)"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import cuid from '@paralleldrive/cuid2'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'

export interface Props {
  expanded?: boolean
  id: string
  label: string
  title?: string
  icon?: PropsButtonPure['icon']
  to?: PropsButtonPure['to']
  isSelected?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  selectTab: [id: Props['id']]
}>()

const componentClass = useComponentClass()
const uid = cuid.createId()

const type = computed<PropsButtonPure['type'] | undefined>(() => {
  if (props.to) return undefined
  return 'radio'
})
</script>

<style lang="scss">
.PmTabNavigationItemPure {
  $block: &;
}
</style>
