<template>
  <div class="PmResourceAllocationConflictPure">
    <PmAppNotificationPure v-if="xstate.state.value.matches('tooManyDays')">
      Resourcen mit mehr als {{ RESOURCE_DAY_MAX_DAYS }} Tagen werden nicht auf
      Zeitkonflikte geprüft.
    </PmAppNotificationPure>

    <PmAppNotificationPure
      v-if="xstate.state.value.matches('check')"
      :is-loading="true"
      title="Suche nach Zeitkonflikten"
    />

    <PmErrorNotificationPure
      v-if="xstate.state.value.matches('error')"
      :message="errorMessage || xstate.meta.value.errorMessage"
      :details="errorDetails"
    />

    <PmAppNotificationPure
      v-if="xstate.state.value.matches('conflicts')"
      variant="warning"
      :icon="ICONS.SWITCH"
      :title="t('title', numberOfConflicts)"
      :open-details-label="t('buttonLabel', numberOfConflicts)"
      :is-details-visible="xstate.state.value.matches('conflicts.visible')"
      @open-details="xstate.service.value.send('OPEN_DETAILS')"
      @close-details="xstate.service.value.send('CLOSE_DETAILS')"
    >
      {{ t('text', numberOfConflicts) }}

      <template #details>
        <div class="PmResourceAllocationConflictPure-resourceDay">
          <slot
            name="resourceDay"
            :close-details="() => xstate.service.value.send('CLOSE_DETAILS')"
          />
        </div>
      </template>
    </PmAppNotificationPure>

    <PmAppNotificationPure
      v-if="xstate.state.value.matches('noConflicts')"
      variant="success"
      :icon="ICONS.CHECK"
      title="Es gibt keine Zeitkonflikte"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceAllocationConflictPure'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { ICONS } from '@/constants/icons'
import { RESOURCE_DAY_MAX_DAYS } from '@/constants/persoplan'

import { useXState } from '@/composition/useXState'
import {
  ResourceAllocationConflictPureState,
  states,
} from '@/components/persoplan/PmResourceAllocationConflict/ResourceAllocationConflictPureState'
import type { State } from '@/components/persoplan/PmResourceAllocationConflict/ResourceAllocationConflictPureState'

import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'

export interface Props {
  state: State
  numberOfConflicts?: number
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  numberOfConflicts: 0,
})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const { t } = useI18n({
  messages: {
    de: {
      title: '{n} Zeitkonflikt | {n} Zeitkonflikte',
      text: 'Diese Zuordnung kollidiert mit einer anderen Zuordnung oder einem Status! | Diese Zuordnung kollidiert mit {n} anderen Zuordnungen oder Status!',
      buttonLabel: 'Konflikt anzeigen | Konflikte anzeigen',
    },
  },
})

const xstate = useXState(ResourceAllocationConflictPureState, {
  syncStateWith: toRef(props, 'state'),
})
</script>

<style lang="scss">
.PmResourceAllocationConflictPure {
  $block: &;
}
</style>
