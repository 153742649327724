import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultipleResourceAllocationsUpdatePureState.typegen'

export type State = Typegen0['matchesStates']

export const PmMultipleResourceAllocationsUpdatePureState = createMachine({
  tsTypes:
    {} as import('./PmMultipleResourceAllocationsUpdatePureState.typegen').Typegen0,
  schema: {},

  id: 'PmMultipleResourceAllocationsUpdatePureState',
  initial: 'default',

  states: {
    default: {
      on: {},
    },

    update: {
      initial: 'updating',

      states: {
        updating: {},

        success: {
          entry: ['clearValues'],
        },

        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Ändern der Ressourcen-Zuordnungen',
          },
        },
      },
    },

    successAndClose: {},
  },
})

export const states = extractAllStates(
  PmMultipleResourceAllocationsUpdatePureState
)
