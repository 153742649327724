<template>
  <PmNotificationLeaveRequestUpdatedPure
    v-bind="{ ...$attrs, ...props }"
    :is-jump-visible="can('approve', 'requests')"
    :is-request-of-current-user="isRequestOfCurrentUser"
    @open-details="isDetailsVisible = true"
  />

  <portal to="modal">
    <PmModalPure
      v-if="isDetailsVisible"
      title="Urlaubsantrag"
      @close="isDetailsVisible = false"
    >
      <PmRequestDetail
        v-if="isDetailsVisible"
        :id="props.leaveRequestId"
        type="leaveRequest"
      />
    </PmModalPure>
  </portal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'

import PmNotificationLeaveRequestUpdatedPure, {
  type Props as PropsNotificationLeaveRequestCreatedPure,
} from '@/components/PmNotification/PmNotificationLeaveRequestUpdated/PmNotificationLeaveRequestUpdatedPure.vue'
import PmRequestDetail from '@/components/PmRequestDetail/PmRequestDetail.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import { useAppAbility } from '@/composition/useAppAbility'
import { CurrentUserDocument } from '@/../generated/graphql'

export interface Props extends PropsNotificationLeaveRequestCreatedPure {
  leaveRequestOfUserId: number
}

const props = withDefaults(defineProps<Props>(), {})

const isDetailsVisible = ref(false)
const { can } = useAppAbility()

const currentUserQuery = useQuery(CurrentUserDocument, null, () => ({
  fetchPolicy: 'cache-first',
}))

const isRequestOfCurrentUser = computed(() => {
  const currentUserId = currentUserQuery.result.value?.user?.id
  if (!currentUserId) return
  return props.leaveRequestOfUserId === currentUserId
})
</script>
