<template>
  <PmTableRowPure class="PmMultipleResourceAllocationsDeleteItemPure">
    <template #cell(project)> {{ project ?? '—' }} </template>
    <template #cell(job)> {{ job ?? '—' }} </template>
    <template #cell(appointment)> {{ appointment ?? '—' }} </template>
    <template #cell(resourceFunction)> {{ resourceFunction ?? '—' }} </template>
    <template #cell(name)> {{ name ?? '—' }} </template>
    <template #cell(state)>
      <PmLoadingPure v-if="state === 'deleting'" size="small" />

      <PmIconPure
        v-if="state === 'error'"
        :name="ICONS.ALERT"
        class="PmMultipleResourceAllocationsDeleteItemPure-statusIcon is-error"
      />

      <PmIconPure
        v-if="state === 'success'"
        :name="ICONS.CHECK"
        class="PmMultipleResourceAllocationsDeleteItemPure-statusIcon is-success"
      />
    </template>
  </PmTableRowPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsDeleteItemPure'

export const propTypes = {
  state: {
    allowed: ['deleting', 'error', 'success'],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import type { Nilable } from '@/types/misc'

import { ICONS } from '@/constants/icons'

import PmTableRowPure from '@/components/basics/PmTable/PmTableRowPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export interface Props {
  project?: Nilable<string>
  job?: Nilable<string>
  appointment?: Nilable<string>
  resourceFunction?: Nilable<string>
  name?: Nilable<string>
  state?: (typeof propTypes.state.allowed)[number]
}

const props = withDefaults(defineProps<Props>(), {})
</script>

<style lang="scss">
.PmMultipleResourceAllocationsDeleteItemPure {
  $block: &;

  &-statusIcon {
    width: 20px;
    height: 20px;

    &.is-error {
      color: color.$danger-600;
    }

    &.is-success {
      color: color.$success-600;
    }
  }
}
</style>
