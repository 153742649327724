<template>
  <PmNotificationLeaveRequestCreatedPure
    v-bind="{ ...$attrs, ...props }"
    :is-jump-visible="can('approve', 'requests')"
    @open-details="isDetailsVisible = true"
  />

  <portal to="modal">
    <PmModalPure
      v-if="isDetailsVisible"
      title="Urlaubsantrag"
      @close="isDetailsVisible = false"
    >
      <PmRequestDetail
        v-if="isDetailsVisible"
        :id="props.leaveRequestId"
        type="leaveRequest"
      />
    </PmModalPure>
  </portal>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import PmNotificationLeaveRequestCreatedPure, {
  type Props as PropsNotificationLeaveRequestCreatedPure,
} from '@/components/PmNotification/PmNotificationLeaveRequestCreated/PmNotificationLeaveRequestCreatedPure.vue'
import PmRequestDetail from '@/components/PmRequestDetail/PmRequestDetail.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import { useAppAbility } from '@/composition/useAppAbility'

export interface Props extends PropsNotificationLeaveRequestCreatedPure {}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const isDetailsVisible = ref(false)
const { can } = useAppAbility()
</script>
