<template>
  <PmCreateLeaveRequestPure
    :state="xstate.path.value"
    :error-message="xstate.snapshot.context.error"
    :error-details="xstate.snapshot.context.errorDetails"
    @close="emit('close')"
    @submit="
      (variables) => xstate.send({ type: 'create', variables: variables })
    "
  />
</template>

<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { fromPromise } from 'xstate5'
import { useStore } from 'vuex'

import { throwFriendlyError } from '@/functional/error'
import PmCreateLeaveRequestPure, {
  type Emits as EmitsCreateLeaveRequestPure,
} from '@/components/PmCreateLeaveRequest/PmCreateLeaveRequestPure.vue'
import { useXState } from '@/composition/useXState5'
import { PmCreateLeaveRequestState } from '@/components/PmCreateLeaveRequest/PmCreateLeaveRequestState'
import { endDateForServer, startDateForServer } from '@/utilities/date'
import {
  CreateLeaveRequestDocument,
  LeaveRequestType,
} from '@/../generated/graphql'

const store = useStore()
const emit = defineEmits<{
  close: []
}>()

const xstate = useXState(
  PmCreateLeaveRequestState.provide({
    actions: {
      showSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          title: 'Dein Urlaubsantrag wurde erfolgreich eingereicht',
        })
      },
      emitClose: () => emit('close'),
    },

    actors: {
      createLeaveRequest: fromPromise(async ({ input }) =>
        createLeaveRequest(input.variables)
      ),
    },
  })
)

const createLeaveRequestMutration = useMutation(
  CreateLeaveRequestDocument,
  () => ({
    refetchQueries: ['MyRequestsList'],
  })
)

async function createLeaveRequest(
  variables: EmitsCreateLeaveRequestPure['submit']
) {
  try {
    await createLeaveRequestMutration.mutate({
      startDate: startDateForServer(variables.startDate),
      endDate: endDateForServer(variables.endDate),
      type: LeaveRequestType[variables.type],
      note: variables.note,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>
