<template>
  <div class="PmNotificationContainerPure" :class="classes">
    <div class="PmNotificationContainerPure-iconContainer">
      <PmButtonPure
        :icon="icon"
        alternative="ghost"
        :icon-hover="iconHover"
        :title="titleToggleUnread"
        @click="toggleUnread"
      />
    </div>

    <div class="PmNotificationContainerPure-content">
      <div class="PmNotificationContainerPure-header">
        <div class="PmNotificationContainerPure-headerContent">
          <div class="PmNotificationContainerPure-date">
            {{ formatToRelativeDate(date) }}
          </div>

          <div class="PmNotificationContainerPure-message">{{ message }}</div>

          <div
            v-show="hasSlotContent.content"
            class="PmNotificationContainerPure-headerContentInner"
          >
            <slot name="content" />
          </div>
        </div>

        <div class="PmNotificationContainerPure-actions">
          <slot name="actions" />
        </div>
      </div>

      <PmMetaListPure
        v-show="hasSlotContent.meta"
        class="PmNotificationContainerPure-meta"
      >
        <slot name="meta" />
      </PmMetaListPure>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { formatToRelativeDate } from '@/utilities/date'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import type { Props as IconPureProps } from '@/components/basics/PmIcon/PmIconPure.vue'
import PmMetaListPure from '@/components/PmMetaList/PmMetaListPure.vue'
import { useHasSlotContent } from '@/composition/useHasSlotContent'

export interface Props {
  notificationType:
    | 'resourceRequestFeedback'
    | 'leaveRequest'
    | 'externalServiceRequest'
  date: Date
  isUnread?: boolean
  message?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'markAsRead'): void
  (event: 'markAsUnread'): void
}>()

const hasSlotContent = useHasSlotContent(['content', 'meta'])

const icon = computed<IconPureProps['name']>(() => {
  if (props.notificationType === 'resourceRequestFeedback')
    return 'mailQuestion'

  if (props.notificationType === 'leaveRequest') return 'vacation'
  if (props.notificationType === 'externalServiceRequest') return 'alien'

  return 'info'
})

const iconHover = computed<IconPureProps['name']>(() => {
  return props.isUnread ? 'check' : 'markAsUnread'
})

const titleToggleUnread = computed(() => {
  if (props.isUnread) return 'Als gelesen markieren'
  return 'Als ungelesen markieren'
})

const classes = computed(() => {
  return {
    'is-unread': props.isUnread,
  }
})

function toggleUnread() {
  if (props.isUnread) emit('markAsRead')
  else emit('markAsUnread')
}
</script>

<style lang="scss">
@use 'sass:math';

.PmNotificationContainerPure {
  $block: &;

  display: flex;
  gap: 12px;

  &-iconContainer {
    position: relative;
    align-self: flex-start;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-header {
    display: flex;
  }

  &-headerContent {
    margin-right: auto;
  }

  &-message {
    font-weight: 600;
    line-height: 1.3;
  }

  &-headerContentInner {
    min-width: 0;
    margin-top: 8px;
  }

  &-date {
    @include mixin.textLabel;

    margin-bottom: 4px;
    position: relative;
    display: flex;
    gap: 0.5em;
    align-items: center;

    #{$block}.is-unread & {
      color: color.$danger-600;
    }

    &::before {
      $size: 8px;

      content: '';
      background-color: color.$danger-500;
      width: $size;
      height: $size;
      border-radius: $size;
      display: block;
      position: relative;
      top: 1px;

      #{$block}:not(.is-unread) & {
        display: none;
      }
    }
  }

  &-actions {
    margin-left: 16px;
    flex-shrink: 0;
  }

  &-meta {
    width: 90%;
    margin-top: 8px;
  }
}
</style>
