<template>
  <div class="PmFloatingBarPure">
    <PmLoadingPure v-if="isLoading" size="default" />

    <template v-if="!isLoading">
      <div v-if="hasControls" class="PmFloatingBarPure-control">
        <PmButtonPure
          v-if="closeButtonVisible"
          :icon="ICONS.CLOSE"
          alternative="ghost"
          @click="$emit('close')"
        />
      </div>

      <slot />
    </template>
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'

export default {
  name: 'PmFloatingBarPure',
  components: {
    PmButtonPure,
    PmLoadingPure,
  },

  props: {
    closeButtonVisible: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  emits: ['close'],

  setup() {
    return {
      ICONS,
    }
  },

  computed: {
    hasControls() {
      if (this.closeButtonVisible) return true
      return false
    },
  },
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmFloatingBarPure {
  $block: &;

  @include shadow.default(
    'low',
    $outline: true,
    $outlineColor: color.$gray-300--alpha
  );

  background-color: color.$white;
  padding: 12px;
  border-radius: constant.$borderRadius-large;
  display: inline-flex;
  align-items: flex-start;
  position: relative;

  &-control {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
  }
}
</style>
