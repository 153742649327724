<template>
  <component
    :is="component"
    :id="id"
    :container-start-date="containerStartDate"
    :container-end-date="containerEndDate"
  />
</template>

<script>
import { CALENDAR_ITEM_TYPE } from '@/constants/persoplan'

import { filterProps } from '@/utilities/props'

import PmAppointmentsContainerPure from '@/components/persoplan/PmAppointmentsContainer/PmAppointmentsContainerPure.vue'
import PmJob from '@/components/persoplan/PmJob/PmJob.vue'
import PmProject from '@/components/persoplan/PmProject/PmProject.vue'

const componentLookup = {
  [CALENDAR_ITEM_TYPE.PROJECT]: 'PmProject',
  [CALENDAR_ITEM_TYPE.JOB]: 'PmJob',
}

export default {
  name: 'PmCalendarItem',
  components: {
    PmJob,
    PmProject,
  },

  props: {
    ...filterProps(PmAppointmentsContainerPure.props, {
      onlyUse: ['containerStartDate', 'containerEndDate'],
    }),
    id: { type: Number, required: true },
    type: { type: String, required: true },
  },

  computed: {
    component() {
      return componentLookup[this.type]
    },
  },
}
</script>

<style lang="scss">
.PmCalendarItem {
  $block: &;
}
</style>
