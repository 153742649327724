import { createMachine, assign } from 'xstate'

import type { Typegen0 } from './PmResourceDayState.typegen'

export type TState = Typegen0['matchesStates']

export const PmResourceDayState = createMachine(
  {
    tsTypes: {} as import('./PmResourceDayState.typegen').Typegen0,
    schema: {
      events: {} as
        | { type: 'CREATE_RESOURCE_STATE' }
        | { type: 'EDIT_RESOURCE_STATE'; resourceStateId: number }
        | { type: 'CLOSE' }
        | { type: 'CANCEL' },

      context: {} as {
        editResourceStateId: number | undefined
      },
    },

    id: 'PmResourceDayState',
    initial: 'default',

    context: {
      editResourceStateId: undefined,
    },

    states: {
      default: {
        on: {
          EDIT_RESOURCE_STATE: {
            target: 'editResourceState',
            actions: 'setEditResourceStateId',
          },
          CREATE_RESOURCE_STATE: 'createResourceState',
        },
      },

      editResourceState: {
        on: {
          CLOSE: 'default',
          CANCEL: 'default',
        },
      },

      createResourceState: {
        on: {
          CLOSE: 'default',
          CANCEL: 'default',
        },
      },
    },
  },
  {
    actions: {
      setEditResourceStateId: assign((context, { resourceStateId }) => {
        return {
          editResourceStateId: resourceStateId,
        }
      }),
    },
  }
)
