import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import { DRAG_AND_DROP_TYPE } from '@/constants/persoplan'
import type { DragAndDropType } from '@/constants/persoplan'

import { useGlobalClasses } from '@/pinia/globalClasses'

interface State {
  type: DragAndDropType | undefined
  canBeDropped: boolean | undefined
  data: any
}

export const useDragAndDrop = defineStore('dragAndDrop', () => {
  const globalClasses = useGlobalClasses()

  const type = ref<DragAndDropType>()
  const canBeDropped = ref<boolean>()
  const data = ref()

  function start(operation: {
    type: DragAndDropType
    canBeDropped: boolean
    data: any
  }) {
    type.value = operation.type
    canBeDropped.value = operation.canBeDropped
    data.value = operation.data

    globalClasses.$patch((state) => {
      state.classes.isDragAndDropInProgress = true
    })

    if (type.value === DRAG_AND_DROP_TYPE.CHANGE_DAY_WIDTH) {
      globalClasses.$patch((state) => {
        state.classes.isDragAndDropTypeChangeDayWidth = true
      })
    }
  }

  function end() {
    type.value = undefined
    canBeDropped.value = undefined
    data.value = undefined

    globalClasses.$patch((state) => {
      state.classes.isDragAndDropInProgress = false

      // Reset type classes
      state.classes.isDragAndDropTypeChangeDayWidth = false
    })
  }

  const inProgress = computed(() => {
    return type.value ? true : false
  })

  return {
    type,
    canBeDropped,
    data,
    start,
    end,
    inProgress,
  }
})
