<template>
  <div class="PmEventsPure" :style="styles">
    <slot />
  </div>
</template>

<script>
import { differenceInCalendarDays } from 'date-fns'

export default {
  name: 'PmEventsPure',
  components: {},

  props: {
    events: { type: Array, default: () => [] },
    containerStartDate: { type: Date, required: true },
    containerEndDate: { type: Date, required: true },
  },

  computed: {
    numberOfDays() {
      return (
        differenceInCalendarDays(
          this.containerEndDate,
          this.containerStartDate
        ) + 1
      )
    },

    styles() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfDays}, var(--dayWidth)`,
      }
    },
  },
}
</script>

<style lang="scss">
.PmEventsPure {
  $block: &;

  display: grid;
  grid-auto-flow: dense;
  align-items: start;
  width: min-content;
  padding-bottom: 4px;
}
</style>
