<template>
  <svg
    :height="radius * 2"
    :width="radius * 2"
    class="PmTimerCirclePure"
    :class="classes"
  >
    <circle
      class="PmTimerCirclePure-circleBackground"
      :style="style"
      :stroke-width="stroke"
      :r="normalizedRadius"
      cx="50%"
      cy="50%"
    />

    <circle
      class="PmTimerCirclePure-circle"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="style"
      :stroke-width="stroke"
      :r="normalizedRadius"
      cx="50%"
      cy="50%"
    />
  </svg>
</template>

<script>
import propValidator from '@/functional/propValidator'

export const propTypes = {
  variant: {
    allowed: ['primary', 'secondary', 'success', 'warning', 'danger'],
  },
}

export default {
  name: 'PmTimerCirclePure',

  props: {
    variant: {
      type: String,
      default: 'secondary',
      validator: (value) =>
        propValidator.oneOf(value, propTypes.variant.allowed),
    },
    progress: { type: Number, default: 100 },
    radius: { type: Number, default: 10 },
    stroke: { type: Number, default: 3 },
  },

  computed: {
    classes() {
      return {
        [`${this.$options.name}--primary`]: this.variant === 'primary',
        [`${this.$options.name}--success`]: this.variant === 'success',
        [`${this.$options.name}--warning`]: this.variant === 'warning',
        [`${this.$options.name}--danger`]: this.variant === 'danger',
      }
    },

    normalizedRadius() {
      return this.radius - this.stroke / 2
    },

    circumference() {
      return this.normalizedRadius * 2 * Math.PI
    },

    style() {
      return {
        strokeDashoffset:
          this.circumference - (this.progress / 100) * this.circumference,
      }
    },
  },
}
</script>

<style lang="scss">
.PmTimerCirclePure {
  $block: &;

  @include cssVar.define($block, 'colorBackground', color.$gray-200);
  @include cssVar.define($block, 'color', color.$gray-500);

  &--primary {
    @include cssVar.define($block, 'colorBackground', color.$primary-200);
    @include cssVar.define($block, 'color', color.$primary-500);
  }

  &--success {
    @include cssVar.define($block, 'colorBackground', color.$success-200);
    @include cssVar.define($block, 'color', color.$success-500);
  }

  &--warning {
    @include cssVar.define($block, 'colorBackground', color.$warning-300);
    @include cssVar.define($block, 'color', color.$warning-500);
  }

  &--danger {
    @include cssVar.define($block, 'colorBackground', color.$danger-200);
    @include cssVar.define($block, 'color', color.$danger-500);
  }

  fill: none;
  transform: rotate(-90deg);

  &-circleBackground {
    stroke: cssVar.use($block, 'colorBackground');
  }

  &-circle {
    stroke: cssVar.use($block, 'color');
  }
}
</style>
