<template>
  <div
    class="PmResourceTimelineBackgroundPure"
    :class="classes"
    :style="styles"
  >
    <div v-if="label" class="PmResourceTimelineBackgroundPure-label">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceTimelineBackgroundPure'

export const propTypes = {
  myProp: {
    allowed: ['example', 'anotherOne'] as const,
    default: 'example' as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, type StyleValue, toRef } from 'vue'
import Color from 'color'

import {
  useResourceDayStatus,
  type ResourceStatus,
} from '@/composition/useResourceDayStatus'

export interface Props {
  color?: string
  isHighlighted?: boolean
  label?: string
  status?: ResourceStatus
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const resourceDayStatus = useResourceDayStatus({
  status: toRef(props, 'status'),
})

const classes = computed(() => {
  return {
    ...resourceDayStatus.classes.value,
    'is-highlighted': props.isHighlighted,
  }
})

const styles = computed<StyleValue>(() => {
  if (!props.color) return {}

  let color = Color(props.color)
  color = color.isLight() ? color.alpha(0.4) : color.alpha(0.25)

  return {
    backgroundColor: color.toString(),
  }
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmResourceTimelineBackgroundPure {
  $block: &;

  @include cssVar.define($block, 'colorBackground', transparent);
  @include cssVar.define($block, 'colorBackground--hover', transparent);

  // @include mixin.transition-hover(background-color);

  $notAvailable: rgba(color.$status-resourceDay-notAvailable, 0.35);
  $notAvailable--hover: rgba(
    color.$status-resourceDay-notAvailable--hover,
    0.65
  );

  $availableIfUrgent: rgba(color.$status-resourceDay-availableIfUrgent, 0.32);
  $availableIfUrgent--hover: rgba(
    color.$status-resourceDay-availableIfUrgent--hover,
    0.56
  );

  $available: rgba(color.$status-resourceDay-available, 0.6);
  $available--hover: rgba(color.$status-resourceDay-available--hover, 0.88);

  $storage: rgba(color.$status-resourceDay-storage, 0.6);
  $storage--hover: rgba(color.$status-resourceDay-storage--hover, 0.88);

  $onProject: rgba(color.$status-resourceDay-onProject, 0.31);
  $onProject--hover: rgba(color.$status-resourceDay-onProject--hover, 0.54);

  width: 100%;
  min-height: 15px;
  height: 100%;
  background-color: cssVar.use($block, 'colorBackground');
  position: relative;
  // content-visibility: auto; // This breaks the outline in Chrome

  &::before {
    // Outline for highlighted state
    $spread: 3px;

    content: '';
    position: absolute;
    top: -$spread;
    left: -$spread;
    bottom: -$spread;
    right: -$spread;
    background-color: rgba(color.$primary-500, 0.25);
    border: 2px solid color.$primary-500;
    border-radius: constant.$borderRadius-default + $spread;
    transition: opacity constant.$duration-fast;
    pointer-events: none;
    z-index: 1;
    opacity: 0;
  }

  &:hover,
  &.is-hover {
    background-color: cssVar.use($block, 'colorBackground--hover');
  }

  &.is-highlighted {
    &::before {
      opacity: 1;
    }
  }

  &.is-statusResourceDayNotAvailable {
    @include cssVar.define($block, 'colorBackground', $notAvailable);
    @include cssVar.define(
      $block,
      'colorBackground--hover',
      $notAvailable--hover
    );
  }

  &.is-statusResourceDayAvailableIfUrgent {
    @include cssVar.define($block, 'colorBackground', $availableIfUrgent);
    @include cssVar.define(
      $block,
      'colorBackground--hover',
      $availableIfUrgent--hover
    );
  }

  &.is-statusResourceDayAvailable {
    @include cssVar.define($block, 'colorBackground', $available);
    @include cssVar.define($block, 'colorBackground--hover', $available--hover);
  }

  &.is-statusResourceDayStorage {
    @include cssVar.define($block, 'colorBackground', $storage);
    @include cssVar.define($block, 'colorBackground--hover', $storage--hover);
  }

  &.is-statusResourceDayOnProject {
    @include cssVar.define($block, 'colorBackground', $onProject);
    @include cssVar.define($block, 'colorBackground--hover', $onProject--hover);
  }

  &-label {
    // @include mixin.transition-hover(opacity, $block);

    position: absolute;
    top: 3px;
    left: 2px;
    z-index: 1;
    font-weight: 550;
    font-size: constant.$fontSize-small;
    padding: 2px 5px;
    color: color.$text-default;
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
    opacity: 0.5;
    line-height: 1;

    &::before {
      // @include mixin.transition-hover(opacity, $block);
      @include shadow.default('very-low');

      content: '';
      background-color: color.$white;
      position: absolute;
      inset: 0;
      border-radius: 20px;
      z-index: -1;
      opacity: 0;
    }

    #{$block}:hover & {
      opacity: 1;

      &::before {
        opacity: 1;
      }
    }
  }
}
</style>
