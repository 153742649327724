<template>
  <div class="PmSidebarEmployees">
    <PmSidebarSectionPure
      title="Mitarbeiter"
      :can-be-collapsed="true"
      :collapsed="collapsableArea.isCollapsed.value"
      @expand="collapsableArea.expand"
      @collapse="collapsableArea.collapse"
    >
      <PmSidebarResourceGroupPure
        v-for="group in normalizedEmployeeGroups"
        :key="group.id"
        :title="group.title"
        :resources="group.resources"
        :type="RESOURCE_TYPE.ADDRESS"
        @highlight="
          (id) => store.commit('persoplan/setTimelineHighlightedId', id)
        "
        @un-highlight="store.commit('persoplan/clearTimelineHighlightedId')"
      />
    </PmSidebarSectionPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarEmployees'

export const propTypes = {
  myProp: {
    allowed: ['example', 'anotherOne'] as const,
    default: 'example' as const,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useQuery } from '@vue/apollo-composable'

import { VIEW_SETTING_MODE, RESOURCE_TYPE } from '@/constants/persoplan'

import { getDisplayNameOfAddress } from '@/utilities/string'
import { useCollapsableArea } from '@/composition/useCollapsableArea'
import { useResourceTimelines } from '@/pinia/resourceTimelines'

import PmSidebarSectionPure from '@/components/persoplan/PmSidebarSection/PmSidebarSectionPure.vue'
import PmSidebarResourceGroupPure, {
  type Resource,
} from '@/components/persoplan/PmSidebarResourceGroupPure.vue'

import { EmployeeGroupsDocument } from '@/../generated/graphql'

const store = useStore()
const collapsableArea = useCollapsableArea({ id: 'employees' })
const resourceTimelines = useResourceTimelines()

const employeeGroupsQuery = useQuery(EmployeeGroupsDocument)
const employeeGroups = computed(
  () => employeeGroupsQuery.result.value?.employeeGroups
)

const normalizedEmployeeGroups = computed(() => {
  if (!employeeGroups.value) return

  const filteredEmployeeGroups = getFilteredEmployeeGroups()
  if (!filteredEmployeeGroups) return

  interface Item {
    title?: string
    id: number
    resources: Resource[]
  }

  const result: Item[] = []

  filteredEmployeeGroups.forEach((group) => {
    if (!group) return

    const resources: Item['resources'] = []

    group.addresses?.forEach((address) => {
      if (!address) return

      let isHighlighted = false

      if (store.state.persoplan.timelineHighlightedId === address.id) {
        isHighlighted = true
      }

      if (
        resourceTimelines.highlightedTimeline?.id === address.id &&
        resourceTimelines.highlightedTimeline?.type === 'address'
      ) {
        isHighlighted = true
      }

      const resource: Resource = {
        id: address.id,
        title: getDisplayNameOfAddress(address),
        isHighlighted,
      }

      resources.push(resource)
    })

    const item: Item = {
      title: group.caption,
      id: group.id,
      resources,
    }

    result.push(item)
  })

  return result
})

function getFilteredEmployeeGroups() {
  switch (store.state.view.currentView.employeeGroups.mode) {
    case VIEW_SETTING_MODE.EXPLICIT:
      return getFilteredEmployeeGroupsExplicit()

    case VIEW_SETTING_MODE.EXCLUDE:
      return getFilteredEmployeeGroupsExclude()

    default:
      throw new Error('employeeGroups.mode must be either explicit or exclude')
  }
}

function getFilteredEmployeeGroupsExplicit() {
  if (!employeeGroups.value) return

  return employeeGroups.value.filter((employeeGroup) => {
    if (!employeeGroup) return false

    return store.state.view.currentView.employeeGroups.ids.includes(
      employeeGroup.id
    )
  })
}

function getFilteredEmployeeGroupsExclude() {
  if (!employeeGroups.value) return

  return employeeGroups.value.filter((employeeGroup) => {
    if (!employeeGroup) return false

    return !store.state.view.currentView.employeeGroups.ids.includes(
      employeeGroup.id
    )
  })
}
</script>

<style lang="scss">
.PmSidebarEmployees {
  $block: &;
}
</style>
