<template>
  <div class="PmLoadingPure" :class="classes">
    <PmIconPure class="PmLoadingPure-icon" :name="ICONS.SYNC" />
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'

import propValidator from '@/functional/propValidator'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'

export const propTypes = {
  size: {
    allowed: ['smallest', 'small', 'default'],
  },
}

export default {
  name: 'PmLoadingPure',
  components: {
    PmIconPure,
  },

  props: {
    light: { type: Boolean, default: false },
    size: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.size.allowed),
    },
  },

  setup() {
    return {
      ICONS,
    }
  },

  computed: {
    classes() {
      return {
        '-light': this.light,
        [`${this.$options.name}--sizeSmallest`]: this.size === 'smallest',
        [`${this.$options.name}--sizeSmall`]: this.size === 'small',
        [`${this.$options.name}--sizeDefault`]: this.size === 'default',
      }
    },
  },
}
</script>

<style lang="scss">
.PmLoadingPure {
  $block: &;
  $name: function.getBlockName($block);

  position: relative;
  flex: none;
  width: 100%;
  height: 100%;

  &--sizeSmallest {
    width: 16px;
    height: 16px;
  }

  &--sizeSmall {
    width: 20px;
    height: 20px;
  }

  &--sizeDefault {
    width: 32px;
    height: 32px;
  }

  &-icon {
    animation-name: #{$name}-spinning;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes #{$name}-spinning {
      0% {
        transform: rotate(-360deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }
  }
}
</style>
