<template>
  <div class="PmWarningIconPure">
    <PmTooltipPure>
      <button class="PmWarningIconPure-button">
        <PmIconPure name="alert" size="small" class="PmWarningIconPure-icon" />
      </button>

      <template #tooltip>
        <PmRichTextPure>
          <strong>{{ label }}:</strong><br />
          <ul>
            <li v-for="warning in warnings" :key="warning">{{ warning }}</li>
          </ul>
        </PmRichTextPure>
      </template>
    </PmTooltipPure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmWarningIconPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import PmTooltipPure from '@/components/basics/PmTooltipPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'

export interface Props {
  label?: string
  warnings: string[]
}

const props = withDefaults(defineProps<Props>(), {
  label: 'Probleme',
  warnings: () => [],
})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()
</script>

<style lang="scss">
.PmWarningIconPure {
  $block: &;

  &-button {
    display: block;
    color: color.$danger-500;

    #{$block}:hover & {
      color: color.$danger-700;
    }
  }
}
</style>
