<template>
  <PmPollingIndicatorPure
    :progress="polling.progress"
    :state="xstate.path.value"
    @update="xstate.service.value.send('UPDATE')"
  />
</template>

<script setup lang="ts">
import { watch, ref, inject } from 'vue'
import gsap from 'gsap'
import { useEventListener } from '@vueuse/core'

import { POLLING_INTERVAL } from '@/constants/persoplan'

import { PmPollingIndicatorState } from '@/components/basics/PmPollingIndicator/PmPollingIndicatorState'
import { useXState } from '@/composition/useXState'
import { pollingIndicatorKey } from '@/utilities/inject'

import PmPollingIndicatorPure from '@/components/basics/PmPollingIndicator/PmPollingIndicatorPure.vue'

export type Provided = {
  update: () => Promise<void>
}

export interface Props {
  initialLoadingFinished?: boolean
  userIsIdle?: boolean
  /** in case the data update is not triggered from the
   * polling interval or the user clicking on the button
   * but from somewhere else */
  isUpdatingFromExternal?: boolean
  /** Update this prop to trigger an update */
  triggerUpdate?: number
}

const props = withDefaults(defineProps<Props>(), {})

const injected = inject(pollingIndicatorKey)

const polling = ref({ progress: 0 })

const xstate = useXState(PmPollingIndicatorState, {
  services: {
    startPollingInterval: startPollingInterval,
    update: update,
  },
  actions: {
    resetPolling: resetPolling,
  },
})

watch(
  () => props.initialLoadingFinished,
  (to, from) => {
    const fromFalseToTrue =
      (from === false || from === undefined) && to === true
    if (!fromFalseToTrue) return

    if (xstate.state.value.matches('initialLoading')) {
      xstate.service.value.send('INITIAL_LOADING_FINISHED')
    }
  },
  {
    immediate: true,
  }
)

watch(
  () => props.userIsIdle,
  (to, from) => {
    if ((from === false || from === undefined) && to === true) {
      xstate.service.value.send('USER_WENT_IDLE')
    }

    if (from === true && to === false) {
      xstate.service.value.send('USER_WENT_ACTIVE')
    }
  },
  { immediate: true }
)

watch(
  () => props.isUpdatingFromExternal,
  () => {
    if (props.isUpdatingFromExternal) {
      xstate.service.value.send('UPDATE_FROM_EXTERNAL')
    } else {
      xstate.service.value.send('UPDATE_FROM_EXTERNAL_FINISHED')
    }
  }
)

watch(
  () => props.triggerUpdate,
  () => xstate.service.value.send('UPDATE')
)

// Check if online
if (!window.navigator.onLine) {
  xstate.service.value.send('GO_OFFLINE')
}

/**
 * Update status browser goes online/offline
 */
useEventListener(window, 'online', updateOnlineStatus)
useEventListener(window, 'offline', updateOnlineStatus)

function updateOnlineStatus() {
  if (window.navigator.onLine) {
    xstate.service.value.send('GO_ONLINE')
  } else {
    xstate.service.value.send('GO_OFFLINE')
  }
}

/**
 * Start polling intervall
 */
async function startPollingInterval() {
  resetPolling()

  return new Promise((resolve) => {
    gsap.to(polling.value, {
      duration: POLLING_INTERVAL,
      progress: 100,
      roundProps: 'progress',
      ease: 'none',
      onComplete: resolve,
    })
  })
}

function resetPolling() {
  gsap.killTweensOf(polling.value)
  polling.value.progress = 0
}

/**
 * Run update methods
 */
async function update() {
  if (injected?.update) {
    return injected.update()
  }
}
</script>
