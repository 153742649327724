/**
 * Default
 */
import Account from '@/assets/svg/account.svg?component'
import Adjustments from '@/assets/svg/adjustments.svg?component'
import Alert from '@/assets/svg/alert.svg?component'
import Alien from '@/assets/svg/alien.svg?component'
import Archive from '@/assets/svg/archive.svg?component'
import ArrowDown from '@/assets/svg/arrowDown.svg?component'
import ArrowLeft from '@/assets/svg/arrowLeft.svg?component'
import ArrowRight from '@/assets/svg/arrowRight.svg?component'
import ArrowUp from '@/assets/svg/arrowUp.svg?component'
import Bed from '@/assets/svg/bed.svg?component'
import Bell from '@/assets/svg/bell.svg?component'
import Book from '@/assets/svg/book.svg?component'
import Bookmark from '@/assets/svg/bookmark.svg?component'
import Calendar from '@/assets/svg/calendar.svg?component'
import Check from '@/assets/svg/check.svg?component'
import CheckboxCircle from '@/assets/svg/checkboxCircle.svg?component'
import CheckboxCircleChecked from '@/assets/svg/checkboxCircleChecked.svg?component'
import CheckCheck from '@/assets/svg/checkCheck.svg?component'
import Clipboard from '@/assets/svg/clipboard.svg?component'
import ClockRewind from '@/assets/svg/clockRewind.svg?component'
import Close from '@/assets/svg/close.svg?component'
import Collection from '@/assets/svg/collection.svg?component'
import Conflict from '@/assets/svg/conflict.svg?component'
import ContextNavigation from '@/assets/svg/contextNavigation.svg?component'
import Cursor from '@/assets/svg/cursor.svg?component'
import Delete from '@/assets/svg/delete.svg?component'
import Direction from '@/assets/svg/direction.svg?component'
import Display from '@/assets/svg/display.svg?component'
import DoubleArrowLeft from '@/assets/svg/doubleArrowLeft.svg?component'
import DoubleArrowRight from '@/assets/svg/doubleArrowRight.svg?component'
import Driver from '@/assets/svg/driver.svg?component'
import DriverSelect from '@/assets/svg/driverSelect.svg?component'
import Duplicate from '@/assets/svg/duplicate.svg?component'
import Edit from '@/assets/svg/edit.svg?component'
import Eraser from '@/assets/svg/eraser.svg?component'
import Exclude from '@/assets/svg/exclude.svg?component'
import Explicit from '@/assets/svg/explicit.svg?component'
import Eye from '@/assets/svg/eye.svg?component'
import Feedback from '@/assets/svg/feedback.svg?component'
import FilePlus from '@/assets/svg/filePlus.svg?component'
import FileUp from '@/assets/svg/fileUp.svg?component'
import FingerPrint from '@/assets/svg/fingerPrint.svg?component'
import Forward from '@/assets/svg/forward.svg?component'
import Help from '@/assets/svg/help.svg?component'
import Home from '@/assets/svg/home.svg?component'
import Info from '@/assets/svg/info.svg?component'
import Jump from '@/assets/svg/jump.svg?component'
import Key from '@/assets/svg/key.svg?component'
import Lock from '@/assets/svg/lock.svg?component'
import LockOpen from '@/assets/svg/lockOpen.svg?component'
import Mail from '@/assets/svg/mail.svg?component'
import MailArrowRight from '@/assets/svg/mailArrowRight.svg?component'
import MailCancel from '@/assets/svg/mailCancel.svg?component'
import MailConfirm from '@/assets/svg/mailConfirm.svg?component'
import MailNotAvailable from '@/assets/svg/mailNotAvailable.svg?component'
import MailQuestion from '@/assets/svg/mailQuestion.svg?component'
import MailWithStatus from '@/assets/svg/mailWithStatus.svg?component'
import MarkAsUnread from '@/assets/svg/markAsUnread.svg?component'
import Menu from '@/assets/svg/menu.svg?component'
import Minus from '@/assets/svg/minus.svg?component'
import Move from '@/assets/svg/move.svg?component'
import Note from '@/assets/svg/note.svg?component'
import Offline from '@/assets/svg/offline.svg?component'
import OverflowLeft from '@/assets/svg/overflowLeft.svg?component'
import OverflowRight from '@/assets/svg/overflowRight.svg?component'
import Pause from '@/assets/svg/pause.svg?component'
import Pin from '@/assets/svg/pin.svg?component'
import Plus from '@/assets/svg/plus.svg?component'
import PlusCircle from '@/assets/svg/plusCircle.svg?component'
import Public from '@/assets/svg/public.svg?component'
import QuestionMark from '@/assets/svg/questionMark.svg?component'
import Random from '@/assets/svg/random.svg?component'
import ReceiptEuro from '@/assets/svg/receiptEuro.svg?component'
import Reload from '@/assets/svg/reload.svg?component'
import Report from '@/assets/svg/report.svg?component'
import Reset from '@/assets/svg/reset.svg?component'
import Save from '@/assets/svg/save.svg?component'
import Search from '@/assets/svg/search.svg?component'
import Send from '@/assets/svg/send.svg?component'
import Settings from '@/assets/svg/settings.svg?component'
import SignOut from '@/assets/svg/signOut.svg?component'
import Snooze from '@/assets/svg/snooze.svg?component'
import Sort from '@/assets/svg/sort.svg?component'
import Sparkles from '@/assets/svg/sparkles.svg?component'
import StaffRequest from '@/assets/svg/staffRequest.svg?component'
import Star from '@/assets/svg/star.svg?component'
import Switch from '@/assets/svg/switch.svg?component'
import Sync from '@/assets/svg/sync.svg?component'
import Terminal from '@/assets/svg/terminal.svg?component'
import Tint from '@/assets/svg/tint.svg?component'
import Unlink from '@/assets/svg/unlink.svg?component'
import User from '@/assets/svg/user.svg?component'
import UserScan from '@/assets/svg/userScan.svg?component'
import Vacation from '@/assets/svg/vacation.svg?component'
import Vehicle from '@/assets/svg/vehicle.svg?component'
import VehicleSelect from '@/assets/svg/vehicleSelect.svg?component'
import Wrench from '@/assets/svg/wrench.svg?component'

/**
 * Small
 */
import AdjustmentsSmall from '@/assets/svg/adjustments.small.svg?component'
import AlertSmall from '@/assets/svg/alert.small.svg?component'
import BookSmall from '@/assets/svg/book.small.svg?component'
import CloseSmall from '@/assets/svg/close.small.svg?component'
import CollectionSmall from '@/assets/svg/collection.small.svg?component'
import ConflictSmall from '@/assets/svg/conflict.small.svg?component'
import ContextNavigationSmall from '@/assets/svg/contextNavigation.small.svg?component'
import DeleteSmall from '@/assets/svg/delete.small.svg?component'
import DuplicateSmall from '@/assets/svg/duplicate.small.svg?component'
import EditSmall from '@/assets/svg/edit.small.svg?component'
import FeedbackSmall from '@/assets/svg/feedback.small.svg?component'
import FingerPrintSmall from '@/assets/svg/fingerPrint.small.svg?component'
import GripSmall from '@/assets/svg/grip.small.svg?component'
import HomeSmall from '@/assets/svg/home.small.svg?component'
import JumpSmall from '@/assets/svg/jump.small.svg?component'
import KeySmall from '@/assets/svg/key.small.svg?component'
import LockOpenSmall from '@/assets/svg/lockOpen.small.svg?component'
import LockSmall from '@/assets/svg/lock.small.svg?component'
import MailArrowRightSmall from '@/assets/svg/mailArrowRight.small.svg?component'
import MailCancelSmall from '@/assets/svg/mailCancel.small.svg?component'
import MailConfirmSmall from '@/assets/svg/mailConfirm.small.svg?component'
import MailNotAvailableSmall from '@/assets/svg/mailNotAvailable.small.svg?component'
import MailQuestionSmall from '@/assets/svg/mailQuestion.small.svg?component'
import MenuSmall from '@/assets/svg/menu.small.svg?component'
import PauseSmall from '@/assets/svg/pause.small.svg?component'
import PlusCircleSmall from '@/assets/svg/plusCircle.small.svg?component'
import PlusSmall from '@/assets/svg/plus.small.svg?component'
import PublicSmall from '@/assets/svg/public.small.svg?component'
import ResetSmall from '@/assets/svg/reset.small.svg?component'
import SaveSmall from '@/assets/svg/save.small.svg?component'
import SearchSmall from '@/assets/svg/search.small.svg?component'
import SettingsSmall from '@/assets/svg/settings.small.svg?component'
import SparklesSmall from '@/assets/svg/sparkles.small.svg?component'
import SwitchSmall from '@/assets/svg/switch.small.svg?component'
import TintSmall from '@/assets/svg/tint.small.svg?component'
import UserSmall from '@/assets/svg/user.small.svg?component'

export default {
  /**
   * Default
   */
  account: Account,
  adjustments: Adjustments,
  alert: Alert,
  alien: Alien,
  archive: Archive,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  bed: Bed,
  bell: Bell,
  book: Book,
  bookmark: Bookmark,
  calendar: Calendar,
  check: Check,
  checkboxCircle: CheckboxCircle,
  checkboxCircleChecked: CheckboxCircleChecked,
  checkCheck: CheckCheck,
  clipboard: Clipboard,
  clockRewind: ClockRewind,
  close: Close,
  collection: Collection,
  conflict: Conflict,
  contextNavigation: ContextNavigation,
  cursor: Cursor,
  delete: Delete,
  direction: Direction,
  display: Display,
  doubleArrowLeft: DoubleArrowLeft,
  doubleArrowRight: DoubleArrowRight,
  driver: Driver,
  driverSelect: DriverSelect,
  duplicate: Duplicate,
  edit: Edit,
  eraser: Eraser,
  exclude: Exclude,
  explicit: Explicit,
  eye: Eye,
  feedback: Feedback,
  filePlus: FilePlus,
  fileUp: FileUp,
  fingerPrint: FingerPrint,
  forward: Forward,
  help: Help,
  home: Home,
  info: Info,
  jump: Jump,
  key: Key,
  lock: Lock,
  lockOpen: LockOpen,
  mail: Mail,
  mailArrowRight: MailArrowRight,
  mailCancel: MailCancel,
  mailConfirm: MailConfirm,
  mailNotAvailable: MailNotAvailable,
  mailQuestion: MailQuestion,
  mailWithStatus: MailWithStatus,
  markAsUnread: MarkAsUnread,
  menu: Menu,
  minus: Minus,
  move: Move,
  note: Note,
  offline: Offline,
  overflowLeft: OverflowLeft,
  overflowRight: OverflowRight,
  pause: Pause,
  pin: Pin,
  plus: Plus,
  plusCircle: PlusCircle,
  public: Public,
  questionMark: QuestionMark,
  random: Random,
  receiptEuro: ReceiptEuro,
  reload: Reload,
  report: Report,
  reset: Reset,
  save: Save,
  search: Search,
  send: Send,
  settings: Settings,
  signOut: SignOut,
  snooze: Snooze,
  sort: Sort,
  sparkles: Sparkles,
  staffRequest: StaffRequest,
  star: Star,
  switch: Switch,
  sync: Sync,
  terminal: Terminal,
  tint: Tint,
  unlink: Unlink,
  user: User,
  userScan: UserScan,
  vacation: Vacation,
  vehicle: Vehicle,
  vehicleSelect: VehicleSelect,
  wrench: Wrench,

  /**
   * Small
   */
  'adjustments.small': AdjustmentsSmall,
  'alert.small': AlertSmall,
  'book.small': BookSmall,
  'close.small': CloseSmall,
  'collection.small': CollectionSmall,
  'conflict.small': ConflictSmall,
  'contextNavigation.small': ContextNavigationSmall,
  'delete.small': DeleteSmall,
  'duplicate.small': DuplicateSmall,
  'edit.small': EditSmall,
  'feedback.small': FeedbackSmall,
  'fingerPrint.small': FingerPrintSmall,
  'grip.small': GripSmall,
  'home.small': HomeSmall,
  'jump.small': JumpSmall,
  'key.small': KeySmall,
  'lock.small': LockSmall,
  'lockOpen.small': LockOpenSmall,
  'mailArrowRight.small': MailArrowRightSmall,
  'mailCancel.small': MailCancelSmall,
  'mailConfirm.small': MailConfirmSmall,
  'mailNotAvailable.small': MailNotAvailableSmall,
  'mailQuestion.small': MailQuestionSmall,
  'menu.small': MenuSmall,
  'pause.small': PauseSmall,
  'plus.small': PlusSmall,
  'plusCircle.small': PlusCircleSmall,
  'public.small': PublicSmall,
  'reset.small': ResetSmall,
  'save.small': SaveSmall,
  'search.small': SearchSmall,
  'settings.small': SettingsSmall,
  'sparkles.small': SparklesSmall,
  'switch.small': SwitchSmall,
  'tint.small': TintSmall,
  'user.small': UserSmall,
}
