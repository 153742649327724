<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('main')">
      <div
        v-if="hasSlotContent.dropdownAddress"
        :class="componentClass.element('filter')"
      >
        <div :class="componentClass.element('filterItem')">
          <slot name="dropdownAddress" />
        </div>
      </div>

      <div :class="componentClass.element('list')">
        <slot name="list" />
      </div>
    </div>

    <div :class="componentClass.element('details')">
      <slot name="details" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@/composition/useComponentClass'
import { useHasSlotContent } from '@/composition/useHasSlotContent'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
const hasSlotContent = useHasSlotContent(['dropdownAddress'])
</script>

<style lang="scss">
.PmRequestOverviewPure {
  $block: &;

  display: flex;
  width: 100%;
  gap: 20px;

  &-main {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-filter {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 16px;
    row-gap: 16px;
    align-items: start;
  }

  &-filterItem {
    flex-basis: 100%;
  }

  &-details {
    width: 40%;
    align-self: flex-start;
    position: sticky;
    top: calc(var(--navigationHeight) + var(--space-smallest));
  }

  &-detailsEmpty {
    height: 100%;
    background-color: color.$gray-100;
    border-radius: constant.$borderRadius-large;
  }
}
</style>
