<template>
  <div class="PmMultiAllocationListPure">
    <PmMultiAllocationListItemPure
      v-for="item in items"
      :key="item.id"
      class="PmMultiAllocationListPure-item"
      v-bind="item"
    />
  </div>
</template>

<script>
import PmMultiAllocationListItemPure from '@/components/persoplan/PmMultiSelectionController/PmMultiAllocationListItemPure.vue'

export default {
  name: 'PmMultiAllocationListPure',
  components: {
    PmMultiAllocationListItemPure,
  },

  props: {
    items: { type: Array, default: () => [] },
  },
}
</script>

<style lang="scss">
.PmMultiAllocationListPure {
  $block: &;

  &-item {
    margin-bottom: 8px;
  }
}
</style>
