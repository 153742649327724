import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import type { ResourceType } from '@/constants/persoplan'

export const useSelectionResourceAllocations = defineStore(
  'selectionResourceAllocations',
  () => {
    const selectedIds = ref(new Set<number>())
    const isInSelectionMode = ref(false)
    const selectionType = ref<ResourceType | undefined>()

    const size = computed(() => {
      return selectedIds.value.size
    })

    const selectedIdsAsArray = computed(() => {
      return Array.from(selectedIds.value)
    })

    function enterSelectionMode() {
      isInSelectionMode.value = true
    }

    function exitSelectionMode() {
      isInSelectionMode.value = false
      clear()
    }

    function add(id: number, type: ResourceType) {
      // If size is null it's a new selection, therefore set the selection type
      if (selectedIds.value.size === 0) {
        selectionType.value = normalizeType(type)
      }

      selectedIds.value.add(id)
    }

    function remove(id: number) {
      selectedIds.value.delete(id)

      // If size is null the selection is empty, therefore remove the selection type
      if (selectedIds.value.size === 0) {
        selectionType.value = undefined
      }
    }

    function normalizeType(type: ResourceType) {
      // Map freelancer to address:
      return type === 'freelancer' ? 'address' : type
    }

    function isSelected(id: number) {
      return selectedIds.value.has(id)
    }

    function canTypeBeSelected(type: ResourceType) {
      if (selectionType.value === undefined) return true
      return selectionType.value === normalizeType(type)
    }

    function clear() {
      selectedIds.value.clear()
      selectionType.value = undefined
    }

    return {
      add,
      remove,
      isSelected,
      canTypeBeSelected,
      clear,
      size,
      selectionType,
      selectedIds: selectedIdsAsArray,
      isInSelectionMode,
      enterSelectionMode,
      exitSelectionMode,
    }
  }
)
