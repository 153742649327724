<template>
  <PmGeneralPageContainerPure>
    <PmGeneralPagePure title="Anträge" layout="fullWidth">
      <template #header>
        <PmTabNavigationPure
          v-model:selectedTabId="selectedTabId"
          :tabs="[
            {
              id: 'current',
              label: 'Aktuelle Anträge',
              icon: 'vacation',
              to: {
                name: 'requestsCurrent',
              },
            },
            {
              id: 'archive',
              label: 'Archiv',
              icon: 'archive',
              to: {
                name: 'requestsArchive',
              },
            },
          ]"
        >
        </PmTabNavigationPure>
      </template>

      <PmGeneralPageSectionPure>
        <PmRequestOverview
          v-if="route.name === 'requestsCurrent'"
          key="current"
          :open-request="openRequest"
          mode="current"
        />

        <PmRequestOverview
          v-if="route.name === 'requestsArchive'"
          key="archive"
          mode="archive"
        />
      </PmGeneralPageSectionPure>
    </PmGeneralPagePure>
  </PmGeneralPageContainerPure>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import PmTabNavigationPure from '@/components/basics/PmTabNavigation/PmTabNavigationPure.vue'
import PmGeneralPageContainerPure from '@/components/PmGeneralPage/PmGeneralPageContainerPure.vue'
import PmGeneralPagePure from '@/components/PmGeneralPage/PmGeneralPagePure.vue'
import PmGeneralPageSectionPure from '@/components/PmGeneralPage/PmGeneralPageSectionPure.vue'
import PmRequestOverview, {
  type Props as PropsRequestOverview,
} from '@/components/PmRequestOverview/PmRequestOverview.vue'

interface Props {
  openRequest?: PropsRequestOverview['openRequest']
}

const props = withDefaults(defineProps<Props>(), {})
const route = useRoute()

const selectedTabId = ref<string>('current')
</script>
