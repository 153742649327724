<template>
  <div class="PmMainNavigationDropdownPure">
    <PmMainNavigationItemPure
      :id="id"
      ref="elTrigger"
      class="PmMainNavigationDropdownPure-trigger"
      :label="label"
      :icon="icon"
      :hide-label-on-mobile="hideLabelOnMobile"
      :is-active="popoverVisible"
      :has-notification="hasNotification"
      @click="togglePopover"
    />

    <PmOnClickOutsidePure
      :disabled="!isPopoverVisibleNormalized"
      :do="onClickOutside"
    >
      <PmPopoverWithTransitionPure
        :is-visible="isPopoverVisibleNormalized"
        :element="popoverElement"
        is-positioned-on="element"
        :padding="false"
        :is-loading="isLoading"
        :is-arrow-visible="false"
      >
        <slot />

        <PmContextNavigationListPure
          v-if="items"
          :items="items"
          @click-on-item="popoverVisible = false"
        />
      </PmPopoverWithTransitionPure>
    </PmOnClickOutsidePure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMainNavigationDropdownPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'

import type { Icon } from '@/constants/icons'

import PmMainNavigationItemPure from '@/components/PmMainNavigation/PmMainNavigationItemPure.vue'
import PmPopoverWithTransitionPure from '@/components/basics/PmPopover/PmPopoverWithTransitionPure.vue'
import PmOnClickOutsidePure from '@/components/utilities/PmOnClickOutsidePure.vue'
import PmContextNavigationListPure, {
  type Props as ContextNavigationListPureProps,
} from '@/components/basics/PmContextNavigation/PmContextNavigationListPure.vue'

export interface Props {
  id?: string
  label?: string
  icon: Icon
  items?: ContextNavigationListPureProps['items']
  isLoading?: boolean
  hideLabelOnMobile?: boolean
  hasNotification?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const popoverVisible = ref(false)
const popoverElement = ref<HTMLElement>()
const elTrigger = ref<InstanceType<typeof PmMainNavigationItemPure>>()

onMounted(() => {
  if (!elTrigger.value?.elRoot) {
    throw new Error('popoverElement not found')
  }

  popoverElement.value = elTrigger.value.elRoot
})

const isPopoverVisibleNormalized = computed(() => {
  if (!elTrigger.value) return false
  return popoverVisible.value
})

function togglePopover() {
  if (popoverVisible.value) {
    popoverVisible.value = false
    return
  }

  popoverVisible.value = true
}

function onClickOutside() {
  popoverVisible.value = false
}
</script>

<style lang="scss">
.PmMainNavigationDropdownPure {
  $block: &;
}
</style>
