import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmLoginFormPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmLoginFormPureState = createMachine({
  tsTypes: {} as import('./PmLoginFormPureState.typegen').Typegen0,

  id: 'PmLoginFormPureState',
  initial: 'default',

  states: {
    default: {},
    loggingIn: {},
    success: {},
    failed: {
      meta: {
        error: true,
        errorMessage: 'Es gab einen unbekannten Fehler bei der Anmeldung',
      },
    },
  },
})

export const states = extractAllStates(PmLoginFormPureState)
