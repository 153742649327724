<template>
  <div class="PmButtonListDividerPure" :class="classes"></div>
</template>

<script lang="ts">
const COMPONENT_NAME = 'PmButtonListDividerPure'

export const propTypes = {}
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { injectStrict, buttonListPureKey } from '@/utilities/inject'

export interface Props {}
// const props = withDefaults(defineProps<Props>(), {})

const { align, fullWidthOnMobile, layout } = injectStrict(buttonListPureKey)

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--fullWidthOnMobile`]: fullWidthOnMobile.value === true,
    [`${COMPONENT_NAME}--alignLeft`]: align.value === 'left',
    [`${COMPONENT_NAME}--alignRight`]: align.value === 'right',
    [`${COMPONENT_NAME}--layoutDistribute`]: layout.value === 'distribute',
  }
})
</script>

<style lang="scss">
.PmButtonListDividerPure {
  $block: &;

  margin-left: -8px;

  &--fullWidthOnMobile {
    @include mixin.media('<=buttons-full-width') {
      display: none;
    }
  }

  &--layoutDistribute {
    display: none;
  }

  &--alignLeft {
    margin-right: auto;
  }

  &--alignRight {
    margin-left: auto;
  }
}
</style>
