<template>
  <div :class="componentClass.root">
    <slot name="from" />

    <PmIconPure
      v-if="hasSlotContent.from && hasSlotContent.to"
      name="arrowRight"
      :class="componentClass.element('arrow')"
    />

    <slot name="to" />
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import { useHasSlotContent } from '@/composition/useHasSlotContent'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
const hasSlotContent = useHasSlotContent(['from', 'to'])
</script>

<style lang="scss">
.PmFromToPure {
  $block: &;

  display: flex;
  gap: 4px;
  align-items: center;

  &-arrow {
    width: 12px;
    height: 12px;
    color: color.$gray-500;
  }
}
</style>
