<template>
  <PmAppointmentsContainerPure
    :id="id"
    v-slot="{ packingContainer, constrainedStartDate, constrainedEndDate }"
    v-bind="propsAppointmentContainer"
    :start-date="startDate"
    :end-date="endDate"
    :container-start-date="containerStartDate"
    :container-end-date="containerEndDate"
    :items-for-content-bounds="itemsForContentBounds"
    :is-highlighted="isHighlighted"
    :type="CALENDAR_ITEM_TYPE.JOB"
    :update-group-visible="can('edit', 'persoplan')"
    :alternative-color-visible="can('edit', 'persoplan')"
    :can-edit-resource-request="can('edit', 'resourceRequest')"
    @show-details="showDetails"
    @jump="jumpToJobInSidebar"
    @update-color="updateAlternativeColor"
    @use-random-color="deleteAlternativeColor"
    @open-note="showDetails"
    @request-considered="isRequestAllOfJobVisible = true"
  >
    <PmPackingElementPure
      v-for="appointment in combinedAppointmentsNormalized"
      :key="appointment.id"
      v-slot="{ styles }"
      :packing-container="packingContainer"
    >
      <component
        :is="appointment.component"
        :style="styles"
        :container-start-date="constrainedStartDate"
        :container-end-date="constrainedEndDate"
        v-bind="appointment.props"
      >
        <template
          v-if="
            appointment.component == PmAppointmentPure &&
            'resources' in appointment
          "
        >
          <PmResource
            v-for="resource in appointment.resources"
            :id="resource.id"
            :key="resource.id"
            :edit-buttons-visible="persoplanState.state.value.matches('edit')"
            :container-start-date="containerStartDate"
          />
        </template>
      </component>
    </PmPackingElementPure>

    <PmRequestAllOfJob
      v-if="isRequestAllOfJobVisible === true"
      :job-id="id"
      @close="isRequestAllOfJobVisible = false"
    />
  </PmAppointmentsContainerPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmJob'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
/**
 * @note Don't use v-if on PmAppointmentsContainer, it might break packing!
 * @todo Update dateconstrain, that 00:00 and container 00:00 startDate is not constrained
 */

import { ref, computed } from 'vue'

import { orderBy, cloneDeep } from 'lodash-es'
import { useStore } from 'vuex'
import { useMutation } from '@vue/apollo-composable'
import cuid from '@paralleldrive/cuid2'

import { EVENT } from '@/constants/events'
import { DATA_MODAL_TYPE, CALENDAR_ITEM_TYPE } from '@/constants/persoplan'

import {
  parseServerDateString,
  formatToServerDateString,
  transformToAlternativeEndOfDay,
  startDateForServer,
  endDateForServer,
} from '@/utilities/date'

import { useAppAbility } from '@/composition/useAppAbility'
import { statusLookupJob } from '@/utilities/persoplan'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import { handleMutationError } from '@/functional/handleMutationError'
import { useCachedQuery } from '@/composition/useCachedQuery'
import EventHub from '@/eventHub'
import { createArtificialAppointments } from '@/functional/artificialAppointments'

import PmAppointmentsContainerPure, {
  type Props as PropsAppointmentsContainerPure,
} from '@/components/persoplan/PmAppointmentsContainer/PmAppointmentsContainerPure.vue'
import PmPackingElementPure from '@/components/persoplan/PmPackingElementPure.vue'
import PmAppointment from '@/components/persoplan/PmAppointment/PmAppointment.vue'
import PmAppointmentPure from '@/components/persoplan/PmAppointment/PmAppointmentPure.vue'
import PmResource from '@/components/persoplan/PmResource/PmResource.vue'
import PmRequestAllOfJob from '@/components/persoplan/PmRequestAllOfJob/PmRequestAllOfJob.vue'

import {
  JobDocument,
  UpdateAlternativeColorDocument,
} from '@/../generated/graphql'

export interface Props {
  containerStartDate: Date
  containerEndDate: Date
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const persoplanState = injectStrict(persoplanStateKey)
const store = useStore()
const { can } = useAppAbility()
const isRequestAllOfJobVisible = ref(false)

const jobQuery = useCachedQuery(JobDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']

  const startDateAlternativeStartOfDay = formatToServerDateString(
    transformToAlternativeEndOfDay(props.containerStartDate)
  )

  return {
    id: props.id,
    startDate: startDateForServer(props.containerStartDate),
    endDate: endDateForServer(props.containerEndDate),
    startDateAlternativeStartOfDay,
    resourceTypeIds: queryVariables.resourceTypeIds,
    excludeResourceTypeIds: queryVariables.excludeResourceTypeIds,
    resourceFunctionIds: queryVariables.resourceFunctionIds,
    excludeResourceFunctionIds: queryVariables.excludeResourceFunctionIds,
  }
})

const job = computed(() => jobQuery.result.value?.job)

const startDate = computed(() => {
  return parseServerDateString(job.value?.startDate)
})

const endDate = computed(() => {
  return parseServerDateString(job.value?.endDate)
})

const propsAppointmentContainer = computed(() => {
  if (!job.value) return

  const title = job.value.caption
  const jobStateId = job.value?.jobState?.id
  const status = jobStateId
    ? statusLookupJob({
        jobStateId: jobStateId,
        jobTypeId: job.value?.jobState?.jobType?.id,
      })
    : undefined

  const result: Partial<PropsAppointmentsContainerPure> = {
    title,
    subtitle: job.value?.project?.caption,
    jobStatus: status,
    numberOfAlerts: job.value?.numberOfMissingResources ?? undefined,
    alertsVisible: store.state.view.currentView.showMissingResourcesWarning,
    number: job.value.number,
    projectId: job.value?.project?.id,
    groupNumber: job.value?.project?.group?.number ?? undefined,
    alternativeColor: settings.value?.alternativeColor,
    note: job.value.comment ?? undefined,
  }

  return result
})

const normalizedAppointments = computed(() => {
  if (!job.value?.appointments) return []

  type Appointment = {
    id: number
    startDate: Date
    endDate: Date
  }

  const normalizedAppointments = job.value.appointments.reduce(
    (result: Appointment[], appointment) => {
      if (!appointment) return result

      const startDate = parseServerDateString(appointment.startDate)
      const endDate = parseServerDateString(appointment.endDate)

      if (!startDate) throw new Error('startDate is undefined')
      if (!endDate) throw new Error('endDate is undefined')

      result.push({
        id: appointment.id,
        startDate, // Used for sorting
        endDate, // Used for sorting
      })

      return result
    },
    []
  )

  const sortedAppointments = orderBy(
    normalizedAppointments,
    ['startDate'],
    ['asc']
  )

  return sortedAppointments
})

const combinedAppointmentsNormalized = computed(() => {
  const appointmentsNormalized = cloneDeep(normalizedAppointments.value).map(
    (appointment) => {
      return {
        ...appointment,
        component: PmAppointment,

        props: {
          id: appointment.id,
          containerStartDateUnconstrained: startDate.value,
          containerEndDateUnconstrained: endDate.value,
          startDate: appointment.startDate,
          endDate: appointment.endDate,
        },
      }
    }
  )

  const artificialAppointmentsNormalized =
    (artificialAppointments.value &&
      cloneDeep(artificialAppointments.value).map((artificialAppointment) => {
        const id = cuid.createId()

        return {
          ...artificialAppointment,
          component: PmAppointmentPure,
          id: id,

          props: {
            id: id,
            title: '- ohne Termin -',
            containerStartDateUnconstrained: startDate.value,
            containerEndDateUnconstrained: endDate.value,
            startDate: artificialAppointment.startDate,
            endDate: artificialAppointment.endDate,
            isWithDetails: false,
          },
        }
      })) ??
    []

  const sortedAppointments = orderBy(
    [...appointmentsNormalized, ...artificialAppointmentsNormalized],
    ['startDate'],
    ['asc']
  )

  return sortedAppointments
})

const itemsForContentBounds = computed(() => {
  return [
    ...normalizedAppointments.value,
    ...(artificialAppointments.value ?? []),
  ].map((item) => {
    return {
      startDate: item.startDate,
      endDate: item.endDate,
    }
  })
})

const settings = computed(() => {
  const json = job.value?.globalSetting?.value
  if (!json) return null

  try {
    return JSON.parse(json)
  } catch {
    console.warn('Could not parse json for global Settings')
  }

  return null
})

const isHighlighted = computed({
  get() {
    return store.getters['persoplan/isItemHighlighted']({
      id: props.id,
      type: CALENDAR_ITEM_TYPE.JOB,
    })
  },
  set(value) {
    if (value === true) {
      store.commit('persoplan/addToHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.JOB,
      })
    } else {
      store.commit('persoplan/removeFromHighlightedItems', {
        id: props.id,
        type: CALENDAR_ITEM_TYPE.JOB,
      })
    }
  },
})

/**
 * Artifical appointments for resources without an appointment
 */
const artificialAppointments = computed(() => {
  if (!job.value?.resourcesWithoutAppointment?.length) return []

  return createArtificialAppointments({
    resources: job.value.resourcesWithoutAppointment,
  })
})

function showDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.JOB,
    id: props.id,
  })
}

function jumpToJobInSidebar() {
  EventHub.$emit(EVENT.SIDEBAR_JOB_SCROLL_INTO_VIEW, {
    id: props.id,
    type: CALENDAR_ITEM_TYPE.JOB,
  })

  store.commit('persoplan/clearHighlightedItems')
  isHighlighted.value = true
}

const updateAlternativeColorMutation = useMutation(
  UpdateAlternativeColorDocument,

  () => ({
    update: (cache, result) => {
      const newValue = result.data?.saveGlobalSetting?.value
      if (!newValue) return

      const cacheId = cache.identify({
        __typename: 'Job',
        id: props.id,
      })

      cache.modify({
        id: cacheId,
        fields: {
          globalSetting(cachedValue) {
            return {
              ...cachedValue,
              value: newValue,
            }
          },
        },
      })
    },
  })
)

async function updateAlternativeColor(color: string | null) {
  const key = `Job:${props.id}`
  const value = JSON.stringify({
    alternativeColor: color,
  })

  try {
    await updateAlternativeColorMutation.mutate({
      key,
      value,
    })
  } catch (error) {
    handleMutationError(error)
  }
}

function deleteAlternativeColor() {
  updateAlternativeColor(null)
}
</script>
