import { createMachine } from 'xstate'

import type { Typegen0 } from './PmStartupState.typegen'

export type TState = Typegen0['matchesStates']

export const PmStartupState = createMachine({
  tsTypes: {} as import('./PmStartupState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'MOUNTED' }
      | { type: 'INITIAL_LOADING_FINISHED' }
      | { type: 'CHANGE_PASSWORD' }
      | { type: 'PASSWORD_CHANGED' },
  },

  id: 'PmStartupState',
  initial: 'waitForMounted',

  states: {
    waitForMounted: {
      on: {
        MOUNTED: 'initialLoading',
      },
    },

    initialLoading: {
      entry: 'loadInitialData',

      on: {
        INITIAL_LOADING_FINISHED: 'finished',
        CHANGE_PASSWORD: 'changePassword',
      },
    },

    changePassword: {
      initial: 'default',

      states: {
        default: {
          on: {
            PASSWORD_CHANGED: 'success',
          },
        },
        success: {
          after: {
            2_000: '#PmStartupState.initialLoading',
          },
        },
      },
    },

    finished: {
      type: 'final',
      entry: 'emitDone',
    },
  },
})
