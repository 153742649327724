<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('controls')">
      <PmButtonListPure :class="componentClass.element('actions')">
        <template v-if="mode === 'current'">
          <PmButtonPure
            v-for="(button, key) in actions"
            :key="key"
            v-bind="button"
            :disabled="button.disabled || isLoading"
            @click="onAction(key)"
          />
        </template>

        <PmContextNavigationPure
          :items="[
            {
              ...propsJumpToCalendar,
              id: 'jumpToCalendar',
              label: 'Im Kalender anzeigen',
              icon: 'jump',
            },
          ]"
        />
      </PmButtonListPure>
    </div>

    <div :class="componentClass.element('content')">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import type { Get } from 'type-fest'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmContextNavigationPure, {
  type Props as PropsContextNavigationPure,
} from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'
import { formatToServerDateString } from '@/utilities/date'
import { addDays, subDays } from 'date-fns'

export interface Props {
  isLoading?: boolean
  canBeApproved?: boolean
  startDate?: Date
  endDate?: Date
  mode?: 'current' | 'archive'
}

const props = withDefaults(defineProps<Props>(), {
  canBeApproved: true,
  mode: 'current',
})

const emit = defineEmits<{
  (event: 'reject'): void
  (event: 'approve'): void
  (event: 'jumpToCalendar'): void
}>()

const componentClass = useComponentClass()

const actions = computed<Record<'reject' | 'approve', PropsButtonPure>>(() => ({
  reject: {
    label: 'Ablehnen',
    icon: 'close',
  },
  approve: {
    label: 'Genehmigen',
    icon: 'check',
    disabled: !props.canBeApproved,
  },
}))

function onAction(action: keyof typeof actions.value) {
  if (action === 'reject') return emit('reject')
  if (action === 'approve') return emit('approve')

  throw new Error('action is not defined')
}

const hasStartAndEndDate = computed(() => {
  if (!props.startDate) return false
  if (!props.endDate) return false

  return true
})

const propsJumpToCalendar = computed(() => {
  type Result = Partial<
    NonNullable<Get<PropsContextNavigationPure, 'items[0]'>>
  >

  let to: Result['to'] = undefined

  if (props.startDate && props.endDate) {
    /** Days to additionally extend the start end dates */
    const marginDays = 2

    const startDate = formatToServerDateString(
      subDays(props.startDate, marginDays),
      {
        type: 'date',
      }
    )
    const endDate = formatToServerDateString(
      addDays(props.endDate, marginDays),
      { type: 'date' }
    )

    to = {
      name: 'persoplan',
      query: {
        'start-date': startDate,
        'end-date': endDate,
      },
    }
  }

  const result: Result = {
    disabled: !hasStartAndEndDate.value,
    to,
  }

  return result
})
</script>

<style lang="scss">
.PmRequestOverviewDetailsPure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: 12px;

  &-controls {
    display: flex;
  }

  &-actions {
    margin-left: auto;
  }
}
</style>
