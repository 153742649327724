<template>
  <div class="PmResourceRequestOverviewFilterPure">
    <div class="PmResourceRequestOverviewFilterPure-control">
      <PmInputContainerPure
        label="Zeitraum"
        :note="noteTimeframe"
        :reserver-space-for-note="true"
      >
        <PmButtonPure
          icon="calendar"
          :label="dateLabel"
          :disabled="isDateControllerDisabled"
          @click="emit('openDateController')"
        />
      </PmInputContainerPure>
    </div>

    <div class="PmResourceRequestOverviewFilterPure-filter">
      <div class="PmResourceRequestOverviewFilterPure-itemContainer">
        <PmDropdownPure
          label="Projekt"
          class="PmResourceRequestOverviewFilterPure-item"
          :has-reset="true"
          :is-loading="isProjectsLoading"
          :options="projects"
          :value="projectId"
          @input-searchterm="(value) => emit('update:searchTermProject', value)"
          @input="updateProjectId"
        />

        <PmCheckboxPure
          :value="useTimeframeOfProject"
          label="Zeitraum verwenden"
          :disabled="isUseTimeframeOfProjectDisabled"
          @update:value="(value) => emit('update:useTimeframeOfProject', value)"
        />
      </div>

      <div class="PmResourceRequestOverviewFilterPure-itemContainer">
        <PmDropdownPure
          label="Job"
          class="PmResourceRequestOverviewFilterPure-item"
          :has-reset="true"
          :is-loading="isJobsLoading"
          :options="jobs"
          :value="jobId"
          @input-searchterm="(value) => emit('update:searchTermJob', value)"
          @input="updateJobId"
        />

        <PmCheckboxPure
          :value="useTimeframeOfJob"
          label="Zeitraum verwenden"
          :disabled="isUseTimeframeOfJobDisabled"
          @update:value="(value) => emit('update:useTimeframeOfJob', value)"
        />
      </div>

      <PmDropdownPure
        label="Job- oder Projektleiter"
        class="PmResourceRequestOverviewFilterPure-item"
        :options="jobOrProjectLeaders"
        :value="jobOrProjectLeaderId"
        :is-loading="isLoadingStaticData"
        :disabled="isLoadingStaticData"
        :has-reset="true"
        @input="updateSelectedJobOrProjectLeaderId"
      />

      <PmDropdownPure
        label="Erstellt von"
        class="PmResourceRequestOverviewFilterPure-item"
        :options="createdByOptions"
        :value="createdById"
        :is-loading="isLoadingStaticData"
        :disabled="isLoadingStaticData"
        :has-reset="true"
        @input="updateCreatedById"
      />

      <PmDropdownPure
        label="Ressourcenfunktion"
        class="PmResourceRequestOverviewFilterPure-item"
        :has-reset="true"
        :options="resourceFunctions"
        :is-loading="isLoadingStaticData"
        :disabled="isLoadingStaticData"
        :value="resourceFunctionId"
        @input="updateSelectedResourceFunctionId"
      />

      <PmDropdownPure
        label="Ressource"
        class="PmResourceRequestOverviewFilterPure-item"
        :has-reset="true"
        :options="resources"
        :value="resourceId"
        :is-loading="isResourceLoading"
        @input-searchterm="(value) => emit('update:searchTermResource', value)"
        @input="updateResourceId"
      />

      <PmDropdownPure
        label="Status"
        class="PmResourceRequestOverviewFilterPure-item"
        :has-reset="true"
        :options="optionsStatus"
        :value="statusId"
        @input="updateStatusId"
      />

      <PmDropdownPure
        label="Status Feedback"
        class="PmResourceRequestOverviewFilterPure-item"
        :has-reset="true"
        :options="optionsStatusFeedback"
        :value="statusFeedbackId"
        @input="updateStatusFeedbackId"
      />

      <PmDropdownPure
        label="Aktualisiert"
        class="PmResourceRequestOverviewFilterPure-item"
        :has-reset="true"
        :options="optionsUpdatedWithin"
        :value="updatedWithinId"
        @input="updateUpdatedWithinId"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  type StatusResourceRequestFeedback,
  type StatusResourceRequest,
  STATUS_RESOURCE_REQUEST_FEEDBACK,
  STATUS_RESOURCE_REQUEST_FEEDBACK_LOOKUP_LABEL,
  STATUS_RESOURCE_REQUEST,
  STATUS_RESOURCE_REQUEST_LOOKUP_LABEL,
} from '@/constants/persoplan'
import { formatWithLocale } from '@/utilities/date'

import PmDropdownPure, {
  type Props as PropsDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmInputContainerPure from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'

import type {
  Option as DropdownOption,
  Emit as EmitDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import type { Nilable } from '@/types/misc'

const optionsUpdatedWithin = [
  {
    id: 'today',
    label: 'Heute',
  },
  {
    id: 'last7days',
    label: 'in den letzten 7 Tagen',
  },
  {
    id: 'last30days',
    label: 'in den letzten 30 Tagen',
  },
] as const satisfies DropdownOption[]

export type UpdatedWithinId = (typeof optionsUpdatedWithin)[number]['id']

export interface Props {
  isLoadingStaticData?: boolean
  startDate?: Date
  endDate?: Date
  jobOrProjectLeaders?: DropdownOption[]
  jobOrProjectLeaderId?: number
  createdByOptions?: DropdownOption[]
  createdById?: number
  resourceFunctions?: DropdownOption[]
  resourceFunctionId?: number
  isProjectsLoading?: boolean
  projects?: DropdownOption[]
  projectId?: number
  isJobsLoading?: boolean
  jobs?: DropdownOption[]
  jobId?: number
  isResourceLoading?: boolean
  resources?: DropdownOption[]
  resourceId?: number
  statusId?: StatusResourceRequest
  statusFeedbackId?: StatusResourceRequestFeedback
  updatedWithinId?: UpdatedWithinId
  isDateControllerDisabled?: boolean
  useTimeframeOfProject?: boolean
  isUseTimeframeOfProjectDisabled?: boolean
  useTimeframeOfJob?: boolean
  isUseTimeframeOfJobDisabled?: boolean
  noteTimeframe?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (e: 'update:jobOrProjectLeaderId', id: number | undefined): void
  (e: 'update:createdById', id: number | undefined): void
  (e: 'update:resourceFunctionId', id: number | undefined): void
  (e: 'openDateController'): void
  (e: 'update:searchTermProject', value: Nilable<string>): void
  (e: 'update:searchTermJob', value: Nilable<string>): void
  (e: 'update:searchTermResource', value: Nilable<string>): void
  (e: 'update:projectId', id: number | undefined): void
  (e: 'update:jobId', id: number | undefined): void
  (e: 'update:resourceId', id: number | undefined): void
  (e: 'update:statusId', id: StatusResourceRequest | undefined): void
  (
    e: 'update:statusFeedbackId',
    id: StatusResourceRequestFeedback | undefined
  ): void
  (e: 'update:updatedWithinId', id: UpdatedWithinId | undefined): void
  (e: 'update:useTimeframeOfProject', value: boolean): void
  (e: 'update:useTimeframeOfJob', value: boolean): void
  // (e: 'openSearch'): void
}>()

const dateLabel = computed(() => {
  if (!props.startDate || !props.endDate) return

  return `${formatWithLocale(props.startDate)} — ${formatWithLocale(
    props.endDate
  )}`
})

function assertValidId(
  value: EmitDropdownPure['input']
): asserts value is number | undefined {
  if (value === undefined) return
  if (typeof value === 'number') return

  throw new Error('value is not valid')
}

function updateSelectedJobOrProjectLeaderId(value: EmitDropdownPure['input']) {
  assertValidId(value)
  emit('update:jobOrProjectLeaderId', value)
}

function updateCreatedById(value: EmitDropdownPure['input']) {
  assertValidId(value)
  emit('update:createdById', value)
}

function updateSelectedResourceFunctionId(value: EmitDropdownPure['input']) {
  assertValidId(value)
  emit('update:resourceFunctionId', value)
}

function updateProjectId(value: EmitDropdownPure['input']) {
  assertValidId(value)
  emit('update:projectId', value)
}

function updateJobId(value: EmitDropdownPure['input']) {
  assertValidId(value)
  emit('update:jobId', value)
}

function updateResourceId(value: EmitDropdownPure['input']) {
  assertValidId(value)
  emit('update:resourceId', value)
}

/**
 * Status
 */
const optionsStatus = computed(() => {
  const options: PropsDropdownPure['options'] = []

  Object.values(STATUS_RESOURCE_REQUEST).forEach((id) => {
    options.push({
      id: id,
      label: STATUS_RESOURCE_REQUEST_LOOKUP_LABEL[id],
    })
  })

  return options
})

function assertValidStatusId(
  value: EmitDropdownPure['input']
): asserts value is StatusResourceRequest | undefined {
  if (value === undefined) return
  if (typeof value !== 'string') throw new Error('value is not string')

  const possibleIds = Object.values(STATUS_RESOURCE_REQUEST)
  if (!possibleIds.includes(value as StatusResourceRequest))
    throw new Error('value is not allowed for statusId')
}

function updateStatusId(value: EmitDropdownPure['input']) {
  assertValidStatusId(value)
  emit('update:statusId', value)
}

/**
 * Status Feedback
 */
const optionsStatusFeedback = computed(() => {
  const options: PropsDropdownPure['options'] = []

  Object.values(STATUS_RESOURCE_REQUEST_FEEDBACK).forEach((id) => {
    options.push({
      id: id,
      label: STATUS_RESOURCE_REQUEST_FEEDBACK_LOOKUP_LABEL[id],
    })
  })

  return options
})

function assertValidStatusFeedbackId(
  value: EmitDropdownPure['input']
): asserts value is StatusResourceRequestFeedback | undefined {
  if (value === undefined) return
  if (typeof value !== 'string') throw new Error('value is not string')

  const possibleIds = Object.values(STATUS_RESOURCE_REQUEST_FEEDBACK)
  if (!possibleIds.includes(value as StatusResourceRequestFeedback))
    throw new Error('value is not allowed for statusFeedbackId')
}

function updateStatusFeedbackId(value: EmitDropdownPure['input']) {
  assertValidStatusFeedbackId(value)
  emit('update:statusFeedbackId', value)
}

/**
 * Updated within
 */

function assertValidUpdatedWithinId(
  value: EmitDropdownPure['input']
): asserts value is UpdatedWithinId | undefined {
  if (value === undefined) return
  if (typeof value !== 'string') throw new Error('value is not string')

  const possibleIds = optionsUpdatedWithin.map((item) => item.id)
  if (!possibleIds.includes(value as UpdatedWithinId))
    throw new Error('value is not allowed for statusFeedbackId')
}
function updateUpdatedWithinId(value: EmitDropdownPure['input']) {
  assertValidUpdatedWithinId(value)
  emit('update:updatedWithinId', value)
}
</script>

<style lang="scss">
.PmResourceRequestOverviewFilterPure {
  $block: &;

  display: flex;
  flex-direction: column;
  gap: 16px;

  &-control {
    display: flex;
    gap: 8px;
    align-items: flex-end;
  }

  &-filter {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 16px;
    row-gap: 16px;
    align-items: start;
  }

  &-itemContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-item {
    flex-basis: 100%;
  }
}
</style>
