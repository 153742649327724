import { computed, type Ref } from 'vue'

import {
  type WebfleetStatus,
  STATUS_WEBFLEET_LOOKUP_LABEL,
} from '@/constants/persoplan'
import scssVariables from '@/assets/scss/scssVariables'

export type { WebfleetStatus } from '@/constants/persoplan'

interface Options {
  status: Ref<WebfleetStatus | undefined | null>
}

export function useWebfleetStatus(options: Options) {
  const classes = computed(() => {
    if (!options.status.value) return

    return {
      'is-statusCreated': options.status.value === 'created',
      'is-statusUpdated': options.status.value === 'updated',
      'is-statusWorkInProgress': options.status.value === 'workInProgress',
      'is-statusAborted': options.status.value === 'aborted',
      'is-statusDeleted': options.status.value === 'deleted',
    }
  })

  const color = computed(() => {
    if (!options.status.value) return

    if (options.status.value === 'created') {
      return scssVariables.color['status-confirmed']
    }

    if (options.status.value === 'updated') {
      return scssVariables.color['status-confirmed']
    }

    if (options.status.value === 'workInProgress') {
      return scssVariables.color['status-requested']
    }

    if (options.status.value === 'aborted') {
      return scssVariables.color['status-cancelled']
    }

    if (options.status.value === 'deleted') {
      return scssVariables.color['status-cancelled']
    }

    return undefined
  })

  const label = computed(() => {
    if (!options.status.value) return
    return STATUS_WEBFLEET_LOOKUP_LABEL[options.status.value]
  })

  return {
    classes,
    color,
    label,
  }
}
