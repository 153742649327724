import { createMachine } from 'xstate'

import type { Typegen0 } from './PmChangePasswordState.typegen'

export type TState = Typegen0['matchesStates']

export const PmChangePasswordState = createMachine({
  tsTypes: {} as import('./PmChangePasswordState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'SUBMIT' },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmChangePasswordState',
  initial: 'default',
  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        SUBMIT: 'saving',
      },
    },

    saving: {
      invoke: {
        id: 'change-password',
        src: 'changePassword',

        onError: {
          target: 'failed',
          actions: (context, error) => {
            console.error(error)
            context.error = error.data.message
            context.errorDetails = error.data.details
          },
        },
        onDone: 'success',
      },
    },

    success: {
      entry: 'emitSuccess',

      on: {
        SUBMIT: 'saving',
      },
    },

    failed: {
      on: {
        SUBMIT: 'saving',
      },
    },
  },
})
