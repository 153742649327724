<template>
  <PmTableGroupPure :class="componentClass.root">
    <template #group>
      <div :class="componentClass.element('header')">
        <span :class="componentClass.element('project')">{{ project }}</span>
        <span :class="componentClass.element('job')">{{ job }}</span>
      </div>
    </template>

    <slot />
  </PmTableGroupPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceRequestOverviewListGroupPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { useComponentClass } from '@/composition/useComponentClass'

import PmTableGroupPure from '@/components/basics/PmTable/PmTableGroupPure.vue'

export interface Props {
  project: string
  job: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
</script>

<style lang="scss">
.PmResourceRequestOverviewListGroupPure {
  $block: &;

  &-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  &-project {
    @include mixin.truncateText;

    font-weight: bold;
  }

  &-job {
    @include mixin.truncateText;
  }
}
</style>
