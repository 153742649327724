<template>
  <PmSendToWebfleetPure
    v-model:note="note"
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :address="normalizeAddress(webfleetOrder?.deliveryAddress)"
    :status-webfleet="webfleetOrder?.state"
    :contact-person="{
      name: webfleetOrder?.contactPerson,
      phone: webfleetOrder?.contactPhoneNumber,
    }"
    :order-date-time="parseServerDateString(webfleetOrder?.orderDateTime)"
    :order-text="webfleetOrder?.orderText"
    :vehicle="webfleetOrder?.vehicle?.caption"
    @send-to-webfleet="xstate.service.value.send('SEND')"
    @cancel="emit('close')"
    @delete-in-webfleet="xstate.service.value.send('DELETE')"
  >
  </PmSendToWebfleetPure>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useMutation, useApolloClient } from '@vue/apollo-composable'
import { useStore } from 'vuex'
import type { ApolloQueryResult } from '@apollo/client'

import { ICONS } from '@/constants/icons'
import { parseServerDateString } from '@/utilities/date'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useXState } from '@/composition/useXState'
import { throwFriendlyError } from '@/functional/error'
import { normalizeAddress } from '@/utilities/address'
import { PmSendToWebfleetState } from '@/components/persoplan/PmSendToWebfleet/PmSendToWebfleetState'

import PmSendToWebfleetPure from '@/components/persoplan/PmSendToWebfleet/PmSendToWebfleetPure.vue'

import {
  DeleteInWebfleetDocument,
  SendToWebfleetDocument,
  SendToWebfleetPreviewDocument,
  type SendToWebfleetPreviewQuery,
} from '@/../generated/graphql'

export interface Props {
  resourceAllocationId: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const store = useStore()
const { client: apolloClient } = useApolloClient()
const note = ref<string>()

const xstate = useXState(PmSendToWebfleetState, {
  services: {
    loadWebfleetOrder: loadWebfleetOrder,
    sendToWebfleet: sendToWebfleet,
    deleteInWebfleet: deleteInWebfleet,
  },

  actions: {
    showSendSuccessNotification: () => {
      store.commit('notification/add', {
        variant: 'success',
        icon: ICONS.CHECK,
        title: 'Übertragung an Webfleet erfolgreich',
      })
    },

    showDeleteSuccessNotification: () => {
      store.commit('notification/add', {
        variant: 'success',
        icon: ICONS.CHECK,
        title: 'Der Auftrag wurde erfolgreich in Webfleet gelöscht',
      })
    },

    emitClose: () => {
      emit('close')
    },
  },
})

const webfleetOrder =
  ref<ApolloQueryResult<SendToWebfleetPreviewQuery>['data']['webfleetOrder']>()

async function loadWebfleetOrder() {
  try {
    const result = await apolloClient.query({
      query: SendToWebfleetPreviewDocument,
      variables: {
        resourceAllocationId: props.resourceAllocationId,
      },
      fetchPolicy: 'no-cache',
    })

    webfleetOrder.value = result.data.webfleetOrder
    note.value = result.data.webfleetOrder?.notice ?? undefined
  } catch (error) {
    console.log('error!')
    throwFriendlyError(error)
  }
}

const sendToWebfleetMutation = useMutation(SendToWebfleetDocument)

async function sendToWebfleet() {
  try {
    await sendToWebfleetMutation.mutate({
      resourceAllocationId: props.resourceAllocationId,
      note: note.value,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}

const deleteInWebfleetMutation = useMutation(DeleteInWebfleetDocument)

async function deleteInWebfleet() {
  try {
    await deleteInWebfleetMutation.mutate({
      resourceAllocationId: props.resourceAllocationId,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}
</script>
