<template>
  <PmSidebarProjectPure
    v-if="purchaseOrder"
    :id="purchaseOrder.id"
    :title="purchaseOrder.caption ?? undefined"
    :alerts-visible="false"
    :pill="purchaseOrder.number ?? undefined"
    :update-group-visible="false"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarPurchaseOrder'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { useCachedQuery } from '@/composition/useCachedQuery'

import PmSidebarProjectPure from '@/components/persoplan/PmSidebarProject/PmSidebarProjectPure.vue'

import { SidebarPurchaseOrderDocument } from '@/../generated/graphql'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const purchaseOrderQuery = useCachedQuery(SidebarPurchaseOrderDocument, () => ({
  id: props.id,
}))

const purchaseOrder = computed(
  () => purchaseOrderQuery.result.value?.purchaseOrder
)
</script>
