export const ERROR = {
  CACHEUPDATE_FAILED: 'cacheupdate-failed',
}

interface KnownError {
  message: string
  report?: boolean
  details?: string | string[]
  operations?: { [key: string]: string }
}

export const knownErrors: { [key: string]: KnownError } = {
  'login.password_incorrect': {
    message: 'Die Anmeldedaten sind ungültig.',
    report: false,
    operations: {
      changePassword: 'Das alte Passwort ist ungültig.',
    },
  },

  'Fehlerhafte Zugangsdaten.': {
    message: 'Die Anmeldedaten sind ungültig.',
  },

  'persoplanview.not_editable_or_found': {
    message:
      'Die Ansicht wurde nicht gefunden oder du darfst sie nicht bearbeiten.',
    report: false,
  },

  'projectgroup.no_permission': {
    message: 'Keine Berechtigung zum Ändern der Projektgruppe.',
    report: false,
  },

  'resourceallocation.exists_already': {
    message:
      'Die Ressourcenzuordnung besteht bereits. Bitte mal F5 drücken, um den Plan zu aktualisieren, falls sie nicht angezeigt wird.',
    report: false,
  },

  'resourceallcoation.not_found': {
    message:
      'Die Ressourcenzuordnung existiert nicht und kann daher nicht bearbeitet/gelöscht werden.',
    report: false,
  },

  'resourceallocation.no_permission': {
    message: 'Keine Berechtigung zum Anlegen dieser Resourcen-Zuordnung.',
    report: false,
  },

  'resourceallocation.linked_with_other_allocation': {
    message:
      'Die Ressourcenzuordnung kann nicht gelöscht werden, da eine andere Ressourcenzuordnung damit verknüpft ist. Bitte in EasyJob löschen.',
    report: false,
  },

  'Expired JWT Token': {
    message:
      'Der Anmeldetoken ist abgelaufen. Dieser sollte normal automatisch erneuert werde. Bitte mal F5 drücken.',
    report: true,
  },

  'cacheupdate-failed': {
    message:
      'Der Vorgang wurde wahrscheinlich durchgeführt aber es gab ein Problem beim Aktualisieren der Ansicht. Bitte das Browserfenster aktualisieren.',
    report: false,
  },

  'Invalid credentials.': {
    message: 'Die eingegebenen Anmeldedaten sind ungültig.',
    report: false,
  },

  'resource_state.no_permission': {
    message:
      'Dir fehlt die Berechtigung um diesen Ressourcen-Status zu bearbeiten.',
    report: false,
    operations: {
      create:
        'Dir fehlt die Berechtigung um diesen Ressourcen-Status zu erstellen.',
      update:
        'Dir fehlt die Berechtigung um diesen Ressourcen-Status zu bearbeiten',
      delete:
        'Dir fehlt die Berechtigung um diesen Ressourcen-Status zu löschen',
    },
  },

  'view_name.already_used.private': {
    message: 'Dieser Name wird bereits für eine andere Ansicht verwendet.',
  },

  'view_name.already_used.public': {
    message: 'Dieser Name wird bereits für eine öffentliche Ansicht verwendet.',
  },

  leave_request_already_exists_in_date_range: {
    message: 'Für diesen Zeitraum existiert bereits ein Eintrag.',
  },
}
