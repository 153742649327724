<template>
  <PmDateControllerPure
    :start-date="store.state.persoplan.visibleStartDate"
    :end-date="store.state.persoplan.visibleEndDate"
    :shortcuts="SHORTCUTS_VISIBLE_TIMEFRAME"
    @set-dates="setDates"
    @close="emit('close')"
  />
</template>

<script setup lang="ts">
import { useStore } from 'vuex'

import { SHORTCUTS_VISIBLE_TIMEFRAME } from '@/constants/persoplan'

import PmDateControllerPure, {
  type Event as EventDateControllerPure,
} from '@/components/persoplan/PmDateControllerPure.vue'

const COMPONENT_NAME = 'PmDateController'

const emit = defineEmits<{
  (event: 'close'): void
}>()

const store = useStore()

function setDates(dates: EventDateControllerPure['setDates']) {
  store.commit('persoplan/setVisibleDates', {
    startDate: dates.start,
    endDate: dates.end,
  })
}
</script>

<style lang="scss">
.PmDateController {
  $block: &;
}
</style>
