<template>
  <PmDataModalProjectSettingsPure
    v-if="value"
    v-model:value="value"
    :loading="loading"
    :state="xstate.path.value"
    :disabled="!$can('edit', 'persoplan')"
    @save="(settings) => xstate.service.value.send('SAVE', settings)"
  />
</template>

<script>
import { getCurrentInstance } from 'vue'
import { cloneDeep } from 'lodash-es'

import { useXState } from '@/composition/useXState'
import { throwFriendlyError } from '@/functional/error'

import { PmDataModalProjectSettingsState } from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectSettingsState'

import PmDataModalProjectSettingsPure from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectSettingsPure.vue'

import UpdateProjectSettingsMutation from '@/components/persoplan/DataModal/DataModalProject/UpdateProjectSettingsMutation.graphql'
import ProjectSettingsQuery from '@/components/persoplan/DataModal/DataModalProject/ProjectSettingsQuery.graphql'
import ProjectFragment from '@/graphql/fragments/ProjectFragment.graphql'

export default {
  name: 'PmDataModalProjectSettings',
  components: {
    PmDataModalProjectSettingsPure,
  },

  props: {
    projectId: { type: Number, required: true },
  },

  setup() {
    const instance = getCurrentInstance()

    const xstate = useXState(PmDataModalProjectSettingsState, {
      services: {
        save: () => instance.ctx.save(),
      },
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      value: undefined,
      loading: false,
    }
  },
  computed: {
    savedValueNormalized() {
      return {
        collectAllJobsOfProject: this.project?.setting?.isJobLayerHidden,
      }
    },
  },

  watch: {
    project() {
      // Create copy of the saved data for editing
      this.value = cloneDeep(this.savedValueNormalized)
    },
  },

  methods: {
    async save() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateProjectSettingsMutation,

          variables: {
            projectId: this.projectId,
            collectAllJobsOfProject: this.value.collectAllJobsOfProject,
          },

          update: (store) => {
            const queryVariables =
              this.$store.getters['queryVariables/calendar']

            const shared = {
              id: `Project:${this.projectId}`,
              fragment: ProjectFragment,
              variables: {
                startDate: queryVariables.startDate,
                endDate: queryVariables.endDate,
              },
            }

            // Read
            const readFragmentResult = store.readFragment({
              ...shared,
            })

            const data = cloneDeep(readFragmentResult)

            // Modify
            data.setting.isJobLayerHidden = this.value.collectAllJobsOfProject

            // Write
            store.writeFragment({
              ...shared,
              data,
            })
          },
        })
      } catch (error) {
        throwFriendlyError(error)
      }
    },
  },

  apollo: {
    project: {
      query: ProjectSettingsQuery,

      variables() {
        const queryVariables = this.$store.getters['queryVariables/calendar']

        return {
          id: this.projectId,
          startDate: queryVariables.startDate,
          endDate: queryVariables.endDate,
        }
      },

      watchLoading(isLoading) {
        this.loading = isLoading
      },
    },
  },
}
</script>

<style lang="scss">
.PmDataModalProjectSettings {
  $block: &;
}
</style>
