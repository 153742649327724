<template>
  <div class="PmMultiSelectionAllocatePure">
    <form
      class="PmMultiSelectionAllocatePure-form"
      @submit.prevent="xstate.service.value.send('CONFIRM')"
    >
      <PmErrorNotificationPure
        v-if="xstate.meta.value.error"
        class="PmMultiSelectionAllocatePure-notification"
        :message="errorMessage || xstate.meta.value.message"
      />

      <PmAppNotificationPure
        v-if="xstate.state.value.matches('success')"
        class="PmMultiSelectionAllocatePure-notification"
        variant="success"
        layout="compact"
      >
        {{ xstate.meta.value.message }}
      </PmAppNotificationPure>

      <div class="PmMultiSelectionAllocatePure-text">
        <strong>{{ resourceTitle }}</strong> wird den folgenden Ressourcen
        zugeordnet:
      </div>

      <PmMultiAllocationListPure
        class="PmMultiSelectionAllocatePure-list"
        :items="resourcesToAllocate"
      />

      <PmDropdownPure
        :options="statusOptions"
        :value="selectedStatusId"
        :disabled="isDropdownDisabled"
        label="Status"
        class="PmMultiSelectionAllocatePure-status"
        required
        @input="(value) => $emit('selectStatus', value)"
      />

      <div class="PmMultiSelectionAllocatePure-control">
        <PmButtonListPure layout="distribute">
          <PmButtonPure
            label="Zurück"
            :icon="ICONS.ARROW_LEFT"
            :disabled="xstate.state.value.matches('saving')"
            @click="$emit('cancel')"
          />

          <PmButtonPure
            v-if="confirmButtonVisible"
            label="Zuordnung durchführen"
            variant="primary"
            type="submit"
            :loading="xstate.state.value.matches('saving')"
          />
        </PmButtonListPure>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, toRef } from 'vue'

import { ICONS } from '@/constants/icons'

import {
  PmMultiSelectionAllocatePureState,
  states,
} from '@/components/persoplan/PmMultiSelectionController/PmMultiSelectionAllocatePureState'
import { useXState } from '@/composition/useXState'
import propValidator from '@/functional/propValidator'

import PmMultiAllocationListPure from '@/components/persoplan/PmMultiSelectionController/PmMultiAllocationListPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmDropdownPure from '@/components/basics/PmDropdownPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmMultiSelectionAllocatePure',
  components: {
    PmButtonListPure,
    PmButtonPure,
    PmMultiAllocationListPure,
    PmAppNotificationPure,
    PmErrorNotificationPure,
    PmDropdownPure,
  },

  props: {
    resourceTitle: { type: String, required: true },
    resourcesToAllocate: { type: Array, default: () => [] },
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    errorMessage: { type: String, default: undefined },
    statusOptions: { type: Array, default: () => [] },
    selectedStatusId: { type: [Number, String], required: true },
  },

  emits: ['confirm', 'selectStatus', 'cancel'],

  setup(props, context) {
    const xstate = useXState(PmMultiSelectionAllocatePureState, {
      actions: {
        emitConfirm: () => context.emit('confirm'),
      },
      syncStateWith: toRef(props, 'state'),
    })

    return {
      ICONS,

      xstate,
    }
  },

  computed: {
    confirmButtonVisible() {
      if (this.xstate.state.value.matches('success')) return false
      if (this.xstate.state.value.matches('failed')) return false

      return true
    },

    isDropdownDisabled() {
      if (this.xstate.state.value.matches('askForConfirmation')) return false
      return true
    },
  },
})
</script>

<style lang="scss">
.PmMultiSelectionAllocatePure {
  $block: &;

  &-notification {
    margin-bottom: 12px;
  }

  &-text {
    margin-bottom: 1em;
  }

  &-list {
    margin-bottom: 16px;
  }

  &-status {
    margin-bottom: 24px;
  }
}
</style>
