<template>
  <div class="PmDayPure" :class="classes">
    <div
      v-if="timeIndicatorVisible"
      class="PmDayPure-timeIndicator"
      :style="{ left: timeInPercent + '%' }"
    ></div>

    <template v-if="backgroundMarkersVisible">
      <PmDayBackgroundMarkerPure
        v-for="(backgroundMarker, index) in backgroundMarkersNormalized"
        :key="`${index}.${backgroundMarker.startTime.getTime()}.${backgroundMarker.endTime.getTime()}`"
        :start-time="backgroundMarker.startTime"
        :end-time="backgroundMarker.endTime"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import {
  isToday,
  getHours,
  getMinutes,
  isSaturday,
  isSunday,
  getDay,
} from 'date-fns'

import PmDayBackgroundMarkerPure from '@/components/persoplan/Day/PmDayBackgroundMarkerPure.vue'

import { useTime } from '@/pinia/time'

export default defineComponent({
  name: 'PmDayPure',
  components: { PmDayBackgroundMarkerPure },

  props: {
    date: { type: Date, required: true },
    isPublicHolidayInGermany: { type: Boolean, default: false },
    isPublicHolidayInLuxemburg: { type: Boolean, default: false },
    backgroundMarkers: { type: Array, default: () => [] },
    coreHours: { type: Array, default: () => [] },
    openingHours: { type: Array, default: () => [] },
  },

  setup() {
    const time = useTime()

    const timeInPercent = computed(() => {
      const percent =
        (100 / (24 * 60)) * (getHours(time.now) * 60 + getMinutes(time.now))

      return percent
    })

    return {
      timeInPercent,
    }
  },

  computed: {
    classes() {
      return {
        'is-holiday': this.isHoliday,
        'is-saturday': isSaturday(this.date),
        'is-sunday': isSunday(this.date),
      }
    },

    backgroundMarkersNormalized() {
      return [
        ...this.backgroundMarkers,
        ...this.coreHoursNormalized,
        ...this.openingHoursNormalized,
      ]
    },

    backgroundMarkersVisible() {
      if (this.isPublicHolidayInGermany) return false
      return true
    },

    openingHoursNormalized() {
      // dateFns day of the week 1 is a monday, 0 is a sunday
      // We want to use day of the week 1 as a monday, 7 as a sunday
      let dayOfTheWeek = getDay(this.date)
      if (dayOfTheWeek === 0) dayOfTheWeek = 7

      return this.openingHours.filter(
        (item) => item.dayOfTheWeek === dayOfTheWeek
      )
    },

    coreHoursNormalized() {
      if (!this.isOpenToday) return []
      return this.coreHours
    },

    timeIndicatorVisible() {
      return isToday(this.date)
    },

    isOpenToday() {
      return !isSaturday(this.date) && !isSunday(this.date)
    },

    isHoliday() {
      if (isSunday(this.date)) return true
      if (this.isPublicHolidayInGermany) return true
      if (this.isPublicHolidayInLuxemburg) return true

      return false
    },
  },
})
</script>

<style lang="scss">
.PmDayPure {
  $block: &;

  flex: none;
  pointer-events: none;
  background-color: #fff;
  // outline: 1px solid color.$gray-300--alpha;
  box-shadow: inset 0 0 0 0.5px color.$gray-300--alpha;
  position: relative;

  &.is-saturday {
    background-color: color.$gray-100;
  }

  &.is-holiday {
    background-color: color.$gray-200;
  }

  &-timeIndicator {
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0;
    background-color: rgba(color.$primary-500, 0.75);
    z-index: 1;

    &::after {
      content: '';
      width: 50px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(color.$key, 0),
        rgba(color.$key, 0.1)
      );
    }
  }
}
</style>
