<template>
  <PmButtonGroupPure class="PmButtonWithActionsPure" :class="$attrs.class">
    <PmButtonPure
      v-if="hasPrimaryAction"
      v-bind="{ ...propsButton, ...separateAttrs($attrs).attributes }"
      @click="emit('click')"
    />

    <PmContextNavigationPure
      :items="actions"
      :in-group="true"
      :is-last-in-group="true"
      :variant="variant"
      :disabled="disabled"
      v-bind="separateAttrs($attrs).listeners"
    >
      <template v-if="!hasPrimaryAction" #trigger="slotProps">
        <PmButtonPure
          v-bind="{ ...propsButton, ...separateAttrs($attrs).attributes }"
          @click="slotProps.togglePopover"
        />
      </template>
    </PmContextNavigationPure>
  </PmButtonGroupPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { separateAttrs } from '@/utilities/misc'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'
import PmButtonGroupPure from '@/components/basics/PmButtonGroupPure.vue'
import PmContextNavigationPure, {
  type Props as PropsContextNavigationPure,
} from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'

defineOptions({
  inheritAttrs: false,
})

export interface Props
  extends Pick<PropsButtonPure, 'variant' | 'disabled' | 'icon' | 'label'> {
  actions?: PropsContextNavigationPure['items']
  /**
   * Determines if the button has a primary action in addition
   * to the secondary actions shown in the context navigation
   */
  hasPrimaryAction?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasPrimaryAction: true,
})

const emit = defineEmits<{
  (event: 'click'): void
}>()

const propsButton = computed<PropsButtonPure>(() => {
  return {
    label: props.label,
    icon: props.icon,
    variant: props.variant,
    disabled: props.disabled,
    inGroup: true,
    isFirstInGroup: true,
  }
})
</script>

<style lang="scss">
.PmButtonWithActionsPure {
  $block: &;
}
</style>
