<template>
  <PmDraggablePure
    display="fullWidth"
    :type="dragAndDropType"
    :data="{ id: id, resourceType: type }"
  >
    <div
      class="PmActionbarListItemPure"
      :data-id="id"
      :data-type="type"
      :data-title="title"
    >
      <div class="PmActionbarListItemPure-content">
        <div class="PmActionbarListItemPure-title">
          {{ title }}
        </div>

        <div v-if="ratings" class="PmActionbarListItemPure-ratings">
          <div
            v-for="(item, index) in ratingsSorted"
            :key="`${item.label}.${index}`"
            class="PmActionbarListItemPure-rating"
          >
            <div class="PmActionbarListItemPure-ratingTitle">
              {{ item.label }}:
            </div>

            <PmRatingPure
              class="PmActionbarListItemPure-ratingStars"
              :rating="item.rating"
            />
          </div>
        </div>

        <div v-if="metaVehicle" class="PmActionbarListItemPure-metaVehicle">
          {{ metaVehicle }}
        </div>
      </div>

      <PmWarningIconPure
        v-if="warnings.length > 0"
        class="PmActionbarListItemPure-warning"
        :warnings="warnings"
      />

      <div class="PmActionbarListItemPure-icon">
        <PmIconPure v-if="icon" :key="icon" :name="icon" size="small" />
      </div>
    </div>
  </PmDraggablePure>
</template>

<script>
import { orderBy } from 'lodash-es'

import { ICONS } from '@/constants/icons'

import PmDraggablePure from '@/components/utilities/PmDraggablePure.vue'
import PmRatingPure from '@/components/basics/PmRatingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmWarningIconPure from '@/components/persoplan/PmWarning/PmWarningIconPure.vue'

import { RESOURCE_TYPE, DRAG_AND_DROP_TYPE } from '@/constants/persoplan'

export default {
  name: 'PmActionbarListItemPure',
  components: {
    PmDraggablePure,
    PmRatingPure,
    PmIconPure,
    PmWarningIconPure,
  },

  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true },
    type: { type: String, required: true },
    ratings: { type: Array, default: () => [] },
    loadingCapacity: { type: Number, default: undefined },
    loadingVolume: { type: Number, default: undefined },
    isProMusik: { type: Boolean, default: undefined },
    hasNoResourceType: { type: Boolean, default: undefined },
  },

  computed: {
    icon() {
      if (this.type === RESOURCE_TYPE.ADDRESS) return ICONS.USER
      if (this.type === RESOURCE_TYPE.VEHICLE) return ICONS.VEHICLE

      return undefined
    },

    ratingsSorted() {
      return orderBy(this.ratings, 'label')
    },

    dragAndDropType() {
      if (this.type === RESOURCE_TYPE.ADDRESS)
        return DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_ADDRESS

      if (this.type === RESOURCE_TYPE.VEHICLE)
        return DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_VEHICLE

      return undefined
    },

    metaVehicle() {
      if (this.loadingCapacity && this.loadingVolume) {
        return `${this.loadingCapacity}t, ${this.loadingVolume}m³`
      }

      if (this.loadingCapacity) {
        return `${this.loadingCapacity}t`
      }

      if (this.loadingVolume) {
        return `${this.loadingVolume}m³`
      }

      return null
    },

    warnings() {
      const warnings = []

      if (this.hasNoResourceType) {
        warnings.push('Kein Resourcen-Typ festgelegt')
      }

      return warnings
    },
  },
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmActionbarListItemPure {
  $block: &;

  border: 1px solid color.$gray-300--alpha;
  border-radius: constant.$borderRadius-default;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  font-size: constant.$fontSize-default;
  line-height: constant.$lineHeight-default;
  cursor: grab;
  z-index: 0;

  &::after {
    @include shadow.drag;

    border-radius: constant.$borderRadius-default;
  }

  &:hover,
  &.is-hover {
    border-color: color.$key;
    color: color.$primary-900;
    background-color: color.$primary-100;
  }

  &.is-dragging {
    &::after {
      opacity: 1;
    }
  }

  &-content {
    margin-right: auto;
    padding: 4px;
  }

  &-title {
    hyphens: auto;
  }

  &-rating {
    display: flex;
    color: color.$gray-700;

    &:first-child {
      margin-top: 4px;
    }
  }

  &-ratingTitle {
    @include mixin.truncateText;

    padding-right: 4px;
    width: 80px;
  }

  &-metaVehicle {
    color: color.$gray-700;
    margin-top: 4px;
  }

  &-warning {
    position: relative;
    z-index: 1;
    flex: none;
    margin-right: 4px;
    width: 26px;
    height: 26px;
    padding: 3px;
  }

  &-icon {
    width: 30px;
    border-left: 1px solid color.$gray-300--alpha;
    flex: none;
    align-self: stretch;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 20px;
      height: 20px;
    }

    #{$block}:hover &,
    #{$block}.is-hover &,
    #{$block}.is-draggableMirror & {
      border-color: color.$key;
      background-color: color.$key;
      color: #fff;
    }
  }
}
</style>
