<template>
  <PmOnClickOutsidePure v-if="mode === 'popover'" :do="maybeClose">
    <PmPopoverWithTransitionPure
      class="PmResourceDayContainerPure-popover"
      is-positioned-on="element"
      :is-loading="isLoading"
      :loader-position="LOADER_POSITION.INLINE"
      :element="popoverElement"
      :is-close-button-visible="canBeClosed"
      tabindex="-1"
      @keydown.esc="maybeClose"
      @close="maybeClose"
    >
      <slot />
    </PmPopoverWithTransitionPure>
  </PmOnClickOutsidePure>

  <portal v-else-if="mode === 'floatingBar'" to="context">
    <div class="PmResourceDayContainerPure-floatingBar">
      <PmFloatingBarPure
        :close-button-visible="canBeClosed"
        :is-loading="isLoading"
        @close="$emit('close')"
      >
        <slot />
      </PmFloatingBarPure>
    </div>
  </portal>

  <div
    v-else-if="mode === 'none'"
    class="PmResourceDayContainerPure PmResourceDayContainerPure--withPadding"
  >
    <PmButtonPure
      v-if="canBeClosed"
      class="PmResourceDayContainerPure-close"
      :icon="ICONS.CLOSE"
      alternative="ghost"
      @click="$emit('close')"
    />

    <slot />
  </div>
</template>

<script lang="ts">
/**
 * @todo This does not work with transitions since upgrade to vue 3
 */
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

import { ICONS } from '@/constants/icons'

import PmPopoverWithTransitionPure from '@/components/basics/PmPopover/PmPopoverWithTransitionPure.vue'
import { LOADER_POSITION } from '@/components/basics/PmPopover/PmPopoverPure.vue'
import PmOnClickOutsidePure from '@/components/utilities/PmOnClickOutsidePure.vue'
import PmFloatingBarPure from '@/components/basics/PmFloatingBarPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

export const propTypes = {
  mode: {
    allowed: ['popover', 'floatingBar', 'none'] as const,
  },
}

export interface Props {
  mode: (typeof propTypes.mode.allowed)[number]
}

export default defineComponent({
  name: 'PmResourceDayContainerPure',
  components: {
    PmPopoverWithTransitionPure,
    PmOnClickOutsidePure,
    PmFloatingBarPure,
    PmButtonPure,
  },

  props: {
    isLoading: { type: Boolean, default: undefined },
    popoverElement: { default: undefined }, // eslint-disable-line vue/require-prop-types
    mode: { type: String as PropType<Props['mode']>, required: true },
    canBeClosed: { type: Boolean, default: true },
  },

  emits: ['close'],

  setup() {
    return {
      ICONS,
      LOADER_POSITION,
    }
  },

  mounted() {
    if (this.mode === 'popover') {
      this.$el.focus({
        preventScroll: true,
      })
    }
  },

  methods: {
    maybeClose() {
      if (!this.canBeClosed) return
      this.$emit('close')
    },
  },
})
</script>

<style lang="scss">
.PmResourceDayContainerPure {
  $block: &;

  position: relative;
  z-index: 0;

  &--withPadding {
    padding: 16px;
  }

  &-close {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
  }
}
</style>
