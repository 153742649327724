<template>
  <div class="PmDataModalProjectSettingsPure" :class="classes">
    <PmLoadingPure v-if="loading" size="default" />

    <div v-if="!loading" class="PmDataModalProjectSettingsPure-content">
      <PmSectionWrapperPure class="PmDataModalProjectSettingsPure-settings">
        <PmSectionPure title="Ansicht">
          <PmCheckboxPure
            :value="value.collectAllJobsOfProject"
            note="Diese Einstellung gilt für alle Benutzer"
            @input="(value) => onInput('collectAllJobsOfProject', value)"
          >
            Alle Jobs dieses Projektes gesammelt anzeigen
          </PmCheckboxPure>
        </PmSectionPure>
      </PmSectionWrapperPure>

      <div class="PmDataModalProjectSettingsPure-control">
        <PmErrorNotificationPure
          v-if="xstate.state.value.matches('failed')"
          class="PmDataModalProjectSettingsPure-notification"
          :message="errorMessage || xstate.meta.value.errorMessage"
          :details="errorDetails"
        />

        <PmButtonPure
          label="Speichern"
          variant="primary"
          :loading="xstate.state.value.matches('saving')"
          @click="$emit('save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRef } from 'vue'
import { cloneDeep } from 'lodash-es'

import { useXState } from '@/composition/useXState'
import propValidator from '@/functional/propValidator'
import {
  PmDataModalProjectSettingsPureState,
  states,
} from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectSettingsPureState'

import PmSectionWrapperPure from '@/components/basics/PmSectionWrapperPure.vue'
import PmSectionPure from '@/components/basics/PmSectionPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmLoadingPure from '@/components/basics/PmLoadingPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmDataModalProjectSettingsPure',
  components: {
    PmSectionWrapperPure,
    PmSectionPure,
    PmCheckboxPure,
    PmErrorNotificationPure,
    PmButtonPure,
    PmLoadingPure,
  },

  props: {
    errorMessage: { type: String, default: undefined },
    errorDetails: { type: Array, default: undefined },
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    value: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: undefined },
    disabled: { type: Boolean, default: undefined },
  },

  emits: ['save', 'input', 'update:value'],

  setup(props) {
    const xstate = useXState(PmDataModalProjectSettingsPureState, {
      syncStateWith: toRef(props, 'state'),
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      checked: false,
    }
  },

  computed: {
    classes() {
      return {
        'is-disabled': this.disabled,
      }
    },
  },

  created() {
    this.settings = { ...this.settings, ...this.initialSettings }
  },

  methods: {
    onInput(key, value) {
      const newValue = cloneDeep(this.value)
      newValue[key] = value

      this.$emit('input', newValue)
      this.$emit('update:value', newValue)
    },
  },
})
</script>

<style lang="scss">
.PmDataModalProjectSettingsPure {
  $block: &;

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-control {
    margin-top: 32px;
  }

  &-notification {
    margin-bottom: var(--space-gutters);
  }
}
</style>
