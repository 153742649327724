<template>
  <button
    class="PmCalendarWidgetDayPure"
    :class="classes"
    :disabled="isButtonDisabled"
    @click="$emit('click')"
  >
    <div class="PmCalendarWidgetDayPure-label">
      {{ label }}
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

import { formatWithLocale } from '@/utilities/date'

export interface Props {
  date: Date
  isDisabled?: boolean
  isToday?: boolean
  isSelected?: boolean
  isOUtsideOfRange?: boolean
}

const COMPONENT_NAME = 'PmCalendarWidgetDayPure'
export default defineComponent({
  name: COMPONENT_NAME,
  components: {},

  props: {
    date: { type: Date, required: true },
    isCurrentMonth: { type: Boolean, default: false },
    isToday: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    isOutsideOfRange: { type: Boolean, default: false },
  },

  emits: ['click'],

  setup(props) {
    const label = computed(() => {
      return formatWithLocale(props.date, 'dd')
    })

    const isButtonDisabled = computed(() => {
      if (props.isOutsideOfRange) return true
      if (props.isSelected) return true

      return false
    })

    const classes = computed(() => {
      return {
        'is-currentMonth': props.isCurrentMonth,
        'is-today': props.isToday,
        'is-selected': props.isSelected,
        'is-outsideOfRange': props.isOutsideOfRange,
      }
    })

    return {
      label,
      classes,
      isButtonDisabled,
    }
  },
})
</script>

<style lang="scss">
.PmCalendarWidgetDayPure {
  $block: &;

  @include cssVar.define($block, 'colorBackground', inherit);
  @include cssVar.define($block, 'colorBackground--hover', color.$gray-200);
  @include cssVar.define($block, 'colorLabel', inherit);

  &.is-selected {
    @include cssVar.define($block, 'colorBackground', color.$primary-400);
    @include cssVar.define(
      $block,
      'colorBackground--hover',
      color.$primary-500
    );
  }

  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 4px;
    border-radius: 999px;
    z-index: -1;
    background-color: cssVar.use($block, 'colorBackground');
  }

  &:hover {
    &::before {
      background-color: cssVar.use($block, 'colorBackground--hover');
    }
  }

  &:not(.is-currentMonth) {
    opacity: 0.5;
  }

  &.is-outsideOfRange {
    pointer-events: none;
    background-color: color.$gray-200;
  }

  &-label {
    position: relative;
    z-index: 0;
    color: cssVar.use($block, 'colorLabel');
    text-align: center;

    #{$block}.is-selected & {
      font-weight: 600;
    }

    #{$block}.is-outsideOfRange & {
      opacity: 0.5;
    }

    // Dot
    // &::before {
    //   content: '';
    //   width: 4px;
    //   height: 4px;
    //   border-radius: 4px;
    //   position: absolute;
    //   top: calc(100% + 2px);
    //   left: calc(50% - 2px);
    // }

    // #{$block}.is-today &::before {
    //   background-color: fuchsia;
    // }
  }
}
</style>
