<template>
  <PmInputPure
    type="datetime-local"
    :value="valueNormalized"
    :min="minNormalized"
    :max="maxNormalized"
    :note="note"
    :has-reset="hasReset"
    @input="onInput"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { format, isValid, parseISO } from 'date-fns'
import type { ComponentProps } from 'vue-component-type-helpers'

import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
type PropsInputPure = ComponentProps<typeof PmInputPure>

import type { Nilable } from '@/types/misc'

export interface Props {
  value?: Date
  min?: Date
  max?: Date

  // Props of PmInputPure
  note?: PropsInputPure['note']
  hasReset?: PropsInputPure['hasReset']
}

const FORMAT_DATETIME = `yyyy-MM-dd'T'HH:mm`

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'input', date: Date | null): void
  (event: 'update:value', date: Date | null): void
}>()

const valueNormalized = computed(() => {
  if (!props.value) return undefined
  return formatDateToString(props.value)
})

const minNormalized = computed(() => {
  if (!props.min) return undefined
  return formatDateToString(props.min)
})

const maxNormalized = computed(() => {
  if (!props.max) return undefined
  return formatDateToString(props.max)
})

function formatDateToString(date: Date) {
  if (!date) return
  if (!isValid(date)) return

  const timestamp = date.valueOf()
  return format(timestamp, FORMAT_DATETIME)
}

function onInput(dateString: Nilable<string | number | Date>) {
  if (typeof dateString !== 'string') {
    throw new Error('dateString needs to be a string')
  }

  if (!dateString) {
    return emitInput(null)
  }

  const date = parseISO(dateString)
  emitInput(date)
}

function emitInput(date: Date | null) {
  emit('input', date)
  emit('update:value', date)
}
</script>
