<template>
  <PmAppointmentPure
    v-if="appointment && startDate && endDate"
    :id="id"
    :title="title"
    :start-date="startDate"
    :end-date="endDate"
    :container-start-date="containerStartDate"
    :container-end-date="containerEndDate"
    :container-start-date-unconstrained="containerStartDateUnconstrained"
    :container-end-date-unconstrained="containerEndDateUnconstrained"
    :is-loading="!appointment"
    :number="number"
    :status="status"
    :note="note"
    :note-on-job="noteOnJob"
    :can-edit-resource-request="can('edit', 'resourceRequest')"
    @show-details="showDetails"
    @show-details-for-number="showJobDetails"
    @open-note="showDetails"
    @enter-viewport="isInViewport = true"
    @leave-viewport="isInViewport = false"
    @request-considered="isRequestAllOfAppointmentVisible = true"
  >
    <template #default="slotProps">
      <PmResource
        v-for="resource in normalizedResources"
        :id="resource.id"
        :key="resource.id"
        :edit-buttons-visible="persoplanState.state.value.matches('edit')"
        :container-start-date="containerStartDate"
        :is-in-viewport="slotProps.isInViewport"
      />

      <PmRequestAllOfAppointment
        v-if="isRequestAllOfAppointmentVisible === true"
        :appointment-id="id"
        @close="isRequestAllOfAppointmentVisible = false"
      />
    </template>
  </PmAppointmentPure>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { orderBy } from 'lodash-es'
import { useStore } from 'vuex'
import { useAppAbility } from '@/composition/useAppAbility'

import { EVENT } from '@/constants/events'
import { DATA_MODAL_TYPE } from '@/constants/persoplan'

import EventHub from '@/eventHub'
import { parseServerDateString } from '@/utilities/date'
import { statusLookupJob, getShortJobNumber } from '@/utilities/persoplan'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import { useCachedQuery } from '@/composition/useCachedQuery'

import PmAppointmentPure, {
  type Props as PropsAppointmentPure,
} from '@/components/persoplan/PmAppointment/PmAppointmentPure.vue'
import PmResource from '@/components/persoplan/PmResource/PmResource.vue'
import PmRequestAllOfAppointment from '@/components/persoplan/PmRequestAllOfAppointment/PmRequestAllOfAppointment.vue'

import {
  AppointmentDocument,
  type AppointmentQuery,
} from '@/../generated/graphql'
import type { Get } from 'type-fest'

export interface Props {
  id: number
  withJobInformation?: boolean
  containerStartDate: Date
  containerEndDate?: Date
  containerStartDateUnconstrained?: PropsAppointmentPure['containerStartDateUnconstrained']
  containerEndDateUnconstrained?: PropsAppointmentPure['containerEndDateUnconstrained']
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const store = useStore()
const persoplanState = injectStrict(persoplanStateKey)
const { can } = useAppAbility()
const isInViewport = ref(false)
const isRequestAllOfAppointmentVisible = ref(false)

const appointmentQuery = useCachedQuery(AppointmentDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']

  return {
    id: props.id,
    startDate: queryVariables.startDate,
    endDate: queryVariables.endDate,
    resourceTypeIds: queryVariables.resourceTypeIds,
    excludeResourceTypeIds: queryVariables.excludeResourceTypeIds,
    resourceFunctionIds: queryVariables.resourceFunctionIds,
    excludeResourceFunctionIds: queryVariables.excludeResourceFunctionIds,
  }
})

const appointment = computed(() => {
  return appointmentQuery.result.value?.appointment
})

type NormalizedResource = Get<AppointmentQuery, 'appointment.resources[0]'> & {
  startDate: Date | undefined
}

const normalizedResources = computed(() => {
  if (!appointment.value?.resources) return []

  const normalizedResources: NormalizedResource[] = []

  // Parse dates for sorting
  appointment.value.resources.forEach((resource) => {
    if (!resource) return

    normalizedResources.push({
      ...resource,
      startDate: parseServerDateString(resource.startDate),
    })
  })

  // Sort resources by resourceFunction.sortOrder and start date
  const sortedResources = orderBy(
    normalizedResources,
    ['resourceFunction.sortOrder', 'startDate'],
    ['asc', 'asc']
  )

  return sortedResources
})

const startDate = computed(() => {
  return parseServerDateString(appointment.value?.startDate)
})

const endDate = computed(() => {
  return parseServerDateString(appointment.value?.endDate)
})

const title = computed(() => {
  return appointment.value?.caption
})

const resources = computed(() => {
  return appointment.value?.resources
})

const number = computed(() => {
  if (!props.withJobInformation) return
  if (!appointment.value) return

  return getShortJobNumber(
    appointment.value?.job?.project?.number,
    appointment.value?.job?.number
  )
})

const status = computed(() => {
  if (!props.withJobInformation) return
  if (!appointment.value) return

  return statusLookupJob({
    jobStateId: appointment.value?.job?.jobState?.id,
    jobTypeId: appointment.value?.job?.jobState?.jobType?.id,
  })
})

const note = computed(() => {
  if (!appointment.value) return
  return appointment.value?.comment
})

const noteOnJob = computed(() => {
  if (props.withJobInformation) {
    return appointment.value?.job?.comment
  }

  return undefined
})

function showDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.APPOINTMENT,
    id: props.id,
  })
}

function showJobDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.JOB,
    id: appointment.value?.job?.id,
  })
}
</script>
