<template>
  <component
    :is="link.tag.value"
    v-bind="link.props.value"
    ref="elRoot"
    class="PmMainNavigationItemPure"
    :class="classes"
    @click="$emit('click')"
  >
    <div class="PmMainNavigationItemPure-iconContainer">
      <PmIconPure
        v-if="icon"
        :key="icon"
        class="PmMainNavigationItemPure-icon"
        :name="icon"
      />
    </div>

    <div v-if="label" class="PmMainNavigationItemPure-label">
      {{ label }}
    </div>

    <div
      v-if="isDropdown && dropdownIcon"
      class="PmMainNavigationItemPure-dropdownIconContainer"
    >
      <PmIconPure
        :key="dropdownIcon"
        class="PmMainNavigationItemPure-icon"
        :name="dropdownIcon"
      />
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMainNavigationItemPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import { useLink, type Props as PropsUseLink } from '@/composition/useLink'

import type { Icon } from '@/constants/icons'

export interface Props {
  id?: string
  label?: string
  icon: Icon
  hideLabelOnMobile?: boolean
  isActive?: boolean
  hasNotification?: boolean
  // useLink Props
  target?: PropsUseLink['target']
  href?: PropsUseLink['href']
  to?: PropsUseLink['to']
  linkOpenInNewWindow?: PropsUseLink['linkOpenInNewWindow']
  isDropdown?: boolean
  isDropdownOpen?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'click'): void
}>()

const link = useLink(props)
const elRoot = ref<HTMLElement>()

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--iconOnly`]: props.icon && !props.label,
    [`${COMPONENT_NAME}--hideLabelOnMobile`]: props.hideLabelOnMobile,
    'is-active': props.isActive,
    'has-notification': props.hasNotification,
  }
})

const dropdownIcon = computed<Icon | undefined>(() => {
  if (props.isDropdownOpen !== undefined) {
    return props.isDropdownOpen ? 'arrowUp' : 'arrowDown'
  }

  return undefined
})

defineExpose({
  elRoot,
})
</script>

<style lang="scss">
@use 'sass:math';

.PmMainNavigationItemPure {
  $block: &;

  @include mixin.transition-hover(color);
  @include cssVar.define($block, 'colorText', color.$gray-300);
  @include cssVar.define($block, 'colorText-hover', color.$white);
  @include cssVar.define($block, 'colorIcon', inherit);
  @include cssVar.define($block, 'colorIcon-hover', color.$key);

  display: flex;
  align-items: center;
  color: cssVar.use($block, 'colorText');
  padding: 12px;
  padding-bottom: 8px - 4px;
  margin-bottom: 4px + 4px;
  gap: 8px;

  @include mixin.media('<=mobile') {
    padding-left: 8px;
    padding-right: 8px;
  }

  &:hover,
  &.is-hover {
    color: cssVar.use($block, 'colorText-hover');
  }

  &.is-active {
    color: cssVar.use($block, 'colorText-hover');
  }

  &-iconContainer {
    width: 20px;
    height: 20px;
    position: relative;

    &::before {
      $size: 8px;

      content: '';
      background-color: color.$danger-500;
      width: $size;
      height: $size;
      border-radius: $size;
      position: absolute;
      top: -1px;
      right: -1px;
      z-index: 1;

      #{$block}:not(.has-notification) & {
        display: none;
      }
    }
  }

  &-dropdownIconContainer {
    width: 20px;
    height: 20px;
    position: relative;
    margin-left: -4px;
  }

  &-icon {
    @include mixin.transition-hover(color, $block);

    color: cssVar.use($block, 'colorIcon');

    #{$block}:hover &,
    .is-hover & {
      color: cssVar.use($block, 'colorIcon-hover');
    }

    #{$block}.is-active & {
      color: cssVar.use($block, 'colorIcon-hover');
    }
  }

  &-label {
    font-weight: 600;

    #{$block}--hideLabelOnMobile & {
      @include mixin.media('<=mobile') {
        display: none;
      }
    }
  }
}
</style>
