<template>
  <PmFileDropzonePure
    v-if="isDropZoneVisible"
    :multiple="isMultipleFilesAllowed"
    :rejected-because="rejectedBecause"
    :is-dragged-over="isDraggedOver"
    :number-of-dragged-files="numberOfDraggedFiles"
    @dragenter="isDraggedOver = true"
    @dragleave="isDraggedOver = false"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useEventListener, useDropZone, useCurrentElement } from '@vueuse/core'

import PmFileDropzonePure, {
  type Props as PropsFileDropzonePure,
} from '@/components/basics/PmFileDropzone/PmFileDropzonePure.vue'
import type { PropsFileInput } from '@/components/basics/PmInputFile/PmInputFilePure.vue'
import { checkIfAllowedFileType } from '@/utilities/file'

export interface Props extends PropsFileInput {}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  drop: [files: File[]]
  show: []
  hide: []
}>()

const el = useCurrentElement<HTMLElement>()
const isDropZoneVisible = ref(false)
const numberOfDraggedFiles = ref<number>()
const isDraggedOver = ref(false)

const isMultipleFilesAllowed = computed(() => {
  return props.maxNumberOfFiles !== 1
})

const rejectedBecause = ref<PropsFileDropzonePure['rejectedBecause']>()
let counterToDetectCancel = 0
useDropZone(el, { onDrop })

// Prevent accidental file drops opening the file in the browser
useEventListener('dragover', (event) => {
  event.preventDefault()
})

/**
 * Show dropzone when file is dragged over window using a counter
 * @see https://stackoverflow.com/questions/44974087/how-can-i-handle-file-drag-and-drop-with-a-window-overlay-and-multiple-drop-zone
 * Info about dragged over files and types seems to be present in Firefox only
 * @see https://stackoverflow.com/questions/11341277/detecting-a-cancelled-drag-and-drop-action-when-uploading-files
 */
useEventListener('dragenter', (event) => {
  if (!event.dataTransfer) return

  // Check if it's actually a file
  const numberOfItems = event.dataTransfer.items.length
  numberOfDraggedFiles.value = numberOfItems
  if (numberOfItems === 0) return

  const isFiles = event.dataTransfer.types.includes('Files')
  if (!isFiles) return

  rejectedBecause.value = getRejectionReason({
    numberOfFiles: numberOfItems,
    mimeTypes: Object.values(event.dataTransfer.items).map((item) => item.type),
  })

  if (counterToDetectCancel === 0) show()
  counterToDetectCancel += 1
})

/**
 *  Checks if files are accepted and returns a reason when they are not
 */
function getRejectionReason(options: {
  numberOfFiles: number
  mimeTypes: string[]
}): (typeof rejectedBecause)['value'] {
  // Check if user drags multiple files on a single file input
  if (isMultipleFilesAllowed.value !== true && options.numberOfFiles > 1) {
    return 'onlyOneFileAllowed'
  }

  // Check if user drags (partly) not allowed file types
  const numberOfNotAcceptedFileTypes = options.mimeTypes.reduce(
    (numberOfNotAcceptedFileTypes, mimeType) => {
      const isAllowedFileType = checkIfAllowedFileType({
        mimeType,
        allowedFileTypes: props.allowedFileTypes,
      })

      if (isAllowedFileType) return numberOfNotAcceptedFileTypes
      return (numberOfNotAcceptedFileTypes += 1)
    },
    0
  )

  if (options.numberOfFiles === numberOfNotAcceptedFileTypes) {
    return 'notAcceptedFileTypeAll'
  }

  if (numberOfNotAcceptedFileTypes) {
    return 'notAcceptedFileTypeSome'
  }
}

useEventListener(el, 'dragover', (event) => {
  if (!event.dataTransfer) return
  event.dataTransfer.dropEffect = 'copy'
})

useEventListener('dragleave', () => {
  counterToDetectCancel -= 1
  if (counterToDetectCancel === 0) hide()
})

useEventListener('drop', (event) => {
  event.preventDefault()
  hide()
})

function onDrop(files: File[] | null, event: DragEvent) {
  hide()
  if (!files) return
  if (!event.dataTransfer) return

  // Check if it's actually a file
  const numberOfItems = event.dataTransfer.items.length
  if (numberOfItems === 0) return

  const isFiles = event.dataTransfer.types.includes('Files')
  if (!isFiles) return

  const isAccepted = !getRejectionReason({
    numberOfFiles: numberOfItems,
    mimeTypes: Object.values(event.dataTransfer.items).map((item) => item.type),
  })

  if (!isAccepted) return
  emit('drop', files)
}

function show() {
  /**
   * This methods gets called twice, probably because the PmFileDropZonePure gets mounted
   * To avoid side-effects we skip the second run
   */
  if (isDropZoneVisible.value === true) return

  isDropZoneVisible.value = true
  emit('show')
}

function hide() {
  counterToDetectCancel = 0
  isDropZoneVisible.value = false
  emit('hide')
}
</script>
