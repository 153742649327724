<template>
  <div class="PmSettingsPure">
    <div class="PmSettingsPure-rowCalendar">
      <PmContextNavigationPure
        :items="[
          {
            id: 'createResourceStateFreelancer',
            icon: ICONS.PLUS,
            label: 'Resourcen-Status für externe Dienstleister erstellen',
          },
        ]"
        @create-resource-state-freelancer="
          emit('createResourceStateFreelancer')
        "
      />

      <PmButtonPure
        :icon="ICONS.CALENDAR"
        title="Zeitraum ändern (ALT+D)"
        :label="dateLabel"
        :expanded="true"
        @click="emit('openDateController')"
      />

      <PmButtonGroupPure>
        <PmButtonPure
          :icon="ICONS.ARROW_LEFT"
          title="Vorherige Woche (ALT+V)"
          :in-group="true"
          @click="emit('showPreviousWeek')"
        />

        <PmButtonPure
          :icon="ICONS.ARROW_RIGHT"
          title="Nächste Woche (ALT+N)'"
          :in-group="true"
          @click="emit('showNextWeek')"
        />
      </PmButtonGroupPure>
    </div>

    <div class="PmSettingsPure-rowViews">
      <PmButtonPure
        :icon="ICONS.STAFF_REQUEST"
        title="Personalanfrage (ALT+P)"
        :active="staffRequestModeActive"
        :disabled="staffRequestDisabled"
        @click="emit('toggleEditMode')"
      />

      <PmButtonPure
        :icon="ICONS.SEARCH"
        icon-type="fontawesome"
        title="Suche (ALT+S)"
        @click="emit('openSearch')"
      />

      <PmDropdownPure
        :options="views"
        placeholder="Ansicht auswählen"
        :value="selectedViewId"
        :has-reset="true"
        @input="selectView"
      />

      <PmButtonWithActionsPure
        :icon="ICONS.ADJUSTMENTS"
        title="Einstellungen (ALT+E)"
        :notification="isViewChanged"
        :actions="viewContextNavigationItems"
        @click="emit('openViewEditor')"
        @create="emit('createView')"
        @save="emit('saveView')"
        @duplicate="emit('duplicateView')"
        @edit="emit('openViewEditor')"
        @reset="onReset"
        @reset-to-default="emit('resetView')"
        @delete="emit('deleteView')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSettingsPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ICONS } from '@/constants/icons'

import { formatWithLocale } from '@/utilities/date'

// import Datepicker from '@/components/basics/Datepicker.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
// import Dropdown from '@/components/basics/Dropdown.vue'
import PmButtonGroupPure from '@/components/basics/PmButtonGroupPure.vue'
import PmDropdownPure, {
  type Emit as EmitDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import PmButtonWithActionsPure, {
  type Props as PropsButtonWitActionsPure,
} from '@/components/basics/PmButtonWithActionsPure.vue'
import PmContextNavigationPure from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'

import type { Get } from 'type-fest'

export interface Props {
  startDate?: Date
  endDate?: Date
  staffRequestModeActive?: boolean
  views?: any[]
  selectedViewId?: number
  staffRequestDisabled?: boolean
  userCanCreatePublicView: boolean
  isPublicView?: boolean
  userNameOfViewOwner?: string
  isViewChanged: boolean
  isDefaultView: boolean
  isSavedView: boolean
}

const props = withDefaults(defineProps<Props>(), {
  views: () => [],
})

const emit = defineEmits<{
  (event: 'createResourceStateFreelancer'): void
  (event: 'openDateController'): void
  (event: 'showPreviousWeek'): void
  (event: 'showNextWeek'): void
  (event: 'toggleEditMode'): void
  (event: 'openSearch'): void
  (event: 'selectView', id: number): void
  (event: 'openViewEditor'): void
  (event: 'createView'): void
  (event: 'saveView'): void
  (event: 'duplicateView'): void
  (event: 'resetView'): void
  (event: 'deleteView'): void
  (event: 'revertView'): void
}>()

const dateLabel = computed(() => {
  if (!props.startDate || !props.endDate) return

  return `${formatWithLocale(props.startDate)} — ${formatWithLocale(
    props.endDate
  )}`
})

const viewContextNavigationItems = computed(() => {
  type Action = Get<PropsButtonWitActionsPure, 'actions.0'>

  const itemCreate: Action = {
    id: 'create',
    icon: ICONS.PLUS,
    label: 'Neue Ansicht erstellen',
  }

  const itemSave: Action = {
    id: 'save',
    icon: ICONS.SAVE,
    label: 'Ansicht speichern',
    disabled: !isSaveViewEnabled.value,
  }

  const itemReset: Action = {
    id: 'reset',
    icon: ICONS.RESET,
    label: 'Ansicht zurücksetzen',
    note: 'Die ausgewählte Ansicht wird auf den zuletzt gespeicherten Stand zurückgesetzt',
    disabled: props.isViewChanged === false,
  }

  const itemResetToDefaultView: Action = {
    id: 'resetToDefault',
    icon: ICONS.RESET,
    label: 'Auf Standard-Ansicht zurücksetzen',
  }

  const itemDelete: Action = {
    id: 'delete',
    icon: ICONS.DELETE,
    label: 'Ansicht löschen',
    variant: 'danger',
    disabled: !isDeleteViewEnabled.value,
  }

  const actions: PropsButtonWitActionsPure['actions'] = [
    itemSave,
    itemCreate,
    itemReset,
    itemResetToDefaultView,
    itemDelete,
  ]

  return actions
})

const isSaveViewEnabled = computed(() => {
  if (props.isPublicView && !props.userCanCreatePublicView) return false
  if (!props.isSavedView) return false

  return true
})

const isDeleteViewEnabled = computed(() => {
  if (props.isPublicView && !props.userCanCreatePublicView) return false
  if (!props.isSavedView) return false

  return true
})

function onReset() {
  if (props.isSavedView) {
    emit('revertView')
  } else {
    emit('resetView')
  }
}

function selectView(viewId: EmitDropdownPure['input']) {
  if (viewId === undefined) {
    return emit('resetView')
  }

  if (typeof viewId !== 'number') throw new Error('viewId is not a number')
  emit('selectView', viewId)
}
</script>

<style lang="scss">
.PmSettingsPure {
  $block: &;

  padding: 10px;
  display: grid;
  grid-auto-rows: 32px;
  grid-gap: 4px;

  &-rowCalendar {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 32px 1fr auto;
    align-items: center;
  }

  &-date {
    width: 100%;
  }

  &-rowViews {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 32px 32px 1fr auto;
  }
}
</style>
