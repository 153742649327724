<template>
  <div ref="elRoot" class="PmSidebarJobPure" :class="classes.root">
    <div class="PmSidebarJobPure-inner">
      <div class="PmSidebarJobPure-numberAndAlerts">
        <PmStatusPillPure
          class="PmSidebarJobPure-pill"
          :title="numberShortened"
          :label="numberShortened"
          type="job"
          :job-status="status"
        />

        <PmPillPure
          v-if="alertsVisible && hasAlerts"
          :title="`${numberOfAlerts} fehlende Ressourcen`"
          variant="danger"
          class="PmSidebarJobPure-alerts"
          :label="numberOfAlerts"
        />
      </div>

      <div
        class="PmSidebarJobPure-title"
        :title="title"
        @click.stop="emit('showDetails')"
      >
        {{ title }}
      </div>

      <div class="PmSidebarJobPure-meta">
        <PmNoteIconPure v-if="note" :note="note" @open="emit('openNote')" />
      </div>

      <div class="PmSidebarJobPure-actions">
        <button
          v-if="!isCollectedWithAllJobsOfProject"
          class="PmSidebarJobPure-action"
          title="Im Kalender anzeigen"
          @click.stop="emit('jump')"
        >
          <PmIconPure :name="ICONS_SMALL.JUMP" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount } from 'vue'
import cssVar from '@/utilities/cssVar'

const COMPONENT_NAME = 'PmSidebarJobPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { EVENT } from '@/constants/events'
import {
  CALENDAR_ITEM_TYPE,
  type CalendarItemType,
  type JobStatus,
} from '@/constants/persoplan'
import { ICONS_SMALL } from '@/constants/icons'

import { getScrollParent } from '@/functional/scrollParent'
import EventHub from '@/eventHub'

import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmNoteIconPure from '@/components/PmNote/PmNoteIconPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'

export interface Props {
  id: number
  number?: string
  title: string
  numberOfAlerts?: number
  alertsVisible?: boolean
  isHighlighted?: boolean
  isCollectedWithAllJobsOfProject?: boolean
  note?: string
  status?: JobStatus
}

const props = withDefaults(defineProps<Props>(), {
  alertsVisible: true,
})

const emit = defineEmits<{
  (event: 'showDetails'): void
  (event: 'openNote'): void
  (event: 'jump'): void
}>()

const classes = computed(() => {
  return {
    root: {
      'is-highlighted': props.isHighlighted,
      'is-alertsVisible': props.alertsVisible,
    },
  }
})

const numberShortened = computed(() => {
  if (!props.number) return

  const parts = props.number.split('.')
  if (!parts.length) return props.number

  return `.${parts[parts.length - 1]}`
})

const hasAlerts = computed(() => {
  if (!props.numberOfAlerts) return
  return props.numberOfAlerts > 0
})

onMounted(() => {
  EventHub.$on(EVENT.SIDEBAR_JOB_SCROLL_INTO_VIEW, maybeScrollIntoView)
})

onBeforeUnmount(() => {
  EventHub.$off(EVENT.SIDEBAR_JOB_SCROLL_INTO_VIEW, maybeScrollIntoView)
})

/**
 * @todo use jumpTargets composable
 */
function maybeScrollIntoView({
  id,
  type,
}: {
  id: number
  type: CalendarItemType
}) {
  if (id !== props.id) return
  if (type !== CALENDAR_ITEM_TYPE.JOB) return

  scrollIntoView()
}

const elRoot = ref<HTMLElement>()

function scrollIntoView() {
  if (!elRoot.value) return

  const elScrollParent = getScrollParent(elRoot.value)
  const rectScrollParent = elScrollParent.getBoundingClientRect()
  const rectEl = elRoot.value.getBoundingClientRect()

  const highlightOffset = cssVar.read(
    `${COMPONENT_NAME}-highlightOffset`,
    'number',
    {
      el: elRoot.value,
    }
  )

  const relativeScrollOffset =
    rectEl.y + elScrollParent.scrollTop - rectScrollParent.y - 20

  elScrollParent.scrollTo({
    top: relativeScrollOffset - highlightOffset,
    behavior: 'smooth',
  })
}
</script>

<style lang="scss">
.PmSidebarJobPure {
  $block: &;

  @include cssVar.define($block, 'highlightOffset', 3);

  cursor: default;
  position: relative;

  &::before {
    content: '';
    left: -10px;
    right: -10px;
    top: calc(#{cssVar.use($block, 'highlightOffset')} * -1px);
    bottom: calc(#{cssVar.use($block, 'highlightOffset')} * -1px);
    position: absolute;
    background-color: color.$primary-200;
    pointer-events: none;
    opacity: 0;
    transition: opacity constant.$duration-fast linear;
  }

  &.is-highlighted {
    &::before {
      opacity: 1;
    }
  }

  &-inner {
    display: grid;
    grid-template-columns: var(--sidebarPillWidth, auto) 1fr auto auto;
    grid-template-areas: 'numberAndAlerts title meta actions';
    align-items: center;
    justify-items: start;
    position: relative;

    #{$block}.is-alertsVisible & {
      grid-template-columns: var(--sidebarPillWidthWithAlerts, auto) 1fr auto auto;
    }
  }

  &-numberAndAlerts {
    grid-area: numberAndAlerts;
    display: flex;
    gap: 4px;
  }

  &-alerts {
    &.is-noAlerts {
      display: none;
    }
  }

  &-actions {
    @include mixin.transition-hover(
      $properties: opacity,
      $rootElements: $block
    );

    grid-area: actions;
    margin-left: 4px;
    opacity: 0.1;
    display: flex;
    pointer-events: none;

    &:not(:last-child) {
      margin-right: 4px;
    }

    #{$block}:hover & {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-action {
    @include mixin.transition-hover(color);

    height: 20px;
    width: 20px;

    &:not(:first-child) {
      margin-left: 3px;
    }

    &:not(:last-child) {
      margin-right: 3px;
    }

    &:hover {
      color: color.$key;
    }
  }

  &-title {
    grid-area: title;
    font-size: constant.$fontSize-large;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    padding-right: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
