import { createMachine } from 'xstate'

import type { Typegen0 } from './PmLoginInterceptorState.typegen'

export type TState = Typegen0['matchesStates']

export const PmLoginInterceptorState = createMachine({
  tsTypes: {} as import('./PmLoginInterceptorState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'LOGIN' },

    context: {} as {
      error: string | undefined
    },
  },

  id: 'PmLoginInterceptorState',
  initial: 'default',
  context: {
    error: undefined,
  },

  states: {
    default: {
      on: {
        LOGIN: 'loggingIn',
      },
    },

    loggingIn: {
      invoke: {
        id: 'login-with-credentials',
        src: 'loginWithCredentials',

        onError: {
          target: 'failed',
          actions: (context, error) => {
            console.error(error.data)
            context.error = error.data.message
          },
        },
        onDone: 'success',
      },
    },

    success: {
      type: 'final',
      entry: 'loginSuccessful',
    },

    failed: {
      on: {
        LOGIN: 'loggingIn',
      },
    },
  },
})
