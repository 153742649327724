<script>
import {
  props as propsDateConstrain,
  default as useDateConstrain,
} from '@/composition/useDateConstrain'

export default {
  name: 'PmEventsItemPure',

  props: {
    ...propsDateConstrain,
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
  },

  setup(props) {
    const {
      startsOnRelativeDay,
      numberOfDaysConstrained,
      isConstrainedStart,
      isConstrainedEnd,
    } = useDateConstrain(props)

    return {
      startsOnRelativeDay,
      numberOfDaysConstrained,
      isConstrainedStart,
      isConstrainedEnd,
    }
  },

  computed: {
    styles() {
      let columnStart = this.startsOnRelativeDay + 1
      const columnSpan = this.numberOfDaysConstrained

      if (this.startsOnRelativeDay < 0) {
        columnStart = 1
      }

      return {
        gridColumn: `${columnStart} / span ${columnSpan}`,
      }
    },
  },

  render() {
    return this.$slots.default({
      styles: this.styles,
      isConstrainedStart: this.isConstrainedStart,
      isConstrainedEnd: this.isConstrainedEnd,
    })
  },
}
</script>
