<template>
  <div class="PmSectionPure" :class="classes">
    <div v-if="title" class="PmSectionPure-title">{{ title }}</div>

    <div class="PmSectionPure-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSectionPure'

export const propTypes = {
  variant: {
    allowed: ['default', 'compact'],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

export interface Props {
  title?: string
  disabled?: boolean
  variant: (typeof propTypes.variant.allowed)[number]
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--variantDefault`]: props.variant === 'default',
    [`${COMPONENT_NAME}--variantCompact`]: props.variant === 'compact',
    'is-disabled': props.disabled,
  }
})
</script>

<style lang="scss">
.PmSectionPure {
  $block: &;

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-title {
    #{$block}--variantDefault & {
      @include typography.h4;

      margin-bottom: 12px;
    }

    #{$block}--variantCompact & {
      @include mixin.textLabel;

      color: color.$text-default;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      &::after {
        content: '';
        flex-grow: 1;
        height: 1px;
        background-color: color.$gray-200;
      }
    }
  }
}
</style>
