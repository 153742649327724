<template>
  <div class="PmInputContainerPure" :class="classes">
    <label v-if="label" class="PmInputContainerPure-label" :for="uid">
      {{ label }}
    </label>

    <div
      v-show="errorMessages && errorMessages?.length > 0"
      class="PmInputContainerPure-error"
    >
      <PmRichTextPure>
        <template
          v-if="Array.isArray(errorMessages) && errorMessages.length > 1"
        >
          <ul>
            <li v-for="item in errorMessages" :key="item">
              {{ item }}
            </li>
          </ul>
        </template>

        <template
          v-if="Array.isArray(errorMessages) && errorMessages.length === 1"
        >
          {{ errorMessages[0] }}
        </template>
      </PmRichTextPure>
    </div>

    <div class="PmInputContainerPure-input">
      <slot />
    </div>

    <div v-if="isNoteVisible" class="PmInputContainerPure-note">
      <PmRichTextPure>
        <template v-if="Array.isArray(note) && note.length > 1">
          <ul>
            <li v-for="noteItem in note" :key="noteItem">
              {{ noteItem }}
            </li>
          </ul>
        </template>

        <template v-if="Array.isArray(note) && note.length === 1">
          {{ note[0] }}
        </template>

        <template v-if="!Array.isArray(note)">
          {{ note }}
        </template>
      </PmRichTextPure>

      <template v-if="!note && reserverSpaceForNote">&nbsp;</template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmInputContainerPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'

export interface Props {
  label?: string
  note?: string | string[]
  errorMessage?: string | string[]
  invalidMessage?: string | string[]
  uid?: string
  required?: boolean
  reserverSpaceForNote?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const classes = computed(() => {
  return {
    'is-required': props.required,
  }
})

const errorMessages = computed(() => {
  const result: string[] = []

  if (props.errorMessage) {
    Array.isArray(props.errorMessage)
      ? result.push(...props.errorMessage)
      : result.push(props.errorMessage)
  }

  if (props.invalidMessage) {
    Array.isArray(props.invalidMessage)
      ? result.push(...props.invalidMessage)
      : result.push(props.invalidMessage)
  }

  return result
})

const isNoteVisible = computed(() => {
  if (props.reserverSpaceForNote === true) return true

  if (Array.isArray(props.note)) {
    return props.note.length > 0
  }

  return props.note
})
</script>

<style lang="scss">
.PmInputContainerPure {
  $block: &;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'label'
    'error'
    'input'
    'note';

  &-label {
    @include mixin.textLabel;

    grid-area: label;
    margin-bottom: 4px;
    justify-self: start;
    position: relative;

    #{$block}.is-required & {
      &::after {
        @include mixin.requiredAsterisk;
      }
    }
  }

  &-error {
    @include mixin.textLabel;

    grid-area: error;
    color: color.$danger-500;
    opacity: 1;
    margin-bottom: 4px;
  }

  &-input {
    grid-area: input;
  }

  &-note {
    font-size: constant.$fontSize-default;
    font-weight: 400;
    color: color.$gray-700;
    grid-area: note;
    margin-top: 3px;
  }
}
</style>
