import { computed, type Ref } from 'vue'

import { STATUS_JOB, type JobStatus } from '@/constants/persoplan'
import scssVariables from '@/assets/scss/scssVariables'
import type { Nilable } from '@/types/misc'

export type { JobStatus } from '@/constants/persoplan'

interface Options {
  status: Ref<JobStatus | undefined | null>
}

function getColor(status?: Nilable<JobStatus>) {
  if (!status) return
  return scssVariables.color[`status-${status}`]
}

export function useJobStatus(options: Options) {
  const classes = computed(() => {
    if (!options.status.value) return

    return {
      'is-statusConfirmed': options.status.value === STATUS_JOB.CONFIRMED,
      'is-statusRequested': options.status.value === STATUS_JOB.REQUESTED,
      'is-statusCancelled': options.status.value === STATUS_JOB.CANCELLED,
      'is-statusConsidered': options.status.value === STATUS_JOB.CONSIDERED,
      'is-statusOffered': options.status.value === STATUS_JOB.OFFERED,
      'is-statusService': options.status.value === STATUS_JOB.SERVICE,
      'is-statusSubHire': options.status.value === STATUS_JOB.SUB_HIRE,
      'is-statusItemTransfer':
        options.status.value === STATUS_JOB.ITEM_TRANSFER,
    }
  })

  const color = computed(() => {
    if (!options.status.value) return
    return getColor(options.status.value)
  })

  return {
    id: options.status.value,
    classes,
    color,
  }
}

export { getColor as getJobColor }
