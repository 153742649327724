<template>
  <PmLoginInterceptorPure
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    @login="
      xstate.service.value.send('LOGIN', {
        username: $event.username,
        password: $event.password,
      })
    "
  />
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import { useXState } from '@/composition/useXState'

import { PmLoginInterceptorState } from '@/components/PmLoginInterceptor/PmLoginInterceptorState'

import PmLoginInterceptorPure from '@/components/PmLoginInterceptor/PmLoginInterceptorPure.vue'

export default defineComponent({
  name: 'PmLoginInterceptor',
  components: {
    PmLoginInterceptorPure,
  },

  emits: ['success'],

  setup(props, context) {
    const instance = getCurrentInstance()

    const xstate = useXState(PmLoginInterceptorState, {
      services: {
        loginWithCredentials: (context, { username, password }) =>
          instance.ctx.loginWithCredentials({ username, password }),
      },

      actions: {
        loginSuccessful: () => context.emit('success'),
      },
    })

    return {
      xstate,
    }
  },

  methods: {
    async loginWithCredentials({ username, password }) {
      await this.$store.dispatch('auth/loginWithCredentials', {
        username,
        password,
        resetApolloStore: false,
      })
    },
  },
})
</script>

<style lang="scss">
.PmLoginInterceptor {
  $block: &;
}
</style>
