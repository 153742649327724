<template>
  <portal to="modal">
    <PmModalPure
      title="Resourcen-Status für externe Dienstleister erstellen"
      :can-be-closed="false"
      @close="$emit('close')"
    >
      <PmResourceStateEditPure
        v-bind="propsResourceStateEditPure"
        :is-address-visible="true"
        :is-loading="isLoading"
        :is-loading-address="isLoadingFreelancersNormalized"
        label-address="Ressource"
        buttons-portal-name="PmResourceStateFreelancerCreate-buttons"
        v-on="listenersResourceStateEditPure"
        @select-address="(id) => (value.selectedAddressId = id)"
      />

      <template #footer>
        <portal-target name="PmResourceStateFreelancerCreate-buttons" />
      </template>
    </PmModalPure>
  </portal>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import { set } from 'date-fns'
import { isNil, uniqBy } from 'lodash-es'

import { RESOURCE_TYPE, RESOURCE_TYPE_LOOKUP } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import { formatToServerDateString } from '@/utilities/date'
import { throwFriendlyError } from '@/functional/error'
import { useXState } from '@/composition/useXState'
import { checkIfResourceStateTypeIsVisible } from '@/functional/resourceStateType'
import EventHub from '@/eventHub'

import { PmResourceStateCreateState } from '@/components/persoplan/PmResourceStateEdit/PmResourceStateCreateState'

import ResourceStateTypesQuery from '@/graphql/ResourceStateTypesQuery.graphql'
import SelectedFreelancerQuery from '@/components/persoplan/PmResourceStateEdit/SelectedFreelancerQuery.graphql'
import FreelancerSearchQuery from '@/components/persoplan/PmResourceStateEdit/FreelancerSearchQuery.graphql'
import CreateResourceStateAddressMutation from '@/components/persoplan/PmResourceStateEdit/CreateResourceStateAddressMutation.graphql'

import PmResourceStateEditPure from '@/components/persoplan/PmResourceStateEdit/PmResourceStateEditPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'

export default defineComponent({
  name: 'PmResourceStateFreelancerCreate',
  components: {
    PmResourceStateEditPure,
    PmModalPure,
  },

  emits: ['close', 'cancel'],

  setup(props, context) {
    const instance = getCurrentInstance()

    const xstate = useXState(PmResourceStateCreateState, {
      services: {
        createResourceState: () => instance.ctx.createResourceState(),
      },
      actions: {
        emitClose: () => context.emit('close'),
      },
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      resourceStateTypes: [],
      selectedFreelancer: undefined,
      freelancerSearchResults: [],
      resourceStates: undefined,
      resourceState: undefined,
      value: {
        startDateTime: undefined,
        endDateTime: undefined,
        selectedAddressId: undefined,
        selectedStateId: undefined,
        note: undefined,
      },
      searchterm: undefined,
      isLoadingRresourceStateTypes: undefined,
      isLoadingFreelancers: undefined,
      isLoadingFreelancerSearch: undefined,
    }
  },

  computed: {
    stateOptionsNormalized() {
      return this.resourceStateTypes.map((resourceStateType) => {
        return {
          id: resourceStateType.id,
          label: resourceStateType.caption,

          hidden: !checkIfResourceStateTypeIsVisible({
            applyForType: RESOURCE_TYPE.FREELANCER,
            resourceStateTypeId: resourceStateType.id,
            resourceType:
              RESOURCE_TYPE_LOOKUP[resourceStateType.resourceType.id],
          }),
        }
      })
    },

    addressOptionsNormalized() {
      const items = [...this.freelancerSearchResults]

      if (this.selectedFreelancer) {
        items.push(this.selectedFreelancer)
      }

      const mappedItems = items.map((item) => {
        return {
          id: item.id,
          label: item.displayName,
        }
      })

      // Remove any double values
      return uniqBy(mappedItems, 'id')
    },

    isLoading() {
      return this.isLoadingRresourceStateTypes
    },

    isLoadingFreelancersNormalized() {
      if (this.isLoadingFreelancerSearch) return true
      if (this.isLoadingFreelancers) return true

      return false
    },

    propsResourceStateEditPure() {
      return {
        ...this.value,
        stateOptions: this.stateOptionsNormalized,
        addressOptions: this.addressOptionsNormalized,
        state: this.xstate.path.value,
        mode: 'create',
        errorMessage: this.xstate.state.value.context.error,
        errorDetails: this.xstate.state.value.context.errorDetails,
      }
    },

    listenersResourceStateEditPure() {
      return {
        updateSearchtermAddress: (value) => (this.searchterm = value),
        inputStartDateTime: (date) => (this.value.startDateTime = date),
        inputEndDateTime: (date) => (this.value.endDateTime = date),
        selectState: (id) => (this.value.selectedStateId = id),
        inputNote: (note) => (this.value.note = note),
        cancel: () => this.$emit('cancel'),
        save: () => this.xstate.service.value.send('SAVE'),
      }
    },
  },

  created() {
    this.value = {
      startDateTime: set(this.$store.state.persoplan.visibleStartDate, {
        hours: 9,
        minutes: 0,
        seconds: 0,
      }),

      endDateTime: set(this.$store.state.persoplan.visibleStartDate, {
        hours: 18,
        minutes: 0,
        seconds: 0,
      }),

      selectedAddressId: undefined,
      selectedStateId: undefined,
      note: undefined,
    }
  },

  methods: {
    async createResourceState() {
      try {
        await this.$apollo.mutate({
          mutation: CreateResourceStateAddressMutation,

          variables: {
            addressId: this.value.selectedAddressId,
            resourceStateTypeId: this.value.selectedStateId,
            startDate: formatToServerDateString(this.value.startDateTime),
            endDate: formatToServerDateString(this.value.endDateTime),
            note: this.value.note,
          },
        })

        EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)
      } catch (error) {
        throwFriendlyError(error, { operation: 'create' })
      }
    },
  },

  apollo: {
    resourceStateTypes: {
      query: ResourceStateTypesQuery,

      watchLoading(isLoading) {
        this.isLoadingRresourceStateTypes = isLoading
      },
    },

    freelancerSearchResults: {
      query: FreelancerSearchQuery,
      debounce: 250,

      watchLoading(isLoading) {
        this.isLoadingFreelancerSearch = isLoading
      },

      skip() {
        return isNil(this.searchterm)
      },

      variables() {
        return {
          searchterm: this.searchterm,
        }
      },
    },

    selectedFreelancer: {
      query: SelectedFreelancerQuery,

      watchLoading(isLoading) {
        this.isLoadingFreelancers = isLoading
      },

      skip() {
        return isNil(this.value.selectedAddressId)
      },

      variables() {
        return {
          id: this.value.selectedAddressId,
        }
      },

      update(data) {
        if (data.selectedFreelancer.length === 0) {
          return
        }

        return data.selectedFreelancer[0]
      },
    },
  },
})
</script>
