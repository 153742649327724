import {
  type NotificationBase,
  type NotificationFromQuery,
} from '@/store/notifications/notifications'

import { type Props as PropsNotificationExternalServiceRequestCreated } from '@/components/PmNotification/PmNotificationExternalServiceRequestCreated/PmNotificationExternalServiceRequestCreated.vue'
import { type Props as PropsNotificationExternalServiceRequestUpdated } from '@/components/PmNotification/PmNotificationExternalServiceRequestUpdated/PmNotificationExternalServiceRequestUpdated.vue'
import { type Props as PropsNotificationExternalServiceRequestAccepted } from '@/components/PmNotification/PmNotificationExternalServiceRequestAccepted/PmNotificationExternalServiceRequestAccepted.vue'
import { type Props as PropsNotificationExternalServiceRequestDeclined } from '@/components/PmNotification/PmNotificationExternalServiceRequestDeclined/PmNotificationExternalServiceRequestDeclined.vue'

import { parseServerDateString } from '@/utilities/date'
import { getDisplayNameOfAddress } from '@/utilities/string'

export interface NotificationExternalServiceRequestCreated {
  type: 'NotificationExternalServiceRequestCreated'
  props: Omit<
    PropsNotificationExternalServiceRequestCreated,
    keyof NotificationBase | 'isJumpVisible'
  >
}

export function normalizeNotificationExternalServiceRequestCreated(
  notification: NotificationFromQuery
) {
  if (notification.meta?.__typename !== 'ExternalServiceRequestCreated') {
    throw new Error(
      'notification.meta.__typename is not ExternalServiceRequestCreated'
    )
  }
  if (notification.details?.__typename !== 'ExternalServiceRequest') {
    throw new Error(
      'notification.details.__typename is not ExternalServiceRequest'
    )
  }

  const result: NotificationExternalServiceRequestCreated = {
    type: 'NotificationExternalServiceRequestCreated',
    props: {
      externalServiceRequestId: notification.details.id,
      name: getDisplayNameOfAddress(notification.meta.user),
      startDate: parseServerDateString(notification.meta.startDate, {
        type: 'date',
      }),
      endDate: parseServerDateString(notification.meta.endDate, {
        type: 'date',
      }),
    },
  }

  return result
}

export interface NotificationExternalServiceRequestUpdated {
  type: 'NotificationExternalServiceRequestUpdated'
  props: Omit<
    PropsNotificationExternalServiceRequestUpdated,
    keyof NotificationBase | 'isJumpVisible'
  >
}

export function normalizeNotificationExternalServiceRequestUpdated(
  notification: NotificationFromQuery
) {
  if (notification.details?.__typename !== 'ExternalServiceRequest') {
    throw new Error(
      'notification.details.__typename is not ExternalServiceRequest'
    )
  }

  const result: NotificationExternalServiceRequestUpdated = {
    type: 'NotificationExternalServiceRequestUpdated',
    props: {
      externalServiceRequestId: notification.details.id,
      name: getDisplayNameOfAddress(notification.details.user),
      startDate: parseServerDateString(notification.details.startDate),
      endDate: parseServerDateString(notification.details.endDate),
      externalServiceRequestOfUserId: notification.details.user.id,
    },
  }

  return result
}

export interface NotificationExternalServiceRequestAccepted {
  type: 'NotificationExternalServiceRequestAccepted'
  props: Omit<
    PropsNotificationExternalServiceRequestAccepted,
    keyof NotificationBase
  >
}

export function normalizeNotificationExternalServiceRequestAccepted(
  notification: NotificationFromQuery
) {
  if (notification.details?.__typename !== 'ExternalServiceRequest') {
    throw new Error(
      'notification.details.__typename is not ExternalServiceRequest'
    )
  }

  const result: NotificationExternalServiceRequestAccepted = {
    type: 'NotificationExternalServiceRequestAccepted',
    props: {
      externalServiceRequestId: notification.details.id,
      startDate: parseServerDateString(notification.details.startDate),
      endDate: parseServerDateString(notification.details.endDate),
    },
  }

  return result
}

export interface NotificationExternalServiceRequestDeclined {
  type: 'NotificationExternalServiceRequestDeclined'
  props: Omit<
    PropsNotificationExternalServiceRequestDeclined,
    keyof NotificationBase
  >
}

export function normalizeNotificationExternalServiceRequestDeclined(
  notification: NotificationFromQuery
) {
  if (notification.details?.__typename !== 'ExternalServiceRequest') {
    throw new Error(
      'notification.details.__typename is not ExternalServiceRequest'
    )
  }

  const result: NotificationExternalServiceRequestDeclined = {
    type: 'NotificationExternalServiceRequestDeclined',
    props: {
      externalServiceRequestId: notification.details.id,
      startDate: parseServerDateString(notification.details.startDate),
      endDate: parseServerDateString(notification.details.endDate),
    },
  }

  return result
}
