<template>
  <PmActionbarPure
    :selected-tab-id="selectedTabId"
    :filter-address-options="filterAddressOptionsNormalized"
    :filter-vehicle-options="filterVehicleOptionsNormalized"
    :filter-address-values="filterAddressValues"
    :filter-vehicle-values="filterVehicleValues"
    :items-address="itemsAddressNormalized"
    :items-vehicle="itemsVehicleNormalized"
    :is-search-address-loading="isLoading.addressItems"
    :is-search-vehicle-loading="isLoading.vehicleItems"
    :is-filter-address-options-loading="isLoading.addressFilter"
    @update-filter-address="(values) => (filterAddressValues = values)"
    @update-filter-vehicle="(values) => (filterVehicleValues = values)"
    @on-tab-change="(value) => (selectedTabId = value)"
  />
</template>

<script>
import { get, map } from 'lodash-es'

import { RESOURCE_TYPE, RESOURCE_TYPE_LOOKUP } from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import { normalizeVehicle } from '@/utilities/persoplan'

import { useLoading } from '@/composition/useLoading'

import PmActionbarPure from '@/components/persoplan/Actionbar/PmActionbarPure.vue'

import ActionbarAddressFilterQuery from '@/components/persoplan/Actionbar/ActionbarAddressFilterQuery.graphql'
import ActionbarVehicleFilterQuery from '@/components/persoplan/Actionbar/ActionbarVehicleFilterQuery.graphql'
import ActionbarAddressesQuery from '@/components/persoplan/Actionbar/ActionbarAddressesQuery.graphql'
import ActionbarVehiclesQuery from '@/components/persoplan/Actionbar/ActionbarVehiclesQuery.graphql'

export default {
  name: 'PmActionbar',
  components: {
    PmActionbarPure,
  },

  setup() {
    const { setLoading, isLoading } = useLoading({
      loaders: [
        'addressFilter',
        'vehicleFilter',
        'addressItems',
        'vehicleItems',
      ],
    })

    return {
      setLoading,
      isLoading,
    }
  },

  data() {
    return {
      selectedTabId: RESOURCE_TYPE.ADDRESS,
      filterAddressOptions: {
        resourceFunctions: [],
        qualifications: [],
        addressResourceTypes: [],
      },
      filterAddressValues: {
        showOnlyWithResourceType: true,
      },
      filterVehicleOptions: {
        resourceFunctions: [],
      },
      filterVehicleValues: {},
      itemsAddress: undefined,
      itemsVehicle: undefined,
      isSearchAddressLoading: false, // TODO: Use Graphql Key
      isSearchVehicleLoading: false, // TODO: Use Graphql Key
    }
  },

  computed: {
    filterAddressOptionsNormalized() {
      const resourceFunctionsFiltered = this.filterResourceFunctionsByType(
        this.filterAddressOptions.resourceFunctions,
        RESOURCE_TYPE.ADDRESS
      )

      return {
        resourceFunctions: this.normalizeOptions(resourceFunctionsFiltered),
        qualifications: this.normalizeOptions(
          this.filterAddressOptions.qualifications
        ),
        addressResourceTypes: this.normalizeOptions(
          this.filterAddressOptions.addressResourceTypes
        ),
      }
    },

    filterVehicleOptionsNormalized() {
      const resourceFunctionsFiltered = this.filterResourceFunctionsByType(
        this.filterVehicleOptions.resourceFunctions,
        RESOURCE_TYPE.VEHICLE
      )

      return {
        resourceFunctions: this.normalizeOptions(resourceFunctionsFiltered),
      }
    },

    itemsAddressNormalized() {
      if (!this.itemsAddress) return

      let result = this.itemsAddress

      result = this.applyAndSearch({
        items: result,
        pathToArray: 'resourceFunction2Address',
        pathToId: 'resourceFunction.id',
        requestedIds: this.filterAddressValues.resourceFunctionIds,
      })

      result = this.applyAndSearch({
        items: result,
        pathToArray: 'resourceQualifications',
        pathToId: 'qualification.id',
        requestedIds: this.filterAddressValues.qualificationIds,
      })

      result = result.map((item) => ({
        id: item.id,
        title: item.displayName,
        type: RESOURCE_TYPE.ADDRESS,
        ratings: this.getRatings(item),
        hasNoResourceType: !item.addressResourceType,
      }))

      return result
    },

    itemsVehicleNormalized() {
      if (!this.itemsVehicle) return

      let result = this.itemsVehicle

      result = this.applyAndSearch({
        items: result,
        pathToId: 'resourceFunction.id',
        requestedIds: this.filterVehicleValues.resourceFunctionIds,
      })

      return result.map((item) => ({
        ...normalizeVehicle(item),
        loadingVolume: item.loadingVolume,
        type: RESOURCE_TYPE.VEHICLE,
      }))
    },
  },

  created() {
    this.$eventHub.$on(EVENT.ACTIONBAR_SET_FILTER, this.onSetFilter)
  },

  beforeUnmount() {
    this.$eventHub.$off(EVENT.ACTIONBAR_SET_FILTER, this.onSetFilter)
  },

  methods: {
    onSetFilter(settings) {
      if (!settings.type) {
        throw new Error('onSetFilter: type is required')
      }

      if (settings.type) {
        this.selectedTabId = settings.type
      }

      if (settings.resourceFunctionId) {
        if (settings.type === RESOURCE_TYPE.ADDRESS) {
          this.filterAddressValues.resourceFunctionIds = [
            settings.resourceFunctionId,
          ]
        }

        if (settings.type === RESOURCE_TYPE.VEHICLE) {
          this.filterVehicleValues.resourceFunctionIds = [
            settings.resourceFunctionId,
          ]
        }
      }

      if (settings.resourceFunctionIds) {
        if (settings.type === RESOURCE_TYPE.ADDRESS) {
          this.filterAddressValues.resourceFunctionIds =
            settings.resourceFunctionIds
        }

        if (settings.type === RESOURCE_TYPE.VEHICLE) {
          this.filterVehicleValues.resourceFunctionIds =
            settings.resourceFunctionIds
        }
      }
    },

    normalizeOptions(items) {
      if (!items) return null

      return items.map((item) => ({
        id: item.id,
        label: item.caption,
      }))
    },

    filterResourceFunctionsByType(resourceFunctions, type) {
      return this.filterAddressOptions.resourceFunctions.filter(
        (resourceFunction) => {
          const resourceType =
            RESOURCE_TYPE_LOOKUP[resourceFunction?.resourceType?.id]
          return resourceType === type
        }
      )
    },

    applyAndSearch({ items, pathToArray, pathToId, requestedIds }) {
      if (!requestedIds) return items

      return items.filter((item) => {
        // Collect Ids
        let itemIds

        if (pathToArray) {
          // Search in object array
          itemIds = map(get(item, pathToArray), pathToId)
        } else {
          // Search in single object
          itemIds = [get(item, pathToId)]
        }

        // Checks if all ids are present
        return requestedIds.every((requestedId) => {
          return itemIds.includes(requestedId)
        })
      })
    },

    getRatings(item) {
      // Get ratings for resource functions
      const relevantRatingsResourceFunction =
        this.getRelevantRatingsForResourceFunction(item)

      const ratingsResourceFunctionNormalized =
        relevantRatingsResourceFunction.map((rating) => ({
          label: rating.resourceFunction.caption,
          rating: rating.ratingQuality,
        }))

      // Get ratings for qualifications
      const relevantRatingsQualification =
        this.getRelevantRatingsForQualification(item)

      const ratingsQualificationNormalized = relevantRatingsQualification.map(
        (rating) => ({
          label: rating.qualification.caption,
          rating: rating.ratingQuality,
        })
      )

      return [
        ...ratingsResourceFunctionNormalized,
        ...ratingsQualificationNormalized,
      ]
    },

    getRelevantRatingsForResourceFunction(item) {
      // Relevant ratings are ratings which are active in the filter criteria a aswell as have an actual rating value
      if (!this.filterAddressValues.resourceFunctionIds) return [] // Filter no active

      return item.resourceFunction2Address.filter((r2AItem) => {
        if (!r2AItem.ratingQuality) return // No rating present

        const hasRelevantId =
          this.filterAddressValues.resourceFunctionIds.includes(
            r2AItem.resourceFunction.id
          )
        return hasRelevantId
      })
    },

    getRelevantRatingsForQualification(item) {
      // Relevant ratings are ratings which are active in the filter criteria a aswell as have an actual rating value
      if (!this.filterAddressValues.qualificationIds) return [] // Filter no active

      return item.resourceQualifications.filter((resourceQualification) => {
        if (!resourceQualification.ratingQuality) return // No rating present

        const hasRelevantId =
          this.filterAddressValues.qualificationIds.includes(
            resourceQualification.qualification.id
          )
        return hasRelevantId
      })
    },
  },

  apollo: {
    filterAddressOptions: {
      query: ActionbarAddressFilterQuery,

      watchLoading(isLoading) {
        this.setLoading('addressFilter', isLoading)
      },

      update(data) {
        return data
      },
    },

    filterVehicleOptions: {
      query: ActionbarVehicleFilterQuery,

      watchLoading(isLoading) {
        this.setLoading('vehicleFilter', isLoading)
      },

      update(data) {
        return data
      },
    },

    itemsAddress: {
      query: ActionbarAddressesQuery,
      debounce: 250,

      skip() {
        // Wait for addressResourceTypes to be available
        return this.filterAddressOptions.addressResourceTypes.length === 0
      },

      watchLoading(isLoading) {
        this.setLoading('addressItems', isLoading)
      },

      variables() {
        let addressResourceTypeIds

        const allAvailableAddressResourceTypeIds =
          this.filterAddressOptions.addressResourceTypes.map((item) => {
            return item.id
          })

        if (this.filterAddressValues.addressResourceTypeId) {
          // Convert single value to array:
          addressResourceTypeIds = [
            this.filterAddressValues.addressResourceTypeId,
          ]
        } else {
          /**
           * If addressResourceType filter is not used and only addresses with set resource Type should
           * be displayed use all available resourceTypeIds as variable for the query
           */
          if (this.filterAddressValues.showOnlyWithResourceType === true) {
            addressResourceTypeIds = allAvailableAddressResourceTypeIds
          }
        }

        return {
          searchterm: this.filterAddressValues.searchterm,
          resourceFunctionIds: this.filterAddressValues.resourceFunctionIds,
          qualificationIds: this.filterAddressValues.qualificationIds,
          addressResourceTypeIds: addressResourceTypeIds,
        }
      },
    },

    itemsVehicle: {
      query: ActionbarVehiclesQuery,
      debounce: 250,

      watchLoading(isLoading) {
        this.setLoading('vehicleItems', isLoading)
      },

      variables() {
        return {
          searchterm: this.filterVehicleValues.searchterm,
          resourceFunctionIds: this.filterVehicleValues.resourceFunctionIds,
        }
      },
    },
  },
}
</script>

<style lang="scss">
.PmActionbar {
  $block: &;
}
</style>
