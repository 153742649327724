import { words } from 'lodash-es'

import {
  STATUS_TRAVEL_AND_HOTEL_LOOKUP,
  STATUS_TRAVEL_AND_HOTEL_LOOKUP_LABEL,
  type StatusIdTravelAndHotel,
} from '@/constants/persoplan'

// import type { Address } from '@/../generated/graphql'
import { lookup } from '@/utilities/misc'
import type { Nilable } from '@/types/misc'

export function getDisplayNameOfAddress(
  address?: {
    firstName?: Nilable<string>
    surname?: Nilable<string>
    company?: Nilable<string>
  } | null
) {
  if (!address) return

  // Formatting for name + company
  if (address.firstName && address.surname && address.company) {
    return `${address.firstName} ${address.surname} (${address.company})`.trim()
  }

  // Formatting for name only
  if (address.firstName && address.surname) {
    return `${address.firstName} ${address.surname}`.trim()
  }

  const company = address.company ? address.company : ''
  const firstName = address.firstName ? address.firstName : ''
  const lastName = address.surname ? address.surname : ''

  // just output whatever you have
  return `${firstName} ${lastName} ${company}`.trim()
}

export function getLocationForAddress(address: {
  street?: Nilable<string>
  zip?: Nilable<string>
  city?: Nilable<string>
}) {
  if (!address) return
  return `${address.street}, ${address.zip} ${address.city}`
}

function isValidStatusId(
  statusId: string | undefined
): statusId is StatusIdTravelAndHotel {
  if (!statusId) return false

  const possibleStatusIds = Object.keys(STATUS_TRAVEL_AND_HOTEL_LOOKUP)
  return possibleStatusIds.includes(statusId)
}

export function extractStatusAndValueFromString(value?: string | null) {
  if (!value) return

  /**
   * Match everything after the |
   * @see https://regex101.com/r/uKeHzO/1
   */
  const regex = /\|(?<status>.*)/gm
  const statusId = regex.exec(value)?.groups?.status
  const valueWithoutStatus = value.replace(regex, '')

  if (!isValidStatusId(statusId)) {
    return {
      statusId: undefined,
      valueWithoutStatus,
    }
  }

  return {
    statusId,
    valueWithoutStatus,
  }
}

export function stringifyValueWithStatus(value?: string | undefined | null) {
  if (!value) return

  const { statusId, valueWithoutStatus } = extractStatusAndValueFromString(
    value
  ) ?? { statusId: undefined, valueWithoutStatus: undefined }

  const status = statusId
    ? lookup(statusId, STATUS_TRAVEL_AND_HOTEL_LOOKUP)
    : undefined

  const statusLabel = lookup(status, STATUS_TRAVEL_AND_HOTEL_LOOKUP_LABEL)

  if (statusLabel && valueWithoutStatus) {
    return `${valueWithoutStatus} (${statusLabel})`
  }

  if (statusLabel) {
    return `(${statusLabel})`
  }

  if (valueWithoutStatus) {
    return valueWithoutStatus
  }
}

export function getFirstWord(string: string) {
  const firstWord = words(string)[0]
  return firstWord
}

export function getNameAbbreviation({
  firstName,
  lastName,
  company,
}: {
  firstName?: string | null
  lastName?: string | null
  company?: string | null
}) {
  if (firstName && lastName) {
    return `${firstName.substring(0, 2)}${lastName.substring(0, 2)}`
  }

  if (!company) {
    throw new Error(
      'Either firstName and lastName or company needs to be defined'
    )
  }

  return company.substring(0, 4)
}
