<template>
  <PmButtonPure
    :loading="isLoading"
    :variant="variant"
    :icon="icon"
    :icon-hover="iconHover"
    :title="xstate.meta.value.title"
    @click="emit('update')"
  >
    <template #icon>
      <PmTimerCirclePure
        v-if="xstate.state.value.matches('waiting')"
        :progress="progress"
        variant="primary"
      />
    </template>
  </PmButtonPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { states } from '@/components/basics/PmPollingIndicator/PmPollingIndicatorPureState'

const COMPONENT_NAME = 'PmPollingIndicatorPure'

export const propTypes = {
  state: {
    allowed: states,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { toRef, ref, computed } from 'vue'
import { ICONS } from '@/constants/icons'

import {
  PmPollingIndicatorPureState,
  type TState,
} from '@/components/basics/PmPollingIndicator/PmPollingIndicatorPureState'

import { useXState } from '@/composition/useXState'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmTimerCirclePure from '@/components/basics/PmTimerCircle/PmTimerCirclePure.vue'

export interface Props {
  progress?: number
  state: TState
}

const props = withDefaults(defineProps<Props>(), {
  progress: 100,
})

const emit = defineEmits<{
  (event: 'update'): void
}>()

const xstate = useXState(PmPollingIndicatorPureState, {
  syncStateWith: toRef(props, 'state'),
})

const variant = computed(() => {
  if (xstate.state.value.matches('failed')) return 'danger'
  if (xstate.state.value.matches('offline')) return 'danger'

  return undefined
})

const icon = computed(() => {
  if (xstate.state.value.matches('failed')) return ICONS.ALERT
  if (xstate.state.value.matches('offline')) return ICONS.OFFLINE
  if (xstate.state.value.matches('inactive')) return ICONS.PAUSE

  return undefined
})

const iconHover = computed(() => {
  if (xstate.state.value.matches('failed')) return ICONS.SYNC
  if (xstate.state.value.matches('waiting')) return ICONS.SYNC

  return undefined
})

const isLoading = computed(() => {
  if (xstate.state.value.matches('initialLoading')) return true
  if (xstate.state.value.matches('updating')) return true
  if (xstate.state.value.matches('updatingFromExternal')) return true

  return false
})
</script>

<style lang="scss">
.PmPollingIndicatorPure {
  $block: &;

  width: 30px;
  height: 30px;

  &-svg {
    fill: none;
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
  }

  &-circleBackground {
    stroke: color.$primary-200;
  }

  &-circle {
    stroke: color.$primary-500;
  }
}
</style>
