import { createMachine } from 'xstate'

import type { Typegen0 } from './PmLogoutState.typegen'

export type TState = Typegen0['matchesStates']

export const PmLogoutState = createMachine({
  tsTypes: {} as import('./PmLogoutState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'SIGN_OUT' },

    services: {} as {
      logout: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmLogoutState',
  initial: 'default',
  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        SIGN_OUT: 'signOut',
      },
    },

    signOut: {
      initial: 'signingOut',

      states: {
        signingOut: {
          invoke: {
            id: 'signing-out',
            src: 'logout',

            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },

            onDone: 'success',
          },
        },

        success: {
          type: 'final',
        },

        failed: {},
      },

      onDone: {
        actions: 'logoutSuccessful',
      },
    },
  },
})
