<template>
  <div class="PmSidebarResourceGroupPure" :class="classes">
    <div v-if="title" class="PmSidebarResourceGroupPure-title">{{ title }}</div>

    <ul class="PmSidebarResourceGroupPure-list">
      <PmSidebarResourceGroupItemPure
        v-for="resource in resourcesNormalized"
        :key="resource.id"
        class="PmSidebarResourceGroupPure-listItem"
        :label="resource.title"
        :is-highlighted="resource.isHighlighted"
        @highlight="emit('highlight', resource.id)"
        @un-highlight="emit('unHighlight')"
      />
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSidebarResourceGroupPure'

export const propTypes = {
  type: {
    allowed: Object.values(RESOURCE_TYPE),
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { RESOURCE_TYPE, type ResourceType } from '@/constants/persoplan'

import { defaultSortVehicles } from '@/utilities/persoplan'

import PmSidebarResourceGroupItemPure from '@/components/persoplan/PmSidebarResourceGroupItemPure.vue'

export interface Resource {
  title?: string
  id: number
  loadingCapacity?: number
  isProMusik?: boolean
  isHighlighted?: boolean
}

export interface Props {
  title?: string
  resources?: Resource[]
  type: ResourceType
}

const props = withDefaults(defineProps<Props>(), {
  resources: () => [],
})

const emit = defineEmits<{
  (event: 'highlight', id: number): void
  (event: 'unHighlight'): void
}>()

const resourcesNormalized = computed(() => {
  if (props.type === RESOURCE_TYPE.VEHICLE) {
    return vehiclesNormalized.value
  }

  return props.resources
})

const vehiclesNormalized = computed(() => {
  return defaultSortVehicles(props.resources)
})

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--withTitle`]: props.title ? true : false,
  }
})
</script>

<style lang="scss">
.PmSidebarResourceGroupPure {
  $spaceVertical: 8px;
  $spaceItemVertical: 6px;

  $block: &;

  position: relative;
  padding-bottom: $spaceVertical;

  &-title {
    @include mixin.textLabel;

    padding-top: $spaceItemVertical;
    padding-bottom: $spaceItemVertical;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: color.$text-default;

    &::after {
      content: '';
      flex-basis: 100%;
      height: 1px;
      background-color: color.$gray-200;
      // margin-right: -10px;
    }
  }

  &-list {
    list-style: none;
    cursor: default;
    padding: 0;
    margin: 0;
  }
}
</style>
