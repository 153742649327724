import {
  type NotificationBase,
  type NotificationFromQuery,
} from '@/store/notifications/notifications'

import { type Props as PropsNotificationLeaveRequestCreated } from '@/components/PmNotification/PmNotificationLeaveRequestCreated/PmNotificationLeaveRequestCreated.vue'
import { type Props as PropsNotificationLeaveRequestUpdated } from '@/components/PmNotification/PmNotificationLeaveRequestUpdated/PmNotificationLeaveRequestUpdated.vue'
import { type Props as PropsNotificationLeaveRequestAccepted } from '@/components/PmNotification/PmNotificationLeaveRequestAccepted/PmNotificationLeaveRequestAccepted.vue'
import { type Props as PropsNotificationLeaveRequestDeclined } from '@/components/PmNotification/PmNotificationLeaveRequestDeclined/PmNotificationLeaveRequestDeclined.vue'
import { parseServerDateString } from '@/utilities/date'
import { getDisplayNameOfAddress } from '@/utilities/string'

export interface NotificationLeaveRequestCreated {
  type: 'NotificationLeaveRequestCreated'
  props: Omit<
    PropsNotificationLeaveRequestCreated,
    keyof NotificationBase | 'isJumpVisible'
  >
}

export function normalizeNotificationLeaveRequestCreated(
  notification: NotificationFromQuery
) {
  if (notification.meta?.__typename !== 'LeaveRequestCreated') {
    throw new Error('notification.meta.__typename is not LeaveRequestCreated')
  }
  if (notification.details?.__typename !== 'LeaveRequest') {
    throw new Error('notification.details.__typename is not LeaveRequest')
  }

  const result: NotificationLeaveRequestCreated = {
    type: 'NotificationLeaveRequestCreated',
    props: {
      leaveRequestId: notification.details.id,
      name: getDisplayNameOfAddress(notification.meta.user),
      startDate: parseServerDateString(notification.meta.startDate, {
        type: 'date',
      }),
      endDate: parseServerDateString(notification.meta.endDate, {
        type: 'date',
      }),
      type: notification.meta.type,
    },
  }

  return result
}

export interface NotificationLeaveRequestUpdated {
  type: 'NotificationLeaveRequestUpdated'
  props: Omit<
    PropsNotificationLeaveRequestUpdated,
    keyof NotificationBase | 'isJumpVisible'
  >
}

export function normalizeNotificationLeaveRequestUpdated(
  notification: NotificationFromQuery
) {
  if (notification.details?.__typename !== 'LeaveRequest') {
    throw new Error('notification.details.__typename is not LeaveRequest')
  }

  const result: NotificationLeaveRequestUpdated = {
    type: 'NotificationLeaveRequestUpdated',
    props: {
      leaveRequestId: notification.details.id,
      name: getDisplayNameOfAddress(notification.details.user),
      startDate: parseServerDateString(notification.details.startDate),
      endDate: parseServerDateString(notification.details.endDate),
      type: notification.details.type,
      leaveRequestOfUserId: notification.details.user.id,
    },
  }

  return result
}

export interface NotificationLeaveRequestAccepted {
  type: 'NotificationLeaveRequestAccepted'
  props: Omit<PropsNotificationLeaveRequestAccepted, keyof NotificationBase>
}

export function normalizeNotificationLeaveRequestAccepted(
  notification: NotificationFromQuery
) {
  if (notification.details?.__typename !== 'LeaveRequest') {
    throw new Error('notification.details.__typename is not LeaveRequest')
  }

  const result: NotificationLeaveRequestAccepted = {
    type: 'NotificationLeaveRequestAccepted',
    props: {
      leaveRequestId: notification.details.id,
      startDate: parseServerDateString(notification.details.startDate),
      endDate: parseServerDateString(notification.details.endDate),
      type: notification.details.type,
    },
  }

  return result
}
export interface NotificationLeaveRequestDeclined {
  type: 'NotificationLeaveRequestDeclined'
  props: Omit<PropsNotificationLeaveRequestDeclined, keyof NotificationBase>
}

export function normalizeNotificationLeaveRequestDeclined(
  notification: NotificationFromQuery
) {
  if (notification.details?.__typename !== 'LeaveRequest') {
    throw new Error('notification.details.__typename is not LeaveRequest')
  }

  const result: NotificationLeaveRequestDeclined = {
    type: 'NotificationLeaveRequestDeclined',
    props: {
      leaveRequestId: notification.details.id,
      startDate: parseServerDateString(notification.details.startDate),
      endDate: parseServerDateString(notification.details.endDate),
      type: notification.details.type,
    },
  }

  return result
}
