import { computed, type Ref } from 'vue'
import scssVariables from '@/assets/scss/scssVariables'

import { RESOURCE_STATUS, type ResourceStatus } from '@/constants/persoplan'

export { type ResourceStatus, RESOURCE_STATUS }

interface Options {
  status: Ref<ResourceStatus | undefined>
}

export function useResourceDayStatus(options: Options) {
  const classes = computed(() => {
    if (!options.status.value) return

    return {
      'is-statusResourceDayNotAvailable':
        options.status.value === RESOURCE_STATUS.NOT_AVAILABLE,
      'is-statusResourceDayAvailableIfUrgent':
        options.status.value === RESOURCE_STATUS.AVAILABLE_IF_URGENT,
      'is-statusResourceDayAvailable':
        options.status.value === RESOURCE_STATUS.AVAILABLE,
      'is-statusResourceDayStorage':
        options.status.value === RESOURCE_STATUS.STORAGE,
      'is-statusResourceDayOnProject':
        options.status.value === RESOURCE_STATUS.ON_PROJECT,
    }
  })

  const color = computed(() => {
    if (!options.status.value) return
    return scssVariables.color[`status-resourceDay-${options.status.value}`]
  })

  return {
    classes,
    color,
  }
}
