<template>
  <PmDataModalResourceAllocationPure
    :id="id"
    :is-loading="isLoading"
    :type="type"
    :status="status"
    :factor="resourceAllocation?.factor"
    :travel="resourceAllocation?.travel"
    :hotel="resourceAllocation?.hotel"
    :note="resourceAllocation?.extra?.notice"
    :name="resourceAllocation?.address?.displayName"
    :company="resourceAllocation?.address?.company"
    :address="{
      id: resourceAllocation?.address?.id,
      street: resourceAllocation?.address?.street,
      zip: resourceAllocation?.address?.zip,
      city: resourceAllocation?.address?.city,
      country: resourceAllocation?.address?.country?.caption,
    }"
    :phone="{
      standard: resourceAllocation?.address?.phone,
      company: resourceAllocation?.address?.phoneCompany,
      mobile: resourceAllocation?.address?.phoneMobile,
      private: resourceAllocation?.address?.phonePrivate,
    }"
    :email="resourceAllocation?.address?.email"
    :vehicle="resourceAllocation?.vehicle?.caption"
    :project="project"
    :job="job"
    :appointment="
      resourceAllocation?.resourceFunctionAllocation?.jobAppointment?.caption
    "
    :resource="
      resourceAllocation?.resourceFunctionAllocation?.resourceFunction?.caption
    "
    :resource-request="resourceRequest"
    :status-webfleet="resourceAllocation?.extra?.webfleetState"
    :is-webfleet-vehicle="resourceAllocation?.vehicle?.isWebfleetVehicle"
    :can-create-webfleet-order="can('create', 'webfleetOrder')"
    @open-project="
      EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
        type: DATA_MODAL_TYPE.PROJECT,
        id: resourceAllocation?.resourceFunctionAllocation?.job?.project?.id,
      })
    "
    @open-job="
      EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
        type: DATA_MODAL_TYPE.JOB,
        id: resourceAllocation?.resourceFunctionAllocation?.job?.id,
      })
    "
    @open-appointment="
      EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
        type: DATA_MODAL_TYPE.APPOINTMENT,
        id: resourceAllocation?.resourceFunctionAllocation?.jobAppointment?.id,
      })
    "
    @open-resource="
      EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
        type: DATA_MODAL_TYPE.RESOURCE,
        id: resourceAllocation?.resourceFunctionAllocation?.id,
      })
    "
    @open-resource-request="
      EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
        type: DATA_MODAL_TYPE.RESOURCE_REQUEST,
        id: resourceAllocation?.id,
      })
    "
    @send-to-webfleet="onSendtoWebfleet"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'DataModalResourceAllocation'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'

import {
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  STATUS_PROJECT_LOOKUP,
  STATUS_JOB_LOOKUP,
  RESOURCE_TYPE,
  DATA_MODAL_TYPE,
} from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import EventHub from '@/eventHub'
import { lookup } from '@/utilities/misc'
import { parseServerDateString } from '@/utilities/date'
import notifications from '@/store/notifications/notifications'
import { useAppAbility } from '@/composition/useAppAbility'

import PmDataModalResourceAllocationPure, {
  type Props as PropsDataModalResourceAllocationPure,
} from '@/components/persoplan/DataModal/PmDataModalResourceAllocation/PmDataModalResourceAllocationPure.vue'

import { DataModalResourceAllocationDocument } from '@/../generated/graphql'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const { can } = useAppAbility()

const resourceAllocationQuery = useQuery(
  DataModalResourceAllocationDocument,
  () => ({
    id: props.id,
  }),
  () => ({
    // Don't update the cache, which might change the calendar view.
    fetchPolicy: 'no-cache',
    // notifyOnNetworkStatusChange is needed so $apollo.loading gets updated
    notifyOnNetworkStatusChange: true,
  })
)

const resourceAllocation = computed(
  () => resourceAllocationQuery.result.value?.resourceAllocation
)

const isLoading = computed(() => {
  return resourceAllocationQuery.loading.value === true
})

const type = computed(() => {
  if (resourceAllocation.value?.address) return RESOURCE_TYPE.ADDRESS
  if (resourceAllocation.value?.vehicle) return RESOURCE_TYPE.VEHICLE

  return undefined
})

const status = computed(() => {
  return lookup(
    resourceAllocation.value?.resourceAllocationState?.id,
    STATUS_RESOURCE_ALLOCATION_LOOKUP
  )
})

/**
 * Project
 */
const projectStatus = computed(() => {
  return lookup(
    resourceAllocation.value?.resourceFunctionAllocation?.job?.project
      ?.projectState?.id,
    STATUS_PROJECT_LOOKUP
  )
})

const project = computed(() => {
  const result: PropsDataModalResourceAllocationPure['project'] = {
    number:
      resourceAllocation.value?.resourceFunctionAllocation?.job?.project
        ?.number,
    label: `${resourceAllocation.value?.resourceFunctionAllocation?.job?.project?.caption} (${resourceAllocation.value?.resourceFunctionAllocation?.job?.project?.projectState?.caption})`,
    status: projectStatus.value,
  }

  return result
})

/**
 * Job
 */
const jobStatus = computed(() => {
  return lookup(
    resourceAllocation.value?.resourceFunctionAllocation?.job?.jobState?.id,
    STATUS_JOB_LOOKUP
  )
})

const job = computed(() => {
  const result: PropsDataModalResourceAllocationPure['job'] = {
    number: resourceAllocation.value?.resourceFunctionAllocation?.job?.number,
    label: `${resourceAllocation.value?.resourceFunctionAllocation?.job?.caption} (${resourceAllocation.value?.resourceFunctionAllocation?.job?.jobState?.caption})`,
    status: jobStatus.value,
  }

  return result
})

/**
 * ResourceRequest
 */
const resourceRequest = computed(() => {
  if (!resourceAllocation.value?.resourceRequest) return

  const created = parseServerDateString(
    resourceAllocation.value.resourceRequest.created
  )
  if (!created) {
    throw new Error('created is undefined but is a required property')
  }

  const result: PropsDataModalResourceAllocationPure['resourceRequest'] = {
    created: created,
    dateOfExpire: parseServerDateString(
      resourceAllocation.value.resourceRequest.dateOfExpire
    ),
    statusFeedback:
      resourceAllocation.value.resourceRequest.feedback?.userState,
    hasNotification:
      hasNotificationForResourceRequestFeedbackStateUpdated.value,
  }

  return result
})

const hasNotificationForResourceRequestFeedbackStateUpdated = computed(() => {
  return notifications.unreadNotificationsForResourceRequestFeedbackStateUpdated.value?.has(
    props.id
  )
})

function onSendtoWebfleet() {
  EventHub.$emit(EVENT.MODAL_SEND_TO_WEBFLEET_SHOW, {
    resourceAllocationId: props.id,
  })

  emit('close')
}
</script>
