<template>
  <PmEventsPure :container-start-date="startDate" :container-end-date="endDate">
    <!-- {{ normalizedBirthdays }} -->
    <PmEventsItemPure
      v-for="birthday in normalizedBirthdays"
      :key="birthday.id"
      v-slot="{ styles }"
      :start-date="birthday.date"
      :end-date="birthday.date"
      :container-start-date="startDate"
      :container-end-date="endDate"
    >
      <PmBirthdayPure
        :style="styles"
        :name="birthday.name"
        :date="birthday.date"
        :date-of-birth="birthday.dateOfBirth"
      />
    </PmEventsItemPure>
  </PmEventsPure>
</template>

<script>
import { getMonth, getYear, set } from 'date-fns'

import { FORMAT_DATE_SERVER } from '@/constants/persoplan'

import {
  startDateForServer,
  endDateForServer,
  parseServerDateString,
} from '@/utilities/date'

import PmEventsPure from '@/components/persoplan/PmGeneralEvents/PmEventsPure.vue'
import PmEventsItemPure from '@/components/persoplan/PmGeneralEvents/PmEventsItemPure.vue'
import PmBirthdayPure from '@/components/persoplan/PmGeneralEvents/PmBirthdayPure.vue'

import BirthdaysQuery from '@/components/persoplan/PmGeneralEvents/BirthdaysQuery.graphql'

export default {
  name: 'PmBirthdays',
  components: {
    PmEventsPure,
    PmEventsItemPure,
    PmBirthdayPure,
  },

  props: {
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
  },

  data() {
    return {
      birthdays: [],
    }
  },

  computed: {
    normalizedBirthdays() {
      return this.birthdays.map((birthday) => {
        const birthdayDate = parseServerDateString(birthday.birthday, {
          type: 'date',
        })

        // Figure out which birthday year is the correct one
        // e.g. if visible date range is from 2019-11 to 2020-02
        // it uses 2020 for every birthday which month is lower than the first month (11) in the visible date range
        const firstMonthInDateRange = getMonth(this.startDate)
        const monthOfBirthday = getMonth(birthdayDate)
        const useYear =
          monthOfBirthday < firstMonthInDateRange
            ? getYear(this.endDate)
            : getYear(this.startDate)

        return {
          name: birthday.displayName,
          date: set(birthdayDate, { year: useYear }),
          dateOfBirth: birthdayDate,
        }
      })
    },
  },

  apollo: {
    birthdays: {
      query: BirthdaysQuery,

      variables() {
        return {
          startDate: startDateForServer(this.startDate),
          endDate: endDateForServer(this.endDate),
        }
      },
    },
  },
}
</script>

<style lang="scss">
.PmBirthdays {
  $block: &;
}
</style>
