import { createMachine } from 'xstate'

import type { Typegen0 } from './PmNoteState.typegen'

export type TState = Typegen0['matchesStates']

export const PmNoteState = createMachine({
  tsTypes: {} as import('./PmNoteState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'UPDATE' } | { type: 'CANCEL' },

    services: {} as {
      updateNote: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmNoteState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        UPDATE: 'update',
      },
    },

    update: {
      initial: 'updating',

      on: {
        CANCEL: '#PmNoteState.default',
      },

      states: {
        updating: {
          invoke: {
            id: 'update-note',
            src: 'updateNote',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
            onDone: '#PmNoteState.default',
          },
        },
        failed: {
          on: {
            UPDATE: 'updating',
          },
        },
      },
    },
  },
})
