<template>
  <div class="PmBeforeAfterPure">
    <template v-if="isOnlyBeforeValue">
      <slot name="before">—</slot>
    </template>

    <template v-else>
      <div class="PmBeforeAfterPure-comparison">
        <PmTagPure label="Vorher" class="PmBeforeAfterPure-tag" />

        <div class="PmBeforeAfterPure-valueBefore">
          <slot name="before">—</slot>
        </div>

        <PmTagPure label="Nachher" class="PmBeforeAfterPure-tag" />

        <div class="PmBeforeAfterPure-valueAfter">
          <slot name="after">—</slot>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmBeforeAfterPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { useHasSlotContent } from '@/composition/useHasSlotContent'

import PmTagPure from '@/components/basics/PmTagPure/PmTagPure.vue'

const hasSlotContent = useHasSlotContent([
  'before',
  { id: 'after', options: { allowEmptyText: true } },
] as const)

const isOnlyBeforeValue = computed(() => {
  return hasSlotContent.value.after === false
})
</script>

<style lang="scss">
.PmBeforeAfterPure {
  $block: &;

  &-comparison {
    display: grid;
    gap: 4px;
    grid-template-columns: auto 1fr;
    justify-items: start;
  }

  &-tag {
    justify-self: end;
    align-self: start;
  }

  &-valueBefore {
    margin-left: 8px;
    opacity: 0.5;
    // text-decoration: line-through;
  }

  &-valueAfter {
    margin-left: 8px;
  }
}
</style>
