<template>
  <PmMultipleResourceAllocationsRequest
    :resource-allocation-ids="allConsideredOfAppointment"
    type="address"
    :has-no-results="hasNoResults"
    @close="emit('close')"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import type { Get } from 'type-fest'

import {
  RESOURCE_TYPE_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_ID_LOOKUP,
  RESOURCE_TYPE,
} from '@/constants/persoplan'

import PmMultipleResourceAllocationsRequest from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequest.vue'

import {
  AllConsideredOfAppointmentDocument,
  type AllConsideredOfAppointmentQuery,
} from '@/../generated/graphql'

export interface Props {
  appointmentId: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const allConsideredOfAppointmentQuery = useQuery(
  AllConsideredOfAppointmentDocument,
  () => ({
    appointmentId: props.appointmentId,
  }),
  () => ({
    fetchPolicy: 'network-only',
  })
)

function isVehicle(
  resource: Get<AllConsideredOfAppointmentQuery, 'resources[0]'>
) {
  if (!resource) return

  const resourceTypeId = resource.resourceFunction?.resourceType?.id
  if (!resourceTypeId) {
    throw new Error('resourceTypeId is undefined')
  }

  const isVehicle =
    RESOURCE_TYPE_LOOKUP[resourceTypeId] == RESOURCE_TYPE.VEHICLE

  return isVehicle
}

const allConsideredOfAppointment = computed(() => {
  const resourceAllocationIds: number[] = []

  allConsideredOfAppointmentQuery.result.value?.resources?.forEach(
    (resource) => {
      if (!resource) return
      if (isVehicle(resource)) return

      resource.resourceAllocations?.forEach((resourceAllocation) => {
        if (!resourceAllocation) return

        if (!resourceAllocation.resourceAllocationState?.id) {
          throw new Error('resourceAllocationState.id is undefined')
        }

        if (
          resourceAllocation.resourceAllocationState.id !==
          STATUS_RESOURCE_ALLOCATION_ID_LOOKUP['considered']
        ) {
          throw new Error('resourceAllocationState is not considered!')
        }

        resourceAllocationIds.push(resourceAllocation.id)
      })
    }
  )

  return resourceAllocationIds
})

const hasNoResults = computed(() => {
  if (allConsideredOfAppointmentQuery.error.value) return false

  return (
    allConsideredOfAppointmentQuery.loading.value === false &&
    allConsideredOfAppointment.value.length === 0
  )
})
</script>
