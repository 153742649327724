<template>
  <div class="PmColorPickerPopoverPure">
    <div class="PmColorPickerPopoverPure-colorList">
      <PmColorButtonPure
        v-for="color in colors"
        :key="color.id"
        :color="color.value"
        :is-active="!isRandom && color.id === selectedColorId"
        @click="$emit('selectColor', color)"
      />

      <PmColorButtonPure
        icon="random"
        :icon-visible="true"
        :color="COLOR_DEFAULT"
        title="Zufällige Farbe"
        :is-active="isRandom"
        @click="$emit('useRandomColor')"
      />
    </div>
  </div>
</template>

<script>
import { color } from '@/assets/scss/scssVariables'

import PmColorButtonPure from '@/components/basics/PmColorButton/PmColorButtonPure.vue'

const COLOR_DEFAULT = color['gray-300']

export default {
  name: 'PmColorPickerPopoverPure',
  components: {
    PmColorButtonPure,
  },

  props: {
    colors: { type: Array, default: () => [] },
    selectedColorId: { type: String, default: undefined },
    isRandom: { type: Boolean, default: undefined },
  },

  emits: ['useRandomColor', 'selectColor'],

  setup() {
    return {
      COLOR_DEFAULT,
    }
  },
}
</script>

<style lang="scss">
.PmColorPickerPopoverPure {
  $block: &;

  display: flex;

  &-colorList {
    display: flex;
    gap: 8px;
  }

  &-randomIcon {
    width: 20px;
    height: 20px;
  }
}
</style>
