<template>
  <div class="PmSearchPure">
    <PmInputPure
      ref="input"
      :value="value"
      :loading="isLoading"
      placeholder="Suchbegriff eingeben…"
      size="large"
      type="search"
      :focus-on-mounted="true"
      @input="onInput"
      @keydown.up.prevent="highlightPrevious"
      @keydown.down.prevent="highlightNext"
      @keydown.enter="selectActive"
    />

    <div v-show="resultsVisible" class="PmSearchPure-results">
      <PmSearchResultPure
        v-for="(result, index) in results"
        ref="elOptions"
        :key="result.id"
        :index="index"
        :is-active="index === highlightedIndex"
        :now="now"
        v-bind="result"
        @click="selectIndex(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmSearchPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
/**
 * @todo Add focus trap
 */

import { ref, computed } from 'vue'
import { isNil } from 'lodash-es'

import PmSearchResultPure, {
  type Props as PropsSearchResultPure,
} from '@/components/persoplan/Search/PmSearchResultPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'

import type { Nilable } from '@/types/misc'

export interface Result extends PropsSearchResultPure {
  id: number
}

export interface Props {
  value?: string
  results: Result[]
  isLoading?: boolean
  now?: Date
}

const props = withDefaults(defineProps<Props>(), {
  results: () => [],
  now: () => new Date(),
})

const emit = defineEmits<{
  (event: 'input', value: string | number): void
  (event: 'update:value', value: string | number): void
  (event: 'select', id: number): void
}>()

function onInput(value: Nilable<string | number>) {
  if (isNil(value)) return

  emit('input', value)
  emit('update:value', value)
}

const highlightedIndex = ref(0)
const elOptions = ref<HTMLOptionElement[]>()

const resultsVisible = computed(() => {
  return props.results.length > 0
})

function highlightPrevious() {
  highlightedIndex.value = highlightedIndex.value - 1

  if (highlightedIndex.value < 0) {
    highlightedIndex.value = props.results.length - 1
  }
}

function highlightNext() {
  highlightedIndex.value = highlightedIndex.value + 1

  if (highlightedIndex.value > props.results.length - 1) {
    highlightedIndex.value = 0
  }
}

function selectActive() {
  selectIndex(highlightedIndex.value)
}

function selectIndex(index: number) {
  highlightedIndex.value = index

  const result = props.results[highlightedIndex.value]
  if (!result) return

  emit('select', result.id)
}
</script>

<style lang="scss">
.PmSearchPure {
  $block: &;

  padding: 0;
  margin-top: 12px;

  &-results {
    max-height: calc(60vh - (var(--space-default) * 2));
    overflow: auto;
  }
}
</style>
