<template>
  <PmDialogConfirmPure
    :subject="SUBJECT_LOOKUP[requestType]"
    action-label="Ablehnen"
    :is-loading="isLoading"
    :error-message="errorMessage"
    :error-details="errorDetails"
    :form-id="formId"
    @cancel="emit('cancel')"
  >
    <div :class="componentClass.root">
      <PmFormPure :id="formId" :disabled="isLoading" @submit.prevent="onSubmit">
        <PmTextareaPure
          v-model:value="note"
          label="Bemerkung"
          :rows="3"
          required
        />
      </PmFormPure>
    </div>
  </PmDialogConfirmPure>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import cuid from '@paralleldrive/cuid2'

import PmDialogConfirmPure from '@/components/basics/PmDialog/PmDialogConfirmPure.vue'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmTextareaPure from '@/components/basics/PmTextarea/PmTextareaPure.vue'

export interface Props {
  requestType: 'leaveRequest' | 'externalServiceRequest'
  isLoading?: boolean
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  submit: {
    note: string
  }
}

const emit = defineEmits<{
  cancel: []
  submit: [Emits['submit']]
}>()

const componentClass = useComponentClass()
const formId = cuid.createId()
const note = ref<string>()

const SUBJECT_LOOKUP: Record<Props['requestType'], string> = {
  leaveRequest: 'Urlaubsantrag',
  externalServiceRequest: 'Fremddienstleistung',
}

function onSubmit() {
  if (!note.value) throw new Error('note is undefined')

  emit('submit', {
    note: note.value,
  })
}
</script>

<style lang="scss">
.PmDialogRequestRejectPure {
  $block: &;
}
</style>
