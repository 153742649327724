<template>
  <div class="PmStartupNeedsActionPure">
    <PmAppNotificationPure
      :icon="ICONS.ALERT"
      class="PmStartupNeedsActionPure-notification"
    >
      {{ message }}
    </PmAppNotificationPure>

    <div class="PmStartupNeedsActionPure-content">
      <slot />
    </div>
  </div>
</template>

<script>
import { ICONS } from '@/constants/icons'

import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'

export default {
  name: 'PmStartupNeedsActionPure',
  components: {
    PmAppNotificationPure,
  },

  props: {
    message: { type: String, default: undefined },
  },

  setup() {
    return {
      ICONS,
    }
  },
}
</script>

<style lang="scss">
.PmStartupNeedsActionPure {
  $block: &;

  &-notification {
    margin-bottom: 20px;
  }
}
</style>
