import { computed, type Ref } from 'vue'
import scssVariables from '@/assets/scss/scssVariables'

import {
  STATUS_RESOURCE_ALLOCATION,
  type ResourceAllocationStatus,
  STATUS_RESOURCE_ALLOCATION_LOOKUP_LABEL,
} from '@/constants/persoplan'

import { lookup } from '@/utilities/misc'

export { type ResourceAllocationStatus, STATUS_RESOURCE_ALLOCATION }

interface Options {
  status: Ref<ResourceAllocationStatus | undefined | null>
}

export function useResourceAllocationStatus(options: Options) {
  const classes = computed(() => {
    if (!options.status.value) return

    return {
      'is-statusConfirmed':
        options.status.value === STATUS_RESOURCE_ALLOCATION.CONFIRMED,
      'is-statusRequested':
        options.status.value === STATUS_RESOURCE_ALLOCATION.REQUESTED,
      'is-statusCancelled':
        options.status.value === STATUS_RESOURCE_ALLOCATION.CANCELLED,
      'is-statusConsidered':
        options.status.value === STATUS_RESOURCE_ALLOCATION.CONSIDERED,
      'is-statusReserved':
        options.status.value === STATUS_RESOURCE_ALLOCATION.RESERVED,
    }
  })

  const color = computed(() => {
    if (!options.status.value) return

    return scssVariables.color[`status-${options.status.value}`]
  })

  const label = computed(() => {
    return lookup(options.status.value, STATUS_RESOURCE_ALLOCATION_LOOKUP_LABEL)
  })

  return {
    classes,
    color,
    label,
  }
}
