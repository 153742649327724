<template>
  <div class="PmResourceTimelinesPure">
    <div v-if="!$slots.default" class="PmResourceTimelinesPure-empty">
      Kein {{ label }} vorhanden
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'PmResourceTimelinesPure',
  components: {},

  props: {
    label: { type: String, required: true },
  },
}
</script>

<style lang="scss">
.PmResourceTimelinesPure {
  $block: &;

  position: relative;

  &-empty {
    @include mixin.textLabel;

    padding: 10px;
    position: sticky;
    left: var(--sidebarLeftWidthWithVisibility);
  }
}
</style>
