<template>
  <PmModalPure title="Zeitraum ändern" @close="close">
    <div class="PmDateControllerPure">
      <PmButtonListPure
        v-if="shortcutsNormalized"
        class="PmDateControllerPure-shortcuts"
      >
        <PmButtonPure
          v-for="shortcut in shortcutsNormalized"
          :key="shortcut.label"
          :label="shortcut.label"
          @click="applyShortcut({ start: shortcut.start, end: shortcut.end })"
        />
      </PmButtonListPure>

      <PmDateRangePickerPure
        v-model:value="dates"
        class="PmDateControllerPure-dateRangePicker"
        @confirm="confirm"
        @valid="areDatesValid = true"
        @invalid="areDatesValid = false"
      >
        <template #notifications>
          <PmAppNotificationPure v-if="infoMessage">
            {{ infoMessage }}
          </PmAppNotificationPure>
        </template>
      </PmDateRangePickerPure>
    </div>

    <template #footer>
      <PmButtonListPure align="right">
        <PmButtonPure label="Abbrechen" @click="close" />

        <PmButtonPure
          class="PmDateControllerPure-confirm"
          label="Anwenden"
          variant="primary"
          :disabled="!areDatesValid"
          @click="confirm"
        />
      </PmButtonListPure>
    </template>
  </PmModalPure>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import {
  differenceInCalendarDays,
  addDays,
  startOfDay,
  endOfDay,
} from 'date-fns'

import { REDUCED_VIEW_DAYS_TRESHOLD } from '@/constants/persoplan'
import { BREAKPOINT } from '@/constants/general'

import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmDateRangePickerPure from '@/components/basics/PmDateRangePicker/PmDateRangePickerPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'

import type { Shortcut } from '@/constants/persoplan'

export interface Props {
  startDate: Date
  endDate: Date
  shortcuts: Shortcut[]
}

export interface Event {
  setDates: {
    start: Date
    end: Date
  }
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'setDates', dates: Event['setDates']): void
}>()

const isMobile = window.matchMedia(`(max-width: ${BREAKPOINT.MOBILE})`).matches

const areDatesValid = ref(true)
const dates = ref({
  start: props.startDate,
  end: props.endDate,
})

const willBeReducedView = computed(() => {
  return (
    differenceInCalendarDays(dates.value.end, dates.value.start) >=
    REDUCED_VIEW_DAYS_TRESHOLD
  )
})

const infoMessage = computed(() => {
  if (willBeReducedView.value) {
    // return `Zeiträume über ${REDUCED_VIEW_DAYS_TRESHOLD} Tage werden in einer reduzierten Ansicht angezeigt!`
    return `Sehr große Zeiträume können zu Performance-Problemen und/oder langen Ladezeiten führen!`
  }

  return undefined
})

const shortcutsNormalized = computed(() => {
  if (!props.shortcuts) return

  return props.shortcuts.filter((shortcut) => {
    if (!isMobile) return true
    return shortcut.isVisibleOnMobile
  })
})

function close() {
  emit('close')
}

async function confirm() {
  emit('setDates', dates.value)
  close()
}

function applyShortcut({ start, end }: { start: number; end: number }) {
  dates.value.start = startOfDay(addDays(new Date(), start))
  dates.value.end = endOfDay(addDays(new Date(), end))
}
</script>

<style lang="scss">
.PmDateControllerPure {
  &-shortcuts {
    margin-bottom: var(--space-small);
  }

  &-confirm {
    flex: none;
  }
}
</style>
