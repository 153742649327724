<template>
  <PmNotificationExternalServiceRequestDeclinedPure
    v-bind="{ ...$attrs, ...props }"
    @open-details="isDetailsVisible = true"
  />

  <portal to="modal">
    <PmModalPure
      v-if="isDetailsVisible"
      title="Antrag Fremddienstleistung"
      @close="isDetailsVisible = false"
    >
      <PmRequestDetail
        v-if="isDetailsVisible"
        :id="props.externalServiceRequestId"
        type="externalServiceRequest"
      />
    </PmModalPure>
  </portal>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import PmNotificationExternalServiceRequestDeclinedPure, {
  type Props as PropsNotificationExternalServiceRequestCreatedPure,
} from '@/components/PmNotification/PmNotificationExternalServiceRequestDeclined/PmNotificationExternalServiceRequestDeclinedPure.vue'
import PmRequestDetail from '@/components/PmRequestDetail/PmRequestDetail.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'

export interface Props
  extends PropsNotificationExternalServiceRequestCreatedPure {}

const props = withDefaults(defineProps<Props>(), {})

const isDetailsVisible = ref(false)
</script>
