import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmSendToWebfleetState.typegen'

export type State = Typegen0['matchesStates']

export const PmSendToWebfleetState = createMachine({
  tsTypes: {} as import('./PmSendToWebfleetState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'SEND' }
      | { type: 'LOADED' }
      | { type: 'ERROR' }
      | { type: 'DELETE' },

    services: {} as {
      loadWebfleetOrder: any
      sendToWebfleet: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  id: 'PmSendToWebfleetState',
  initial: 'load',

  states: {
    load: {
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            id: 'load-webfleet-order',
            src: 'loadWebfleetOrder',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
            onDone: '#PmSendToWebfleetState.default',
          },
        },
        failed: {},
      },
    },

    default: {
      on: {
        SEND: 'send',
        DELETE: 'delete',
      },
    },

    send: {
      initial: 'sending',
      states: {
        sending: {
          invoke: {
            id: 'send-to-webfleet',
            src: 'sendToWebfleet',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
            onDone: 'success',
          },
        },

        failed: {
          on: {
            SEND: 'sending',
          },
        },

        success: {
          type: 'final',
          entry: ['showSendSuccessNotification', 'emitClose'],
        },
      },
    },

    delete: {
      initial: 'deleting',
      states: {
        askForConfirmation: {},

        deleting: {
          invoke: {
            id: 'delete-in-webfleet',
            src: 'deleteInWebfleet',
            onError: {
              target: 'failed',
              actions: (context, error) => {
                context.error = error.data.message
                context.errorDetails = error.data.details
              },
            },
            onDone: 'success',
          },
        },

        failed: {
          on: {
            DELETE: 'deleting',
          },
        },

        success: {
          type: 'final',
          entry: ['showDeleteSuccessNotification', 'emitClose'],
        },
      },
    },
  },
})

export const states = extractAllStates(PmSendToWebfleetState)
