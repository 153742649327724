<template>
  <PmDefaultViewsPure
    :views="normalizedViews"
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :initial-form-data="initialFormData"
    @submit="
      ({ viewIdMobile, viewIdDesktop }) =>
        xstate.service.value.send('SUBMIT', { viewIdMobile, viewIdDesktop })
    "
  />
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import { throwFriendlyError } from '@/functional/error'
import { getSettingsDataToSave } from '@/functional/settings'
import { useXState } from '@/composition/useXState'

import { PmDefaultViewsState } from '@/components/PmDefaultViews/PmDefaultViewsState'

import PmDefaultViewsPure from '@/components/PmDefaultViews/PmDefaultViewsPure.vue'

import ViewsQuery from '@/components/persoplan/PmSettings/ViewsQuery.graphql'
import SaveSettingMutation from '@/graphql/SaveSettingMutation.graphql'

export default defineComponent({
  name: 'PmDefaultViews',
  components: {
    PmDefaultViewsPure,
  },

  setup() {
    const instance = getCurrentInstance()

    const xstate = useXState(PmDefaultViewsState, {
      services: {
        save: (context, { viewIdMobile, viewIdDesktop }) =>
          instance.ctx.save({ viewIdMobile, viewIdDesktop }),
      },
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      views: [],
      initialFormData: {
        selectedViewIdMobile: undefined,
        selectedViewIdDesktop: undefined,
      },
    }
  },

  computed: {
    normalizedViews() {
      if (FEATURE_FLAG.ENABLE_PUBLIC_VIEWS === false) {
        const nonPublicViews = this.views.filter((view) => view.public !== true)
        return nonPublicViews.map((view) => ({
          id: view.id,
          label: view.title,
        }))
      }

      return this.views.map((view) => ({
        id: view.id,
        label: view.title,
        icon: view.public ? 'public' : null,
        iconTitle: view.public ? 'Öffentlich' : null,
      }))
    },
  },

  created() {
    this.initialFormData.selectedViewIdMobile =
      this.$store.state.view.defaultSavedViewIdMobile

    this.initialFormData.selectedViewIdDesktop =
      this.$store.state.view.defaultSavedViewIdDesktop
  },

  methods: {
    async save({ viewIdMobile, viewIdDesktop }) {
      const settings = getSettingsDataToSave({
        'view.defaultSavedViewIdMobile': viewIdMobile,
        'view.defaultSavedViewIdDesktop': viewIdDesktop,
      })

      try {
        await this.$apollo.mutate({
          mutation: SaveSettingMutation,
          variables: {
            values: JSON.stringify(settings),
          },
        })

        this.$store.commit('applyPersistedSettings', settings)
      } catch (error) {
        throwFriendlyError(error)
      }
    },
  },

  apollo: {
    views: {
      query: ViewsQuery,
    },
  },
})
</script>
