<template>
  <div class="PmDefaultViewsPure">
    <div v-if="hasNotifications" class="PmDefaultViewsPure-notifications">
      <PmAppNotificationPure
        v-if="isNotificationSuccessVisible"
        variant="success"
      >
        Die Standard-Ansichten wurden erfolgreich gespeichert
      </PmAppNotificationPure>

      <PmErrorNotificationPure
        v-if="isNotificationErrorVisible"
        :message="errorMessage || xstate.meta.value.errorMessage"
        :details="errorDetails"
      />
    </div>

    <form class="PmDefaultViewsPure-form" @submit.prevent="submit">
      <div class="PmDefaultViewsPure-inputs">
        <PmDropdownPure
          v-model:value="selectedViewIdMobile"
          label="Mobil"
          class="PmDefaultViewsPure-input"
          :options="views"
        />

        <PmDropdownPure
          v-model:value="selectedViewIdDesktop"
          label="Desktop"
          class="PmDefaultViewsPure-input"
          :options="views"
        />
      </div>

      <div class="PmDefaultViewsPure-control">
        <PmButtonPure
          :icon="ICONS.SAVE"
          label="Speichern"
          variant="primary"
          class="PmDefaultViewsPure-submit"
          :loading="isLoading"
          type="submit"
          :disabled="!hasFormChanges"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, toRef } from 'vue'
import { isEqual } from 'lodash-es'

import { ICONS } from '@/constants/icons'

import { useXState } from '@/composition/useXState'
import propValidator from '@/functional/propValidator'

import {
  PmDefaultViewsPureState,
  states,
} from '@/components/PmDefaultViews/PmDefaultViewsPureState'

import PmDropdownPure from '@/components/basics/PmDropdownPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'

export const propTypes = {
  state: {
    allowed: states,
  },
}

export default defineComponent({
  name: 'PmDefaultViewsPure',
  components: {
    PmDropdownPure,
    PmAppNotificationPure,
    PmErrorNotificationPure,
    PmButtonPure,
  },

  props: {
    state: {
      type: String,
      default: undefined,
      validator: (value) => propValidator.oneOf(value, propTypes.state.allowed),
    },
    views: { type: Array, default: () => [] },
    errorMessage: { type: String, default: undefined },
    errorDetails: { type: Array, default: undefined },
    initialFormData: {
      type: Object,
      default: () => ({
        selectedViewIdMobile: undefined,
        selectedViewIdDesktop: undefined,
      }),
    },
  },

  emits: ['submit'],

  setup(props) {
    const xstate = useXState(PmDefaultViewsPureState, {
      syncStateWith: toRef(props, 'state'),
    })

    return {
      ICONS,

      xstate,
    }
  },

  data() {
    return {
      selectedViewIdMobile: undefined,
      selectedViewIdDesktop: undefined,
    }
  },

  computed: {
    isLoading() {
      if (this.xstate.state.value.matches('saving')) return true

      return false
    },

    isNotificationSuccessVisible() {
      return this.xstate.state.value.matches('success')
    },

    isNotificationErrorVisible() {
      return this.xstate.meta.value.error ? true : false
    },

    hasNotifications() {
      if (this.isNotificationSuccessVisible) return true
      if (this.isNotificationErrorVisible) return true

      return false
    },

    formData() {
      return {
        selectedViewIdMobile: this.selectedViewIdMobile,
        selectedViewIdDesktop: this.selectedViewIdDesktop,
      }
    },

    hasFormChanges() {
      return !isEqual(this.formData, this.initialFormData)
    },
  },

  created() {
    this.selectedViewIdMobile = this.initialFormData.selectedViewIdMobile
    this.selectedViewIdDesktop = this.initialFormData.selectedViewIdDesktop
  },

  methods: {
    submit() {
      this.$emit('submit', {
        viewIdMobile: this.selectedViewIdMobile,
        viewIdDesktop: this.selectedViewIdDesktop,
      })
    },
  },
})
</script>

<style lang="scss">
.PmDefaultViewsPure {
  $block: &;

  &-title {
    @include typography.h3;

    margin-bottom: 1em;
  }

  &-notifications {
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-inputs {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;

    @include mixin.media('<=mobile') {
      grid-template-columns: 1fr;
    }
  }

  &-input {
    // Empty
    flex-grow: 1;
  }

  &-control {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
