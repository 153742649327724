import { orderBy, isNil } from 'lodash-es'

import {
  STATUS,
  STATUS_JOB_LOOKUP,
  STATUS_JOB_TYPE_LOOKUP,
  STATUS_PROJECT_LOOKUP,
} from '@/constants/persoplan'

import { getFirstWord } from '@/utilities/string'
import { lookup } from '@/utilities/misc'
import type { Nilable } from '@/types/misc'

export function statusLookupJob({
  jobStateId,
  jobTypeId,
}: {
  jobStateId: Nilable<number>
  jobTypeId: Nilable<number>
}) {
  if (isNil(jobStateId)) {
    throw new Error('jobStateId is not defined')
  }

  if (jobTypeId) {
    if (lookup(jobTypeId, STATUS_JOB_TYPE_LOOKUP) === STATUS.SUB_HIRE) {
      return STATUS.SUB_HIRE
    }

    if (lookup(jobTypeId, STATUS_JOB_TYPE_LOOKUP) === STATUS.ITEM_TRANSFER) {
      return STATUS.ITEM_TRANSFER
    }
  }

  return lookup(jobStateId, STATUS_JOB_LOOKUP)
}

export function statusLookupProject({
  projectStateId,
}: {
  projectStateId: Nilable<number>
}) {
  if (isNil(projectStateId)) {
    throw new Error('projectStateId is not defined')
  }

  return lookup(projectStateId, STATUS_PROJECT_LOOKUP)
}

export function isProMusikVehicle(title: string) {
  return title.startsWith('PM')
}

interface Vehicle {
  id?: Nilable<number>
  caption?: Nilable<string>
  loadingCapacity?: Nilable<number>
}

interface VehicleNormalized {
  id: number
  title?: string
  isProMusik?: boolean
  loadingCapacity?: number | undefined
  isHighlighted?: boolean
}

export function normalizeVehicle(vehicle: Vehicle) {
  if (!vehicle.id) {
    throw new Error('vehicle.id is undefined')
  }

  const result: VehicleNormalized = {
    id: vehicle.id,
    title: vehicle?.caption ?? '—',
    isProMusik: vehicle.caption
      ? isProMusikVehicle(vehicle.caption)
      : undefined,
    loadingCapacity: vehicle.loadingCapacity ?? undefined,
  }

  return result
}

export function normalizeVehicles(vehicles?: Nilable<Vehicle>[]) {
  if (!vehicles) return

  return vehicles.reduce((result: VehicleNormalized[], vehicle) => {
    if (!vehicle) return result

    result.push(normalizeVehicle(vehicle))
    return result
  }, [])
}

export function defaultSortVehicles(vehicles: VehicleNormalized[]) {
  return orderBy(
    vehicles,
    [
      'isProMusik',
      (item) => (item.title ? getFirstWord(item.title) : '—'),
      'loadingCapacity',
      'title',
    ],
    ['desc', 'asc', 'desc', 'asc']
  )
}

export function getShortJobNumber(
  projectNumber: Nilable<string>,
  jobNumber: Nilable<string>
) {
  if (!jobNumber) return
  if (!projectNumber) return jobNumber

  const shortJobNumber = jobNumber.replace(projectNumber, '')

  return shortJobNumber
}

/**
 * Replace search input to strip out a leading * character
 */
export function normalizeSearchInput(value: Nilable<string>) {
  if (!value) return undefined

  // https://regex101.com/r/404w9v/2
  const regex = /^(?<strip>\*)(?<input>.+)/gm
  const result = regex.exec(value)

  const strip = result?.groups?.strip
  const input = result?.groups?.input

  if (!strip && !input) {
    return value
  }

  return input
}
