import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultipleResourceAllocationsDeletePureState.typegen'

export type State = Typegen0['matchesStates']

export const PmMultipleResourceAllocationsDeletePureState = createMachine({
  tsTypes:
    {} as import('./PmMultipleResourceAllocationsDeletePureState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'CONFIRM' } | { type: 'CANCEL' },
  },

  id: 'PmMultipleResourceAllocationsDeletePureState',
  initial: 'askForConfirmation',

  states: {
    askForConfirmation: {},

    delete: {
      initial: 'deleting',

      states: {
        deleting: {},

        success: {},

        failed: {
          meta: {
            error: true,
            errorMessage:
              'Es gab einen unbekannten Fehler beim Löschen der Ressourcen-Zuordnungen',
          },
        },
      },
    },
  },
})

export const states = extractAllStates(
  PmMultipleResourceAllocationsDeletePureState
)
