<template>
  <PmDataModalResourceSettingsPure
    v-if="value"
    v-model:value="value"
    :is-loading="isLoading"
    :state="xstate.path.value"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    :disabled="!$can('edit', 'persoplan')"
    @save="(settings) => xstate.service.value.send('SAVE', settings)"
  />
</template>

<script>
import { getCurrentInstance } from 'vue'

import { cloneDeep } from 'lodash-es'

import { useXState } from '@/composition/useXState'
import { throwFriendlyError } from '@/functional/error'

import { PmDataModalResourceSettingsState } from '@/components/persoplan/DataModal/DataModalResource/PmDataModalResourceSettingsState'

import PmDataModalResourceSettingsPure from '@/components/persoplan/DataModal/DataModalResource/PmDataModalResourceSettingsPure.vue'

import UpdateResourceSettingsMutation from '@/components/persoplan/DataModal/DataModalResource/UpdateResourceSettingsMutation.graphql'
import ResourceSettingsQuery from '@/components/persoplan/DataModal/DataModalResource/ResourceSettingsQuery.graphql'
import ResourceFragment from '@/graphql/fragments/ResourceFragment.graphql'

export default {
  name: 'PmDataModalResourceSettings',
  components: {
    PmDataModalResourceSettingsPure,
  },

  props: {
    resourceId: { type: Number, required: true },
  },

  setup() {
    const instance = getCurrentInstance()

    const xstate = useXState(PmDataModalResourceSettingsState, {
      services: {
        save: () => instance.ctx.save(),
      },
    })

    return {
      xstate,
    }
  },

  data() {
    return {
      value: undefined,
      isLoading: false,
    }
  },

  computed: {
    savedValueNormalized() {
      return {
        isTimesLocked: this.resource?.timesScheduled,
      }
    },
  },

  watch: {
    resource() {
      // Create copy of the saved data for editing
      this.value = cloneDeep(this.savedValueNormalized)
    },
  },

  methods: {
    async save() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateResourceSettingsMutation,

          variables: {
            id: this.resourceId,
            timesScheduled: this.value.isTimesLocked,
          },

          update: (store) => {
            const shared = {
              id: `ResourceFunctionAllocation:${this.resourceId}`,
              fragment: ResourceFragment,
            }

            // Read
            const readFragmentResult = store.readFragment({
              ...shared,
            })

            const data = cloneDeep(readFragmentResult)

            // Modify
            data.timesScheduled = this.value.isTimesLocked

            // Write
            store.writeFragment({
              ...shared,
              data,
            })
          },
        })
      } catch (error) {
        throwFriendlyError(error)
      }
    },
  },

  apollo: {
    resource: {
      query: ResourceSettingsQuery,

      variables() {
        return {
          id: this.resourceId,
        }
      },

      watchLoading(isLoading) {
        this.isLoading = isLoading
      },
    },
  },
}
</script>
