<template>
  <PmNotificationContainerPure
    notification-type="externalServiceRequest"
    :date="date"
    :is-unread="isUnread"
    :message="message"
    :class="componentClass.root"
  >
    <template #meta>
      <PmMetaListItemPure v-if="!isRequestOfCurrentUser" label="Name">
        {{ name }}
      </PmMetaListItemPure>

      <PmMetaListItemPure label="Datum">
        <template v-if="startDate && endDate">
          {{ startEndDateForText(startDate, endDate) }}
        </template>
      </PmMetaListItemPure>
    </template>

    <template #actions>
      <PmButtonListPure>
        <PmButtonPure label="Details" @click="emit('openDetails')" />

        <PmButtonPure
          v-if="isJumpVisible"
          icon="jump"
          title='In "Anträge" anzeigen'
          :to="{
            name: 'requestsCurrent',
            params: {
              type: 'fremddienstleistung',
              id: externalServiceRequestId,
            },
          }"
        />
      </PmButtonListPure>
    </template>
  </PmNotificationContainerPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import PmNotificationContainerPure, {
  type Props as PropsNotificationContainerPure,
} from '@/components/PmNotification/PmNotificationContainerPure.vue'
import PmMetaListItemPure from '@/components/PmMetaList/PmMetaListItemPure.vue'
import { startEndDateForText } from '@/utilities/date'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import type { Nilable } from '@/types/misc'

export interface Props
  extends Pick<PropsNotificationContainerPure, 'date' | 'isUnread'> {
  externalServiceRequestId: number
  name: Nilable<string>
  startDate: Nilable<Date>
  endDate: Nilable<Date>
  isJumpVisible?: boolean
  isRequestOfCurrentUser?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  openDetails: []
}>()

const componentClass = useComponentClass()
const message = computed(() => {
  if (props.isRequestOfCurrentUser) {
    return 'Dein Antrag auf Fremddienstleistung wurde aktualisiert'
  }

  return 'Ein Antrag auf Fremddienstleistung wurde aktualisiert'
})
</script>
