<template>
  <div :class="componentClass.root">
    <PmTabNavigationPure
      v-model:selected-tab-id="selectedTabId"
      :class="componentClass.element('tabs')"
      :expanded="true"
      :tabs="tabs"
    />

    <div v-if="selectedTabId === 'details'">
      <PmDataModalProjectPure
        :id="id"
        :is-loading="projectQuery.loading.value"
        :name="project?.caption"
        :number="project?.number"
        :status="status"
        :customer="normalizeAddress(project?.customer)"
        :contact="normalizeContact(project?.customer?.contact)"
        :type="project?.projectType?.caption"
        :start-date="parseServerDateString(project?.startDate)"
        :end-date="parseServerDateString(project?.endDate)"
        :created-date="parseServerDateString(project?.created)"
        :created-by="project?.createdBy?.displayName"
        :edited-date="parseServerDateString(project?.updated)"
        :edited-by="project?.updatedBy?.displayName"
        :project-leader="project?.arrangedBy?.displayName"
        :priority="project?.priority?.caption"
        :all-jobs="allJobs"
        @open-job="openJob"
      >
        <template #note>
          <PmNoteProject
            v-if="can('edit', 'persoplan')"
            :id="id"
            class="DataModalProject-note"
          />
        </template>
      </PmDataModalProjectPure>
    </div>

    <div v-if="selectedTabId === 'settings'" class="DataModalProject-settings">
      <PmDataModalProjectSettings :project-id="id" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import type { ComponentProps } from 'vue-component-type-helpers'

import {
  STATUS_PROJECT_LOOKUP,
  STATUS_JOB_LOOKUP,
  DATA_MODAL_TYPE,
} from '@/constants/persoplan'
import { EVENT } from '@/constants/events'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useAppAbility } from '@/composition/useAppAbility'
import { normalizeAddress, normalizeContact } from '@/utilities/address'
import { parseServerDateString } from '@/utilities/date'
import EventHub from '@/eventHub'

import PmTabNavigationPure from '@/components/basics/PmTabNavigation/PmTabNavigationPure.vue'
import PmDataModalProjectSettings from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectSettings.vue'
import PmDataModalProjectPure, {
  type Props as PropsDataModalProjectPure,
} from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProjectPure.vue'
import PmNoteProject from '@/components/PmNote/PmNoteProject.vue'

import { DataModalProjectDocument } from '@/../generated/graphql'
import { lookup } from '@/utilities/misc'

type PropsTabNavigationPure = ComponentProps<typeof PmTabNavigationPure>

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
const { can } = useAppAbility()

const tabs = [
  { id: 'details', label: 'Details' },
  { id: 'settings', label: 'Einstellungen' },
] as const satisfies PropsTabNavigationPure['tabs']

const selectedTabId = ref<(typeof tabs)[number]['id']>('details')

const projectQuery = useQuery(DataModalProjectDocument, () => ({
  id: props.id,
}))

const project = computed(() => projectQuery.result.value?.project)

/**
 * Status
 */
const projectStatus = computed(() => {
  return lookup(project.value?.projectState?.id, STATUS_PROJECT_LOOKUP)
})

const status = computed(() => {
  if (!projectStatus.value) return

  const label = project.value?.projectState?.caption
  if (!label) return

  const result: PropsDataModalProjectPure['status'] = {
    status: projectStatus.value,
    label: label,
  }

  return result
})

/**
 * All jobs of this project
 */
const allJobs = computed(() => {
  const result: PropsDataModalProjectPure['allJobs'] = []

  project.value?.jobs?.forEach((job) => {
    if (!job) return

    const jobNormalized: Exclude<
      PropsDataModalProjectPure['allJobs'],
      undefined
    >[number] = {
      id: job.id,
      title: job.caption,
      number: job.number,
      status: lookup(job.jobState?.id, STATUS_JOB_LOOKUP),
    }

    result.push(jobNormalized)
  })

  return result
})

function openJob(id: number) {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.JOB,
    id: id,
  })
}
</script>

<style lang="scss">
.PmDataModalProject {
  $block: &;

  &-tabs {
    margin-bottom: 16px;
  }
}
</style>
