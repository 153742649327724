retu
<template>
  <div class="PmResourceTimelinesVehicles">
    <PmResourceTimeline
      v-for="vehicle in vehiclesSorted"
      :id="vehicle.id"
      :key="vehicle.id"
      :start-date="startDate"
      :end-date="endDate"
      :type="RESOURCE_TYPE.VEHICLE"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { RESOURCE_TYPE } from '@/constants/persoplan'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useQuery } from '@vue/apollo-composable'

import { defaultSortVehicles, normalizeVehicles } from '@/utilities/persoplan'
import PmResourceTimeline from '@/components/persoplan/PmResourceTimeline/PmResourceTimeline.vue'
import { ProMusikVehiclesDocument } from '@/../generated/graphql'

export interface Props {
  startDate: Date
  endDate: Date
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()

const proMusikVehiclesQuery = useQuery(ProMusikVehiclesDocument)
const proMusikVehicles = computed(
  () => proMusikVehiclesQuery.result.value?.vehicles
)

const vehiclesNormalized = computed(() => {
  if (!proMusikVehicles.value) return
  return normalizeVehicles(proMusikVehicles.value)
})

const vehiclesSorted = computed(() => {
  if (!vehiclesNormalized.value) return
  return defaultSortVehicles(vehiclesNormalized.value)
})
</script>

<style lang="scss">
.PmResourceTimelinesVehicles {
  $block: &;

  padding-bottom: 8px;
}
</style>
