<template>
  <PmDataModalAppointmentPure
    :id="id"
    :is-loading="isLoading"
    :name="appointment?.caption"
    :type="appointment?.jobAppointmentType?.caption"
    :start-date="parseServerDateString(appointment?.startDate)"
    :end-date="parseServerDateString(appointment?.endDate)"
    :location="{
      street: appointment?.job?.deliveryAddress?.street,
      zip: appointment?.job?.deliveryAddress?.zip,
      city: appointment?.job?.deliveryAddress?.city,
      country: appointment?.job?.deliveryAddress?.country?.caption,
    }"
    :job="job"
    :project="project"
    :resources="resources"
    @open-project="
      (projectId) =>
        EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
          type: DATA_MODAL_TYPE.PROJECT,
          id: projectId,
        })
    "
    @open-job="
      (jobId) =>
        EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
          type: DATA_MODAL_TYPE.JOB,
          id: jobId,
        })
    "
  >
    <template #note>
      <PmNoteAppointment :id="id" />
    </template>
  </PmDataModalAppointmentPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'

import {
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  STATUS_PROJECT_LOOKUP,
  STATUS_JOB_LOOKUP,
  RESOURCE_TYPE_LOOKUP,
  DATA_MODAL_TYPE,
  type ResourceType,
} from '@/constants/persoplan'
import { EVENT } from '@/constants/events'

import EventHub from '@/eventHub'
import { lookup } from '@/utilities/misc'
import { parseServerDateString } from '@/utilities/date'
import { getDisplayNameOfAddress } from '@/utilities/string'

import PmDataModalAppointmentPure, {
  type Props as PropsDataModalAppointmentPure,
} from '@/components/persoplan/DataModal/PmDataModalAppointment/PmDataModalAppointmentPure.vue'

import PmNoteAppointment from '@/components/PmNote/PmNoteAppointment.vue'

import {
  DataModalAppointmentDocument,
  type DataModalAppointmentQuery,
} from '@/../generated/graphql'
import type { Get } from 'type-fest'

export interface Props {
  id: number
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const appointmentQuery = useQuery(DataModalAppointmentDocument, () => ({
  id: props.id,
}))

const appointment = computed(
  () => appointmentQuery.result.value?.jobAppointment
)

const isLoading = computed(() => {
  return appointmentQuery.loading.value === true
})

/**
 * Project
 */
const projectStatus = computed(() => {
  return lookup(
    appointment.value?.job?.project?.projectState?.id,
    STATUS_PROJECT_LOOKUP
  )
})

const project = computed(() => {
  const project = appointment.value?.job?.project
  if (!project) return

  const result: PropsDataModalAppointmentPure['project'] = {
    id: project.id,
    number: project.number,
    label: project.caption,
    status: projectStatus.value,
  }

  return result
})

/**
 * Job
 */
const jobStatus = computed(() => {
  return lookup(appointment.value?.job?.jobState?.id, STATUS_JOB_LOOKUP)
})

const job = computed(() => {
  if (!jobStatus.value) return

  const job = appointment.value?.job
  if (!job) return

  const result: PropsDataModalAppointmentPure['job'] = {
    id: job?.id,
    label: job.caption,
    number: job.number,
    status: jobStatus.value,
  }

  return result
})

/**
 * Resources
 */
const resources = computed(() => {
  const result: PropsDataModalAppointmentPure['resources'] = []

  appointment.value?.resourceFunctionAllocations?.forEach(
    (resourceFunctionAllocation) => {
      if (!resourceFunctionAllocation) return

      const resourceNormalized: Get<
        PropsDataModalAppointmentPure,
        'resources[0]'
      > = {
        id: resourceFunctionAllocation.id,
        title: resourceFunctionAllocation.resourceFunction?.caption,
        abbreviation: resourceFunctionAllocation.resourceFunction?.abbreviation,
        startDate: parseServerDateString(resourceFunctionAllocation.startDate),
        endDate: parseServerDateString(resourceFunctionAllocation.endDate),
        resourceAllocations: [],
      }

      const resourceType = lookup(
        resourceFunctionAllocation.resourceFunction?.resourceType?.id,
        RESOURCE_TYPE_LOOKUP
      )

      if (!resourceType) {
        console.warn('resourceType is undefined')
        return
      }

      resourceFunctionAllocation.resourceAllocations?.forEach(
        (resourceAllocation) => {
          const resourceAllocationNormalized = normalizeResourceAllocation(
            resourceAllocation,
            {
              resourceType: resourceType,
            }
          )

          if (!resourceAllocationNormalized) return

          resourceNormalized.resourceAllocations.push(
            resourceAllocationNormalized
          )
        }
      )

      result.push(resourceNormalized)
    }
  )

  return result
})

type ResourceAllocation = Get<
  DataModalAppointmentQuery,
  'jobAppointment.resourceFunctionAllocations[0].resourceAllocations[0]'
>

function normalizeResourceAllocation(
  resourceAllocation: ResourceAllocation,
  options: { resourceType: ResourceType }
) {
  if (!resourceAllocation) return

  let name: string | undefined = undefined

  if (options.resourceType === 'address')
    name = normalizeNameOfAddress(resourceAllocation)

  if (options.resourceType === 'vehicle')
    name = normalizeNameOfVehicle(resourceAllocation)

  const result: Get<
    PropsDataModalAppointmentPure,
    'resources[0].resourceAllocations[0]'
  > = {
    id: resourceAllocation.id,
    name: name,
    status: lookup(
      resourceAllocation.resourceAllocationState?.id,
      STATUS_RESOURCE_ALLOCATION_LOOKUP
    ),
  }

  return result
}

function normalizeNameOfAddress(resourceAllocation: ResourceAllocation) {
  if (!resourceAllocation) return

  let name = getDisplayNameOfAddress(resourceAllocation.address)

  const vehicleName =
    resourceAllocation.resourceAllocationDriver?.vehicle?.caption

  if (vehicleName) {
    name = `${name}@${vehicleName}`
  }

  return name
}

function normalizeNameOfVehicle(resourceAllocation: ResourceAllocation) {
  if (!resourceAllocation) return

  let name = resourceAllocation.vehicle?.caption ?? undefined

  const driverName = getDisplayNameOfAddress(
    resourceAllocation?.resourceAllocationDriver?.address
  )

  if (driverName) {
    name = `${name}@${driverName}`
  }

  return name
}
</script>

<style lang="scss">
.PmDataModalAppointment {
  $block: &;
}
</style>
