<template>
  <PmPollingIndicator
    :initial-loading-finished="initialLoadingFinished"
    :trigger-update="triggerUpdate"
  />
</template>

<script setup lang="ts">
import { onActivated, provide, ref } from 'vue'
import { useApolloClient } from '@vue/apollo-composable'

import { pollingIndicatorKey } from '@/utilities/inject'
import PmPollingIndicator from '@/components/basics/PmPollingIndicator/PmPollingIndicator.vue'

export interface Props {
  initialLoadingFinished: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

provide(pollingIndicatorKey, {
  update: update,
})

const triggerUpdate = ref(0)
const { client: apolloClient } = useApolloClient()

async function update() {
  await apolloClient.refetchQueries({
    include: ['ResourceRequestOverviewList', 'DataModalResourceRequest'],
  })
}

onActivated(() => {
  triggerUpdate.value += 1
})
</script>
