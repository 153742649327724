import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmGroupManagerItemPureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmGroupManagerItemPureState = createMachine({
  tsTypes: {} as import('./PmGroupManagerItemPureState.typegen').Typegen0,
  schema: {},

  id: 'PmGroupManagerItemPureState',
  initial: 'default',

  states: {
    default: {},
    save: {
      initial: 'saving',
      states: {
        saving: {},
        success: {},
        failed: {},
      },
    },
  },
})

export const states = extractAllStates(PmGroupManagerItemPureState)
