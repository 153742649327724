<template>
  <div class="PmResourceAllocationStatusPure" :class="classes">
    <div class="PmResourceAllocationStatusPure-status"></div>

    <template v-if="hasFactor">
      <div
        v-for="index in factor - 1"
        :key="index"
        class="PmResourceAllocationStatusPure-factor"
      ></div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceAllocationStatusPure'

export const propTypes = {
  status: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import { useResourceAllocationStatus } from '@/composition/useResourceAllocationStatus'

import {
  STATUS_RESOURCE_ALLOCATION,
  type ResourceAllocationStatus,
} from '@/constants/persoplan'

export interface Props {
  factor?: number
  status?: ResourceAllocationStatus
  isSkeleton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  factor: 1,
})

// const emit = defineEmits<{
//   (event: 'example', id: string): void
// }>()

const resourceAllocationStatus = useResourceAllocationStatus({
  status: toRef(props, 'status'),
})

const hasFactor = computed(() => {
  return props.factor > 1
})

const classes = computed(() => {
  return {
    ...resourceAllocationStatus.classes.value,
    'has-factor': hasFactor.value,
    'is-skeleton': props.isSkeleton,
  }
})
</script>

<style lang="scss">
.PmResourceAllocationStatusPure {
  $block: &;
  $name: function.getBlockName($block);

  $heightContainer: 17px;
  $sizeCircle: 10px;
  $heightContainerFactor: 17px;
  $sizeCircleFactor: 10px;
  $gap: 0;

  @include cssVar.define($block, 'color', color.$gray-400);
  @include mixin.status(cssVar.name($block, 'color'));

  &.is-skeleton {
    @include cssVar.define($block, 'color', color.$gray-300);
  }

  display: flex;
  flex-direction: column;
  gap: $gap;

  &-status {
    width: $sizeCircle;
    height: $heightContainer;

    // TODO:
    // margin-right: 3px;
    position: relative;
    flex: none;

    &::before {
      content: '';
      width: $sizeCircle;
      height: $sizeCircle;
      border-radius: $sizeCircle;
      background-color: cssVar.use($block, 'color');
      position: absolute;
      top: ($heightContainer - $sizeCircle) * 0.5;
      left: 0;
      z-index: 1;
    }

    #{$block}.has-factor &::after {
      $width: 2px;

      content: '';
      width: $width;
      left: ($sizeCircle - $width) * 0.5;
      top: $heightContainer * 0.5;
      height: ($heightContainer * 0.5) + $gap + ($heightContainerFactor * 0.5);
      position: absolute;
      background-color: color.$gray-400;
      z-index: 0;
    }
  }

  &-factor {
    $size: 8px;

    width: $sizeCircleFactor;
    height: $heightContainerFactor;
    position: relative;

    &::before {
      content: '';
      width: $sizeCircleFactor;
      height: $sizeCircleFactor;
      border-radius: $sizeCircleFactor;
      background-color: cssVar.use($block, 'color');
      position: absolute;
      top: ($heightContainerFactor - $sizeCircleFactor) * 0.5;
      left: 0;
      z-index: 1;
    }

    &:not(:last-child)::after {
      $width: 2px;

      content: '';
      width: $width;
      left: ($sizeCircleFactor - $width) * 0.5;
      top: $heightContainerFactor * 0.5;
      height: $heightContainerFactor + $gap;
      position: absolute;
      background-color: color.$gray-400;
      z-index: 0;
    }
  }
}
</style>
