<template>
  <PmKeyValueListPure v-if="address" appearance="naked" label-width="address">
    <PmKeyValuePure v-if="displayName" label="Name">
      {{ displayName }}
    </PmKeyValuePure>

    <PmKeyValuePure v-if="hasAddress" label="Adresse">
      <template v-if="address.street"> {{ address.street }}<br /> </template>

      <template v-if="address.zip && address.city">
        {{ address.zip }} {{ address.city }}<br />
      </template>

      <template v-if="address.country">
        {{ address.country }}
      </template>
    </PmKeyValuePure>

    <PmKeyValuePure v-if="hasPhone" label="Telefon">
      <PmRichTextPure>
        <template v-if="address.phone">
          <a :href="`tel:${address.phone}`">
            {{ address.phone }}
          </a>
          <br />
        </template>

        <template v-if="address.phoneCompany">
          <a :href="`tel:${address.phoneCompany}`">
            {{ address.phoneCompany }} (Firma)
          </a>
          <br />
        </template>

        <template v-if="address.phoneMobile">
          <a :href="`tel:${address.phoneMobile}`">
            {{ address.phoneMobile }} (Mobil)
          </a>
          <br />
        </template>

        <template v-if="address.phonePrivate">
          <a :href="`tel:${address.phonePrivate}`">
            {{ address.phonePrivate }} (Privat)
          </a>
        </template>
      </PmRichTextPure>
    </PmKeyValuePure>

    <PmKeyValuePure v-if="address.email" label="E-Mail">
      <PmRichTextPure>
        <a :href="`mailto:${address.email}`">{{ address.email }}</a>
      </PmRichTextPure>
    </PmKeyValuePure>

    <PmKeyValuePure v-if="address.url" label="Website">
      <PmRichTextPure>
        <a :href="`//${address.url}`" target="_blank">{{ address.url }}</a>
      </PmRichTextPure>
    </PmKeyValuePure>

    <slot />
  </PmKeyValueListPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getDisplayNameOfAddress } from '@/utilities/string'

import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'

import type { Nilable } from '@/types/misc'

export type Address = {
  firstName?: Nilable<string>
  lastName?: Nilable<string>
  company?: Nilable<string>
  street?: Nilable<string>
  zip?: Nilable<string>
  city?: Nilable<string>
  country?: Nilable<string>
  phone?: Nilable<string>
  phoneCompany?: Nilable<string>
  phoneMobile?: Nilable<string>
  phonePrivate?: Nilable<string>
  url?: Nilable<string>
  email?: Nilable<string>
}

export interface Props {
  address?: Nilable<Address>
}

const props = withDefaults(defineProps<Props>(), {})

const displayName = computed(() => {
  return getDisplayNameOfAddress({
    ...props.address,
    surname: props.address?.lastName,
  })
})

const hasAddress = computed(() => {
  if (props.address?.street) return true
  if (props.address?.zip && props.address.city) return true
  if (props.address?.country) return true

  return false
})

const hasPhone = computed(() => {
  if (props.address?.phone) return true
  if (props.address?.phoneCompany) return true
  if (props.address?.phoneMobile) return true
  if (props.address?.phonePrivate) return true

  return false
})
</script>
