<template>
  <div class="PmAppNotificationListPure" :class="classes">
    <div
      v-if="usePortal"
      ref="content"
      class="PmAppNotificationListPure-content PmAppNotificationListPure-content--portal"
    >
      <portal-target multiple :name="portalName">
        <template #wrapper="nodes">
          <transition-group @enter="onEnterDisabled" @leave="onLeaveDisabled">
            <component :is="node" v-for="node in nodes" :key="node" />
          </transition-group>
        </template>
      </portal-target>
    </div>

    <portal v-if="usePortal" :to="portalName">
      <slot />
    </portal>

    <transition-group
      v-if="!usePortal"
      ref="content"
      tag="div"
      class="PmAppNotificationListPure-content PmAppNotificationListPure-content--slot"
      @enter="onEnterDisabled"
      @leave="onLeaveDisabled"
    >
      <slot />
    </transition-group>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'PmAppNotificationListPure',

  props: {
    notifications: { type: Array, default: () => [] },
    usePortal: { type: Boolean, default: false },
    portalName: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      disabled: false,
    }
  },

  computed: {
    classes() {
      return {
        'is-disabled': this.disabled,
      }
    },
  },

  created() {
    // Validate portal props
    if (this.usePortal && !this.portalName) {
      throw new Error(
        `If you want to use "usePortal" you also need to define "portalName"`
      )
    }
  },

  methods: {
    getSiblingsAfter(el) {
      const siblings = []
      while ((el = el.nextSibling)) siblings.push(el)
      return siblings
    },

    /**
     * There is a bug when using transitions with portal-vue
     * Therefore transitions are disabled until this is fixed
     * @see: https://github.com/LinusBorg/portal-vue/issues/378
     */
    onEnterDisabled(el, done) {
      done()
    },

    onLeaveDisabled(el, done) {
      done()
    },

    onEnter(elNotification, done) {
      gsap.set(elNotification, {
        opacity: 0,
        x: 200,
      })

      gsap.to(elNotification, 0.5, {
        opacity: 1,
        x: '0%',
        ease: 'back.out',
        clearProps: 'all',
        onComplete: done,
      })
    },

    onLeave(elNotification, done) {
      const duration = 0.3

      this.disabled = true // TODO

      // Make preperations
      const rectContainer = this.$el.getBoundingClientRect()
      this.$el.style.height = `${rectContainer.height}px`
      const rectNotification = elNotification.getBoundingClientRect()

      // Record siblings
      const elsSiblings = [...this.$refs.content.$el.childNodes].filter(
        (child) => child !== elNotification
      )

      const siblingsData = elsSiblings.map((elSibling) => {
        return {
          el: elSibling,
          before: {},
          after: {},
          inverted: {},
        }
      })

      siblingsData.forEach((sibling) => {
        const rect = sibling.el.getBoundingClientRect()

        sibling.before = {
          y: rect.y,
        }
      })

      // Apply final state
      elNotification.style.display = 'none'

      siblingsData.forEach((sibling) => {
        const rect = sibling.el.getBoundingClientRect()

        sibling.after = {
          y: rect.y,
        }

        sibling.inverted = {
          y: sibling.before.y - sibling.after.y,
        }
      })

      // Apply styles
      siblingsData.forEach((sibling) => {
        gsap.set(sibling.el, {
          y: sibling.inverted.y,
        })
      })

      // Remove final state
      elNotification.style.display = null

      gsap.set(elNotification, {
        position: 'absolute',
        width: rectNotification.width,
        height: rectNotification.height,
        y: elNotification.offsetTop,
      })

      // Animate
      gsap.to(elNotification, {
        duration: duration,
        x: 200,
        ease: 'back.in',
      })

      gsap.to(elNotification, duration - 0.2, {
        opacity: 0,
        delay: 0.2,
        ease: 'none',
      })

      // No other notifications:
      if (!siblingsData.length) {
        gsap.delayedCall(duration, () => {
          this.disabled = false
          this.$el.style.height = null
          done()
        })

        return
      }

      gsap.to(
        siblingsData.map((sibling) => sibling.el),
        {
          y: 0,
          duration: 0.2,
          delay: duration - 0.1,
          clearProps: 'all',
          stagger: {
            each: 0.05,
          },
          onComplete: () => {
            this.disabled = false
            this.$el.style.height = null
            done()
          },
        }
      )
    },
  },
}
</script>

<style lang="scss">
.PmAppNotificationListPure {
  $block: &;

  padding: 12px;
  padding-left: 40px;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: var(--space-gutters);

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  /* stylelint-disable */
  .PmAppNotificationPure {
    pointer-events: auto;

    #{$block}.is-disabled & {
      pointer-events: none;
    }

    &:not(:last-child) {
      margin-bottom: var(--space-gutters);
    }
    // transition: all 100s;
    // display: inline-block;

    // &.v-enter-from,
    // &.v-leave-to {
    //   opacity: 0;
    //   transform: translateX(200px);
    // }

    // &.v-leave-active {
    //   position: absolute;
    //   border: 2px solid green;
    //   display: block;
    //   transition: all 100s;
    // }

    // &.v-move {
    //   outline: 5px solid brown;
    //   transition: all 10s;
    // }
  }
  /* stylelint-enable */
}
</style>
