<template>
  <PmModalPure title="Ansicht löschen" @close="emit('close')">
    <div class="PmViewDeletePure">
      <PmErrorNotificationPure
        v-if="error && errorMessage"
        class="PmViewDeletePure-notification"
        :message="errorMessage"
        :details="errorDetails"
      />

      <PmAppNotificationPure
        v-if="isPublicViewNoteVisible"
        class="PmViewDeletePure-notification"
        icon="public"
        variant="warning"
      >
        Öffentliche Ansicht — Änderungen wirken sich auf andere Benutzer aus.
      </PmAppNotificationPure>

      <PmAskForConfirmationPure
        title="Ansicht löschen"
        :is-loading="loading"
        @delete="emit('delete')"
        @cancel="emit('close')"
      >
        Willst du die Ansicht <b>{{ viewName }}</b> löschen? Die Ansicht kann
        nicht wiederhergestellt werden
      </PmAskForConfirmationPure>
    </div>
  </PmModalPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmViewDeletePure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmAskForConfirmationPure from '@/components/PmAskForConfirmation/PmAskForConfirmationPure.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'

export interface Props {
  viewName?: string
  loading?: boolean
  error?: boolean
  errorMessage?: string
  errorDetails?: string[]
  isPublicViewNoteVisible?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'delete'): void
  (event: 'close'): void
}>()
</script>

<style lang="scss">
.PmViewDeletePure {
  $block: &;

  &-title {
    @include typography.h4;
  }

  &-text {
    margin-bottom: 1em;
  }

  &-notification {
    margin-bottom: var(--space-gutters);
  }
}
</style>
