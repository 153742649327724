<template>
  <div class="PmPackingContainerPure" :style="styles">
    <slot :packing-container="slotProps" />
  </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash-es'
import deepmerge from 'deepmerge'

import { emitPackingFinished } from '@/events/packing'

import { PACKING_CONTAINER_ID } from '@/constants/persoplan'

import { packing } from '@/functional/packing'

const DEBUG = false

const packingContainerSettingsDefaults = {
  observeResize: true,
}

export default {
  name: 'PmPackingContainerPure',

  inject: {
    packingContainerSettings: {
      default: () => cloneDeep(packingContainerSettingsDefaults),
    },
  },

  props: {
    id: { type: String, default: undefined },
  },

  data() {
    return {
      elements: [],
      height: undefined,
      slotProps: {
        addElement: undefined,
        removeElement: undefined,
        updateDimensionsOfElement: undefined,
        packedElements: [],
      },
    }
  },

  computed: {
    styles() {
      return {
        height: `${this.height}px`,
      }
    },

    packingContainerSettingsNormalized() {
      return deepmerge(
        packingContainerSettingsDefaults,
        this.packingContainerSettings
      )
    },
  },

  created() {
    // add function references to slotProps:
    this.slotProps.addElement = this.addElement
    this.slotProps.removeElement = this.removeElement
    this.slotProps.updateDimensionsOfElement = this.updateDimensionsOfElement
  },

  mounted() {
    let debounceTime = 50
    if (this.id === PACKING_CONTAINER_ID.CALENDAR_JOBS) {
      debounceTime = 150
    }

    this.packDebounced = debounce(() => {
      this.pack()
    }, debounceTime)

    this.resizeObserver = new ResizeObserver(this.elementsResized)

    this.mutationObserver = new MutationObserver(this.contentsChanged)
    this.mutationObserver.observe(this.$el, {
      childList: true,
    })
  },

  beforeUnmount() {
    this.resizeObserver.disconnect()
  },

  methods: {
    elementsResized() {
      if (!this.packingContainerSettingsNormalized.observeResize) {
        return
      }

      // TODO: Possible Performance improvement here would be only to update the dimensions
      // for the elements which actually changed
      this.elements.forEach((element) => {
        element.updateDimensions()
      })

      this.packDebounced()
    },

    contentsChanged(mutationList) {
      const childListChanged = mutationList.some((mutation) => {
        return mutation.type === 'childList'
      })

      if (!childListChanged) return
      this.packDebounced()
    },

    addElement(element) {
      this.elements.push(element)
      this.resizeObserver.observe(element.el)
      this.packDebounced()
    },

    removeElement({ id }) {
      const index = this.elements.findIndex((element) => element.id === id)

      if (index === -1) {
        return console.warn(`packingContainer: element with id ${id} not found`)
      }

      const element = this.elements[index]
      this.resizeObserver.unobserve(element.el)
      this.elements.splice(index, 1)
    },

    updateDimensionsOfElement({ id, x, width, height }) {
      const element = this.elements.find((element) => {
        return element.id === id
      })

      if (!element) {
        if (DEBUG && import.meta.env.DEV === true) {
          console.warn(`packingContainer: element with id ${id} not found`)
        }

        return
      }

      element.x = x
      element.width = width
      element.height = height

      this.packDebounced()
    },

    pack() {
      // When cypress is active we skip packing for now, to make tests more reliable
      if (window.Cypress) {
        return
      }

      const rect = this.$el.getBoundingClientRect()

      if (rect.width === 0) {
        console.log('element with width of 0')
        // console.log(this.$el)
      }

      const packedData = packing(this.elements, {
        containerWidth: rect.width,
        maintainOrder: false,
      })

      if (packedData) {
        this.slotProps.packedElements = packedData.packedElements
        this.height = packedData.heightOfPackedElements
      }

      if (!this.id) return
      emitPackingFinished(this.id)
    },
  },

  // render() {
  //   return this.$scopedSlots.default({
  //     styles: this.packingContainer.styles,
  //     packingContainer: this.packingContainer.slotProps
  //   })
  // }
}
</script>

<style lang="scss">
.PmPackingContainerPure {
  position: relative;
}
</style>
