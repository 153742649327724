import { setup, fromPromise, assertEvent } from 'xstate5'
import { type Emits as EmitsCreateLeaveRequestPure } from '@/components/PmCreateLeaveRequest/PmCreateLeaveRequestPure.vue'
import { assertFriendlyError } from '@/functional/friendlyErrors'

export const PmCreateLeaveRequestState = setup({
  types: {
    events: {} as {
      type: 'create'
      variables: EmitsCreateLeaveRequestPure['submit']
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  actions: {
    showSuccessNotification: () => {
      throw new Error('showSuccessNotification not implemented')
    },

    emitClose: () => {
      throw new Error('emitClose not implemented')
    },
  },

  actors: {
    createLeaveRequest: fromPromise<
      void,
      { variables: EmitsCreateLeaveRequestPure['submit'] }
    >(async () => {
      throw new Error('createLeaveRequest not implemented')
    }),
  },
}).createMachine({
  id: 'PmCreateLeaveRequestState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        create: 'create',
      },
    },

    create: {
      initial: 'saving',

      states: {
        saving: {
          invoke: {
            id: 'createLeaveRequest',
            src: 'createLeaveRequest',
            input: ({ event }) => {
              assertEvent(event, 'create')
              return { variables: event.variables }
            },
            onDone: {
              target: 'success',
            },
            onError: {
              target: 'error',
              actions: ({ context, event }) => {
                assertFriendlyError(event.error)

                context.error = event.error.message
                context.errorDetails = event.error.details
              },
            },
          },
        },
        error: {
          on: {
            create: '#PmCreateLeaveRequestState.create',
          },
        },
        success: {
          entry: ['showSuccessNotification', 'emitClose'],
          type: 'final',
        },
      },
    },
  },
})
