<template>
  <div class="PmSvgSpritePure">
    <template v-for="icon in allIcons" :key="icon.id">
      <component
        :is="icon.component"
        :id="`svgsprite-${icon.id}`"
        class="PmSvgSpritePure-icon"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import iconComponent from '@/assets/icons'

const allIcons = Object.entries(iconComponent).map(([key, component]) => {
  return {
    id: key,
    component: component,
  }
})
</script>

<style lang="scss">
.PmSvgSpritePure {
  $block: &;

  display: none;

  &-icon {
    @include mixin.svgIcon;
  }
}
</style>
