<template>
  <PmPollingIndicator
    :initial-loading-finished="initialLoadingFinished"
    :is-updating-from-external="isUpdatingCalendarCache"
    :user-is-idle="userIsIdle"
    :trigger-update="triggerUpdate"
  />
</template>

<script setup lang="ts">
import {
  shallowRef,
  ref,
  computed,
  onBeforeUnmount,
  nextTick,
  provide,
} from 'vue'
import { useStore } from 'vuex'
import { useApolloClient } from '@vue/apollo-composable'

import { EVENT } from '@/constants/events'

import eventHub from '@/eventHub'
import { getFriendlyErrors } from '@/functional/friendlyErrors'
import {
  transformToAlternativeEndOfDay,
  formatToServerDateString,
} from '@/utilities/date'
import { pollingIndicatorKey } from '@/utilities/inject'

import PmPollingIndicator from '@/components/basics/PmPollingIndicator/PmPollingIndicator.vue'

import {
  CalendarCacheDocument,
  SidebarCacheDocument,
} from '@/../generated/graphql'

export interface Props {
  initialLoadingFinished?: boolean
  isUpdatingCalendarCache?: boolean
  userIsIdle?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

provide(pollingIndicatorKey, {
  update: update,
})

const store = useStore()
const abortController = shallowRef<AbortController>()
const { client: apolloClient } = useApolloClient()

const startDateAlternativeStartOfDay = computed(() => {
  return transformToAlternativeEndOfDay(store.state.persoplan.visibleStartDate)
})

/**
 * Trigger update by event
 */
const triggerUpdate = ref(0)

function updateByEvent() {
  triggerUpdate.value += 1
}

eventHub.$on(EVENT.CACHE_CALENDAR_UPDATE, updateByEvent)

onBeforeUnmount(() => {
  eventHub.$on(EVENT.CACHE_CALENDAR_UPDATE, updateByEvent)
})

/**
 * For information about how to do cancellation of queries
 * @see https://evilmartians.com/chronicles/aborting-queries-and-mutations-in-react-apollo
 * There is another method, by using watchQuery, but I couldn't  get this wot work
 * @see https://github.com/apollographql/apollo-client/issues/4150#issuecomment-500127694
 */
async function update() {
  try {
    // Check if there's an existing abortController and cancel it in case
    if (abortController.value) {
      abortController.value.abort()
      // We need to wait for the previous promise chain to resolve, otherwise the next abortController will get resetted too early
      await nextTick()
    }
  } catch (error) {
    throw new Error('Error when trying to abort previous request')
  }

  /**
   * The `promiseExit` has the sole purpose of exiting the async/await flow in case an
   * update is going to get cancelle  d. There is no way of accessing the resolve method
   * of the apollo-query apparently
   */
  let promiseExitResolve
  const promiseExit = new Promise((resolve) => {
    promiseExitResolve = resolve
  })

  abortController.value = new window.AbortController()
  abortController.value.signal.addEventListener('abort', promiseExitResolve, {
    once: true,
  })

  try {
    const queryVariables = store.getters['queryVariables/calendar']

    const promiseCalendar = apolloClient.query({
      query: CalendarCacheDocument,
      fetchPolicy: 'network-only',
      variables: {
        ...queryVariables,
        startDateAlternativeStartOfDay: formatToServerDateString(
          startDateAlternativeStartOfDay.value
        ),
      },
      context: {
        fetchOptions: {
          signal: abortController.value.signal,
        },
      },
    })

    const promiseSidebar = apolloClient.query({
      query: SidebarCacheDocument,
      fetchPolicy: 'network-only',
      variables: queryVariables,
      context: {
        fetchOptions: {
          signal: abortController.value.signal,
        },
      },
    })

    // Collect updates in a single promise
    const promiseQueries = Promise.all([promiseCalendar, promiseSidebar])

    // Wait for either all updates to finish or the exit promise to resolve
    await Promise.race([promiseExit, promiseQueries])

    // Reset abortController
    abortController.value = undefined
  } catch (error) {
    const friendlyErrors = getFriendlyErrors(error)
    throw new Error(friendlyErrors?.message, { cause: error })
  }
}
</script>
