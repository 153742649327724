/**
 * @todo When converted ty Typescript fix
 * @reference 6a0kcd8
 */

import {
  // STATUS_RESOURCE_ALLOCATION_LOOKUP,
  // STATUS_RESOURCE_ALLOCATION,
  // STATUS_JOB_LOOKUP,
  // STATUS as STATUS_JOB,
  VISIBLE_RESOURCE_STATE_TYPE_IDS_FREELANCERS,
  ADDRESS_RESOURCE_TYPE_ID_LOOKUP,
  ADDRESS_RESOURCE_TYPE,
} from '@/constants/persoplan'

import type {
  ResourceTimelineFreelancerQuery,
  SidebarFreelancersQuery,
} from '@/../generated/graphql'
import type { Get } from 'type-fest'

export function shouldResourceAllocationBeVisibleInFreelancerArea({
  jobStatusId,
  resourceAllocationStateId,
}) {
  jobStatusId
  resourceAllocationStateId

  return true

  // // Only use resource allocations on cancelled jobs
  // const statusJob = STATUS_JOB_LOOKUP[jobStatusId]
  // if (statusJob !== STATUS_JOB.CANCELLED) return false

  // // Check allocation status
  // const statusResourceAllocation =
  //   STATUS_RESOURCE_ALLOCATION_LOOKUP[resourceAllocationStateId]

  // if (
  //   statusResourceAllocation === STATUS_RESOURCE_ALLOCATION.CONFIRMED ||
  //   statusResourceAllocation === STATUS_RESOURCE_ALLOCATION.RESERVED
  // ) {
  //   return true
  // }
}

export function shouldResourceStateBeVisibleInFreelancerArea({
  resourceStateTypeId,
}) {
  return VISIBLE_RESOURCE_STATE_TYPE_IDS_FREELANCERS.includes(
    resourceStateTypeId
  )
}

export function shouldBeVisibleInFreelancerArea(item) {
  // Check if resource allocations are relevant
  if (item.__typename === 'ResourceAllocation') {
    if (
      !shouldResourceAllocationBeVisibleInFreelancerArea({
        jobStatusId: item.resourceFunctionAllocation.job.jobState.id,
        resourceAllocationStateId: item.resourceAllocationState.id,
      })
    ) {
      return false
    }
  }

  // Check if resource states are relevant
  if (item.__typename === 'ResourceState') {
    if (
      !shouldResourceStateBeVisibleInFreelancerArea({
        resourceStateTypeId: item.resourceStateType.id,
      })
    ) {
      return false
    }
  }

  return true
}

/**
 * Extract all addressIds of Freelancers in attendees and add them to the
 * result if not present already
 */
function handleEvent({ event, result }) {
  const attendeesWhichAreFreelancers = event.attendees.filter((attendee) => {
    return (
      attendee.address?.addressResourceType?.id ===
      ADDRESS_RESOURCE_TYPE_ID_LOOKUP[ADDRESS_RESOURCE_TYPE.FREELANCER]
    )
  })

  // Add all which are not already in result
  attendeesWhichAreFreelancers.forEach((attendee) => {
    const addressId = attendee.address.id

    const isAlreadyIn = Object.prototype.hasOwnProperty.call(result, addressId)
    if (isAlreadyIn) return result

    result[addressId] = {
      id: addressId,
      firstName: attendee.address.firstName,
      lastName: attendee.address.surname,
      title: attendee.address.displayName,
    }
  })
}

function handleResourceAllocationOrResourceState({ item, result }) {
  const id = item.address.id

  // Check if item is already in results
  const isAlreadyIn = Object.prototype.hasOwnProperty.call(result, id)
  if (isAlreadyIn) return result

  // Do filtering checks
  if (!shouldBeVisibleInFreelancerArea(item)) {
    return result
  }

  // Add item to results
  result[id] = {
    id: id,
    firstName: item.address.firstName,
    lastName: item.address.surname,
    title: item.address.displayName,
  }
}

export function getReducedFreelancerTimelineData(
  freelancerCalendar:
    | SidebarFreelancersQuery['freelancerCalendar']
    | ResourceTimelineFreelancerQuery['resourceTimeline']
) {
  if (!freelancerCalendar) return

  const result = freelancerCalendar.reduce((result, item) => {
    if (!item) return result

    if (
      item.__typename === 'ResourceAllocation' ||
      item.__typename === 'ResourceState' ||
      item.__typename === 'CalendarEvent'
    ) {
      handleResourceAllocationOrResourceState({ item, result })
    }

    if (item.__typename === 'Event') {
      handleEvent({ event: item, result })
    }

    return result
  }, {})

  return result
}
