import { createMachine } from 'xstate'
import { extractAllStates } from '@/composition/useXState'

import type { Typegen0 } from './PmMultiSelectionAllocatePureState.typegen'

export type TState = Typegen0['matchesStates']

export const PmMultiSelectionAllocatePureState = createMachine({
  tsTypes: {} as import('./PmMultiSelectionAllocatePureState.typegen').Typegen0,
  schema: {
    events: {} as { type: 'CONFIRM' },
  },

  id: 'PmMultiSelectionAllocatePureState',
  initial: 'askForConfirmation',
  states: {
    askForConfirmation: {
      on: {
        CONFIRM: {
          actions: 'emitConfirm',
        },
      },
    },
    saving: {},
    success: {
      meta: {
        message: 'Die Zuordnungen wurden erfolgreich durchgeführt',
      },
    },
    failed: {
      meta: {
        error: true,
        message: 'Es gab einen Fehler bei ein oder mehreren Zuordnungen',
      },
    },
  },
})

export const states = extractAllStates(PmMultiSelectionAllocatePureState)
